

import { FileManager } from '../../services/files/FileManager.js';

export var _utils = {

    // Call the function
    //fetchPageDetails();

    handleBackButton: function () {

        /*  let backButton = $$$(document, "maineditor", "back")
         backButton.classList.add("active")
         backButton.onclick = event => {
             event.stopPropagation()
             event.preventDefault()
             _utils.closeAnimator()
 
         } */

    },





    createStrippedDownObject: function (obj) {

        let newObject = {}

        let keysToCopy = [
            "type", "ancestor", "classes", "id", "text", "type", "media"
        ]

        for (let key of keysToCopy) {
            if (obj[key]) {
                newObject[key] = obj[key]
            }
        }

        return newObject

    },


    round: function (time, timeIncrement) {

        return Math.round(time / timeIncrement) * timeIncrement;

        //console.log("snap", snap, time, timeIncrement)



    },


    SmartButton: class {
        constructor(settings) {
            this.settings = settings;

            /* 
            //settings looks like this:
            button: css_audio.ui.generate_captions,
             function: css_audio.transcribeAudio,
             params: [],
             eta: 3000,  // the number of ms this is likely to take
             onComplete: null */


            this.init()


            this.el = $$$$(this.settings.button, "smart_button");
            this.originalLabel = this.el.label.textContent;
            this.isComplete = false; // Flag to stop the progress
            this.el.progress.style.width = "0%";

        }
        init() {
            this.settings.button.onclick = () => {
                this.click();
            };
        }

        async click() {
            //this.el.progress.style.width = "10%";
            this.animateProgress();

            this.el.label.textContent = "Processing...";
            let response = await this.settings.function(...this.settings.params);

            this.isComplete = true; // Mark completion when the async function finishes

            this.el.label.textContent = this.settings.success || "Complete!";

            // Animate to 100% once the task completes
            //this.el.progress.style.transition = "width 0.5s";
            this.el.progress.style.width = "100%";

            setTimeout(() => {
                this.el.label.textContent = this.originalLabel;
                this.el.progress.style.width = "0%";
            }, 1000);

            if (this.settings.onComplete) {
                this.settings.onComplete(response);
            }
        }

        animateProgress() {
            // Animated progress based on the eta
            const startTime = Date.now();
            const eta = this.settings.eta || 3000; // default to 3000ms if eta is not provided
            const progressTarget = 80; // Stop at 80% until the function is complete

            const updateProgress = () => {
                if (this.isComplete) return; // Stop if the task has completed

                const elapsedTime = Date.now() - startTime;
                const progress = Math.min((elapsedTime / eta) * progressTarget, progressTarget);

                this.el.progress.style.width = `${progress}%`;

                if (progress < progressTarget) {
                    requestAnimationFrame(updateProgress);
                }
            };

            requestAnimationFrame(updateProgress);
        }

        complete() {

        }
    },



    removeAttributesFromNode: function (node, attributesToRemove) {
        if (node.nodeType === Node.ELEMENT_NODE) {
            attributesToRemove.forEach(attr => {
                if (node.hasAttribute(attr)) {
                    node.removeAttribute(attr);
                }
            });
        }

        // Recursively remove attributes from child nodes
        for (let child of node.children) {
            _utils.removeAttributesFromNode(child, attributesToRemove);
        }
    },

    clearInlineStyles: function (node) {
        if (node.nodeType === Node.ELEMENT_NODE) {
            node.removeAttribute('style');
        }

        // Recursively clear inline styles from child nodes
        for (let child of node.children) {
            _utils.clearInlineStyles(child);
        }
    },


    createEasingChart: function (locationElement, easing, totalTimeInMs, width, height, color) {

        if (!height) {
            height = 26;  // Fixed height as per your requirement
        }


        // Clear existing content in the location element
        locationElement.innerHTML = '';

        // Create and configure the canvas element
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        locationElement.appendChild(canvas);
        const ctx = canvas.getContext('2d');

        // Define margins and drawing area (set horizontal margins to 0)
        const margin = { top: 10, right: 0, bottom: 10, left: 0 };
        const drawWidth = width - margin.left - margin.right;
        const drawHeight = height - margin.top - margin.bottom;

        // Parse the easing function
        const easingFunction = parseEasingFunction(easing);

        // Generate data points
        const numPoints = 200;
        const data = [];
        for (let i = 0; i <= numPoints; i++) {
            const t = i / numPoints;
            const time = t * totalTimeInMs;
            const value = easingFunction(t);
            data.push({ time, value });
        }

        // Define scales
        const xScale = d => margin.left + (d / totalTimeInMs) * drawWidth;
        const yScale = d => margin.top + (1 - d) * drawHeight;  // Invert y-axis for proper orientation

        // Draw the easing curve
        ctx.beginPath();
        ctx.moveTo(xScale(data[0].time), yScale(data[0].value));
        for (let i = 1; i < data.length; i++) {
            ctx.lineTo(xScale(data[i].time), yScale(data[i].value));
        }
        ctx.lineWidth = 1;
        ctx.strokeStyle = "rgba(0, 131, 247, 0.52)"

        //ctx.strokeStyle = color


        ctx.stroke();

        // Helper Functions
        function parseEasingFunction(easing) {
            const easingFunctions = {
                'linear': t => t,
                'ease-in': t => t * t,
                'ease-out': t => t * (2 - t),
                'ease-in-out': t => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t,
                'ease': t => cubicBezier(0.25, 0.1, 0.25, 1)(t),
                'step-start': t => (t === 0 ? 0 : 1),
                'step-end': t => (t === 1 ? 1 : 0),
            };

            if (typeof easing === 'function') {
                return easing;
            } else if (easingFunctions[easing]) {
                return easingFunctions[easing];
            } else {
                const bezierMatch = easing.match(/cubic-bezier\(([^,]+),([^,]+),([^,]+),([^,]+)\)/);
                if (bezierMatch) {
                    const [, p0, p1, p2, p3] = bezierMatch.map(Number);
                    return cubicBezier(p0, p1, p2, p3);
                } else {
                    throw new Error(`Easing function "${easing}" not recognized.`);
                }
            }
        }

        function cubicBezier(p0, p1, p2, p3) {
            return function (t) {
                const cx = 3 * p0;
                const bx = 3 * (p2 - p0) - cx;
                const ax = 1 - cx - bx;
                const cy = 3 * p1;
                const by = 3 * (p3 - p1) - cy;
                const ay = 1 - cy - by;

                const x = ((ax * t + bx) * t + cx) * t;
                const y = ((ay * t + by) * t + cy) * t;

                return y;
            };
        }
    },





    createAnimationCss: function (obj) {

        let animationProperties = [];

        // Sort definitions by start time to ensure animations are in order
        let sortedDefinitions = Object.values(obj).sort((a, b) => a.start - b.start);

        for (let definition of sortedDefinitions) {
            let id = definition.id;
            let duration = definition.duration;
            let delay = definition.start;
            let easing = definition.easing;

            // Assuming keyframes are correctly defined in the publicai object
            let keyframes = publicai.css.keyframes[id];
            if (keyframes) {
                // Format: 'name duration timing-function delay iteration-count direction fill-mode play-state'
                // Example: 'slideInUp 1.5s ease-in-out 0s 1 normal forwards running'
                let animationString = `${id} ${duration}ms ${easing} ${delay}ms forwards`;

                if (!nws.isPlayer &&
                    animator_timeline.mode == "keyframes" &&
                    definition.enabled
                ) {
                    animationProperties.push(animationString);
                } else {
                    //console.log(definition.id, "skipped because it's off scree")
                }

                if (nws.isPlayer) {
                    animationProperties.push(animationString);
                }



            }
        }



        return animationProperties.join(', ');

    },





    mediaSubscription: {
        subscribers: {},
        clickHandlers: {}, // New object to hold named click handlers

        // Subscribe to an event
        subscribe: function (event, callback) {
            if (!this.subscribers[event]) {
                this.subscribers[event] = [];
            }
            this.subscribers[event].push(callback);
        },

        // Register a named click handler
        registerClickHandler: function (name, callback) {
            this.clickHandlers[name] = callback;
        },

        // Call a specific named click handler
        callClickHandler: function (name, data) {
            if (this.clickHandlers[name]) {
                this.clickHandlers[name](data);
            }
        },

        // Unsubscribe from an event
        unsubscribe: function (event, callback) {
            if (this.subscribers[event]) {
                this.subscribers[event] = this.subscribers[event].filter(subscriber => subscriber !== callback);
            }
        },

        // Publish an event and optionally call a named click handler
        publish: function (event, data, handlerName = null) {
            if (this.subscribers[event]) {
                this.subscribers[event].forEach(callback => callback(data));
            }
            if (handlerName && this.clickHandlers[handlerName]) {
                this.callClickHandler(handlerName, data);
            }
        }
    },


    frameMessages: {

        //consider using at some point. Right now, the frames talk to one another directly

        subscribers: {},
        clickHandlers: {},
        targetOrigin: '*', // Be more specific for security in production!
        initialized: false,
        messageStack: [], // Array to keep track of all messages

        init: function () {
            if (this.initialized) {
                return;
            }
            window.addEventListener('message', (event) => {
                // Security check: Validate event.origin here
                const { type, name, data } = event.data;

                // Add received message to the stack
                this.messageStack.push({ type, name, data, received: true });

                // Directly invoke callbacks based on the type of message
                if (type === 'publish' && this.subscribers[name]) {
                    this.subscribers[name].forEach(callback => callback(data));
                }
            });
            this.initialized = true;
        },

        subscribe: function (event, callback) {
            if (!this.subscribers[event]) {
                this.subscribers[event] = [];
            }
            this.subscribers[event].push(callback);
        },


        unsubscribe: function (event, callback) {
            if (this.subscribers[event]) {
                this.subscribers[event] = this.subscribers[event].filter(subscriber => subscriber !== callback);
            }
        },

        publish: function (event, data, handlerName = null) {
            const message = { type: 'publish', name: event, data: data };

            // Add sent message to the stack
            this.messageStack.push({ ...message, sent: true });

            window.postMessage(message, this.targetOrigin);

            if (handlerName) {
                this.callClickHandler(handlerName, data);
            }
        },
    },

    throttle: function (func, delay) {
        let lastCall = 0;
        return function (...args) {
            const now = new Date().getTime();
            if (now - lastCall >= delay) {
                lastCall = now;
                return func(...args);
            }
        };
    },


    refreshTags: async function () {
        //let body = "{\"query\":\"{tags(story:false,first:500,type:\\\"LABEL\\\"){totalCount,pageInfo{hasNextPage,hasPreviousPage,startCursor,endCursor},edges{node{id,name,publicId}}}}\"}"
        let body = {
            query: `
              query FetchTags($story: Boolean, $first: Int, $type: String, $name: String) {
                tags(story: $story, first: $first, type: $type, name: $name) {
                  totalCount
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                  }
                  edges {
                    node {
                      id
                      name
                      publicId
                    }
                  }
                }
              }
            `,
            variables: {
                story: false,
                first: 500,
                type: "LABEL",
                name: nwsapp.currentProject.id
            }
        };

        // When you need to stringify this object for a request:
        // let requestBody = JSON.stringify(body);

        const options = graphql.getGraphQLOptions(JSON.stringify(body), true);

        try {
            const response = await fetch(nws.url, options);
            const data = await response.json();
            console.log(data);

            delete public_system.library.tags;
            public_system.library.tags = {};

            console.log("all tags", data.data.tags.edges);
            for (let item of data.data.tags.edges) {

                try {
                    item.node.name = JSON.parse(item.node.name)

                } catch (err) { }

                public_system.library.tags[item.node.name.name] = item.node;
            }


            let folderFor = _tagfind.matchElements({
                "project": nwsapp.currentProject.id
            })

            if (!public_system.library.tags[nwsapp.currentProject.slug]) {
                let tag = _defaults.newFolder()
                tag.ancestor = null
                tag.name = nwsapp.currentProject.slug
                tag.role = "root"
                await _utils.createTag(tag.id)
            }

        } catch (error) {
            console.error("Error:", error);
        }
    },

    createTag: async function (tag) {

        //creates new tags to be used by folders


        /* else {
            tag = _defaults.newFolder();
            tag.ancestor = public_system.library.currentFolder.name.id;
        } */

        let requestBody = {
            query: `
            mutation createTag($data: CreateTagInput!) {
              createTag(input: $data) {
                tag {
                  id
                  name
                  publicId
                }
              }
            }
            `,
            variables: {
                data: {
                    name: tag,
                    websiteId: "nwsapp.profile.websiteId",
                    tagType: "LABEL"
                }
            }
        };

        const body = JSON.stringify(requestBody);
        const options = graphql.getGraphQLOptions(body, true); // Ensure this is defined and returns the correct options for fetch

        try {
            const response = await fetch(nws.url, options); // Make sure 'url' points to your GraphQL endpoint
            const data = await response.json();
            //console.log(data);
            return data; // Resolve the promise with the data received from the server
        } catch (error) {
            console.error("Error:", error);
            throw error; // Reject the promise with the error
        }
    },


    wordCount: function (str) {
        // Trim the input string to remove leading and trailing whitespace
        const trimmedStr = str.trim();

        // Calculate word count by splitting the string by spaces
        // and filtering out any empty strings that may result from multiple consecutive spaces
        const words = trimmedStr.length > 0 ? trimmedStr.split(/\s+/).length : 0;

        // Calculate character count by considering the length of the string without leading/trailing whitespace
        const characters = trimmedStr.length;

        // Return the result as an object
        return {
            words: words,
            characters: characters
        };
    },

    collectLibraryComponents: function (where, key) {
        // Object to hold all components by their library key
        const libraryComps = {};

        // Find all elements with a 'data-library' attribute
        const elements = $$$(where, key)

        // Iterate through the elements and organize them into the libraryComps object
        elements.forEach(el => {
            const theKey = el.getAttribute(key);
            // Check if the key already exists
            if (libraryComps[theKey]) {
                // If the key exists and it's not already an array, convert it to an array
                if (!Array.isArray(libraryComps[theKey])) {
                    libraryComps[theKey] = [libraryComps[theKey]];
                }
                // Add the current element to the array for this key
                libraryComps[theKey].push(el);
            } else {
                // If the key doesn't exist, add it with the current element as its value
                libraryComps[theKey] = [el];
            }
        });

        return libraryComps;
    },


    getPageURLClient: function (page) {

        //conso
        let ancestorSlugs = _find.getAllAncestorSlugs(page.id)
        if (publicai.active.page.properties.role == "template") {
            ancestorSlugs.pop()
            ancestorSlugs.pop()
            ancestorSlugs.push(publicai.pageData.activeEntry.slug)
        } else {
            ancestorSlugs.push(page.properties.slug)
        }

        let path = ancestorSlugs.join("/")

        //let production = nws.previewURL + nwsapp.profile.websiteAlias + "/" + path
        //let preview = nws.unCachedURL + nwsapp.profile.websiteAlias + "/" + path


        return (path)

    },



    getPageURL: function (page, activeEntry = null) {


        let agent = ""
        let agentPath = null
        let finalAgentURL = null

        //conso
        let ancestorSlugs = _find.getAllAncestorSlugs(page.id)
        if (publicai.active.page.properties.role == "template") {
            //ancestorSlugs.pop()
            //ancestorSlugs.pop()
            ancestorSlugs.push(page.properties.slug)

            if (!activeEntry) {
                ancestorSlugs.push(publicai.pageData.activeEntry.slug)
            } else {
                ancestorSlugs.push(activeEntry)
            }
            //            agent = _find.getFirstAgentPage()


            agent = _find.getFirstAgentPage(page)

            if (agent) {
                //console.log(agent)
                agentPath = _find.getAllAncestorSlugs(agent.id).join("/") || ""
            }

        } else {
            ancestorSlugs.push(page.properties.slug)
        }

        let path = ancestorSlugs.join("/")




        let production = nws.previewURL + nwsapp.profile.websiteAlias + "/" + path
        let preview = nws.unCachedURL + nwsapp.profile.websiteAlias + "/" + path
        if (agentPath) {
            finalAgentURL = nws.unCachedURL + nwsapp.profile.websiteAlias + "/" + agentPath
        }

        //finding body to create the best possible screenshot
        let bodyClass = page.content[0].classes[0].name


        let rootFolder = _find.getRoot(page.id)
        if (!rootFolder || typeof rootFolder == "undefined" || rootFolder == undefined) {
            cssID = publicai.root.pages.id
            rootFolder = publicai.pages
        } else {
            cssID = rootFolder.id
        }

        let cssObject = nwsapp.currentProject.config.css[cssID]
        let bodyEl = cssObject[bodyClass]
        let width = parseInt(bodyEl.width?.replace("px", "")) || 0
        let height = parseInt(bodyEl.height?.replace("px", "")) || 0
        let sizeAppend = ""

        if (width && height) {
            //add &viewport=300x250 to the end of the url
            sizeAppend = "&viewport=" + width + "x" + height
        }

        //console.log("body el", bodyEl)


        //let screenshot = "https://transform.nws.ai/" + encodeURIComponent(nws.screenshot + encodeURIComponent(preview) + sizeAppend + "&delay=5206") + "/compress/"
        let screenshot = "https://transform.nws.ai/" + encodeURIComponent(nws.screenshot + encodeURIComponent(preview) + sizeAppend) + "/compress/"
        //console.log("screenshot", screenshot)


        let response = {
            "production": production,
            "preview": preview,
            "agent": finalAgentURL,
            "screenshot": screenshot
        }




        return (response)

    },


    getSelectionContainer: function (selectionContainerLocation) {

        let c = selectionContainerLocation.createElement('div');
        c.style.position = "absolute"
        c.style.pointerEvents = "none"
        c.style.width = '100%';
        c.style.height = '100%';
        c.style.border = '0';
        c.style.left = "0px"
        c.style.top = "0px"
        c.style.zIndex = "1000000"
        c.classList.add("selector_container")

        return (c)

    },

    getAnswers: async function (question) {
        const response = await fetch(nws.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + token,
            },
            body: JSON.stringify({
                query: `
                    query answerEngineDocuments($question: String!) {
                        answerEngineDocuments(query: $question)
                    }
                `,
                variables: { question: question }
            })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }

        const finalResponse = await response.json();
        console.log(finalResponse);
        return finalResponse;
    },




    getDefaultDataObject: function () {

        let settingsObject = {
            "source": "sheet",
            "sheet": {
                "url": "",
                "updated": "",
                "multiTab": false,
                "tab": "Sheet1",
                "data": null,
            },
            "json": {
                "url": "",
                "updated": "",
                "data": null,
            },
            "local": {
                "updated": "",
                "data": null,
            },
        }

        return (settingsObject)

    },

    dummy_bulletsdData: function () {
        let tableData = {
            settings: _utils.getDefaultDataObject(),
            data: [],
        }

        tableData.settings.sheet.url = "https://docs.google.com/spreadsheets/d/1Mo_RVQjE1OOMhxSnqxWu3_AnQLJiqszsBZte32_vMCo/edit#gid=0"

        return (tableData)
    },

    dummy_highlightsData: function () {
        let tableData = {
            settings: _utils.getDefaultDataObject(),
            data: []
        }
        tableData.settings.sheet.url = "https://docs.google.com/spreadsheets/d/19kOE72WN8vtwfV9El-U7FAOs2lY78vpAasx3OIG2KJo/edit#gid=0"

        return (tableData)
    },


    dummy_quotesData: function () {
        let tableData = {
            settings: _utils.getDefaultDataObject(),
            data: []
        }

        tableData.settings.sheet.url = "https://docs.google.com/spreadsheets/d/1Q_a3aSmfe7SolwT9Rq_WyiGhRXGYMC1Vu55hESXh-xM/edit#gid=0"
        return (tableData)
    },
    dummy_dynamic_carouselData: function () {
        let tableData = {
            settings: _utils.getDefaultDataObject(),
            media: ["placeholder", "placeholder", "placeholder", "placeholder", "placeholder"]
        }

        return (tableData)
    },





    dummy_tabledData: function () {
        let tableData = {
            settings: _utils.getDefaultDataObject(),
            data: []
        }

        tableData.settings.sheet.url = "https://docs.google.com/spreadsheets/d/17XA-SS5bdAdBuygFLa-5kaTDMFMzakxEN5KVQoTUetc/edit#gid=1752105069"
        return (tableData)



    },

    getImageProperties: function (url) {
        return new Promise((resolve, reject) => {
            // Create a new Image object
            const img = new Image();

            // Set up event listeners to handle successful loading or an error
            img.onload = function () {
                // Calculate the aspect ratio
                const aspectRatio = img.width / img.height;

                // Create the response object
                const response = {
                    width: img.width,
                    height: img.height,
                    aspectRatio: aspectRatio
                };

                // Resolve the promise with the response object
                resolve(response);
            };



            img.onerror = function () {
                reject(new Error('Unable to load image.'));
            };

            // Set the image source to the provided URL
            img.src = url;
            //console.log("have loaded", img)
        });
    },

    fileInfo: function (url) {
        // Extract the part of the URL after the last slash
        const lastSlashIndex = url.lastIndexOf('/');
        const fileNameWithExtension = url.slice(lastSlashIndex + 1);

        // Extract the extension
        const lastDotIndex = fileNameWithExtension.lastIndexOf('.');
        const fileName = fileNameWithExtension.slice(0, lastDotIndex);
        const extension = fileNameWithExtension.slice(lastDotIndex + 1);

        return {
            fileName,
            extension
        };
    },


    activateToolsPanel: function (status) {
        //check if user has rights to edit first!

        if (!window.editor.ui) {
            return
        }

        if (status) {
            editor.ui.sidePanel.classList.add("active")
        } else {
            editor.ui.sidePanel.classList.remove("active")
        }
    },


    setUpEditor: async function () {


        let animator_edit = document.querySelector(".animator_edit")

        /*  if (!animator_edit) {
             //first time load
             removeAllChildNodes(document.body)
             
             animator_edit = addWhatWhere(components.ui.animator_edit, document.body, "", "")
         } */
        await css_management.uxservice() //adds client styles

        window.editor.ui = {
            sidePanel: document.querySelector(".side_panel_main"),
            pageName: $$$(document, "page-nav", "name"),
            pageSettings: $$$(document, "page-nav", "settings"),
            collectionEntry: $$$(document, "page-nav", "entry"),
            schemasSelection: $$$(document, "page-nav", "schemas"),
            editButton: $$$(document, "page-nav", "edit"),
            previewPage: $$$(document, "page-nav", "preview"),
            runPage: $$$(document, "page-nav", "run_page"),
        }
        _utils.activateToolsPanel(false)


        editor.ui.runPage.onclick = async () => {
            _agents.runPage()
        }

        editor.ui.pageLabel = editor.ui.pageName.querySelector(".nav_label")
        editor.ui.pageName.classList.add("is_preset")
        editor.ui.collectionLabel = editor.ui.collectionEntry.querySelector(".nav_label")
        editor.ui.buttonIcon = editor.ui.collectionEntry.querySelector(".materialicon") //the collection item icon
        editor.ui.editLabel = editor.ui.editButton.querySelector(".nav_label")
        editor.ui.editIcon = editor.ui.editButton.querySelector(".materialicon") //the collection item icon

    },




    pageIsLoading: function (status) {

        if (status) {
            publicai.iframeContainer.style.display = "none"
        } else {
            publicai.iframeContainer.style.display = "flex"
        }
    },


    extractClientComponents: async function () {

        console.error("extract client components.....")

        publicai.componentsPage = _find.pageByRole(publicai.pages, "components")
        let rawHTML = publicai.componentsPage.content[0].content[0].raw
        const parser = new DOMParser();
        const doc = parser.parseFromString(rawHTML, 'text/html');
        let raw = doc.body.firstChild; // This is your DOM element

        //only run once
        components.system_wide = { ...await _utils.extractSystemComponents(raw, "public_component") }

        let componentsCSS = nwsapp.currentProject.config.css[_find.pageByRole(publicai.pages, "components").ancestor]

        for (let key of Object.keys(componentsCSS)) {
            if (!publicai.css[key]) {
                publicai.css[key] = componentsCSS[key]
            }
        }
        applyCSS()

    },

    extractSystemComponentsNativeBasic: function (html, attribute) {

        let ux = {}
        let items = $$$(html, attribute)

        //console.log("all ux comps", items)

        for (let item of items) {

            let newEntry = {}
            let id = item.getAttribute(attribute)
            //newEntry.el = item.cloneNode(true)
            //newEntry.settings = null
            ux[id] = item.cloneNode(true)
            //ux[item].removeAttribute("public-reuse")
            item.remove()

        }
        return (ux)

    },

    extractSystemComponentsNative: function (html, attribute) {

        let ux = {}
        let items = $$$(html, attribute)

        //console.log("all ux comps", items)

        for (let item of items) {

            let newEntry = {}
            let id = item.getAttribute(attribute)
            newEntry.el = item.cloneNode(true)
            newEntry.settings = null
            ux[id] = newEntry
            //ux[item].removeAttribute("public-reuse")
            item.remove()

        }
        return (ux)

    },


    extractSystemComponents: function (html, attribute) {


        let ux = {}
        let items = $$$(html, attribute)

        //console.log("all ux comps", items)

        for (let item of items) {

            //console.log("item", item)

            let newEntry = {}
            let id = item.getAttribute(attribute)
            newEntry.el = item.cloneNode(true)
            //newEntry.settings = _find.elementByID(publicai.pages, id)
            ux[id] = newEntry
            //ux[item].removeAttribute("public-reuse")
            item.remove()

        }
        return (ux)

    },



    getComponents: function (html, attribute, clone = false) {

        let ux = {}
        let items = $$$(html, attribute)

        //_find.elementByAttribute(public_system.active.prompt.page, "public-role", "conversation")

        //console.log("all ux comps", items)

        for (let item of items) {
            let att = item.getAttribute(attribute)
            ux[att] = item
        }
        return (ux)

    },


    extractComponentsByAttribute: function (html, attribute, clone = false) {

        let ux = {}
        let items = $$$(html, attribute)

        //_find.elementByAttribute(public_system.active.prompt.page, "public-role", "conversation")

        //console.log("all ux comps", items)

        for (let item of items) {

            let newEntry = {}
            let id = item.getAttribute("public_id")
            let att = item.getAttribute(attribute)
            if (clone) {
                newEntry.el = item.cloneNode(true)
                item.remove()
            } else {
                newEntry.el = item
            }

            newEntry.settings = _find.elementByID(publicai.pages, id)
            ux[att] = newEntry
            //ux[item].removeAttribute("public-reuse")

        }
        return (ux)

    },


    extractComponentsByAttributeNative: function (html, attribute, clone = false) {

        let ux = {}
        let items = $$$(html, attribute)

        //_find.elementByAttribute(public_system.active.prompt.page, "public-role", "conversation")

        //console.log("all ux comps", items)

        for (let item of items) {

            let newEntry = {}
            let id = item.getAttribute("public_id")
            let att = item.getAttribute(attribute)
            if (clone) {
                newEntry.el = item.cloneNode(true)
                item.remove()
            } else {
                newEntry.el = item
            }

            //newEntry.settings = _find.elementByID(publicai.pages, id)
            ux[att] = newEntry
            //ux[item].removeAttribute("public-reuse")

        }
        return (ux)

    },



    extractComponents: function (data, attribute) {

        let ux = {}

        for (let item of data) {

            let newEntry = {}
            let id = item.getAttribute(attribute)
            newEntry.el = item.cloneNode(true)
            newEntry.settings = _find.elementByID(publicai.pages, id)
            ux[id] = newEntry
            //ux[item].removeAttribute("public-reuse")
            item.remove()

        }

        return (ux)

    },

    convertObjectsToSheetFormat: function (objects) {
        if (objects.length === 0) return '';

        // Extract headers from the first object's keys
        const headers = Object.keys(objects[0]);

        // Create a header row string
        const headerRow = headers.join('\t');

        // Map each object in the array to a row string
        const rows = objects.map(obj => {
            // Map each key in headers to its corresponding value in the object
            return headers.map(header => {
                // Ensure undefined values are converted to empty strings
                return obj[header] == null ? '' : obj[header];
            }).join('\t'); // Join values with a tab separator
        });

        // Join all rows with a newline character, starting with the header row
        return [headerRow].concat(rows).join('\n');
    },




    retrieveFeed: async function (sheetsURL) {
        //const sheetsURL = nwsapp.currentProject.config.feeds.feed.link

        return new Promise(async (resolve, reject) => {

            if (!isValidGoogleSheetsURL(sheetsURL)) {
                /*  notifications.show([
                     ["Oops.", "That doesn't look like a valid Google Sheets URL."]
                 ]) */
                reject("invalid google sheets url")
            }

            try {
                const response = await fetch(`https://datafeeds.nws.ai/?sheetsURL=${encodeURIComponent(sheetsURL)}`);
                const result = await response.json();

                if (response.ok) {

                    let flat = []
                    result.forEach((sheet) => {
                        let s = JSON.parse(JSON.stringify(sheet))
                        s.data = _utils.getObjectFromSheet(sheet.data)
                        flat.push(s)
                    })

                    let processed = {
                        kv: getObject(result),
                        flat: flat
                    }

                    resolve(processed)


                } else {
                    console.error("Error:", result.error);
                }
            } catch (error) {

                console.error("Fetch error:", error);
                reject(error)
            }
        });


        function isValidGoogleSheetsURL(linkField) {
            const googleSheetsURLPattern = /^https:\/\/docs\.google\.com\/spreadsheets\/d\/[a-zA-Z0-9-_]+(?:\/edit)?(?:\/.*)?$/

            return googleSheetsURLPattern.test(linkField);
        }

        function getObject(sheetData) {
            console.log("sheet data", sheetData)
            // Extract the first row as headers

            let data = []
            for (let sheet of sheetData) {

                const headers = sheet.data[0];
                // Map the rest of the rows into objects
                const entries = sheet.data.slice(1).map(row => {
                    let entry = {};
                    headers.forEach((header, index) => {
                        entry[header] = row[index];
                    });
                    return entry;
                });
                sheet.data = entries
            }

            //console.log("sheetData", sheetData)
            return sheetData;
        }


    },

    getObjectFromSheet: function (sheetData) {
        // Extract the first row as headers (if needed for other references)
        const headers = sheetData[0];
        // Map the rest of the rows into arrays of values, maintaining their order
        const entries = sheetData.slice(1).map(row => {
            // Directly return the row, which is already an array of values in order
            return row;
        });

        return entries;
    },



    createFirstPages: function () {
        let root = nwsapp.currentProject.config.root
        if (!nwsapp.currentProject.config.root.pages.homepage) {
            //first time load
            let newPage = _defaults.blankPage(root.pages.id)
            newPage.ancestor = root.pages.id
            root.pages.homepage = newPage.id
            root.pages.content.push(newPage)
            _pages.selected = newPage.id
        }


        if (!root.pages.components) {

            let componentsFolder = _defaults.folder(root.pages.id)
            componentsFolder.ancestor = root.pages.id
            //componentsFolder.role = "components"
            componentsFolder.properties.name = "Components"
            componentsFolder.properties.slug = "Components"
            componentsFolder.permissions.delete = false
            root.pages.content.push(componentsFolder)


            let componentsPage = _defaults.blankPage(root.pages.id)
            componentsPage.ancestor = componentsFolder.id
            componentsPage.role = "components"
            componentsPage.properties.name = "Components"
            componentsPage.properties.slug = "Components"
            componentsPage.permissions.delete = false
            root.pages.components = {
                page: componentsPage.id,
                folder: componentsFolder.id
            }
            componentsFolder.content.push(componentsPage)

            nwsapp.currentProject.config.css[componentsFolder.id] = JSON.parse(JSON.stringify(nwsapp.currentProject.config.root.definitions.classes))

        }



        let agentsPage = nwsapp.currentProject.config.root.pages.content.find(x => x.properties.role === "agents")
        if (!agentsPage) {
            agentsPage = _defaults.agentsPage()
            agentsPage.ancestor = root.pages.id
            agentsPage.properties.name = "Agents"
            agentsPage.properties.slug = "Agents"
            agentsPage.properties.description = "Agents"
            nwsapp.currentProject.config.root.pages.content.push(agentsPage)
        }


        let page404 = nwsapp.currentProject.config.root.pages.content.find(x => x.properties.role === "404")
        if (!page404) {
            page404 = _defaults.page404()
            page404.ancestor = root.pages.id
            page404.properties.name = "404"
            page404.properties.slug = "404"
            page404.properties.description = "404 Page not found."
            nwsapp.currentProject.config.root.pages.content.push(page404)
        }
    },


    page404: function () {

        //not working

        let page404 = nwsapp.currentProject.config.root.pages.content.find(x => x.properties.role === "404")
        window.openEditorPath("404/");
        //to debug
    },

    allowTextSelection: function (newDiv, addOrRemove) {

        if (!nws.isPlayer) {

            if (!addOrRemove) {
                newDiv.style.webkitUserSelect = 'none';
                newDiv.style.mozUserSelect = 'none';
                newDiv.style.msUserSelect = 'none';
                newDiv.style.userSelect = 'none';
                newDiv.style.cursor = 'default';


            } else {
                newDiv.style.webkitUserSelect = 'text';
                newDiv.style.mozUserSelect = 'text';
                newDiv.style.msUserSelect = 'text';
                newDiv.style.userSelect = 'text';
                newDiv.style.cursor = 'text';
            }
        }
    },

    addDialogue: function (settings) {


        let dialogue = addWhatWhere(components.ui.ai_confirmations, settings.location)

        let el = {
            container: $$$(dialogue, "confirmation", "container"),
            label: $$$(dialogue, "confirmation", "label"),
        }

        el.label.textContent = settings.label

        removeAllChildNodes(el.container)

        for (let item of settings.items) {
            let itemUnit = addWhatWhere(components.ui.confirm_item, el.container)

            let label = $$$(itemUnit, "item", "label")
            label.textContent = item.label

            itemUnit.onclick = event => {
                event.stopPropagation()
                event.preventDefault()
                item.callback(dialogue)
            }
        }

        return (dialogue)




    },

    animatorControls: function () {



    },
    createSizesOptions: function () {


        let sizes = {
            "vertical": ["1280x720", "300x600", "160x600"],
            "square": ["300x250", "1080x1080", "250x250", "200x200", "180x180"],
            "horizontal": ["728x90", "970x250", "1080x1920"]
        }

        for (let format of Object.keys(sizes)) {

            let unit = $$$(document, "sizeunit", format)

            let sizesContainer = $$$(unit, "holder", "sizes")
            removeAllChildNodes(sizesContainer)

            for (let size of sizes[format]) {
                let s = addWhatWhere(components.ui.sizes_unit, sizesContainer)
                $$$(s, "thesize", "label").textContent = size
            }

        }


    },

    showHideLayerControls: function (showOrHide) {


        let el = [
            $$$(document, "animator", "delete_selected"),
        ]
        for (let item of el) {
            if (showOrHide) {
                item.classList.remove("invisible")
            } else {
                item.classList.add("invisible")
            }
        }

    },


    handlePlusMinusControls: function () {

        //unused

        let el = {
            zoom_out: $$$(document, "animator", "zoom_out"),
            zoom_in: $$$(document, "animator", "zoom_in"),
            zoom_label: $$$(document, "animator", "zoom_label"),
        }

        //el.zoom_label.setAttribute("contenteditable", true)


    },

    closeAnimator: function () {
        let backButton = $$$(document, "maineditor", "back")
        backButton.classList.remove("active")
        render.addClassesToAllChildrenOf(document.querySelector(".main_project_native"), "animator", false)
        let animatorContent = document.querySelector(".animator_content")
        removeAllChildNodes(animatorContent)
    },

    createTooltip: function (el, value, position) {


        let container = document.querySelector(".animator_main_screen")

        const rect = el.getBoundingClientRect();
        const topPosition = rect.top;
        const leftPosition = rect.left;
        let deltaX = 0
        let deltaY = 0

        if (container) {
            let tip = addWhatWhere(components.ui.tooltip_item, container)
            tip.classList.add("fadeInScenes")

            let theElement = $$$(tip, "thetooltip", "content")
            theElement.textContent = value

            let tipWidth = tip.getBoundingClientRect().width
            let tipHeight = tip.getBoundingClientRect().height

            let elementWidth = el.getBoundingClientRect().width

            if (position == "down") {
                deltaY = (rect.height + 10)
                tip.style.left = leftPosition + deltaX - tipWidth / 2 + 5 + "px"
                tip.style.top = topPosition + deltaY + "px"

            }
            if (position == "right") {
                tip.style.left = leftPosition - rect.width + 10 + "px"
                tip.style.top = topPosition + rect.height / 2 + "px"
            }
            if (position == "left") {

                tip.style.left = leftPosition - tipWidth - 20 + "px"
                tip.style.top = rect.top + rect.height / 2 - tipHeight / 2 + "px"
            }

            if (!position) {
                deltaY = (rect.height * -1) - 10
                tip.style.left = leftPosition + deltaX - tipWidth / 2 + 5 + el.getBoundingClientRect().width / 2 + "px"
                tip.style.top = topPosition + deltaY + "px"
            }


            let additionalTooltipStyle = el.getAttribute("tooltip-style")

            if (!additionalTooltipStyle || additionalTooltipStyle == "") {
                additionalTooltipStyle = "light"
            }

            if (additionalTooltipStyle && additionalTooltipStyle != "") {
                render.addClassesToAllChildrenOf(tip, additionalTooltipStyle, true)
            }

            if (position && position != "") {
                render.addClassesToAllChildrenOf(tip, position, true)
                tip.classList.add(position)
            }
        }
    },

    removeTooltip: function (el, value) {
        let tooltips = document.querySelectorAll(".tooltip_item")
        if (tooltips.length > 0) {
            for (let tooltip of tooltips) {
                tooltip.remove()
            }
        }

    },

    makeConsecutiveZindexes: function () {
        // Create an array from the timeline items
        let itemsArray = Object.values(publicai.active.timeline);

        // Sort the array based on current zIndex values
        itemsArray.sort((a, b) => a.zIndex - b.zIndex);

        // Reassign zIndex values starting from 1
        itemsArray.forEach((item, index) => {
            item.zIndex = index + 1;
        });
    },


    normaliseMedia: function (el) {

        let newEl = { ...el }

        if (newEl.type) {
            newEl.mediaType = newEl.type
        }

        if (newEl.mediaType) {
            newEl.type = newEl.mediaType
        }



        newEl.sizes = {
            preview: newEl.previewUrl,
            small: newEl.url,
            medium: newEl.url,
            large: newEl.url
        }

        return (newEl)

    },




    videoControlsAndLogging: function (controls, video, block, assetId, progressBarColor) {

        if (!controls) {
            return
        }

        console.log(controls)

        let video_progress_bar = controls.progress.el
        let audioStatusButton = controls.sound.el
        let pauseButton = controls.play.el
        let progress_label = controls.label?.el || null


        //console.log("adding progress bar", video_progress_bar)

        //equivalent of setUpVideoProgressBar for natives. To check


        //console.log("setUpVideoProgressBar", assetId)
        block.videoEvents = {
            "event0": false,
            "event25": false,
            "event50": false,
            "event75": false,
            "event100": false,
        }

        //this is used in block media (not side media!). For side-media, look up manageBlockMedia:

        video_progress_bar.style.maxWidth = "0%"


        theCurrentVideo = video

        video.onclick = event => {
            console.log("pause!", block)

            if (block?.media?.settings?.clickURL) {

                openExternalURL({
                    "targetwindow": "new_window",
                    "targetURL": block.media.settings.clickURL
                })


            } else {

                if (!video.paused) {

                    if (nws.isPlayer) {
                        nws.reporting.log({
                            "event_type": "pause",
                            "project_id": nwsapp.currentProject.id,
                            "event_value": 0,
                            "event_number": 0,
                            "unit_id": 0,
                            "website_id": nwsapp.currentProject.websiteId,
                            "publisher_id": nwsapp.currentProject.publisherId,
                            "referrer": bestReferrer(),

                        }, "impressionLog")
                    }


                    //playPauseLabel.textContent = "play_arrow"
                    video.pause()
                } else {
                    //playPauseLabel.textContent = "pause"
                    video.play()

                    if (nws.isPlayer) {
                        nws.reporting.log({
                            "event_type": "play",
                            "project_id": nwsapp.currentProject.id,
                            "event_value": 0,
                            "event_number": 0,
                            "unit_id": 0,
                            "website_id": nwsapp.currentProject.websiteId,
                            "publisher_id": nwsapp.currentProject.publisherId,
                            "referrer": bestReferrer(),

                        }, "impressionLog")
                    }
                }
            }
        }
        video.onended = (event) => {
            //console.log("test ended") //on ended doesn't work on hls need to use the time detection below
        }


        video.crossOrigin = "anonymous";


        let frameId;
        let previousTime = null;


        video.ontimeupdate = (event) => {
            updateSceneElementsBasedOnTime(video.currentTime)
            let thePercentage = video.currentTime * 100 / video.duration;
            let p = Math.round(thePercentage);

            //console.log("thePercentage", thePercentage)

            if (p > 0 && !block.videoEvents.event0) {
                block.videoEvents.event0 = true

                if (nws.isPlayer) {
                    nws.reporting.log({
                        "event_type": "video_0",
                        "project_id": nwsapp.currentProject.id,
                        "event_value": assetId,
                        "event_number": 0,
                        "website_id": nwsapp.currentProject.websiteId,
                        "publisher_id": nwsapp.currentProject.publisherId,
                        "unit_id": 0,
                        "referrer": bestReferrer(),

                    }, "impressionLog")
                }
            }

            if (p > 24 && !block.videoEvents.event25) {
                block.videoEvents.event25 = true

                if (nws.isPlayer) {
                    nws.reporting.log({
                        "event_type": "video_25",
                        "project_id": nwsapp.currentProject.id,
                        "event_value": assetId,
                        "event_number": 0,
                        "website_id": nwsapp.currentProject.websiteId,
                        "publisher_id": nwsapp.currentProject.publisherId,
                        "unit_id": 0,
                        "referrer": bestReferrer(),

                    }, "impressionLog")
                }
            }

            if (p > 50 && !block.videoEvents.event50) {
                block.videoEvents.event50 = true

                if (nws.isPlayer) {
                    nws.reporting.log({
                        "event_type": "video_50",
                        "project_id": nwsapp.currentProject.id,
                        "event_value": assetId,
                        "event_number": 0,
                        "website_id": nwsapp.currentProject.websiteId,
                        "publisher_id": nwsapp.currentProject.publisherId,
                        "unit_id": 0,
                        "referrer": bestReferrer(),

                    }, "impressionLog")
                }
            }

            if (p > 75 && !block.videoEvents.event75) {
                block.videoEvents.event75 = true

                if (nws.isPlayer) {
                    nws.reporting.log({
                        "event_type": "video_75",
                        "project_id": nwsapp.currentProject.id,
                        "event_value": assetId,
                        "event_number": 0,
                        "website_id": nwsapp.currentProject.websiteId,
                        "publisher_id": nwsapp.currentProject.publisherId,
                        "unit_id": 0,
                        "referrer": bestReferrer(),

                    }, "impressionLog")
                }
            }

            if (p > 98 && !block.videoEvents.event100) {
                block.videoEvents.event100 = true
                if (nws.isPlayer) {
                    nws.reporting.log({
                        "event_type": "video_100",
                        "project_id": nwsapp.currentProject.id,
                        "event_value": assetId,
                        "event_number": 0,
                        "website_id": nwsapp.currentProject.websiteId,
                        "publisher_id": nwsapp.currentProject.publisherId,
                        "unit_id": 0,
                        "referrer": bestReferrer(),

                    }, "impressionLog")
                }
            }


            let currentProgress = 0;

            const animateProgressBar = () => {

                let p = thePercentage
                if (p > 92) {
                    p = 100
                }
                if (p < 6) {
                    p = 0
                }
                //this hack allows the bar to visilby reach the edges. Otherwise, it looks like the video never finishes.


                video_progress_bar.style.maxWidth = p + "%";
                frameId = requestAnimationFrame(animateProgressBar);
            };

            animateProgressBar();


            let roundedCurrentTime = Math.floor(video.currentTime);

            if (roundedCurrentTime !== previousTime) {
                //progress_label.textContent = fixProgressTime(video.currentTime);
                previousTime = roundedCurrentTime;
            }

            //progress_label.textContent = ""

        };

        function fixProgressTime(progress) {
            let theTime = Math.round(video.currentTime)
            if (theTime < 60) {
                if (theTime < 10) {
                    theTime = "0" + theTime
                }
                return ("0:" + theTime)
            }
            if (theTime > 60) {

                let minutes = Math.floor(theTime / 60);
                let seconds = theTime - minutes * 60;
                if (seconds < 10) {
                    seconds = "0" + seconds
                }
                return (minutes + ":" + seconds)
            }
        }

        if (block.hasSound) {
            audioStatusButton.classList.add("active")
            operateAudioOnOffButton(audioStatusButton, video)
        } else {
            audioStatusButton.classList.remove("active")
        }

    },

    getSeconds: function (ms) {

        let seconds = ms / 1000;
        // Return seconds with 2 decimal places
        return seconds.toFixed(2);

    },

    getFrames: function (ms) {

        let seconds = ms / 1000;
        let frames = seconds * 24
        return Math.round(frames);

    },

    updateTotalTime: function (node) {
        let maxTime = 0;

        // Start with the current node
        let currentNode = node;

        // Traverse through the ancestors
        while (currentNode) {

            //console.log(currentNode.classes[0].name, currentNode.settings)

            if (currentNode.settings?.timing?.end > maxTime) {
                maxTime = currentNode.settings.timing.end;
            }

            // Move up to the ancestor node
            if (currentNode.ancestor) {
                currentNode = _find.elementByID(publicai.pages, currentNode.ancestor);
            } else {
                currentNode = null;  // No more ancestors, exit the loop
            }
        }

        // Return the highest end time found in the ancestors
        return maxTime;
    },

    addStyleshetToPage: function (css) {

        // Create a new <style> element
        let style = document.createElement("style");
        style.type = "text/css";

        // Add the CSS string to the <style> element
        if (style.styleSheet) {
            // This is required for IE
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }

        // Append the <style> element to the <head>
        document.head.appendChild(style);



    },


    addMediaTo: function (el, mediaContainer, addProgressBar = true) {

        //let animations = nwsapp.currentProject.config.content[nwsapp.activeVariation].animations
        let effects = null
        let progressContainer = document.querySelector(".media_progress_unit")
        try {
            effects = nwsapp.currentVariation.effects[nwsapp.currentSettings.mode][nwsapp.currentSettings.style][nwsapp.currentSettings.size].media
        } catch (err) { }

        //adds images or videos to the media container
        el = nativemedia.normaliseMedia(el)

        let objectToAnimate = document.querySelector(".native_media_1")



        if (el && el.mediaType?.toLowerCase() == "image") {
            let img = addImageTo(el.source.large, mediaContainer, "100%", "100%")
            if (effects) {
                if (effects.mode != "none") {
                    let settings = effects.settings
                    //applyAnimationStyles(img, settings)
                    applyAnimationStyles(objectToAnimate, settings)
                }
            }
            img.classList.add("swiper-slide")
            removeAllChildNodes(progressContainer)
            return (img)

        }
        if (el && el.mediaType?.toLowerCase() == "video") {
            let srcMP4 = el.video.original
            let poster = el.source.medium
            let video = addVideoTo(srcMP4, poster, mediaContainer, "100%", "100%")

            //console.log("have added video ", video, srcMP4, poster, mediaContainer,  el.video)

            //video.classList.add("swiper-slide")

            //let videoControls = render.add(components.user.video_controls, progressContainer)
            //videoControls.classList.add("active")
            //setUpVideoProgressBar(videoControls, video, null, s.content, 123)
            return (video)
        } else {
            removeAllChildNodes(progressContainer)
        }
    },




    getBestZIndex: function (type) {
        let highestMediaZIndex = 0; // Start from 0, assuming no negative zIndices
        let lowestTextZIndex; // Initialized when a text item is found

        for (let itemId in publicai.active.timeline) {
            let item = publicai.active.timeline[itemId];
            if (item.type === 'media') {
                highestMediaZIndex = Math.max(highestMediaZIndex, item.zIndex);
            } else if (item.type === 'text') {
                // Initialize lowestTextZIndex to the zIndex of the first text item found
                if (lowestTextZIndex === undefined) {
                    lowestTextZIndex = item.zIndex;
                } else {
                    lowestTextZIndex = Math.min(lowestTextZIndex, item.zIndex);
                }
            }
        }

        if (type === 'media') {
            if (lowestTextZIndex !== undefined) {
                // Ensure media zIndex is above all media but below text elements
                return Math.min(highestMediaZIndex + 1, lowestTextZIndex - 1);
            } else {
                // If no text elements, just above all media
                return highestMediaZIndex + 1;
            }
        } else if (type === 'text') {
            // If lowestTextZIndex is not defined, it means there are no text items yet
            return lowestTextZIndex !== undefined ? lowestTextZIndex + 1 : highestMediaZIndex + 1;
        }

        // Optionally handle other types or return a default value
    },





    getActiveElementsForTime: function (theTime) {
        let timelineObject = publicai.active.timeline;
        let activeElements = [];

        for (const itemId in timelineObject) {
            if (timelineObject.hasOwnProperty(itemId)) {
                const item = timelineObject[itemId];

                if (item.timing && theTime >= item.timing.start && theTime <= item.timing.end) {
                    let opacity = 1;

                    // Calculate opacity based on animateIn and animateOut
                    const animationStart = item.timing.start;
                    const animationEnd = item.timing.end;
                    const animateInDuration = item.animation?.animateIn?.duration || 0;
                    const animateOutDuration = item.animation?.animateOut?.duration || 0;
                    const animateInEnd = animationStart + animateInDuration;
                    const animateOutStart = animationEnd - animateOutDuration;

                    // If within animateIn period, adjust opacity
                    if (theTime < animateInEnd) {
                        opacity = (theTime - animationStart) / animateInDuration;
                    }

                    // If within animateOut period, adjust opacity
                    if (theTime > animateOutStart) {
                        opacity = (animationEnd - theTime) / animateOutDuration;
                    }

                    // Ensure opacity is within 0 to 1 range
                    opacity = Math.max(0, Math.min(opacity, 1));

                    // Add the item with modified opacity to the array
                    if (item.type != "scene") {
                        activeElements.push({ item: item, opacity: opacity });
                    }
                }
            }
        }

        return activeElements;
    },




    findItemWithMaxZIndex: function () {
        let timelineObject = publicai.active.timeline;
        let itemWithMaxZIndex = null;
        let maxZIndex = -Infinity; // Initialize with the lowest possible value

        for (const itemId in timelineObject) {
            if (timelineObject.hasOwnProperty(itemId)) {
                const item = timelineObject[itemId];
                if (item.zIndex > maxZIndex) {
                    maxZIndex = item.zIndex;
                    itemWithMaxZIndex = item;
                }
            }
        }

        return itemWithMaxZIndex;
    },



    findFirstItemByStartTime: function () {

        let timelineObject = publicai.timeline.content
        let firstItem = null;
        let earliestStartTime = Infinity;

        for (const itemId in timelineObject) {
            if (timelineObject.hasOwnProperty(itemId)) {
                const item = timelineObject[itemId];
                if (item.settings?.animation?.timing && item.settings.animation.timing.start < earliestStartTime) {
                    earliestStartTime = item.settings.animation.timing.start;
                    firstItem = item;
                }
            }
        }

        return firstItem;
    },

    findLastItemByEndTime: function (type) {



        let timelineObject = publicai.selected.settings.content;
        //let timelineObject = publicai.timeline.content


        let lastItem = null;
        let latestEndTime = 0; // Initialize with the smallest possible number

        for (let item of timelineObject) {


            // Check if item has a timing property and if a type is specified, check for type match
            if (item.settings.timing && item.settings.timing.end >= latestEndTime && (!type || item.type === type)) {
                latestEndTime = item.settings.timing.end;
                lastItem = item;
            }
        }

        return lastItem;
    },

    findLastKeyframeItem: function (type) {

        if (nwsapp.video) {

            //console.log("HACK FOR VIDEO. To resolve!")

            return ({
                end: 10000
            })
        }

        let timelineObject = publicai.keyframesTimeline.settings;
        //let timelineObject = publicai.timeline.content


        let lastItem = null;
        let latestEndTime = -Infinity; // Initialize with the smallest possible number

        for (const item of Object.values(timelineObject)) {
            // Check if item has a timing property and if a type is specified, check for type match
            if (item.end > latestEndTime) {
                latestEndTime = item.end;
                lastItem = item;
            }
        }
        return lastItem;
    },





    timelineIsEmpty: function () {
        if (Object.keys(publicai.active.timeline).length == 0) {
            return (true)
        } else {
            return (false)
        }
    },



    timelineSizeDropdown: function () {

        return

        let temp = {}

        let timelineSettings = [
            {
                "name": "Normal",
                "setting": 10
            },
            {
                "name": "Large",
                "setting": 5
            },
            {
                "name": "Small",
                "setting": 20
            }
        ]

        dropdowns.addSimpleDropDown(
            {
                "selector": $$$(document, "animator", "d1"),
                "defaultValue": "Normal Size",
                "defaultLabel": "",
                "dropdownStyle": "rounded",
                "options": timelineSettings,
                "storageObject": temp,
                "storageVariable": "style",
                "additionalStyling": "small",
                "type": "text",
                "direction": "up",
                "callback": addNewItem
            }
        )

        function addNewItem(settings) {
            //_defaults.addNewItem(settings.setting)
            publicai.timelineConstant = settings.setting
            animator_timeline.refreshTimeline()


        }

    },

    activateInput: function (s) {
        s.input.value = s.storage[s.variable]

        s.input.addEventListener('blur', (event) => {
            s.storage[s.variable] = event.target.value
        })

        s.input.addEventListener('input', (event) => {
            s.storage[s.variable] = event.target.value
            if (s.element) {
                s.element.textContent = event.target.value
            }
            if (s.makeInactiveIfEmpty) {
                checkIfEmpty(event.target.value, s.element)
            }

        })


        if (s.contentEditable && s.element) {
            s.element.setAttribute("contenteditable", "true")
            s.element.addEventListener('input', (e) => {
                s.storage[s.variable] = s.element.textContent
                if (s.input) {
                    s.input.value = s.element.textContent
                }
            })
        }
    },





    _old_timelineResize: function () {
        let minHeight = 120;
        let handler = nwsapp.ux.timeline_resize;
        let element = document.querySelector(".animator_timeline");
        let element2 = $$$(document, "timeline_el", "animator")

        let startY, startHeight;

        handler.addEventListener('mousedown', function (e) {
            // Capture the starting point and initial height
            _create.deselectAllElements()
            let contentHolder = document.querySelector(".thecontent_holder")
            contentHolder.style.pointerEvents = "none"




            startY = e.clientY;
            startHeight = parseInt(window.getComputedStyle(element).height, 10);

            // Add event listeners to track dragging
            document.addEventListener('mousemove', resize);
            document.addEventListener('mouseup', stopResize);
            document.addEventListener('mouseleave', stopResize);  // To handle mouse leaving the window
        });

        function resize(e) {
            // Reverse the direction: moving mouse down decreases height, up increases it
            let newHeight = startHeight - (e.clientY - startY);

            // Ensure the new height does not go below the minimum height
            if (newHeight > minHeight) {
                element.style.height = element2.style.height = `${newHeight}px`;
                element.style.minHeight = element2.style.minHeight = `${newHeight}px`;
            } else {
                stopResize()
            }
        }

        function stopResize() {
            // Remove the event listeners when the resizing stops
            let contentHolder = document.querySelector(".thecontent_holder")
            contentHolder.style.pointerEvents = "auto"
            document.removeEventListener('mousemove', resize);
            document.removeEventListener('mouseup', stopResize);
            document.removeEventListener('mouseleave', stopResize);
        }
    },



    openCloseTimeline: function (status, refresh = false) {

        //console.log("status", status)
        let timeline = document.querySelector(".animator_timeline")
        let bottom_bar = document.querySelector(".bottom_bar")
        publicai.timelineIsActive = status

        timeline.onmouseover = event => {
            publicai.timelineIsHovering = true
        }

        timeline.onmouseout = event => {
            publicai.timelineIsHovering = false
        }



        if (status == false) {
            render.addClassesToAllChildrenOf(timeline, "timelineactive", false)
            render.addClassesToAllChildrenOf(bottom_bar, "timelineactive", false)
        } else {
            render.addClassesToAllChildrenOf(timeline, "timelineactive", true)
            render.addClassesToAllChildrenOf(bottom_bar, "timelineactive", true)
        }

        //animator_timeline.enabled = status



    },


    getAllCoordinates: function () {
        let items = []
        for (let item of Object.keys(publicai.active.timeline)) {

            let entry = publicai.active.timeline[item]

            let obj = {}
            obj.position = entry.position
            obj.type = entry.type
            obj.size = entry.size
            obj.content = entry.content
            items.push(obj)
        }
        let summary = {
            "content": items,
            "width": 300,
            "height": 600,
            "font-size": "25px",

        }
        return (summary)
    },


    addStylesheet: function (src) {
        var head = nwsapp.activeDocument.getElementsByTagName('head')[0];
        var link = nwsapp.activeDocument.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = src
        link.media = 'all';
        head.appendChild(link);
    },

    findLowestPoint: function (inViewElements, type) {
        //used to determine the lowest point where a new element can be added
        let lowestPoint = 0;
        if (inViewElements.length == 0) {
            lowestPoint = publicai.aspects[publicai.active.aspect].initialTop //different initial top size depending on aspect
            return lowestPoint;
        }

        inViewElements.forEach(item => {
            if (item.settings.type == type) {
                //only compare with items of the same type

                // Parse the top value and convert to a number
                const topValue = parseInt(item.element.style.top, 10);
                // Get the computed style to find the height
                const style = window.getComputedStyle(item.element);
                const height = parseInt(style.height, 10);
                let heightPercentage = (height / publicai.active.bodyElement.offsetHeight) * 100
                // Calculate the bottom position
                const bottom = topValue + heightPercentage;
                // Update the lowest point if this is the new lowest
                if (bottom > lowestPoint) {
                    lowestPoint = bottom;
                }

                //determine the percentage from publicai.active.bodyElement.offsetHeight

            }
        });

        let spacer = (publicai.spaceBetweenElements / publicai.active.bodyElement.offsetHeight) * 100
        lowestPoint += spacer
        return lowestPoint;
    },


    loadExternalScripts: async function () {

        return new Promise((resolve, reject) => {
            try {
                if (publicai.pages.properties.js) {

                    let el = document.createElement("script")
                    el.src = publicai.pages.properties.js
                    el.type = "text/javascript";
                    el.addEventListener("load", function (e) {
                        resolve();
                    });
                    document.body.appendChild(el)
                    publicai.externalScript = el
                    console.log("have created", el)
                } else {
                    resolve();
                }

            } catch (error) {
                // Reject the promise if an error occurs
                reject(error);
            }
        });

    },


    getTemplateById: function (id, refresh = false) {
        // Construct the URL with the refresh parameter
        const url = new URL(`https://uxcomps.newsroom-ai9687.workers.dev/`);
        url.searchParams.append('id', id);
        if (refresh) {
            url.searchParams.append('refresh', 'true');
        }

        // Return the fetch promise
        return fetch(url.toString())
            .then(response => {
                if (!response.ok) {
                    // If the response is not ok, throw an error that will be caught in the catch block
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json(); // Parse the JSON of the response
            })
            .then(data => {
                //console.log('Template retrieved:', data);
                return data; // Return the data for the next promise in the chain
            })
            .catch(error => {
                console.error('Error fetching the template:', error);
                // It's important to throw the error again so the promise is rejected
                throw error;
            });
    },


    getFileList: async function () {
        try {
            const fileManager = new FileManager();

            let allAllowedTypes = ["stylesheet"]

            const allFonts = await fileManager.listFiles({
                type: allAllowedTypes,
            });

            console.log("allFonts", allFonts)

            return allFonts.files.stylesheet


        } catch (error) {
            console.error("Error getting file list:", error);
        }
    },



    initialiseFonts: async function () {


        publicai.fonts.user = await this.getFileList("user")

        publicai.fonts.user.forEach((el, index) => {



            let url = window.endpoints.media + el.s3Key
            console.log("user font", url, el)

            //publicai.fonts.user.push(el)
            //addStylesheet(el.node.url, "custom", el.node.id)
            //play_interactive.addStylesheet(el.node.url, "custom", el.node.id)
            //public.fonts.loaded.add(el.node.id)
        })

        console.log("FIX Fonts..")


        for (let item of nws.systemFonts) {

            //console.log(">>>>item", item)

            addStylesheet(item);
        }




        publicai.fonts.platform = []


        return

        getFonts().then(result => { //my fonts
            publicai.fonts.all = result.data.medias.edges
            let fonts = result.data.medias.edges
            nws.fontsWhitelist.forEach((e, i) => {
                e.source = "platform"
            })
            fonts.forEach((el, index) => {
                el.node.source = "user"
                //console.log("user font", el.node)
                publicai.fonts.user.push(el.node)
                //addStylesheet(el.node.url, "custom", el.node.id)
                //play_interactive.addStylesheet(el.node.url, "custom", el.node.id)
                //public.fonts.loaded.add(el.node.id)
            })
            //console.log("my fonts", nwsapp.myFonts)
        });

        //nwsapp.currentProject.config.variations.fonts

        getPlatformFonts().then(result => { // platform fonts
            let fonts = result.data.medias.edges
            publicai.fonts.allPlatform = result.data.medias.edges
            let loaded = 0
            let howManyToLoadFirst = 250
            fonts.forEach((el, index) => {
                el.node.source = "platform"
                //let franklin = publicai.fonts.all.find(el => el.node.fileName == "Franklin")
                if (isWhiteListed(el.node.id)) {
                    publicai.fonts.platform.push(el.node)
                    //find font with fileName containing neue
                    if (loaded < howManyToLoadFirst) {
                        //addStylesheet(el.node.url, "custom", el.node.id)
                        //play_interactive.addStylesheet(el.node.url, "custom", el.node.id)
                        //public.fonts.loaded.add(el.node.id)
                        loaded++
                    }
                }
            })
        });

        function isWhiteListed(id) {
            let result = false
            _utils.fontsWhitelist.forEach((el, index) => {

                if (el.id == id) {
                    result = true
                }
            })
            //result = true
            return result
        }

    },

    dummyValues: function (type, category) {

        const longFillers = [
            "In a hole in the ground there lived a hobbit. Not a nasty, dirty, wet hole, filled with the ends of worms and an oozy smell, nor yet a dry, bare, sandy hole with nothing in it to sit down on or to eat: it was a hobbit-hole, and that means comfort.",
            "It is a truth universally acknowledged, that a single man in possession of a good fortune, must be in want of a wife.",
            "It was the best of times, it was the worst of times, it was the age of wisdom, it was the age of foolishness, it was the epoch of belief, it was the epoch of incredulity, it was the season of Light, it was the season of Darkness, it was the spring of hope, it was the winter of despair.",
            "Far out in the uncharted backwaters of the unfashionable end of the Western Spiral arm of the Galaxy lies a small unregarded yellow sun. Orbiting this at a distance of roughly ninety-two million miles is an utterly insignificant little blue-green planet whose ape-descended life forms are so amazingly primitive that they still think digital watches are a pretty neat idea.",
            "Finance is not merely about making money. It's about achieving our deep goals and protecting the fruits of our labor. It's about stewardship and, therefore, about achieving the good society.",
            "The stock market, with its unpredictable ebbs and flows, serves as a stage where investors' emotions dance with the rhythm of greed and fear. It's a place where fortunes are made and lost, where dreams are shattered or realized. Yet amidst the chaos, there exists a fundamental truth: success in investing requires discipline, patience, and a deep understanding of the principles that govern the market's movements. Those who heed this truth can navigate the turbulent waters of finance with confidence, while those who ignore it risk being swept away by the currents of speculation and uncertainty.",
            "Finance, as a field of study and practice, encompasses far more than just the pursuit of wealth. At its core, finance is about the efficient allocation of resources, the management of risk, and the creation of value. It's about making informed decisions in the face of uncertainty, weighing the trade-offs between risk and return, and striving for sustainable growth and prosperity. In today's interconnected world, where markets are shaped by a myriad of factors ranging from geopolitical events to technological innovations, the need for sound financial knowledge and expertise has never been greater. By embracing the principles of finance and applying them with wisdom and foresight, individuals and organizations can navigate the complexities of the modern economy and chart a course towards financial success and stability.",
            "In the vast ocean of financial markets, where waves of information crash upon the shores of investor sentiment, it's easy to lose sight of the underlying currents that drive long-term value creation. Yet amidst the noise and confusion, there are timeless principles that serve as guiding stars for those who seek to navigate these treacherous waters. Diversification, discipline, and diligence are the watchwords of the prudent investor, while patience, perseverance, and a focus on fundamentals serve as the foundation for enduring success. By staying true to these principles, investors can weather the storms of volatility and uncertainty, and emerge stronger and wiser on the other side.",
            "The world of finance is a vast and intricate tapestry, woven together by the threads of capital, credit, and commerce. From the bustling trading floors of Wall Street to the quiet corridors of central banks, the forces of supply and demand, risk and reward, shape the contours of global markets. Yet amidst the complexity, there are simple truths that stand the test of time. The power of compounding, the importance of diversification, and the value of long-term thinking are principles that transcend market cycles and economic paradigms. By embracing these truths and applying them with discipline and prudence, investors can build wealth steadily and sustainably, and navigate the ever-changing currents of the financial world with confidence and clarity.",
            "At its heart, finance is a game of probabilities, where success is not guaranteed, but the odds can be tilted in one's favor through careful analysis and prudent decision-making. Whether it's evaluating the risk-return trade-offs of a particular investment, hedging against unforeseen market events, or allocating capital to maximize long-term growth, the principles of probability and statistics underpin every aspect of financial strategy. By understanding these principles and applying them with rigor and precision, investors can increase their chances of success in an uncertain world, and achieve their financial goals with greater confidence and clarity."

        ];


        const fillers = [
            "Unleashing Creativity: Exploring the Power of Imagination",
            "Mindfulness in Everyday Life: Cultivating Presence and Peace",
            "The Art of Communication: Building Stronger Connections",
            "Exploring Nature's Wonders: Adventures Await",
            "Healthy Living: Nurturing Body, Mind, and Spirit",
            "The Joy of Cooking: Discovering Culinary Delights",
            "Travel Tales: Wanderlust and Adventures Across the Globe",
            "Embracing Diversity: Celebrating Differences, Building Unity",
            "The Power of Music: Transforming Emotions, Soothing Souls",
            "A Journey into the Unknown: Embracing Life's Mysteries"
        ];

        const names = [
            "Ella Harmony", "Leo Courage", "Aria Light",
            "Felix Joy", "Serena Peace", "Milo Valor",
            "Luna Grace", "Oscar Hope", "Iris Faith",
            "Leo Bright", "Gemma Spark", "Hugo Quest",
            "Zara Bloom", "Axel Blaze", "Skye Dream",
            "Mia Radiance", "Kai Ocean", "Ruby Star",
            "Nolan Sky", "Lily Serene", "Jasper Sun",
            "Amber Glow", "Finn Breeze", "Daisy Shine",
            "Evan Blaze", "Ava Bliss", "Miles Zen",
            "Sofia Dawn", "Jude Light", "Ivy Moonbeam",
            "Dylan Wave", "Bella Beam", "Oliver Leaf",
            "Zoe Stream", "Ethan Forest", "Lila Ray",
            "Riley Stone", "Eve Aurora", "Liam Gale",
            "Nora Spirit"
        ];


        if (type === "bullets") {
            return (_utils.dummyDynamicData("bullets"))
        }

        if (type === "table") {
            return (_utils.dummyDynamicData("table"))
        }
        if (type === "quotes") {
            return (_utils.dummyDynamicData("quotes"))
        }
        if (type === "highlights") {
            return (_utils.dummyDynamicData("highlights"))
        }
        if (type === "dynamic_carousel") {
            return (_utils.dummy_dynamic_carouselData())
        }

        if (type === "media") {
            return ({
                "placeholder": {
                    modified: getPrettyTimeStamp(),
                    id: "placeholder"
                }
            })
        }

        if (type === "textarea") {
            //console.log("getting long text")

            //let lucky = 

            if (category === "name") {
                return names[Math.floor(Math.random() * names.length)];
            } else if (category === "filler") {
                return longFillers[Math.floor(Math.random() * longFillers.length)];
            }
        }


        if (type === "text" || type === "input") {
            if (category === "name") {
                return names[Math.floor(Math.random() * names.length)];
            } else if (category === "filler") {
                return fillers[Math.floor(Math.random() * fillers.length)];
            }
        } else if (type === "number" && category === null) {
            return Math.floor(Math.random() * 101);
        }

        return ("not a known type yet");
    },

    dummyDynamicData: function (type) {

        //used by schemas

        let data = {
            table: [
                {
                    "title": "General",
                    "data": [
                        {
                            "Country": "USA",
                            "Population (millions)": "331.4",
                            "GDP (trillion USD)": "21.43",
                            "Inflation Rate (%)": "2.5",
                            "Unemployment Rate (%)": "3.8"
                        },
                        {
                            "Country": "China",
                            "Population (millions)": "1441.7",
                            "GDP (trillion USD)": "14.34",
                            "Inflation Rate (%)": "1.8",
                            "Unemployment Rate (%)": "4.1"
                        },
                        {
                            "Country": "Japan",
                            "Population (millions)": "126.5",
                            "GDP (trillion USD)": "5.15",
                            "Inflation Rate (%)": "0.5",
                            "Unemployment Rate (%)": "2.9"
                        },
                        {
                            "Country": "Germany",
                            "Population (millions)": "83.2",
                            "GDP (trillion USD)": "4.42",
                            "Inflation Rate (%)": "1.2",
                            "Unemployment Rate (%)": "3.4"
                        },
                        {
                            "Country": "India",
                            "Population (millions)": "1393.4",
                            "GDP (trillion USD)": "3.05",
                            "Inflation Rate (%)": "5.2",
                            "Unemployment Rate (%)": "6.2"
                        },
                        {
                            "Country": "UK",
                            "Population (millions)": "68",
                            "GDP (trillion USD)": "2.94",
                            "Inflation Rate (%)": "1.6",
                            "Unemployment Rate (%)": "4.5"
                        },
                        {
                            "Country": "France",
                            "Population (millions)": "65.3",
                            "GDP (trillion USD)": "2.78",
                            "Inflation Rate (%)": "1.4",
                            "Unemployment Rate (%)": "7.9"
                        },
                        {
                            "Country": "Italy",
                            "Population (millions)": "60.4",
                            "GDP (trillion USD)": "2.08",
                            "Inflation Rate (%)": "1.3",
                            "Unemployment Rate (%)": "9.2"
                        },
                        {
                            "Country": "Brazil",
                            "Population (millions)": "213.9",
                            "GDP (trillion USD)": "1.84",
                            "Inflation Rate (%)": "2.9",
                            "Unemployment Rate (%)": "13.5"
                        },
                        {
                            "Country": "Canada",
                            "Population (millions)": "38",
                            "GDP (trillion USD)": "1.85",
                            "Inflation Rate (%)": "1.9",
                            "Unemployment Rate (%)": "6.8"
                        }
                    ]
                },
                {
                    "title": "Trade",
                    "data": [
                        {
                            "Country": "USA",
                            "Exports (billion USD)": "2.5",
                            "Imports (billion USD)": "3",
                            "Trade Balance (billion USD)": "-0.5",
                            "Trade as % of GDP": "10.5"
                        },
                        {
                            "Country": "China",
                            "Exports (billion USD)": "2.1",
                            "Imports (billion USD)": "2.4",
                            "Trade Balance (billion USD)": "-0.3",
                            "Trade as % of GDP": "18.2"
                        },
                        {
                            "Country": "Japan",
                            "Exports (billion USD)": "0.9",
                            "Imports (billion USD)": "1.1",
                            "Trade Balance (billion USD)": "-0.2",
                            "Trade as % of GDP": "17.5"
                        },
                        {
                            "Country": "Germany",
                            "Exports (billion USD)": "1.7",
                            "Imports (billion USD)": "1.9",
                            "Trade Balance (billion USD)": "-0.2",
                            "Trade as % of GDP": "43.2"
                        },
                        {
                            "Country": "India",
                            "Exports (billion USD)": "0.8",
                            "Imports (billion USD)": "1",
                            "Trade Balance (billion USD)": "-0.2",
                            "Trade as % of GDP": "22.3"
                        },
                        {
                            "Country": "UK",
                            "Exports (billion USD)": "0.7",
                            "Imports (billion USD)": "0.9",
                            "Trade Balance (billion USD)": "-0.2",
                            "Trade as % of GDP": "26.8"
                        },
                        {
                            "Country": "France",
                            "Exports (billion USD)": "0.6",
                            "Imports (billion USD)": "0.7",
                            "Trade Balance (billion USD)": "-0.1",
                            "Trade as % of GDP": "20.3"
                        },
                        {
                            "Country": "Italy",
                            "Exports (billion USD)": "0.5",
                            "Imports (billion USD)": "0.6",
                            "Trade Balance (billion USD)": "-0.1",
                            "Trade as % of GDP": "24.1"
                        },
                        {
                            "Country": "Brazil",
                            "Exports (billion USD)": "0.9",
                            "Imports (billion USD)": "1.2",
                            "Trade Balance (billion USD)": "-0.3",
                            "Trade as % of GDP": "19.5"
                        },
                        {
                            "Country": "Canada",
                            "Exports (billion USD)": "0.6",
                            "Imports (billion USD)": "0.8",
                            "Trade Balance (billion USD)": "-0.2",
                            "Trade as % of GDP": "31.6"
                        }
                    ]
                },
                {
                    "title": "Debt",
                    "data": [
                        {
                            "Country": "USA",
                            "Government Debt (% of GDP)": "110",
                            "Household Debt (% of GDP)": "80",
                            "Corporate Debt (% of GDP)": "70",
                            "Total Debt (% of GDP)": "260"
                        },
                        {
                            "Country": "China",
                            "Government Debt (% of GDP)": "50",
                            "Household Debt (% of GDP)": "40",
                            "Corporate Debt (% of GDP)": "150",
                            "Total Debt (% of GDP)": "240"
                        },
                        {
                            "Country": "Japan",
                            "Government Debt (% of GDP)": "230",
                            "Household Debt (% of GDP)": "60",
                            "Corporate Debt (% of GDP)": "120",
                            "Total Debt (% of GDP)": "410"
                        },
                        {
                            "Country": "Germany",
                            "Government Debt (% of GDP)": "70",
                            "Household Debt (% of GDP)": "60",
                            "Corporate Debt (% of GDP)": "80",
                            "Total Debt (% of GDP)": "210"
                        },
                        {
                            "Country": "India",
                            "Government Debt (% of GDP)": "70",
                            "Household Debt (% of GDP)": "20",
                            "Corporate Debt (% of GDP)": "80",
                            "Total Debt (% of GDP)": "170"
                        },
                        {
                            "Country": "UK",
                            "Government Debt (% of GDP)": "90",
                            "Household Debt (% of GDP)": "90",
                            "Corporate Debt (% of GDP)": "100",
                            "Total Debt (% of GDP)": "280"
                        },
                        {
                            "Country": "France",
                            "Government Debt (% of GDP)": "100",
                            "Household Debt (% of GDP)": "60",
                            "Corporate Debt (% of GDP)": "90",
                            "Total Debt (% of GDP)": "250"
                        },
                        {
                            "Country": "Italy",
                            "Government Debt (% of GDP)": "130",
                            "Household Debt (% of GDP)": "40",
                            "Corporate Debt (% of GDP)": "70",
                            "Total Debt (% of GDP)": "240"
                        },
                        {
                            "Country": "Brazil",
                            "Government Debt (% of GDP)": "90",
                            "Household Debt (% of GDP)": "50",
                            "Corporate Debt (% of GDP)": "90",
                            "Total Debt (% of GDP)": "230"
                        },
                        {
                            "Country": "Canada",
                            "Government Debt (% of GDP)": "90",
                            "Household Debt (% of GDP)": "100",
                            "Corporate Debt (% of GDP)": "100",
                            "Total Debt (% of GDP)": "290"
                        }
                    ]
                },
                {
                    "title": "Investment",
                    "data": [
                        {
                            "Country": "USA",
                            "Foreign Direct Investment (billion USD)": "400",
                            "Portfolio Investment (billion USD)": "800",
                            "Gross Fixed Investment (billion USD)": "3500",
                            "Investment as % of GDP": "16.3"
                        },
                        {
                            "Country": "China",
                            "Foreign Direct Investment (billion USD)": "200",
                            "Portfolio Investment (billion USD)": "500",
                            "Gross Fixed Investment (billion USD)": "2000",
                            "Investment as % of GDP": "25.6"
                        },
                        {
                            "Country": "Japan",
                            "Foreign Direct Investment (billion USD)": "100",
                            "Portfolio Investment (billion USD)": "200",
                            "Gross Fixed Investment (billion USD)": "900",
                            "Investment as % of GDP": "17.8"
                        },
                        {
                            "Country": "Germany",
                            "Foreign Direct Investment (billion USD)": "150",
                            "Portfolio Investment (billion USD)": "300",
                            "Gross Fixed Investment (billion USD)": "1200",
                            "Investment as % of GDP": "27.3"
                        },
                        {
                            "Country": "India",
                            "Foreign Direct Investment (billion USD)": "100",
                            "Portfolio Investment (billion USD)": "200",
                            "Gross Fixed Investment (billion USD)": "1000",
                            "Investment as % of GDP": "32.1"
                        },
                        {
                            "Country": "UK",
                            "Foreign Direct Investment (billion USD)": "100",
                            "Portfolio Investment (billion USD)": "300",
                            "Gross Fixed Investment (billion USD)": "1100",
                            "Investment as % of GDP": "18.7"
                        },
                        {
                            "Country": "France",
                            "Foreign Direct Investment (billion USD)": "80",
                            "Portfolio Investment (billion USD)": "200",
                            "Gross Fixed Investment (billion USD)": "800",
                            "Investment as % of GDP": "21.4"
                        },
                        {
                            "Country": "Italy",
                            "Foreign Direct Investment (billion USD)": "70",
                            "Portfolio Investment (billion USD)": "150",
                            "Gross Fixed Investment (billion USD)": "600",
                            "Investment as % of GDP": "22.9"
                        },
                        {
                            "Country": "Brazil",
                            "Foreign Direct Investment (billion USD)": "80",
                            "Portfolio Investment (billion USD)": "100",
                            "Gross Fixed Investment (billion USD)": "700",
                            "Investment as % of GDP": "18.3"
                        },
                        {
                            "Country": "Canada",
                            "Foreign Direct Investment (billion USD)": "120",
                            "Portfolio Investment (billion USD)": "200",
                            "Gross Fixed Investment (billion USD)": "800",
                            "Investment as % of GDP": "20.1"
                        }
                    ]
                }
            ],
            highlights: [
                {
                    "title": "Employment",
                    "data": [
                        [
                            "Job Openings",
                            "7.6M",
                            "Unfilled hiring positions."
                        ],
                        [
                            "Avg. Hourly Earnings",
                            "$25.64",
                            "Wage rate per hour."
                        ],
                        [
                            "Labor Productivity Growth",
                            "2.50%",
                            "Output increase per hour."
                        ],
                        [
                            "Unemployment Rate",
                            "4.50%",
                            "Percentage of jobless workforce."
                        ],
                        [
                            "Labor Force Participation Rate",
                            "62.40%",
                            "Percentage of working-age population."
                        ],
                        [
                            "Employment Rate",
                            "59.30%",
                            "Percentage of employed working-age population."
                        ]
                    ]
                },
                {
                    "title": "Inflation",
                    "data": [
                        [
                            "Consumer Price Index (CPI)",
                            "2.00%",
                            "Change in goods prices."
                        ],
                        [
                            "Producer Price Index (PPI)",
                            "1.80%",
                            "Change in producer goods prices."
                        ],
                        [
                            "Core Inflation Rate",
                            "2%",
                            "Excludes food and energy prices."
                        ],
                        [
                            "Inflation Expectations",
                            "3%",
                            "Anticipated future inflation rate."
                        ],
                        [
                            "Cost of Living Index",
                            "120",
                            "Relative price level indicator."
                        ],
                        [
                            "Purchasing Power Parity",
                            "$1.15",
                            "Exchange rate equilibrium."
                        ]
                    ]
                },
                {
                    "title": "Performance",
                    "data": [
                        [
                            "Stock Market Index (S&P 500)",
                            "4000",
                            "Large-cap stock benchmark.",
                        ],
                        [
                            "Bond Yield",
                            "2.50%",
                            "Return on investment for bonds.",
                        ],
                        [
                            "Foreign Exchange Rate (EUR/USD)",
                            "1.2",
                            "Euro's value in US dollars.",
                        ],
                        [
                            "Market Capitalization",
                            "$40T",
                            "Total stock market value.",
                        ],
                        [
                            "Interest Rate (Fed Funds Rate)",
                            "2.00%",
                            "Benchmark lending rate set by Fed.",
                        ],
                        [
                            "Initial Public Offerings (IPOs)",
                            "200",
                            "Number of new company listings.",
                        ],

                    ]
                }
            ],

            quotes: [
                {
                    "title": "Jamie Dimon",
                    "data": [
                        [
                            "On the current economic climate:",
                            "We are seeing strong consumer and business balance sheets, robust spending, and a globally synchronous recovery.'\"",
                            "Jamie Dimon, CEO of JPMorgan"
                        ]
                    ]
                },
                {
                    "title": "WEF",
                    "data": [
                        [
                            "Experts indicated a cautious stance towards global growth, noting ongoing geopolitical and policy uncertainties.",
                            "\"In the 2024 Chief Economists Outlook by the World Economic Forum, experts indicated a cautious stance towards global growth, noting ongoing geopolitical and policy uncertainties affecting the economic landscape.\"",
                            "World Economic Forum, 2024 Chief Economists Outlook​​"
                        ]
                    ]
                },
                {
                    "title": "IMF",
                    "data": [
                        [
                            "The IMF's World Economic Outlook Update in January 2024 predicts moderating inflation and steady growth",
                            "\"Predicts moderating inflation and steady growth, suggesting a soft landing for the global economy.\"",
                            "IMF, January 2024 World Economic Outlook Update​​"
                        ]
                    ]
                }
            ],

            bullets: [
                {
                    "title": "Highlights",
                    "data": [
                        [
                            "Inflation Concerns",
                            "Rising inflation sparks worries about purchasing power erosion, impacting consumer spending and investment decisions. This can lead to higher costs of living, reduced savings, and changes in monetary policy by central banks.",
                            "",
                            ""
                        ],
                        [
                            "Market Volatility",
                            "Increased market volatility prompts investor caution, leading to fluctuations in asset prices and portfolio strategies. Market volatility can result from various factors such as economic data releases, geopolitical events, and changes in market sentiment.",
                            "",
                            ""
                        ],
                        [
                            "Job Market Recovery",
                            "Positive signs indicate a gradual labor market rebound, with job creation and declining unemployment rates fostering economic optimism. A strong job market contributes to higher consumer confidence, increased household spending, and broader economic growth.",
                            "",
                            ""
                        ]
                    ]
                },
                {
                    "title": "Updates",
                    "data": [
                        [
                            "Global Trade Tensions",
                            "Escalating trade tensions threaten global economic stability, impacting supply chains, trade flows, and market sentiments. Heightened trade tensions can lead to increased tariffs, disrupted global trade, and uncertainty for businesses and investors."
                        ],
                        [
                            "Technology Innovation",
                            "Technological advancements drive economic growth and disruption, revolutionizing industries and reshaping consumer behaviors. Emerging technologies such as artificial intelligence, blockchain, and biotechnology are transforming business models and driving productivity gains."
                        ],
                        [
                            "Debt Crisis Looms",
                            "Rising levels of debt raise concerns about economic sustainability, with potential implications for fiscal policy and financial stability. High levels of publicai and private debt can strain government budgets, increase borrowing costs, and pose risks to economic growth and stability."
                        ]
                    ]
                },
                {
                    "title": "Insights",
                    "data": [
                        [
                            "Green Economy Transition",
                            "Transitioning to a green economy accelerates amid climate change concerns, driving investments in renewable energy and sustainable infrastructure. The shift towards sustainability presents opportunities for innovation, job creation, and economic resilience while addressing environmental challenges.",
                            "Explore",
                            "http://www.example.com/green-economy"
                        ],
                        [
                            "Digital Transformation",
                            "Digital transformation reshapes industries and workforce dynamics, fostering innovation and productivity gains while posing challenges for traditional business models. The adoption of digital technologies such as cloud computing, IoT, and automation is revolutionizing business operations and customer experiences.",
                            "Find out more",
                            "http://www.example.com/digital-transformation"
                        ],
                        [
                            "Inequality Challenges",
                            "Rising income inequality sparks debates on social and economic policies, highlighting disparities in wealth distribution and access to opportunities. Addressing inequality requires policy interventions to promote inclusive growth, improve access to education and healthcare, and create pathways for economic mobility.",
                            "Learn more",
                            "http://www.example.com/inequality-challenges"
                        ]
                    ]
                }
            ]
        }

        if (data[type]) {
            let response = JSON.parse(JSON.stringify(data[type]))
            return (response)
        } else {

            return ({})
        }


    },

    addScrollListeners() {
        if (!publicai.active.iframe) {
            return
        }




        publicai.active.iframe.contentDocument.removeEventListener('scroll', _shortcuts.handleScroll);
        PubSub.removeEvent('scroll');
        publicai.active.iframe.contentDocument.addEventListener('scroll', _shortcuts.handleScroll, { passive: true });
        //console.log("re-creating scroll event", publicai.active.iframe.contentDocument)
    },



    fetchPhotoAsBinary: async function (photoUrl) {
        const encodedPhotoUrl = encodeURIComponent(photoUrl);
        const workerUrl = `https://mediaproxy.newsroom-ai9687.workers.dev/?photoUrl=${encodedPhotoUrl}`;

        try {
            const response = await fetch(workerUrl);

            if (!response.ok) {
                throw new Error(`Server returned ${response.status}: ${response.statusText}`);
            }

            const blob = await response.blob();
            const imageUrl = URL.createObjectURL(blob);

            // Create an <img> element and set its source to the blob URL
            const imageElement = document.createElement('img');
            imageElement.src = imageUrl;
            imageElement.onload = () => {
                // Revoke the blob URL to free up memory after the image is loaded
                URL.revokeObjectURL(imageUrl);
            };
            imageElement.onerror = (e) => {
                console.error("Error loading image:", e);
            };

            // Return the <img> DOM element
            return imageElement;
        } catch (error) {
            console.error("Failed to fetch photo as binary:", error);
        }
    },





    "fontsWhitelist": [

        {
            "id": "TWVkaWFOb2RlOjIyODEzNzA=",
            "publicId": "75932c6d-c5f9-11ee-bf9e-4cd796c019ed",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288826107/imperial.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288826107/imperial.css",
            "created": "1707338570517.6448",
            "fileName": "Imperial",
            "source": "platform"
        },

        {
            "id": "TWVkaWFOb2RlOjIyODEzNTY=",
            "publicId": "ffe55e65-c5f3-11ee-bea6-d9271fdda2bb",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288826107/newfranklin.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288826107/newfranklin.css",
            "created": "1707336225601.449",
            "fileName": "Franklin",
            "source": "platform"
        },


        {
            "id": "TWVkaWFOb2RlOjIyNzM0Njg=",
            "publicId": "96b2a3e5-beb5-11ee-a280-595453b0ed41",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288826107/_NeueHaasGrotDisp-65Medium-Web.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288826107/_NeueHaasGrotDisp-65Medium-Web.css",
            "created": "1706539762171.819",
            "fileName": "_NeueHaasGrotDisp-65Medium-Web",
            "source": "platform"
        },
        {
            "id": "TWVkaWFOb2RlOjIyNzM0Njk=",
            "publicId": "96b705a0-beb5-11ee-bfff-c540bd9ab88d",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288826107/_NeueHaasGrotText-65Medium-Web.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288826107/_NeueHaasGrotText-65Medium-Web.css",
            "created": "1706539762200.325",
            "fileName": "_NeueHaasGrotText-65Medium-Web",
            "source": "platform"
        },
        {
            "id": "TWVkaWFOb2RlOjIyNzM0Njg=",
            "publicId": "96b2a3e5-beb5-11ee-a280-595453b0ed41",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288826107/_NeueHaasGrotDisp-65Medium-Web.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288826107/_NeueHaasGrotDisp-65Medium-Web.css",
            "created": "1706539762171.819",
            "fileName": "_NeueHaasGrotDisp-65Medium-Web",
            "source": "platform"
        },


        {
            "id": "TWVkaWFOb2RlOjIyNzM0Njk=",
            "publicId": "96b705a0-beb5-11ee-bfff-c540bd9ab88d",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288826107/_NeueHaasGrotText-65Medium-Web.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288826107/_NeueHaasGrotText-65Medium-Web.css",
            "created": "1706539762200.325",
            "fileName": "_NeueHaasGrotText-65Medium-Web",
            "source": "platform"
        },


        {
            "id": "TWVkaWFOb2RlOjM3NTQxMA==",
            "publicId": "1c5bef2a-b2f4-11eb-8ead-848b16951eec",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288798397/Antikor_Mono_SemiBold.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288798397/Antikor_Mono_SemiBold.css",
            "created": "1607806798421.683",
            "fileName": "Antikor Mono SemiBold"
        },

        {
            "id": "TWVkaWFOb2RlOjM1OTE1MQ==",
            "publicId": "97466f3d-b2fb-11eb-a406-d355f34c0713",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288798397/Averta-Black_.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288798397/Averta-Black_.css",
            "created": "1605112656149.454",
            "fileName": "Averta-Black ?"
        },

        {
            "id": "TWVkaWFOb2RlOjI2NzMy",
            "publicId": "390c940c-b310-11eb-86b2-70eea94a25b3",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288708050/Benton_Modern_Semi_Bold.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288708050/Benton_Modern_Semi_Bold.css",
            "created": "1559047988544.1538",
            "fileName": "Benton Modern Semi Bold"
        },

        {
            "id": "TWVkaWFOb2RlOjM5NDUwOQ==",
            "publicId": "0355852c-b2f6-11eb-b523-31b8d9e7f435",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288798397/FH_Oscar_Medium.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288798397/FH_Oscar_Medium.css",
            "created": "1611911453843.2341",
            "fileName": "FH Oscar Medium"
        },
        {
            "id": "TWVkaWFOb2RlOjM5NDUwNg==",
            "publicId": "035bb5d5-b2f6-11eb-914d-b29267104ab2",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288798397/FHOscar-LightItalic.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288798397/FHOscar-LightItalic.css",
            "created": "1611911453827.7532",
            "fileName": "FHOscar-LightItalic"
        },
        {
            "id": "TWVkaWFOb2RlOjM5NDY0OQ==",
            "publicId": "0142b181-b2f6-11eb-adff-7b293f771aa3",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288798397/FHRonaldson-RegularItalic.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288798397/FHRonaldson-RegularItalic.css",
            "created": "1611911624057.84",
            "fileName": "FHRonaldson-RegularItalic"
        },
        {
            "id": "TWVkaWFOb2RlOjY2NzA4",
            "publicId": "1145af59-b2f4-11eb-ac91-562beee83902",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288708050/Foco_Regular.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288708050/Foco_Regular.css",
            "created": "1581942963193.0981",
            "fileName": "Foco Regular"
        },
        {
            "id": "TWVkaWFOb2RlOjcwMTkw",
            "publicId": "1270f6c7-b2f4-11eb-8a2f-32af3a1d8b04",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288708877/Founders_Grotesk_Mono_Light_61lcOa0.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288708877/Founders_Grotesk_Mono_Light_61lcOa0.css",
            "created": "1582839509206.743",
            "fileName": "Founders Grotesk Mono Light"
        },

        {
            "id": "TWVkaWFOb2RlOjM5NDQ5Nw==",
            "publicId": "03a6298c-b2f6-11eb-8e9a-5a3c77f458b9",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288798397/FHOscar-Light.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288798397/FHOscar-Light.css",
            "created": "1611911453528.72",
            "fileName": "FHOscar-Light"
        },
        {
            "id": "TWVkaWFOb2RlOjMxNDI4Ng==",
            "publicId": "5a3713da-b300-11eb-930d-3e12c6c2b105",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288795176/Gotham_Medium.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288795176/Gotham_Medium.css",
            "created": "1597327561254.411",
            "fileName": "Gotham Medium"
        },
        {
            "id": "TWVkaWFOb2RlOjI1MTYx",
            "publicId": "17e07f2d-b310-11eb-9276-ec24fa15984c",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288708050/GT_Walsheim_Bold.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288708050/GT_Walsheim_Bold.css",
            "created": "1558428889599.151",
            "fileName": "GT Walsheim Bold"
        },
        {
            "id": "TWVkaWFOb2RlOjE1NDQy",
            "publicId": "376e8242-b310-11eb-9d4e-f8323a4aa2b5",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288708050/guardian-headline-bold_BvTBL6Z.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288708050/guardian-headline-bold_BvTBL6Z.css",
            "created": "1551785409554.56",
            "fileName": "Guardian Headline Bold"
        },
        {
            "id": "TWVkaWFOb2RlOjE1NDM5",
            "publicId": "37b9bfff-b310-11eb-8634-beff3d998c53",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288708050/guardian-egyptian-web-bold_TG9TROu.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288708050/guardian-egyptian-web-bold_TG9TROu.css",
            "created": "1551785290891.191",
            "fileName": "Guardian Egyptian Web Bold"
        },
        {
            "id": "TWVkaWFOb2RlOjE1NDQw",
            "publicId": "37bffdb0-b310-11eb-8068-a202036adf4f",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288708050/guardian-headline-regular_73kvldk.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288708050/guardian-headline-regular_73kvldk.css",
            "created": "1551785333055.216",
            "fileName": "Guardian Headline Regular"
        },

        {
            "id": "TWVkaWFOb2RlOjU4MzE3",
            "publicId": "6f8dd296-b30c-11eb-a891-bf200da8e2a3",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288708877/ibm-plex-sans-normal.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288708877/ibm-plex-sans-normal.css",
            "created": "1578486749967.0151",
            "fileName": "IBM Plex Sans Normal"
        },
        {
            "id": "TWVkaWFOb2RlOjU4MzIy",
            "publicId": "6f6b7cf7-b30c-11eb-8d80-3f810d030aa0",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288708877/ibm-plex-sans-light.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288708877/ibm-plex-sans-light.css",
            "created": "1578487027334.5151",
            "fileName": "IBM Plex Sans Light"
        },
        {
            "id": "TWVkaWFOb2RlOjYwMzU4",
            "publicId": "36493ac4-b30c-11eb-9d5f-93e9d8fb3210",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288708877/ibm-thin_CuSVvAg.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288708877/ibm-thin_CuSVvAg.css",
            "created": "1579334825471.3982",
            "fileName": "IBM Plex Sans Thin"
        },

        {
            "id": "TWVkaWFOb2RlOjcwMTk1",
            "publicId": "2bd23a57-b30b-11eb-a7df-44685520637f",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288708877/neue-haas-grotesk_Bold.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288708877/neue-haas-grotesk_Bold.css",
            "created": "1582839700826.407",
            "fileName": "neue-haas-grotesk Bold"
        },
        {
            "id": "TWVkaWFOb2RlOjcwMjA5",
            "publicId": "1257ab01-b2f4-11eb-93e6-e5912ed9fcb5",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288708877/NeueHaasGroteskDisp_Std_Normal.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288708877/NeueHaasGroteskDisp_Std_Normal.css",
            "created": "1582841739902.456",
            "fileName": "NeueHaasGroteskDisp Std Normal"
        },
        {
            "id": "TWVkaWFOb2RlOjM2MjE4Mw==",
            "publicId": "456b2e25-b2fb-11eb-b17b-e1039acd6ba1",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288798397/Night_Light_Outline.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288798397/Night_Light_Outline.css",
            "created": "1605713859220.157",
            "fileName": "Night Light Outline"
        },
        {
            "id": "TWVkaWFOb2RlOjkyNzU0",
            "publicId": "1119f414-b2f4-11eb-a3ba-5737db4a70aa",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288708877/Offshot_Black.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288708877/Offshot_Black.css",
            "created": "1586080251610.809",
            "fileName": "Offshot Black"
        },

        {
            "id": "TWVkaWFOb2RlOjE1NDUz",
            "publicId": "37fd63cd-b310-11eb-8785-3d92704d0da6",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288708050/playfair-display.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288708050/playfair-display.css",
            "created": "1551785806837.3662",
            "fileName": "Playfair Display"
        },
        {
            "id": "TWVkaWFOb2RlOjcxODYz",
            "publicId": "fef81ba2-b30a-11eb-b568-172e84493f50",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288708877/Playfair_Display_Black.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288708877/Playfair_Display_Black.css",
            "created": "1583271922635.9812",
            "fileName": "Playfair Display Black"
        },
        {
            "id": "TWVkaWFOb2RlOjcxODYw",
            "publicId": "ff0b35a8-b30a-11eb-9ecd-00119245e802",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288708877/Playfair_Display_Normal_Italic.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288708877/Playfair_Display_Normal_Italic.css",
            "created": "1583271860037.051",
            "fileName": "Playfair Display Normal Italic"
        },
        {
            "id": "TWVkaWFOb2RlOjcxODU5",
            "publicId": "ff119c1b-b30a-11eb-9912-39ba5bcc7a17",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288708877/Playfair_Display_Normal.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288708877/Playfair_Display_Normal.css",
            "created": "1583271843265.791",
            "fileName": "Playfair Display Normal"
        },

        {
            "id": "TWVkaWFOb2RlOjE0NDYyMQ==",
            "publicId": "105f444a-b2f4-11eb-ab34-545fc6de2355",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288708877/Southampton_Hand.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288708877/Southampton_Hand.css",
            "created": "1590679355872.52",
            "fileName": "Southampton Hand"
        },
        {
            "id": "TWVkaWFOb2RlOjM1NjQ5",
            "publicId": "3a6392bf-b310-11eb-8675-a21365f0c573",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288715283/stylesheet.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288715283/stylesheet.css",
            "created": "1563534731055.808",
            "fileName": "Standard A Medium"
        },

        {
            "id": "TWVkaWFOb2RlOjIwNjA5",
            "publicId": "383bb00a-b310-11eb-bf30-08aca70656ed",
            "url": "https://cdn.thenewsroom.io/platform/story_media/1288711343/trade.css",
            "mediaType": "FONT",
            "previewUrl": "https://cdn.thenewsroom.io/platform/story_media/1288711343/trade.css",
            "created": "1554970466810.395",
            "fileName": "TradeGothic"
        }
    ],



    CountdownProgress: class {
        constructor(totalTime, container, settings = {}) {
            this.totalTime = totalTime;
            this.currentTime = 0;
            this.percentage = 0;
            this.timeLeft = totalTime;
            this.container = container;
            this.isPaused = false; // New property to track if the countdown is paused

            // Default settings and overrides from the passed settings object
            this.settings = {
                startAngle: settings.startAngle || -90, // Default to 12 o'clock (-90 degrees)
                strokeWidth: settings.strokeWidth || 5, // Default stroke width is 5px
                color: settings.color || 'black',       // Default color is black
                onComplete: settings.onComplete || function () { }, // Default to an empty function
            };

            this.createProgressCircle();
        }

        // Function to create the SVG and circle elements
        createProgressCircle() {
            const namespace = "http://www.w3.org/2000/svg";

            // Create the SVG element
            this.svg = document.createElementNS(namespace, "svg");
            this.svg.setAttribute("width", "100");
            this.svg.setAttribute("height", "100");
            this.svg.setAttribute("viewBox", "0 0 100 100");

            // Create the circle element
            this.circle = document.createElementNS(namespace, "circle");
            this.circle.setAttribute("cx", "50");
            this.circle.setAttribute("cy", "50");
            this.circle.setAttribute("r", "45"); // Radius of the circle
            this.circle.setAttribute("stroke", this.settings.color); // Use the color from settings
            this.circle.setAttribute("stroke-width", this.settings.strokeWidth); // Stroke width from settings
            this.circle.setAttribute("fill", "none");

            // Set up the stroke-dasharray and stroke-dashoffset for progress animation
            const radius = 45;
            const circumference = 2 * Math.PI * radius;
            this.circle.style.strokeDasharray = `${circumference}`;
            this.circle.style.strokeDashoffset = `${circumference}`;

            // Rotate the circle to start at the specified angle (startAngle in degrees)
            this.svg.style.transform = `rotate(${this.settings.startAngle}deg)`;

            // Append the circle to the SVG
            this.svg.appendChild(this.circle);

            // Append the SVG to the container
            this.container.appendChild(this.svg);
        }

        // Function to update the progress
        updateProgress(currentTime) {
            if (this.isPaused) return; // Do nothing if paused

            this.currentTime = currentTime;
            this.percentage = (this.currentTime / this.totalTime) * 100;
            this.timeLeft = Math.round((this.totalTime - this.currentTime) / 1000);

            // Calculate the stroke-dashoffset based on percentage
            const radius = 45;
            const circumference = 2 * Math.PI * radius;
            const offset = circumference - (this.percentage / 100) * circumference;

            // Update the circle's stroke-dashoffset
            this.circle.style.strokeDashoffset = offset;

            // Check if countdown is complete and trigger the onComplete callback once
            if (this.currentTime >= this.totalTime && !this.isCompleted) {
                this.isCompleted = true;  // Ensure onComplete only triggers once
                this.settings.onComplete(); // Call the onComplete callback
            }
        }

        // Method to pause the countdown
        pause() {
            this.isPaused = true;
        }

        // Method to resume the countdown
        resume() {
            this.isPaused = false;
        }
    },

    propertiesToConvert: [
        "background-color", "background-image", "background-position", "border-bottom",
        "border-bottom-color", "border-bottom-left-radius", "border-bottom-right-radius",
        "border-bottom-width", "border-left", "border-left-color", "border-left-width",
        "border-right", "border-right-color", "border-right-width", "border-top",
        "border-top-color", "border-top-left-radius", "border-top-right-radius",
        "border-top-width", "box-shadow", "flex-basis", "flex-grow", "flex-shrink",
        "font-family", "font-size", "font-weight", "line-height", "margin-bottom",
        "margin-left", "margin-right", "margin-top", "padding-bottom", "padding-left",
        "padding-right", "padding-top", "text-decoration", "text-shadow", "transition-delay",
        "transition-duration", "transition-property", "transition-timing-function", "z-index"
    ],
    convertToCamelCase: function (str) {
        return str.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
    },






}



