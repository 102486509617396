export var css_audio = {


    //this is the side panel

    init: function () {
        if (publicai.selected.settings.type != "audio") {
            return
        } else {
            ai_audio.init(document)
        }

        if (!css_audio.ui) {
            let panel = $$$(document, "data-group", "audio")
            css_audio.ui = $$$$(document, "audio_settings")
            css_audio.ui.panel = panel
        }

        let panels = ["audio", "audio_script", "audio_actor", "audio_playback", "audio_captions"]
        for (let panel of panels) {
            css_management.allPanels[panel].classList.remove("hidden")
        }

        let secondaryPanels = ["audio_script", "audio_actor", "audio_captions",]
        for (let item of secondaryPanels) {
            let panel = css_management.allPanels[item]
            render.addClassesToAllChildrenOf(panel, "public-panel-hidden", true)

        }

        if (publicai.selected.settings.type != "audio") {
            css_audio.ui.panel.classList.add("hidden")
            return
        } else {
            css_audio.ui.panel.classList.remove("hidden")
        }

        this.otherSettings()
        this.autoPlaySwitch()
        this.setUpSpeed()
        this.setUpVolume()
        this.setUpWordsInView()



        native_utils.boolean_combo({
            "selector": css_audio.ui.controls,
            "defaultValue": publicai.selected.settings.settings.player.controls.enabled,
            "storageObject": publicai.selected.settings.settings.player.controls,
            "storageVariable": "enabled",
            "save": true,
            key: "combo",
            "callback": controlsSwitch,
        })

        function controlsSwitch(s) {

            if (s.value == "false") {
                publicai.selected.settings.settings.player.controls.enabled = false
            } else {
                publicai.selected.settings.settings.player.controls.enabled = true
            }

            css_audio.refresh()

        }

        native_utils.boolean_combo({
            "selector": css_audio.ui.captions,
            "defaultValue": publicai.selected.settings.settings.player.captions.enabled,
            "storageObject": publicai.selected.settings.settings.player.captions,
            "storageVariable": "enabled",
            "save": true,
            key: "combo",
            "callback": captionsSwitch,
        })

        function captionsSwitch(s) {

            if (s.value == "false") {
                publicai.selected.settings.settings.player.captions.enabled = false
            } else {
                publicai.selected.settings.settings.player.captions.enabled = true
            }

            css_audio.refresh()

        }

        native_utils.boolean_combo({
            "selector": css_audio.ui.player,
            "defaultValue": publicai.selected.settings.settings.player.enabled,
            "storageObject": publicai.selected.settings.settings.player,
            "storageVariable": "enabled",
            "save": true,

            key: "combo",
            "callback": playerSwitch,
        })

        function playerSwitch(s) {

            if (s.value == "false") {
                publicai.selected.settings.settings.player.enabled = false
            } else {
                publicai.selected.settings.settings.player.enabled = true
            }
            console.log("player", publicai.selected.settings.settings.player.enabled)
            css_audio.refresh()
        }


        if (!publicai.selected.settings.settings.player.helpers) {
            publicai.selected.settings.settings.player.helpers = false

        }

        native_utils.boolean_combo({
            "selector": css_audio.ui.helpers,
            "defaultValue": publicai.selected.settings.settings.player.helpers,
            "storageObject": publicai.selected.settings.settings.player,
            "storageVariable": "helpers",
            "save": true,

            key: "combo",
            "callback": refreshHelpers,
        })

        function refreshHelpers(s) {
            console.log(s)
            if (s.value == "false") {
                publicai.selected.settings.settings.player.helpers = false
            } else {
                publicai.selected.settings.settings.player.helpers = true
            }

            notifications.show([
                ["By the way....", "Helpers will be removed when the document is playing. You can enable it to help with design."]

            ])

            css_audio.refresh()
        }


        this.captions()

    },


    playerSwithch: function () {

        css_audio.ui.player_on.onclick = css_audio.ui.player_off.onclick = function () {
            publicai.selected.settings.settings.player.enabled = !publicai.selected.settings.settings.player.enabled
            refreshButtons()
        }

        function refreshButtons() {

            if (publicai.selected.settings.settings.player.enabled) {
                css_audio.ui.player_on.classList.add("active")
                css_audio.ui.player_off.classList.remove("active")
            } else {
                css_audio.ui.player_on.classList.remove("active")
                css_audio.ui.player_off.classList.add("active")
            }
        }
        refreshButtons()

    },

    refresh: function (s) {
        a_audio.toggleElementsVisibility(publicai.selected.settings)
    },

    setUpSpeed: function () {

        let player = publicai.selected.settings.settings.player

        if (!player.speed) {
            player.speed = 1
        }

        let speeds = [
            {
                label: "Slow (0.8x)",
                value: 0.8,
            },
            {
                label: "Normal (1x)",
                value: 1,
            },
            {
                label: "1.1x",
                value: 1.1,
            },
            {
                label: "1.2",
                value: 1.2,
            },

        ]


        css_utils.setup_reg_dropdown({
            "selector": css_audio.ui.speed,
            //"value": player.speed,
            "object": player,
            "variable": "speed",
            "autoLabel": true,
            "multiSelect": false,
            "search": false,
            "values": speeds,
            "label": "Add new element",
            "styles": ["auto", "horizontal", "light", "no_input", "lighter"],
            "customMode": true,
            "save": false,
            "callback": updateSpeed,
            "direction": "down",
            "overlayColor": "black",
            "overlayOpacity": 0.1,
            "width": "100%"
        })


        function updateSpeed(s) {
            player.speed = s.value
            css_audio.refresh()
        }
    },


    setUpWordsInView: function () {

        const throttledZoomSliderCallback = _utils.throttle(zoomSliderCallback, 5); // 200 ms delay
        let selector = css_audio.ui.words_in_view

        let el = $$$$(selector, "data-slider")

        let settings = {
            selector: selector,
            object: publicai.selected.settings.settings.player.captions,
            variable: "wordsInView",
            sliderCallback: throttledZoomSliderCallback, // Use throttled version
            defaultValue: publicai.selected.settings.settings.player.captions.wordsInView,
            label: el.label,
            units: " words",
            cursorWidth: 13,
            //startSlideCallback: startSlide,
            stopSlideCallback: stopSlide,
            range: {
                "min": 1,
                "max": 15,
            },
        }


        function stopSlide() {
            _create.forceReHandle(publicai.selected.settings)
        }

        function zoomSliderCallback(value, elastic = false) {
            publicai.selected.settings.settings.player.captions.wordsInView = parseInt(value)
            updateLabel(value, elastic)
        }

        function updateLabel(value, elastic = false) {
            el.label.textContent = value + " words"
            let percentage = value * 100 / settings.range.max
            el.progress.style.width = percentage + "%"
            el.cursor.style.left = "calc(-6.5px + " + percentage + "%)"

        }
        css_utils.zoomSlider(settings);

        let initialValue = publicai.selected.settings.settings.player.captions.wordsInView

        updateLabel(initialValue);

        //first time
        //el.cursor.style.left = "calc(-6.5px + " + initialValue * 100 / settings.range.max + "%)"

    },

    setUpVolume: function () {

        let selector = css_audio.ui.volume_slider

        let el = $$$$(selector, "data-slider")

        //console.log("el", el)

        let settings = {
            selector: selector,
            object: publicai.selected.settings.settings.player,
            variable: "volume",
            sliderCallback: zoomSliderCallback, // Use throttled version
            defaultValue: publicai.selected.settings.settings.player.volume * 100,
            label: el.label,
            units: "%",
            range: {
                "min": 0,
                "max": 100,
            },
        }

        function zoomSliderCallback(value, reHandle = true) {
            publicai.selected.settings.settings.player.volume = value / 100
            if (reHandle) {
                _create.forceReHandle(publicai.selected.settings)
            }
            updateLabel(value)
        }

        function updateLabel(value) {
            el.label.textContent = value + "%"
            let valueWidth = value * 100 / settings.range.max
            //console.log("volume", valueWidth, value)
            el.progress.style.width = valueWidth + "%"
            el.cursor.style.left = valueWidth + "%"
        }


        css_utils.zoomSlider(settings);
        zoomSliderCallback(publicai.selected.settings.settings.player.volume * 100, false);

    },


    refresh: function () {
        _create.forceReHandle(publicai.selected.settings)
    },


    autoPlaySwitch: function () {

        css_audio.ui.autoplay_on.onclick = css_audio.ui.autoplay_off.onclick = function () {
            publicai.selected.settings.settings.player.autoplay = !publicai.selected.settings.settings.player.autoplay
            refreshButtons()
        }

        function refreshButtons() {

            if (publicai.selected.settings.settings.player.autoplay) {
                css_audio.ui.autoplay_on.classList.add("active")
                css_audio.ui.autoplay_off.classList.remove("active")
            } else {
                css_audio.ui.autoplay_on.classList.remove("active")
                css_audio.ui.autoplay_off.classList.add("active")
            }

        }
        refreshButtons()

    },


    captions: function () {


        if (!publicai.selected.settings.settings.player.captions) {
            publicai.selected.settings.settings.player.captions = {
                "mode": "word_highlights",
                "enabled": true,
                "wordsInView": 10
            }
        }




        let captionOptions = [
            {
                "label": "No Captions",
                "value": "none",
                "icon": "subtitles_off",
            },
            {
                "label": "Word Highlights",
                "value": "word_highlights",
                "icon": "text_increase",
            },
            {
                "label": "Continuous Highlights",
                "value": "continuous_highlights",
                "icon": "subtitles",
            },
        ]

        css_utils.setup_reg_dropdown({
            "selector": css_audio.ui.captions_mode,
            "value": publicai.selected.settings.settings.player.captions.mode,
            "autoLabel": true,
            "object": publicai.selected.settings.settings.player.captions,
            "variable": "mode",
            "multiSelect": false,
            "search": false,
            "values": captionOptions,
            "label": "Add new element",
            "styles": ["auto", "horizontal", "light", "no_input", "lighter"],
            "customMode": true,
            "save": false,
            "callback": refreshCaptions,
            "direction": "down",
            "overlayColor": "black",
            "overlayOpacity": 0.1,
            "width": "100%"
        })

        function refreshCaptions(s) {

            console.log(s)

            if (s.value == "none") {
                publicai.selected.settings.settings.player.captions.enabled = false
                publicai.selected.settings.settings.player.captions.mode = "none"
            } else {
                publicai.selected.settings.settings.player.captions.enabled = true
                publicai.selected.settings.settings.player.captions.mode = s.value

            }

            css_audio.ui.captions_label.textContent = publicai.selected.settings.settings.player.captions.mode
            _create.forceReHandle(publicai.selected.settings)
        }


        //css_audio.ui.captions_label.textContent = publicai.selected.settings.settings.player.captions.mode
        let libraryEntry = publicai.library.files[Object.keys(publicai.selected.settings.media.content)[0]]

        let enableGenerateCaptions = false

        if (libraryEntry.alignment && libraryEntry.alignment.type != "eleven") {
            enableGenerateCaptions = true
        }

        if (!libraryEntry.alignment) {
            enableGenerateCaptions = true
        }

        if (enableGenerateCaptions) {
            css_audio.ui.generate_captions.classList.remove("is_hidden")
        }

        let generateCaptionsButton = new _utils.SmartButton({
            button: css_audio.ui.generate_captions,
            function: css_audio.transcribeAudio,
            params: [],
            eta: 7000,  // the number of ms this is likely to take
            onComplete: updateCaptions,
            success: "Done!"
        })

        let self = this
        function updateCaptions() {
            self.refresh()
        }

        if (!libraryEntry.alignment || !publicai.preload[libraryEntry.alignment] || publicai.preload[libraryEntry.alignment].type != "openai") {
            css_audio.ui.edit_captions.classList.add("is_hidden")
        } else {
            css_audio.ui.edit_captions.classList.remove("is_hidden")
        }


        css_audio.ui.edit_captions.onclick = function (e) {
            e.stopPropagation()
            e.preventDefault()
            css_audio.openCaptionsEditor(libraryEntry.alignment)
        }

    },



    transcribeAudio: async function () {

        let mediaObject = publicai.selected.settings.media.content;
        let libraryEntry = publicai.library.files[Object.keys(mediaObject)[0]]

        if (!libraryEntry || !libraryEntry.url) {
            return
        }
        //no entry found


        let workerUrl = "https://voice.newsroom-ai9687.workers.dev/voice-to-text/";

        let data = {
            element: publicai.selected.settings.id,
            page: publicai.active.page.id,
            project: nwsapp.currentProject.id,
            //entry: "none",
            url: libraryEntry.url // No need to manually encode, URLSearchParams will handle it
        };

        const params = new URLSearchParams(data);
        workerUrl += "?" + params.toString();

        console.log(workerUrl);


        try {
            const response = await fetch(workerUrl, {
                method: "GET"
            });

            if (!response.ok) {
                throw new Error(`Worker API error: ${response.statusText}`);
            }

            const transcriptionData = await response.json();
            console.log("Transcription with timestamps:", transcriptionData);

            delete libraryEntry.alignment

            libraryEntry.alignment = nws.voicesEndpoint + "/alignment/" + nwsapp.currentProject.id + "_" + publicai.selected.settings.id

            console.log("firstElement", libraryEntry, libraryEntry.alignment, transcriptionData)

            /*        libraryEntry.alignment = {
                       type: "openai",
                       alignment: transcriptionData
                   } */

            //console.log("firstElement", libraryEntry)


            // Handle the transcription data (e.g., display or process it)
            return transcriptionData;
        } catch (error) {
            console.error("Error transcribing audio:", error);
        }
    },

    openCaptionsEditor: function (alignmentUrl) {
        let alignment = publicai.preload[alignmentUrl];
        let code = "";

        // Create content for the editor, each word on a new line
        if (alignment.type == "openai") {
            for (let item of alignment.words) {
                code += item.word + "\n";  // Place each word on a new line (no padding needed here)
            }
        }

        if (!code) {
            console.error("code is empty");
        }

        let editor = nwsapp.ux.captions_editor;
        editor.classList.add("active");

        let codeEditorMain = editor.querySelector(".captions_editor_main");
        let codeArea = $$$(editor, "captions_editor", "code");

        let autoFormat = $$$(editor, "captions_editor", "auto_format");
        let labelEditor = $$$(editor, "captions_editor", "label");

        labelEditor.textContent = "Edit Captions";

        codeArea.setAttribute("contenteditable", "true");
        labelEditor.setAttribute("contenteditable", "true");

        // Create a function to map each line number to the start time of the corresponding word
        function timeBasedLineNumber(lineNumber) {
            if (alignment.words[lineNumber]) {
                return alignment.words[lineNumber].start.toFixed(2); // Round time to 2 decimal places
            }
            return lineNumber + 1; // Default to normal line number if no word found
        }

        // Function to update words array when editing
        function updateWordsArray(updatedContent) {
            // Split the updated content by newlines to get the words
            let newWords = updatedContent.split("\n").filter(word => word.trim() !== "");

            // Update the alignment.words array with the new words
            for (let i = 0; i < newWords.length; i++) {
                if (alignment.words[i]) {
                    alignment.words[i].word = newWords[i]; // Update existing word
                } else {
                    // If there are more new words, you can add them to the array (with default start/end times)
                    alignment.words.push({ word: newWords[i], start: 0, end: 0 }); // You can set start/end accordingly
                }
            }

            // If the new content has fewer words than before, remove the extra ones
            if (newWords.length < alignment.words.length) {
                alignment.words.splice(newWords.length);
            }
        }

        // Initialize the CodeMirror editor with custom time-based line numbers
        let theEditor = CodeMirror.fromTextArea(codeArea, {
            lineNumbers: true,               // Enable line numbers
            mode: "text/plain",              // Set the mode to plain text (to avoid JS highlighting)
            theme: "dracula",                // Theme (optional)
            indentUnit: 8,                   // Indentation settings
            lineWrapping: true,              // Wrap long lines
            matchBrackets: false,            // No need to match brackets in plain text mode
            autoCloseBrackets: false,        // Disable auto-close brackets in plain text
            readOnly: false,                 // Editable
            firstLineNumber: 0,              // Start line numbers from 0
            lineNumberFormatter: timeBasedLineNumber,  // Custom formatter for line numbers
            viewportMargin: Infinity,        // Render the entire document
        });

        this.theEditor = theEditor;
        this.codeObject = code;

        theEditor.setSize("100%", "100%");
        theEditor.setValue(code);

        // Apply padding directly to the line divs after rendering
        function applyPaddingToLines() {
            const lines = theEditor.display.lineDiv.childNodes;
            lines.forEach(line => {
                line.style.paddingLeft = "20px";  // Add left padding to each line element
            });
        }

        // Listen for changes in the CodeMirror editor and update the words array
        theEditor.on('change', (cm) => {
            let updatedContent = cm.getValue();
            updateWordsArray(updatedContent); // Update words array whenever the text is edited
            code.code = updatedContent; // Save the updated content to your code object
            applyPaddingToLines(); // Reapply padding after changes
        });

        // Apply padding once initially after editor setup
        applyPaddingToLines();

        let self = this;
        autoFormat.onclick = function () {
            self.autoformatCode(theEditor, code);
        };

        codeArea.textContent = code;

        document.addEventListener('click', css_audio.closeCaptionsEditor);

        codeEditorMain.onclick = function (e) {
            e.stopPropagation();
            e.preventDefault();
        };
    },



    closeCaptionsEditor: function () {
        nwsapp.ux.captions_editor.classList.remove("active");
    },





    otherSettings: function () {

        return

        let otherSettings = ["barGap", "height"]

        let settings = publicai.selected.settings.settings.player.settings


        if (!publicai.selected.settings.settings.player.volume) {
            publicai.selected.settings.settings.player.volume = 1
        }

        css_audio.ui.volume.value = publicai.selected.settings.settings.player.volume * 100

        css_audio.ui.volume.onchange = function () {
            publicai.selected.settings.settings.player.volume = this.value / 100
            play_interactive.forceRecreateSelected(false, true)
        }


        for (let item of otherSettings) {
            if (css_audio.ui[item]) {
                css_audio.ui[item].value = publicai.selected.settings.settings.player.settings[item]
                css_audio.ui[item].onchange = function () {
                    publicai.selected.settings.settings.player.settings[item] = this.value
                    _create.forceReHandle(publicai.selected.settings)
                }

            }

        }
    },

    playerColors: function () {


        //wave_picker

        let colorPickerSettings = {
            "selector": css_audio.ui.wave_picker,
            "default": publicai.selected.settings.settings.player.settings.waveColor,
            "targetObject": publicai.selected.settings.settings.player.settings,
            "label": "",
            "targetVariable": "waveColor",
            "targetDocumentObject": null,
            "showColorCode": false,
            "callback": refreshColor,
            "name": generateSceneID(),
            //"cover": css_borders.ui.color_cover_element, //element on top
        }
        function refreshColor(s) {

            //console.log("refresh", s)

        }


        css_utils.input({
            "selector": css_audio.ui.wave_color,
            "variable": "color",
            "color": colorPickerSettings,
            "units": false,
            "validation": "color",
        })


        let progressPickerSettings = {
            "selector": css_audio.ui.progress_picker,
            "default": publicai.selected.settings.settings.player.settings.progressColor,
            "targetObject": publicai.selected.settings.settings.player.settings,
            "label": "",
            "targetVariable": "progressColor",
            "targetDocumentObject": null,
            "showColorCode": false,
            "callback": refreshColor,
            "name": generateSceneID(),
            //"cover": css_borders.ui.color_cover_element, //element on top
        }



        css_utils.input({
            "selector": css_audio.ui.progress_color,
            "variable": "color",
            "color": progressPickerSettings,
            "units": false,
            "validation": "color",
        })

    },




}