import { DynamoDBClient, PutItemCommand, UpdateItemCommand, DeleteItemCommand, QueryCommand, GetItemCommand } from '@aws-sdk/client-dynamodb';
import { S3Client, PutObjectCommand, GetObjectCommand } from '@aws-sdk/client-s3';
import { marshall, unmarshall } from '@aws-sdk/util-dynamodb';  // Import marshall/unmarshall utilities
import { applyCSSServer } from '../../css/server_css.js';


const S3_BUCKET = 'publicsystem';  // Define your S3 bucket name

// Hardcoded credentials for AWS
const AWS_CREDENTIALS = {
    region: 'us-east-1',
    credentials: {
        accessKeyId: 'AKIA5IN444726R3Q2IE6',  // Your actual AWS Access Key ID
        secretAccessKey: 'JsPuXiiletwUK2zNBe0DQIDLujVTqS0FUIe1mBDN',  // Your actual AWS Secret Access Key
    }
};

// Create S3 and DynamoDB clients
async function createS3Client() {
    return new S3Client(AWS_CREDENTIALS);
}

async function createDynamoDBClient() {
    return new DynamoDBClient(AWS_CREDENTIALS);
}

// Upload a file to S3
async function uploadFileToS3(key, body, contentType = 'application/json') {
    const s3Client = await createS3Client();
    const uploadParams = {
        Bucket: S3_BUCKET,
        Key: key,
        Body: body,
        ContentType: contentType
    };

    try {
        const data = await s3Client.send(new PutObjectCommand(uploadParams));
        //console.log(`Successfully uploaded ${key} to S3:`, data);
        return { success: true, s3Key: key };
    } catch (error) {
        //console.error(`Error uploading ${key} to S3:`, error);
        throw new Error(`Failed to upload ${key} to S3: ${error.message}`);
    }
}

// Upload content to S3 with separate JSON, HTML, and CSS
async function uploadContentToS3(content, id) {
    const folderKey = `system/${id}/`;  // Define the base folder for this component

    // Upload JSON content
    await uploadFileToS3(`${folderKey}element.json`, JSON.stringify(content));

    // Process and upload HTML
    const htmlContent = content.html || '';
    await uploadFileToS3(`${folderKey}element.html`, htmlContent, 'text/html');

    // Process CSS and upload it
    let cssContent = content.css || '';
    cssContent = applyCSSServer(cssContent);  // Process the CSS before saving
    await uploadFileToS3(`${folderKey}element.css`, cssContent, 'text/css');

    return { success: true, folderKey };
}

// Save or update content in DynamoDB and S3
export async function saveOrUpdateContent(config) {
    const dynamoClient = await createDynamoDBClient();
    const tableName = "PublicSystem";
    const timestamp = new Date().toISOString();

    const folderKey = `system/${config.id}/`;  // Folder for this component

    try {
        // Upload content to S3 (JSON, HTML, and CSS)
        await uploadContentToS3(config.content, config.id);

        // Now update the DynamoDB metadata with the S3 folder key
        const checkParams = {
            TableName: tableName,
            Key: marshall({
                type: config.type,
                id: config.id
            })
        };

        const checkData = await dynamoClient.send(new GetItemCommand(checkParams));

        if (checkData.Item) {
            //console.log("Updating content", config.id);
            const updateParams = {
                TableName: tableName,
                Key: marshall({
                    type: config.type,
                    id: config.id
                }),
                UpdateExpression: 'set s3Key = :s3k, updated = :u',
                ExpressionAttributeValues: marshall({
                    ':s3k': folderKey,
                    ':u': timestamp
                })
            };
            await dynamoClient.send(new UpdateItemCommand(updateParams));
            return { success: true, message: 'Content metadata updated in DynamoDB.' };
        } else {
            //console.log("Saving content", config.id);
            const saveParams = {
                TableName: tableName,
                Item: marshall({
                    type: config.type,
                    id: config.id,
                    name: config.name || 'Unnamed',
                    s3Key: folderKey,  // Store the S3 folder key
                    modified: timestamp,
                    userId: config.credentials?.username || '-'
                })
            };
            await dynamoClient.send(new PutItemCommand(saveParams));
            return { success: true, message: 'Content metadata saved in DynamoDB.' };
        }
    } catch (error) {
        console.error('Error saving or updating content:', error);
        throw new Error(`Failed to save or update content: ${error.message}`);
    }
}

function streamToString(stream) {
    return new Promise((resolve, reject) => {
        const chunks = [];
        stream.on('data', (chunk) => chunks.push(chunk));
        stream.on('error', reject);
        stream.on('end', () => resolve(Buffer.concat(chunks).toString('utf-8')));
    });
}

async function getContentFromS3(s3Key) {
    const s3Client = await createS3Client();

    const getParams = {
        Bucket: S3_BUCKET,
        Key: s3Key  // The full key passed here
    };

    try {
        const data = await s3Client.send(new GetObjectCommand(getParams));

        // Convert the data.Body stream to a string
        const bodyContents = await streamToString(data.Body);
        return JSON.parse(bodyContents);  // Parse the JSON content
    } catch (error) {
        console.error('Error fetching content from S3:', error);
        throw new Error(`Failed to fetch content from S3: ${error.message}`);
    }
}

export async function getContent(settings) {
    const dynamoClient = await createDynamoDBClient();
    const { type } = settings;
    let allItems = [];
    let lastEvaluatedKey = null;

    const baseParams = {
        TableName: "PublicSystem",
        KeyConditionExpression: '#type = :type',
        ExpressionAttributeNames: {
            '#type': 'type'  // Use an alias for the reserved keyword 'type'
        },
        ExpressionAttributeValues: {
            ':type': { S: type }
        },
        // Do not include ExclusiveStartKey initially
    };

    try {
        do {
            // Create a new params object for each iteration to avoid mutating baseParams
            const params = { ...baseParams };
            if (lastEvaluatedKey) {
                params.ExclusiveStartKey = lastEvaluatedKey;
            }

            const data = await dynamoClient.send(new QueryCommand(params));

            // Unmarshall each item in data.Items to convert to native JS object
            const items = data.Items.map((item) => unmarshall(item));

            allItems = allItems.concat(items);
            lastEvaluatedKey = data.LastEvaluatedKey;
        } while (lastEvaluatedKey);

        // Retrieve content from S3 for each item
        const itemsWithContent = await Promise.all(allItems.map(async (item) => {
            if (item.s3Key) {
                const content = await getContentFromS3(`${item.s3Key}element.json`);
                item.content = content;
            } else {
                console.error(`Missing s3Key for item: ${JSON.stringify(item)}`);
            }
            return item;
        }));

        return itemsWithContent;
    } catch (error) {
        console.error('Error retrieving items:', error);
        throw new Error(`Failed to retrieve items: ${error.message}`);
    }
}



// Delete a package from DynamoDB
export async function deletePackageFromDynamoDB({ resourceType, resourceId }) {
    const dynamoClient = await createDynamoDBClient();

    const params = {
        TableName: 'PublicSystem',
        Key: marshall({
            resourceType,  // Assume this is your partition key
            resourceId     // Assume this is your sort key
        })
    };

    try {
        const data = await dynamoClient.send(new DeleteItemCommand(params));
        //console.log('Successfully deleted item from DynamoDB:', data);
        return { success: true, message: 'Package deleted from DynamoDB.' };
    } catch (error) {
        //console.error('Error deleting item from DynamoDB:', error);
        throw new Error(`Failed to delete package: ${error.message}`);
    }
}
