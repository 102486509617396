import Pickr from '@simonwep/pickr';
import '@simonwep/pickr/dist/themes/nano.min.css';

export class ColorPicker {
    constructor(settings) {
        this.settings = settings;
        this.coverElement = settings.selector;
        this.inputElement = settings.element ? $$$(settings.element, "data-input", "input") : null;
        this.eyedropper = settings.element ? $$$(settings.element, "picker", "eye") : null;
        this.pickr = null;
        this.clickOutsideHandler = this.handleClickOutside.bind(this);
        this.clickHandler = this.handleClick.bind(this);

        this.init();

        if (!_public.pickers) _public.pickers = [];
        _public.pickers.push(this);
    }

    init() {
        this.refresh();
        this.setupEyedropperEvents();
        this.setupClickHandler();
    }

    refresh() {
        const currentColor = this.settings.targetObject[this.settings.targetVariable] || "#ffffff";
        this.coverElement.style.backgroundColor = currentColor;
        if (this.inputElement) {
            this.inputElement.value = currentColor || "No color";
        }
    }

    handleClick(e) {
        e.preventDefault();
        e.stopPropagation();

        if (!this.pickr) {
            this.createPickr();
        }

        if (this.pickr) {
            this.pickr.show();
        }
    }

    setupClickHandler() {
        this.settings.selector.addEventListener('click', this.clickHandler);
    }

    createPickr() {
        const rect = this.settings.selector.getBoundingClientRect();
        const mainElement = nwsapp.ux.main;

        const pickrContainer = document.createElement('div');
        pickrContainer.className = 'color-picker-container';
        pickrContainer.style.position = 'absolute';
        pickrContainer.style.left = `${rect.left}px`;
        pickrContainer.style.top = `${rect.bottom + 5}px`;
        pickrContainer.style.zIndex = '99999';

        mainElement.appendChild(pickrContainer);

        const pickrOptions = {
            el: this.settings.selector,
            container: pickrContainer,
            theme: 'nano',
            default: this.settings.default || '#ffffff',
            components: {
                preview: true,
                opacity: true,
                hue: true,
                interaction: {
                    hex: true,
                    rgba: true,
                    input: true,
                    clear: true,
                    save: true
                }
            }
        };

        try {
            this.pickr = Pickr.create(pickrOptions);
            this.setupPickrEvents();
            console.log('Picker container created:', pickrContainer);
        } catch (error) {
            console.error('Error creating picker:', error);
        }
    }

    setupPickrEvents() {
        this.pickr
            .on('change', (color) => {
                if (!color) return;
                _public.isColoring = true;
                _create.deselectAllElements(12);

                const hexColor = color.toHEXA().toString();
                const rgbaColor = color.toRGBA().toString();

                this.handleColorMove(hexColor, rgbaColor);
            })
            .on('save', (color) => {
                if (!color) return;
                const hexColor = color.toHEXA().toString();
                const rgbaColor = color.toRGBA().toString();

                console.log('Color saved:', hexColor, rgbaColor);
                this.handleColorChange(rgbaColor, true);

                if (this.pickr) {
                    this.pickr.hide();
                }
            })
            .on('show', () => {
                document.addEventListener('mousedown', this.clickOutsideHandler);
                console.log('Picker shown');
            })
            .on('hide', () => {
                document.removeEventListener('mousedown', this.clickOutsideHandler);
                if (this.pickr && this.pickr.getSelectedColor()) {
                    const color = this.pickr.getSelectedColor();
                    const rgbaColor = color.toRGBA().toString();
                    this.handleColorChange(rgbaColor, true);
                }
                console.log('Picker hidden');
            });
    }

    handleColorMove(hexColor, rgbaColor) {
        this.settings.callback(this.settings, hexColor);
        this.coverElement.style.backgroundColor = rgbaColor;
        this.handleColorChange(rgbaColor, false);
    }

    handleColorChange(color, completed = false) {
        _public.activeColorPicker = this;
        _public.isColoring = !completed;

        if (!color) return;

        const validatedColor = css_utils.validateColor(color);
        if (validatedColor && !validatedColor.includes('NaN')) {
            this.settings.targetObject[this.settings.targetVariable] = validatedColor;
            this.coverElement.style.backgroundColor = validatedColor;

            if (this.inputElement) {
                this.inputElement.value = validatedColor;
            }

            this.settings.callback(this.settings, validatedColor);
            this.toggleElementColorStyle(!completed);
        }

        if (completed) {
            css_management.manage();
        }

        this.settings.callback(this.settings, validatedColor);

        console.log('Color change event:', {
            color: validatedColor,
            completed: completed
        });
    }

    handleClickOutside(event) {
        if (!this.pickr) return;

        const pickrApp = this.pickr.getRoot().app;
        if (pickrApp && !pickrApp.contains(event.target) && !this.settings.selector.contains(event.target)) {
            this.pickr.hide();
        }
    }

    setupEyedropperEvents() {
        if (this.eyedropper && window.EyeDropper) {
            this.eyedropper.onclick = (e) => {
                e.preventDefault();
                e.stopPropagation();
                this.setupEyeDropper();
            };
        }
    }

    setupEyeDropper() {
        if (!window.EyeDropper) {
            console.error("EyeDropper API not supported");
            return;
        }

        const eyeDropper = new EyeDropper();
        eyeDropper.open()
            .then((result) => {
                const pickedColor = result.sRGBHex;
                this.handleColorChange(pickedColor, true);
            })
            .catch(console.error);
    }

    toggleElementColorStyle(toggle) {


        console.log("this.settings.targetDocumentObject", this.settings.targetDocumentObject, this.settings.targetVariable, toggle)

        if (!toggle) {
            if (this.settings.targetDocumentObject) {
                this.settings.targetDocumentObject.style[this.settings.targetVariable] = "";
            }
            _create.refreshSelectionContainer(11);
        } else {
            if (this.settings.targetDocumentObject) {
                this.settings.targetDocumentObject.style[this.settings.targetVariable] = this.settings.targetObject[this.settings.targetVariable];
            }
        }
    }

    destroy() {
        //console.log('Destroying picker instance');


        // Remove event listeners
        if (this.eyedropper) {
            this.eyedropper.onclick = null;
        }
        document.removeEventListener('mousedown', this.clickOutsideHandler);

        // Remove click handler
        if (this.settings.selector) {
            this.settings.selector.removeEventListener('click', this.clickHandler);
        }

        // Destroy Pickr instance
        if (this.pickr) {
            this.pickr.destroyAndRemove();
        }

        // Remove from _public.pickers array
        const index = _public.pickers.indexOf(this);
        if (index > -1) {
            _public.pickers.splice(index, 1);
        }

        // Clean up references
        this.pickr = null;
        this.settings = null;
        this.coverElement = null;
        this.inputElement = null;
        this.eyedropper = null;
    }
}