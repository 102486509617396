import { init as domains } from './domains.js';
import { init as fonts } from './fonts.js';

export function init() {

    console.log("publishing init")

    let callbacks = { domains, fonts }


    let tabs = new utils.Tabs({
        identifiers: {
            tab: "tab2",
            panel: "panel2"
        },
        container: app.ui.settings.el,
        default: "domains",
        callbacks: callbacks,
        root: "settings"
    })


}