
import { removeAllChildNodes } from "../../modules/utils/utils.js"
import { addDomain, listDomains, deleteDomain, updateDomain, verifyDomain, setupDomainInfrastructure } from "../../services/domains/domainsApi.js"



import { Menu } from '../../modules/utils/menu.js'

export async function init() {
    //let tabs = new utils.Tabs({
    //this.domains = await refreshDomainList()
    await createDomainsList()
    app.ui.settings.content.new_domain.onclick = function () {
        addNewDomain()
    }
}

async function createDomainsList() {
    let myDomains = await listDomains()
    let container = app.ui.settings.content.domain_container
    utils.removeAllChildNodes(container)
    console.log("my domains ", myDomains)

    app.ui.settings.content.domain_count.textContent = myDomains.length + " domains"

    container.textContent = "Loading domains..."

    if (myDomains.length == 0) {
        container.textContent = "No domains connected yet. "
    } else {
        container.textContent = ""
    }

    for (let domain of myDomains) {
        let d = utils.addWhatWhere(nwsapp.comp.domain_item, container)
        let el = $$$$(d, "role")
        el.label.textContent = domain.domain


        //make pretty date 
        let prettyDate = new Date(domain.modified).toDateString()
        el.date.textContent = prettyDate

        //let verified = domain.status == "verified" ? true : false
        el.domain_info.classList.toggle("is_active", !domain.ready)


        el.status.textContent = domain.ready ? "Ready" : "Pending"

        //el.verify.classList.toggle("is_hidden", domain.ready)


        let tableContainer = el.record_lines
        utils.removeAllChildNodes(tableContainer)
        let records = domain.records
        for (let record of records) {
            let row = utils.addWhatWhere(nwsapp.comp.domain_row, tableContainer)
            let elements = $$$$(row, "role")
            elements.record_type.textContent = record.type
            elements.record_name.textContent = record.name
            elements.record_value.textContent = record.value
            //elements.record_status.textContent = record.ready
            if (record.ready) {
                row.classList.add("is_ready")
            } else {
                row.classList.add("is_not_ready")
            }
        }

        el.verify.onclick = (e) => {
            checkDomain(domain)

        }

        //get mosue coordinates
        let buttonRect = el.more.getBoundingClientRect()
        let moreValues = [
            /*  {
                 "label": "Edit",
                 "value": "edit",
                 "icon": "edit"
             }, */
            {
                "label": "Remove Domain",
                "value": "delete",
                "icon": "delete"
            },
        ]

        let panel = new Menu({
            "selector": el.more,
            "trigger": false,
            "values": moreValues,
            "label": "Add new element",
            "styles": ["auto", "horizontal", "light", "no_input"],
            "customMode": true,
            "save": false,
            "callback": moreMenu,
            "direction": "down",
            "overlayColor": "rgb(27 40 68)",
            "overlayOpacity": 0.05,
            "width": 220,
            "max_height": 500,
            x: buttonRect.left - 200,
            y: buttonRect.top - 50,
            //closeCallback: this.settings.more.closeCallback,
            //keys: this.settings.more.keys
        })

        function moreMenu(s) {

            console.log(s.value)

            if (s.value == "delete") {
                console.log("delete", domain)
                deleteDomain(domain.id)
                createDomainsList()
            }

        }
    }
    return myDomains

}

async function checkDomain(domain) {

    console.log("verify domain", domain.id)
    await verifyDomain(domain.id)
    createDomainsList()

}

function errorMessage(message) {
    let el = app.ui.domain_modal.content;
    el.error.classList.add("is_active");
    el.error.textContent = message;
}

function addNewDomain() {
    let modal = new utils.Modal({
        comp: nwsapp.comp.add_domain,
        space: "domain_modal",
        //callback: createNewDomain
    });

    let el = app.ui.domain_modal.content;

    el.domain_name.placeholder = "Enter domain name";
    setTimeout(() => {
        el.domain_name.focus();
    }, 250);

    // Regex to validate domains (only subdomains or www)
    const domainRegex = /^(www\.|[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

    // Helper function to validate and update UI
    function validateDomainInput(input) {
        if (input.trim() === "") {
            errorMessage("Domain name cannot be empty.");
            el.add_domain.classList.remove("is_ready");
            return false;
        }

        if (!/^[a-zA-Z0-9-\.]+$/.test(input)) {
            errorMessage("Domain name contains invalid characters.");
            el.add_domain.classList.remove("is_ready");
            return false;
        }

        if (!domainRegex.test(input)) {
            if (/^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/.test(input)) {
                errorMessage(
                    "We currently don't support root level domains. Please enter a subdomain or a domain like www.example.com."
                );
            } else {
                errorMessage("Invalid domain name. Ensure it includes a subdomain.");
            }
            el.add_domain.classList.remove("is_ready");
            return false;
        }

        // Valid domain
        el.error.classList.remove("is_active");
        el.add_domain.classList.add("is_ready");
        return true;
    }

    // Register a listener for input changes
    el.domain_name.addEventListener("input", (e) => {
        validateDomainInput(e.target.value);
    });

    // Register a listener for the enter key to submit
    el.domain_name.onkeypress = (e) => {
        if (e.key === "Enter" && validateDomainInput(el.domain_name.value)) {
            addnew(el.domain_name.value, "unverified");
        }
    };

    // Register a listener for the button click
    el.add_domain.onclick = (e) => {
        addnew(el.domain_name.value, "unverified");
    };

    async function addnew(domain) {
        el.add_domain.textContent = "Adding...";
        el.add_domain.classList.add("is_pending");

        await addDomain(domain, "unverified");
        createDomainsList();
        console.log("modal", modal);
        modal.closeModal();
    }
}

