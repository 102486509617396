
//handles global navigation, etc
import * as utils from '../utils/utils.js';
import { googleLogin } from '../../sections/login.js'


export function routeUpdate(path) {


    //if (!window.app) { app = {} }
    //console.log("route update")

    // Define patterns to ignore
    const ignorePatterns = [
        /\.js$/,         // JavaScript files
        /\.map$/,        // Source maps
        /\.ico$/,        // Favicon
        /^\/api\//,      // API calls
        /\.css$/,        // Stylesheets
        /\.png$/,        // PNG images
        /\.jpg$/,        // JPEG images
        /\.svg$/         // SVG images
    ];

    // Check if the path matches any of the ignore patterns
    if (ignorePatterns.some(pattern => pattern.test(path))) {
        return; // Exit without updating if path matches any ignore pattern
    }

    if (typeof nwsapp == "undefined") {
        console.log("path is undefined... returning")
        return
    }

    // Proceed with route update for valid paths


    app.path = path

    sidePanelUpdate();
}


export function createAppContainer() {

    utils.removeAllChildNodes(document.body);
    document.body.style.height = "100vh"
    document.body.style.overflow = "hidden"
    //set zero padding
    document.body.style.padding = "0"
    document.body.style.margin = "0"
    document.body.style.boxSizing = "border-box"
    //let app = utils.addWhatWhere(nwsapp.comp.app, document.body)

    window.app = {}
    app.ui = {}

    //just add an empty div for now
    let top = document.createElement("div")
    top.setAttribute("role", "app")
    top.classList.add("public_app")
    document.body.appendChild(top)

    app.ui.top = {
        el: top,
        content: $$$$(top, "role")
    }


    let sidebar = utils.addWhatWhere(nwsapp.comp.sidebar, top, "sidebar")
    let main = utils.addWhatWhere(nwsapp.comp.main, top, "main")
}


function tabs(path) {

    //add navigation

    let section = path.split("/")[1]

    let el = utils.$$$$(document, "role")

    let topNav = el.nav_top
    let bottomNav = el.nav_bottom

    let navLocation = bottomNav
    //switch to bottom if agent
    utils.removeAllChildNodes(topNav)
    utils.removeAllChildNodes(bottomNav)

    let nav = utils.addWhatWhere(nwsapp.comp.nav, navLocation)

    let tabs = utils.$$$$(document, "tab")
    for (let key of Object.keys(tabs)) {

        let tab = tabs[key]

        tab.onmouseover = () => {
            utils.addClassesToChildrenOf(el.nav, "is_over", true)
            tab.classList.add("is_highlight")
        }
        tab.onmouseout = () => {
            utils.addClassesToChildrenOf(el.nav, "is_over", false)
        }



        utils.addClassesToChildrenOf(tabs[key], "is_active", false)

        let fullPath = key.split("_").join("/")

        tabs[key].onclick = () => {
            window.router.navigateTo("/" + fullPath)
        }
    }

    if (section && path && tabs[section]) {
        utils.addClassesToChildrenOf(tabs[section], "is_active", true)
    }

}

function profileButtons(el) {

    el.user.onclick = () => {
        el.profile_panel.classList.toggle("is_hidden")
    }


}



export function sidePanelUpdate() {


    let section = app.path.split("/")[1]
    let el = app.ui.sidebar.content

    el.sidebar_toggle.onclick = () => {
        app.ui.sidebar.el.classList.toggle("is_inactive")
    }

    tabs(app.path)
    actions(app.path)
    profileButtons(el)

    let allSections = ["agents", "editor", "threads", "projects"]
    for (let section of allSections) {
        utils.addClassesToChildrenOf(app.ui.sidebar.el, "is_" + section, false)
    }

    utils.addClassesToChildrenOf(app.ui.sidebar.el, "is_" + section, true)

    if (section == "editor") {
        //console.log("is editor", app.ui.sidebar.el)
        app.ui.sidebar.el.classList.add("is_inactive")
    }

    app.ui.sidebar.content.user_details.classList.remove("is_active")
    app.ui.sidebar.content.signin_buttons.classList.remove("is_active")

    if (app?.profile?.isAnonymous) {

        app.ui.sidebar.content.user_details.classList.remove("is_active")
        app.ui.sidebar.content.signin_buttons.classList.add("is_active")

    } else if (app?.profile?.name) {
        app.ui.sidebar.content.profile_user.textContent = app.profile.name
        //app.ui.sidebar.content.signin_buttons.classList.add("is_hidden")

        app.ui.sidebar.content.user_details.classList.add("is_active")
        app.ui.sidebar.content.signin_buttons.classList.remove("is_active")

        //removeAllChildNodes(addImageTo.avatar)
        //addImageTo(profileElements.avatar, app.profile.picture)
        app.ui.sidebar.content.profile_avatar.textContent = ""
        let myImage = document.createElement("img");
        myImage.style.objectFit = "cover";
        myImage.crossOrigin = "Anonymous";
        myImage.style.width = "100%";
        myImage.style.height = "100%";
        myImage.src = app.profile.picture;

        // Handle image load errors
        myImage.addEventListener('error', function () {
            myImage.style.display = "none";
        });
        app.ui.sidebar.content.profile_avatar.appendChild(myImage);
    } else {
        app.ui.sidebar.content.user_details.classList.remove("is_active")
        app.ui.sidebar.content.signin_buttons.classList.add("is_active")

    }



    app.ui.sidebar.content.user_menu.onmouseover = () => {
        utils.addClassesToAllChildrenOf(app.ui.sidebar.el, "user_profile", true)
    }

    //disable on roll out
    app.ui.sidebar.content.user_menu.onmouseout = () => {
        utils.addClassesToAllChildrenOf(app.ui.sidebar.el, "user_profile", false)
    }

    app.ui.sidebar.content.login_email.onclick = () => {
        window.router.navigateTo("/login")
    }

    app.ui.sidebar.content.login_google.onclick = () => {
        googleLogin()
    }

    //if app.profile

}




function actions(path) {


    let section = path.split("/")[1]

    let actionButtons = utils.$$$$(document, "actions")
    for (let key of Object.keys(actionButtons)) {

        utils.addClassesToChildrenOf(actionButtons[key], "is_active", false)

        actionButtons[key].onclick = () => {
            window.router.navigateTo("/manage/" + key)
        }
    }

}