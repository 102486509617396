

import { googleLogin } from '../../services/auth/session.js'
import { Menu } from '../utils/menu.js'

export class LineItems {
    constructor(settings) {
        this.settings = settings;
        this.data = settings.data

        this.selecteditems = new Set()
        this.items = {}

        this.init();
    }

    init() {
        utils.removeAllChildNodes(this.settings.container);
        let el = utils.addWhatWhere(nwsapp.comp.line_items, this.settings.container);
        this.elements = utils.$$$$(el, "role")

        console.log(this.elements)

        this.addItems(this.data)
        //this.manageitemsearch()
    }


    addItems(items) {

        let self = this
        let index = 1

        this.elements.status.textContent = items.length + " items found"

        utils.removeAllChildNodes(this.elements.container)

        for (let item of items) {
            let itemEl = utils.addWhatWhere(this.settings.line_item, this.elements.container);
            let el = utils.$$$$(itemEl, "role")

            this.items[item.id] = itemEl
            this.settings.dataMap(item, el) //assign values


            if (this.settings.events.doubleClick) {
                itemEl.ondblclick = () => {
                    this.settings.events.doubleClick(item)
                }
            }

            el.more.onclick = (e) => {
                this.showMenu(item, el.more, e)
            }

            itemEl.onclick = () => {
                this.toggleitemselection(item.id)
            }

            if (this.elements.toggle_selection) {
                this.elements.toggle_selection.onclick = () => {
                    this.toggleSelectAllOrNone()
                }
            }

            //check if odd or even 
            if (index % 2 === 0) {
                itemEl.classList.add("is_odd")
            }
            index++

        }

    }

    manageitemsearch() {
        this.elements.project_search.placeholder = "Search for items..."
        this.elements.project_search.onkeyup = (e) => {
            let value = e.target.value.toLowerCase()
            let filteredByKeyword = this.data.filter(item => {
                return item.name.toLowerCase().includes(value)
            })

            utils.removeAllChildNodes(this.elements.container);
            addItems(filteredByKeyword);


        }
    }

    toggleSelectAllOrNone() {

        if (this.selecteditems.size == Object.keys(this.items).length) {
            this.selecteditems.clear()
        } else {
            this.selecteditems = new Set(Object.keys(this.items))
        }

        this.showSelected()
    }

    showSelected() {
        for (let key of Object.keys(this.items)) {
            let itemItem = this.items[key]
            if (this.selecteditems.has(key)) {
                utils.addClassesToChildrenOf(itemItem, "is_selected", true)
            } else {
                utils.addClassesToChildrenOf(itemItem, "is_selected", false)
            }
        }
    }

    toggleitemselection(id) {

        if (this.selecteditems.has(id)) {
            this.selecteditems.delete(id)
        } else {
            this.selecteditems.add(id)
        }
        this.showSelected()

    }

    showMenu(item, dots, e) {

        nwsapp.dropdownIsOpen = true
        //get mosue coordinates
        let x = e.clientX
        let y = e.clientY
        let buttonRect = dots.getBoundingClientRect()


        for (let key of Object.keys(this.settings.more.values)) {
            this.settings.more.values[key].item = item
        }

        let panel = new Menu({
            "selector": dots,
            "trigger": true,
            "theLocation": app.ui.top.el,
            "values": this.settings.more.values,
            "label": "Add new element",
            "styles": ["auto", "horizontal", "light", "no_input"],
            "customMode": true,
            "save": false,
            "callback": this.settings.more.callback,
            "direction": "down",
            "overlayColor": "rgb(27 40 68)",
            "overlayOpacity": 0.08,
            "width": 240,
            "max_height": 500,
            x: x - 170,
            y: buttonRect.top - 50,
            closeCallback: this.settings.more.closeCallback,
            keys: this.settings.more.keys

        })

    }

}


