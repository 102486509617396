
import { css_spacing } from './spacing.js';
export var css_position = {

    init: function () {
        //css_classes.add()

        if (!this.ui) {

            let container = $$$(document, "data-group", "position")

            this.ui = {
                "panel": container,
                "allDataInputGroups": $$$(container, "data-input-group"),
                "position_dropdown": $$$(document, "data-dropdown", "position"),



                "selector": container,

                "align_presents": $$$(container, "position-combo", "align-presets"),

                "labelContainer": container.querySelector(".reg_label"),
                "label": container.querySelector(".field_value"),
                "allControlers": $$$(container, "controller"),
                "spacing_config": $$$(container, "data-panels-spacing", "config"),
                "allDataInputGroup": $$$(container, "data-input-pos", "position"), // this is the field in the position panel. Better think of a better name
                "z_index": $$$(container, "z-index-input", "value"),

                "float": $$$(document, "layout-combo", "float"),
                "clear": $$$(document, "layout-combo", "clear"),


            }
        }


        css_utils.combo({
            "selector": this.ui.float,
            "save": true,
        })


        css_utils.combo({
            "selector": this.ui.clear,
            "save": true,
        })


        this.ui.input = $$$(css_position.ui.allDataInputGroup, "data-input", "input")

        let positions = [

            /*   {
                  label: "Static",
                  value: "static",
                  comment: "Default value. Elements render in order, as they appear in the document flow."
              }, */
            {
                label: "Static",
                value: "static",
                comment: "Default value. Elements render in order, as they appear in the document flow."
            },
            {
                label: "Relative",
                value: "relative",
                comment: "Relative to its normal position."
            },
            {
                label: "Absolute",
                value: "absolute",
                comment: "Relative to its first positioned (not static) ancestor element."
            },
            {
                label: "Fixed",
                value: "fixed",
                comment: "Relative to the browser window."
            },
            {
                label: "Sticky",
                value: "sticky",
                comment: "Based on the user's scroll position."
            },


        ]

        css_utils.dropdown({
            "selector": css_position.ui.position_dropdown,
            "values": positions,
            "label": "Position",
            "style": null,
            "callback": positionSettings,
            "direction": "center",

        })

        function positionSettings(position) {

            showOrHidePositionPresets()

        }

        function showOrHidePositionPresets() {
            if (publicai.active.lastStyleInStack.css.position == "absolute") {
                css_position.ui.align_presents.classList.remove("hidden")
            } else {
                css_position.ui.align_presents.classList.add("hidden")

            }
        }



        showOrHidePositionPresets()

        function comboUpdate(settings) {
            for (let key of Object.keys(css_position.alignPresets[settings])) {
                publicai.active.lastStyleInStack.css[key] = css_position.alignPresets[settings][key]
            }
            css_management.manage()
            css_position.configureSpacing()

        }

        css_utils.combo({
            "selector": this.ui.align_presents,
            "save": false,
            "callback": comboUpdate
        })



        if (!publicai.active.lastStyleInStack.css["z-index"]) {
            publicai.active.lastStyleInStack.css["z-index"] = publicai.selected.settings.zIndex
        } else {

            publicai.selected.settings.zIndex = parseInt(publicai.active.lastStyleInStack.css["z-index"])
            //console.log("refreshed", publicai.selected.settings.zIndex)
        }




        css_utils.input({
            "selector": css_position.ui.z_index,
            //"variable": css_position.ui.z_index.getAttribute("data-input-group"),
            "variable": "z-index",
            "slider": true,
            "defaultSliderValue": 5,
            "ranges": {
                "min": 0,
                "max": 100,
            },
            "units": false,
            "number": true,
            "custom_range": true,
            "validation": "number",
            callback: updateZIndex
        })

        function updateZIndex(value) {
            //console.log("caallback,", value)
            publicai.selected.settings.zIndex = value
            css_management.manage()
        }


        this.configureSpacing()


    },




    addDragAndResizehandlers: function () {

        return

        let el = publicai.selected.element
        let entry = publicai.active.lastStyleInStack
        entry.locked = false


        el.style.position = 'absolute';
        el.style.left = entry.css.left;
        el.style.top = entry.css.top;
        el.style.zIndex = entry.css.zIndex;

        let originX, originY, originalLeft, originalTop;



        el.addEventListener('mousedown', function (e) {
            console.log("mousedown")
            if (_create.currentDragElement !== null && _create.currentDragElement !== el) {
                console.log("another element is already being dragged");
                return;
            }

            _create.currentDragElement = el;
            el.isDragging = true;

            const parentRect = el.parentElement.getBoundingClientRect();
            const rect = el.getBoundingClientRect();
            originX = e.clientX;
            originY = e.clientY;
            originalLeft = (rect.left - parentRect.left) / parentRect.width * 100;
            originalTop = (rect.top - parentRect.top) / parentRect.height * 100;

            //console.log(`Initial left: ${originalLeft}%, Initial top: ${originalTop}%`);
        });

        let alertedAboutTheLockStatus = false
        document.addEventListener('mousemove', function (e) {
            console.log("mousemove")

            if (!entry.locked) {

                if (!el.isDragging || _create.currentDragElement !== el) return;
                el.style.zIndex = 999
                const parentRect = el.parentElement.getBoundingClientRect();
                let newX = ((e.clientX - originX) / parentRect.width) * 100 + originalLeft;
                let newY = ((e.clientY - originY) / parentRect.height) * 100 + originalTop;


                newX = Math.round(newX / publicai.snapConstant) * publicai.snapConstant //snap to 5px. To revise
                newY = Math.round(newY / publicai.snapConstant) * publicai.snapConstant

                el.style.left = newX + '%';
                el.style.top = newY + '%';
                _create.updateLayerinfo(el.style)

                //console.log(`Updated left: ${newX}%, Updated top: ${newY}%`);
            } else {
                if (!alertedAboutTheLockStatus) {
                    notifications.show([
                        ["Ooops.", "This element is locked. Unlock it to move it around."]
                    ])
                    alertedAboutTheLockStatus = true
                }
            }

        });

        document.addEventListener('mouseup', function (e) {
            if (!el.isDragging || _create.currentDragElement !== el) return;
            el.isDragging = false;
            _create.currentDragElement = null;

            entry.css.left = el.style.left;
            entry.css.top = el.style.top;
            el.style.zIndex = entry.css.zIndex;

            _create.updateLayerinfo(null)

            //console.log(`Final left: ${entry.css.left}, Final top: ${entry.css.top}`);
        });
    },


    configureSpacing: function () {

        function closePanel(where) {

            css_position.ui.spacing_config.classList.remove("active")
            document.removeEventListener('click', closePanel);
            css_utils.highlightThisPanel(null, false)
        }

        function refreshAllValues(applyCCSS = false) {

            for (let box of css_position.ui.allControlers) {
                let setting = box.getAttribute("controller")
                let valueResponse = css_utils.getExistingValues(setting)

                box.classList.remove("hasvalue")
                box.classList.remove("hasinheritedvalue")

                if (valueResponse.style) {
                    box.classList.add(valueResponse.style)
                    //render.addClassesToAllChildrenOf(box, valueResponse.style, true)
                }
                box.textContent = valueResponse.value
            }

            if (applyCCSS) {
                applyCSS()
            }
        }

        refreshAllValues(false)


        for (let box of css_position.ui.allControlers) {
            css_spacing.configureSpacingWidget(box, refreshAllValues, closePanel, css_position.ui)
        }
    },

    alignPresets: {
        tl: {
            "top": "0px",
            "left": "0px",
            "right": "",
            "bottom": "",
        },
        tr: {
            "top": "0px",
            "right": "0px",
            "left": "",
            "bottom": "",
        },
        bl: {
            "bottom": "0px",
            "left": "0px",
            "top": "",
            "right": "",
        },
        br: {
            "bottom": "0px",
            "right": "0px",
            "top": "",
            "left": "",
        },
        reset: {
            "bottom": "",
            "right": "",
            "top": "",
            "left": "",
        },
        top_full: {
            "top": "0px",
            "left": "0px",
            "right": "0px",
            "bottom": "",
            "width": "100%",
        },
        bottom_full: {
            "bottom": "0px",
            "left": "0px",
            "right": "0px",
            "top": "",
            "width": "100%",
        },
        left_full: {
            "top": "0px",
            "left": "0px",
            "bottom": "0px",
            "right": "",
            "height": "100%",
        },
        right_full: {
            "top": "0px",
            "right": "0px",
            "bottom": "0px",
            "left": "",
            "height": "100%",
        },

    }


}