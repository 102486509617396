export var css_classes = {


    init: function () {

        if (!this.ui) {
            let container = $$$(document, "data-group", "classes")
            this.ui = {
                "classes_container": $$$(container, "css-classes", "container"),
                "panel": container,
                "add_new_class_button": $$$(document, "css-classes", "add_new"),
                "expand_panel": $$$(document, "css-classes", "expand_panel"),
                "state_panel": $$$(document, "css-classes", "state_panel"),

                "component_input": $$$(document, "css-classes", "component"),

                "div_state": $$$(document, "states-group", "div"),
                "input_state": $$$(document, "states-group", "input"), //different controls for the input, such as focused, etc

                "breakpointsGroup": container.querySelector(".breakpoint_element"),
                "allBreakpointsIcons": $$$(container.querySelector(".breakpoint_element"), "breakpoint-icon"),


                "css_suggestions": $$$(document, "css-classes", "suggestions"),
                "suggestion_label": $$$(document, "css-classes", "suggestion_label"),
                "moreOptions": $$$(container, "data-more", "more"),
            }
        }






        if (this.ui.classes_container) {

        }

        this.setUpMoreButton()


        let classes = publicai.selected.settings.classes

        //consider doing the icons part below more elegantly with no queryselectors

        let allClassElements = document.querySelectorAll(".class_element")

        for (let item of allClassElements) {
            item.remove()
        }
        let inputField = document.querySelector(".new_class_input")
        if (inputField) {
            inputField.remove()
        }

        //removeAllChildNodez(this.ui.classes_container)

        css_classes.addNewClassesConfig()
        css_classes.addStateConfig()

        for (let item of css_classes.ui.allBreakpointsIcons) {
            item.classList.remove("active")
        }

        let breakpointAttr = publicai.breakpointFriendlyNames[publicai.active.breakpoint].toLowerCase().replace(" ", "")
        $$$(css_classes.ui.breakpointsGroup, "breakpoint-icon", breakpointAttr).classList.add("active")

        //breakpoint.querySelector(".class_item_label").textContent = publicai.breakpointFriendlyNames[publicai.active.breakpoint]
        css_classes.ui.breakpointsGroup.classList.add("breakpoint")
        css_classes.ui.breakpointsGroup.setAttribute("tooltip", "Breakpoint: " + publicai.breakpointFriendlyNames[publicai.active.breakpoint])


        for (let item of classes) {


            let class_item = addWhatWhere(components.css.class_element, this.ui.classes_container)
            let label = class_item.querySelector(".class_item_label")
            let moreArrow = class_item.querySelector(".class_more")

            label.textContent = item.name

            class_item.onmouseenter = function () {
                console.log("enter")
                moreArrow.classList.add("active")
            }
            class_item.onmouseleave = function () {
                moreArrow.classList.remove("active")
            }

            class_item.onclick = function (e) {
                e.stopPropagation()
                e.preventDefault()
                css_classes.ui.expand_panel.classList.remove("active")
                css_classes.showMoreMenu({
                    "ui_item": class_item,
                    "value": item.name,
                    "main_class": item.name,
                    "is_combo": false
                })



            }

            //add combo classes
            if (item.combo && item.combo.length > 0) {
                for (let combo of item.combo) {
                    if (!combo.includes("__")) { //means it's a breakpoint
                        //this is a combo class
                        let combo_item = addWhatWhere(components.css.class_element, this.ui.classes_container)
                        combo_item.classList.add("public-combo")

                        let label = combo_item.querySelector(".class_item_label")
                        let moreArrow = combo_item.querySelector(".class_more")
                        label.textContent = combo

                        combo_item.onmouseenter = function () {
                            moreArrow.classList.add("active")
                        }
                        combo_item.onmouseleave = function () {
                            moreArrow.classList.remove("active")
                        }

                        combo_item.onclick = function (e) {
                            e.stopPropagation()
                            e.preventDefault()
                            combo_item.style.zIndex = 9999
                            css_classes.ui.expand_panel.classList.remove("active")
                            css_classes.showMoreMenu({
                                "ui_item": combo_item,
                                "value": combo,
                                "main_class": item.name,
                                "is_combo": true
                            })
                        }
                    }
                }
            }

            if (publicai.active.state) {

                let stateItem = addWhatWhere(components.css.class_element, this.ui.classes_container)
                stateItem.classList.add("public-state")
                let label = stateItem.querySelector(".class_item_label")
                label.textContent = publicai.active.state
            }
        }


    },

    

    setUpMoreButton: function () {


        //css_classes.removeAllSettings()

        let moreOptions = [
            {
                "label": "Reset All Settings",
                "value": "reset",
                "icon": "format_color_reset",
                "comment": "Removes all CSS properties from the class or combo."
            },
            {
                "label": "Avoid Conflicts",
                "value": "conflicts",
                "icon": "check",
                "comment": "Adds unique components to the class or combo to avoid conflicts."
            },
            {
                "label": "Delete Unused",
                "value": "delete_unused",
                "icon": "content_copy",
                "comment": "Delete unused classes"
            },
            {
                "label": "Copy Values",
                "value": "copy",
                "icon": "content_copy",
                "comment": "Copies values to clipboard so they can be pasted to another element."
            },
        ]

        if (publicai.stylesCliboard) {
            moreOptions.push({
                "label": "Paste Values",
                "value": "paste",
                "icon": "content_paste",
                "comment": "Pastes values from clipboard to the class or combo."
            })
        }


        css_utils.setup_reg_dropdown({
            "selector": css_classes.ui.moreOptions,
            "value": "Select",
            "multiSelect": false,
            "search": false,
            "values": moreOptions,
            "label": "Add new element",
            "styles": ["auto", "horizontal", "light", "no_input"],
            "customMode": true,
            "save": false,
            "callback": optionscallback,
            "direction": "down",
            "overlayColor": "black",
            "overlayOpacity": 0.2,
            "width": "100%"
        })

        function optionscallback(s) {
            //attribute.mode = s.value

            switch (s.value) {
                case "reset":
                    css_classes.removeAllSettings()
                    break;
                case "copy":
                    css_classes.copyValues()
                    break;
                case "delete_unused":
                    css_classes.deleteUnusedClasses()
                    break;
                case "conflicts":
                    css_classes.renameAllChildrenToAvoidConflicts()
                    break;
                case "paste":
                    css_classes.pasteValues()
                    break;
            }
        }




    },

    copyValues: function () {
        let activeClass = { ...publicai.active.lastStyleInStack.css }
        publicai.stylesCliboard = activeClass
        css_classes.setUpMoreButton()
    },

    pasteValues: function () {
        publicai.active.lastStyleInStack.css

        for (let key of Object.keys(publicai.stylesCliboard)) {
            publicai.active.lastStyleInStack.css[key] = publicai.stylesCliboard[key]
        }

        css_management.manage(true)


    },

    removeAllSettings: function () {


        let stack = publicai.active.lastStyleInStack.css

        for (let item of Object.keys(stack)) {
            delete stack[item]
        }

        css_management.refreshCssStack()
        css_management.manage()

    },

    closeExpandPanel: function (e) {
        css_classes.ui.classes_container.classList.remove("active")
        css_classes.ui.expand_panel.classList.remove("active")


        console.log("closing panel")

        if (e && e.target != css_classes.ui.expand_panel) {
            document.removeEventListener("click", css_classes.closeExpandPanel)
            if (nws.currentNewComboLabel && nws.currentNewComboLabel != "" && publicai.selected.settings.classes.length != 0) {
                css_classes.saveTheClass(nws.currentNewComboLabel)
            }
            if (publicai.selected.settings.classes.length == 0) {
                //css_classes.saveClass(nws.currentNewComboLabel) 
                console.log("save class - error - to look into")
            }



        }
    },

    closeStatePanel: function (e) {
        css_classes.ui.state_panel.classList.remove("active")
        document.removeEventListener("click", css_classes.closeStatePanel)
    },


    addStateConfig: function () {

        css_classes.ui.add_new_class_button.onclick = function (e) {
            e.stopPropagation()
            e.preventDefault()

            document.addEventListener("click", css_classes.closeStatePanel)
            css_classes.ui.state_panel.classList.add("active")

            publicai.classesExpansionPanelCloseFunction = css_classes.closeStatePanel //picks up the click over the iframe and closes the panel



            let allStates = $$$(css_classes.ui.state_panel, "data-css-state")

            let contentType = "div" //allows for different states to be displayed
            if (publicai.selected.settings.type == "input_basic" || publicai.selected.settings.type == "textarea_basic" || publicai.selected.settings.type == "dropdown_basic") {
                contentType = "input"
            }

            css_classes.ui.div_state.classList.remove("active")
            css_classes.ui.input_state.classList.remove("active")
            css_classes.ui[contentType + "_state"].classList.add("active")


            for (let state of allStates) {
                state.onclick = function (e) {
                    e.stopPropagation()
                    e.preventDefault()
                    css_classes.closeStatePanel(e)
                    css_classes.createState(state.getAttribute("data-css-state"))
                }
            }
        }

    },

    createState: function (state_name) {



        css_classes.ui.expand_panel.classList.remove("active")
        let originalClass = publicai.selected.settings.classes[0].name
        let classes = publicai.selected.settings.classes
        publicai.active.state = state_name

        /*  if (!publicai.css[publicai.active.path + "." + state_name]) {
             publicai.css[publicai.active.path + "." + state_name] = {}
         }
          */
        css_management.refreshCssStack()
        css_management.manage(true)
    },



    saveTheClass: function (class_name, performValidation = true) {


        if (class_name == "New Combo" || class_name == " " || class_name == "  " || class_name == "_" || class_name == "__") {
            return
        }


        //saving combo 

        let classes = publicai.selected.settings.classes

        if (performValidation) {
            class_name = css_classes.validateClassName(class_name, null)
        }
        css_classes.ui.expand_panel.classList.remove("active")


        if (classes.length == 0) {
            //create a brand new class
            publicai.selected.settings.classes.push({ "name": class_name, "combo": [] })
            if (!publicai.css[class_name]) {
                publicai.css[class_name] = {
                    combo: {},
                    state: {}
                }
            }

            //console.log("--- creating main class", publicai.css, class_name)

        } else {
            //create a combo class
            let originalClass = publicai.selected.settings.classes[0].name

            if (!publicai.selected.settings.classes[0].combo.includes(class_name)) {
                //console.log("----have pushed the combo")
                publicai.selected.settings.classes[0].combo.push(class_name) //push the combo
            }

            if (publicai.active.lastStyleInStack.css.combo[class_name]) {
                //console.log("----combo exists")
                //combo exists. Just apply it. 
                //do nothing

            } else {
                //combo doesn't exists. Create it. 



                if (!publicai.active.lastStyleInStack.css.combo && animator_timeline.mode != "keyframes") {
                    publicai.active.lastStyleInStack.css.combo = {
                        combo: {},
                        state: {}
                    }
                }


                //only create it if it doesn't exist
                publicai.active.lastStyleInStack.css.combo[class_name] = {
                    combo: {}
                }


                //public.css[originalClass + publicai.classSeparator + class_name] = {}
            }
        }

        publicai.selected.element.classList.add(class_name)
        css_management.manage(true)
    },




    addNewClassesConfig: function () {

        //css_classes.ui.expand_panel.classList.remove("active")
        css_classes.ui.classes_container.onclick = function (e) {
            nws.currentNewComboLabel = ""
            css_classes.ui.classes_container.classList.add("active")

            document.addEventListener("click", css_classes.closeExpandPanel)
            publicai.classesExpansionPanelCloseFunction = css_classes.closeExpandPanel //picks up the click over the iframe and closes the panel

            css_classes.ui.expand_panel.classList.add("active")

            //remove all previouslly added new items
            document.querySelectorAll(".new_class_input").forEach(item => item.remove())

            //let new_item = addWhatWhere(components.css.class_element, css_classes.ui.classes_container)
            let new_item = addWhatWhere(components.ui.new_class_input, css_classes.ui.classes_container)
            let input = $$$(new_item, "data-class", "input")
            input.focus()

            makeSuggestions(null)

            input.oninput = function (e) {
                let multiplier = 5
                let w = (input.value.length * multiplier) + "px"

                makeSuggestions(input.value)

                nws.currentNewComboLabel = input.value
                if (input.value.length > 10) {
                    input.style.minWidth = w
                    input.style.width = w
                }
            }
            e.stopPropagation()
            e.preventDefault()
        }

        function makeSuggestions(str) {

            let keyword = str || "";
            let suggestionsContainer = css_classes.ui.css_suggestions;
            removeAllChildNodes(suggestionsContainer);
            let classesToSuggest;

            //console.log("ll", publicai.selected.settings.classes.length)

            if (publicai.selected.settings.classes.length == 0) {
                // Showing all classes and filtering by keyword
                css_classes.ui.suggestion_label.textContent = "Create new or select existing";
                classesToSuggest = Object.keys(publicai.css)
                    .filter(key => !key.includes(publicai.classSeparator))
                    .filter(key => key.toLowerCase().includes(keyword.toLowerCase())); // Additional filter by keyword
                //console.log("classesToSuggest 0", classesToSuggest);
            } else {
                // Only show combo classes of the main class and filter by keyword
                css_classes.ui.suggestion_label.textContent = "Create new or select existing";
                let mainClassName = publicai.selected.settings.classes[0].name;



                classesToSuggest = Object.keys(publicai.active.lastStyleInStack.css.combo)
                classesToSuggest = Object.keys(publicai.active.lastStyleInStack.css.combo).filter(item => !item.includes("__")); //do not suggest breakpoints

                if (classesToSuggest.length == 0) {
                    let createCopy = addWhatWhere(components.css.class_element, suggestionsContainer)
                    createCopy.classList.add("create_copy")
                    createCopy.textContent = "Create Copy"
                    createCopy.onclick = function (e) {
                        let label = generateShortId()
                        css_classes.saveTheClass(label)
                    }

                }


                /*   .filter(key => key.startsWith(mainClassName + publicai.classSeparator)) // Ensures correct class is being checked
                  .map(key => key.split(publicai.classSeparator)[1]) // Extract combo class
                  .filter(comboClass => comboClass.toLowerCase().includes(keyword.toLowerCase())); // Filter by keyword */
                //console.log("classesToSuggest", classesToSuggest);
            }


            for (let item of classesToSuggest) {

                let suggestion
                if (publicai.selected.settings.classes.length == 0) {
                    //suggestion main classes
                    suggestion = addWhatWhere(components.css.class_element, suggestionsContainer)
                    suggestion.classList.add("public_suggestion")
                    suggestion.textContent = item
                } else {
                    //suggesting combo
                    if (!publicai.selected.settings.classes[0].combo.includes(item)) {
                        suggestion = addWhatWhere(components.css.class_element, suggestionsContainer)
                        suggestion.classList.add("public_suggestion")
                        suggestion.textContent = item
                    }
                }

                if (suggestion) {

                    suggestion.onclick = function (e) {

                        e.preventDefault()
                        e.stopPropagation()

                        nws.currentNewComboLabel = suggestion.textContent

                        css_classes.ui.expand_panel.classList.remove("active")

                        if (publicai.selected.settings.classes.length != 0) {
                            css_classes.saveTheClass(suggestion.textContent, false)
                        } else {
                            css_classes.saveTheClass(suggestion.textContent, false)
                            //public.selected.settings.classes.push({ "name": suggestion.textContent, "combo": [] })
                        }
                        //_create.deselectAllElements()
                        css_management.manage(true)
                    }
                }

            }

        }

        /*
               
        
        
                //let input = $$$(new_item, "data-class", "input")
        
                new_item.classList.add("brand_new")
        
        
        
                input.onblur = function () {
                    console.log("blur!!")
                }
        
                nws.currentNewComboLabel = label.textContent
        
                label.oninput = function () {
                    nws.currentNewComboLabel = label.textContent
        
                }
                //add key listener on enter
                label.addEventListener("keydown", function (e) {
                    if (e.keyCode === 13) {
                        e.preventDefault()
                        e.stopPropagation()
                        document.removeEventListener("click", css_classes.closeExpandPanel)
                        label.blur()
                        css_classes.saveTheClass(label.textContent)
                    }
                })
         */

    },




    closeMorePanel: function () {

        console.log("close more...")

        let allExtraPanels = css_classes.ui.panel.querySelectorAll(".extra_panel_holder")
        for (let item of allExtraPanels) {
            removeAllChildNodes(item)
        }

        document.removeEventListener("click", css_classes.closeMorePanel)
    },


    deletePreviousClass: function () {

        //triggered on del key 
        console.log("delete prev")

        let is_combo = true
        if (publicai.css[publicai.active.lastStyleInStack.name]) {
            is_combo = false
            //combos are created differently namewise
        }


        css_classes.deleteClass({
            "ui_item": null,
            "value": publicai.active.lastStyleInStack.name,
            "main_class": null,
            "is_combo": is_combo
        })

        css_management.manage(true)




    },



    resetClasses: function () {
        //reapply classes on renames, deletes, etc
        let el = publicai.selected.element
        let classes = publicai.selected.settings.classes
        el.classList = ""
        for (let item of classes) {
            el.classList.add(item.name)
            for (let combo of item.combo) {
                if (!combo.includes("__")) {
                    el.classList.add(combo) //do not include breakpoint classes into the combo list. They're automatically added by the browser via @media
                }
            }
        }
        if (publicai.selected.settings.type == "body") {
            el.classList.add("public-active-body")
        } else {
            el.classList.add("public-active")
        }
        css_management.manage()
        //_create.activateSpecificElement(publicai.selected.settings.id)
    },





    renameClass: async function (settings, class_item, item) {

        console.log("rename ", settings)

        let originalClassName = settings.value

        css_classes.renamingClass = true

        let label = settings.ui_item.querySelector(".class_item_label")

        settings.ui_item.querySelector(".class_more").classList.remove("active")
        this.closeMorePanel()

        settings.ui_item.classList.add("editing_name")

        label.setAttribute("contenteditable", "true")
        label.focus()

        //select all content in label 
        var range = document.createRange();
        range.selectNodeContents(label);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);

        document.addEventListener("click", saveClass)

        //listen to enter key
        label.addEventListener("keydown", function (e) {
            if (e.keyCode === 13) {
                e.preventDefault()

                saveClass()
            }
        })

        async function saveClass() {

            console.log("saved!!!")

            css_classes.renamingClass = false
            label.blur()
            document.removeEventListener("click", saveClass)
            label.setAttribute("contenteditable", "")

            settings.ui_item.classList.remove("editing_name")




            //item = label.textContent
            let validatedClass = css_classes.validateClassName(label.textContent)
            if (validatedClass != false && validatedClass != "false") {
                label.textContent = validatedClass
                renameCssKey(settings, validatedClass)
                renameClassEntry(settings, validatedClass)


                let rootFolder = _find.getRoot(publicai.active.page.id)

                if (rootFolder == null || rootFolder == "null" || !rootFolder) {
                    for (let page of publicai.pages.content) {
                        //this will not open up folders, since they'd be using a different css

                        css_classes.performClassRenameAcrossAllPages(page, originalClassName, validatedClass, false)
                    }
                } else {
                    //this will open up folders since it's the same css
                    css_classes.performClassRenameAcrossAllPages(rootFolder, originalClassName, validatedClass, true)
                }



                css_classes.resetClasses() //resets the classes on the object and shuffles the css object

            } else {
                notifications.show([
                    ["Oops", "You may be using a forbidden keyword or character."]
                ])
                return

            }

            let index = 0
            for (let classList of publicai.selected.settings.classes) {
                if (classList == item) {
                    publicai.selected.settings.classes[index] = validatedClass
                    css_management.manage(true)
                }
                index++
            }

            //everything needs be refreshed as other elements may have been using the old class name. To consider running on each element
            publicai.active.bodyElement.remove()
            await play_interactive.addBodyScene() //adding main scene
            play_interactive.createPageElements(publicai.active.page.content[0].content)

        }
    },


    deleteUnusedClasses: function () {

        let cssID

        let rootFolder = _find.getRoot(publicai.active.page.id)
        if (!rootFolder || typeof rootFolder == "undefined" || rootFolder == undefined) {
            cssID = publicai.root.pages.id
            rootFolder = publicai.pages
        } else {
            cssID = rootFolder.id
        }

        let cssObject = nwsapp.currentProject.config.css[cssID]
        let unusedClasses = []

        //avoid folders with "schemaroot"

        for (let theClass of Object.keys(publicai.css)) {

            let isInUse = _find.isClassInUse(publicai.pages, theClass, rootFolder.id)
            if (!isInUse) {
                unusedClasses.push(theClass)
            }

        }

        let length = unusedClasses.length

        let allClasses = unusedClasses.join(", ")

        notifications.show([
            ["Alright", "The following classses (" + length + ") have been removed " + allClasses]
        ]);


        console.log("Deleted unused", unusedClasses)

        for (let item of unusedClasses) {
            delete publicai.css[item]
        }



    },



    renameAllChildrenToAvoidConflicts: function () {

        let nodeCopy = JSON.parse(JSON.stringify(publicai.selected.settings))


        _save_as_template.foundClasses = {}
        _save_as_template.transitions = {}
        _save_as_template.addClassesFromNode(nodeCopy) //save the classes of the node
        _save_as_template.getAllClassesForNode(nodeCopy) //saves the classes of the children
        //console.log(_save_as_template.foundClasses)

        //public.active.rootFolder = rootFolder

        for (let className of Object.keys(_save_as_template.foundClasses)) {

            let oldName = className

            let newName
            if (oldName.includes("_")) {
                newName = oldName.substr(0, oldName.indexOf("_"));
            } else {
                newName = oldName.substr(0, 10);
            }


            //let newName = css_classes.validateClassName(className + "_" + generateShortId())
            newName = css_classes.validateClassName(newName + "_" + generateShortId(5))


            if (publicai.selected.settings.classes[0].name == oldName) {
                publicai.selected.settings.classes[0].name = newName
            }


            //css_classes.performClassRenameAcrossAllPages(publicai.active.body, oldName, newName, true)
            css_classes.performClassRenameAcrossAllPages(publicai.active.rootFolder, oldName, newName, true)




            let oldCss = { ...publicai.css[oldName] }
            delete publicai.css[oldName]
            publicai.css[newName] = oldCss


        }

        editor.selectBody()
        play_interactive.forceRecreateSelected(false)



    },



    performClassRenameAcrossAllPages(node, oldName, newName, renameIntoFolders = false) {

        //console.log("performClassRenameAcrossAllPages", node, oldName, newName, renameIntoFolders)
        //node = publicai.active.rootFolder
        // If the scene has children, update their ancestor IDs and recursively update their IDs
        if (node.content && node.content.length > 0) {
            for (let child of node.content) {
                //calls itself for all children

                if (child.classes) {
                    if (child.classes[0].name == oldName) {
                        //console.log("found at", child, "renaming to", newName, "from", oldName)
                        child.classes[0].name = newName
                    }

                    for (let combo of child.classes[0].combo) {
                        if (combo == oldName) {
                            child.classes[0].combo[child.classes[0].combo.indexOf(combo)] = newName
                        }
                    }
                }

                if (!renameIntoFolders && child.type == "folder") {
                    return
                }

                if (child.type == "folder") {
                    console.log("opening folder", child.properties.name)
                }

                css_classes.performClassRenameAcrossAllPages(child, oldName, newName, renameIntoFolders) // Recursively update child IDs
            }
        }
    },



    validateClassName(class_name, suffix = 0) {

        const forbiddenKeywords = ["span"]; // Add more as needed


        const forbiddenChars = "!@#$%^&*()+=[]{};:'\"";
        let modifiedClassName = class_name.replace(/\s+/g, '_');

        if (suffix > 0) {
            modifiedClassName += `_${suffix}`;
        }

        // Check if the class name exactly matches a forbidden keyword
        if (forbiddenKeywords.includes(modifiedClassName.toLowerCase())) {
            console.log(`Invalid class name: '${modifiedClassName}' is a forbidden keyword. Trying next suffix.`);
            notifications.show([
                ["Ooops!", "Illegal or conflicting class found: " + modifiedClassName + ". Fixing..."]
            ]);
            return validateClassName(class_name, suffix + 1);
        }

        if (modifiedClassName.split('').some(char => forbiddenChars.includes(char))) {
            console.log(`Invalid class name: '${modifiedClassName}' still contains forbidden characters. Trying next suffix.`);
            notifications.show([
                ["Ooops!", "Illegal or conflicting class found: " + modifiedClassName + ". Fixing..."]
            ]);
            return validateClassName(class_name, suffix + 1);
        }

        // Return the modified class name if it passes all checks
        return modifiedClassName;
    },


    findAllCombosFor(class_name) {
        let foundCombos = []
        for (let item of Object.keys(publicai.css)) {
            if (item.includes(class_name + publicai.classSeparator)) {
                foundCombos.push(item)
            }

        }

        return (foundCombos)
    },


    duplicateClass: function (settings) {



        if (settings.is_combo) {
            //delete combo class
            let backup
            let comboStorage = publicai.selected.settings.classes[0].combo
            for (let item of comboStorage) {
                if (item == settings.value) {
                    backup = JSON.parse(JSON.stringify(comboStorage.indexOf(item)))
                    comboStorage.splice(comboStorage.indexOf(item), 1)
                    css_management.manage(true)
                    let newName = settings.value + "_" + generateShortId()

                    for (let item of css_classes.findAllCombosFor(settings.value)) {
                        console.log("duplicating", item)
                        let newComboName = item.replace(settings.value, newName)
                        publicai.css[newComboName] = JSON.parse(JSON.stringify(publicai.css[item]))
                    }

                    //comboStorage.splice(backup, 0, newName)
                    applyNewSettingsAndRename(newName, true)
                    break
                }
            }
        } else {
            //delete main class
            //for (let item of publicai.selected.settings.classes) {
            let backupComboStorage = JSON.parse(JSON.stringify(publicai.selected.settings.classes[0].combo))
            let newName = settings.value + "_" + generateShortId()
            publicai.selected.settings.classes = []
            publicai.selected.settings.classes.push({ "name": newName, "combo": backupComboStorage })
            let allCombosForThisClass = css_classes.findAllCombosFor(settings.value)
            //duplicating class
            publicai.css[newName] = JSON.parse(JSON.stringify(publicai.css[settings.value]))
            //duplicating combos
            for (let item of allCombosForThisClass) {
                let newComboName = item.replace(settings.value, newName)
                publicai.css[newComboName] = JSON.parse(JSON.stringify(publicai.css[item]))
            }

            css_management.manage(true)
            applyNewSettingsAndRename(newName, false)

        }

        function applyNewSettingsAndRename(newName, isCombo) {


            let newSettings = {
                "ui_item": settings.ui_item,
                "value": newName,
                "main_class": settings.main_class,
                "is_combo": isCombo
            }

            play_interactive.forceRecreateSelected(false)

            setTimeout(function () {
                //css_classes.renameClass(newSettings)
            }, 1000)


        }


        publicai.selected.element.classList.remove(settings.value)

    },


    deleteClass: function (settings, deleteEntry) {

        //console.log("deelete ", settings, deleteEntry)

        if (settings.is_combo) {
            //delete combo class
            //make sure to delete states as well.
            let comboStorage = publicai.selected.settings.classes[0].combo
            for (let item of comboStorage) {
                if (item == settings.value) {
                    comboStorage.splice(comboStorage.indexOf(item), 1)
                    css_management.manage(true)
                    //css_classes.renameClass(settings)
                    break
                }
            }

            let rootFolder = _find.getRoot(publicai.active.page.id)



        } else {
            //delete main class
            console.log("is NOT combo")
            //for (let item of publicai.selected.settings.classes) {
            for (let i = 0; i < publicai.selected.settings.classes.length; i++) {

                let item = publicai.selected.settings.classes[i].name

                if (item == settings.value) {
                    publicai.selected.settings.classes.delete(i)
                    css_management.manage(true)
                    //break
                }
            }
        }

        publicai.selected.element.classList.remove(settings.value)

    },



    showMoreMenu: function (settings, class_item, item, isCombo = false) {

        //console.log("setti", settings)

        let extra_panel_holder = settings.ui_item.querySelector(".extra_panel_holder")
        removeAllChildNodes(extra_panel_holder)
        let panel = addWhatWhere(components.css.css_extra_classes, extra_panel_holder)
        document.addEventListener("click", css_classes.closeMorePanel)


        let rename_class = $$$(extra_panel_holder, "data-css", "rename_class")
        let remove_class = $$$(extra_panel_holder, "data-css", "remove_class")
        let duplicate_class = $$$(extra_panel_holder, "data-css", "duplicate_class")
        let delete_entry = $$$(extra_panel_holder, "data-css", "delete_entry")
        let setToChildren = $$$(extra_panel_holder, "data-css", "set_children")
        let removeFromChildren = $$$(extra_panel_holder, "data-css", "remove_children")


        delete_entry.classList.add("hidden")
        if (!settings.is_combo) {

        }

        //console.log("settings", settings, class_item, item, isCombo)

        if (!settings.is_combo) {
            setToChildren.classList.add("hidden")
            removeFromChildren.classList.add("hidden")
        } else {
            setToChildren.classList.remove("hidden")
            removeFromChildren.classList.remove("hidden")

            setToChildren.onclick = function (e) {
                e.stopPropagation()
                e.preventDefault()

                //sets a class to children
                _find.addClassesToAllChildren(publicai.selected.settings, settings.value, true, true)
                removeAllChildNodes(extra_panel_holder)
                document.removeEventListener("click", css_classes.closeMorePanel)
                css_management.manage()
                play_interactive.forceRecreateSelected(false)

                notifications.show([
                    ["Alright.", "The " + settings.value + " combo class has been applied to all child elements."],
                    ["Please note....", "The styles have not been duplicated."],
                ])
            }

            removeFromChildren.onclick = function (e) {
                e.stopPropagation()
                e.preventDefault()
                //sets a class to children
                _find.addClassesToAllChildren(publicai.selected.settings, settings.value, false)
                removeAllChildNodes(extra_panel_holder)
                document.removeEventListener("click", css_classes.closeMorePanel)

                css_management.manage()
                play_interactive.forceRecreateSelected(false)

                notifications.show([
                    ["Alright.", "The " + settings.value + " combo class has been removed to all child elements."]
                ])

            }

        }




        duplicate_class.onclick = function (e) {
            e.stopPropagation()
            e.preventDefault()
            document.removeEventListener("click", css_classes.closeMorePanel)
            css_classes.duplicateClass(settings)
        }

        remove_class.onclick = function (e) {
            e.stopPropagation()
            e.preventDefault()
            console.log("del")
            css_classes.deleteClass(settings, false)
            css_management.manage(true)
        }

        /*     delete_entry.onclick = function (e) {
                e.stopPropagation()
                e.preventDefault()
                css_classes.deleteClass(settings, true)
                css_management.manage(true)
            } */

        rename_class.onclick = function (e) {
            e.stopPropagation()
            e.preventDefault()
            css_classes.renameClass(settings)
        }
    }
}