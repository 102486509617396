export var css_utils = {
    //define which css properties can be inehrited from ancestors
    inheritance: {
        "color": true,
        "font-family": true,
        "font-size": true,
        "font-style": true,
        "font-variant": true,
        "font-weight": true,
        "letter-spacing": true,
        "line-height": true,
        "text-align": true,
        "text-indent": true,
        "text-transform": true,
        "visibility": true,
        "white-space": true,
        "word-spacing": true,
        "custom_transitions": true,
        //"background-color": true,
    },

    operateTabs: function (settings) {

        let tabs = $$$(settings.location, settings.name)
        let contentPanels = $$$(settings.location, settings.name + "-content")
        let controls = $$$(settings.location, settings.name + "-controls") //the buttons at the top
        let helpers = $$$(document, settings.name + "-helpers") //the helpers on the left side

        function disableAll() {

            for (let tab of tabs) {
                tab.classList.remove("active")
            }
            for (let panel of contentPanels) {
                panel.classList.remove("active")
            }
            for (let panel of controls) {
                panel.classList.remove("active")
            }
            for (let panel of helpers) {
                panel.classList.remove("active")
            }
        }

        function activateSpecificTab(tab) {
            disableAll()

            //console.log("tab", tab)

            $$$(settings.location, settings.name, tab).classList.add("active")
            $$$(settings.location, settings.name + "-content", tab).classList.add("active")

            let controls = $$$(settings.location, settings.name + "-controls", tab)
            let helpers = $$$(document, settings.name + "-helpers", tab) //this looks it up in the document so helpers can be placed outside


            if (controls) { controls.classList.add("active") }
            if (helpers) { helpers.classList.add("active") }


            let h1 = $$$(settings.location, settings.name, tab).getAttribute("h1")
            let h2 = $$$(settings.location, settings.name, tab).getAttribute("h2")


            if (h1 && h1 != "") {

                let h1Text = $$$(document, "tab-related", "h1")
                let h2Text = $$$(document, "tab-related", "h2")

                if (h1Text) {
                    h1Text.textContent = h1
                }
                if (h2Text) {
                    h2Text.textContent = h2
                }

            }
        }

        for (let tab of tabs) {
            tab.onclick = (e) => {
                e.stopPropagation()
                e.preventDefault()
                disableAll()
                activateSpecificTab(tab.getAttribute(settings.name))
                settings.callback(tab.getAttribute(settings.name))
            }
        }


        activateSpecificTab(settings.default)



    },


    checkValuesFromAncestors: function (settings) {

        //generic function that checks ancestors. Should use consistently across all components.

        let waterFallClassFound = false
        let acestorClassFound = false
        let inheritValuesFromAncestors = false
        let waterfalledValues = []

        let response = {}

        let existingCssValue = null
        if (css_utils.inheritance[settings.variable]) {
            inheritValuesFromAncestors = true
        }
        if (publicai.active.lastStyleInStack.css[settings.variable]) {
            //variable is set in class
            existingCssValue = publicai.active.lastStyleInStack.css[settings.variable];
        } else {
            //variable may be inherited frrom css stack

            for (let css of publicai.active.cssStack) {
                if (css.css[settings.variable]) {
                    existingCssValue = css.css[settings.variable];
                    waterfalledValues.push(css.css[settings.variable])
                    waterFallClassFound = true
                }
            }
            if (!waterFallClassFound && !inheritValuesFromAncestors) {
                //no values set in none of the classes or combos. 
            }
            if (!waterFallClassFound && inheritValuesFromAncestors) {
                //inheritValuesFromAncestors means that inheritances are allowed for this variable
                let inheritedValues = findClosestCssValue(settings.variable) //only if values can be inherited. Width etc are not. 
                if (inheritedValues.length > 0) {
                    //the value is inherited from another container
                    acestorClassFound = true
                    existingCssValue = inheritedValues[0].value;

                    waterfalledValues.push(existingCssValue)
                }
            }
        }

        response.waterFallClassFound = waterFallClassFound
        response.acestorClassFound = acestorClassFound
        response.inheritValuesFromAncestors = inheritValuesFromAncestors
        response.existingCssValue = existingCssValue
        response.waterfalledValues = waterfalledValues



        return (response)
    },




    dropdown: function (settings) {

        settings.variable = settings.selector.getAttribute("data-dropdown")
        settings.default = settings.selector.getAttribute("data-default")
        let comboItems = $$$(settings.selector, "data-value")


        removeAllChildNodes(settings.selector)
        let dropdown = addWhatWhere(components.css.dropdown_comp, settings.selector)

        let items = {
            "selector": settings.selector,
            "labelContainer": dropdown.querySelector(".reg_label"),
            "label": dropdown.querySelector(".field_value"),
            "dropdown_main": dropdown.querySelector(".dropdown_main"),
            "value_label": dropdown.querySelector(".switch_label"),
            "expansion_panel": dropdown.querySelector(".dropdown_expansion"),
            "dropdown_comment": dropdown.querySelector(".dropdown_comment"),
            "search_container": dropdown.querySelector(".dropdown_search"),
            "search_input": $$$(dropdown, "data-dropdown", "search"),
        }


        if (settings.search) {
            items.search_container.classList.add("active")
            items.search_container.onclick = (e) => {
                e.stopPropagation()
                e.preventDefault()
            }
        } else {
            if (items.search_container) {
                items.search_container.classList.remove("active")
            }
        }


        //let existingCssValue = css_utils.setInheritedValueClasses(settings.variable, items, settings.selector.getAttribute("data-default")) //sets hasvalue and hasinheritedvalue classes


        let existingCssValue

        function checkValuesFromAncestors() {

            let waterFallClassFound = false
            let acestorClassFound = false
            let inheritValuesFromAncestors = false
            let existingCssValue = null
            if (css_utils.inheritance[settings.variable]) {
                inheritValuesFromAncestors = true
            }
            if (publicai.active.lastStyleInStack.css[settings.variable]) {
                //variable is set in class
                existingCssValue = publicai.active.lastStyleInStack.css[settings.variable];
            } else {
                //variable may be inherited frrom css stack
                for (let css of publicai.active.cssStack) {
                    if (css.css[settings.variable]) {
                        existingCssValue = css.css[settings.variable];
                        waterFallClassFound = true
                    }
                }
                if (!waterFallClassFound && !inheritValuesFromAncestors) {
                    //no values set in none of the classes or combos. 
                }
                if (!waterFallClassFound && inheritValuesFromAncestors) {
                    //inheritValuesFromAncestors means that inheritances are allowed for this variable
                    let inheritedValues = findClosestCssValue(settings.variable) //only if values can be inherited. Width etc are not. 
                    if (inheritedValues.length > 0) {
                        //the value is inherited from another container
                        acestorClassFound = true
                        existingCssValue = inheritedValues[0].value;
                    }
                }
            }

            //adding inheritance classes
            if (publicai.active.lastStyleInStack.css[settings.variable]) {
                render.addClassesToAllChildrenOf(items.selector, "hasinheritedvalue", false)
                render.addClassesToAllChildrenOf(items.selector, "hasvalue", true)
            } else {
                render.addClassesToAllChildrenOf(items.selector, "hasvalue", false)
                if (waterFallClassFound || acestorClassFound) {
                    render.addClassesToAllChildrenOf(items.selector, "hasinheritedvalue", true)
                } else {
                    render.addClassesToAllChildrenOf(items.selector, "hasinheritedvalue", false)
                }
            }
        }


        if (!settings.value) { //a value may be given via settigs
            items.value_label.textContent = capitaliseFirstLetter(existingCssValue) || "Auto"
        } else {
            items.value_label.textContent = settings.value
        }

        //no point looking for inherited values if more classes are included in custom code. Used for custom scroll bars. 
        if (settings.save != false) {
            checkValuesFromAncestors()
        }


        items.labelContainer.onclick = (e) => {
            e.stopPropagation()
            e.preventDefault()
            document.addEventListener('click', css_utils.closeExtraPanels);
            css_utils.openAncestoryPanel(items.labelContainer, settings.variable, items.selector.getAttribute("data-align-panel") || null)
        }



        items.label.textContent = settings.label
        items.value_label.textContent = capitaliseFirstLetter(existingCssValue) || "Auto"


        function closeDropDownMenus() {
            document.removeEventListener('click', closeDropDownMenus);
            let container = items.expansion_panel.querySelector(".dropdown_items")
            removeAllChildNodes(container)
            items.expansion_panel.classList.remove("active")
            items.expansion_panel.classList.remove("up")
            items.expansion_panel.classList.remove("down")

            if (settings.ui && settings.ui.panel) {
                css_utils.highlightThisPanel(settings.ui.panel, false)
            }

        }

        items.dropdown_main.onclick = (e) => {

            e.stopPropagation()
            e.preventDefault()

            document.addEventListener('click', closeDropDownMenus);

            if (settings.ui && settings.ui.panel) {
                css_utils.highlightThisPanel(settings.ui.panel, true)
            }

            let searchField = items.search_input
            function debounce(func, delay) {
                let debounceTimer;
                return function () {
                    const context = this;
                    const args = arguments;
                    clearTimeout(debounceTimer);
                    debounceTimer = setTimeout(() => func.apply(context, args), delay);
                };
            }

            const performSearch = () => {
                let foundFonts = [];
                let searchValue = searchField.value.toLowerCase();

                for (let item of publicai.fonts.user) {
                    if (item.fileName.toLowerCase().includes(searchValue.toLowerCase())) {

                        foundFonts.push({
                            label: item.fileName,
                            value: item.fileName,
                        })

                    }
                }

                for (let item of publicai.fonts.platform) {
                    if (item.fileName.toLowerCase().includes(searchValue.toLowerCase())) {
                        foundFonts.push({
                            label: item.fileName,
                            value: item.fileName,
                        })
                    }
                }

                if (foundFonts.length > 0) {
                    generateDropdownList(foundFonts);

                } else {
                    notifications.show([
                        ["Ooops.", "Can't find any fonts containing " + searchValue]
                    ])
                    generateDropdownList(settings.values);
                }


            };

            if (settings.search) {
                setTimeout(() => {
                    searchField.focus()
                }, 100)
                searchField.oninput = debounce(performSearch, 300); // 1000 milliseconds = 1 second
            }


            let container = items.expansion_panel.querySelector(".dropdown_items")



            generateDropdownList(settings.values)

            function generateDropdownList(data) {

                removeAllChildNodes(container)
                for (let item of data) {
                    let dropdownItem = addWhatWhere(components.css.dropdown_extra_item, container)



                    if (settings.styles) {
                        for (let style of settings.styles) {
                            render.addClassesToAllChildrenOf(dropdownItem, style, true)
                        }

                    }


                    if (settings.font) {
                        dropdownItem.style.fontFamily = item.label
                        dropdownItem.classList.add("isfont") // allow the fonts to go over multiple lines
                    }

                    let label = dropdownItem.querySelector(".dropdown_label_1")
                    let icon = dropdownItem.querySelector(".materialicon_switch")
                    icon.classList.remove("active")
                    label.textContent = item.label

                    if (item.icon) {
                        icon.textContent = item.icon
                        icon.classList.add("active")

                    }

                    if (item.value == existingCssValue) {
                        icon.classList.add("active")
                    }

                    dropdownItem.onclick = (e) => {
                        closeDropDownMenus()
                        if (settings.save != false) {
                            publicai.active.lastStyleInStack.css[settings.variable] = item.value
                            css_management.manage()
                            applyCSS()
                        }


                        if (settings.callback) {
                            settings.callback(item)
                        }
                        e.stopPropagation()
                        e.preventDefault()
                    }

                    dropdownItem.onmouseenter = (e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        if (item.comment && item.comment != "") {
                            items.dropdown_comment.textContent = item.comment
                            items.dropdown_comment.classList.add("active")
                        }

                        items.dropdown_comment.onmouseenter = (e) => {
                            e.stopPropagation()
                            e.preventDefault()
                        }
                    }

                    dropdownItem.onmouseleave = (e) => {
                        //items.dropdown_comment.classList.remove("active")
                    }

                }

            }




            if (settings.font) {
                container.classList.add("isfont") //allow it to be larger
            }


            items.expansion_panel.classList.add(settings.direction || "down")
            items.expansion_panel.classList.add("active")


        }
        if (settings.styles) {
            for (let style of settings.styles) {
                render.addClassesToAllChildrenOf(dropdown, style, true)
            }

        }


    },

    addImageOrVideoTo: function (el, mediaContainer, addProgressBar = true, progressBarColor) {
        //let animations = nwsapp.currentProject.config.content[nwsapp.activeVariation].animations
        let effects = null
        let progressContainer = mediaContainer.querySelector(".media_progress_unit")

        //adds images or videos to the media container
        //el = nativemedia.normaliseMedia(el)


        if (el && el.mediaType?.toLowerCase() == "image") {
            let img = addImageTo(el.url, mediaContainer, "100%", "100%")
            if (effects) {
                if (effects.mode != "none") {
                    let settings = effects.settings
                    //applyAnimationStyles(img, settings)
                }
            }
            img.classList.add("swiper-slide")
            removeAllChildNodes(progressContainer)
            return (img)
        }
        if (el && el.mediaType?.toLowerCase() == "video") {
            let srcMP4 = el.url
            let poster = el.previewUrl
            let video = addVideoTo(srcMP4, poster, mediaContainer, "100%", "100%")
            //video.classList.add("swiper-slide")

            /* let videoControls = render.add(components.user.video_controls, progressContainer)
            videoControls.classList.add("active")
            //setUpVideoProgressBar(videoControls, video, null, s.content, 123)


            let progressBgColor = "blue"

            if (addProgressBar) {
                setUpVideoProgressBar(videoControls, video, null, el, 123, progressBgColor)
            } */

            return (video)
        } else {
            removeAllChildNodes(progressContainer)
        }
    },




    floating_panel: function (settings) {

        //this is loaded inside the iframe

        let location = document.querySelector(".maincontainer_animator")
        if (settings.location) {
            location = settings.location
        }

        let overlay = addWhatWhere(components.ui.overlay_container, location)
        let bg = overlay.querySelector(".overlay_background")
        let container = overlay.querySelector(".the_menu_container")

        removeAllChildNodes(container)

        let panel = addWhatWhere(settings.panel, container)
        let close = $$$(panel, "floating-controls", "close")
        let header = $$$(panel, "thegroup", "header")

        container.style.width = settings.width + "px"

        makeDivDraggable(header, container)


        overlay.onclick = close.onclick = (e) => {


            let formNode = _find.parentFormNode(publicai.selected.element)

            //console.log("form node..", formNode)

            //console.log("closing", formNode)

            if (formNode) {
                _create.forceReHandle(formNode)
            }


            //console.log("closed floating panel", settings)

            closeDropDownMenus()
            e.stopPropagation()
            e.preventDefault()

        }

        function closeDropDownMenus() {
            overlay.remove()
        }


        settings.callback(panel, settings.tab, settings.node)

        if (settings.overlayColor) {
            bg.style.backgroundColor = settings.overlayColor
        }
        if (settings.overlayOpacity) {
            bg.style.opacity = settings.overlayOpacity
        }

        let iframe = publicai.active.iframe.getBoundingClientRect()
        let myObject = settings.selector.getBoundingClientRect();


        let viewportHeight = window.innerHeight;
        let distanceToWindowBottom = viewportHeight - (iframe.top + myObject.top + myObject.height);
        let panelHeight = panel.getBoundingClientRect().height


        container.style.left = iframe.left + myObject.left + "px"
        container.style.top = iframe.top + myObject.top + myObject.height + 10 + "px"


        if (panelHeight > distanceToWindowBottom) {
            //place the container at the top of the div
            /*   let position = iframe.top + myObject.top - panelHeight - 30
              console.log("pos", position)
              container.style.top = position + "px" */

            let position = myObject.top + 50
            console.log("pos", position)
            container.style.top = position + "px"
            container.style.left = iframe.left + myObject.left + 50 + "px"

        }
        if (settings.node.type == "media") {
            let position = myObject.top + 130
            container.style.top = position + "px"
            container.style.left = iframe.left + myObject.left + 130 + "px"
        }

        if (settings.node.type == "body") {
            //body no longer shows settings via label clicks. TBD
            let position = iframe.top + 30
            container.style.top = position + "px"
        }


        container.onclick = (e) => {
            e.stopPropagation()
            e.preventDefault()
        }



        return (panel)

    },


    closeConfigurationpanelManually: function () {

        let container = document.querySelector(".overlay_container")

        if (container) {
            container.remove()
        }



    },


    configurationPanel: function (settings) {

        //this is loaded inside the iframe

        let location = document.querySelector(".maincontainer_animator")
        if (settings.location) {
            location = settings.location
        }

        let overlay = addWhatWhere(components.ui.overlay_container, location)
        let bg = overlay.querySelector(".overlay_background")
        let container = overlay.querySelector(".the_menu_container")

        removeAllChildNodes(container)

        let panel = addWhatWhere(settings.comp, container)
        let header = $$$(panel, "thegroup", "header")

        makeDivDraggable(header, container)


        if (settings.closeOnBackgroundClick == "disabled") {
            overlay.style.pointerEvents = "none"
            panel.style.pointerEvents = "auto"
            //console.log("overlay", panel)
        }


        overlay.onclick = (e) => {
            if (!publicai.colorPickerActive) {
                // do not close if a color picker has been opeend. Let the color picker close first. 
                closeDropDownMenus()
                if (settings.closeOnBackgroundClick != "disabled") {
                    e.stopPropagation()
                    e.preventDefault()
                }
            } else {
                console.log("picker is open")
            }

        }

        function closeDropDownMenus() {
            if (settings.closeOnBackgroundClick != "disabled") {
                overlay.remove()
                settings.callback()
            }
        }



        if (settings.overlayColor) {
            bg.style.backgroundColor = settings.overlayColor
        }
        if (settings.overlayOpacity) {
            bg.style.opacity = settings.overlayOpacity
        } else {
            bg.style.opacity = 0
        }

        let panelDimensions = panel.getBoundingClientRect()

        container.style.left = settings.x - panelDimensions.width - 70 + "px"
        container.style.top = settings.y - panelDimensions.height + 70 + "px"

        if (settings.followExactPosition) {
            container.style.left = settings.x - panelDimensions.width - 70 + "px"
            container.style.top = settings.y + "px"
        }


        if (settings.width) {
            container.style.width = settings.width + "px"
        }

        container.onclick = (e) => {
            e.stopPropagation()
            e.preventDefault()
        }

        return (panel)

    },



    confirmationMessage: function (settings) {

        //this is loaded inside the iframe

        let location = document.querySelector(".maincontainer_animator")

        if (!location) {
            location = document.querySelector(".maincontainer")
        }

        if (settings.location) {
            location = settings.location
        }

        let overlay = addWhatWhere(components.ui.overlay_container, location)
        let bg = overlay.querySelector(".overlay_background")
        let container = overlay.querySelector(".the_menu_container")

        removeAllChildNodes(container)

        let panel = addWhatWhere(components.ui.floating_confirmation, container)
        let header = $$$(panel, "thegroup", "header")

        let question = $$$(panel, "confirmation", "question")
        let yes = $$$(panel, "confirmation", "yes")
        let no = $$$(panel, "confirmation", "no")

        no.textContent = settings.no
        yes.textContent = settings.yes
        question.textContent = settings.question



        makeDivDraggable(header, container)


        if (settings.noFunction) {
            console.log("setting no function")
            no.onclick = (e) => {
                console.log("no..")
                e.stopPropagation()
                e.preventDefault()
                settings.noFunction()
                closeDropDownMenus()
            }
        } else {
            no.onclick = (e) => {
                e.stopPropagation()
                e.preventDefault()
                closeDropDownMenus()
            }
        }

        yes.onclick = (e) => {
            e.stopPropagation()
            e.preventDefault()
            settings.callback()

            notifications.show([
                [settings.success[0], settings.success[1]]
            ])

            closeDropDownMenus()
        }

        overlay.onclick = (e) => {
            closeDropDownMenus()
            e.stopPropagation()
            e.preventDefault()

        }

        function closeDropDownMenus() {
            overlay.remove()
        }



        if (settings.overlayColor) {
            bg.style.backgroundColor = settings.overlayColor
        }
        if (settings.overlayOpacity) {
            bg.style.opacity = settings.overlayOpacity
        }

        let panelDimensions = panel.getBoundingClientRect()


        container.style.left = settings.x - panelDimensions.width + 20 + "px"
        container.style.top = settings.y + 20 + "px"


        container.onclick = (e) => {
            e.stopPropagation()
            e.preventDefault()
        }

        return (panel)

    },



    setup_reg_dropdown: function (settings) {

        let label = settings.selector.querySelector(".switch_label")

        if (label && settings.value) {
            label.textContent = capitaliseFirstLetter(settings.value)
        }

        settings.selector.onclick = function (e) {
            e.stopPropagation()
            e.preventDefault()
            css_utils.reg_dropdown(settings)
        }

        function callbackIntercept(settings) {

            //console.log("call", settings)

            if (label) {
                label.textContent = capitaliseFirstLetter(settings.value)
            }

            if (originalCallback) {
                originalCallback(settings)
            }

        }

        let originalCallback = null
        if (settings.callback) {
            originalCallback = settings.callback
            settings.callback = callbackIntercept
        }

    },

    reg_dropdown: function (settings) {

        //settings.variable = settings.selector.getAttribute("data-dropdown") || ""
        settings.default = settings.selector.getAttribute("data-default") || ""
        let comboItems = $$$(settings.selector, "data-value")

        let location = document.querySelector(".maincontainer_animator")

        if (!location) {
            location = document.querySelector(".maincontainer")
        }

        if (settings.location) {
            location = settings.location
        }

        let overlay = addWhatWhere(components.ui.overlay_container, location)
        let bg = overlay.querySelector(".overlay_background")
        let container = overlay.querySelector(".the_menu_container")
        removeAllChildNodes(container)

        overlay.onclick = (e) => {
            closeDropDownMenus()
            e.stopPropagation()
            e.preventDefault()

        }


        if (settings.overlayColor) {
            bg.style.backgroundColor = settings.overlayColor
        }
        if (settings.overlayOpacity) {
            bg.style.opacity = settings.overlayOpacity
        }

        let coords = {
            x: 0,
            y: 0
        }

        let rect

        if (settings.selector) {

            rect = settings.selector.getBoundingClientRect();

            coords.x = rect.right
            coords.y = rect.top
            coords.width = rect.width
            coords.height = rect.height
        }


        let dropdown = addWhatWhere(components.css.dropdown_comp, container)

        let items = {
            "selector": settings.selector,
            "labelContainer": dropdown.querySelector(".reg_label"),
            "label": dropdown.querySelector(".field_value") || settings.labelElement,
            "dropdown_main": dropdown.querySelector(".dropdown_main"),
            "value_label": dropdown.querySelector(".switch_label"),
            "expansion_panel": dropdown.querySelector(".dropdown_expansion"),
            "dropdown_comment": dropdown.querySelector(".dropdown_comment"),
            "search_container": dropdown.querySelector(".dropdown_search"),
            "search_input": $$$(dropdown, "data-dropdown", "search"),
            "dropdown_items": dropdown.querySelector(".dropdown_items"),
        }

        if (settings.max_height) {
            items.dropdown_items.style.maxHeight = settings.max_height + "px"
        }





        if (settings.search) {
            items.search_container.classList.add("active")
            items.search_container.onclick = (e) => {
                e.stopPropagation()
                e.preventDefault()
            }
        } else {
            items.search_container.classList.remove("active")
        }


        let searchField = items.search_input
        if (settings.searchField) {
            searchField = settings.searchField
            items.search_container.classList.remove("active") //disable dropdown search if search field is provided. 
        }



        function closeDropDownMenus() {
            //document.removeEventListener('click', closeDropDownMenus);


            let container = items.expansion_panel.querySelector(".dropdown_items")
            removeAllChildNodes(container)
            items.expansion_panel.classList.remove("active")
            items.expansion_panel.classList.remove("up")
            items.expansion_panel.classList.remove("down")
            overlay.remove()
            if (settings.ui && settings.ui.panel) {
                css_utils.highlightThisPanel(settings.ui.panel, false)
            }
        }


        let actionItem = items.dropdown_main




        //hide dropdown ui and only show expansion panel. Dropdown can pop from any element
        actionItem = settings.selector
        render.addClassesToAllChildrenOf(container, "is_custom_ui", true) //hides dropdown ui
        let width = settings.width || 200

        if (settings.width == "100%") {
            //the size of the dropdown
            container.style.width = coords.width + "px"
            container.style.top = coords.y + coords.height + 5 + "px"
            container.style.left = coords.x - coords.width + "px"
        } else {
            container.style.width = width + "px"
            container.style.top = coords.y + + coords.height + 5 + "px"
            container.style.left = coords.x - width + "px"
        }

        if (settings.x && settings.y) {
            //if coordinates are given, use those. Otherwise ^^ use the component coordinates. 
            let iframeCoords = publicai.active.iframe.getBoundingClientRect()
            let newX = iframeCoords.left + settings.x
            let newY = iframeCoords.top + settings.y
            container.style.top = newY + "px"
            container.style.left = newX + "px"
        }

        if (settings.anchor == "left") {
            //coords.x = rect.left
            let rect = settings.selector.getBoundingClientRect();
            let containerW = settings.selector.getBoundingClientRect();
            container.style.left = rect.left + "px"

        }



        if (settings.ui && settings.ui.panel) {
            css_utils.highlightThisPanel(settings.ui.panel, true)
        }


        function debounce(func, delay) {
            let debounceTimer;
            return function () {
                const context = this;
                const args = arguments;
                clearTimeout(debounceTimer);
                debounceTimer = setTimeout(() => func.apply(context, args), delay);
            };
        }

        const performSearch = () => {
            console.log("perform search", searchField.value)
            let foundElements = [];
            let searchValue = searchField.value.toLowerCase();

            for (let item of settings.values) {
                if (item.label.toLowerCase().includes(searchValue.toLowerCase())) {
                    foundElements.push(item)
                }
            }

            if (foundElements.length > 0) {
                generateDropdownList(foundElements);

            } else {
                /*    notifications.show([
                       ["Ooops.", "Can't find any fonts containing " + searchValue]
                   ]) */
                generateDropdownList(settings.values);
            }


        };

        if (settings.search) {
            setTimeout(() => {
                searchField.focus()
            }, 100)
            searchField.oninput = debounce(performSearch, 50); // 1000 milliseconds = 1 second
        }


        let expContainer = items.expansion_panel.querySelector(".dropdown_items")
        generateDropdownList(settings.values)

        function generateDropdownList(data) {

            if (settings.multiSelect) {
                data = Object.keys(data)
            }


            removeAllChildNodes(expContainer)
            for (let item of data) {
                let dropdownItem = addWhatWhere(components.css.dropdown_extra_item, expContainer)
                if (settings.styles) {
                    for (let style of settings.styles) {
                        render.addClassesToAllChildrenOf(dropdownItem, style, true)
                    }
                }

                let label = dropdownItem.querySelector(".dropdown_label_1")
                let label2 = $$$(dropdownItem, "dropdown", "second_label")
                let icon = dropdownItem.querySelector(".materialicon_switch")
                let icon2 = dropdownItem.querySelector(".additional_context")

                if (label2 && item.label2) {
                    label2.textContent = item.label2
                    label2.classList.add("active")
                }


                if (settings.multiSelect) {
                    label.textContent = item

                    if (settings.values[item]) {
                        icon.classList.add("active")
                    } else {
                        icon.classList.remove("active")
                    }


                } else {

                    icon.classList.remove("active")
                    label.textContent = item.label



                    if (item.style) {
                        render.addClassesToAllChildrenOf(dropdownItem, item.style, true)
                    }

                    if (item.icon) {
                        icon.textContent = item.icon
                        icon.classList.add("active")
                    }

                    if (item.icon2) {
                        icon2.textContent = item.icon2
                        icon2.classList.add("active")
                    }

                    if (typeof existingCssValue != "undefined" && item.value == existingCssValue) {
                        icon.classList.add("active")
                    }

                }

                dropdownItem.onclick = (e) => {

                    e.stopPropagation()
                    e.preventDefault()


                    if (!settings.multiSelect) {
                        if (!item.value) {
                            return
                        }
                        closeDropDownMenus()
                    } else {

                        console.log("click", settings.values[item])
                        //this uses checkboxes
                        settings.values[item] = !settings.values[item]

                        if (settings.values[item]) {
                            icon.classList.add("active")
                        } else {
                            icon.classList.remove("active")
                        }

                    }


                    if (settings.callback) {

                        if (!settings.multiSelect) {
                            settings.callback(item)
                        } else {
                            settings.callback(settings.values)
                        }
                    }

                }

                dropdownItem.onmouseenter = (e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    if (item.comment && item.comment != "") {
                        items.dropdown_comment.textContent = item.comment
                        items.dropdown_comment.classList.add("active")
                    }

                    items.dropdown_comment.onmouseenter = (e) => {
                        e.stopPropagation()
                        e.preventDefault()
                    }
                }

                dropdownItem.onmouseleave = (e) => {
                    //items.dropdown_comment.classList.remove("active")
                }

            }

        }


        items.expansion_panel.classList.add(settings.direction || "down")
        items.expansion_panel.classList.add("active")


        if (settings.styles) {
            for (let style of settings.styles) {
                render.addClassesToAllChildrenOf(dropdown, style, true)
            }

        }

        if (settings.autoLabel) {
            console.log("auto label....")
            items.value_label.textContent = settings.values.find(x => x.value == settings.object[settings.variable]).label
        }


    },






    getExistingValues: function (variable) {


        let existingCssValue = null

        if (publicai.active.lastStyleInStack.css[variable] == "") {
            delete publicai.active.lastStyleInStack.css[variable]
        }

        if (publicai.active.lastStyleInStack.css[variable]) {
            //see if variable has ever been set
            existingCssValue = publicai.active.lastStyleInStack.css[variable]

            return ({
                value: existingCssValue,
                style: "hasvalue"
            })

        } else {
            //not set. Look for ancestors
            let waterFallClassFound = false
            for (let css of publicai.active.cssStack) {
                if (css.css[variable]) {
                    existingCssValue = css.css[variable];
                    waterFallClassFound = true

                    return ({
                        value: existingCssValue,
                        style: "hasinheritedvalue"
                    })


                }
            }
        }


        return ({
            value: 0,
            style: null
        })



    },



    setInheritedValueClasses: function (variable, items, defaultValue) {

        let existingCssValue = null
        if (publicai.active.lastStyleInStack.css[variable]) {
            //see if variable has ever been set
            render.addClassesToAllChildrenOf(items.labelContainer, "hasvalue", true)
            existingCssValue = publicai.active.lastStyleInStack.css[variable]

        } else {
            //not set. Look for ancestors
            let waterFallClassFound = false
            for (let css of publicai.active.cssStack) {
                if (css.css[variable]) {
                    existingCssValue = css.css[variable];
                    waterFallClassFound = true
                    render.addClassesToAllChildrenOf(items.labelContainer, "hasinheritedvalue", true)
                } else {
                    render.addClassesToAllChildrenOf(items.labelContainer, "hasinheritedvalue", false)
                }
            }
        }

        /*    if (!existingCssValue) {
               //not set and no ancestors found. Look for defaults
               existingCssValue = defaultValue
           } */

        if (items.value_label) {
            items.value_label.textContent = existingCssValue
        }

        return (existingCssValue)
    },


    combo: function (settings) {

        if (!settings.variable) {
            settings.variable = settings.selector.getAttribute("layout-combo")
        }
        settings.default = settings.selector.getAttribute("data-default")
        let comboItems = $$$(settings.selector, "data-value")

        let items = {
            "selector": settings.selector,
            "labelContainer": settings.selector.querySelector(".reg_label"),
            "label": settings.selector.querySelector(".field_value"),
        }





        let existingCssValue = null
        function refreshAllCombos() {

            render.addClassesToAllChildrenOf(items.selector, "hasvalue", false)
            render.addClassesToAllChildrenOf(items.selector, "hasinheritedvalue", false)

            let existingCssValue = css_utils.setInheritedValueClasses(settings.variable, items) //sets hasvalue and hasinheritedvalue classes

            //console.log("combo value", existingCssValue)

            if (!existingCssValue) {
                //not set and no ancestors found. Look for defaults
                existingCssValue = settings.default //default value - set on component level. Like auto or none
            }

            render.addClassesToAllChildrenOf(items.selector, "active", false)


            if (settings.reverseElement) {

                if (existingCssValue) {
                    if (existingCssValue.includes("reverse")) {
                        settings.reverseElement.classList.add("active")
                    }
                }
                settings.reverseElement.onclick = (e) => {

                    //check if value contains reverse
                    if (existingCssValue.includes("reverse")) {
                        settings.reverseElement.classList.remove("active")
                        existingCssValue = existingCssValue.replace("-reverse", "")
                    } else {
                        settings.reverseElement.classList.add("active")
                        existingCssValue = existingCssValue + "-reverse"
                    }

                    if (settings.save != false) { //some combos don't need to save to css
                        publicai.active.lastStyleInStack.css[settings.variable] = existingCssValue
                    }
                    refreshAllCombos()
                    applyCSS()

                }

            }



            for (let item of comboItems) {
                let value = item.getAttribute("data-value")
                if (existingCssValue == value || existingCssValue == value + "-reverse") {
                    render.addClassesToAllChildrenOf(item, "active", true)
                }
            }

            if (settings.callback && settings.save != false) {
                settings.callback(existingCssValue)
            }
        }

        items.labelContainer.onclick = (e) => {

            e.stopPropagation()
            e.preventDefault()
            document.addEventListener('click', css_utils.closeExtraPanels);
            css_utils.openAncestoryPanel(items.labelContainer, settings.variable, items.selector.getAttribute("data-align-panel") || null)
        }

        refreshAllCombos()

        for (let item of comboItems) {

            item.onclick = (e) => {
                e.stopPropagation()
                e.preventDefault()

                let value = item.getAttribute("data-value")

                if (settings.reverseElement && settings.reverseElement.classList.contains("active")) {
                    value = value + "-reverse"
                }

                if (settings.save != false) { //some combos don't need to save to css
                    publicai.active.lastStyleInStack.css[settings.variable] = value
                }

                if (settings.callback) {
                    //used by the align combo presets and border sides
                    settings.callback(value)
                }



                refreshAllCombos()
                applyCSS()
            }
        }
    },

    standAloneSlider: function (settings) {

        document.removeEventListener('mousedown', startSlide);

        // Gather elements
        let container = settings.slider;
        let currentValue = settings.object.value;

        let el = $$$$(container, "data-slider")
        let cursorWidth = 5

        //let unit = items.unit.textContent.toLowerCase()
        let range = settings.range

        el.progress.classList.add("hasvalue")


        function setInitialPosition() {
            let initialPercentage = ((currentValue - range.min) / (range.max - range.min)) * 100;
            let pixelPosition = (initialPercentage / 100) * (el.bar.offsetWidth - cursorWidth);
            //console.log(":initialPercentage", initialPercentage, settings.type, pixelPosition)

            el.cursor.style.left = pixelPosition + 'px';
            //el.progress.style.left = (initialPercentage - 100) + '%';

            positionProgress(initialPercentage)
        }

        function startSlide(event) {
            publicai.isSliding = true;
            document.addEventListener('mousemove', moveCursor);
            document.addEventListener('mouseup', stopSlide);
        }

        let value

        function positionProgress(percentage) {
            let bar = el.progress;

            // Calculate the left position and width based on percentage
            if (settings.type === "translate" || settings.type === "scale") {

                let percentageToUse = percentage

                if (percentage > 100) { percentageToUse = 100 }


                let adjustedLeft
                let adjustedPercentage = Math.abs(percentage - 50)
                if (percentage >= 50) {
                    adjustedLeft = 50
                    bar.style.left = adjustedLeft + '%';
                    bar.style.width = adjustedPercentage + '%';
                } else {
                    adjustedLeft = 50 - adjustedPercentage
                    bar.style.left = adjustedLeft + '%';
                    bar.style.width = adjustedPercentage + '%';
                }



            } else {
                // Default behavior (not specific, can adjust based on other types)
                bar.style.left = (percentage - 100) + '%';
                bar.style.width = '10%'; // Example width, adjust as needed for other types
            }
        }


        function moveCursor(event) {

            let offsetX = event.clientX - el.bar.getBoundingClientRect().left;
            let newLeft = Math.min(Math.max(0, offsetX - cursorWidth / 2), el.bar.offsetWidth - cursorWidth);
            el.cursor.style.left = newLeft + 'px';

            let percentage = (newLeft / (el.bar.offsetWidth - cursorWidth)) * 100;

            let tt = range.min + (percentage * (range.max - range.min) / 100)

            value = parseFloat(range.min + (percentage * (range.max - range.min) / 100)).toFixed(2);



            positionProgress(percentage)
            settings.sliderCallback(value, percentage)

        }


        function stopSlide() {
            publicai.isSliding = false
            document.removeEventListener('mousemove', moveCursor);
            document.removeEventListener('mouseup', stopSlide);
            document.removeEventListener('mousedown', startSlide);
            settings.sliderCallback(value)
        }


        function createSlider() {
            settings.container.onmouseenter = (e) => {
                el.cursor.addEventListener('mousedown', startSlide);
            }

            settings.container.onmouseleave = (e) => {
                if (!publicai.isSliding) {
                    el.cursor.removeEventListener('mousedown', startSlide);
                }
            }
            setInitialPosition();
        }

        createSlider()


    },

    zoomSlider: function (settings) {

        document.removeEventListener('mousedown', startSlide);

        // Gather elements
        let container = settings.selector;
        let currentValue = settings.object[settings.variable]

        let el = $$$$(container, "data-slider")
        let cursorWidth = settings.cursorWidth || 14

        //let unit = items.unit.textContent.toLowerCase()
        let range = settings.range


        function setInitialPosition() {
            let initialPercentage = ((currentValue - range.min) / (range.max - range.min)) * 100;
            let pixelPosition = (initialPercentage / 100) * (el.bar.offsetWidth - cursorWidth);
            //console.log(":initialPercentage", initialPercentage, settings.type, pixelPosition)

            el.cursor.style.left = pixelPosition + 'px';
            //el.progress.style.left = (initialPercentage - 100) + '%';

        }

        function startSlide(event) {
            publicai.isSliding = true;
            if (settings.startSlideCallback) {
                settings.startSlideCallback()
            }
            document.addEventListener('mousemove', moveCursor);
            document.addEventListener('mouseup', stopSlide);
        }

        let value


        function moveCursor(event) {

            let offsetX = event.clientX - el.bar.getBoundingClientRect().left;
            let newLeft = Math.min(Math.max(0, offsetX - cursorWidth / 2), el.bar.offsetWidth - cursorWidth);
            el.cursor.style.left = newLeft + 'px';

            let percentage = (newLeft / (el.bar.offsetWidth - cursorWidth)) * 100;
            value = parseFloat(range.min + (percentage * (range.max - range.min) / 100)).toFixed(0);
            settings.sliderCallback(value, percentage)
        }


        function setZoomLevel(percentage) {
            settings.sliderCallback(percentage)
            let pixelPosition = (percentage / 100) * (el.bar.offsetWidth - cursorWidth);
            el.cursor.style.left = pixelPosition + 'px';
        }

        settings.label.onclick = (e) => {
            _create.deselectAllElements();
            setZoomLevel(100)
            _create.addSelectionContainer(publicai.selected.element, publicai.selected.settings, "select")
        }



        settings.selector.onclick = (e) => {
            // Get the bounding rectangle of the slider
            let rect = settings.selector.getBoundingClientRect();
            let delta = e.clientX - rect.left;
            let width = rect.width;
            let percentage = (delta / width) * 100;
            percentage = Math.round(Math.max(0, Math.min(percentage, 100)));
            setZoomLevel(percentage)
        };


        let allPresetValues = $$$$(settings.selector, "data-preset")
        for (let presetValue of Object.keys(allPresetValues)) {

            let button = allPresetValues[presetValue]
            button.onclick = function (e) {
                e.stopPropagation()
                e.preventDefault()
                settings.sliderCallback(parseInt(presetValue), true)
                //updateLabel(parseInt(presetValue))
            }
        }


        function stopSlide() {
            publicai.isSliding = false
            if (settings.stopSlideCallback) {
                settings.stopSlideCallback()
            }

            document.removeEventListener('mousemove', moveCursor);
            document.removeEventListener('mouseup', stopSlide);
            document.removeEventListener('mousedown', startSlide);
            settings.sliderCallback(value)
        }


        function createSlider() {
            settings.selector.onmouseenter = (e) => {
                el.cursor.addEventListener('mousedown', startSlide);
            }

            settings.selector.onmouseleave = (e) => {
                if (!publicai.isSliding) {
                    el.cursor.removeEventListener('mousedown', startSlide);
                }
            }
            setInitialPosition();
        }

        createSlider()


    },



    slider: function (items, settings, sliderCallback, panelCallback) {
        // Gather elements
        let container = settings.selector;
        let currentValue = parseFloat(items.input.value);

        if (isNaN(currentValue) && settings.defaultSliderValue) {
            currentValue = settings.defaultSliderValue //if content has no value yet - such as opacity or zindex
        }

        if (settings.givenValue) {
            currentValue = settings.givenValue
        }


        let el = {
            element: $$$(container, "data-slider", "element"),
            bar: $$$(container, "data-slider", "bar"),
            cursor: $$$(container, "data-slider", "cursor"),
            progressBar: $$$(container, "data-slider", "progress"),
        };

        let cursorWidth = 5

        // Get range settings
        let unit = items.unit.textContent.toLowerCase()
        let range



        if (settings.custom_range) {
            range = settings.ranges
        } else {
            range = settings.ranges[unit] || settings.ranges.vh
        }

        //console.log(settings.ranges, range)

        // Initialize the slider
        function createSlider() {
            //console.log("have created slider for ", settings.variable, settings.selector, el.cursor)
            // Add event listener for drag or click on cursor
            settings.selector.onmouseenter = (e) => {
                el.cursor.addEventListener('mousedown', startSlide);
            }

            settings.selector.onmouseleave = (e) => {
                if (!publicai.isSliding) {
                    el.cursor.removeEventListener('mousedown', startSlide);
                }
            }
            setInitialPosition();
        }

        function setInitialPosition() {
            // Calculate the initial percentage based on currentValue
            let initialPercentage = ((currentValue - range.min) / (range.max - range.min)) * 100;

            // Calculate pixel position based on percentage and adjust for cursor width
            let pixelPosition = (initialPercentage / 100) * (el.bar.offsetWidth - cursorWidth);

            // Set the initial position of cursor and progress bar
            el.cursor.style.left = pixelPosition + 'px';
            el.progressBar.style.left = (initialPercentage - 100) + '%';
        }

        function startSlide(event) {
            // Add moving and release event listeners
            publicai.isSliding = true;
            document.addEventListener('mousemove', moveCursor);
            document.addEventListener('mouseup', stopSlide);
        }

        function moveCursor(event) {
            // Calculate the offset from the left side of the bar
            let offsetX = event.clientX - el.bar.getBoundingClientRect().left;

            // Adjust the new left position to account for cursor width
            let newLeft = Math.min(Math.max(0, offsetX - cursorWidth / 2), el.bar.offsetWidth - cursorWidth);

            el.cursor.style.left = newLeft + 'px';

            // Calculate the percentage based on the adjusted position
            let percentage = (newLeft / (el.bar.offsetWidth - cursorWidth)) * 100;
            let value = range.min + (percentage * (range.max - range.min) / 100);
            //console.log(`Percentage: ${percentage.toFixed(2)}%, Value: ${value.toFixed(2)}`);

            // Update the progress bar
            el.progressBar.style.left = (percentage - 100) + '%';


            sliderCallback(value, percentage)
            if (panelCallback) {
                //this calls the initiating function - such as the shadows panel, so that settings are applied in real time. 
                panelCallback(parseFloat(value).toFixed(0), parseFloat(percentage).toFixed(0))
            }
        }


        function stopSlide() {
            // Remove event listeners
            publicai.isSliding = false
            //console.log("stop slide for  ", settings.variable, settings.selector)
            document.removeEventListener('mousemove', moveCursor);
            document.removeEventListener('mouseup', stopSlide);
            sliderCallback(null, null, true)

            publicai.selected.element.style[settings.variable] = ""
        }

        createSlider();
    },



    input: function (settings) {

        let items = {
            "selector": settings.selector,
            "labelContainer": settings.selector.querySelector(".reg_label"),
            "input": $$$(settings.selector, "data-input", "input"),
            "label": settings.selector.querySelector(".field_value"),
            "unit": settings.selector.querySelector(".unit_indicator"),
        }



        let valueAdjustment = 1; //allows for value / unit adjustment when dealing with 0-1 ranges. 


        let objectToSaveTo = publicai.active.lastStyleInStack.css
        //values may be saved into different objects - such as the shadow object.

        if (settings.object) {
            objectToSaveTo = settings.object
        }


        function sliderCallback(value, percentage, slidingEnded = false) {


            if (slidingEnded) {
                console.log("sliding ended", value, percentage)
                checkVariableStatus()
                checkIfInputRequiresUnits() //adds px if required
                applyCSS()

                
                if (settings.callback) {
                    settings.callback(value)
                }

                return
            }

            if (!items.unit.textContent || items.unit.textContent == "-" || items.unit.textContent == "") {
                checkVariableStatus()
                checkIfInputRequiresUnits() //adds px if required. Unavailable the first time, so has to run once. 
            }

            //called on slider update
            items.input.value = parseFloat(value).toFixed(0)
            objectToSaveTo[settings.variable] = items.input.value + items.unit.textContent.toLowerCase()
            publicai.selected.element.style[settings.variable] = value + items.unit.textContent.toLowerCase()




        }


        if (settings.label) {
            items.label.textContent = settings.label
        }



        if (settings.rightClick) {




            //allow for fields to present a context menu with size suggestions. Such as 100%, 50%, 25% etc.

            items.input.oncontextmenu = (e) => {


                console.log("righclick")
                e.stopPropagation()
                e.preventDefault()

                css_utils.reg_dropdown({
                    "selector": items.input,
                    "values": settings.rightClick,
                    "label": "Add new element",
                    //"styles": ["auto"],
                    "styles": ["auto", "horizontal", "light", "is_white"],
                    "customMode": true,
                    "save": false,
                    "callback": applyRightClickValues,
                    "direction": "down",
                    "overlayColor": "rgb(107 95 88)",
                    "overlayOpacity": 0.08,
                    "width": 120
                })

                function applyRightClickValues(s) {
                    items.input.focus()
                    objectToSaveTo[settings.variable] = s.value
                    items.input.value = s.value
                    checkIfInputRequiresUnits()
                    checkVariableStatus()
                    items.input.blur()

                }
            }
        }

        render.addClassesToAllChildrenOf(items.selector, "hasvalue", false)
        render.addClassesToAllChildrenOf(items.selector, "hasinheritedvalue", false)

        items.labelContainer.onclick = (e) => {
            e.stopPropagation()
            e.preventDefault()
            document.addEventListener('click', css_utils.closeExtraPanels);
            css_utils.openAncestoryPanel(items.labelContainer, settings.variable, items.selector.getAttribute("data-align-panel") || null)
        }

        let unit = "px"

        function checkVariableStatus() {

            let waterFallClassFound = false
            let acestorClassFound = false
            let inheritValuesFromAncestors = false

            if (css_utils.inheritance[settings.variable]) {
                inheritValuesFromAncestors = true
            }


            if (objectToSaveTo[settings.variable]) {
                //variable is set in class
                items.input.value = objectToSaveTo[settings.variable];
                let unit = removeUnitFromInput(items.input)
                items.unit.textContent = unit;
            } else {

                //variable may be inherited frrom css stack
                for (let css of publicai.active.cssStack) {
                    if (css.css[settings.variable]) {
                        items.input.value = css.css[settings.variable];
                        let unit = removeUnitFromInput(items.input)
                        items.unit.textContent = unit;
                        waterFallClassFound = true

                    }
                }

                if (!waterFallClassFound && !inheritValuesFromAncestors) {
                    //no values set in none of the classes or combos. 
                    items.input.value = ""
                    items.unit.textContent = "-"
                }

                if (!waterFallClassFound && inheritValuesFromAncestors) {
                    //inheritValuesFromAncestors means that inheritances are allowed for this variable
                    let inheritedValues = findClosestCssValue(settings.variable) //only if values can be inherited. Width etc are not. 
                    if (inheritedValues.length > 0) {
                        //the value is inherited from another container
                        acestorClassFound = true
                        items.input.value = inheritedValues[0].value;
                        let unit = removeUnitFromInput(items.input)
                        items.unit.textContent = unit;
                    } else {
                        items.input.value = ""
                        items.unit.textContent = "-"
                    }
                }
            }



            //adding inheritance classes
            if (objectToSaveTo[settings.variable]) {
                render.addClassesToAllChildrenOf(items.selector, "hasinheritedvalue", false)
                render.addClassesToAllChildrenOf(items.selector, "hasvalue", true)
            } else {
                render.addClassesToAllChildrenOf(items.selector, "hasvalue", false)
                if (waterFallClassFound || acestorClassFound) {
                    render.addClassesToAllChildrenOf(items.selector, "hasinheritedvalue", true)
                } else {
                    render.addClassesToAllChildrenOf(items.selector, "hasinheritedvalue", false)
                }

            }
        }

        checkVariableStatus()

        function removeUnitFromInput(input, pass) {

            if (settings.validation == "color") {
                //no validation or parsefloats required if dealing with hex codes. 
                //consider adding hex validation
                //console.log("validation is color , returning blank")
                return ("")
            }


            if (items.input.value.toLowerCase().includes("auto")) {
                objectToSaveTo[settings.variable] = "auto"
                input.value = "Auto"
                items.input.blur()
                //css_management.manage()
                return ("-")
            }

            // Remove any previous unit from the value and extract numeric part
            const valueWithoutUnit = parseFloat(input.value);
            // Identify and extract the unit (px, em, vw, vh, %)
            const unitMatch = input.value.match(/px|em|vw|vh|%$/);
            const unit = unitMatch ? unitMatch[0] : "px"; // Default to px if no unit found
            // Update the items object
            input.value = valueWithoutUnit; // Update with the numeric value only


            return (unit)
        }




        function checkIfInputRequiresUnits() {
            if (settings.units == false) {
                items.unit.textContent = "-"
                items.unit.classList.add("disabled")
                //remove units from value 
                if (settings.validation == "color") {
                    return
                }
                if (objectToSaveTo[settings.variable]) {
                    try {
                        objectToSaveTo[settings.variable] = parseInt(objectToSaveTo[settings.variable].replace(/px|em|vw|vh|%$/, ""))

                    } catch (err) {
                        //will error if already Integer
                    }
                }

            }


            if (settings.unit) {
                items.unit.textContent = settings.unit
            }

        }

        function pickerCallback(value) {

        }


        function updateSlider() {
            if (settings.slider) {
                css_utils.slider(items, settings, sliderCallback, settings.callback)
                //slider callback sets vairables, etc
                //settings.callback uses the variables to apply inline css while dragging. used for shadows
            }
        }

        let activePicker = null

        function updateColorPicker() {
            if (settings.color) {
                activePicker = new ColorPicker(settings.color)
            }

        }
        checkIfInputRequiresUnits()


        function validateInputOnBlur(event) {

            //console.log("validating in put on blur", settings.variable, items.input.value, settings)

            //console.log("input blur", settings.variable, items.input.value)
            if (settings.validation != "color") {
                if (items.input.value != "") {

                    if (objectToSaveTo[settings.variable]) {
                        let unit = removeUnitFromInput(items.input)
                        if (allowedUnits.includes(unit)) {
                        } else {
                            //console.log("not part of allowed units", allowedUnits) // probably none has been entered
                            let unitMatch = objectToSaveTo[settings.variable].match(/px|em|vw|vh|%$/)
                            unit = unitMatch ? unitMatch[0] : "px"; // Default to px if no unit found
                        }


                        if (items.input.value.toLowerCase().includes("auto")) {
                            items.unit.textContent = unit;
                            objectToSaveTo[settings.variable] = "Auto"
                        } else {



                            if (!isNaN(parseFloat(items.input.value))) {
                                items.unit.textContent = unit;
                                objectToSaveTo[settings.variable] = items.input.value + items.unit.textContent
                                checkIfInputRequiresUnits()


                            } else {

                                console.log("here......")
                                items.input.value = ""
                                items.unit.textContent = "-"
                                delete objectToSaveTo[settings.variable]
                                css_management.manage()
                            }
                        }

                    } else {
                        //it has beeen triggered by a blur with an inherited value. Do nothing, since value was saved on input.
                    }

                } else {
                    //console.log("is empty, deleting entry")
                    delete objectToSaveTo[settings.variable]
                    css_management.manage()
                    applyCSS()

                }
            } else {
                if (objectToSaveTo[settings.variable] != items.input.value) {
                    console.log("color changed....")
                    items.input.value = css_utils.validateColor(items.input.value)
                    objectToSaveTo[settings.variable] = items.input.value

                    css_management.manage()
                    applyCSS()
                    updateColorPicker()
                    items.input.blur()
                }

            }

            if (settings.callback) {
                settings.callback(items.input.value)
            }

            updateSlider()
            updateColorPicker()
        }


        //remove all key listeners first




        let keyUp = function (event) {
            // Check if the key pressed is 'Enter'
            if (settings.validation == "color") {
                items.input.value = css_utils.validateColor(items.input.value)
                //css_management.manage()
                applyCSS()
                //activePicker.refresh()
                //updateColorPicker()

            }


            if (event.target === items.input && event.key === 'Enter') {
                event.stopPropagation()
                event.preventDefault()
                event.target.blur()
                validateInputOnBlur(event)
                applyCSS()
            }




        }

        items.input.removeEventListener('keyup', keyUp)
        items.input.addEventListener('keyup', keyUp);


        function arrowsCallback(event) {

            if (!isNaN(parseFloat(items.input.value))) {
                if (event.key == "ArrowUp") {
                    items.input.value = parseInt(items.input.value) + 1
                }
                if (event.key == "ArrowDown") {
                    items.input.value = parseInt(items.input.value) - 1
                }
            }

        }

        items.input.onfocus = (e) => {
            if (settings.number) {
                publicai.inputCallback = arrowsCallback
            } else {
                publicai.inputCallback = null //don't call back if input is not a number
            }
        }

        items.input.onblur = (e) => {
            e.stopPropagation()
            e.preventDefault()
            validateInputOnBlur(e)
            applyCSS()
        }


        let allowedUnits = ["px", "em", "vw", "vh", "%"]
        items.input.oninput = (e) => {
            //check what key it was and ignore this if it was an arrow up
            objectToSaveTo[settings.variable] = items.input.value + items.unit.textContent
            render.addClassesToAllChildrenOf(items.labelContainer, "hasvalue", true)
            render.addClassesToAllChildrenOf(items.labelContainer, "hasinheritedvalue", false)
            applyCSS()
        }


        if (settings.units != false) {
            items.unit.onclick = (e) => {
                e.stopPropagation()
                e.preventDefault()
                document.addEventListener('click', css_utils.closeExtraPanels);

                let panel = addWhatWhere(components.css.css_units, items.unit)

                let allItems = $$$(items.unit, "data-unit")

                for (let unitItem of allItems) {
                    let value = unitItem.getAttribute("data-unit")
                    unitItem.onclick = (e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        items.unit.textContent = value
                        css_utils.closeExtraPanels()
                        objectToSaveTo[settings.variable] = items.input.value + value
                        applyCSS()
                        updateSlider()
                        updateColorPicker()
                    }
                }

            }
        }

        updateSlider()
        updateColorPicker()


    },



    validateColor: function (color) {

        // Regular expression to check if the string is a valid hex code (3, 4, 6, or 8 characters)
        const hexRegex = /^#?([A-Fa-f0-9]{3}|[A-Fa-f0-9]{4}|[A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/;
        const rgbaRegex = /^rgba?\(\s*\d{1,3}\s*,\s*\d{1,3}\s*,\s*\d{1,3}\s*(,\s*\d+(\.\d+)?\s*)?\)$/;

        // Check if it's already rgba or rgb format
        if (rgbaRegex.test(color)) {
            return color; // It's already rgba/rgb
        }

        // Check if it matches the hex format (with or without #)
        if (hexRegex.test(color)) {
            // Ensure it has the # symbol
            const hex = color.startsWith('#') ? color : `#${color}`;

            // Call the convert hex to rgba function, passing alpha if needed
            return css_utils.hexToRgba(hex);
        }

        // If it doesn't match any valid format, return the original value or throw an error.
        return color;
    },



    hexToRgba: function (hex) {
        // Remove the hash symbol if present
        hex = hex.replace('#', '');

        let r, g, b, a = 1; // Default alpha is 1 (fully opaque)

        // If it's a 3 or 4-character hex code, expand it to 6 or 8 characters
        if (hex.length === 3 || hex.length === 4) {
            hex = hex.split('').map(h => h + h).join('');
        }

        // Extract the RGB values (and optionally the alpha)
        r = parseInt(hex.substring(0, 2), 16);
        g = parseInt(hex.substring(2, 4), 16);
        b = parseInt(hex.substring(4, 6), 16);

        // If the hex code has an alpha value (8 characters), extract it and round to 2 decimals
        if (hex.length === 8) {
            a = (parseInt(hex.substring(6, 8), 16) / 255).toFixed(2); // Round alpha to 2 decimal places
        }

        // Return the RGBA format
        return `rgba(${r}, ${g}, ${b}, ${a})`;
    },





    closeExtraPanels: function () {
        document.removeEventListener('click', css_utils.closeExtraPanels);
        document.querySelectorAll(".value_source_panel").forEach(item => item.remove())
        document.querySelectorAll(".css_units").forEach(item => item.remove())
    },


    openAncestoryPanel: function (location, variable, style) {

        publicai.classesExpansionPanelCloseFunction = css_utils.closeExtraPanels
        let inheritValuesFromAncestors = false
        let acestorClassFound = false
        let ancestorWithValue = null
        if (css_utils.inheritance[variable]) {
            inheritValuesFromAncestors = true
        }
        let inheritedValues = findClosestCssValue(variable) //only if values can be inherited. Width etc are not. 
        if (inheritedValues.length > 0) {
            //the value is inherited from another container
            //console.log("found inheritedValues", inheritedValues)
            acestorClassFound = true
            ancestorWithValue = inheritedValues;
        }

        let panel = addWhatWhere(components.css.value_source_panel, location)
        if (style) {
            panel.classList.add(style)
        } else {
            panel.classList.remove("right") //to remove, redundant
        }

        let container = panel.querySelector(".css_extra_states")

        removeAllChildNodes(container)

        let reset = $$$(panel, "data-reset", "reset")

        reset.onclick = (e) => {
            e.stopPropagation()
            e.preventDefault()

            if (animator_timeline.mode === "keyframes") {
                console.log("NO reset in keyframes mode.")
                return
            }

            delete publicai.active.lastStyleInStack.css[variable]
            css_management.manage()
            applyCSS()
            css_utils.closeExtraPanels()
        }



        function addInheritedClass(css) {
            if (css.css[variable]) {
                let source = addWhatWhere(components.css.css_source_item, container)
                let label = source.querySelector(".source_label")
                label.textContent = css.name
                if (css.value) {
                    label.textContent += " (" + css.value + ")"
                }

                if (css.id) {
                    source.classList.add("clickable")
                }

                source.onclick = (e) => {
                    if (css.id) {
                        console.log(css.id)
                        _create.activateSpecificElement(css.id)
                    }


                    /*       notifications.show([
                              [css.name, JSON.stringify(css.css)]
                          ]) */

                    css_utils.closeExtraPanels()
                    e.stopPropagation()
                    e.preventDefault()
                }
            }

        }

        for (let css of publicai.active.cssStack) {
            addInheritedClass(css)
        }

        if (acestorClassFound) {
            console.log("founde inherited from ancestors", ancestorWithValue)
            for (let css of ancestorWithValue) {
                addInheritedClass(css)
            }
        }

        render.addClassesToAllChildrenOf(container, "inactive", true)
        if (container.childNodes.length > 0) {
            container.childNodes[0].classList.remove("inactive")
        }
    },


    highlightThisPanel(group, trueOrFalse) {
        //fades all other panels when a pop up opens
        let allGroups = $$$(document, "data-group")
        if (!trueOrFalse) {
            //back to normal
            for (let item of allGroups) {
                item.classList.remove("public-group-faded")
                item.style.pointerEvents = "auto"
            }
        } else {
            for (let item of allGroups) {
                if (item != group) {
                    let groupName = item.getAttribute("data-group")
                    if (groupName != "classes") {


                        item.classList.add("public-group-faded")
                        item.style.pointerEvents = "none"
                        item.style.zIndex = 1
                    }
                }
            }
            group.style.zIndex = 999

        }
    },

    closeAllpanels: function () {

    },






    manageCombos: function (panel, objectLocation) {

        let allCombos = $$$(panel, "animation-value")
        let allPanels = $$$(panel, "animation-panel")


        /*     if (!objectLocation.activePanel) {
                objectLocation.activePanel = "scale"
            } */

        for (let item of allCombos) {
            item.classList.remove("active")
            item.onclick = function () {
                objectLocation.activePanel = this.getAttribute("animation-value")
                refreshCombos()
            }
        }

        function refreshCombos() {

            for (let item of allCombos) {
                if (item.getAttribute("animation-value") == objectLocation.activePanel) {
                    item.classList.add("active")
                } else {
                    item.classList.remove("active")
                }
            }

            for (let item of allPanels) {
                if (item.getAttribute("animation-panel") == objectLocation.activePanel) {
                    item.classList.add("active")
                } else {
                    item.classList.remove("active")
                }
            }

        }

        refreshCombos()

    },

  
    // Mapping of CSS properties that need conversion
   

}



