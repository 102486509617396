
import { css_input } from './css_input.js';

export var css_spacing = {

    init: function () {
        //css_classes.add()

        if (!this.ui) {

            let container = $$$(document, "data-group", "spacing")

            this.ui = {
                "panel": container,
                "labelContainer": container.querySelector(".reg_label"),
                "label": container.querySelector(".field_value"),
                "allControlers": $$$(container, "controller"),
                "spacing_config": $$$(container, "data-panels-spacing", "config"),
                "allDataInputGroup": $$$(container, "data-input-all", "spacing"),
                "input": $$$(container, "data-input", "input"),
            }
        }

        this.configureSpacing()
    },

    configureSpacing: function () {

        function closePanel(where) {

            css_spacing.ui.spacing_config.classList.remove("active")
            document.removeEventListener('click', closePanel);
            css_utils.highlightThisPanel(null, false)
            
        }

        function refreshAllValues(applyCCSS = false) {

            
            for (let box of css_spacing.ui.allControlers) {
                let setting = box.getAttribute("controller")
                let valueResponse = css_utils.getExistingValues(setting)

                box.classList.remove("hasvalue")
                box.classList.remove("hasinheritedvalue")
                
                if (valueResponse.style) {
                    box.classList.add(valueResponse.style)
                    //render.addClassesToAllChildrenOf(box, valueResponse.style, true)
                }else{
                    box.classList.remove("hasvalue")
                    box.classList.remove("hasinheritedvalue")

                    
                }
                box.textContent = valueResponse.value
            }

            if (applyCCSS) {
                applyCSS()
            }
        }

        refreshAllValues(false)


        for (let box of css_spacing.ui.allControlers) {
            css_spacing.configureSpacingWidget(box, refreshAllValues, closePanel, css_spacing.ui)
        }
    },

    configureSpacingWidget: function (box, refreshAllValues, closePanel, ui) {


        //console.log("spacing for ", box, refreshAllValues, ui)


        let controls = ui.spacing_config

        box.onclick = (event) => {
            closePanel()

            let setting = box.getAttribute("controller")
            event.stopPropagation()
            event.preventDefault()


            controls.classList.add("active")

            controls.onclick = (event) => {
                event.stopPropagation()
                event.preventDefault()
            }

            css_utils.highlightThisPanel(ui.panel, true)
            document.addEventListener('click', closePanel);
            console.log("added event list")
            nwsapp.activeDocument.addEventListener('click', closePanel);

            publicai.closeFunction = closePanel


            ui.allDataInputGroup.removeAttribute("data-input-group")
            ui.allDataInputGroup.setAttribute("data-input-group", setting)


            //setting up the input
            function refreshInput() {
               /*  css_utils.input({
                    "selector": ui.allDataInputGroup,
                    "variable": ui.allDataInputGroup.getAttribute("data-input-group"),
                    "label": capitaliseFirstLetter(setting.replaceAll("-", " ")),
                    "validation": "none",
                }) */

                css_input.input({
                    "selector": ui.allDataInputGroup,
                    "variable": ui.allDataInputGroup.getAttribute("data-input-group"),
                    "label": capitaliseFirstLetter(setting.replaceAll("-", " ")),
                    "validation": "none",
                    "number":  true, 
                    "doubleClick": true,
                })


/* 
                css_input.input({
                    "selector": item,
                    "variable": item.getAttribute("data-input-group"),
                    "validation": "none",
                    "number":  true, 
                    "rightClick": rightClickValues, 
                    "doubleClick": true,
                }) */


            }

            refreshInput()

            let input = ui.input
            let label = $$$(controls, "spacing", "label")
            let save = $$$(controls, "spacing", "save")
            let apply_to_all = $$$(controls, "spacing", "apply_to_all")
            let reset = $$$(controls, "spacing", "reset")
            let allPresets = $$$(controls, "presetvalue")



            function handleEnterKeyPress(event) {
                if (event.key === 'Enter') {
                    // Prevent any parent handlers from being notified of the event
                    document.removeEventListener('keydown', handleEnterKeyPress);
                    event.stopPropagation();
                    event.preventDefault();
                    saveAndClose()
                    // Your code here, what you want to do when Enter is pressed
                    console.log('Enter key pressed and event propagation stopped.');
                    // If you want to remove the listener after the first Enter press
                    
                }
            }

            // Add the event listener to the document
            document.addEventListener('keydown', handleEnterKeyPress);



            for (let preset of allPresets) {
                let theValue = preset.getAttribute("presetvalue")
                preset.onclick = () => {
                    input.value = theValue
                    publicai.active.lastStyleInStack.css[setting] = theValue
                    input.focus()
                    input.select()
                    input.blur()
                    refreshInput()
                    setTimeout(() => {
                        refreshAllValues(true)
                    }
                        , 20)
                }
            }

            input.focus()
            setTimeout(() => {
                input.select()
            }
                , 100)

            function saveAndClose() {
                input.focus()
                input.select()
                input.blur()
                closePanel()
                refreshAllValues(true)
                document.removeEventListener('keydown', handleEnterKeyPress);

            }

            save.onclick = () => {
                saveAndClose()

            }

            apply_to_all.onclick = () => {
                document.removeEventListener('keydown', handleEnterKeyPress);
                input.focus()
                input.select()
                input.blur()
                let valueForAll = publicai.active.lastStyleInStack.css[setting]
                let allSettings
                if (setting.includes("padding")) {
                    console.log("padding")
                    allSettings = ["padding-top", "padding-right", "padding-bottom", "padding-left"]
                }
                if (setting.includes("margin")) {
                    console.log("margin")
                    allSettings = ["margin-top", "margin-right", "margin-bottom", "margin-left"]
                }

                for (let item of allSettings) {
                    publicai.active.lastStyleInStack.css[item] = valueForAll
                }
                closePanel()
                refreshAllValues(true)
            }
        }

    },


    


}