

import * as utils from '../utils/utils.js';


export async function loadAgent(agentId) {
    let url = "/api/agent/get";
    await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: agentId }),
    })
        .then((response) => response.json())
        .then((json) => {
            console.log("Got agent:", json);
            nwsapp.currentAgent = json;  // Assign agent data to a global variable
        })
        .catch((error) => {
            console.error("Error getting agent:", error);
        });
}


export  async function saveAgent() {
    console.log("***save agent");

    let agentData = nwsapp.currentAgent;

    let url = "/api/agent/save";

    await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(agentData)
    })
        .then(response => response.json())
        .then(json => {
            console.log("Agent saved:", json);
        })
        .catch(error => {
            console.error("Error saving agent:", error);
        });
}


function getDefaultAgent() {
    // Generate a unique ID for the agent
    const agentId = utils.generateSceneID()
    const timestamp = new Date().toISOString();

    // Essential fields required for DynamoDB
    const defaultAgent = {
        userId: "",  // This should be set to the current user's ID when saving
        id: agentId,
        name: "New Agent",
        description: "This is a default agent.",
        created: timestamp,
        modified: timestamp
    };

    // Advanced config stored under schema
    defaultAgent.schema = {
        flow: {
            id: "default_flow",  // ID for the main flow
            name: "Default Flow",  // Name of the flow
            steps: [
                {
                    id: "step_1",
                    type: "question",
                    content: "Welcome to the agent! How can I assist you?",
                    next: "step_2"
                },
                {
                    id: "step_2",
                    type: "response",
                    content: "Thank you for your input.",
                    next: null  // No next step, end of flow
                }
            ]
        },
        questions: [
            {
                id: "q1",
                text: "What is your name?",
                type: "text",
                options: []  // Options for multiple-choice questions
            },
            {
                id: "q2",
                text: "How can we assist you today?",
                type: "text",
                options: []
            }
        ],
        responses: [
            {
                id: "r1",
                text: "Thank you for your response."
            },
            {
                id: "r2",
                text: "Please let us know if you need further assistance."
            }
        ]
    };

    return defaultAgent;
}




export  async function createNewAgent() {
    console.log("***create new agent");

    // Define a function or use an existing function to get the default agent structure
    let agentData = getDefaultAgent();

    // Generate a unique ID for the new agent
    let agentId = utils.generateSceneID()
    agentData.id = agentId;

    console.log("Saving new agent:", agentData);

    let url = "/api/agent/save";

    await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(agentData)
    })
        .then(response => response.json())
        .then(json => {
            console.log("Agent saved:", json);
        })
        .catch(error => {
            console.error("Error saving agent:", error);
        });

    delete nwsapp.currentAgent;
    nwsapp.currentAgent = agentData;
    router.updateURLSilently("//" + agentId + "/details");
}


export async function deleteAgent(agentId) {
    let url = "/api/agent/delete";

    await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ resourceId: agentId }),
    })
        .then((response) => response.json())
        .then((json) => {
            console.log("Agent deleted:", json);
        })
        .catch((error) => {
            console.error("Error deleting agent:", error);
        });
}
