export var _find = {


    elementInSchema: function (elementID) {

        for (let schema of Object.keys(publicai.dataSources.schemas)) {
            for (let section of publicai.dataSources.schemas[schema].schema.sections) {
                for (let element of section.elements) {
                    if (element.id == elementID) {
                        return (element)
                    }
                }
            }
        }
    },


    sourcePageMatchingSchema: function (schema) {

        for (let page of publicai.pages.content) {
            if (page.schema == schema) {
                return (page)
            }
        }
    },

    findCombo: function (css, theClass) {
        // Recursive function to search for the combo
        //console.log(css, theClass)
        function searchCombo(combo) {
            if (!combo || typeof combo !== 'object') return null;

            // Check if the combo has the desired class key directly
            if (combo.hasOwnProperty(theClass)) {
                return combo[theClass];
            }

            // Recursively search through the first key's combo, if it exists
            for (let key in combo) {
                if (combo[key] && combo[key].combo) {
                    const result = searchCombo(combo[key].combo);
                    if (result) {
                        return result;
                    }
                }
            }

            return null;
        }

        // Start searching from the top level
        return searchCombo(css.combo);
    },






    reCreateAnimationsForAllChildren: function (data, theClass, addOrRemove, checkIfClassesExist) {
        //adds specific combo class to all children of a node
        if (data) {
            if (addOrRemove) {
                data.classes[0].combo.add(theClass)

                if (checkIfClassesExist) {
                    //create the combo if it doesn't exist
                    let currentObject = css_management.getCssStack(data)
                    let lastInStack = currentObject.cssStack[currentObject.cssStack.length - 1]
                    if (!lastInStack.css.combo[theClass] && currentObject.lastStyleInStack.name != theClass) {
                        //avoid adding it twice
                        lastInStack.css.combo[theClass] = {
                            "combo": {},
                            "keyframes": {},
                            "keyframe_definitions": {},
                            "state": {}
                        }
                    }
                }
            } else {
                data.classes[0].combo.remove(theClass)
            }
        }

        // If the current data has a 'content' property, search within it
        if (data.content && Array.isArray(data.content)) {
            for (let i = 0; i < data.content.length; i++) {
                const result = _find.reCreateAnimationsForAllChildren(data.content[i], theClass, addOrRemove, checkIfClassesExist);
                if (result) {
                    return result;
                }
            }
        }
        // If the contentId was not found in the current path
        return null;
    },





    addClassesToAllChildren: function (data, theClass, addOrRemove, checkIfClassesExist) {
        //adds specific combo class to all children of a node
        if (data) {
            if (addOrRemove) {
                data.classes[0].combo.add(theClass)

                if (checkIfClassesExist) {
                    //create the combo if it doesn't exist
                    let currentObject = css_management.getCssStack(data)
                    let lastInStack = currentObject.cssStack[currentObject.cssStack.length - 1]
                    if (!lastInStack.css.combo[theClass] && currentObject.lastStyleInStack.name != theClass) {
                        //avoid adding it twice
                        lastInStack.css.combo[theClass] = {
                            "combo": {},
                            "keyframes": {},
                            "keyframe_definitions": {},
                            "state": {}
                        }
                    }
                }
            } else {
                data.classes[0].combo.remove(theClass)
            }
        }

        // If the current data has a 'content' property, search within it
        if (data.content && Array.isArray(data.content)) {
            for (let i = 0; i < data.content.length; i++) {
                const result = _find.addClassesToAllChildren(data.content[i], theClass, addOrRemove, checkIfClassesExist);
                if (result) {
                    return result;
                }
            }
        }
        // If the contentId was not found in the current path
        return null;
    },

    lightboxElement: function (data, role, lightBoxName, lightBoxGroupName) {

        //used for lightboxes
        if (!publicai.searchResults) {
            publicai.searchResults = [];
        }

        // Check if the current data object matches the criteria
        if (data && data.settings && data.settings.role) {
            let roleMatch = data.settings.role == role;
            let lightBoxNameMatch = lightBoxName ? data.settings?.lightbox?.name == lightBoxName : true; // True if lightBoxName not specified
            let lightBoxGroupNameMatch = lightBoxGroupName ? data.settings?.lightbox?.group == lightBoxGroupName : true; // True if lightBoxGroupName not specified

            // If all specified criteria match, add the data object to the results
            if (roleMatch && lightBoxNameMatch && lightBoxGroupNameMatch) {
                publicai.searchResults.push({
                    node: data,
                    el: $$$(nwsapp.activeDocument, "public_id", data.id)
                });
            }
        }

        if (data.content && Array.isArray(data.content)) {
            for (let i = 0; i < data.content.length; i++) {
                _find.lightboxElement(data.content[i], role, lightBoxName, lightBoxGroupName);
            }
        }

        return publicai.searchResults;
    },




    elementByRole: function (data, role, lightBoxName) {
        //can also look for lightbox elements
        if (data && data.settings && data.settings.role) {

            if (lightBoxName) {
                if (data.settings.role == role && data.settings.lightbox?.name == lightBoxName) {
                    return data;
                }
            }

            if (data.settings.role == role) {
                return data;
            }
        }

        // If the current data has a 'content' property, search within it
        if (data.content && Array.isArray(data.content)) {
            for (let i = 0; i < data.content.length; i++) {
                const result = _find.elementByRole(data.content[i], role);
                if (result) {
                    return result;
                }
            }
        }
        // If the contentId was not found in the current path
        return null;
    },

    schemaElement: function (schema, elementId) {

        let sections = schema.schema.sections

        for (let section of sections) {
            for (let element of section.elements) {
                if (element.id == elementId) {
                    return element
                }
            }
        }
    },





    elementsByLinkage: function (data, element, excludeId = null) {

        //finds all other page elements with the same element linkage to determine order and allow for fragments to work.

        let results = [];

        function searchByLinkage(data, element, excludeId) {
            if (data && data.dynamic) {
                //console.log("check", data.dynamic)
                if (data.dynamic && data.dynamic.element && data.dynamic.element.id == element) {

                    if (excludeId) {
                        if (data.id == excludeId) {
                            return
                        }
                    }

                    results.push({
                        node: data,
                        el: $$$(nwsapp.activeDocument, "public_id", data.id)
                    });
                }
            }
            if (data.content && Array.isArray(data.content)) {
                for (let i = 0; i < data.content.length; i++) {
                    searchByLinkage(data.content[i], element, excludeId);
                }
            }
        }

        searchByLinkage(data, element, excludeId);

        return results;
    },


    elementsByRole: function (data, role) {
        //return all elements with a given role.

        let results = []; // Initialize an array to hold matching elements

        // Function to recursively search for elements that match the role
        function searchByRole(data, role) {

            //console.log("open", data.settings)

            if (data && data.settings && data.settings.role) {
                if (data.settings.role == role) {
                    results.push({
                        node: data,
                        el: $$$(nwsapp.activeDocument, "public_id", data.id)
                    });


                }
            }

            // If the current data has a 'content' property, search within it
            if (data.content && Array.isArray(data.content)) {
                for (let i = 0; i < data.content.length; i++) {
                    searchByRole(data.content[i], role); // Recursively search in sub-elements
                }
            }
        }

        searchByRole(data, role); // Start the search with the initial data

        return results; // Return all found elements matching the role
    },

    elementsByName: function (data, name) {
        let results = []; // Initialize an array to hold matching elements

        // Function to recursively search for elements that match the name
        function searchByName(currentData, name) {
            if (currentData && currentData.properties && currentData.properties.name) {
                if (currentData.properties.name === name) {
                    results.push({
                        node: currentData,
                    });
                }
            }

            // If the current data has a 'content' property, search within it
            if (currentData.content && Array.isArray(currentData.content)) {
                for (let i = 0; i < currentData.content.length; i++) {
                    searchByName(currentData.content[i], name); // Recursively search in sub-elements
                }
            }
        }

        searchByName(data, name); // Start the search with the initial data

        return results; // Return all found elements matching the name
    },

    findFirstElementByName: function (data, name) {
        let result = null; // Initialize to null, indicating no match has been found yet

        // Function to recursively search for the first element that matches the name
        function searchByName(currentData, name) {
            if (result) return; // If a result is already found, exit early to prevent further searching

            if (currentData && currentData.properties && currentData.properties.name) {
                if (currentData.properties.name === name) {
                    result = currentData; // Assign the found node to result and stop further searching
                    return;
                }
            }

            // If the current data has a 'content' property, search within it
            if (currentData.content && Array.isArray(currentData.content)) {
                for (let i = 0; i < currentData.content.length; i++) {
                    searchByName(currentData.content[i], name); // Recursively search in sub-elements
                    if (result) return; // Exit the loop early if the result is found
                }
            }
        }

        searchByName(data, name); // Start the search with the initial data

        return result; // Return the first found element, or null if no match is found
    },

    parseHTML: function (data) {
        // Ensure the nwsapp.system_ux object is properly initialized
        nwsapp.system_ux = {};

        function parseContent(currentData, currentObject) {
            // Determine the path for system_ux storage
            const objectKey = currentData.properties.name;

            // Log the folder being entered
            //console.log('Entering folder:', objectKey);

            // Handle 'page' type elements that contain HTML but no nested 'content'
            if (currentData.type === 'page' && currentData.html) {
                const parser = new DOMParser();
                let doc = parser.parseFromString(currentData.html, 'text/html');

                if (doc.body.firstChild) {
                    let html = doc.body.firstChild.cloneNode(true); // Clone the DOM element
                    // Store the html content at the constructed path
                    currentObject[objectKey] = html;
                }
            }

            // Handle 'folder' type elements that may contain nested 'content'
            else if (currentData.type === 'folder') {
                // Create a nested object for this folder
                currentObject[objectKey] = {};

                // Only process content if it's a folder
                if (currentData.content && Array.isArray(currentData.content)) {
                    currentData.content.forEach(child => {
                        // Recursive call with the new nested object
                        parseContent(child, currentObject[objectKey]);
                    });
                }
            }
        }

        // Start parsing from the root node
        parseContent(data, nwsapp.system_ux);
        // Optionally return the data to confirm changes or to chain functions
        return data;
    },









    childPages: function (data, value) {
        let results = [];

        // Check if the current data object is of the desired type
        if (data && data.type && data.type === value) {

            data.url = _utils.getPageURL(data)
            results.push(data);
        }

        // Recursively search in the content array if it exists
        if (data.content && Array.isArray(data.content)) {
            for (let i = 0; i < data.content.length; i++) {
                const childResults = _find.childPages(data.content[i], value);
                results = results.concat(childResults);
            }
        }

        return results;
    },


    childrenByType: function (data, type) {
        let results = []; // Initialize an array to hold matching elements

        // Function to recursively search for elements that match the type
        function searchByType(currentData, type) {
            if (currentData && currentData.type && currentData.type === type) {
                results.push(currentData);
            }

            // If the current data has a 'content' property, search within it
            if (currentData.content && Array.isArray(currentData.content)) {
                for (let i = 0; i < currentData.content.length; i++) {
                    searchByType(currentData.content[i], type); // Recursively search in sub-elements
                }
            }
        }

        searchByType(data, type); // Start the search with the initial data

        return results; // Return all found elements matching the type
    },






    childByType: function (data, value) {

        if (data && data.type && data.type == value) {
            return data;
        }

        if (data.content && Array.isArray(data.content)) {
            for (let i = 0; i < data.content.length; i++) {
                const result = _find.childByType(data.content[i], value);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    },

    elementByAttribute: function (data, key, value) {
        // Base case: if the current data is the object we're looking for

        /*    if (data && data.settings && data.settings.attributes && data.settings.attributes.length > 0 &&
               data.settings.attributes[0].name === key &&
               data.settings.attributes[0].value === value) {
               return data;
           } */

        if (data && data.settings && data.settings.attributes && data.settings.attributes.length > 0) {
            const attributeMatch = data.settings.attributes.find(attribute => attribute.name === key && attribute.value === value);
            if (attributeMatch) {
                return data;
            }
        }


        // If the current data has a 'content' property, search within it
        if (data.content && Array.isArray(data.content)) {
            for (let i = 0; i < data.content.length; i++) {
                const result = _find.elementByAttribute(data.content[i], key, value);
                if (result) {
                    return result;
                }
            }
        }
        // If the contentId was not found in the current path
        return null;
    },

    $$$: function (data, key, value) {

        if (!data) {
            data = publicai.active.page
        }


        if (data && data.settings && data.settings.attributes && data.settings.attributes.length > 0) {
            const attributeMatch = data.settings.attributes.find(attribute => attribute.name === key && attribute.value === value);
            if (attributeMatch) {
                return ({
                    node: data,
                    el: $$$(nwsapp.activeDocument, "public_id", data.id)
                })
            }
        }


        // If the current data has a 'content' property, search within it
        if (data.content && Array.isArray(data.content)) {
            for (let i = 0; i < data.content.length; i++) {
                const result = _find.$$$(data.content[i], key, value);
                if (result) {
                    return result;
                }
            }
        }
        // If the contentId was not found in the current path
        return null;
    },

    elementByID: function (data, contentId, variable = "id") {
        // Base case: if the current data is the object we're looking for
        if (data && data[variable] && data[variable] === contentId) {
            return data;
        }

        // If the current data has a 'content' property, search within it
        if (data.content && Array.isArray(data.content)) {
            for (let i = 0; i < data.content.length; i++) {
                const result = _find.elementByID(data.content[i], contentId, variable);
                if (result) {
                    return result;
                }
            }
        }
        // If the contentId was not found in the current path
        return null;
    },

    pageByRole: function (data, role, id = null) {
        // Base case: if the current data is the object we're looking for
        if (data && data.role && data.role == role) {
            return data;
        }

        // If the current data has a 'content' property, search within it
        if (data.content && Array.isArray(data.content)) {
            for (let i = 0; i < data.content.length; i++) {
                const result = _find.pageByRole(data.content[i], role);
                if (result) {
                    return result;
                }
            }
        }
        // If the contentId was not found in the current path
        return null;
    },

    pagesByRole: function (data, role, id = null) {

        //checks for schema id not page id

        let results = [];

        // Base case: if the current data matches the role (and id, if provided)
        if (data && data.properties && data.properties.role == role) {
            if (id === null || (data.schema && data.schema.id && data.schema.id == id)) {
                results.push(data);
            }
        }

        // Only search within 'content' if the current data's type is 'folder'
        if (data.type === "folder" && data.content && Array.isArray(data.content)) {
            for (let i = 0; i < data.content.length; i++) {
                const nestedResults = _find.pagesByRole(data.content[i], role, id);

                if (nestedResults.length > 0) {
                    results = results.concat(nestedResults);
                }
            }
        }

        // Return the accumulated results
        return results;
    },




    getFirstAgentPage: function (page) {
        let firstAgentPage = null

        let root = _find.getRoot(publicai.active.page.id)
        let firstAgent = root.content.filter(object => object.properties.role == "prompts")[0].content[0]

        return firstAgent

        if (publicai.active.page.properties.role == "template") {
            let a1 = _find.getRoot(publicai.active.page.id).ancestor
            let a2 = _find.elementByID(publicai.pages, a1).ancestor
            let a3 = _find.elementByID(publicai.pages, a2)

            let promptsFolder = a3.content.filter(object => object.properties.role == "prompts");
            if (promptsFolder && promptsFolder[0].content) {

                console.log("promptsFolder", promptsFolder)

                let firstFolder = promptsFolder[0].content.filter(object => object.properties.role == "prompt");
                console.log("firstFolder", firstFolder)
                if (firstFolder) {
                    //consider returning all
                    firstAgentPage = firstFolder[0].content[0]
                }
            }
        }

        return (firstAgentPage)

    },



    findClosestAncestorWithNonZeroDuration: function (currentElement, stopAtNodeId) {
        let location = nwsapp.currentProject.config.root.pages;

        // Helper function to recursively find the closest ancestor with a non-zero duration
        function recursiveFindAncestorWithDuration(element) {
            // Check if the current element has a non-zero duration
            let duration = element?.settings?.timing?.duration || 0;

            if (duration > 0) {
                return {
                    element: element,
                    duration: duration
                };
            }

            // Stop if the current element's ID matches stopAtNodeId
            if (element.id === stopAtNodeId) {
                return null;
            }

            // Check if the current element has an ancestor
            if (element.ancestor) {
                let ancestor = _find.elementByID(location, element.ancestor);
                if (ancestor) {
                    return recursiveFindAncestorWithDuration(ancestor);
                }
            }

            // Return null if no ancestor with non-zero duration is found
            return null;
        }

        if (currentElement) {
            // Start the recursion with the current element
            return recursiveFindAncestorWithDuration(currentElement);
        }

        return null;
    },



    sumUpParentStartTimes: function (curentElement, stopAtNodeId) {


        //console.error("running sum up")

        let location = nwsapp.currentProject.config.root.pages;

        //stopAtNodeId indicates the top parent. If editing in another div, it will stop at the parent of that div. In player, it will stop at body.

        // Initialize the result object
        let result = {
            ancestors: {},
            total: 0
        };

        // Helper function to recursively sum up start times
        function recursiveSumUp(element, accumulatedTime) {
            // If there's a start time, add it to the accumulated time
            let startTime = element?.settings?.timing?.start || 0;
            //console.log("adding", startTime, "from element", element.classes[0].name);
            accumulatedTime += startTime;

            // Store the current element's start time in the ancestors object
            result.ancestors[element.id] = startTime;

            // Stop if the current element's ID matches stopAtNodeId, but include its time
            if (element.id === stopAtNodeId) {
                return accumulatedTime;
            }

            // Check if the current element has an ancestor
            if (element.ancestor) {
                let ancestor = _find.elementByID(location, element.ancestor);
                if (ancestor) {
                    return recursiveSumUp(ancestor, accumulatedTime);
                }
            }

            // Return the total accumulated time
            return accumulatedTime;
        }

        if (curentElement) {
            // Start the recursion with the current element
            result.total = recursiveSumUp(curentElement, 0);
        }

        return result;
    },



    findChildAnimations: function (node, parentId = null, searchResults = {}) {
        // Check if the node exists and has a valid ID
        if (node && node.id) {
            // Check if there's an animation for this node ID
            if (publicai.animations[node.id] && Object.keys(publicai.animations[node.id]).length > 0) {
                // Add the entry to the search results
                searchResults[node.id] = {
                    parent: parentId,
                    animation: publicai.animations[node.id],
                };
            }

            // If the node has children, recursively search them
            if (node.content && Array.isArray(node.content)) {
                node.content.forEach(child => {
                    _find.findChildAnimations(child, node.id, searchResults);
                });
            }
        }

        // Return the search results
        return searchResults;
    },






    forceReHandleChildren: function (node) {
        // Check if the node is valid and has a 'content' property which is an array with elements
        if (node && node.content) {
            // Log the current node because it has content with children

            _create.forceReHandle(node) //re-creates the element to take changes into account

            // Recursively call this function for each child in the content array
            node.content.forEach(child => {
                _find.forceReHandleChildren(child);
            });
        }
    },



    isClassInUse: function (location, className, rootFolderID) {
        // Base case: Check if the current location has a classes property
        // and if one of those classes matches the given class name.

        if (location.settings && location.settings.role == "schemaroot") {
            console.log("whoops, can't enter this folder")
            return false;
        }


        if (location.classes && Array.isArray(location.classes)) {
            for (let j = 0; j < location.classes.length; j++) {
                if (location.classes[j].name === className) {
                    return true;
                }
            }
        }

        // Recursive case: If the current location has a 'content' property, search within it
        if (location.content && Array.isArray(location.content)) {
            for (let i = 0; i < location.content.length; i++) {
                const result = _find.isClassInUse(location.content[i], className);
                if (result) {
                    return true; // Class found in one of the children
                }
            }
        }

        // If the class was not found in the current path
        return false;
    },

    allPagesAndFolders: function (data, level = 1, found = []) {
        // Base case: if the current data is null or undefined, return the collected array
        if (!data) {
            return found;
        }

        // If the current data is an array, iterate through each item
        if (Array.isArray(data)) {
            data.forEach(item => {
                _find.allPagesAndFolders(item, level, found);
            });
        } else {
            // Check if the current data is of type 'page' or 'folder' before adding
            if (data.type === 'page' || data.type === 'folder') {
                found.push({ level: level, el: data, type: data.type });

                // Only continue searching within 'page' type nodes
                if (data.type === 'page' && data.content && Array.isArray(data.content) && data.content.length > 0) {
                    data.content.forEach(child => {
                        _find.allPagesAndFolders(child, level + 1, found);
                    });
                }
            }
        }

        // Return the collected array of found elements
        return found;
    },

    getAllAncestorSlugs: function (id) {
        let location = nwsapp.currentProject.config.root.pages;
        let currentPage = this.elementByID(location, id);
        let ancestorSlugs = [];

        while (currentPage && currentPage.ancestor) {
            let ancestor = this.elementByID(location, currentPage.ancestor);
            if (ancestor) {
                // Prepend the ancestor slug to the array
                // console.log("ancestor.properties.slug", ancestor.properties.slug)
                ancestorSlugs.unshift(ancestor.properties.slug); // assuming the ancestor has a property 'slug'
                // Move up the tree
                currentPage = ancestor;
            } else {
                // No more ancestors, exit the loop
                break;
            }
        }

        return ancestorSlugs;
    },


    checkIfAncestorIsVideoPlayer: function (id, lastFolderFound = null) {
        let location = nwsapp.currentProject.config.root.pages;
        let curentElement = _find.elementByID(location, id);
        if (curentElement?.type.includes("video_player")) {
            return curentElement
        }

        if (curentElement && curentElement.ancestor) {
            let ancestor = _find.elementByID(location, curentElement.ancestor);
            if (ancestor?.dynamic?.schema?.id) {
                return ancestor;
            } else {
                // The return statement here is crucial to pass the value up the recursion chain
                return _find.checkIfAncestorIsVideoPlayer(curentElement.ancestor, ancestor);
            }
        }
        if (lastFolderFound?.dynamic?.schema?.id) {
            return lastFolderFound
        } else {
            return null;
        }
    },

    toggleClass: function (where, key, value, theClass, toggle) {

        //toogles visibility of element by adding class into the node (not in dom)

        return

        let item = _find.$$$(where, key, value)


        //console.log("item", item)


        if (item && item.node.classes.length > 0) {
            let classes = item.node.classes[0]
            let combo = classes.combo

            if (!toggle) {

                for (let el of combo) {
                    if (el == theClass) {
                        let index = combo.indexOf(el)
                        if (index > -1) {
                            combo.splice(index, 1)
                            console.log("removed", theClass)
                        }
                    }
                }

                //combo.remove(theClass)
            } else {
                if (!combo.includes(theClass)) {
                    combo.push(theClass)
                }
            }
            //_create.forceReHandle(item)

            //let ancestor = _find.elementByID(publicai.active.page, item.node.ancestor);
            //play_interactive.createPageElements(ancestor.content, ancestor.id, true, true)

            if (!toggle) {
                item.el.classList.remove(theClass)
            } else {
                item.el.classList.add(theClass)
            }

            //console.log("toggled", toggle, item.el)



        } else {
            //item not found
            return false
        }

    },


    checkIfAncestorType: function (id, typesArray, lastFolderFound = null) {
        let location = nwsapp.currentProject.config.root.pages;
        let curentElement = _find.elementByID(location, id);
        if (typesArray.includes(curentElement?.type)) {
            return curentElement
        }

        if (curentElement && curentElement.ancestor) {
            let ancestor = _find.elementByID(location, curentElement.ancestor);
            return _find.checkIfAncestorType(curentElement.ancestor, typesArray, ancestor);
        }
        if (lastFolderFound?.dynamic?.schema?.id) {
            return lastFolderFound
        } else {
            return null;
        }
    },

    checkIfAncestorIsMediaElement: function (id, lastFolderFound = null) {
        let location = nwsapp.currentProject.config.root.pages;
        let curentElement = _find.elementByID(location, id);
        if (curentElement?.type.includes("media_")) {
            return curentElement
        }

        if (curentElement && curentElement.ancestor) {
            let ancestor = _find.elementByID(location, curentElement.ancestor);
            if (ancestor?.dynamic?.schema?.id) {
                return ancestor;
            } else {
                // The return statement here is crucial to pass the value up the recursion chain
                return _find.checkIfAncestorIsMediaElement(curentElement.ancestor, ancestor);
            }
        }
        if (lastFolderFound?.dynamic?.schema?.id) {
            return lastFolderFound
        } else {
            return null;
        }
    },


    checkIfAncestorIsTable: function (id, lastFolderFound = null) {
        let location = nwsapp.currentProject.config.root.pages;
        let curentElement = _find.elementByID(location, id);
        if (curentElement?.type.includes("table")) {
            return curentElement
        }

        if (curentElement && curentElement.ancestor) {
            let ancestor = _find.elementByID(location, curentElement.ancestor);
            if (ancestor?.dynamic?.schema?.id) {
                return ancestor;
            } else {
                // The return statement here is crucial to pass the value up the recursion chain
                return _find.checkIfAncestorIsTable(curentElement.ancestor, ancestor);
            }
        }
        if (lastFolderFound?.dynamic?.schema?.id) {
            return lastFolderFound
        } else {
            return null;
        }
    },

    checkIfAncestorIsFormElement: function (id, lastFolderFound = null) {
        let location = nwsapp.currentProject.config.root.pages;
        let curentElement = _find.elementByID(location, id);
        if (curentElement?.type.includes("form_")) {
            return curentElement
        }

        if (curentElement && curentElement.ancestor) {
            let ancestor = _find.elementByID(location, curentElement.ancestor);
            if (ancestor?.dynamic?.schema?.id) {
                return ancestor;
            } else {
                // The return statement here is crucial to pass the value up the recursion chain
                return _find.checkIfAncestorIsFormElement(curentElement.ancestor, ancestor);
            }
        }
        if (lastFolderFound?.dynamic?.schema?.id) {
            return lastFolderFound
        } else {
            return null;
        }
    },


    hasMediaAttributes: function (node) {

        let hasFormAttributes = false
        let firstAttributeFound = null



        let attributes = node.settings?.attributes

        if (attributes) {
            for (let item of attributes) {
                //console.log(item)
                if (item.name.includes("form-")) {
                    hasFormAttributes = true
                    firstAttributeFound = item
                }

            }
        } else {
            console.log("node has no attributes", node)
        }

        return ({
            hasFormAttributes: hasFormAttributes,
            firstAttributeFound: firstAttributeFound
        })


    },

    hasFormAttributes: function (node) {

        let hasFormAttributes = false
        let firstAttributeFound = null



        let attributes = node.settings?.attributes

        if (attributes) {
            for (let item of attributes) {
                //console.log(item)
                if (item.name.includes("form-")) {
                    hasFormAttributes = true
                    firstAttributeFound = item
                }

            }
        } else {
            console.log("node has no attributes", node)
        }

        return ({
            hasFormAttributes: hasFormAttributes,
            firstAttributeFound: firstAttributeFound
        })


    },




    isAncestorDynamicCarousel: function (id, lastFolderFound = null) {
        let location = nwsapp.currentProject.config.root.pages;
        let curentElement = _find.elementByID(location, id);
        if (curentElement?.type == 'dynamic_carousel') {
            return curentElement
        }

        if (curentElement && curentElement.ancestor) {
            let ancestor = _find.elementByID(location, curentElement.ancestor);
            if (ancestor?.dynamic?.schema?.id) {
                return ancestor;
            } else {
                // The return statement here is crucial to pass the value up the recursion chain
                return _find.isAncestorDynamicCarousel(curentElement.ancestor, ancestor);
            }
        }
        if (lastFolderFound?.dynamic?.schema?.id) {
            return lastFolderFound
        } else {
            return null;
        }
    },





    getAncestorWithSchemaNew: function (id, lastFolderFound = null) {
        let location = nwsapp.currentProject.config.root.pages;
        let curentElement = _find.elementByID(location, id);


        if (curentElement?.schema?.id) {
            return curentElement
        }

        if (curentElement && curentElement.ancestor) {
            let ancestor = _find.elementByID(location, curentElement.ancestor);
            if (ancestor?.schema?.id) {
                return ancestor;
            } else {
                // The return statement here is crucial to pass the value up the recursion chain
                return _find.getAncestorWithSchemaNew(curentElement.ancestor, ancestor);
            }
        }
        if (lastFolderFound?.schema?.id) {
            return lastFolderFound
        } else {
            return null;
        }
    },

    getAncestorWithSchema: function (id, lastFolderFound = null) {

        // getAncestorWithSchemaNew is more robust. Dynamic may need to be removed

        let location = nwsapp.currentProject.config.root.pages;
        let curentElement = _find.elementByID(location, id);

        //console.log("schema", curentElement)

        if (curentElement?.dynamic?.schema?.id) {
            return curentElement
        }

        if (curentElement && curentElement.ancestor) {
            let ancestor = _find.elementByID(location, curentElement.ancestor);
            if (ancestor?.dynamic?.schema?.id) {
                return ancestor;
            } else {
                // The return statement here is crucial to pass the value up the recursion chain
                return _find.getAncestorWithSchema(curentElement.ancestor, ancestor);
            }
        }
        if (lastFolderFound?.dynamic?.schema?.id) {
            return lastFolderFound
        } else {
            return null;
        }
    },


    findAllForms: function (data, result = []) {
        // Base case: if the current data is the object we're looking for
        if (data && data.settings && data.settings.role?.includes("form")) {
            result.push(data); // Add the current node to the result array
        }

        // If the current data has a 'content' property and it's an array, search within it
        if (data.content && Array.isArray(data.content)) {
            for (let i = 0; i < data.content.length; i++) {
                _find.findAllForms(data.content[i], result); // Recursively search for more forms
            }
        }

        // If the current data is an object but not the one we're looking for, search its properties
        else if (typeof data === 'object' && data !== null) {
            Object.values(data).forEach(childNode => {
                _find.findAllForms(childNode, result); // Recursively search for forms in child nodes
            });
        }

        return result; // Return the array of found forms
    },




    allMedia: function (data, result = {}) {
        // Base case: if the current data is the media object we're looking for
        if (data && data.type === "media") {
            for (let key of Object.keys(data.media.content)) {
                result[key] = publicai.library.files[key]
            }
        }

        // If the current data has a 'content' property and it's an array, search within it
        if (data.content && Array.isArray(data.content)) {
            for (let i = 0; i < data.content.length; i++) {
                _find.allMedia(data.content[i], result); // Recursively search for more media elements
            }
        }

        // If the current data is an object but not the one we're looking for, search its properties
        else if (typeof data === 'object' && data !== null) {
            Object.values(data).forEach(childNode => {
                _find.allMedia(childNode, result); // Recursively search for media in child nodes
            });
        }

        return result; // Return the array of found media elements
    },



    checkIfAncestorIsTemplatesFolder: function (id, lastFolderFound = null) {


        let location = nwsapp.currentProject.config.root.pages;
        let curentElement = _find.elementByID(location, id);

        if (curentElement?.properties?.role?.includes("template")) {
            return curentElement
        }

        if (curentElement && curentElement.ancestor) {
            let ancestor = _find.elementByID(location, curentElement.ancestor);
            if (ancestor?.settings?.role?.includes("template")) {
                return ancestor;
            } else {
                // The return statement here is crucial to pass the value up the recursion chain
                return _find.checkIfAncestorIsTemplatesFolder(curentElement.ancestor, ancestor);
            }
        }
        if (lastFolderFound?.properties?.role && lastFolderFound?.properties.role?.includes("template")) {
            return lastFolderFound
        } else {
            return null;
        }
    },



    getFirstAncestorWithFormRole: function (id, lastFolderFound = null) {

        //places the form id all childnre inputs

        let location = nwsapp.currentProject.config.root.pages;
        let curentElement = _find.elementByID(location, id);

        if (curentElement?.settings?.role?.includes("form")) {
            return curentElement
        }

        if (curentElement && curentElement.ancestor) {
            let ancestor = _find.elementByID(location, curentElement.ancestor);
            if (ancestor?.settings?.role?.includes("form")) {
                return ancestor;
            } else {
                // The return statement here is crucial to pass the value up the recursion chain
                return _find.getFirstAncestorWithFormRole(curentElement.ancestor, ancestor);
            }
        }
        if (lastFolderFound?.settings.role?.includes("form")) {
            return lastFolderFound
        } else {
            return null;
        }
    },


    parentFormNode: function (existingElement, node) {
        let formElement = existingElement; // Assuming xx is your starting element
        while (formElement && !formElement.hasAttribute('public_form')) {
            formElement = formElement.parentElement;
        }

        if (formElement) {
            let formId = formElement.getAttribute("public_id")
            let formNode = _find.elementByID(publicai.active.page, formId)
            return (formNode)
        } else {
            return (null)

        }
    },




    getRoot: function (id, lastFolderFound = null) {
        let location = nwsapp.currentProject.config.root.pages;

        let currentPage = _find.elementByID(location, id);
        /* 
                if (currentPage.properties.role == "template") {
                    let item = _find.elementByID(publicai.pages, currentPage.ancestor)
                    return (item)
                } */

        /*     if (currentPage.properties.role == "template") {
                let item = _find.elementByID(publicai.pages, currentPage.ancestor)
                return (item)
            }
     */
        if (currentPage.properties.role == "prompt") {
            //useful to separate all prompts css from everythign else. 
            let item = _find.elementByID(publicai.pages, currentPage.ancestor)
            return (item)
        }

        if (currentPage.ancestor) {
            let ancestor = _find.elementByID(location, currentPage.ancestor);
            if (!ancestor.ancestor) {
                return lastFolderFound;
            } else {

                return _find.getRoot(currentPage.ancestor, ancestor);
            }
        }

        return lastFolderFound;
    },

    // Function to find a page based on the given route.

    homepageOfFolder: function (folder) {

        if (folder && folder.homepage) {
            let found = _find.elementByID(folder, folder.homepage);
        } else {
            return _find.page404(folder)
        }

    },

    page404: function () {
        let page404 = publicai.pages.content.find(x => x.properties.role === "404");
        return page404 || "404 page not set up correctly"; // Fallback message if not found
    },

    homepageOfFolder: function (folder) {
        let homepage = folder.content.find(x => x.id === folder.homepage);
        if (homepage) {
            return homepage;
        } else {
            return _find.page404();
        }
    },

    pageFromRoute: function (route, returnHomepages = true) {


        let currentFolder = publicai.pages;

        // If no path is specified, we're in the root and we need to find the homepage.
        if (!route) {
            return _find.homepageOfFolder(publicai.pages);
        }

        const pathSegments = route.split("/").filter(segment => segment !== "");

        _find.pathRoute = []
        let foundResult = null

        let i = 0
        for (let segment of pathSegments) {

            //console.log("lookign up ", currentFolder, segment)
            foundResult = _find.findByPathSegment(currentFolder, segment)
            if (!foundResult) {
                console.log("not found")
                return _find.page404();
            } else {
                currentFolder = foundResult;
            }

            //console.log("Found it:", foundResult.properties.slug)
        }

        if (currentFolder.type == "folder") {
            //console.log("found", currentFolder, currentFolder.properties.role)
            if (returnHomepages) {
                return _find.homepageOfFolder(currentFolder);
            }
        }

        return (currentFolder)
    },



    findByPathSegment: function (data, slug) {

        if (data.content && Array.isArray(data.content)) {

            for (let node of data.content) {
                if (node.properties.slug === slug) {
                    return node; // Node found with the matching path segment
                }
            }
        }
        return null;
    },




    deleteObjectByID: function (data, contentId) {
        if (typeof data !== 'object' || data === null) {
            return false;
        }

        if (Array.isArray(data.content)) {
            for (let i = 0; i < data.content.length; i++) {
                // Check if the current item is the object we're looking for and not of type "body"
                if (data.content[i].id === contentId && data.content[i].type !== "body") {
                    data.content.splice(i, 1);
                    return true; // Object found and deleted
                }



                // If the object is of type "body", we don't delete but still search inside it
                const result = _find.deleteObjectByID(data.content[i], contentId);
                if (result) {
                    return true; // Object found and deleted in a nested structure
                }
            }
        }

        return false; // Object not found or not deleted due to type "body"
    },


}
