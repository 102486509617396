

let cssRules = {};
let keyframes = {};
let cssToProcess

export function applyCSS(createHistorySnapshot = true, customCSS = null, target = null) {

    let targetDocument = nwsapp.activeDocument || document;

    if (target) {
        targetDocument = target
    }

    nwsapp.activeDocument.getElementById('dynamic-styles')

    let styleElement = targetDocument.getElementById('dynamic-styles');
    if (!styleElement) {
        styleElement = targetDocument.createElement('style');
        styleElement.id = 'dynamic-styles';
        targetDocument.head.appendChild(styleElement);
    }


    publicai.mediaQueries = generateMediaQueries(publicai.breakpoints.definitions);



    if (customCSS) {
        cssToProcess = customCSS
    } else {
        cssToProcess = publicai.css
    }

    processCssObject(cssToProcess);
    let compiledCssRules = generateCssRules();

    let compiledMediaQueries = generateCssFromObject(publicai.mediaQueries);
    let keyframeText = Object.values(keyframes).join("\n");
    styleElement.textContent = keyframeText + "\n" + compiledCssRules + "\n" + compiledMediaQueries;
    publicai.lastCSS = styleElement.textContent

    // Post-processing after CSS application
    if (!nws.isPlayer) {
        if (!publicai.isColoring) {
            _create.refreshSelectionContainer();
        }
        if (createHistorySnapshot) {
            change_history.snapshot();
        }
    }

    play_interactive.updateFontsInUse();

    if (!nws.isPlayer && animator_timeline.mode == "keyframes") {
        //console.log("refreshed animations as well. ")
        animation_setup.refreshAllAnimations(publicai.selected.settings) //recreates all animations
        keyframes_editor.updateTimeAndDelay(publicai.currentTime, false)
        //animation_setup.createElementAnimation(publicai.selected.settings, publicai.selected.element, publicai.active.body.id)
    }
}






function generateMediaQueries(breakpointsObject) {
    const css = {};

    for (const [key, value] of Object.entries(breakpointsObject)) {
        const breakpoint = value;
        let entries = value.entries;

        // Initialize entries as an empty object if it doesn't exist
        if (!entries) {
            entries = {};
        }

        let mediaQuery = '';

        if (breakpoint.min !== null && breakpoint.max !== null) {
            mediaQuery = `@media (min-width: ${breakpoint.min}px) and (max-width: ${breakpoint.max}px)`;
        } else if (breakpoint.min !== null) {
            mediaQuery = `@media (min-width: ${breakpoint.min}px)`;
        } else if (breakpoint.max !== null) {
            mediaQuery = `@media (max-width: ${breakpoint.max}px)`;
        }

        if (mediaQuery) {
            css[key] = {
                mediaQuery,
                entries
            };
        }
    }

    return css;
}

function generateCssFromObject(breakpoints) {
    let css = '';

    for (const [key, value] of Object.entries(breakpoints)) {
        const mediaQuery = value.mediaQuery;
        const entries = value.entries;

        css += `${mediaQuery} {\n`;

        for (const [selector, styles] of Object.entries(entries)) {
            // Change selector format to .selector
            const formattedSelector = `.${selector}`;

            css += `    ${formattedSelector} {\n`;

            for (const [property, value] of Object.entries(styles)) {
                css += `        ${property}: ${value};\n`;
            }

            css += `    }\n`;
        }

        css += `}\n\n`;
    }

    return css;
}


function processCssObject(cssObject, selectorPrefix = '') {
    cssObject.state = cssObject.state || {}; //initiate them if they don't exist
    cssObject.combo = cssObject.combo || {};

    let theSplit = selectorPrefix.split(".");
    let comboClass = theSplit[theSplit.length - 1];
    let pathClasses = []
    let classWithoutBreakpoints = "";
    let breakpointClass = Object.keys(publicai.breakpoints.definitions).find(
        breakpoint => comboClass && comboClass.endsWith(breakpoint)
    );

    //console.log(selectorPrefix, theSplit)

    let separator = ".";
    if (breakpointClass) {
        for (let item of theSplit) {
            let isThisABreakpoint = Object.keys(publicai.breakpoints.definitions).find(
                breakpoint => item && item.endsWith(breakpoint)
            );

            if (!isThisABreakpoint) {
                pathClasses.push(item)
                //classWithoutBreakpoints += "."  + item;
            }
        }
        classWithoutBreakpoints = pathClasses.join(".")
        separator = ".";
    }


    Object.entries(cssObject).forEach(([key, value]) => {
        // Check for custom properties like scrollbars and transitions before processing as CSS
        if (key === 'custom_scrollbars') {
            css_management.updateScrollbarTheme(value);
            return; // Skip further processing for this property
        }
        /* 
                    if (key === 'opacity') {
                        value = Number(value) / 100;
                    } */

        if (key == "keyframe_definitions") {
            //this is usedd for storing keyframe data
            return
        }

        if (key === 'keyframes' && typeof value === 'object') {

            Object.entries(value).forEach(([frameName, frames]) => {
                let keyframesCss = `@keyframes ${frameName} {`;
                Object.entries(frames).forEach(([key, props]) => {
                    keyframesCss += `${key} {`;
                    Object.entries(props).forEach(([propName, propValue]) => {
                        keyframesCss += `${propName}: ${propValue};`;
                    });
                    keyframesCss += `}`;
                });

                keyframesCss += `}`;

                keyframes[frameName] = keyframesCss

                //appendCssToHead(keyframesCss);
            });
            return;
        }

        if (key === 'custom_transitions' && Array.isArray(value)) {

            //this key is processed manually

            value.forEach(theTransition => {

                let transitionDataCSS = theTransition
                if (transitionDataCSS) {
                    let transitionCSS = Object.entries(transitionDataCSS)
                        .map(([prop, val]) => `${prop}: ${val};`).join(' ');
                    // Append transition CSS to the current selector
                    if (!cssRules[selectorPrefix]) cssRules[selectorPrefix] = [];
                    cssRules[selectorPrefix].push(transitionCSS);
                }
            });
            return; // Skip further processing for this property
        }

        if (key === 'combo' || key === 'state') {
            // Handle combo and state as special nested structures

            let pseudoClasses = ["hover", "active", "pressed", "focused"]; // These are pseudo-classes
            let pseudoElements = ["placeholder", "before", "after"]; // These are pseudo-elements
            let stateSeparator = separator;


            Object.entries(value).forEach(([nestedKey, nestedValue]) => {

                if (pseudoElements.includes(nestedKey)) {
                    stateSeparator = "::"
                } else {
                    if (nestedKey == "hover") {
                        stateSeparator = ":"
                    }
                }



                let newSelector;

                newSelector = selectorPrefix + stateSeparator + nestedKey;

                /*  if (key === 'combo') {
                     newSelector = selectorPrefix + stateSeparator + nestedKey;
                 } else {
                     newSelector = selectorPrefix + stateSeparator + nestedKey;
                 } */

                processCssObject(nestedValue, newSelector);


            });
        } else if (typeof value === 'object' && value !== null && !(value instanceof Array)) {
            // Handle nested objects
            let newPrefix = `${selectorPrefix} ${key}`.trim();
            processCssObject(value, newPrefix);
        } else {
            // Direct CSS properties
            if (breakpointClass) {

                if (!publicai.mediaQueries[comboClass].entries[classWithoutBreakpoints]) {
                    publicai.mediaQueries[comboClass].entries[classWithoutBreakpoints] = {}
                }
                //console.log("is breakpoint", classWithoutBreakpoints, comboClass)
                // If it's a breakpoint, push to mediaQueries
                publicai.mediaQueries[comboClass].entries[classWithoutBreakpoints][key] = value;
                //mediaQueries[breakpointClass].entries[classWithoutBreakpoints] = {}

            } else {
                // Otherwise, push to general cssRules
                if (!cssRules[selectorPrefix]) {
                    cssRules[selectorPrefix] = [];
                }
                cssRules[selectorPrefix].push(`${key}: ${value};`);
            }
        }
    });
}

function generateCssRules() {
    return Object.entries(cssRules).map(([selector, rulesArray]) => {
        // Check if the selector is a media query
        if (selector.startsWith('@media')) {
            // Handle media queries differently to ensure proper CSS syntax
            return `${selector} { ${rulesArray.join('\n')} }`;
        } else {
            return `.${selector} { ${rulesArray.join(' ')} }`;
        }
    }).join("\n");
}