
import { css_audio } from './audio.js';

import { FileLibrary } from '../../../modules/library/library.js';

export var css_media = {

    addAudio: function (node, panelLocation) {

    },

    init: function (changeTab) {

        //this is the floating media panel

        let node = publicai.selected.settings

        let panelLocation = css_management.ui.panels.third

        if (!css_media.ui) {
            let panel = $$$(document, "data-group", "media")
            css_audio.ui = $$$$(document, "audio_settings")
            css_audio.ui.panel = panel
        }





        let panels = ["media_settings", "media"]

        for (let panel of panels) {
            css_management.allPanels[panel].classList.remove("hidden")
        }

        if (!css_media.el) {

            css_media.el = {
                "select": $$$(panelLocation, "media_panel", "select"),
                "container": $$$(panelLocation, "media_panel", "container"),

                "load": $$$(panelLocation, "media_panel", "load"),
                "alt": $$$(panelLocation, "media_panel", "alt"),

                settings_panel1: $$$(panelLocation, "data-group", "media_settings"),
                settings_panel2: $$$(panelLocation, "data-group", "media"),

            }

        }

        css_media.el.settings_panel1.classList.remove("is_hidden")
        css_media.el.settings_panel2.classList.remove("is_hidden")




        //let media = node.media.content

        let dynamicValue = _handlers.getDynamicValue(node)

        let media

        if (dynamicValue) {
            media = dynamicValue.value
            css_media.el.select.classList.add("hidden")

            css_media.el.select.onclick = event => {
                _element_properties_panel.operateDynamicPanel(panelLocation, "dynamic", node)

            }
            css_media.el.container.onclick = event => {
                _element_properties_panel.operateDynamicPanel(panelLocation, "dynamic", node)
            }


        } else {
            media = node.media.content
            css_media.el.select.onclick = event => {
                css_media.openMediaLibrary(media, refreshMedia)
            }
            css_media.el.container.onclick = event => {
                css_media.openMediaLibrary(media, refreshMedia)
            }
        }


        function refreshMedia(incomingMedia = null, refresh = false) {


            if (incomingMedia) {

                //for (let item of incomingMedia) {
                for (let id of Object.keys(incomingMedia)) {
                    publicai.library.files[id] = JSON.parse(JSON.stringify(incomingMedia[id]))

                    node.media.content[id] = {
                        modified: getPrettyTimeStamp(),
                        id: id
                    }
                }
            }

            css_media.createMediaElements({
                media: node.media.content,
                container: css_media.el.container,
                style: ["is_panel"]
            })

            if (refresh) {
                //document.querySelector(".overlay_container").remove()
                _create.forceReHandle(publicai.selected.settings) //re-creates the element to take changes into account
            }

        }
        refreshMedia(null, false)

        //this.randomiseDropdown(node, panelLocation)
        this.preloadDropdown(node, panelLocation)
        this.playerControls(node, panelLocation)
        this.setUpVolume()



    },


    openMediaLibrary: function (media, callback, tab = null) {

        let settings = {
            callback: callback,
            multiSelect: false,
            publicai: publicai,
            tab: tab,
            type: "web",
            callback: callback

        }
        new FileLibrary(settings);

    },

    playerControls: function (node, panelLocation) {

        return
        //need to add player

        native_utils.boolean_combo({
            "selector": css_audio.ui.controls,
            "defaultValue": publicai.selected.settings.settings.player.controls.enabled,
            "storageObject": publicai.selected.settings.settings.player.controls,
            "storageVariable": "enabled",
            "save": true,
            key: "combo",
            "callback": controlsSwitch,
        })


        function controlsSwitch(s) {

            if (s.value == "false") {
                publicai.selected.settings.settings.player.controls.enabled = false
            } else {
                publicai.selected.settings.settings.player.controls.enabled = true
            }

            css_media.refresh()

        }
    },

    setUpVolume: function () {


        if (!publicai.selected.settings.settings.player) { return }

        let selector = css_audio.ui.volume_slider

        let el = $$$$(selector, "data-slider")

        //console.log("el", el)

        let settings = {
            selector: selector,
            object: publicai.selected.settings.settings.player,
            variable: "volume",
            sliderCallback: zoomSliderCallback, // Use throttled version
            defaultValue: publicai.selected.settings.settings.player.volume * 100,
            label: el.label,
            units: "%",
            range: {
                "min": 0,
                "max": 100,
            },
        }

        function zoomSliderCallback(value, reHandle = true) {
            publicai.selected.settings.settings.player.volume = value / 100
            if (reHandle) {
                _create.forceReHandle(publicai.selected.settings)
            }
            updateLabel(value)
        }

        function updateLabel(value) {
            el.label.textContent = value + "%"
            let valueWidth = value * 100 / settings.range.max
            //console.log("volume", valueWidth, value)
            el.progress.style.width = valueWidth + "%"
            el.cursor.style.left = valueWidth + "%"
        }


        css_utils.zoomSlider(settings);
        zoomSliderCallback(publicai.selected.settings.settings.player.volume * 100, false);

    },



    checkIfImportedFolderExists: function () {

        if (!publicai.library.folders["imported"]) {
            publicai.library.folders["imported"] = _defaults.newFolder()
            publicai.library.folders["imported"].name = "ImportedMedia"
            publicai.library.folders["imported"].folderName = "ImportedMedia"
            publicai.library.folders["imported"].ancestor = "root"
        }

    },

    randomiseDropdown: function (node, panelLocation) {


        let shuffleButton = $$$(panelLocation, "media_panel", "shuffle")
        let dropdown = $$$(panelLocation, "media_panel", "mode")

        shuffleButton.onclick = e => {
            _create.forceReHandle(publicai.selected.settings) //re-creates the element to take changes into account
        }

        let modes = [
            {
                "label": "Shuffle",
                "value": "shuffle",
                icon: "shuffle"
            },
            {
                "label": "Multiple",
                "value": "multiple",
                icon: "splitscreen_top"
            }
        ]

        if (!node.media.mode) {
            node.media.mode = "shuffle"
        }

        css_utils.setup_reg_dropdown({
            "selector": dropdown,
            "values": modes,
            "label": node.media.mode,
            "styles": ["auto", "horizontal", "light", "no_input"],
            "customMode": true,
            "save": false,
            "callback": changeMode,
            "direction": "down",
            "overlayColor": "rgb(107 95 88)",
            "overlayOpacity": 0.2,
            "width": 200
        })

        function changeMode(s) {
            console.log(s)
        }


    },


    preloadDropdown: function (node, panelLocation) {

        return

        let dropdown = $$$(panelLocation, "media_panel", "load")

        let modes = [
            {
                "label": "Preload 100%",
                "value": "100",
                icon: "shuffle"
            },
            {
                "label": "Preload 50%",
                "value": "50",
                icon: "splitscreen_top"
            }
        ]

        if (!node.media.preload) {
            node.media.preload = "100"
        }

        css_utils.setup_reg_dropdown({
            "selector": dropdown,
            "values": modes,
            "label": node.media.preload,
            "styles": ["auto", "horizontal", "light", "no_input"],
            "customMode": true,
            "save": false,
            "callback": changeMode,
            "direction": "down",
            "overlayColor": "rgb(107 95 88)",
            "overlayOpacity": 0.2,
            "width": 200
        })

        function changeMode(s) {
            console.log(s)
        }


    },



    createMediaElements: async function (settings) {

        //console.log("settings", settings)

        let media = settings.media
        let container = settings.container
        let styles = settings.style
        let line1 = $$$(document, "media_panel", "line1")
        let line2 = $$$(document, "media_panel", "line2")


        //removeAllChildNodes(container)

        //let m = addWhatWhere(components.ui.media_widget, container)
        //console.log("have added container", m)

        //let elementsContainer = $$$(m, "media_preview", "elements")

        removeAllChildNodes(container)

        for (let item of Object.keys(media)) {

            let mediaItem = publicai.library.files[item]

            //components.ui.media_entry_el
            let m = addWhatWhere(components.ui.media_entry_el_new, container)

            let controls = $$$$(m, "medi_el_nav")

            let imgContainer = controls.media_container

            render.addClassesToAllChildrenOf(m, "is_active", false)

            controls.controls.onclick = e => {
                _create.forceReHandle(publicai.selected.settings) //re-creates the element to take changes into account
                e.stopPropagation()
                e.preventDefault()

            }


            controls.del.onclick = e => {

                if (Object.keys(media).length > 1) {
                    delete media[item]
                    css_media.createMediaElements({
                        media: media,
                        container: container,
                        style: styles
                    })
                } else {
                    notifications.show([
                        ["Oops.", "You need at least one media element."]
                    ])
                }
                e.stopPropagation()
                e.preventDefault()
            }

            removeAllChildNodes(imgContainer)



            let mediaEl = media_cache.addMediaElementTo(mediaItem, imgContainer, null, true);

            if (mediaItem.info && mediaItem.info.aspectRatio) {

                imgContainer.style.height = m.offsetWidth / mediaItem.info.aspectRatio + "px"
                m.style.height = Math.max(m.offsetWidth / mediaItem.info.aspectRatio, 100) + "px"

                line1.textContent = "Size: " + mediaItem.info.width + " x " + mediaItem.info.height + "px" + " | " + "Ratio: " + (mediaItem.info.aspectRatio + "").substr(0, 4)
                line2.textContent = "Open file in new window."
                line2.onclick = e => {
                    window.open(mediaItem.medium, '_blank');
                }
            }

            //console.log("lading ", mediaItem)
            //console.log("cached ", mediaEl)


            m.onmouseover = e => {
                render.addClassesToAllChildrenOf(m, "is_active", true)
                if (mediaItem?.mediaType?.toLowerCase() == "video") {
                    let video = imgContainer.firstChild
                    video.play()
                }
            }

            m.onmouseout = e => {
                render.addClassesToAllChildrenOf(m, "is_active", false)

                if (mediaItem?.mediaType?.toLowerCase() == "video") {
                    let video = imgContainer.firstChild
                    video.pause()
                }
            }


            if (styles) {
                for (let style of styles) {
                    render.addClassesToAllChildrenOf(m, style, true)
                }
            }
        }
    },
}