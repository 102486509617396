export var css_carousel = {

    //this is the carousel settings panel

    init: function () {

        let container = $$$(document, "data-group", "carousel")

        if (publicai.selected.settings.type != "carousel") {
            container.classList.add("hidden")
            return
        }

        css_carousel.settings = publicai.selected.settings.settings.carousel

        if (!css_carousel.ui) {
            css_carousel.ui = {
                "panel": container,
                "switch": $$$(container, "carousel-combo", "switch"),
                "on": $$$($$$(container, "carousel-combo", "switch"), "data-value", "on"),
                "off": $$$($$$(container, "carousel-combo", "switch"), "data-value", "off"),


                "group": $$$(container, "carousel-combo", "group"),
                "effects": $$$(container, "data-dropdown", "effect"),


                "autoplay": {
                    "container": $$$(container, "carousel-combo", "auto-play"),
                    "on": $$$($$$(container, "carousel-combo", "auto-play"), "data-value", "on"),
                    "off": $$$($$$(container, "carousel-combo", "auto-play"), "data-value", "off"),
                },

                "shuffle": {
                    "container": $$$(container, "carousel-combo", "shuffle"),
                    "on": $$$($$$(container, "carousel-combo", "shuffle"), "data-value", "on"),
                    "off": $$$($$$(container, "carousel-combo", "shuffle"), "data-value", "off"),
                },

                "loop": {
                    "container": $$$(container, "carousel-combo", "loop"),
                    "on": $$$($$$(container, "carousel-combo", "loop"), "data-value", "on"),
                    "off": $$$($$$(container, "carousel-combo", "loop"), "data-value", "off"),
                },

                "direction": {
                    "container": $$$(container, "carousel-combo", "direction"),
                    "on": $$$($$$(container, "carousel-combo", "direction"), "data-value", "horizontal"),
                    "off": $$$($$$(container, "carousel-combo", "direction"), "data-value", "vertical"),
                },



                "slides": $$$(container, "carousel-slides", "input"),
                "space": $$$(container, "carousel-space", "input"),

                "navigation": $$$(container, "data-carousel", "navigation"),

                "controls": {
                    "first": $$$(container, "carousel_nav", "first"),
                    "last": $$$(container, "carousel_nav", "last"),
                    "prev": $$$(container, "carousel_nav", "prev"),
                    "next": $$$(container, "carousel_nav", "next"),
                    "jump": $$$(container, "data-dropdown", "jump"),
                }


            }
        }
        this.setupControls()
        this.setupEffects()
        this.setupOnOffSwitch()

        this.shuffle()
        this.autoPlay()
        this.loop()
        this.direction()


        this.spaceBetween()
        this.slidesPerView()



    },

    setupControls: function () {


        let controls = css_carousel.ui.controls

        controls.first.onclick = function () {
            publicai.carousels[publicai.selected.settings.id].swiper.slideTo(0)
        }

        controls.last.onclick = function () {
            publicai.carousels[publicai.selected.settings.id].swiper.slideTo(publicai.carousels[publicai.selected.settings.id].swiper.slides.length - 1)
        }

        controls.prev.onclick = function () {
            publicai.carousels[publicai.selected.settings.id].swiper.slidePrev()
        }

        controls.next.onclick = function () {
            publicai.carousels[publicai.selected.settings.id].swiper.slideNext()
        }



        let slidesList = []
/* 
        css_utils.setup_reg_dropdown({
            "selector": css_carousel.ui.controls.jump,
            "value": css_carousel.ui.controls.jump,
            "multiSelect": false,
            "search": false,
            "values": slidesList,
            "label": "Jump",
            "styles": ["auto", "horizontal", "light", "no_input", "lighter"],
            "customMode": true,
            "save": false,
            "callback": jump,
            "direction": "down",
            "overlayColor": "black",
            "overlayOpacity": 0.02,
            "width": "100%"
        }) */

        function jump(settings) {
            //console.log(settings)
            css_carousel.updateSwiper()
        }

    },

    activateInput: function (input, variable) {

        input.value = css_carousel.settings[variable]
        input.onchange = function () {
            css_carousel.settings[variable] = input.value
            css_carousel.updateSwiper()
        }
        input.onblur = function () {
            css_carousel.updateSwiper()
        }

    },



    spaceBetween: function () {

        this.activateInput(css_carousel.ui.space, "spaceBetween")

    },

    slidesPerView: function () {

        this.activateInput(css_carousel.ui.slides, "slidesPerView")

    },




    updateSwiper: function () {
        if (publicai.carousels[publicai.selected.settings.id]) {
            publicai.carousels[publicai.selected.settings.id].swiper.update()
        }
    },



    updateOnOffButtons: function (item, value) {

        //console.log("button ", item, css_carousel.settings[item])

        if (value == 1) {
            css_carousel.ui[item].on.classList.add("active")
            css_carousel.ui[item].off.classList.remove("active")
        } else {
            css_carousel.ui[item].on.classList.remove("active")
            css_carousel.ui[item].off.classList.add("active")
        }

        this.updateSwiper()

    },

    direction: function () {

        let item = "direction"

        let switchValue = 1
        if (css_carousel.settings.direction == "vertical") {
            switchValue = 2
        }

        css_carousel.updateOnOffButtons(item, switchValue)
        this.ui[item].on.onclick = function () {
            css_carousel.settings.direction = "horizontal"
            css_carousel.updateOnOffButtons(item, 1)

        }
        this.ui[item].off.onclick = function () {
            css_carousel.settings.direction = "vertical"
            css_carousel.updateOnOffButtons(item, 2)
        }
    },


    shuffle: function () {

        let switchValue = 1
        if (!css_carousel.settings.shuffle) {
            switchValue = 2
        }

        let item = "shuffle"
        css_carousel.updateOnOffButtons(item, switchValue)
        this.ui[item].on.onclick = function () {
            css_carousel.settings.shuffle = true
            css_carousel.updateOnOffButtons(item, 1)
        }
        this.ui[item].off.onclick = function () {
            css_carousel.settings.shuffle = false
            css_carousel.updateOnOffButtons(item, 2)
        }
    },

    autoPlay: function () {

        let item = "autoplay"

        let switchValue = 1
        if (!css_carousel.settings[item]) {
            switchValue = 2
        }


        css_carousel.updateOnOffButtons(item, switchValue)
        this.ui[item].on.onclick = function () {
            css_carousel.settings[item] = true
            css_carousel.updateOnOffButtons(item, 1)

        }
        this.ui[item].off.onclick = function () {
            css_carousel.settings[item] = false
            css_carousel.updateOnOffButtons(item, 2)
        }
    },

    loop: function () {

        let item = "loop"

        let switchValue = 1
        if (!css_carousel.settings[item]) {
            switchValue = 2
        }

        css_carousel.updateOnOffButtons(item, switchValue)
        this.ui[item].on.onclick = function () {
            css_carousel.settings[item] = true
            css_carousel.updateOnOffButtons(item, 1)

        }
        this.ui[item].off.onclick = function () {
            css_carousel.settings[item] = false
            css_carousel.updateOnOffButtons(item, 2)
        }
    },




    swithcOnCarouselSettings: function (switchOn) {
        if (switchOn) {
            publicai.carousels[publicai.selected.settings.id] = {
                swiper: widget_carousel.init(publicai.selected.settings),
                settings: {
                    "active": true,
                    "helper": ""
                }
            }

            css_carousel.refreshStatusButtons()

        } else {
            //css_carousel.ui.group.classList.remove("active")
            if (publicai.carousels[publicai.selected.settings.id]) {
                publicai.carousels[publicai.selected.settings.id].swiper.destroy(true, true)
                play_interactive.forceRecreateSelected(false, true)
                publicai.carousels[publicai.selected.settings.id].settings.active = false

            }
            css_carousel.refreshStatusButtons()
        }
    },

    refreshStatusButtons: function () {

        //updates the on off buttons and hides / shows the controls group

        if (publicai.carousels[publicai.selected.settings.id]?.settings?.active) {
            css_carousel.ui.navigation.classList.remove("hidden")
            css_carousel.ui.on.classList.add("active")
            css_carousel.ui.off.classList.remove("active")
        } else {
            css_carousel.ui.navigation.classList.add("hidden")
            css_carousel.ui.on.classList.remove("active")
            css_carousel.ui.off.classList.add("active")
        }
    },

    setupOnOffSwitch: function () {

        //public.carousels[publicai.selected.settings.id] = null

        css_carousel.refreshStatusButtons()

        css_carousel.ui.off.onclick = function () {
            css_carousel.swithcOnCarouselSettings(false)
        }

        css_carousel.ui.on.onclick = function () {
            css_carousel.swithcOnCarouselSettings(true)
        }

    },

    setupEffects: function () {



        let effectsList = [
            {
                "label": "slide",
                "value": "slide",
                "icon": "linear_scale"
            },
            {
                "label": "fade",
                "value": "fade",
                "icon": "transition_fade"
            },
            {
                "label": "cube",
                "value": "cube",
                "icon": "deployed_code"
            },
            {
                "label": "coverflow",
                "value": "coverflow",
                "icon": "switch_access_shortcut"
            },
            {
                "label": "flip",
                "value": "flip",
                "icon": "auto_stories"
            },
        ]



        function effect(settings) {
            css_carousel.settings.effect = settings.value
            css_carousel.updateSwiper()
        }

        css_utils.setup_reg_dropdown({
            "selector": css_carousel.ui.effects,
            "value": css_carousel.settings.effect,
            "multiSelect": false,
            "search": false,
            "values": effectsList,
            "label": "Add new element",
            "styles": ["auto"],
            "customMode": true,
            "save": false,
            "callback": effect,
            "direction": "down",
            "overlayColor": "black",
            "overlayOpacity": 0.12,
            "width": "100%"
        })

    },


}