export var css_transitions = {

    init: function () {

        if (!this.ui) {
            let container = $$$(document, "data-group", "transitions")
            this.ui = {
                "panel": container,
                "allDataInputGroups": $$$(container, "data-input-group"),
                "transition_type_dropdown": $$$(container, "data-dropdown", "transition-type"),
                "extras_container": $$$(container, "data-transitions", "container"),
                "var": "custom_transitions"
            }
        }



        let transformProperties = []
        for (let item of Object.keys(css_transitions.transitionProperties)) {
            for (let property of css_transitions.transitionProperties[item]) {
                transformProperties.push({
                    label: property.label,
                    value: property.property,
                    comment: property.comment
                })
            }
        }

        /*  css_utils.dropdown({
             "selector": css_transitions.ui.transition_type_dropdown,
             "values": transformProperties,
             "label": "Add",
             "style": null,
             "save": false,
             "callback": createTransitionProperty,
             "direction": "up",
 
         }) */


        css_utils.setup_reg_dropdown({
            "selector": css_transitions.ui.transition_type_dropdown,
            "value": "Select",
            "multiSelect": false,
            "search": false,
            "values": transformProperties,
            "label": "In",
            "styles": ["auto", "horizontal", "bold", "no_input"],
            "customMode": true,
            "save": false,
            "callback": createTransitionProperty,
            "direction": "up",
            "overlayColor": "black",
            "overlayOpacity": 0.12,
            "width": "100%"
        })



        function createTransitionProperty(entry) {
            console.log(entry)

            let newTransition = {
                "transition-property": entry.value,
                "transition-duration": "200ms",
                "transition-timing-function": "ease",
            }

            //let id = generateSceneID()



            if (!publicai.active.lastStyleInStack.css.custom_transitions) {
                publicai.active.lastStyleInStack.css.custom_transitions = []
            }

            publicai.active.lastStyleInStack.css.custom_transitions.push(newTransition)



            //public.active.lastStyleInStack.css.custom_transitions.push(id)
            //console.log(entry)
            css_transitions.createTransitionsList()
        }

        css_transitions.createTransitionsList()


    },

    checkValuesFromAncestors: function (settings) {

        //generic function that checks ancestors. Should use consistently across all components.

        let waterFallClassFound = false
        let acestorClassFound = false
        let inheritValuesFromAncestors = false
        let waterfalledValues = []

        let existingCssValue = null
        if (css_utils.inheritance[settings.variable]) {
            inheritValuesFromAncestors = true
        }

        if (publicai.active.lastStyleInStack.css[settings.variable]) {
            existingCssValue = publicai.active.lastStyleInStack.css[settings.variable];
        }

        for (let css of publicai.active.cssStack) {
            if (css.css[settings.variable]) {
                if (publicai.active.lastStyleInStack.name != css.name) {
                    //do not add the current one
                    waterfalledValues.push(css.css[settings.variable])
                    waterFallClassFound = true
                }
            }
        }


        let response = {
            "waterFallClassFound": waterFallClassFound,
            "acestorClassFound": acestorClassFound,
            "inheritValuesFromAncestors": inheritValuesFromAncestors,
            "existingCssValue": existingCssValue,
            "waterfalledValues": waterfalledValues
        }

        return (response)
    },



    createTransitionsList: function () {

        let container = css_transitions.ui.extras_container
        let transitionData = []
        let ancestorsData = null


        ancestorsData = this.checkValuesFromAncestors({
            "variable": "custom_transitions"
        })

        if (ancestorsData.existingCssValue) {
            for (let item of ancestorsData.existingCssValue) {
                transitionData.push({
                    data: item,
                    inherited: false
                })
            }
        }

        if (ancestorsData.waterfalledValues) {
            for (let item of ancestorsData.waterfalledValues) {
                for (let transition of item) {
                    transitionData.push({
                        data: transition,
                        inherited: true
                    })
                }
            }
        }


        if (transitionData.length > 0) {

            container.classList.add("active")
            removeAllChildNodes(css_transitions.ui.extras_container)

            for (let t of transitionData) {

                let transition = t.data


                let item = addWhatWhere(components.ui.additional_element_item, container)
                let label = $$$(item, "data-extras", "label")
                let hide = $$$(item, "data-extras", "hide")
                label.textContent = capitaliseFirstLetter(transition["transition-property"].substr(0, 20)) + " - " + transition["transition-duration"]

                let fieldElement = item.querySelector(".field_value")
                if (t.inherited) {
                    fieldElement.classList.add("hasinheritedvalue")
                } else {
                    fieldElement.classList.add("hasvalue")
                }

                let deleteButton = $$$(item, "data-extras", "delete")
                let popup = $$$(item, "data-extras", "panel")

                deleteButton.onclick = function (e) {
                    //remove the transition from list

                    let index = publicai.active.lastStyleInStack.css.custom_transitions.indexOf(transition)
                    publicai.active.lastStyleInStack.css.custom_transitions.splice(index, 1)
                    css_transitions.createTransitionsList()
                    e.preventDefault()
                    e.stopPropagation()
                }

                item.onclick = function (e) {

                    let targetCoords = item.getBoundingClientRect()

                    let settingsEl = css_utils.configurationPanel({
                        comp: components.ui.popup_content_transitions,
                        x: targetCoords.left,
                        y: targetCoords.top - 100,
                        width: 240,
                        overlayOpacity: 0.01,
                        callback: updateTransition
                    })

                    function updateTransition(settings) {
                        console.log("closed transition settings", settings)
                    }

                    //configure transitions popup

                    let popupLabel = $$$(settingsEl, "thegroup", "header")
                    let timingInput = $$$(settingsEl, "data-input", "input")
                    let easingDropdown = $$$(settingsEl, "data-dropdown", "easing")

                    popupLabel.textContent = capitaliseFirstLetter(transition["transition-property"])


                    timingInput.value = transition["transition-duration"].replace("ms", "")
                    timingInput.oninput = function () {
                        transition["transition-duration"] = this.value + "ms"
                    }


                    css_utils.dropdown({
                        "selector": easingDropdown,
                        "values": css_transitions.easingSettings,
                        "value": transition["transition-timing-function"] || "ease",
                        "label": "Easing",
                        "style": null,
                        "save": false,
                        "callback": saveEasing,
                        "direction": "up",

                    })

                    let dropdownLabel = easingDropdown.querySelector(".switch_label")
                    dropdownLabel.textContent = capitaliseFirstLetter(transition["transition-timing-function"].substr(0, 20) || "ease")

                    easingDropdown.onclick = function (e) {
                        e.stopPropagation()
                        e.preventDefault()
                    }

                    function saveEasing(easingSettings) {
                        console.log("saving easing")
                        transition["transition-timing-function"] = easingSettings.value
                        dropdownLabel.textContent = easingSettings.value
                        //hidePopup()

                    }


                    e.preventDefault()
                    e.stopPropagation()
                }


            }

        } else {
            container.classList.remove("active")
        }



    },

    transitionProperties: {
        "common": [
            { "label": "All Properties", "property": "All" },
            { "label": "Opacity", "property": "opacity" },
            { "label": "Margin", "property": "margin" },
            { "label": "Padding", "property": "padding" },
            { "label": "Border", "property": "border" },
            { "label": "Transform", "property": "transform" },
            { "label": "Filter", "property": "filter" },
            { "label": "Flex", "property": "flex" }
        ],
        "background": [
            { "label": "Background Color", "property": "background-color" },
            { "label": "Background Position", "property": "background-position" },
            { "label": "Text Shadow", "property": "text-shadow" },
            { "label": "Box Shadow", "property": "box-shadow" }
        ],
        "size": [
            { "label": "Width", "property": "width" },
            { "label": "Height", "property": "height" },
            { "label": "Max Height", "property": "max-height" },
            { "label": "Max Width", "property": "max-width" },
            { "label": "Min Height", "property": "min-height" },
            { "label": "Min Width", "property": "min-width" }
        ],
        "borders": [
            { "label": "Border Radius", "property": "border-radius" },
            { "label": "Border Color", "property": "border-color" },
            { "label": "Border Width", "property": "border-width" }
        ],
        "typography": [
            { "label": "Font Color", "property": "color" },
            { "label": "Font Size", "property": "font-size" },
            { "label": "Line Height", "property": "line-height" },
            { "label": "Letter Spacing", "property": "letter-spacing" },
            { "label": "Text Indent", "property": "text-indent" },
            { "label": "Word Spacing", "property": "word-spacing" },
            { "label": "Font Variations", "property": "font-variation-settings" },
            { "label": "Text Stroke Color", "property": "text-stroke-color" }
        ],
        "position": [
            { "label": "Top", "property": "top" },
            { "label": "Left", "property": "left" },
            { "label": "Bottom", "property": "bottom" },
            { "label": "Right", "property": "right" },
            { "label": "Z-Index", "property": "z-index" }
        ],
        "margin": [
            { "label": "Margin Bottom", "property": "margin-bottom" },
            { "label": "Margin Left", "property": "margin-left" },
            { "label": "Margin Right", "property": "margin-right" },
            { "label": "Margin Top", "property": "margin-top" }
        ],
        "padding": [
            { "label": "Padding Bottom", "property": "padding-bottom" },
            { "label": "Padding Left", "property": "padding-left" },
            { "label": "Padding Right", "property": "padding-right" },
            { "label": "Padding Top", "property": "padding-top" }
        ],
        "flex": [
            { "label": "Flex Grow", "property": "flex-grow" },
            { "label": "Flex Shrink", "property": "flex-shrink" },
            { "label": "Flex Basis", "property": "flex-basis" }
        ],
        "Advanced": []
    },

    easingSettings: [

        {
            "label": "Linear",
            "value": "linear",
            "comment": "Linear across the timeline"
        },
        {
            "label": "Ease Out",
            "value": "ease-out",
            "comment": "Slow start, fast finish"
        },
        {
            "label": "Ease In",
            "value": "ease-in",
            "comment": "Fast start, slow finish"
        },
        {
            "label": "Ease In Out",
            "value": "ease-in-out",
            "comment": "Slow start, slow finish"
        },
        {
            "label": "Linear",
            "value": "linear",
            "comment": "Constant speed"
        },
        {
            "label": "Ease",
            "value": "ease",
            "comment": "Default easing"
        },
        {
            "label": "Step Start",
            "value": "step-start",
            "comment": "Immediate change at the start"
        },
        {
            "label": "Step End",
            "value": "step-end",
            "comment": "Immediate change at the end"
        },
        {
            "label": "Swift Move Out",
            "value": "cubic-bezier(.47, 0, .745, .715)",
            "comment": "Starts moderately, accelerates mid-way, and ends smoothly"
        },
        {
            "label": "Gentle Ease",
            "value": "cubic-bezier(.25, .8, .25, 1)",
            "comment": "Gentle acceleration at the beginning and end"
        },
        {
            "label": "Sudden Spring",
            "value": "cubic-bezier(.17, .67, .83, .67)",
            "comment": "Quick start, slight bounce mid-way, and swift conclusion"
        },
        {
            "label": "Bouncy Entrance",
            "value": "cubic-bezier(.68, -0.55, .27, 1.55)",
            "comment": "Dips below start then overshoots before settling"
        },
        {
            "label": "Heavy Drop",
            "value": "cubic-bezier(.9, .03, .69, .22)",
            "comment": "Fast drop with a slight rebound at the end"
        },
        {
            "label": "Lazy Lounge",
            "value": "cubic-bezier(.15, .85, .85, 1.2)",
            "comment": "Slow start, overshoots mid-way, then eases into place"
        },
        {
            "label": "Dynamic Drive",
            "value": "cubic-bezier(.4, 0, .2, 1)",
            "comment": "Smooth start, faster movement in the middle, then settling softly"
        }
    ]
}
