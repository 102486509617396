
import { css_input } from './css_input.js';

export var css_typography = {

    init: function () {

        if (!this.ui) {

            let container = $$$(document, "data-group", "typography")
            this.ui = {
                "panel": container,
                "align": $$$(document, "layout-combo", "text-align"),
                "text_transform": $$$(document, "layout-combo", "text-transform"),
                "text_decoration": $$$(document, "layout-combo", "text-decoration"),

                "white_space": $$$(document, "data-dropdown", "white-space"),


                "font_family": $$$(document, "data-dropdown", "font-family"),
                "font_weight": $$$(document, "data-dropdown", "font-weight"),

                "font_size": $$$(container, "data-input-group", "font-size"),
                "line_height": $$$(container, "data-input-group", "line-height"),
                "letter_spacing": $$$(container, "data-input-group", "letter-spacing"),
                "text_indent": $$$(container, "data-input-group", "text-indent"),


                "text_color": $$$(container, "combo-background", "color"),
                "color_picker": $$$(container, "data-input", "color"),


                "direction": $$$(document, "layout-combo", "flex-direction"),
                "justify": $$$(document, "layout-combo", "justify-content"),
                "element": $$$(container, "color_pickers", "typography"),


            }
        }


        css_typography.ui.fontFamilyLabel = this.ui.font_family.querySelector(".switch_label")


        

        let colorPickerSettings = {
            "element": css_typography.ui.element,
            "selector": css_typography.ui.color_picker,
            "default": css_utils.checkValuesFromAncestors({ "variable": "color" })?.existingCssValue,
            "targetObject": publicai.active.lastStyleInStack.css,
            "label": "",
            "targetVariable": "color",
            "targetDocumentObject": publicai.selected.element,
            "showColorCode": false,
            "callback": refreshColor,
            "name": generateSceneID(),
            //"cover": css_borders.ui.color_cover_element, //element on top
        }
        function refreshColor() {

        }



        css_utils.input({
            "selector": css_typography.ui.text_color,
            "variable": "color",
            "color": colorPickerSettings,
            "units": false,
            "validation": "color",
        })


        //inputs

        let ranges = {
            "px": {
                "min": 0,
                "max": 100,
            },
            "-": {
                "min": 0,
                "max": 100,
            },
            "em": {
                "min": 0,
                "max": 10,
            },
            "%": {
                "min": 0,
                "max": 100,
            },
            "vw": {
                "min": 0,
                "max": 100,
            },
            "vh": {
                "min": 0,
                "max": 100,
            },

        }


        css_input.input({
            "selector": css_typography.ui.font_size,
            "variable": css_typography.ui.font_size.getAttribute("data-input-group"),
            "validation": "none",
            "number":  true, 
        })


        css_input.input({
            "selector": css_typography.ui.line_height,
            "variable": css_typography.ui.line_height.getAttribute("data-input-group"),
            "validation": "none",
            "number":  true, 
        })

        css_input.input({
            "selector": css_typography.ui.letter_spacing,
            "variable": css_typography.ui.letter_spacing.getAttribute("data-input-group"),
            "validation": "none",
            "number": true, 
        })


        css_input.input({
            "selector": css_typography.ui.text_indent,
            "variable": css_typography.ui.text_indent.getAttribute("data-input-group"),
            "validation": "none",
            "number": true, 
        })


   /*      css_utils.input({
            "selector": css_typography.ui.font_size,
            "variable": css_typography.ui.font_size.getAttribute("data-input-group"),
            "slider": false,
            "ranges": ranges,
            "validation": "none",
            "number": true, 
        }) */
      /*   css_utils.input({
            "selector": css_typography.ui.line_height,
            "variable": css_typography.ui.line_height.getAttribute("data-input-group"),
            "slider": false,
            "ranges": ranges,
            "validation": "none",
            "number": true, 
        }) */


    /*     css_utils.input({
            "selector": css_typography.ui.letter_spacing,
            "variable": css_typography.ui.letter_spacing.getAttribute("data-input-group"),
            "validation": "none",
            "number": true, 
        }) */
      


        css_utils.combo({
            "selector": css_typography.ui.align,
            "save": true,
        })

        css_utils.combo({
            "selector": css_typography.ui.text_transform,
            "save": true,
        })


        css_utils.combo({
            "selector": css_typography.ui.text_decoration,
            "save": true,
        })


        let white_space_values = [

            {
                label: "No Wrap",
                value: "nowrap",
                comment: "Text will never wrap to the next line. The text continues on the same line until a <br> tag is encountered."
            },
            {
                label: "Normal",
                value: "normal",
                comment: "Breaks lines to avoid overflow."
            },
            {
                label: "Pre",
                value: "pre",
                comment: "Whitespace is preserved by the browser. Text will only wrap on line breaks. Acts like the <pre> tag in HTML."
            },
            {
                label: "Pre Line",
                value: "pre-line",
                comment: "Whitespace is collapsed by the browser. Text will wrap when necessary, and on line breaks."
            },
            {
                label: "Pre Wrap",
                value: "pre-wrap",
                comment: "Whitespace is preserved by the browser. Text will wrap when necessary, and on line breaks."
            },
            {
                label: "Initial",
                value: "initial",
                comment: "Sets this property to its default value."
            },
            {
                label: "Inherit",
                value: "inherit",
                comment: "Inherits this property from its parent element."
            },



        ]

        css_utils.dropdown({
            "selector": css_typography.ui.white_space,
            "values": white_space_values,
            "label": "Breaking",
            "style": null,
            "callback": whiteSpace,
            "direction": "center",
            "ui": css_typography.ui
        })

        function whiteSpace(position) {

            showOrHidePositionPresets()

        }

        this.fontWeight()


        function checkFontsAndSetFamily() {
            const checkInterval = 100; // milliseconds to wait between checks

            function waitForFonts() {
                if (publicai.fonts.platform == null || publicai.fonts.platform.length == 0) {
                    // Fonts not loaded, wait a bit and check again
                    setTimeout(waitForFonts, checkInterval);
                } else {
                    // Fonts are loaded, set the font family
                    css_typography.fontFamily();
                }
            }

            waitForFonts();
        }

        checkFontsAndSetFamily()




    },

    fontWeight: function () {


        const fontWeightOptions = [
            { "value": 100, "label": "100 Thin" },
            { "value": 200, "label": "200 Extra Light" },
            { "value": 300, "label": "300 Light" },
            { "value": 400, "label": "400 Normal" },
            { "value": 500, "label": "500 Medium" },
            { "value": 600, "label": "600 Semi Bold" },
            { "value": 700, "label": "700 Bold" },
            { "value": 800, "label": "800 Extra Bold" },
            { "value": 900, "label": "900 Black" }
        ];


        let values = []
        for (let item of fontWeightOptions) {
            values.push({
                label: item.label,
                value: item.value,
            })
        }

        css_utils.dropdown({
            "selector": css_typography.ui.font_weight,
            "values": values,
            "label": "Weight",
            "style": null,
            "direction": "center",
            "ui": css_typography.ui

        })



    },


    fontFamily: function () {
        let foundFonts = [];




        ancestorsData = css_utils.checkValuesFromAncestors({
            "variable": "font-family"
        })

        let familyDropdown = css_typography.ui.font_family

        render.addClassesToAllChildrenOf(familyDropdown, "hasinheritedvalue", ancestorsData.acestorClassFound)

        //css_typography.ui.fontFamilyLabel.textContent = publicai.active.lastStyleInStack.css["font-family"]
        css_typography.ui.fontFamilyLabel.textContent = ancestorsData.existingCssValue



        //adding inheritance classes
        if (publicai.active.lastStyleInStack.css["font-family"]) {
            render.addClassesToAllChildrenOf(familyDropdown, "hasinheritedvalue", false)
            render.addClassesToAllChildrenOf(familyDropdown, "hasvalue", true)
        } else {
            render.addClassesToAllChildrenOf(familyDropdown, "hasvalue", false)
            if (ancestorsData.waterFallClassFound || ancestorsData.acestorClassFound) {
                render.addClassesToAllChildrenOf(familyDropdown, "hasinheritedvalue", true)
            } else {
                render.addClassesToAllChildrenOf(familyDropdown, "hasinheritedvalue", false)
            }
        }



        let labelContainer = familyDropdown.querySelector(".reg_label")
        labelContainer.onclick = (e) => {
            e.stopPropagation()
            e.preventDefault()
            document.addEventListener('click', css_utils.closeExtraPanels);
            css_utils.openAncestoryPanel(labelContainer, "font-family", null)
        }

        //console.log("ancestorsData", ancestorsData)


        for (let item of publicai.fonts.user) {
            foundFonts.push({
                label: item.fileName,
                value: item.fileName,
                id: item.id,
            })
        }

        for (let item of publicai.fonts.platform) {
            foundFonts.push({
                label: item.fileName,
                value: item.fileName,
                id: item.id,

            })

        }


      /*   for (let item of publicai.fonts.user) {
            console.log(item.fileName)
        } */

        let targetCoords = css_typography.ui.font_family.getBoundingClientRect()

        let fontsPanel
        css_typography.ui.font_family.querySelector(".dropdown_main").onclick = event => {

            event.stopPropagation()
            event.preventDefault()

            fontsPanel = css_utils.configurationPanel({
                comp: components.ui.floating_fonts,
                followExactPosition: true,
                x: targetCoords.left + 20,
                y: 160,
                width: 240,
                overlayOpacity: 0.01,
                callback: updateFont
            })

            console.log("open fonts", fontsPanel)

            createFontsList()

            let searchField = $$$(document, "font-lookup", "search")

            setTimeout(() => {
                searchField.focus()
            }, 100)


            searchField.oninput = event => {
                event.stopPropagation()
                event.preventDefault()
                performSearch(searchField.value)
            }


        }
        function updateFont() { }


        function createFontsList(searchValues) {
            let fontsContainer = document.querySelector(".fonts_container_panel")
            fontsContainer.style.maxHeight = 400 + "px"
            let searchPanel = $$$(fontsPanel, "font-lookup", "search")

            let fontsToList = foundFonts
            if (searchValues) {
                fontsToList = searchValues
            }

            removeAllChildNodes(fontsContainer)
            fontsToList.sort((a, b) => (a.label > b.label) ? 1 : -1)
            for (let item of fontsToList) {
                addFont(item, fontsContainer)
            }
        }

        //suggest something

        function addFont(item, location) {
            let fontLine = addWhatWhere(components.ui.multi_element_entry, location)


            render.addClassesToAllChildrenOf(fontLine, "active", false)
            observer.observe(fontLine);
            fontLine.setAttribute("data-font", item.id)

            let labelLine = $$$(fontLine, "fontline", "label")
            labelLine.textContent = item.label

            loadFont(item.id)

            if (!item.label.toLowerCase().includes("material")) {
                fontLine.style.fontFamily = item.label
            }

            fontLine.onclick = () => {
                //css["font-family"] = item.fileName
                //a_controls.closeFloatingPanels()
                updateValues(item.label)
                deseletAllFonts()
                render.addClassesToAllChildrenOf(fontLine, "active", true)
            }
        }


        function deseletAllFonts() {
            let allFonts = document.querySelectorAll(".multi_element_entry")
            for (let item of allFonts) {
                render.addClassesToAllChildrenOf(item, "active", false)
            }
        }


        function removeAllObservers(el) {
            //adding observers so that if fonts in view are not loaded, they get loaded right away
            for (let item of fontsContainer.children) {
                observer.unobserve(item);
            }

        }


        //setting up search 


        function performSearch(value) {
            let foundFonts = [];
            let searchValue = value.toLowerCase()

            for (let item of publicai.fonts.user) {
                if (item.fileName.toLowerCase().includes(searchValue.toLowerCase())) {

                    foundFonts.push({
                        label: item.fileName,
                        value: item.fileName,
                    })
                }
            }

            for (let item of publicai.fonts.platform) {
                if (item.fileName.toLowerCase().includes(searchValue.toLowerCase())) {
                    foundFonts.push({
                        label: item.fileName,
                        value: item.fileName,
                    })
                }
            }

            //public.fonts.allPlatform.filter(el => el.node.fileName.includes("franklin"))

            //console.log("foundFonts", foundFonts)

            if (foundFonts.length > 0) {
                createFontsList(foundFonts);

            } else {
                notifications.show([
                    ["Ooops.", "Can't find any fonts containing " + searchValue]
                ])
                createFontsList(settings.values);
            }


        };







        function updateValues(file, updateCSS = true) {
            publicai.active.lastStyleInStack.css["font-family"] = file
            applyCSS()
            return

            let value = css["font-family"] || css(publicai.active.body.classes, 0)["font-family"]

            if (css["font-family"]) {
                if (value != backupcss["font-family"]) {
                    el.button.classList.add("csshasvalue")
                } else {
                    el.button.classList.remove("csshasvalue")
                }
            } else {
                el.button.classList.remove("csshasvalue")
            }

            //el.button.textContent = "" + value
            //el.button.style.fontFamily = value
            if (updateCSS) {
                applyCSS()
            }
        }




        const observerCallback = (entries, observer) => {
            // Loop over the entries
            entries.forEach(entry => {
                // Check if the entry (our element `w`) is intersecting (visible)
                if (entry.isIntersecting) {
                    let id = entry.target.getAttribute("data-font")
                    if (public_system.fonts.loaded.has(id)) {
                        //console.log(`The font file ${id} is loaded.`);
                    } else {
                        //console.log(`The font file ${id} is not loaded.`);
                        loadFont(id)
                    }
                    // If you only want to check once when `w` first comes into view, uncomment the next line:
                    observer.unobserve(entry.target);
                }
            });
        };



        function loadFont(id) {

            if (public_system.fonts.loaded.has(id)) {
                return
            }

            let url = publicai.fonts.user.find(x => x.id === id)?.url

            //console.log("loading font", id, url)

            if (!url) {
                url = publicai.fonts.platform.find(x => x.id === id)?.url
            }
            if (url) {
                //adding it to both the document and the iframe so that fonts are loaded but also previews are available
                //consider only fetching the css once and applying to both
                play_interactive.addStylesheet(url, "custom", id)
                addStylesheet(url, "custom", id)
                public_system.fonts.loaded.add(id)
            } else {
                //console.log("couldn't find", id)
            }
        }

        // Create the observer with the callback and options
        const observer = new IntersectionObserver(observerCallback, {
            root: null, // observing with respect to the viewport
            threshold: 0.1 // callback will run when `w` is at least 10% visible
        });

        // Start observing the element `w`
        //updateValues(false) // do not apply css on open 

    },



}