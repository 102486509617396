// /web/modules/sections/sections.js
//import { handleLoginSuccess, getUserSessionFromCookies } from '../auth/sessuion.js';  // Import the session extraction logic

import * as agent from './agent.js';


const sections = {
    agent,
    // Add other sections as needed
};

export default sections;
