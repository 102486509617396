// /web/modules/sections/sections.js
//import { handleLoginSuccess, getUserSessionFromCookies } from '../auth/sessuion.js';  // Import the session extraction logic

import * as apps from './apps.js';
import * as editor from './editor.js';
import * as login from './login.js';
import * as content from './content.js';
import * as settings from './settings.js';
import * as logout from './logout.js';
import * as create from './create.js';

import * as workspace from './workspace.js';



const sections = {
    apps,
    editor,
    login,
    content,
    settings,
    logout,
    create, 
    workspace
};

export default sections;
