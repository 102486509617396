

import { init as store } from './apps/store.js';
import { init as myapps } from './apps/myapps.js';
import { init as new_app } from './apps/new_app.js';



export async function init(path) {

    console.log("settings", path)

    let modals = { new_app }

    let segments = path.split("/")


    if (segments[3] == "m") {
        console.log("modal", path)
        modals[segments[4]]()
        return
    }

    utils.addWhatWhere(nwsapp.comp.apps, app.ui.main.el, "apps", true)




    let callbacks = { store, myapps }


    let tabs = new utils.Tabs({
        identifiers: {
            tab: "tab",
            panel: "panel"
        },
        container: app.ui.apps.el,
        default: segments[2],
        callbacks: callbacks,
        root: "apps"
    })



}







