

export function updateFontsInUse() {

    if (nws.isPlayer) {
        return
    }
    //creates a list of all fonts used. TBD - remove fonts that are no longer referenced in publicai.css
    for (let item of Object.keys(publicai.css)) {
        let entry = publicai.css[item]
        if (entry["font-family"]) {
            //find the font first
            if (publicai.fonts.platform) {
                let theFont = publicai.fonts.platform.find(font => font.fileName === entry["font-family"])
                if (!theFont) {
                    theFont = publicai.fonts.user.find(font => font.fileName === entry["font-family"])
                }
                if (!theFont) {
                    theFont = nws.fontsWhitelist.find(font => font.fileName === entry["font-family"])
                }
                if (theFont) {
                    if (!publicai.fonts?.used[theFont.id]) {
                        //console.log("adding font...", publicai.fonts.used[theFont.id])
                        publicai.fonts.used[theFont.id] = theFont
                    }
                }
            }
        }
    }

    //when adding templated components, the fonts are added in the un use but not loaded
    play_interactive.checkIfFontsInUseAreLoaded()

}

export function checkIfFontsInUseAreLoaded() {

    for (let item of Object.keys(publicai.fonts.used)) {
        let font = publicai.fonts.used[item]
        //see if font.id is in publicai.fonts.loaded
        if (!public_system.fonts.loaded.has(font.id)) {
            //font not loaded. Load it
            play_interactive.addStylesheet(font.url, "custom", font.id) //one loads to iframe, other loads to document
            addStylesheet(font.url, "custom", font.id)
            public_system.fonts.loaded.add(font.id)
        }
    }
}

export async function addStylesheetWithFontDisplay(src, type, fontId, fontDisplay = 'swap') {

    //console.log("loading font", src)

    const response = await fetch(src);
    let cssText = await response.text();

    // Modify the CSS to include font-display in @font-face declarations
    cssText = cssText.replace(/@font-face {/g, `@font-face { font-display: ${fontDisplay};`);

    var head = nwsapp.activeDocument.getElementsByTagName('head')[0];
    var style = nwsapp.activeDocument.createElement('style');
    style.type = 'text/css';
    style.textContent = cssText;

    if (typeof type != "undefined") {
        style.setAttribute("mode", type);
    }
    if (typeof fontId != "undefined") {
        style.setAttribute("fontid", fontId);
    }

    head.appendChild(style);
}



export function addStylesheet(src, type, fontId) {
    var head = nwsapp.activeDocument.getElementsByTagName('head')[0];
    var link = nwsapp.activeDocument.createElement('link');
    //console.log("adding ", src)

    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = src
    link.media = 'all';
    if (typeof type != "undefined") {
        link.setAttribute("mode", type)
    }
    if (typeof fontId != "undefined") {
        link.setAttribute("fontid", fontId)
    }
    head.appendChild(link);
}


export function addFonts() {


    return new Promise(async (resolve) => {

        for (let item of Object.keys(publicai.fonts.used)) {
            let entry = publicai.fonts.used[item]
            if (entry) {
                if (entry.url) {
                    //console.log("adding used fonts", entry.url, "to", entry.id)
                    //console.log("loaded font", entry.url)
                    //console.log("adding fonts in use...", entry.url)
                    await play_interactive.addStylesheetWithFontDisplay(entry.url, "custom", entry.id);
                }
            }
        }


        /*             let helveticaDefaultFont = "https://cdn.thenewsroom.io/platform/story_media/1288817549/Neuehaasgrotdisp_web.css";
        
                    //consider loading those too
                    let circularFont = "https://cdn-cf1.nws.ai/platform/story_media/1288826107/CircularXXWeb.css"
                    let materialIcons = "https://cdn.thenewsroom.io/platform/story_media/1288826107/material_icons_woff_Odr2iEu.css" */


        for (let item of nws.systemFonts) {
            //addStylesheet(item);

            play_interactive.addStylesheet(item);
            //play_interactive.addStylesheetWithFontDisplay(item);

        }





        // Create an array to store the FontFaceObserver instances
        let fontObservers = [];

        // Create FontFaceObserver instances for each font family and push them to the array
        /*    if (bodyFont != "") {
               fontObservers.push(new FontFaceObserver(bodyFont));
           }
           if (headlineFont != "") {
               fontObservers.push(new FontFaceObserver(headlineFont));
           }
           if (ctaFont != "") {
               fontObservers.push(new FontFaceObserver(ctaFont));
           }
 
           // Use Promise.all to wait for all fonts to load before resolving
           Promise.all(fontObservers.map(observer => observer.load())).then(() => {
               resolve();
           });
*/
        resolve();
    });
}



// Make sure to call init to set everything up




export function addBodyScene() {

    //console.error("adding body scene")


    let entry = publicai.active.body
    return new Promise((resolve, reject) => {
        // Remove all child nodes from sceneContainer
        //removeAllChildNodes(publicai.sceneContainer);
        // Create an iframe with no border, and full width and height

        let existingBody = $$$(document, "publicai", "body")
        if (existingBody) {
            existingBody.remove()
        }


        let bodyContainer = document.createElement('div');

        publicai.iframeContainer = bodyContainer

        bodyContainer.classList.add("body" + generateShortId())
        bodyContainer.setAttribute("publicai", "body")
        bodyContainer.style.width = '100%';
        bodyContainer.style.height = '100%';
        bodyContainer.style.border = 0;
        bodyContainer.style.margin = 0;
        bodyContainer.style.padding = 0;


        publicai.sceneContainer.appendChild(bodyContainer);

        if (nws.isPlayer) {
            bodyContainer.style.overflow = "hidden";
        }

        publicai.active.bodyContainer = bodyContainer

        let iframe = document.createElement('iframe');

        publicai.active.iframe = iframe

        iframe.style.width = '100%';
        iframe.style.height = '100%';
        iframe.style.border = 0;
        iframe.style.margin = 0;
        iframe.style.padding = 0;
        iframe.style.overflow = "hidden";

        bodyContainer.appendChild(iframe);



        // Handle the iframe load event 
        publicai.active.iframe = iframe
        iframe.onload = () => {


            const handleLoad = async () => {


                try {
                    let iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                    nwsapp.activeDocument = window.document2 = iframeDoc;


                    if (nws.isPlayer) {
                        click_handlers.setEventsDispatcher(document2)
                    }

                    document2.addEventListener('selectionchange', function (e) {
                        //used to detect selection changes
                        const selection = iframe.contentWindow.getSelection();
                        //console.log(selection)
                        if (selection.rangeCount > 0) {
                            const selectedText = selection.toString().trim();
                            // Check if there is any selected text
                            if (selectedText !== "") {
                                publicai.selected.text = selectedText
                                publicai.savedSelectionRange = selection.getRangeAt(0).cloneRange();

                                const settings = { /* ... */ };
                                // Publish the event
                                PubSub.publish('selectionChange', {
                                    selection: selection,
                                    selectedText: selectedText,
                                    settings: settings,
                                    target: e.target
                                });
                            }
                        } else {
                            //public.savedSelectionRange = null;
                        }
                    });



                    document2.addEventListener('keydown', function (e) {
                        const selection = iframe.contentWindow.getSelection();

                        if (e.shiftKey && (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown')) {
                            // Capture the selection range when expanding with Shift + arrow keys
                            if (selection.rangeCount > 0) {
                                const selectedText = selection.toString().trim();

                                if (selectedText !== "") {
                                    publicai.selected.text = selectedText;
                                    publicai.savedSelectionRange = selection.getRangeAt(0).cloneRange();

                                    PubSub.publish('selectionChange', {
                                        selection: selection,
                                        selectedText: selectedText,
                                        target: e.target
                                    });
                                }
                            }
                        }
                    });



                    //add
                    nwsapp.activeDocument.addEventListener('keydown', _shortcuts.handleKeyboardShortcuts);
                    nwsapp.activeDocument.addEventListener('mousedown', _shortcuts.handleMouseDown);
                    nwsapp.activeDocument.addEventListener('mouseup', _shortcuts.handleMouseUp);
                    nwsapp.activeDocument.addEventListener('keyup', _shortcuts.handleKeyUp);

                    _utils.addScrollListeners()
                    window.addEventListener('resize', _shortcuts.handleResize, { passive: true });


                    publicai.active.bodyElement = iframeDoc.body
                    publicai.active.bodyElement.style.pointerEvents = "auto"


                    if (nws.isPlayer) {
                        play_interactive.resizeBodyToGivenSize()
                    }



                    if (nws.isPlayer) {
                        //making the body invisible for now. It will be switchd back on by the page role handlers
                        _utils.pageIsLoading(true)

                    }

                    //_utils.frameMessages.init() // disabled. frames talk to one another directly for now

                    //initiate the role handlers. 

                    publicai.active.bodyElement.style.margin = 0

                    for (let style of publicai.active.body.classes) {
                        iframeDoc.body.classList.add(style.name)
                        if (style.combo) {
                            for (let combo of style.combo) {
                                //console.log("adding body combo", combo)
                                iframeDoc.body.classList.add(combo);
                            }
                        }
                    }

                    publicai.active.bodyElement.setAttribute("public_id", publicai.active.body.id);
                    _handlers.div(publicai.active.bodyElement, publicai.active.body);


                    if (!nws.isPlayer) {
                        _size.selectAndResizeScene(true);
                        _size.setBreakpoints()
                        //copyStylesToIframe(iframe); //adds user styles
                        await play_interactive.addFonts(); // Make sure this only runs once - tbd.
                    }

                    _create.enableSelectionAndScrollListeners(publicai.active.bodyElement, publicai.active.body)

                    resolve();

                    if (!nws.isPlayer) {
                        _shortcuts.handleIframeEvents()
                    }

                } catch (error) {
                    // Reject the promise if an error occurs
                    //console.error("!!error loading iframe", error)
                    reject(error);
                }
            };


            handleLoad()

        };

        // Set the src of iframe to about:blank to trigger load event
        iframe.src = 'about:blank';

    });
}


export function resizeBodyToGivenSize() {


    let size
    let width, height = 500

    //check if the size is given
    if (nws.initOptions.size) {
        size = nws.initOptions.size
        width = size.split("x")[0] + "px"
        height = size.split("x")[1] + "px"
    } else {
        if (publicai.active.page.properties.size.responsive) {
            width = "100%"
            height = "100%"
        } else {
            //find the first enabled element from active list
            const activeSizes = publicai.active.page.properties.size.active;
            const firstEnabledSize = Object.values(activeSizes).find(size => size.enabled === true);
            width = firstEnabledSize.width + "px"
            height = firstEnabledSize.height + "px"
        }
    }

    publicai.active.bodyContainer.style.width = width
    publicai.active.bodyContainer.style.height = height
    publicai.active.bodyContainer.style.minWidth = width
    publicai.active.bodyContainer.style.minHeight = height

    console.log("have resized to ", width, height)




    //public.active.bodyElement.style.pointerEvents = "auto"

}

export function addDivTo(theLocation, classes, position, node) {


    /*  if (node.type == "span") {
         //skipping spans. They'll be configured later. 
         let span = $$$(document2, "public_span", node.id)
 
         if (span){
             console.log("span already exists", span)
             return span
         }else{
             console.log("span not found")
             var newDiv = document.createElement("span");
             _utils.allowTextSelection(newDiv, false)
             theLocation.appendChild(newDiv);
             return newDiv
         }
 
   
     } */

    // Create a new div element
    //var newDiv = document.createElement('div');

    let elementType = "div"

    if (node.type == "input_basic" || node.type == "dropdown_basic") {
        elementType = "input"
    }



    if (node.type == "textarea_basic") {
        elementType = "textarea"
    }


    if (node.type == "span") {
        elementType = "span"
        node.text = {
            text: "bla"
        }
    }

    var newDiv = document.createElement(elementType);

    if (node.type == "span") {
        console.log("span>> ", newDiv)
        newDiv.textContent = node.text.text
    }

    _utils.allowTextSelection(newDiv, false)


    // Append the div to the location
    if (position === 'first' && location.firstChild) {
        theLocation.insertBefore(newDiv, location.firstChild);
    } else {

        if (theLocation) {
            theLocation.appendChild(newDiv);
        } else {
            console.log("Location not found.")
        }
    }

    // Return the new div
    return newDiv;
}



export async function forceRecreateSelected(isHover = false, selectAfterCreate = false, componentIsReady = false) {



    let elementToBeRecreated
    if (!isHover) {
        elementToBeRecreated = publicai.selected.settings.id
    }


    let ancestor
    if (isHover) {
        ancestor = _find.elementByID(publicai.active.page, publicai.hovering.settings.ancestor);
    } else {
        ancestor = _find.elementByID(publicai.active.page, publicai.selected.settings.ancestor);
    }

    if (ancestor) {
        //console.log("----force recreate!")
        play_interactive.createPageElements(ancestor.content, ancestor.id, true, componentIsReady)
    } else {

        publicai.active.bodyElement.remove()
        await play_interactive.addBodyScene() //adding main scene
        //console.log("----force recreate!")
        play_interactive.createPageElements(publicai.active.page.content[0].content)
    }

    if (selectAfterCreate && elementToBeRecreated) {
        _create.activateSpecificElement(elementToBeRecreated)
    }

    if (!nws.isPlayer) {
        animator_timeline.init()
    }


}

export function createPageElements(content, ancestorID, foreceCreate = false) {

    //console.error("creating", content)



    if (!ancestorID) {
        publicai.totalElementsAdded = 0
        removeAllChildNodes(publicai.active.bodyElement) //clean up the scene
        ancestorID = publicai.active.body.id

    }

    if (foreceCreate) {
        //removes all element and re-creates at ancestor level
        let ancestorContainer = $$$(nwsapp.activeDocument, "public_id", ancestorID)
        removeAllChildNodes(ancestorContainer)

    }


    if (ancestorID == publicai.active.body.id) {
        _create.addSelectorContainer()
    }

    for (let theNode of content) {

        let node

        if (theNode.symbol) {
            //console.log("This is symbol", node)
            let symbolsFolder = _find.getRoot(publicai.active.page.id)?.symbols || publicai.root.pages.symbols
            let symbol = symbolsFolder[theNode.symbol.id]

            node = JSON.parse(JSON.stringify(symbol)) //deep copy of symbol

            /*  for (let key of Object.keys(symbol)) {
                 if (key != "id" && key != "symbol") {
                     node[key] = symbol[key] //shallow copy of symbol
                 }
             } */

            node.id = generateShortId()
            updateSceneIds(node)
            console.log("fresh node symbol", node)


        } else {
            node = theNode
        }



        let existingElement = $$$(nwsapp.activeDocument, "public_id", node.id)


        //console.log("-- " , existingElement, node)

        if (!existingElement) {

            node.ancestor = ancestorID
            let location

            if (node.ancestor) {
                location = $$$(nwsapp.activeDocument, "public_id", node.ancestor)
            } else {
                location = publicai.active.bodyElement
            }

            //console.log(location, node.ancestor)

            //console.log(node.classes[0].name)

            if (!location) {
                console.log("Location not found.")
            }

            let existingElement = play_interactive.addDivTo(location, node.classes, null, node)

            if (_handlers[node.type]) {

                if (node.settings?.attributes) {
                    for (let attribute of node.settings.attributes) {
                        if (attribute.name != "" && attribute.value != "") {
                            existingElement.setAttribute(attribute.name, attribute.value)
                        }
                    }
                }


                _handlers[node.type](existingElement, node, null, false) //adds text, media, etc
                existingElement.setAttribute("public_id", node.id)

                if (node.dynamic.schema) {
                    existingElement.setAttribute("public_schema", node.dynamic.schema.id)
                }

                //if (!nws.isPlayer) {
                _create.enableSelectionAndScrollListeners(existingElement, node)
                //}
                let newAncestorID = node.id
                publicai.totalElementsAdded++
                publicai.selected.settings = node

                // If this node has a nested 'content' array, recursively call this function with the new ancestor
                if (node.content && Array.isArray(node.content) && node.content.length > 0) {
                    //console.log("xxxx", node, existingElement)
                    play_interactive.createPageElements(node.content, newAncestorID);
                }
                //reset class list
                existingElement.classList = []
                if (!nws.isPlayer) {
                    _create.highlightElementIfBrandNew(node, existingElement)
                }

                // Add classes to the div
                if (node.classes && node.classes.length > 0) {
                    for (let className of node.classes) {


                        existingElement.classList.add(className.name);
                        if (className.combo) {
                            for (let combo of className.combo) {
                                //console.log("adding combmo", combo)
                                existingElement.classList.add(combo);
                            }

                        }
                    }
                }

                animation_setup.createElementAnimation(node, existingElement, publicai.active.body.id)

            } else {
                //console.log("no handler for", node.type)
            }

        } else {
            // If element already exists, do not re-create it
            //console.log("element already exists...", node)

            if (!nws.isPlayer && node.type == "span") {
                _create.enableSelectionAndScrollListeners(existingElement, node)
            }

            if (node.classes && node.classes.length > 0) {
                for (let className of node.classes) {


                    existingElement.classList.add(className.name);
                    if (className.combo) {
                        for (let combo of className.combo) {
                            //console.log("adding combmo", combo)
                            existingElement.classList.add(combo);
                        }

                    }
                }
            }

            publicai.selected.settings = node
            //node.ancestor = ancestorID

            /*   if (node.type == "body"){
                  node.ancestor = null
              } */


            //console.log("adding content into ", node.classes[0].name)
            if (node.content && Array.isArray(node.content) && node.content.length > 0) {
                play_interactive.createPageElements(node.content, existingElement);
            }
        }
    }
}

export function addFolderComponents(folder) {

    //adds components to the page. 

    let ancestor = _find.elementByID(publicai.pages, publicai.active.page.ancestor)
    let componentsFolder = null
    for (let item of ancestor.content) {
        if (item.type == "folder" && item.properties.role == "components") {
            componentsFolder = item
        }
    }
    if (componentsFolder) {

        publicai.active.components = componentsFolder.content
        publicai.hiddenElements = []

        for (let page of publicai.active.components) {
            for (let contentItem of page.content[0].content) {

                let nodeCopy = JSON.parse(JSON.stringify(contentItem))
                nodeCopy.ancestor = publicai.active.body.id
                publicai.hiddenElements.push(nodeCopy)
            }
        }

        play_interactive.createPageElements(publicai.hiddenElements, publicai.active.body.id);
    } else {
        //console.log("No components folder found")
    }
}

export async function renderInteractiveComponents() {

    this.addFolderComponents()

    if (nws.isPlayer) {
        //only auto enable carousels in client mode
        this.checkForCarousels()
        publicai.totalTime = _utils.updateTotalTime(publicai.selected.settings)


        animation_setup.refreshAllAnimations(publicai.active.body)

    }

    if (!nws.isPlayer) {
        //only auto enable carousels in client mode
        this.addInheritedSchemaSettings()

    }

    this.activateLightboxes()
    this.checkForSchemaContainers()
    _create.removeAllGhosts()
    this.checkForFormElements()


    publicai.ready = true

    if (nws.isPlayer) {
        logProjectImpression()

        if (nws.initOptions && nws.initOptions.record) {
            play_interactive.hideSystemControls()
        }
        publicai.actions = {}

        let delayedTimeout = 0

        if (nws.initOptions && nws.initOptions.record) {
            delayedTimeout = 2000
        }

        //console.log("delayed play by ", delayedTimeout)
        setTimeout(() => {

            console.log("Sending ready after ", delayedTimeout)

            window.parent.postMessage({
                action: "ready",
                data: { ready: true }
            }, '*');

            window.parent.parent.postMessage({
                action: "ready",
                data: { ready: true }
            }, '*');

            window.postMessage({
                action: "ready",
                data: { ready: true }
            }, '*');

            publicai.timeline.animation.play()

        }, delayedTimeout)

    }
    //nws.initOptions
}

export function hideSystemControls() {

    let systemControls = $$$(nwsapp.activeDocument, "system_role")
    for (let control of systemControls) {
        control.style.display = "none" //more reliable
        //control.style.classList.addd("hidden")
    }

}




export function activateLightboxes() {

    //checks if there are any lightboxes (tabbed content) and activates the first tab in each group. 
    // the first one is activated by default. Tbd if this is the best way


    if (!nws.isPlayer) {
        //do not activate lightboxes in editor
        return
    }

    let allUniqueLightboxes = new Set()

    let allTabs = _find.elementsByRole(publicai.active.page, "lightbox_tab")
    let firstTab = _find.elementByRole(publicai.active.page, "lightbox_tab")


    for (let tab of allTabs) {
        if (tab.node.settings?.lightbox) {
            allUniqueLightboxes.add(tab.node.settings.lightbox.group)
        }
    }

    for (let lightbox of allUniqueLightboxes) {

        let firstTabInGroup = _find.elementByRole(publicai.active.page, "lightbox_tab", lightbox)

        if (firstTabInGroup) {
            let settings = firstTabInGroup.settings.lightbox
            click_handlers.activateLightboxTab(settings)
        }

        //console.log("first tab in group", firstTabInGroup)

    }


    //_find.elementByRole(publicai.active.page, "lightbox_tab").settings.lightbox


}


export function addInheritedSchemaSettings(node, schema) {

    //creates a schemaStack for the newly created elemements

    let inherited = _find.getAncestorWithSchemaNew(publicai.selected.settings.id)
    if (inherited) {
        _dynamic.distributeSchemaSettingsToAllChildren(publicai.active.page)
    }

}

export function checkForCarousels() {
    let allCarouselElements = $$$(nwsapp.activeDocument, "public_carousel")

    //nws.isPlayer

    for (let pageElement of allCarouselElements) {
        let id = pageElement.getAttribute("public_carousel")
        let node = _find.elementByID(publicai.active.page, id)
        //check if carousel is part of dynamic carousel. In this case, it should not be initiated.
        let ancestorsIsDynamic = _find.isAncestorDynamicCarousel(node.id)

        if (!ancestorsIsDynamic) {

            widget_carousel.init(node)
        } else {
            //carousel is part of a dynamic carousel. It initiated later in dynamic_carousels_handlers
        }
    }


}




export function checkForFormElements() {

    //goes through all form containers
    let allDataElements = $$$(nwsapp.activeDocument, "public_data")
    for (let elementItem of allDataElements) {
        let id = elementItem.getAttribute("public_id")
        let node = _find.elementByID(publicai.active.page, id)
        _handlers[node.type](elementItem, node, null, true)
    }


    let allFormElements = $$$(nwsapp.activeDocument, "public_form")
    for (let elementItem of allFormElements) {
        let id = elementItem.getAttribute("public_id")
        let node = _find.elementByID(publicai.active.page, id)
        //_handlers.handleFormElements(elementItem, node)
        _handlers[node.type](elementItem, node, null, true)
        //true allows for the handlers to be called, flagging the component as fully rendered
    }


    //goes through all form containers
    let allAudioElements = $$$(nwsapp.activeDocument, "public_audio")

    for (let elementItem of allAudioElements) {
        let id = elementItem.getAttribute("public_id")

        let node = _find.elementByID(publicai.active.page, id)
        if (node) {

            _handlers[node.type](elementItem, node, null, true)
        } else {
            console.error("audio node not found", id)
        }
    }


}

export function checkForSchemaContainers() {
    //looks up all containers that have schemas attached. They will ghost-duplicate elements inside. Only the first element has functionality, all others are dummy
    //console.error("check for containers")

    return
    _create.removeAllGhosts()
    let allSchemaContainers = $$$(nwsapp.activeDocument, "public_schema")

    for (let elementItem of allSchemaContainers) {
        let id = elementItem.getAttribute("public_schema")
        let schema = publicai.dataSources.schemas[id]
        let collection = publicai.dataEntries[id]
        if (collection) {

            let element = elementItem.firstChild
            if (!element) {
                return
            }
            let node = _find.elementByID(publicai.active.page, element.getAttribute("public_id"))

            let wrapperNode = _find.elementByID(publicai.active.page, elementItem.getAttribute("public_id"))
            let filteredCollection = schema_filters.filter(collection, wrapperNode)

            //console.log("wrapper node is ", wrapperNode)

            for (let i = 1; i < filteredCollection.length; i++) {
                _create.duplicateElement({
                    "element": element,
                    "container": elementItem,
                    "entry": collection[i],
                    "index": i,
                    "node": node
                })
            }

        }
    }
    _create.addContentToGhosts()

}

