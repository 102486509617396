//handles scene resizes
//css size config is in /css!
export var _size = {


    setUpBreakpointSelectors: function () {


        this.manageSizeModes()

        let allBreakPointSelectors = $$$(document, "data-breakpoint")
        for (let item of allBreakPointSelectors) {
            item.onclick = function (e) {
                publicai.active.breakpoint = item.dataset.breakpoint
                _size.switchBreakPoint()
                _create.refreshSelectionContainer()
                css_management.manage()
            }
        }
        _size.switchBreakPoint(publicai.active.breakpoint)
    },


    checkReverseCompatibility() {

        if (!nwsapp.currentProject.config.formats) {
            nwsapp.currentProject.config.formats = _defaults.getSizes()
        }

        if (!publicai.active.page.properties.size.active) { publicai.active.page.properties.size.active = {} }
        if (!publicai.active.page.properties.size.current) {

            if (!publicai.active.page.properties.size.responsive) {

                //intialise current

                publicai.active.page.properties.size.current = {
                    "label": "Full HD (1080p)",
                    "value": "full_hd",
                    "icon": "slideshow",
                    "width": 1920,
                    "height": 1080,
                    "label2": "1920x1080px",
                    "classes": [],
                    "comment": "Standard high-definition resolution commonly used for widescreen displays.",
                    "enabled": false,
                }

            } else {
                publicai.active.page.properties.size.current = {
                    "label": "Responsive",
                    "value": "responsive",
                    "icon": "fit_page",
                    "width": "auto",
                    "height": "auto",
                    "label2": "Breakpoints-based",
                    "enabled": false,
                    "classes": [],
                    "comment": "Adjusts automatically to the available screen space based on breakpoints."
                }
            }
        }

        if (!publicai.active.page.properties.size) {
            //to remove in the future
            publicai.active.page.properties.size = _defaults.getDefaultSizeSettings()
        }
        if (!publicai.active.page.properties.sizes) {
            publicai.active.page.properties.sizes = {}
        }

    },

    setCanvasZoom: function () {
        let zoom = publicai.zoomValue;

        //canvas parent
        nwsapp.ux.canvas_container

        nwsapp.ux.canvas.style.transformOrigin = "50% 0%";  // Set the anchor point to top middle
        nwsapp.ux.canvas.style.transform = "scale(" + zoom / 100 + ")";

        nwsapp.ux.resize_handler.style.transformOrigin = "50% 0%";  // Set the anchor point to top middle
        nwsapp.ux.resize_handler.style.transform = "scale(" + publicai.zoomReverse + ")";

        nwsapp.ux.resize_handler.classList.toggle("is_hidden", !publicai.active.page.properties.size.responsive)

        if (publicai.active.page.properties.size.responsive) {

            /*       nwsapp.ux.canvas.style.flexGrow = 1; // Allow canvas to grow within the container
                  nwsapp.ux.canvas.style.overflowY = "auto";  */

        }

    },


    canvasZoom: function () {

        const throttledZoomSliderCallback = _utils.throttle(zoomSliderCallback, 5); // 200 ms delay
        let zoomKey = "responsive"
        let current = publicai.active.page.properties.size.current

        if (!publicai.active.page.properties.size.responsive) {
            zoomKey = current.width + "x" + current.height
        }

        function zoomSliderCallback(value) {
            localStorage.setItem("zoom", publicai.zoom)
            let zoomKey = "responsive"



            if (!publicai.active.page.properties.size.responsive) {
                zoomKey = publicai.active.page.properties.size.current.width + "x" + publicai.active.page.properties.size.current.height

            }



            publicai.zoom[zoomKey].value = value
            publicai.zoomValue = value
            publicai.zoomReverse = 1 / (publicai.zoomValue / 100);

            updateLabel(value)
            _size.setCanvasZoom()
        }

        let zoomProgressBar = $$$(nwsapp.ux.document_zoom, "data-slider", "progress")


        let topRange = 200

        function updateLabel(value) {
            nwsapp.ux.document_zoom_label.textContent = value + "%"
            let valueWidth = value * 100 / topRange

            zoomProgressBar.style.width = valueWidth + "%"
        }



        let initialPercentage = publicai.zoom[zoomKey].value

        let zoomValue = {
            value: initialPercentage
        }

        //_create.deselectAllElements();



        function startSlide() {
            _create.deselectAllElements();
        }

        function stopSlide() {
            _create.addSelectionContainer(publicai.selected.element, publicai.selected.settings, "select")
        }

        zoomSliderCallback(initialPercentage);

        // Update your slider call
        css_utils.zoomSlider({
            selector: nwsapp.ux.document_zoom,
            object: zoomValue,
            variable: "value",
            sliderCallback: throttledZoomSliderCallback, // Use throttled version
            defaultValue: initialPercentage,
            label: nwsapp.ux.document_zoom_label,
            units: "%",
            startSlideCallback: startSlide,
            stopSlideCallback: stopSlide,
            range: {
                "min": 0,
                "max": 200,
            },
        });

    },



    manageSizeModes: function () {

        this.checkReverseCompatibility()
        this.canvasZoom()

        //responsiveness switch
        nwsapp.ux.responsive_group.classList.toggle("hidden", !publicai.active.page.properties.size.responsive)
        nwsapp.ux.page_sizes.classList.toggle("hidden", publicai.active.page.properties.size.responsive)

        this.toggleUIPanels()

        this.setUpSizesDropdown()
        if (!publicai.active.page.properties.size.responsive) {
            this.toggleSize()
            this.toggleGroups()
        }

        interactive_controls.refresh()


        nwsapp.ux.page_width.value = publicai.active.page.properties.size.current.width
        nwsapp.ux.page_height.value = publicai.active.page.properties.size.current.height

        nwsapp.ux.page_width.style.cssText += '-webkit-appearance: none; margin: 0;';
        nwsapp.ux.page_width.style.cssText += '-moz-appearance:textfield;';

        nwsapp.ux.page_height.style.cssText += '-webkit-appearance: none; margin: 0;';
        nwsapp.ux.page_height.style.cssText += '-moz-appearance:textfield;';


        nwsapp.ux.page_width.onchange = function () {
            publicai.active.page.properties.size.size.width = nwsapp.ux.page_width.value
            _size.manageSizeModes()
        }

        nwsapp.ux.page_height.onchange = function () {
            publicai.active.page.properties.size.size.height = nwsapp.ux.page_height.value
            _size.manageSizeModes()
        }

        let canvas = nwsapp.ux.canvas
        let current = publicai.active.page.properties.size.current

        if (!publicai.active.page.properties.size.responsive) {
            canvas.style.maxWidth = current.width + "px"
            canvas.style.width = current.width + "px"
            canvas.style.height = current.height + "px"
        } else {
            canvas.style.maxWidth = "100%"
            canvas.style.height = "100%"
        }


    },



    toggleSize: function () {
        let button = nwsapp.ux.format_switch
        let label = nwsapp.ux.format_status_label

        button.onclick = function () {

            toggleTheSize()
            interactive_controls.refresh()
        }

        let current = publicai.active.page.properties.size.current
        let key = current.width + "x" + current.height

        function toggleTheSize() {

            if (!publicai.active.page.properties.size.active[key]) {
                publicai.active.page.properties.size.active[key] = JSON.parse(JSON.stringify(current))
                publicai.active.page.properties.size.active[key].enabled = true
            } else {
                //exists already 
                publicai.active.page.properties.size.active[key].enabled = !publicai.active.page.properties.size.active[key].enabled
            }

            activateButton()

        }

        function activateButton() {
            //console.log("k", publicai.active.page.properties.size.active[key])
            if (publicai.active.page.properties.size.active[key]) {
                render.addClassesToAllChildrenOf(button, "is_active", publicai.active.page.properties.size.active[key].enabled)
            } else {
                render.addClassesToAllChildrenOf(button, "is_active", false)
            }
            if (publicai.active.page.properties.size.active[key] && publicai.active.page.properties.size.active[key].enabled) {
                label.textContent = "ON"
            } else {
                label.textContent = "OFF"
            }

        }

        activateButton()

    },

    toggleGroups: function () {
        let current = publicai.active.page.properties.size.current


        if (current.value == "fixed") {
            //adding custom size
            nwsapp.ux.default_sizes_group.classList.remove("is_active")
            nwsapp.ux.custom_size_group.classList.add("is_active")

        } else {

            nwsapp.ux.default_sizes_group.classList.add("is_active")
            nwsapp.ux.custom_size_group.classList.remove("is_active")

            nwsapp.ux.default_sizes_label.textContent = current.label + " (" + current.width + "x" + current.height + ")"
        }

    },

    order: [
        "auto_auto",
        "860x540",
        "label_video",
        "1920x1080",
        "1280x720",
        "1080x1080",
        "1080x1920",
        "1080x1350",
        "label_display",
        "970x250",
        "300x250",
        "336x280",
        "300x600",
    ],


    setUpSizesDropdown: function () {

        let sizesToDisplay = []

        /*     for (let item of Object.values(publicai.active.page.properties.active)) {
                sizesToDisplay.push(item)
            } */




        // Add the formats from the order array
        for (let key of this.order) {
            let item = nwsapp.currentProject.config.formats[key];
            if (item) {

                sizesToDisplay.push(item);
            } else {
                if (key == "label_video") {
                    sizesToDisplay.push({
                        "label": "Video Formats:",
                        "value": null,
                        "icon": "south",
                        "style": "is_chapter",
                        "enabled": false,
                    });
                }

                if (key == "label_display") {
                    sizesToDisplay.push({
                        "label": "Display Formats:",
                        "value": null,
                        "icon": "south",
                        "style": "is_chapter",
                        "enabled": false,
                    });
                }
            }
        }

        // Add any remaining formats not already in the sizesToDisplay
        for (let key in nwsapp.currentProject.config.formats) {
            if (!this.order.includes(key)) {
                sizesToDisplay.push(nwsapp.currentProject.config.formats[key]);
            }
        }

        publicai.sortedSizes = sizesToDisplay


        css_utils.setup_reg_dropdown({
            "selector": nwsapp.ux.default_sizes_label,
            "value": "Select",
            "multiSelect": false,
            "search": false,
            "values": sizesToDisplay,
            "label": "Add new element",
            "styles": ["auto", "horizontal", "bold", "no_input"],
            "customMode": true,
            "save": false,
            "callback": changeResponsiveMode,
            "direction": "down",
            "anchor": "left",
            "overlayColor": "black",
            "overlayOpacity": 0.01,
            "width": "275"
        })


        css_utils.setup_reg_dropdown({
            "selector": nwsapp.ux.default_sizes_label,
            "value": "Select",
            "multiSelect": false,
            "search": false,
            "values": sizesToDisplay,
            "label": "Add new element",
            "styles": ["auto", "horizontal", "bold", "no_input"],
            "customMode": true,
            "save": false,
            "callback": changeResponsiveMode,
            "direction": "down",
            "anchor": "left",
            "overlayColor": "black",
            "overlayOpacity": 0.01,
            "width": "275"
        })


        css_utils.setup_reg_dropdown({
            "selector": nwsapp.ux.page_mode,
            "value": "Select",
            "multiSelect": false,
            "search": false,
            "values": sizesToDisplay,
            "label": "Add new element",
            "styles": ["auto", "horizontal", "bold", "no_input"],
            "customMode": true,
            "save": false,
            "callback": changeResponsiveMode,
            "direction": "down",
            "anchor": "left",
            "overlayColor": "black",
            "overlayOpacity": 0.01,
            "width": "275"
        })


        function changeResponsiveMode(s) {
            if (s.value == "responsive") {
                publicai.active.page.properties.size.responsive = true

            } else {

                publicai.active.page.properties.size.responsive = false

                delete publicai.active.page.properties.size.current
                publicai.active.page.properties.size.current = s
            }
            _size.manageSizeModes()

            //since zoom level may have changed, refresh the selection to reflect that
            _create.deselectAllElements();
            _create.addSelectionContainer(publicai.selected.element, publicai.selected.settings, "select")

        }


    },

    toggleUIPanels: function () {

        let s = publicai.active.page.properties.size.sizes

        if (!publicai.active.page.properties.size.responsive) {

            if (!s || !s.value || s.value == "fixed") {
                //adding custom size
                nwsapp.ux.default_sizes_group.classList.remove("is_active")
                nwsapp.ux.custom_size_group.classList.add("is_active")

            } else {
                nwsapp.ux.default_sizes_group.classList.add("is_active")
                nwsapp.ux.custom_size_group.classList.remove("is_active")
                nwsapp.ux.default_sizes_label.textContent = s.label
            }
        }

        nwsapp.ux.responsive_group.classList.toggle("hidden", !publicai.active.page.properties.size.responsive)
        nwsapp.ux.page_sizes.classList.toggle("hidden", publicai.active.page.properties.size.responsive)
    },





    manageSizeModes_____: function () {





        function disableNumberInputArrows() {
            var style = document.createElement('style');
            style.innerHTML = `
                /* Chrome, Safari, Edge, Opera */
                input[type="number"]::-webkit-outer-spin-button,
                input[type="number"]::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
        
                /* Firefox */
                input[type="number"] {
                    -moz-appearance: textfield;
                }
        
                /* Hide up/down arrows for all browsers */
                input[type="number"] {
                    appearance: textfield;
                }
            `;

            // Append the style element to the document head
            document.head.appendChild(style);
        }

        disableNumberInputArrows()



    },


    switchBreakPoint(resize = true) {


        let allBreakPointSelectors = $$$(document, "data-breakpoint")
        let scene_holder = publicai.sceneContainer

        for (let item of allBreakPointSelectors) {
            if (item.dataset.breakpoint == publicai.active.breakpoint) {
                item.classList.add("active")
            } else {
                item.classList.remove("active")
            }

            if (resize) { //do not resize elements when resizing by the handles
                let sizes = publicai.breakpoints.definitions[publicai.active.breakpoint]
                scene_holder.parentElement.style.width = (sizes.min || 0) + "px"
                if (sizes.max) {
                    scene_holder.style.maxWidth = sizes.max + "px"
                } else {
                    scene_holder.style.maxWidth = "unset"
                }
            }
        }

        $$$(document, "data-breakpoint-info", "size").textContent = numberWithCommas(parseInt(document.querySelector(".animator_holder_border").style.width.replace("px", ""))) + "px"
        if (publicai.active.cssStack && publicai.active.cssStack.length > 0) {
            //may error at first
            css_management.manage()
        }
    },

    refreshRulerIndicators: function (theWidth) {
        //set up the values of the ruller indicators at the top of the page

        if (!theWidth) {
            theWidth = parseInt(document.querySelector(".animator_holder_border").style.width.replace("px", ""))
        } else {
            theWidth = parseInt(theWidth.replace("px", ""))
        }

        let i = 0
        for (let item of _size.allRulerIndicators) {
            let theValue = numberWithCommas(Math.round(theWidth / 4) * i)
            item.textContent = theValue + "px"
            i++
        }
    },


    setUpSceneResize: function () {


        let scene = document.querySelector(".animator_holder_border")
        //public.sceneContainer
        let resizeHandle = $$$(document, "data-resize", "handle")
        let resizeHandleLeft = $$$(document, "data-resize", "left")
        let resizeHandleRight = $$$(document, "data-resize", "right")
        let sizeLabel = $$$(document, "data-breakpoint-info", "size")

        _size.allRulerIndicators = $$$(document, "data-ruler-item")

        _size.refreshRulerIndicators()

        function updateSceneWidthLabel() {

            sizeLabel.textContent = numberWithCommas(parseInt(scene.style.width.replace("px", ""))) + "px"

        }
        updateSceneWidthLabel()


        function findMatchingBreakpoint(width) {
            let breakpoints = publicai.breakpoints.definitions;
            return Object.keys(breakpoints).find(breakpoint => {
                let { min, max } = breakpoints[breakpoint];
                min = min || 0;
                max = max || Infinity;
                return width >= min && (max === null || width <= max);
            }) || null;
        }

        let allResizeHandles = [resizeHandle, resizeHandleLeft, resizeHandleRight]
        function highlightResizeHandles(activate = true) {
            for (let item of allResizeHandles) {
                if (activate) {
                    item.classList.add("active")
                } else {
                    item.classList.remove("active")
                }
            }

        }

        function findMatchingDevice(width) {
            let matches = [];
            let minDiff = Infinity;

            Object.entries(_size.deviceSizes).forEach(([device, deviceWidth]) => {
                let diff = Math.abs(deviceWidth - width);

                if (diff < minDiff) {
                    matches = [[device, deviceWidth]];
                    minDiff = diff;
                } else if (diff === minDiff) {
                    matches.push([device, deviceWidth]);
                }
            });

            return matches;
        }

        let lastFoundDevices = ""
        let devicesHolder = document.querySelector(".devices_holder")
        removeAllChildNodes(devicesHolder)

        function createMatchedDevicesList(matchedDevices) {

            if (matchedDevices.length > 0) {
                devicesHolder.classList.add("active")
                removeAllChildNodes(devicesHolder)
                for (let item of matchedDevices) {
                    let device = item[0]
                    let size = item[1]
                    let deviceItem = addWhatWhere(components.ui.matched_device, devicesHolder, "", "")
                    deviceItem.textContent = device + " (" + size + "px)"
                }
            } else {
                devicesHolder.classList.remove("active")
            }
            //
        }


        function resizeElement(e, isLeftHandle) {
            document.querySelectorAll(".object_select").forEach(item => { item.style.pointerEvents = "none" })
            let startX = e.clientX;
            let startWidth = parseInt(window.getComputedStyle(scene).width, 10);

            let deselected = false
            function performResize(e) {
                highlightResizeHandles(true)



                let contentHolder = document.querySelector(".thecontent_holder")


                if (!deselected) {
                    _create.deselectAllElements(22)
                    contentHolder.style.pointerEvents = "none"
                    deselected = true
                    console.log("deselected....")
                }



                publicai.resizing = true;
                let currentX = e.clientX;
                let delta = currentX - startX;

                if (isLeftHandle) {
                    // Left handle resizing
                    let newWidth = startWidth - delta;
                    scene.style.width = Math.max(newWidth, 0) + 'px';
                } else {
                    // Right handle resizing
                    let newWidth = startWidth + delta;
                    scene.style.width = Math.max(newWidth, 0) + 'px';
                }
                updateSceneWidthLabel()

                // Determine the current breakpoint
                let newBreakpoint = findMatchingBreakpoint(parseInt(scene.style.width, 10));




                if (newBreakpoint !== publicai.active.breakpoint && publicai.active.page.properties.size.responsive) {
                    console.log("switch break", newBreakpoint)
                    publicai.active.breakpoint = newBreakpoint;
                    //console.log('New breakpoint:', newBreakpoint);
                    _size.switchBreakPoint(false)
                }
                let matchedDevices = findMatchingDevice(parseInt(scene.style.width, 10));

                if (matchedDevices.map(([device, size]) => `${device} (${size}px)`).join(', ') != lastFoundDevices) {
                    lastFoundDevices = matchedDevices.map(([device, size]) => `${device} (${size}px)`).join(', ') //found new devices, re-create list
                    createMatchedDevicesList(matchedDevices);
                }

                _size.refreshRulerIndicators(scene.style.width)

            }


            function stopResize() {
                highlightResizeHandles(false)
                createMatchedDevicesList([])
                document.querySelectorAll(".object_select").forEach(item => { item.style.pointerEvents = "none" })
                publicai.resizing = false
                window.removeEventListener('mousemove', performResize, false);
                window.removeEventListener('mouseup', stopResize, false);
                console.log('Resize stopped');

                let contentHolder = document.querySelector(".thecontent_holder")
                contentHolder.style.pointerEvents = "auto"
            }

            window.addEventListener('mousemove', performResize, false);
            window.addEventListener('mouseup', stopResize, false);
        }

        if (resizeHandleRight) resizeHandleRight.addEventListener('mousedown', (e) => resizeElement(e, false), false);
        if (resizeHandleLeft) resizeHandleLeft.addEventListener('mousedown', (e) => resizeElement(e, true), false);
        if (resizeHandle) resizeHandle.addEventListener('mousedown', (e) => resizeElement(e, false), false);
    },

    setBreakpoints: function () {
        //_size.refreshMediaQueries()
        return //do not apply

        let breakpoints = publicai.breakpoints.all

        let loc = document
        if (typeof document2 != "undefined") {
            loc = document2
        }

        let styleElement = loc.getElementById('custom-breakpoints-style');
        if (!styleElement) {
            styleElement = loc.createElement('style');
            styleElement.id = 'custom-breakpoints-style';
            loc.head.appendChild(styleElement);
        }

        styleElement.textContent = `
            ${breakpoints.__desktop}
            ${breakpoints.__tablet}
            ${breakpoints.__mobile_h}
            ${breakpoints.__mobile_v}
        `;
    },


    refreshMediaQueries: function () {

        return
        let definitions = publicai.breakpoints.definitions
        const queries = {};
        for (const [key, { min, max }] of Object.entries(definitions)) {
            let mediaQuery = `@media `;
            if (min !== null) mediaQuery += `(min-width: ${min}px)`;
            if (min !== null && max !== null) mediaQuery += ' and ';
            if (max !== null) mediaQuery += `(max-width: ${max}px)`;
            mediaQuery += ` { .${key} { /* ${key.charAt(0).toUpperCase() + key.slice(1)} styles */ } }`;
            queries[key] = mediaQuery;
        }

        publicai.breakpoints.all = queries
        //console.log(publicai.breakpoints.all)
        _size.mediaQueryListener()
        return queries;
    },

    mediaQueryListener: function () {
        // Utility function to create a media query string based on the min and max widths
        function createMediaQueryString(min, max) {
            const parts = [];
            if (min !== null) parts.push(`(min-width: ${min}px)`);
            if (max !== null) parts.push(`(max-width: ${max}px)`);
            return parts.join(' and ');
        }

        // Function to handle the change event for each media query
        function handleBreakpointChange(breakpoint, queryList) {
            if (queryList.matches) {
                console.log(`Entered ${breakpoint} breakpoint`);
                // Add any specific logic you want to handle for this breakpoint
            } else {
                console.log(`Exited ${breakpoint} breakpoint`);
                // Add any logic you want to handle when exiting this breakpoint
            }
        }

        // Assume document2 is the iframe's document object
        if (typeof document2 != "undefined") {
            let iframeWindow = document2.defaultView || document2.parentWindow;

            // Iterate over the breakpoints and set up listeners
            Object.entries(publicai.breakpoints.definitions).forEach(([breakpoint, { min, max }]) => {
                const queryString = createMediaQueryString(min, max);
                const mediaQueryList = iframeWindow.matchMedia(queryString);
                mediaQueryList.addListener((e) => handleBreakpointChange(breakpoint, e));

                // Call the handler function immediately to check if the media query matches on load
                handleBreakpointChange(breakpoint, mediaQueryList);
            });

        }

    },

    logBreakpointChanges: function () {
        let breakpoints = publicai.breakpoints.definitions
        let iframeWindow = document2.defaultView || document2.parentWindow;

        // Function to get current breakpoint
        function getCurrentBreakpoint(width) {
            if ((breakpoints.__mobile_v.min === null || width >= breakpoints.__mobile_v.min) && width <= breakpoints.__mobile_v.max) {
                return '__mobile_v';
            }
            if (width >= breakpoints.__mobile_h.min && width <= breakpoints.__mobile_h.max) {
                return '__mobile_h';
            }
            if (width >= breakpoints.__tablet.min && width <= breakpoints.__tablet.max) {
                return '__tablet';
            }
            if (width >= breakpoints.__desktop.min) {
                return '__desktop';
            }
            return 'Undefined Breakpoint';
        }

        // Event listener for resize events
        function onResize() {
            const width = iframeWindow.innerWidth;
            const currentBreakpoint = getCurrentBreakpoint(width);
            console.log(`Current size: ${width}px, Breakpoint: ${currentBreakpoint}`);
        }

        // Attach the event listener to the resize event
        iframeWindow.addEventListener('resize', onResize);

        // Initial log
        onResize();
    },


    selectAndResizeScene: function (activate = true) {

        //console.log("Select and resize scene")
        //handles resizing of the scene
        let scene_holder = document.querySelector(".thecontent_holder")
        let animator_bg = document.querySelector(".animator_bg")


        if (!activate == false) {
            //_nav.toggleNavigationContainer(false)
            scene_holder.classList.add("selected")



            animator_bg.onclick = function (e) {

                if (publicai.colorPickerActive) {
                    publicai.isColoring = null
                    publicai.colorPickerActive.spectrum("hide"); //since propagation is disabled, we need to manually hide the color picker if active
                    return
                }


                publicai.active.state = null //reset state in case it was editing something
                if (!publicai.active.panel) {
                    editor.selectBody()
                }
            }

            scene_holder.onclick = function (e) {
                console.log("scene holder click") // will not work because holder picks up the click

                console.log(e.target)

                if (e.target == publicai.active.bodyElement || e.target == scene_holder) {
                    animator_timeline.deActivateAllTracks(false)
                }
                e.stopPropagation()
                e.preventDefault()
            }

            //public.selected.element = [publicai.scene, document.querySelector(".animator_holder_border")]
            publicai.selected.element = publicai.active.bodyElement
            publicai.selected.settings = publicai.active.body
            //public.selected.id = publicai.active.body.id



            applyCSS()
        } else {
            scene_holder.classList.remove("selected")
            //console.log("here")
        }

    },


    setResizeObserver: function () {


        let contentArea = document.querySelector(".animator_main");

        if (!contentArea) {
            console.warn("contentArea not found");
            return;
        }

        // Function to handle resize events
        const onResize = () => {
            _size.refreshEmValue();
        };

        // Set up the ResizeObserver
        const resizeObserver = new ResizeObserver(onResize);

        // Start observing the contentArea
        resizeObserver.observe(contentArea);

    },

    refreshEmValue: function () {


        // Get the actual pixel dimensions for the active size and aspect ratio

        let areaSize = 0;
        let contentArea = document.querySelector(".animator_main");
        let baseSize = publicai.aspects[publicai.active.aspect].basedFontSize;

        if (publicai.active.size.toLowerCase() === "full screen") {
            areaSize = contentArea.offsetWidth * contentArea.offsetHeight;
        } else {
            let [width, height] = publicai.active.size.split("x").map(Number);
            areaSize = width * height;
        }

        // Assuming the base size corresponds to the area for "1080x1920"
        let baseArea = 300 * 600;
        //let baseArea = 400 * 640;

        // Calculate the scaling factor based on the area
        let scaleFactor = Math.sqrt(areaSize / baseArea);

        // Calculate the new em value
        let newEm = baseSize * scaleFactor;

        // Update the em value in the active object
        publicai.active.em = newEm;


        newEm = Math.round(newEm * 100) / 100

        css(publicai.active.body.classes, 0)["font-size"] = newEm + "px"
        contentArea.style.fontSize = newEm + "px"
        return newEm;
    },

    deviceSizes: {
        "iPhone 6/7/8": 375,
        "iPhone 6/7/8 Plus": 414,
        "iPhone X/XS/11 Pro": 375,
        "iPhone XR/11": 414,
        "iPhone 12/13 Mini": 375,
        "iPhone 12/13/14": 390,
        "iPhone 12/13/14 Pro": 390,
        "iPhone 12/13/14 Pro Max": 428,
        "iPad Mini": 768,
        "iPad Air/Pro 9.7": 768,
        "iPad Pro 10.5": 834,
        "iPad Pro 11": 834,
        "iPad Pro 12.9": 1024,
        "Samsung Galaxy S8/S9/S10": 360,
        "Samsung Galaxy S8+/S9+/S10+": 412,
        "Samsung Galaxy S20/S21": 384,
        "Samsung Galaxy Note 10/20": 412,
        "Samsung Galaxy Note 10+/20+": 428,
        "Google Pixel 3/4/5": 393,
        "Google Pixel 3 XL/4 XL": 412,
        "Huawei P30/P40": 360,
        "Huawei P30 Pro/P40 Pro": 384
    }

}