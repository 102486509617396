export var css_layout = {

    init: function () {
        //css_classes.add()

        if (!this.ui) {
            this.ui = {
                "panel": $$$(document, "data-group", "layout"),

                "display": $$$(document, "layout-combo", "display"),

                "timeline": $$$(document, "layout-combo", "timeline"),

                "direction": $$$(document, "layout-combo", "flex-direction"),
                "direction_reverse": $$$(document, "direction-inverse", "button"),
                "wrap_reverse": $$$(document, "wrap-inverse", "button"),

                "wrap": $$$(document, "layout-combo", "flex-wrap"),
                "justify": $$$(document, "layout-combo", "justify-content"),
                "align": $$$(document, "layout-combo", "align-items"),
                "allDataInputGroups": $$$($$$(document, "data-group", "layout"), "data-input-group"),
                "carouselGroup": $$$(document, "data-group", "carousel")


            }

            this.ui.on = $$$(css_layout.ui.timeline, "data-value", "true")
            this.ui.off = $$$(css_layout.ui.timeline, "data-value", "false")
        }


        //display
        css_utils.combo({
            "selector": this.ui.display,
            "save": true,
        })

        css_utils.combo({
            "selector": this.ui.wrap,
            "callback": css_layout.updateWrap,
            "save": true,
            "reverseElement": this.ui.wrap_reverse,
        })





        css_utils.combo({
            "selector": this.ui.direction,
            "callback": css_layout.updateDirectionIcons,
            "save": true,
            "reverseElement": this.ui.direction_reverse,
        })




        css_utils.combo({
            "selector": this.ui.justify,
            "save": true,
        })

        css_utils.combo({
            "selector": this.ui.align,
            "save": true,
        })


        for (let item of css_layout.ui.allDataInputGroups) {
            css_utils.input({
                "selector": item,
                "variable": item.getAttribute("data-input-group"),
                "validation": "none",
            })
        }

        this.setUpTimelineSwitch()

    },


    setUpTimelineSwitch: function () {


        function refreshStatus() {
            let status = publicai.selected.settings.settings?.timeline?.enabled
            if (status) {
                css_layout.ui.on.classList.add("active")
                css_layout.ui.off.classList.remove("active")
                
            } else {
                css_layout.ui.on.classList.remove("active")
                css_layout.ui.off.classList.add("active")
            }
            
        }

        css_layout.ui.on.onclick = css_layout.ui.off.onclick= e => {
            publicai.selected.settings.settings.timeline.enabled = !publicai.selected.settings.settings.timeline.enabled
            refreshStatus()
            animator_timeline.init()
            css_management.updateBreadCrumbs()
            e.stopPropagation()
        }

        //_utils.openCloseTimeline(publicai.selected.settings.settings.timeline.enabled, true)

        refreshStatus()
    },


    updateWrap: function (existingCssValue) {

    },


    updateDirectionIcons: function (existingCssValue) {


        css_layout.ui.carouselGroup.classList.remove("hidden")

        //to investigate and uncomment! bug.
        if (existingCssValue == "row") {
            css_layout.ui.carouselGroup.classList.remove("hidden")
        } else {
            //css_layout.ui.carouselGroup.classList.add("hidden")
        }


        //change the justify and align icons based on the flex direction

        let justify = css_layout.ui.justify
        let align = css_layout.ui.align

        let allJustifyIcons = $$$(justify, "data-value")
        let allAlignIcons = $$$(align, "data-value")


        let icons = {
            row: {
                justify: ["align_justify_flex_start", "align_justify_center", "align_justify_flex_end", "align_justify_space_around"],
                align: ["align_vertical_top", "align_vertical_center", "align_vertical_bottom", "align_items_stretch"]
            },
            column: {
                justify: ["align_vertical_top", "align_center", "align_vertical_bottom", "align_stretch"],
                align: ["align_horizontal_left", "align_horizontal_center", "align_horizontal_right", "align_justify_stretch"]
            }
        }


        let index = 0
        for (let icon of allJustifyIcons) {

            let existingCssValueBackup = existingCssValue.replace("-reverse", "")

            icon.firstChild.textContent = icons[existingCssValueBackup].justify[index]
            index++
        }

        index = 0
        for (let icon of allAlignIcons) {
            let existingCssValueBackup = existingCssValue.replace("-reverse", "")
            icon.firstChild.textContent = icons[existingCssValueBackup].align[index]
            index++
        }


    }


}