import sections from '../sections/sections.js';  // Import your sections
import manage from '../modules/manage/manage.js';  // Import your sections
import { checkSessionClient } from '../services/auth/session.js';  // Import your sections
import * as system from '../modules/utils/system.js';



export class Router {
    constructor() {
        if (typeof window !== 'undefined') {
            this.path = { full: '', segments: [] };  // Initialize the path object
            this.history = [];  // Initialize the history array to store path changes
            this.handleRoute(window.location.pathname);  // Handle the initial route when the page loads
            window.router = this;
            window.onpopstate = this.handlePopState.bind(this);  // Handle browser back/forward button
        }
        //console.log("Router initialized for client-side navigation.");
    }

    // Navigate to a specific route and update the URL, calling the handler
    navigateTo(path) {
        if (typeof window !== 'undefined') {
            window.history.pushState({}, '', path);  // Updates the URL and adds it to browser history
            system.routeUpdate(path)
            this.handleRoute(path);  // Trigger the handler for the route
        } else {
            //console.error('navigateTo can only be used in the browser');
        }
    }

    // Update the URL without triggering the handler or adding to browser history
    updateURLSilently(path) {
        console.log(">>>>>updateURLSilently", path)
        if (typeof window !== 'undefined') {
            window.history.replaceState({}, '', path);  // Silently update the URL without calling the handler
            this.updatePath(path, false);  // Just update the stored path, not re-trigger routing
            //console.error(`URL updated silently to: ${path}`);
        } else {
            //console.error('updateURLSilently can only be used in the browser');
        }
    }


    // New method to append a segment to the current path
    appendNavigateTo(segment) {
        if (typeof window !== 'undefined') {
            // Construct the new path by appending the segment to the current path
            const newPath = `${this.path.full.replace(/\/+$/, '')}/${segment.replace(/^\/+/, '')}`;
            this.navigateTo(newPath);
        } else {
            //console.error('appendNavigateTo can only be used in the browser');
        }
    }

    // Handle the route based on the current path
    handleRoute(path) {
        this.updatePath(path, true);
        checkSessionClient(path);

        const sectionName = this.path.segments[0];

        // Check if the path starts with '/manage'
        if (sectionName === 'manage') {
            const manageSection = this.path.segments[1]; // Second segment for the manage sub-section name

            if (manageSection && manage?.[manageSection]?.init) {
                manage[manageSection].init(path); // Call the init method for the manage sub-section
            } else {
                console.log(`No manage sub-section found for /manage/${manageSection}`);
            }
        } else if (sectionName && sections[sectionName]?.init) {
            sections[sectionName].init(path);
        } else {
            console.log(`No section found for ${path}`);
            window.location.href = 'https://www.nws.ai';




        }
    }



    updatePageTitle(title) {
        document.title = title;
    }

    // Update the path object, store it in the history (if needed), and store the full path and segments
    updatePath(path, addToHistory = false) {
        this.path = {
            full: path,
            segments: path.split('/').filter(Boolean)  // Filter out empty segments
        };

        // If addToHistory is true, store the current path in the history
        if (addToHistory) {
            this.history.push(this.path);
        }
        //console.log(`Path updated: ${path}`);
        //console.log(`History:`, this.history);
    }

    // Handle back/forward navigation
    handlePopState() {
        const path = window.location.pathname;
        console.log(`Navigating to ${path} from popstate event`);
        this.handleRoute(path);
    }
}
