export var css_transforms = {
    init: function () {
        if (!this.ui) {
            this.ui = $$$$(document, "transforms_el")
        }


        if (!css_transforms.activePanel) {
            css_transforms.activePanel = "translate"
        }

        this.createList()
        css_transforms.editTransform(this, "css_transforms.activePanel")
    },

    createList: function (openPanel) {
        let ancestorsData = this.checkValuesFromAncestors({
            "variable": "transform"
        })

        let transformData = []

        if (ancestorsData.existingCssValue) {
            transformData.push({
                data: ancestorsData.existingCssValue,
                inherited: false
            })
        }

        if (ancestorsData.waterfalledValues) {
            for (let item of ancestorsData.waterfalledValues) {
                transformData.push({
                    data: ancestorsData.waterfalledValues,
                    inherited: true
                })
            }
        }

        let transformedCSS = css_transforms.parseTransform(publicai.active.lastStyleInStack.css["transform"])
        css_transforms.transform = transformedCSS.fullResponse

        let transformItems = []
        css_transforms.editTransform(transformItems[0], "translate")
    },

    setActivepanel: function (key) {
        if (key == "translate") {
            css_transforms.activePanel = "translate"
        }
        if (key == "scale3d") {
            css_transforms.activePanel = "scale3d"
        }
        if (key == "rotateX" || key == "rotateY" || key == "rotateZ") {
            css_transforms.activePanel = "rotate"
        }
        if (key == "skew") {
            css_transforms.activePanel = "skew"
        }
    },

    formatTransformLabel: function (key, value) {
        if (Array.isArray(value)) {
            const formattedValues = value.map(v => `${v.value}${v.unit}`).join(', ');
            return `${key}(${formattedValues})`;
        } else {
            return `${key}(${value.value}${value.unit})`;
        }
    },


    parseTransform: function (transformString) {

        const defaultProperties = css_transforms.getDefaultProperties()

        const regex = /(\w+)\(([^)]+)\)/g;
        let match;
        while ((match = regex.exec(transformString)) !== null) {
            const property = match[1];
            const values = match[2].split(',').map(value => {
                const num = parseFloat(value);
                const unit = value.match(/[a-zA-Z%]+$/)?.[0] || '';
                return { value: num, unit: unit };
            });
            defaultProperties[property] = values;
        }

        const fullResponse = { ...defaultProperties };
        const justTheOnesThatMatter = {};

        for (const prop in defaultProperties) {
            const values = defaultProperties[prop];
            const isDefaultValue = values.every(val =>
                (prop.startsWith('scale') && val.value === 1) ||
                (!prop.startsWith('scale') && val.value === 0)
            );

            if (!isDefaultValue) {
                justTheOnesThatMatter[prop] = values;
            }
        }

        return {
            fullResponse: fullResponse,
            justTheOnesThatMatter: justTheOnesThatMatter
        };
    },

    updateTransform: function (refreshPanels = true) {
        let transformObject = css_transforms.transform;

        //console.log("transformObject", transformObject)

        //scale3d


        const transformStrings = [];

        for (const property in transformObject) {
            const values = transformObject[property];
            let includeTransform = false;  // Flag to determine if the transform affects the element

            const valueString = Array.isArray(values) ?
                values.map((v, index) => {
                    if (property === 'translateZ') {
                        v.unit = 'px';  // Force the unit for translateZ to be 'px'
                    }
                    if ((property.startsWith('scale') && v.value !== 1) ||
                        (!property.startsWith('scale') && v.value !== 0)) {
                        includeTransform = true;
                    }
                    return `${v.value}${property.startsWith('scale') ? '' : v.unit}`;
                }).join(', ') :
                `${values.value}${property.startsWith('scale') ? '' : values.unit}`;

            if (includeTransform || property.startsWith('translate') || property.startsWith('rotate') || property.startsWith('skew')) {
                transformStrings.push(`${property}(${valueString})`);
            }
        }

        publicai.active.lastStyleInStack.css.transform = transformStrings.join(' ');

        //console.log("publicai.active.lastStyleInStack.css.transform", publicai.active.lastStyleInStack.css.transform);

        if (refreshPanels) {
            css_transforms.createList();
        }
        applyCSS();
    },


    editTransform: function (target, transform) {
        let transformsPanel = $$$(document, "data-group", "transforms")

        let thePanels = ["skew", "rotate", "translate", "scale3d"]



        for (let item of Object.values(this.ui)) {
            item.classList.toggle("is_keyframes", (animator_timeline.mode == "keyframes"))
        }


        let panels = $$$$(transformsPanel, "animation-panel")
        for (let key of Object.keys(panels)) {
            css_transforms.manageProperty(key, panels[key])
            css_transforms.manageUnits(key, panels[key])
        }

        if (animator_timeline.mode == "keyframes") {
            for (let panel of thePanels) {
                $$$(transformsPanel, "animation-panel", panel).classList.add("active")
            }

        } else {
            css_utils.manageCombos(transformsPanel, css_transforms)
        }

    },

    manageUnits: function (key, panel) {
        if (key == "scale3d") {
            return
        }

        let unitsDropdown = $$$(panel, "transform_units")

        let rotateValues = [
            {
                "value": "DEG",
                "label": "deg"
            },
            {
                "value": "RAD",
                "label": "rad"
            },
            {
                "value": "TURN",
                "label": "turn"
            },
            {
                "value": "GRAD",
                "label": "grad"
            }
        ]

        let moveValues = [
            {
                "value": "PX",
                "label": "px"
            },
            {
                "value": "%",
                "label": "%"
            },
            {
                "value": "EM",
                "label": "em"
            },
            {
                "value": "REM",
                "label": "rem"
            },
            {
                "value": "VW",
                "label": "vw"
            },
            {
                "value": "VH",
                "label": "vh"
            },
        ]

        let values
        let existingValue = "px"

        if (key == "rotate3d") {
            values = rotateValues
            existingValue = css_transforms.transform?.rotateX?.unit || css_transforms.transform?.rotateY?.unit || css_transforms.transform?.rotateZ?.unit
        }

        if (key == "skew") {
            values = rotateValues
            existingValue = css_transforms.transform.skew[0].unit
        }

        if (key == "translate") {
            values = moveValues
            existingValue = css_transforms.transform.translateX[0].unit
        }

        for (let unit of unitsDropdown) {
            unit.textContent = existingValue

            css_utils.setup_reg_dropdown({
                "selector": unit,
                "value": existingValue,
                "multiSelect": false,
                "search": false,
                "values": values,
                "label": "In",
                "styles": ["auto", "horizontal", "bold", "no_input"],
                "customMode": true,
                "save": false,
                "callback": changeUnits,
                "direction": "up",
                "overlayColor": "black",
                "overlayOpacity": 0.12,
                "width": "100%"
            })
        }

        function changeUnits(s) {
            if (key == "rotate3d") {
                css_transforms.transform.rotateX[0].unit = s.value
                css_transforms.transform.rotateY[0].unit = s.value
                css_transforms.transform.rotateZ[0].unit = s.value
            }

            if (key == "skew") {
                css_transforms.transform.skew[0].unit = s.value
                css_transforms.transform.skew[1].unit = s.value
            }

            if (key == "translate") {
                css_transforms.transform.translateX[0].unit = s.value
                css_transforms.transform.translateY[0].unit = s.value
                css_transforms.transform.translateZ[0].unit = s.value
            }

            css_transforms.manageUnits(key, panel)
        }
    },


    getDefaultProperties: function () {

        let properties = {
            translateX: [{ value: 0, unit: '%' }],
            translateY: [{ value: 0, unit: '%' }],
            translateZ: [{ value: 0, unit: '%' }],
            rotateX: [{ value: 0, unit: 'deg' }],
            rotateY: [{ value: 0, unit: 'deg' }],
            rotateZ: [{ value: 0, unit: 'deg' }],
            scale3d: [{ value: 1, unit: '' }, { value: 1, unit: '' }, { value: 1, unit: '' }],
            skew: [{ value: 0, unit: 'deg' }, { value: 0, unit: 'deg' }]
        };

        return properties

    },

    manageProperty(key, panel) {
        let inputs = $$$$(panel, "group_input")

        let defaults = css_transforms.getDefaultProperties()

        let ranges = {
            translate: {
                "min": -300,
                "max": 300,
            },
            scale: {
                "min": 0,
                "max": 3,
            },
            rotate: {
                "min": 0,
                "max": 360,
            },
            skew: {
                "min": 0,
                "max": 360,
            }

        }

        function inputSetup(settings) {

            settings.range = ranges[settings.type]
            settings.selector.value = settings.object.value

            settings.selector.onchange = function () {
                settings.object.value = settings.selector.value
                settings.object.unit = settings.existingUnit || null
                updateLabel()
                settings.callback()
                updateKeyframes()
            }

            function updateKeyframes() {
                if (animator_timeline.mode == "keyframes") {
                    keyframes_editor.showKeyframesTimeline()
                }
            }


            function updateLabel() {
                let label = settings.container.querySelector(".reg_label")
                if (settings.object.value == settings.defaultValue) {
                    render.addClassesToAllChildrenOf(label, "hasvalue", false)
                } else {
                    render.addClassesToAllChildrenOf(label, "hasvalue", true)
                }

                label.onclick = function () {
                    settings.object.value = settings.defaultValue
                    settings.callback()
                    updateLabel()
                }
            }


            function sliderCallback(value, percentage) {

                settings.object.value = value
                settings.selector.value = value

                if (settings.type == "scale" && settings.lock) {
                    for (let item of css_transforms.transform.scale3d) {
                        item.value = value
                    }
                    css_transforms.manageProperty("scale3d", $$$(document, "animation-panel", "scale3d"))
                }

                settings.callback(false) //do not re-create the transform panel
                //updateLabel()
            }

            settings.sliderCallback = sliderCallback
            css_utils.standAloneSlider(settings)
            updateLabel()

        }

        if (key == "rotate") {
            let xValue = css_transforms.transform.rotateX[0]
            let yValue = css_transforms.transform.rotateY[0]
            let zValue = css_transforms.transform.rotateZ[0]


            let inputX = $$$(inputs.x, "group_value", "input")
            let inputY = $$$(inputs.y, "group_value", "input")
            let inputZ = $$$(inputs.z, "group_value", "input")

            let sliderX = $$$(inputs.x, "data-slider", "element")
            let sliderY = $$$(inputs.y, "data-slider", "element")
            let sliderZ = $$$(inputs.z, "data-slider", "element")



            inputs.x.value = xValue?.value || 0
            inputs.y.value = yValue?.value || 0
            inputs.z.value = zValue?.value || 0





            let existingUnit = css_transforms.transform.rotateX[0].unit

            inputSetup({
                container: inputs.x,
                selector: inputX,
                object: css_transforms.transform.rotateX[0],
                defaultValue: defaults.rotateX[0].value,
                callback: css_transforms.updateTransform,
                slider: sliderX,
                existingUnit: existingUnit,
                type: "rotate"
            })



            inputSetup({
                container: inputs.y,
                selector: inputY,
                object: css_transforms.transform.rotateY[0],
                callback: css_transforms.updateTransform,
                defaultValue: defaults.rotateY[0].value,
                slider: sliderY,
                existingUnit: existingUnit,
                type: "rotate"
            })


            inputSetup({
                container: inputs.z,
                selector: inputZ,
                object: css_transforms.transform.rotateZ[0],
                callback: css_transforms.updateTransform,
                defaultValue: defaults.rotateZ[0].value,
                slider: sliderZ,
                existingUnit: existingUnit,
                type: "rotate"
            })
        }

        function parseUnits(value) {

            let units = value.match(/[a-zA-Z%]+$/)?.[0] || '';

        }




        for (let inputKey of Object.keys(inputs)) {
            let value = css_transforms.transform[key]
            if (key == "translate") {
                let input = inputs[inputKey]
                let existingUnit = css_transforms.transform.translateX[0].unit


                let inputX = $$$(inputs.x, "group_value", "input")
                let inputY = $$$(inputs.y, "group_value", "input")
                let inputZ = $$$(inputs.z, "group_value", "input")

                let sliderX = $$$(inputs.x, "data-slider", "element")
                let sliderY = $$$(inputs.y, "data-slider", "element")
                let sliderZ = $$$(inputs.z, "data-slider", "element")

                if (inputKey == "x") {
                    inputSetup({
                        container: inputs.x,
                        selector: inputX,
                        object: css_transforms.transform.translateX[0],
                        callback: css_transforms.updateTransform,
                        slider: sliderX,
                        defaultValue: defaults.translateX[0].value,
                        existingUnit: existingUnit,
                        type: "translate"
                    })
                }

                if (inputKey == "y") {
                    inputSetup({
                        selector: inputY,
                        container: inputs.y,
                        object: css_transforms.transform.translateY[0],
                        callback: css_transforms.updateTransform,
                        defaultValue: defaults.translateY[0].value,
                        slider: sliderY,
                        existingUnit: existingUnit,
                        type: "translate"
                    })

                }

                if (inputKey == "z") {
                    inputSetup({
                        selector: inputZ,
                        container: inputs.z,
                        object: css_transforms.transform.translateZ[0],
                        callback: css_transforms.updateTransform,
                        defaultValue: defaults.translateZ[0].value,
                        slider: sliderZ,
                        existingUnit: existingUnit,
                        type: "translate"
                    })
                }
            }

            if (key == "scale3d") {
                let input = inputs[inputKey]

                let inputX = $$$(inputs.x, "group_value", "input")
                let inputY = $$$(inputs.y, "group_value", "input")
                let inputZ = $$$(inputs.z, "group_value", "input")

                let sliderX = $$$(inputs.x, "data-slider", "element")
                let sliderY = $$$(inputs.y, "data-slider", "element")
                let sliderZ = $$$(inputs.z, "data-slider", "element")

                let existingUnit = css_transforms.transform.scale3d[0].unit
                if (value && value[0] && inputKey == "x") {

                    inputSetup({
                        selector: inputX,
                        container: inputs.x,
                        object: css_transforms.transform.scale3d[0],
                        callback: css_transforms.updateTransform,
                        defaultValue: defaults.scale3d[0].value,
                        slider: sliderX,
                        existingUnit: existingUnit,
                        type: "scale",
                        lock: true
                    })

                }

                if (inputKey == "y") {


                    inputSetup({
                        selector: inputY,
                        container: inputs.y,
                        object: css_transforms.transform.scale3d[1],
                        callback: css_transforms.updateTransform,
                        defaultValue: defaults.scale3d[1].value,
                        slider: sliderY,
                        existingUnit: existingUnit,
                        type: "scale",
                        lock: true
                    })
                }

                if (inputKey == "z") {
                    inputSetup({
                        selector: inputZ,
                        container: inputs.z,
                        object: css_transforms.transform.scale3d[2],
                        callback: css_transforms.updateTransform,
                        defaultValue: defaults.scale3d[2].value,
                        slider: sliderZ,
                        existingUnit: existingUnit,
                        type: "scale",
                        lock: true
                    })
                }
            }

            if (key == "skew") {
                let input = inputs[inputKey]

                let inputX = $$$(inputs.x, "group_value", "input")
                let inputY = $$$(inputs.y, "group_value", "input")

                let sliderX = $$$(inputs.x, "data-slider", "element")
                let sliderY = $$$(inputs.y, "data-slider", "element")

                if (value && value[0] && inputKey == "x") {

                    inputSetup({
                        selector: inputX,
                        container: inputs.x,
                        object: css_transforms.transform.skew[0],
                        callback: css_transforms.updateTransform,
                        defaultValue: defaults.skew[0].value,
                        slider: sliderX,
                        type: "skew"
                        //existingUnit: existingUnit
                    })
                }

                if (value && value[1] && inputKey == "y") {
                    inputSetup({
                        selector: inputX,
                        container: inputs.y,
                        object: css_transforms.transform.skew[1],
                        callback: css_transforms.updateTransform,
                        defaultValue: defaults.skew[1].value,
                        slider: sliderY,
                        type: "skew"
                        //existingUnit: existingUnit
                    })
                }
            }
        }
    },

    checkValuesFromAncestors: function (settings) {
        let waterFallClassFound = false
        let acestorClassFound = false
        let inheritValuesFromAncestors = false
        let waterfalledValues = []

        let existingCssValue = null
        if (css_utils.inheritance[settings.variable]) {
            inheritValuesFromAncestors = true
        }

        if (publicai.active.lastStyleInStack.css[settings.variable]) {
            existingCssValue = publicai.active.lastStyleInStack.css[settings.variable];
        }

        for (let css of publicai.active.cssStack) {
            if (css.css[settings.variable]) {
                if (publicai.active.lastStyleInStack.name != css.name) {
                    waterfalledValues.push(css.css[settings.variable])
                    waterFallClassFound = true
                }
            }
        }

        let response = {
            "waterFallClassFound": waterFallClassFound,
            "acestorClassFound": acestorClassFound,
            "inheritValuesFromAncestors": inheritValuesFromAncestors,
            "existingCssValue": existingCssValue,
            "waterfalledValues": waterfalledValues
        }

        return (response)
    },
}
