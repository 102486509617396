import { FileUpload } from '../../services/files/upload_frontend.js';
import { FileManager } from '../../services/files/FileManager.js';
import { LineItems } from '../../modules/utils/line_items.js';


export function init() {
    //console.log("fonts init")
    uploadSetup()
    window.getFileList = getFileList

    getFileList()

}


async function getFileList() {
    console.log("getting file list")
    try {
        const fileManager = new FileManager('/api');

        const cssFiles = await fileManager.listFiles({
            type: 'stylesheet'
        });
        //console.log("cssFiles", cssFiles);

        //createFontsList(cssFiles.files)
        refreshLineItems(cssFiles.files)

        // List all image files
        const fontFiles = await fileManager.listFiles({
            type: 'font'  // Simplified filters for testing
        });
        //console.log("fontFiles", fontFiles);

        // List all image files
       /*  const imageFiles = await fileManager.listFiles({
            type: 'image'  // Simplified filters for testing
        });
        console.log("imageFiles", imageFiles);

        // List video files
        const videoFiles = await fileManager.listFiles({
            type: 'video'  // Simplified filters for testing
        });
        console.log("videoFiles", videoFiles); */

    } catch (error) {
        console.error("Error getting file list:", error);
    }
}





function uploadSetup() {

    //let elements = $$$$(document, "role_upload")
    //console.log("upload elements", elements)
    const fileUpload = new FileUpload({
        area: app.ui.settings.content.upload,
        uploadEndpoint: '/api/upload',
        //extensions: ['png', 'jpg', 'jpeg', 'mp4', 'ttf', 'otf', 'woff', 'woff2'],
        extensions: ['ttf', 'otf', 'woff', 'woff2'],

        onFileSuccess: (data) => {
            // Show success message, update UI with file details
            console.log(`${data.fileName} uploaded to ${data.s3Key}`);
        },
        onFileError: (data) => {
            // Show error message
            console.error(`Failed to upload ${data.fileName}`);
        },
        onComplete: (data) => {
            // Show completion message
            console.log(`Uploaded ${data.successfulFiles} of ${data.totalFiles} files`);
        },
        onError: (error) => {
            // Show error message
            console.error('Upload failed:', error);
        },
        onProgress: (data) => {
            console.log(`
                File: ${data.fileName}
                Progress: ${data.fileProgress.toFixed(2)}%
                Speed: ${data.speed.toFixed(2)} MB/s
                Overall: ${data.totalProgress.toFixed(2)}%
                Average Speed: ${(data.averageSpeed / (1024 * 1024)).toFixed(2)} MB/s
                Uploaded: ${(data.uploadedSize / (1024 * 1024)).toFixed(2)}/${(data.totalSize / (1024 * 1024)).toFixed(2)} MB
            `);
        }

    });

}


function dataMap(item, el) {
    let created = new Date(item.uploadedAt)
    el.label.textContent = item.fontFamily
    el.date.textContent = created.toLocaleString()
    el.details.textContent = item.style
}



function openFont(project) {
    console.log("Open project", project)
    window.router.navigateTo("/editor/" + project.id + "/")
}



function refreshLineItems(data) {

    let lines = new LineItems({
        data: data,
        container: app.ui.settings.content.fonts_container,
        line_item: nwsapp.comp.project_item,
        dataMap: dataMap,
        events: {
            doubleClick: openFont,
        },
        more: {
            callback: callback,
            closeCallback: closeCallback,

            keys: {
                enter: closeCallback,
                escape: closeCallback,
            },
            values: [
                {
                    value: "edit",
                    label: "Edit",
                    icon: "edit"
                },
                {
                    value: "preview",
                    label: "Preview",
                    icon: "preview"
                },
                {
                    value: "delete",
                    label: "Delete",
                    icon: "delete"
                },
                {
                    value: "label",
                    label: "Rename ",
                    type: "input",
                    inputSettings: manageitemName,
                    //item: item
                },

            ]
        }
    })

}


function closeCallback() {
    nwsapp.dropdownIsOpen = false

}

async function callback(settings, more) {

    if (settings.value == "edit") {
        window.router.navigateTo("/editor/" + settings.item.id)
    }
    if (settings.value == "preview") {
        console.log("Preview")
    }
    if (settings.value == "delete") {
        console.log("Delete")
        await deleteProject(settings.item.id)
        //recreate list of projects
        /*      loadAllProjects()
                 .then(projects => {
                     console.log("Projects loaded successfully:", projects);
                     nwsapp.projects = projects
                     //addProjects(projects);
                     refreshLineItems(projects)
                 }) */
    }

}

function manageitemName(menuItem, settings, menu, item) {

    return
    //manages the input settings for the label via Menu constructor
    console.log("MANAGE", settings)

    let elements = $$$$(menuItem, "input_el")

    elements.input.placeholder = "Enter Name...."
    elements.input.value = item.item.name

    elements.label.textContent = "Rename"
    elements.comment.textContent = "Rename the current item name."


    elements.button_label.textContent = "Rename"




    elements.button.onclick = function () {
        menu.destroy()
    }

    elements.input.focus()
}