export class FileUpload {
    constructor(settings) {
        this.settings = settings;
        this.uploadArea = settings.area;
        this.uploadButton = settings.button; // New upload button element
        this.uploadEndpoint = settings.uploadEndpoint;
        this.allowedExtensions = settings.extensions;
        this.type = settings.type; // New type parameter
        this.projectId = settings.projectId;  // New projectId parameter


        this.setupUploadArea();
        this.setupUploadButton(); // Setup the button upload
    }

    setupUploadArea() {
        const dropArea = this.uploadArea;
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.multiple = true;

        // Click and drag events for the drop area
        dropArea.addEventListener('click', () => fileInput.click());
        fileInput.addEventListener('change', (e) => this.handleFiles(e.target.files));
        dropArea.addEventListener('dragover', (e) => {
            e.preventDefault();
            dropArea.classList.add('drag-over');
        });
        dropArea.addEventListener('dragleave', (e) => {
            e.preventDefault();
            dropArea.classList.remove('drag-over');
        });
        dropArea.addEventListener('drop', (e) => {
            e.preventDefault();
            dropArea.classList.remove('drag-over');
            this.handleFiles(e.dataTransfer.files);
        });
    }

    setupUploadButton() {
        if (!this.uploadButton) return;

        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.multiple = true;

        // Event listener for button click

        this.uploadButton.onclick = (e) => {
            fileInput.click()
            e.stopPropagation()
            e.preventDefault()
        }

        fileInput.addEventListener('change', (e) => this.handleFiles(e.target.files));
    }

    handleFiles(files) {
        const validFiles = [];
        const invalidFiles = [];

        for (const file of files) {
            const extension = file.name.split('.').pop().toLowerCase();
            if (this.allowedExtensions.includes(extension)) {
                validFiles.push(file);
            } else {
                invalidFiles.push(file.name);
            }
        }

        if (invalidFiles.length > 0) {
            alert(`The following files are not allowed: ${invalidFiles.join(', ')}`);
        }

        if (validFiles.length > 0) {
            this.uploadFiles(validFiles);
        }
    }

    uploadFileWithXHR(file, uploadId, fileStartTime, totalProgress, type) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            const formData = new FormData();

            // Increase chunk size for larger files
            const isMultipart = file.size > 50 * 1024 * 1024;
            const endpoint = isMultipart
                ? `${this.uploadEndpoint}/multipart?uploadId=${uploadId}`
                : `${this.uploadEndpoint}?uploadId=${uploadId}`;

            formData.append(isMultipart ? 'file' : 'files', file);
            formData.append('type', type); // Add type to formData
            if (this.projectId) {
                formData.append('projectId', this.projectId);  // Add projectId to formData if available
            }

            // Add upload options to formData for multipart uploads
            if (isMultipart) {
                formData.append('options', JSON.stringify({
                    partSize: 20 * 1024 * 1024, // 20MB chunks
                    queueSize: 4 // Concurrent uploads
                }));
            }

            xhr.upload.onprogress = (progressEvent) => {
                if (progressEvent.lengthComputable) {
                    const currentTime = Date.now();
                    const elapsedTime = (currentTime - fileStartTime) / 1000;
                    const instantSpeed = progressEvent.loaded / elapsedTime;

                    if (!this.speedSamples) this.speedSamples = [];
                    this.speedSamples.push(instantSpeed);
                    if (this.speedSamples.length > 5) this.speedSamples.shift();
                    const averageSpeed = this.speedSamples.reduce((a, b) => a + b) / this.speedSamples.length;

                    const fileProgress = (progressEvent.loaded / progressEvent.total) * 100;

                    // Update total progress
                    totalProgress.loaded += progressEvent.loaded - totalProgress.lastLoaded;
                    totalProgress.lastLoaded = progressEvent.loaded;
                    const totalProgressPercent = (totalProgress.loaded / totalProgress.total) * 100;

                    if (this.settings.onProgress) {
                        this.settings.onProgress({
                            fileName: file.name,
                            fileSize: file.size,
                            fileProgress,
                            totalProgress: totalProgressPercent,
                            speed: (averageSpeed / (1024 * 1024)), // MB/s
                            loaded: progressEvent.loaded,
                            total: progressEvent.total,
                            totalLoaded: totalProgress.loaded,
                            totalSize: totalProgress.total
                        });
                    }
                }
            };

            xhr.onload = () => {
                if (xhr.status === 200) {
                    try {
                        const response = JSON.parse(xhr.responseText);
                        console.log(`Upload complete for ${file.name}:`, response);
                        resolve(response);
                    } catch (error) {
                        reject(new Error('Invalid server response'));
                    }
                } else {
                    reject(new Error(`Upload failed with status ${xhr.status}`));
                }
            };

            xhr.onerror = () => {
                reject(new Error('Network error during upload'));
            };

            xhr.open('POST', endpoint);
            xhr.send(formData);
        });
    }


    async uploadFiles(files) {
        const uploadId = Date.now().toString();
        const totalSize = files.reduce((acc, file) => acc + file.size, 0);
        const totalProgress = {
            loaded: 0,
            lastLoaded: 0,
            total: totalSize
        };

        console.log(`Starting upload of ${files.length} files, total size: ${(totalSize / (1024 * 1024)).toFixed(2)} MB`);

        try {
            // Set up SSE for server-side progress
            const eventSource = new EventSource(`${this.uploadEndpoint}/progress/${uploadId}`);

            eventSource.onmessage = (event) => {
                if (event.data === ': keepalive') return;

                try {
                    const data = JSON.parse(event.data);
                    console.log('Server progress update:', data);

                    // Handle different types of server messages
                    switch (data.type) {
                        case 'progress':
                            if (this.settings.onServerProgress) {
                                this.settings.onServerProgress(data);
                            }
                            break;
                        case 'success':
                            if (this.settings.onFileSuccess) {
                                this.settings.onFileSuccess(data);
                            }
                            break;
                        case 'error':
                            if (this.settings.onFileError) {
                                this.settings.onFileError(data);
                            }
                            break;
                        case 'complete':
                            if (this.settings.onComplete) {
                                this.settings.onComplete(data);
                            }
                            break;
                    }
                } catch (error) {
                    console.error('Error parsing server message:', error);
                }
            };

            // Process files sequentially
            for (const file of files) {
                const fileStartTime = Date.now();
                try {
                    await this.uploadFileWithXHR(file, uploadId, fileStartTime, totalProgress, this.type);
                } catch (error) {
                    console.error(`Error uploading ${file.name}:`, error);
                    if (this.settings.onError) {
                        this.settings.onError({
                            fileName: file.name,
                            error: error.message
                        });
                    }
                }
            }

            // Close SSE connection after all files are processed
            eventSource.close();

        } catch (error) {
            console.error('Error in upload process:', error);
            if (this.settings.onError) {
                this.settings.onError(error);
            }
        }
    }
}
