//adds selection container and other design helpers. 

export var _create = {
    currentDragElement: null,

    addSelectorContainer: function () {



        //absolute container floating on top of the iframe, holding selector indicators

        if (nws.isPlayer && !nws.edit) { return }


        let selectionContainerLocation = document
        if (nws.isPlayer) {
            selectionContainerLocation = document2
        }

        //console.log(selectionContainerLocation)

        let container = _utils.getSelectionContainer(selectionContainerLocation)

        if (nws.isPlayer) {
            //timing is important in this case
            let container = _utils.getSelectionContainer(document2)
            publicai.active.bodyElement.appendChild(container)
            publicai.active.selectorContainer = document2.querySelector(".selector_container")
        }


        if (nws.isPlayer) {
            //let location = $$$(document, "publicai", "body")
            //public.active.bodyElement.insertBefore(c)
        } else {
            publicai.sceneContainer.appendChild(container)
            publicai.active.selectorContainer = container
        }

        //console.log("adding selection container", publicai.active.selectorContainer)
    },


    //refreshSelectionContainer


    createTimelineElements: function () {

        //console.log("refresh timeline")

    },

    deselectAllElements: function (caller) {

        render.addClassesToAllChildrenOf(publicai.active.bodyElement, ["public-active", "public-active-body"], false)

        if (typeof document2 != "undefined") {

            document2.querySelectorAll(".public_div_label").forEach(e => e.remove());
            $$$(document2, "public-rollover").forEach(e => e.remove());
            $$$(document2, "public-select").forEach(e => e.remove());
            $$$(document2, "public-span").forEach(e => e.remove());

        }

        $$$(document, "public-rollover").forEach(e => e.remove());
        $$$(document, "public-select").forEach(e => e.remove());
        $$$(document, "public-span").forEach(e => e.remove());



    },

    addSelectionContainer: function (el, entry, type, selectionRect = null) {


        if (publicai.isEditingText) {
            //return
        }

        if (nwsapp.isDragging) { return }


        if (type == "select") {
            //console.error("selection container")
        }


        if (nws.isPlayer && !nws.edit) { return; }

        //console.log("adding selection container")

        if (type == "span") {
            console.log("is span...")
            _create.deselectAllElements();
        }

        if (!el || !publicai.active?.iframe?.ownerDocument) {
            return;
        }


        let rect;
        if (!selectionRect) {
            rect = el.getBoundingClientRect();
        } else {
            rect = selectionRect;
        }

        let roundedLeft = Math.round(rect.left);
        let roundedTop = Math.round(rect.top);
        let roundedWidth = Math.round(rect.width);
        let roundedHeight = Math.round(rect.height);

        if (!entry) {
            console.log("no entry found for ", el, entry, type);
        }

        if (!publicai.selected || !publicai.selected.settings) {
            return;
        }

        if (entry.id == publicai.selected.settings.id && type === "rollover") {
            return;
        }

        let selectionContainer = addWhatWhere(nwsapp.comp.selections, publicai.active.selectorContainer);
        selectionContainer.setAttribute("object_select", "true");
        publicai.active.selectionContainer = selectionContainer;

        if (type == "span") {
            //console.log("rect: ", rect, roundedWidth, roundedHeight)
            //console.log("adding", type, selectionContainer, publicai.isEditingText)
        }

        if (type != "rollover") {
            _create.updateContextualEditButtons();
        }

        selectionContainer.style.pointerEvents = "none";
        selectionContainer.style.position = "absolute";
        selectionContainer.style.transform = `translate(${roundedLeft}px, ${roundedTop}px)`;
        selectionContainer.style.width = `${roundedWidth}px`;
        selectionContainer.style.height = `${roundedHeight}px`;
        let frameRequested = false;



        const updatePosition = () => {
            let currentRect = el.getBoundingClientRect();
            let scrollX = window.scrollX;
            let scrollY = window.scrollY;

            let currentRoundedLeft = Math.round(currentRect.left + scrollX);
            let currentRoundedTop = Math.round(currentRect.top + scrollY);
            let currentRoundedWidth = Math.round(currentRect.width);
            let currentRoundedHeight = Math.round(currentRect.height);

            selectionContainer.style.transform = `translate(${currentRoundedLeft}px, ${currentRoundedTop}px)`;
            selectionContainer.style.width = `${currentRoundedWidth}px`;
            selectionContainer.style.height = `${currentRoundedHeight}px`;
        };


        let updatePosition_old = () => {
            if (!publicai.active?.iframe?.contentWindow) {
                return;
            }

            if (type == "span") {
                return;
            }

            if (!document.body.contains(selectionContainer)) {
                //console.log('selectionContainer no longer exists, cleaning up observers');
                resizeObserver.disconnect();
                iframeDocument.defaultView.removeEventListener('scroll', scrollListener);
                window.removeEventListener('resize', parentResizeListener);
                extendedMutationObserver.disconnect();
                return;
            }

            if (!frameRequested) {
                frameRequested = true;
                requestAnimationFrame(() => {
                    let currentRect = el.getBoundingClientRect();
                    let scrollX = publicai.active.iframe.contentWindow.scrollX;
                    let scrollY = publicai.active.iframe.contentWindow.scrollY;

                    let currentRoundedLeft = Math.round(currentRect.left + scrollX);
                    let currentRoundedTop = Math.round(currentRect.top + scrollY);
                    let currentRoundedWidth = Math.round(currentRect.width);
                    let currentRoundedHeight = Math.round(currentRect.height);
                    selectionContainer.style.transform = `translate(${currentRoundedLeft}px, ${currentRoundedTop}px)`;
                    selectionContainer.style.width = `${currentRoundedWidth}px`;
                    selectionContainer.style.height = `${currentRoundedHeight}px`;
                    frameRequested = false;
                    console.log("currentRoundedTop", currentRoundedTop, scrollY)
                });
            }
        };

        // Use a unique key to avoid overwriting observers
        let observerKey = `${el}_${type}`;
        if (publicai.active[observerKey]?.resizeObserver) {
            publicai.active[observerKey].resizeObserver.disconnect();
        }

        let resizeObserver = new ResizeObserver(() => {
            updatePosition();
        });

        if (!publicai.observers) { publicai.observers = {}; }



        resizeObserver.observe(el);
        publicai.active[observerKey] = { resizeObserver };


        let iframeDocument = publicai.active.iframe.contentDocument;

        resizeObserver.observe(iframeDocument.body);


        if (!iframeDocument) {
            console.log("no iframe document found.", el);
            return;
        }

        let scrollListener = () => {
            updatePosition();
        };

        let parentResizeListener = () => {
            updatePosition();
        };

        PubSub.subscribe('scroll', scrollListener);

        let extendedMutationObserver = new MutationObserver(mutations => {
            for (let mutation of mutations) {
                if (mutation.type === 'childList') {
                    let removedNodesArray = Array.from(mutation.removedNodes);
                    removedNodesArray.forEach(removedNode => {
                        if (removedNode.contains(selectionContainer)) {
                            resizeObserver.disconnect();
                            iframeDocument.defaultView.removeEventListener('scroll', scrollListener);
                            window.removeEventListener('resize', parentResizeListener);
                            extendedMutationObserver.disconnect();
                        }
                    });
                }
            }
        });

        extendedMutationObserver.observe(iframeDocument.body, { childList: true, subtree: true });

        if (type === "span") {
            selectionContainer.setAttribute("public-span", entry.id);
        }

        if (type === "rollover") {
            selectionContainer.setAttribute("public-rollover", entry.id);
            selectionContainer.classList.add("over");
            publicai.selected.hoverElement = selectionContainer;
            _create.addDivLabel(selectionContainer, entry, "rollover", el, type);

            //console.log("selectionContainer", selectionContainer)
        }

        if (type === "select") {
            selectionContainer.setAttribute("public-select", entry.id);
            publicai.selected.selectionContainer = selectionContainer;
            _create.addDivLabel(selectionContainer, entry, null, el, type);

            _controls.init(selectionContainer)
            //console.log(publicai.active?.lastStyleInStack)
        }

        if (!nws.isPlayer) {
            _create.highlightElementIfBrandNew();
        }

        if ((publicai.selected.settings?.dynamic?.schema || publicai.selected.settings?.dynamic?.enabled) && type === "select") {
            render.addClassesToAllChildrenOf(selectionContainer, "is_dynamic", true);
        }
    },



    forceReHandle: function (node) {
        //re-runs the type handler to refresh dynamic settings

        //console.log("rehandle ", node.classes[0].name)

        if (_handlers[node.type]) {

            //console.log("found handler")

            let existingElement = $$$(nwsapp.activeDocument, "public_id", node.id)

            if (existingElement) {
                //console.log("YES>>>  ", node.classes[0].name)
                _handlers[node.type](existingElement, node, null, true) //adds text, media, etc
            }
        }
    },


    removeAllGhosts: function () {
        //ghosts are elements that are non editable but they present collection elements IRL
        let allGhosts = $$$(publicai.active.bodyElement, "public_ghost")
        for (let ghost of allGhosts) {
            ghost.remove()
        }

    },

    duplicateElement: function (settings) {
        //duplicates the original element
        let clone = addWhatWhere(settings.element, settings.container)
        render.addClassesToAllChildrenOf(clone, "is_ghost", true)
        let originalID = settings.element.getAttribute("public_id")
        clone.setAttribute("public_ghost", originalID)
        clone.setAttribute("public_id", "ghost")
        clone.setAttribute("ghost_index", settings.index)
        //console.log("have cloned based on node", settings.index)
        //render.addClassesToAllChildrenOf(clone, "ghost_index", )

        setAttributeToAllChildren(clone, "ghost_index", settings.index)

        //console.log("have added ", clone)

    },

    addContentToGhosts: function () {

        let allGhostsElements = $$$(publicai.active.bodyElement, "data-dynamic_schema")
        for (let ghost of allGhostsElements) {

            let schema = ghost.dataset.dynamic_schema
            let element = ghost.dataset.dynamic_element
            let id = ghost.getAttribute("public_id")
            //console.log("ghost", ghost, type, schema, element, id)
            let node = _find.elementByID(publicai.active.page, id)
            let index = ghost.getAttribute("ghost_index")
            //let index = findAncestorWithAttribute(ghost, "ghost_index")

            //console.log("calling handler", ghost, index, node.type)
            if (index && ghost.classList.contains("is_ghost")) {
                console.log("found ghost...")
                _handlers[node.type](ghost, node, index) //adds text, media, etc
            }
            //let node = _find.elementByID(publicai.active.page, ghost.getAttribute("public_ghost"))
        }

    },


    getCollection: function (id) {

        let schema = publicai.dataSources.schemas[id]
        let collection = publicai.dataEntries[id]
        if (collection) {
            return (collection)
        } else {
            return (null)
        }
    },

    highlightElementIfBrandNew: function (node, existingElement) {

        //adds the public_empty class if the elment has no content, no min width, no min height and no border - so that it's easy to select it



        if (!node) {
            //if none given, it's called when dealing with selecte element
            node = publicai.selected.settings
        }

        if (!existingElement) {
            existingElement = publicai.selected.element
        }

        if (!publicai.selected.element) {
            return
        }


        if (node.classes.length > 0) {
            //class may have been deleted from the class panel

            let className = node.classes[0].name
            let styleObj = publicai.css[className]


            //console.log("checking if brand new element", node, existingElement)

            if (publicai.selected.settings.type == "text") {
                return
            }

            //checks if scene element needs to be highlighted using min width, height and border

            if (node.type == "span") {
                return
            }

            if (node.type == "body" || className == "") {
                //console.log("it's a scene element")
                existingElement.classList.remove("public_empty")
                return
            }
            //check if it has children
            if (node.content && Array.isArray(node.content) && node.content.length > 0) {
                //do not highlight if it has children.
                //console.log("it has children")
                existingElement.classList.remove("public_empty")
                return
            }


            //console.log("looking up", existingElement, className, node)



            function shouldThisBeHighlighted(styleObj) {

                if (!styleObj) {
                    console.log("!style object is missing.")
                    return (false)
                }

                if (Object.keys(styleObj).length === 0) {
                    //console.log("^^yes")
                    return (true)
                }
                if (styleObj.width || styleObj.height || styleObj.minWidth || styleObj.minHeight || styleObj.border) {
                    //console.log("^^no", styleObj)
                    return (false)
                }

                return (true)

            }


            if (shouldThisBeHighlighted(styleObj)) {
                existingElement.classList.add("public_empty")
            } else {
                existingElement.classList.remove("public_empty")
            }

        }
    },








    pasteTimelineElement: function (pasteAtCurrentTime = false, shiftKey) {

        console.log("shift key", shiftKey)


        let clipboardData = JSON.parse(JSON.stringify(nwsapp.clipboard))
        let el = clipboardData.content

        if (clipboardData.scope != publicai.active.rootFolder.id) {
            //saving into a different folder. Creating new classes there. 
            _create.foundClasses = {}
            _create.transitions = {}
            _create.addClassesFromNode(el, clipboardData.scope) //save the classes of the node
            _create.getAllClassesForNode(el, clipboardData.scope) //saves the classes of the children

            let classesToBeAdded = {}

            for (let theClass of Object.keys(_create.foundClasses)) {

                let validatedClass

                if (publicai.css[theClass]) {
                    validatedClass = css_classes.validateClassName(theClass, null)
                } else {
                    validatedClass = theClass
                }

                if (validatedClass != theClass) {

                    console.log("manage conflict for ", theClass, validatedClass)

                    //need to rename the combos as well now
                    let keys = Object.keys(_create.foundClasses)
                    for (let key of keys) {
                        if (key.includes(theClass)) {
                            let newKey = key.replace(theClass, validatedClass)
                            _create.foundClasses[newKey] = _create.foundClasses[key]
                            delete _create.foundClasses[key]
                        }
                    }
                    _create.performClassesRenameOnNode(el, theClass, validatedClass)

                }
                //do not add the classes right into the css, as it will result in conflicts for future validatations
                classesToBeAdded[validatedClass] = _create.foundClasses[theClass]
            }

            for (let key of Object.keys(classesToBeAdded)) {
                publicai.css[key] = classesToBeAdded[key]
            }


            //add classes and manage conflicts
        }

        if (shiftKey) {
            //paste special. Make a copy and increase start by 30ms
            let label = generateShortId()
            css_classes.saveTheClass(label)
        }



        updateSceneIds(el)

        el.ancestor = publicai.selected.settings.id

        console.log("pasting", el)

        let insertAfter = null

        if (el.backupAncestor == publicai.selected.settings.id) {
            // Lookup the original element it was copied from
            let originalElement = publicai.selected.settings.content.find(e => e.id == el.backupId);

            // Find index of original element
            insertAfter = publicai.selected.settings.content.indexOf(originalElement);
            console.log("original index is ", insertAfter);
        }

        // Ensure insertAfter is valid
        if (insertAfter !== -1) {
            //public.selected.settings.content.splice(insertAfter + 1, 0, el); // Insert after the found element
            publicai.selected.settings.content.push(el); // Append if original element is not found
        } else {
            publicai.selected.settings.content.push(el); // Append if original element is not found
        }



        //find index 
        //let original 
        //nwsapp.clipboard.content.backupId

        if (pasteAtCurrentTime) {
            //not yet implemented
            el.settings.timing.start = publicai.currentTime
            if (el.settings.timing.end && el.settings.timing.end != 0) {
                el.settings.timing.end = el.settings.timing.start + el.settings.timing.duration
            }
        }

        //console.log("pasting", el)



        play_interactive.createPageElements(publicai.selected.settings.content, publicai.selected.settings.id)
        play_interactive.renderInteractiveComponents()



        _create.activateSpecificElement(el.ancestor)
        css_management.manage()
        animator_timeline.init()

    },


    findAllCombosFor(class_name, sourceId) {
        let foundCombos = []

        let css = publicai.globalCss[sourceId]

        for (let item of Object.keys(css)) {
            if (item.includes(class_name + publicai.classSeparator)) {
                foundCombos.push(item)
            }

        }

        return (foundCombos)
    },

    addClassesFromNode: function (node, sourceId) {
        let css = publicai.globalCss[sourceId]

        for (let className of node.classes) {
            _create.foundClasses[className.name] = className.name
            _create.foundClasses[className.name] = { ...css[className.name] }

            let classCSS = css[className.name]


            //retrieves all combos available
            let allCombosForThisClass = _create.findAllCombosFor(className.name, sourceId)

            for (let combo of allCombosForThisClass) {
                _create.foundClasses[combo] = combo
                _create.foundClasses[combo] = JSON.parse(JSON.stringify(css[combo]))

                let comboCSS = css[combo]


            }
        }
    },

    getAllClassesForNode(node, sourceId) {
        // If the scene has children, update their ancestor IDs and recursively update their IDs
        if (node.content && node.content.length > 0) {
            for (let child of node.content) {
                _create.addClassesFromNode(child, sourceId)
                //calls itself for all children
                _create.getAllClassesForNode(child, sourceId) // Recursively update child IDs
            }
        }
    },


    performClassesRenameOnNode(node, oldName, newName) {

        //console.log("looking up all entries of", oldName, "and renaming to ", newName)

        if (node.classes) {
            if (node.classes[0].name == oldName) {
                console.log("found at", node, "renaming to", newName, "from", oldName)
                node.classes[0].name = newName
            }

            for (let combo of node.classes[0].combo) {
                if (combo == oldName) {
                    node.classes[0].combo[node.classes[0].combo.indexOf(combo)] = newName
                }
            }

        }


        if (node.content && node.content.length > 0) {
            for (let child of node.content) {
                //calls itself for all children

                if (child.classes) {
                    if (child.classes[0].name == oldName) {
                        console.log("found at", child, "renaming to", newName, "from", oldName)
                        child.classes[0].name = newName
                    }

                    for (let combo of child.classes[0].combo) {
                        if (combo == oldName) {
                            child.classes[0].combo[child.classes[0].combo.indexOf(combo)] = newName
                        }
                    }

                    _create.performClassesRenameOnNode(child, oldName, newName) // Recursively update child IDs
                }
            }
        }
    },





    getClassesForNode: function (node, scopeId, saveTo) {

        console.log("checking", node)


        let css = publicai.globalCss[scopeId]


        for (let className of node.classes) {
            saveTo[className.name] = className.name
            saveTo[className.name] = { ...css[className.name] }

            let classCSS = css[className.name]


            //retrieves all combos available
            let allCombosForThisClass = _create.getClassesForNode(className.name, scopeId, saveTo)

            for (let combo of allCombosForThisClass) {
                saveTo[combo] = combo
                saveTo[combo] = JSON.parse(JSON.stringify(css[combo]))



            }
        }
    },




    removeDeletedElements: function () {
        let allPageElements = $$$(nwsapp.activeDocument.documentElement, "public_id")
        for (let el of allPageElements) {
            let id = el.getAttribute("public_id")
            let foundElement = _find.elementByID(publicai.active.page, id)
            if (!foundElement) {
                el.remove()
            }
        }
    },


    refreshContent: function () {
        //re-creates the content from one level above the selected element
        if (!publicai.selected.settings.ancestor) {
            //current element is the body
            _create.enableSelectionAndScrollListeners(publicai.active.bodyElement, publicai.active.body)
        } else {
            let ancestor = $$$(nwsapp.activeDocument, "public_id", publicai.selected.settings.ancestor)
            let ancestorSettings = _find.elementByID(publicai.active.page, publicai.selected.settings.ancestor)
            _create.enableSelectionAndScrollListeners(ancestor, ancestorSettings)
        }
        _create.removeDeletedElements()
    },




    activateSpecificElement: function (id) {




        _create.deselectAllElements(3)
        publicai.selected.id = id
        publicai.selected.element = $$$(nwsapp.activeDocument, "public_id", publicai.selected.id)
        publicai.selected.settings = _find.elementByID(publicai.active.page, id)

        //console.log("activating", publicai.selected.element, publicai.selected.settings)

        if (animator_timeline.mode == "keyframes") {
            //prevent applyCSS from firing an animation refresh, while object has changed. 
            animator_timeline.mode = "children"
        }

        css_management.manage()
        _create.addSelectionContainer(publicai.selected.element, publicai.selected.settings, "select")


        _create.highlightElementIfBrandNew() // Highlights the element if it's brand new


    },

    refreshSelectionContainer: function () {
        if (!publicai.active.selectorContainer) {
            return
        }

        _create.deselectAllElements(2)
        _create.addSelectionContainer(publicai.selected.element, publicai.selected.settings, "select")


    },


    updateContextualEditButtons: function () {

        //manages the buttons at the top of the page.



        if (!publicai.selected.settings || nws.isPlayer) {
            return
        }

        let inherited = _find.getAncestorWithSchema(publicai.selected.settings.id)
        //console.log(inherited)
        if (inherited && inherited != null) {
            editor.ui.editButton.classList.remove("hidden")
            editor.ui.editLabel.textContent = "Edit Schema"
            editor.ui.editIcon.textContent = "discover_tune"
        } else {
            editor.ui.editButton.classList.add("hidden")
        }


        if (publicai.active.page.properties.role == "schema_index") {
            editor.ui.collectionEntry.classList.remove("hidden")
            editor.ui.collectionLabel.textContent = "Edit Schema"
            editor.ui.buttonIcon.textContent = "discover_tune"

            editor.ui.collectionEntry.onclick = function () {
                window.openEditorPath('/' + "schema" + "/" + publicai.active.page.dynamic.schema);
            }
        }


        if (publicai.active.page.dynamic.schema) {

            //if (publicai.active.page.properties.role == "prompt") {
            editor.ui.collectionEntry.classList.remove("hidden")
            editor.ui.collectionLabel.textContent = "Manage Entries"
            //this is using the same button as the collection entry dropdown, but it's chaning the icon
            editor.ui.buttonIcon.textContent = "discover_tune"

            let schemaId = publicai.active.page.dynamic.schema
            let promptId = publicai.active.page.id
            let whichTab = "entries"

            editor.ui.collectionEntry.onclick = function () {
                _routes.openSchema(schemaId, whichTab, promptId);

            }
        } else {
            editor.ui.collectionEntry.classList.add("hidden")
        }
        //}

    },




    makeDivDraggable: function (dragHandler, draggableDiv) {
        var posX = 0, posY = 0, divX = 0, divY = 0;

        // Mouse down event on the drag handler
        dragHandler.onmousedown = dragMouseDown;

        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // Get the initial mouse cursor position
            posX = e.clientX;
            posY = e.clientY;

            // Call a function whenever the cursor moves
            document.onmousemove = elementDrag;

            // Call a function to stop moving when mouse button is released
            document.onmouseup = stopElementDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // Calculate the new cursor position
            divX = posX - e.clientX;
            divY = posY - e.clientY;
            posX = e.clientX;
            posY = e.clientY;

            // Set the draggable div's new position
            draggableDiv.style.top = (draggableDiv.offsetTop - divY) + "px";
            draggableDiv.style.left = (draggableDiv.offsetLeft - divX) + "px";
        }

        function stopElementDrag() {
            // Stop moving when mouse button is released
            document.onmouseup = null;
            document.onmousemove = null;
        }
    },







    addDivLabel: function (el, entry, classes, theObject, type) {

        //this is unlikely to work. remove.
        el.querySelectorAll(".public_div_label").forEach(e => e.remove()); 
        //!!  this may not find anything as classes have changed.
        //let label = addWhatWhere(components.ui.public_div_label, el, null, null, true)_

        removeAllChildNodes(el)

        let label = addWhatWhere(nwsapp.comp.label_comp, el, null, null, true)

        let controls = null
        if (nws.isPlayer && nws.edit && type != "rollover") {
            controls = addWhatWhere(nwsapp.comp.div_controls, el, null, null, true)
        }

        if (entry.type == "body") {
            render.addClassesToAllChildrenOf(label, "is_document_body", true)
        }

        let iframe = publicai.active.bodyElement
        let labelRect = label.getBoundingClientRect();

        

        let iframeRect = iframe.getBoundingClientRect();

        let objectSettings = theObject.getBoundingClientRect();
        //console.log("objectSettings", objectSettings)

        if (publicai.zoomReverse) {

            label.style.transformOrigin = "0% 0%";  // Set the anchor point to top middle
            label.style.transform = "scale(" + publicai.zoomReverse + ")";

        }

        


        // Calculate the label's top position relative to the iframe
        let relativeTopPosition = labelRect.top - iframeRect.top; //not used anymore
        // Check if the label's top position is at or very near the top of the iframe
        if (labelRect.top <= 120 && objectSettings.height > 50) {
            render.addClassesToAllChildrenOf(label, "is_top", true); //adds the label inside the div, if at the top of the page
        }

        //if container is too small or too close to the top, place the label at the bottom
        if (labelRect.top <= 120 && objectSettings.height < 35) {
            render.addClassesToAllChildrenOf(label, "is_bottom", true); //adds the label inside the div, if at the top of the page
        }

        //let selectionElements.div_settings = $$$(label, "layer-label", "settings")
        //let updateTemplateButton = $$$(label, "layer-label", "save_template")

        let selectionElements = $$$$(label, "data-public-selection")

        selectionElements.div_code.classList.add("hidden")


        if (!nws.isPlayer) {
            //checks if the user has permissions to edit the element. If so, update buttons may be enabled. 
          /*   if (entry.settings.permissions?.owner == "nwsapp.profile.websiteId" && entry.settings.permissions.component) {
                selectionElements.div_component.classList.remove("hidden")

                selectionElements.div_component.onclick = function () {
                    //create update template functionality in a separte function and add here. 
                }
            } */
        }


        let panel

        function openFloatingPanel(defaultTab) {




            panel = css_utils.floating_panel({
                "selector": theObject,
                "node": entry,
                "tab": defaultTab || null,
                "panel": components.ui.floating_dynamic,
                "label": "Add new element",
                "styles": [],
                "customMode": true,
                "save": false,
                "callback": _element_properties_panel.operateDynamicPanel,
                "direction": "down",
                "overlayColor": "rgb(107 95 88)",
                "overlayOpacity": 0.05,
                "width": 320
            })
        }

        if (entry.type != "body") {
            label.onclick = function (e) {
                e.stopPropagation()
                e.preventDefault()
                openFloatingPanel()
            }
        } else {
            selectionElements.div_settings.classList.add("hidden")
        }

        let ancestorForm = _find.checkIfAncestorIsFormElement(entry.id)
        //let ancestorMedia = _find.checkIfAncestorIsMediaElement(entry.id)
        //console.log("ancestorMedia", ancestorMedia)

        let mediaAttributesCheck = _find.hasMediaAttributes(entry)



        if (entry.type == "media" ||
            entry.type == "input_basic" ||
            entry.type == "textarea_basic" ||
            entry.type == "dropdown_basic" ||
            entry.type == "slider_basic" ||
            entry.type == "upload_basic" ||
            entry.type == "table" ||
            entry.type == "highlights" ||
            entry.type == "quotes" ||
            entry.type == "qrcode" ||
            entry.type == "bullets" ||
            entry.type == "audio" ||
            entry.type == "text" && ancestorForm && mediaAttributesCheck.hasFormAttributes ||
            entry.type == "div" && ancestorForm
            //entry.type == "text" && ancestorMedia ||
            //entry.type == "div" && ancestorMedia
        ) {

            //console.log("ancestorForm", ancestorForm)

            let defaultTab

            if (entry.type == "qrcode") {
                defaultTab = "qrcode"
            }


            if (entry.type == "media") {
                defaultTab = "media"
            }

            /*   if (entry.type == "audio") {
                  defaultTab = "audio_sources"
              } */


            if (entry.type == "table" ||
                entry.type == "bullets" ||
                entry.type == "highlights" ||
                entry.type == "quotes"

            ) {
                defaultTab = "table"
            }

            if (entry.type == "div" && ancestorForm) {
                defaultTab = "input_settings"
            }

            /*     if (entry.type == "div" && ancestorMedia) {
                    defaultTab = "media"
                } */

            if (entry.type == "slider_basic") {
                defaultTab = "input_settings"
            }

            if (entry.type == "upload_basic") {
                defaultTab = "input_settings"
            }


            if (entry.type == "input_basic") {
                defaultTab = "input_settings"
            }

            if (entry.type == "dropdown_basic") {
                defaultTab = "input_settings"
            }

            if (entry.type == "textarea_basic") {
                defaultTab = "input_settings"
            }

            if (entry.type == "audio") {
                defaultTab = "media"
            }

            if (entry.type == "text" && ancestorForm) {
                defaultTab = "input_settings"
            }
            /* 
                        if (entry.type == "text" && ancestorMedia) {
                            defaultTab = "media"
                        } */


            //console.log("defaultTab", defaultTab)

            theObject.ondblclick = function (e) {
                if (entry.type != "text") {
                    console.log("entru", entry)
                    e.stopPropagation()
                    e.preventDefault()
                    openFloatingPanel(defaultTab)
                }
            }

        }


        if (controls) {
            controls.style.pointerEvents = "auto";
        }
        label.style.pointerEvents = "auto";
        if (label) {
            //the_div_text = label.querySelector(".the_div_text")

            window.the_div_text = $$$(label, "data-public-selection", "div_label")
            window.the_div_text.textContent = entry.classes[0]?.name || "No Class"
        }

        if (classes) {
            render.addClassesToAllChildrenOf(label, classes, true)
        }
    },



    closeActivePanels: function () {

        //check if some panels should allow being kept open
        if (publicai.active.panel) {
            _nav.toggleNavigationContainer(false);
        }

    },






    enableSelectionAndScrollListeners: function (el, entry) {

        


        if (entry.settings.timeline?.play) {

            let scrollManager = new ScrollTimelineController(el, entry);
        }

        if (nws.isPlayer && !nws.edit || nwsapp.isDragging) { return }




        if (el) {

            el.onclick = (event) => {


                if (publicai.classesExpansionPanelCloseFunction) {
                    css_management.manage()
                    event.stopPropagation()
                    event.preventDefault()
                    return
                }


                if (publicai.colorPickerActive) {
                    _create.addSelectionContainer(publicai.selected.element, publicai.selected.settings, "select")
                    publicai.colorPickerActive.spectrum("hide");
                    publicai.isColoring = null
                    publicai.colorPickerActive = null
                    css_management.manage()

                    event.stopPropagation()
                    event.preventDefault()
                    return
                }

                //console.log("selected")

                if (!publicai.resizing || publicai.isEditingText) {
                    event.stopPropagation()
                    event.preventDefault()
                }

                if (publicai.isEditingText) {
                    console.log("is editing, exiting")
                    event.stopPropagation()
                    event.preventDefault()
                    return
                }

                if (publicai.colorPickerActive) {
                    console.log("hide picker....")
                    publicai.isColoring = null
                    publicai.colorPickerActive.spectrum("hide"); //since propagation is disabled, we need to manually hide the color picker if active
                }

                _create.deselectAllElements(1)


                if (!el.classList.contains("public-active")) {
                    _create.addSelectionContainer(el, entry, "select")
                    _create.closeActivePanels()

                    publicai.selected.id = entry.id
                    render.addClassesToAllChildrenOf(publicai.active.bodyElement, "public-active", false)
                    el.classList.remove("public-over")
                    if (entry.type == "body") {
                        el.classList.add("public-active-body")
                    } else {
                        el.classList.add("public-active")
                    }

                    publicai.selected.element = el
                    publicai.selected.settings = entry
                    publicai.selected.id = entry.id
                    if (!nws.isPlayer) {

                        if (animator_timeline.mode == "keyframes") {
                            //prevent applyCSS from firing an animation refresh, while object has changed. 
                            animator_timeline.mode = "children"
                        }
                        css_management.manage()
                    }



                } else {
                    el.classList.remove("public-active")
                    el.classList.remove("public-active-body")
                }

                if (publicai.active.panel == "elements") {
                    _nav.elements()
                }
                if (publicai.active.panel == "css") {
                    _nav.css()
                }
            }


            el.addEventListener('click', function (event) {
                _shortcuts.handleClicksOverIframe(event)
            })

            el.addEventListener('mouseover', function (event) {

                if (nwsapp.isDragging) {
                    return
                }

                if (!entry) {
                    return //to investigate how's this possible
                }

                if (!publicai.isEditingText && !publicai.keys.isMouseDown) {
                    for (let item of $$$(document, "public-rollover")) { item.remove() }

                    publicai.hovering = {
                        "element": el,
                        "settings": entry,
                        "id": entry.id
                    }



                    $$$(document2, "public-rollover").forEach(e => e.remove());
                    $$$(document, "public-rollover").forEach(e => e.remove());
                    //console.log("hovering", publicai.keys.isMouseDown)
                    _create.addSelectionContainer(el, entry, "rollover")
                    event.stopPropagation()
                    event.preventDefault()
                }

            });

            el.addEventListener('mouseleave', function (event) {
                event.target.classList.remove("public-over")
                let rollOverItem = $$$(document, "public-rollover", entry.id)
                if (rollOverItem) {
                    rollOverItem.remove()
                }

            });



        } else {
            console.log("entry doesn't exist. ", entry)
        }
    },






    updateLayerinfo: function (style, width, height) {


        let container = $$$(document, "nav-position", "container")

        if (style) {

            let w = style.width
            if (width) {
                w = parseFloat(width).toFixed(2) + "%"
            }

            let h = style.height
            if (height) {
                h = parseFloat(height).toFixed(2) + "%"
            }


            container.classList.add("active")
            let el1 = $$$(document, "coordinates", "size")
            let el2 = $$$(document, "coordinates", "position")
            el1.textContent = "X: " + style.left + " Y: " + style.top
            el2.textContent = "W: " + w + " H: " + capitaliseFirstLetter(h)
        } else {
            container.classList.remove("active")
        }


    },


    activateResizeHandlers: function (el, entry) {
        let resizeBottom = $$$(el, "resize", "bottom");
        let resizeTop = $$$(el, "resize", "top");
        let resizeRight = $$$(el, "resize", "right");
        let resizeLeft = $$$(el, "resize", "left");

        let cornerHandlers = [
            $$$(el, "resize", "br"),
            $$$(el, "resize", "bl"),
            $$$(el, "resize", "tl"),
            $$$(el, "resize", "tr"),
        ]



        let handle = $$$(el, "animator", "handle");


        el.style.height = entry.css.height
        el.style.width = entry.css.width


        let isResizingRight = false;
        let isResizingBottom = false;

        resizeRight.addEventListener('mousedown', function (e) {
            el.classList.add("resizing");
            handle.classList.add("resizing");


            isResizingRight = true;
            if (!publicai.resizing) {
                e.preventDefault();
                e.stopPropagation(); // Prevent event from bubbling
            }
        });

        if (entry.css.height != "auto") {

            resizeBottom.addEventListener('mousedown', function (e) {
                el.classList.add("resizing");
                handle.classList.add("resizing");
                isResizingBottom = true;
                if (!publicai.resizing) {
                    e.preventDefault();
                    e.stopPropagation(); // Prevent event from bubbling
                }
            });

        } else {

            resizeRight.classList.add("large") //make the handle larger and rounded
            resizeLeft.classList.add("inactive")

            resizeBottom.classList.add("inactive")
            resizeTop.classList.add("inactive")

            for (let corner of cornerHandlers) {
                corner.classList.add("inactive") // remove corner handlers
            }

        }

        let alertedAboutTheLockStatus = false
        document.addEventListener('mousemove', function (e) {
            if (!entry.locked) {
                if (isResizingRight) {
                    let newWidth = e.clientX - el.getBoundingClientRect().left;
                    //newWidth = Math.round(newWidth / publicai.snapConstant) * publicai.snapConstant

                    let widthPercentage = (newWidth / el.parentElement.offsetWidth) * 100;
                    _create.updateLayerinfo(el.style, widthPercentage)

                    el.style.width = newWidth + 'px';
                    el.style.zIndex = 999
                }
                if (isResizingBottom) {
                    let newHeight = e.clientY - el.getBoundingClientRect().top;
                    _create.updateLayerinfo(el.style)
                    //newHeight = Math.round(newHeight / publicai.snapConstant) * publicai.snapConstant
                    el.style.height = newHeight + 'px';
                    el.style.zIndex = 999
                }
            } else {
                if (!alertedAboutTheLockStatus) {
                    notifications.show([
                        ["Ooops.", "This element is locked. Unlock it to move it around."]
                    ])
                    alertedAboutTheLockStatus = true
                }
            }
        });

        document.addEventListener('mouseup', function () {
            if (isResizingRight || isResizingBottom) {
                // Calculate percentage size relative to parent element
                let parent = el.parentElement;
                let parentWidth = parent.offsetWidth;
                let parentHeight = parent.offsetHeight;

                let widthPercentage = (el.offsetWidth / parentWidth) * 100;

                let heightPercentage
                if (entry.css.height != "auto") {
                    heightPercentage = (el.offsetHeight / parentHeight) * 100;
                }

                // Update the entry with new size in percentage
                entry.css.width = widthPercentage;
                if (entry.css.height != "auto") {
                    entry.css.height = heightPercentage;
                }

                // Apply the new percentage size to the element
                el.style.width = widthPercentage + '%';
                if (entry.css.height != "auto") {
                    el.style.height = heightPercentage + '%';
                }

                isResizingRight = false;
                isResizingBottom = false;
                el.classList.remove("resizing");
                handle.classList.remove("resizing");
                _create.updateLayerinfo(null)
                el.style.zIndex = entry.css.zIndex;

            }
        });
    },





    callToAction: function (el, entry) {


    },




}
/* 


renderTimeline: function (settings) {

    //this is given multiple timelines to render. Divs have their own timeline
    let objectsInView = _utils.getActiveElementsForTime(publicai.currentTime) //get all active ones at current time

    for (let item of Object.keys(settings.timeline)) {
        let entry = settings.timeline[item]

        //console.log("Adding timeline", settings.timeline)

        if (entry.type != "scene") {
            //let el = $$$(settings.container, "public_id", entry.id)
            let el = $$$(document, "public_id", entry.id)

            if (!el) {
                //if div, it has to be re-created
                //if the above doesn't exist already
                el = addWhatWhere(publicai.comps[entry.type], settings.container)
                el.setAttribute("public_id", entry.id)
                _handlers[entry.type](el, entry) //managed with the handlers below



            }

            if (entry.type == "div") {
                _create.renderTimeline({
                    timeline: entry.timeline,
                    container: el,
                    objectsInView: objectsInView
                })

            }

            let isInView = false
            let foundObject = null
            for (let o of objectsInView) {
                if (o.item.id == entry.id) {
                    isInView = true
                    foundObject = o
                }
            }

            if (isInView && entry.visible) {

                el.classList.remove("inactive")
                el.style.opacity = foundObject.opacity
                el.style.zIndex = foundObject.item.zIndex

                publicai.inViewElements.push({
                    "element": el,
                    "settings": entry
                })


            } else {
                if (el.parentElement == publicai.active.bodyElement) {
                    el.classList.add("inactive")
                }
            }

        }
    }

}, */