
//the tabs navigation on the side

export var _nav = {
    init: async function () {
        //_utils.createSizesOptions()

        const originalAddEventListener = document.addEventListener;
        const originalRemoveEventListener = document.removeEventListener;
        nws.listeners = [];

        document.addEventListener = function (type, listener, options) {
            //console.log(`Event listener added: ${type}`, listener);
            // You can also push this to an array or object for later reference
            originalAddEventListener.call(document, type, listener, options);
            nws.listeners.push({ type, listener, options });

            if (options == "all") {
            }
        };
        document.removeEventListener = function (type, listener, options) {
            //console.log(`Event listener removed: ${type}`, listener);
            // You can also push this to an array or object for later reference
            originalRemoveEventListener.call(document, type, listener, options);

            //remove the listener from nws.listeners
            let index = nws.listeners.findIndex(item => item.listener == listener)
            nws.listeners.splice(index, 1)
        };


        _utils.openCloseTimeline(false)
        nwsapp.ux = $$$$(document, "ux")
        _editor_modes.init()

        _nav.homeButton()

        /* _size.setUpAspectSelectors()
        _size.setUpSizesDropdown()
        _size.setResizeObserver() */
        _size.setUpBreakpointSelectors()
        _size.setUpSceneResize()
        _utils.initialiseFonts() // this is excesive, loading all fonts. Consider loading only the ones needed. See addBodyScene in play_interactive. Also loaded on client
        //_utils.refreshTags() //no need to refresh tags. They're saved locally now
        handle.loadTemplates()
        this.handleTooltipEvents()
        this.editorControls()
        if (!publicai.activeTab) {
            //public.activeTab = "variations"
        }
        _nav.manageControlsPanel()
        _nav.refreshTabsNavigation()


        document.addEventListener('keydown', _shortcuts.handleKeyboardShortcuts);
        document.addEventListener('keyup', _shortcuts.handleKeyUp);

        document.addEventListener('paste', pasteEvent => {
            KeysSubscription.publish('paste', {
                event: pasteEvent,
            });
        });


        console.log("FIXme - function below is commented.")
        //await ux.extractFromComponents() //consider adding await
    },

    homeButton: function () {

        //console.log("ST up site menu", nwsapp.ux.sitemenu)

        nwsapp.ux.sitemenu.onmouseover = event => {
            render.addClassesToAllChildrenOf(nwsapp.ux.sitemenu, "is_active", true)
        }

        nwsapp.ux.sitemenu.onmouseleave = event => {
            render.addClassesToAllChildrenOf(nwsapp.ux.sitemenu, "is_active", false)
        }

        nwsapp.ux.sitemenu.onclick = event => {
            window.router.navigateTo("/create")
        }



    },


    editorControls: function () {

        //letrightSideTabs = xx


    },

    manageControlsPanel: function () {

        let sidebarControl = $$$(document, "sidebar", "close")
        let sidepanel = document.querySelector(".side_panel_main")

        let icon = sidebarControl.firstChild


        sidebarControl.onclick = event => {
            sidepanel.classList.toggle("active")
            if (sidepanel.classList.contains("active")) {
                icon.textContent = "chevron_right"
            } else {

                icon.textContent = "chevron_left"
            }
        }


    },




    handleTooltipEvents: function () {
        let tooltipTimeout;
        let originalPosition;



        document.addEventListener('mouseenter', function (event) {
            // Check if the target is an Element and has the tooltip attribute
            if (event.target instanceof Element && event.target.hasAttribute('tooltip')) {


                const element = event.target;
                const tooltipAttr = element.getAttribute('tooltip');
                const position = element.getAttribute('position');

                // Clear any existing timeout to prevent duplicate tooltips
                clearTimeout(tooltipTimeout);

                // Set a timeout to delay the display of the tooltip by 0.5 seconds
                tooltipTimeout = setTimeout(function () {

                    _utils.createTooltip(element, tooltipAttr, position);
                }, 180); // Delay of 0.5 second
            }
        }, true); // Use capture to ensure the event is not ignored

        document.addEventListener('mouseleave', function (event) {
            // Check if the target is an Element and has the tooltip attribute
            if (event.target instanceof Element && event.target.hasAttribute('tooltip')) {
                const element = event.target;

                // Clear the timeout if the mouseleave event occurs before the tooltip appears
                clearTimeout(tooltipTimeout);


                _utils.removeTooltip(element);
            }
        }, true); // Use capture to ensure the event is not ignored
    },


    managePanelPinning() {

        if (typeof nwsapp.pinPanels == "undefined") {
            nwsapp.pinPanels = false
        }

        //_nav.togglePinning(nwsapp.pinPanels)

        //toggleNavigationContainer


        let pinButton = $$$(document, "data-button", "pin_panel")
        let closeButton = $$$(document, "data-button", "close_panel")
        let clickCapture = document.querySelector(".click_capture")
        let navigationPanel = $$$(document, "side-panel", "content")

        function refreshButtons() {

            if (pinButton) {
                pinButton.classList.toggle("active", nwsapp.pinPanels);
                closeButton.classList.toggle("hidden", !nwsapp.pinPanels);
                clickCapture.classList.toggle("hidden", nwsapp.pinPanels)
                render.addClassesToAllChildrenOf(navigationPanel, "is_pinned", nwsapp.pinPanels)

                document.addEventListener('click', _nav.closeSidePanel, nwsapp.pinPanels);

                pinButton.onclick = event => {
                    nwsapp.pinPanels = !nwsapp.pinPanels;
                    refreshButtons()
                }

                closeButton.onclick = event => {
                    nwsapp.pinPanels = false
                    refreshButtons()
                    _nav.toggleNavigationContainer(false)
                }

            }
        }

        refreshButtons()

    },





    toggleNavigationContainer: function (activate = true) {





        let container = $$$(document, "tabbed", "content");
        let navigationElement = document.querySelector(".navigation_element")

        _nav.activateSpecificTab();

        if (activate) {
            container.classList.add("active");
            navigationElement.classList.add("active");

            // Set up the listener in the capturing phase
            document.addEventListener('click', _nav.closeSidePanel, true);
        } else {

            if (nwsapp.pinPanels) {
                return
            }

            _edit_agent.liveView(false)
            publicai.active.panel = null;
            if (publicai.state != "sources" && publicai.state != "utility") {
                ///reset state on panels close. If it's already set as sources, it's via the tab
                publicai.state = "pages"
            }

            container.classList.remove("active");
            navigationElement.classList.remove("active");
            document.removeEventListener('click', _nav.closeSidePanel, true);


            //window.router.updateURLSilently('/page/' + _pages.selected);
            window.router.updatePageTitle('Edit - ' + _public.active.page.properties.name);

            $$$(document, "maintab").forEach(item => {
                //render.addClassesToAllChildrenOf(item, "active", false) 

            })
        }


    },



    closeSidePanel: function (e) {
        // Check if the click is inside the container



        if (publicai.allowFileUpload || public_system.lib) {
            return
        }

        $$$(document, "maintab").forEach(item => {
            //render.addClassesToAllChildrenOf(item, "active", false) 
        })


        let container = $$$(document, "side-panel", "content");
        let mediaLibraryIsActive = document.querySelector(".overlaying_panel")?.classList.contains("active")
        let clickCapture = document.querySelector(".click_capture")

        if (nwsapp.pinPanels) { return }



        if (e.target == clickCapture) {
            closeSidePanel()
            _edit_agent.liveView(false)
        }

        if (document.querySelector(".overlay_container") || mediaLibraryIsActive) {
            //do nothing if overlayer has been created
            return
        }


        if (container.contains(e.target).firstChild) {

            return; // Do nothing if the click is inside the container
        }


        if (e && e.target && e.target.classList.contains("page_tab") || e.target?.parentElement?.classList.contains("page_tab") || container.contains(e.target)) {

        } else {
            e.preventDefault();
            e.stopPropagation();
        }



        function closeSidePanel() {
            let container = $$$(document, "tabbed", "content");
            let navigationElement = document.querySelector(".navigation_element")


            publicai.active.panel = null;

            container.classList.remove("active");
            navigationElement.classList.remove("active");


            document.removeEventListener('click', _nav.closeSidePanel, true);
            document.querySelector(".side_panel_main").classList.add("active")


            let selected = _find.elementByID(publicai.pages, _pages.selected);

            //if the last selected element is a folder - we need to open its homepage or the root hompeage
            if (selected && selected.type == "folder") {
                if (selected.homepage) {
                    _pages.selected = selected.homepage
                } else {
                    _pages.selected = publicai.pages.homepage
                }
                window.openEditorPath('/' + "page" + "/" + _pages.selected);
            } else {
                //do not reload the page, just replace the URL
                //nwsapp.router.updateURLSilently('/page/' + _pages.selected);
                window.router.updatePageTitle('Edit - ' + _public.active.page.properties.name);
            }
        }
    },


    refreshTabsNavigation: function () {
        //console.log("main tab setup....")

        let allTabs = $$$(document, "maintab")
        let container = $$$(document, "tabbed", "content")
        let containerContent = $$$(document, "side-panel", "content")
        if (nwsapp.pinPanels) { return }
        removeAllChildNodes(containerContent)

        container.onclick = event => {
            event.stopPropagation()
            event.preventDefault()
        }

        for (let item of allTabs) {

            let theTab = item.getAttribute("maintab")
            _nav.activateSpecificTab()

            item.onclick = event => {
                event.preventDefault()
                event.stopPropagation()
                //console.log("theTab", theTab)
                window.openEditorPath('/' + theTab);
                //_nav.openSideSection(theTab)

            }
        }
    },

    openSideSection: function (theTab, settings = null) {
        publicai.activeTab = theTab
        _nav.toggleNavigationContainer(true)
        _nav.refreshTabsNavigation()
        publicai.active.panel = theTab
        publicai.state = theTab
        publicai.urlStack = []
        _nav[theTab](settings)
    },


    pages: function (settings) {
        //allows for specific page to be opened programmatically
        _pages.init(settings)

    },

    schemas: function (settings) {
        //allows for specific schema to be opened programmatically
        _schemas.init(settings)
    },

    settings: function () {
        console.log("settings function")
    },


    activateSpecificTab: function () {
        //consider removing - adds too much complexity
        let allTabs = $$$(document, "maintab")

        if (publicai.activeTab) {

            for (let item of allTabs) {
                let theTab = item.getAttribute("maintab")

                if (theTab == publicai.activeTab) {
                    item.classList.add("active")
                } else {
                    item.classList.remove("active")
                }
            }

            let container = $$$(document, "side-panel", "content")

            if (nwsapp.pinPanels) { return }

            removeAllChildNodes(container)
            let componentToAdd = components.ui["an_tab_" + publicai.activeTab]
            let comp = addWhatWhere(componentToAdd, container)
            container.classList.add("active")
            //document.querySelector(".side_panel_main").classList.remove("active")
            _nav.managePanelPinning()
        }

    },



    addComponentsPreview: function () {

    },

    addText: function (settings) {

    },

    css: function () {

        let cssEl = $$$(document, "data-css", "source")
        let copy = $$$(document, "data-button", "copy-css")


        // This function converts text content to HTML, replacing line breaks and spaces
        function formatCssForHtml(cssText) {
            return cssText
                .replace(/\n/g, '<br>')         // Replace newline characters with <br>
                .replace(/\s\s\s\s/g, '&nbsp;&nbsp;&nbsp;&nbsp;'); // Replace 4 spaces with corresponding HTML entities
        }

        // Usage:
        // Assuming you have a <div id="css-container"></div> in your HTML
        var cssText = getFormattedCSS(publicai.selected.settings.classes[0].name)
        cssEl.innerHTML = formatCssForHtml(cssText); // Apply the formatted CSS as HTML

    },




    elements: function () {


        //show elements navigator side bar

        //make sure to not display utilities or elements that have not beedn rendered. Check mode. 

        let container = document.querySelector(".elements_nav")
        removeAllChildNodes(container)
        components.css.elements_block
        let allNavItem = []

        let closeAllToggleButton = $$$(document, "data-button", "close_all")
        closeAllToggleButton.textContent = "Close All"


        publicai.active.navItem = null

        //elementLookup
        let search = $$$(document, "lookup", "element")
        search.oninput = event => {

            searchForElement(search.value)



        }

        function searchForElement(keyword) {
            removeAllChildNodes(container)
            addElement(publicai.active.page.content[0], container, null, keyword)


            if (keyword != "") {
                let allExpandableItems = document.querySelectorAll(".element_children")
                for (let item of allExpandableItems) {
                    item.classList.toggle("inactive", false)
                }
            }
        }


        function matchKeyword(el, keyword) {

            let match = false

            if (el.classes[0].name.toLowerCase().includes(keyword.toLowerCase())) {
                match = true
            }

            for (let att of el.settings.attributes) {
                if (att.name.toLowerCase().includes(keyword.toLowerCase())) {
                    match = true
                }
                if (att.value.toLowerCase().includes(keyword.toLowerCase())) {
                    match = true
                }
            }


            return match

        }




        function addElement(el, location, parent, keyword) {

            if (!keyword) {
                keyword = ""
            }

            let navItem = addWhatWhere(components.css.elements_block, location)

            let label = navItem.querySelector(".el_label")
            let icon = navItem.querySelector(".el_icon")
            let visibility = navItem.querySelector(".el_visibility")
            let elementItem = navItem.querySelector(".elements_item")
            let close = navItem.querySelector(".el_close")
            let childrenContainer = navItem.querySelector(".element_children")
            let settingsContainer = navItem.querySelector(".page_settings_container")

            let duplicate = $$$(navItem, "data-page", "duplicate")
            let up = $$$(navItem, "data-page", "up")
            let down = $$$(navItem, "data-page", "down")


            let deleteButton = $$$(navItem, "data-page", "delete")


            let attributesContainer = navItem.querySelector(".el_attributes")
            removeAllChildNodes(attributesContainer)


            for (let att of el.settings.attributes) {
                let attItem = addWhatWhere(components.ui.el_attribute, attributesContainer)
                let attName = $$$(attItem, "el_att", "name")
                let attValue = $$$(attItem, "el_att", "value")
                attName.textContent = att.name + ": "
                attValue.textContent = att.value
                attItem.onclick = function (e) {
                    _create.activateSpecificElement(el.id)
                    _nav.elements()
                    settings_attributes.editAttribute(att, e)
                    e.preventDefault()
                    e.stopPropagation()
                }
            }


            if (keyword != "") {
                elementItem.classList.toggle("is_faded", !matchKeyword(el, keyword))
                elementItem.classList.toggle("is_highlighted", matchKeyword(el, keyword))
                attributesContainer.classList.toggle("is_faded", !matchKeyword(el, keyword))
            } else {
                elementItem.classList.toggle("is_highlighted", false)
                elementItem.classList.toggle("is_faded", false)
            }

            //components.ui.el_attribute


            navItem.onclick = event => {
                event.preventDefault()
                event.stopPropagation()
                _create.activateSpecificElement(el.id)
                _nav.elements()

            }


            elementItem.onmouseenter = event => {
                settingsContainer.classList.add("active")
                for (let item of $$$(document, "public-rollover")) { item.remove() }
                let element = $$$(nwsapp.activeDocument, "public_id", el.id)

                publicai.hovering = {
                    "element": element,
                    "settings": el,
                    "id": el.id
                }
                _create.addSelectionContainer(element, el, "rollover")
            }

            elementItem.onmouseleave = event => {
                settingsContainer.classList.remove("active")

                for (let item of $$$(document, "public-rollover")) { item.remove() }
                let rollOverItem = $$$(document, "public-rollover", el.id)

                if (rollOverItem) {
                    rollOverItem.remove()
                }

            }




            up.onclick = event => {
                event.preventDefault()
                event.stopPropagation()
                moveUpOrDown(el, parent, "up", navItem)
            }

            down.onclick = event => {
                event.preventDefault()
                event.stopPropagation()
                moveUpOrDown(el, parent, "down", navItem)
            }
            duplicate.onclick = event => {
                event.preventDefault()
                event.stopPropagation()
                duplicateElement(el, parent)
            }

            deleteButton.onclick = event => {
                event.preventDefault()
                event.stopPropagation()

                let ancestor = _find.elementByID(publicai.active.page, el.ancestor)

                let index = parent.content.indexOf(el)
                parent.content.splice(index, 1)
                navItem.remove()

                _create.deselectAllElements();
                _nav.reCreateSpecificNode(ancestor)
                _create.addSelectionContainer(publicai.selected.element, publicai.selected.settings, "select")

            }





            let hidden = false
            //go through stack
            if (hidden) {
                visibility.textContent = "visibility_off"
            }


            close.onclick = event => {
                event.preventDefault()
                event.stopPropagation()
                childrenContainer.classList.toggle("inactive")
            }


            icon.textContent = _handlers.icons[el.type]
            if (el.type == "media") {
                //console.log("media node", el)
            }



            if (el.classes.length > 0) {
                label.textContent = el.classes[0].name

                if (el.settings.general?.label && el.settings.general.label != "" && el.settings.general.label != el.classes[0].name) {
                    label.textContent = el.classes[0].name + " (" + el.settings.general.label + ")"
                }

            } else {
                console.log(el)
            }
            if (el.content.length > 0) {
                for (let child of el.content) {
                    addElement(child, childrenContainer, el, keyword)
                    childrenContainer.classList.add("inactive")
                }
            } else {
                close.classList.add("inactive")
            }

            if (el.id == publicai.selected.id) {
                render.addClassesToAllChildrenOf(elementItem, "nav-selected", true)
                publicai.active.navItem = elementItem
                //elementItem.classList.add("nav-selected")
            }

        }

        function duplicateElement(el, parent) {
            _create.removeAllGhosts()
            let newEl = { ...el }
            newEl.id = generateSceneID()
            //insert the element right after the original
            let index = parent.content.indexOf(el)
            parent.content.splice(index + 1, 0, newEl)

            _nav.reCreateSpecificNode(publicai.selected.settings)
            play_interactive.renderInteractiveComponents()
            _create.activateSpecificElement(newEl.id)
            _nav.elements()
        }





        function moveUpOrDown(el, parent, direction, navItem) {
            // Assuming $$$ is a function that retrieves the DOM element by a specific attribute
            _create.deselectAllElements();
            let pageElement = $$$(nwsapp.activeDocument, "public_id", el.id);
            //let navItem = $$$(nwsapp.activeDocument, "nav_id", el.id); // Replace 'nav_id' with the actual attribute used to identify the navItem.

            let index = parent.content.indexOf(el);
            let newIndex = (direction === "up") ? index - 1 : index + 1;

            // Ensure the new index is within the bounds of the array
            newIndex = Math.max(0, Math.min(newIndex, parent.content.length - 1));
            if (newIndex !== index) {
                // Update the config object
                parent.content.splice(index, 1);
                parent.content.splice(newIndex, 0, el);

                // Move the DOM element
                if (direction === "up") {
                    if (pageElement.previousElementSibling) {
                        pageElement.parentNode.insertBefore(pageElement, pageElement.previousElementSibling);
                    }
                    if (navItem.previousElementSibling) {
                        navItem.parentNode.insertBefore(navItem, navItem.previousElementSibling);
                    }
                } else if (direction === "down") {
                    if (pageElement.nextElementSibling) {
                        pageElement.parentNode.insertBefore(pageElement.nextElementSibling, pageElement);
                    }
                    if (navItem.nextElementSibling) {
                        navItem.parentNode.insertBefore(navItem.nextElementSibling, navItem);
                    }
                }
            }
        }



        //old function that also does re-renders

        /*  function moveUpOrDown(el, parent, direction) {
             let pageElement = $$$(nwsapp.activeDocument, "public_id", el.id)
             //_create.removeAllGhosts()
             let index = parent.content.indexOf(el)
             let newIndex = index
             if (direction == "up") {
                 newIndex = index - 1
             } else {
                 newIndex = index + 1
             }
             parent.content.splice(index, 1)
             parent.content.splice(newIndex, 0, el)
             _nav.reCreateSpecificNode(publicai.selected.settings)
 
              play_interactive.renderInteractiveComponents()
             _create.activateSpecificElement(el.id)
             _nav.elements() 
         }
  */
        addElement(publicai.active.page.content[0], container)


        closeAllToggleButton.onclick = event => {
            closeAll()
        }

        function closeAll() {
            let allExpandableItems = document.querySelectorAll(".element_children")
            for (let item of allExpandableItems) {
                item.classList.toggle("inactive")
            }
            //check if items have been switch to active or inactive
            if (allExpandableItems[0].classList.contains("inactive")) {
                closeAllToggleButton.textContent = "Expand All"
            } else {
                closeAllToggleButton.textContent = "Close All"
            }
        }


        function focusOnElement(id) {

            removeClassFromParents(publicai.active.navItem, "inactive")
            //console.log("focusOnElement", id)
            if (publicai.active.navItem) {
                publicai.active.navItem.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
        }

        //closeAll() 
        if (publicai.selected.settings?.id) {
            focusOnElement(publicai.selected.settings.id)
        }


    },

    reCreateSpecificNode: function (node) {

        console.log("----re-create node")

        //this doesn't really work. Need to be worked to only recreate one element, not the whole page for perf. considerations
        let containerToRefresh
        if (node.ancestor) {
            containerToRefresh = _find.elementByID(publicai.active.page, node.ancestor)
        } else {
            containerToRefresh = publicai.active.page
        }
        //play_interactive.createPageElements(containerToRefresh.content, containerToRefresh.ancestor)

        play_interactive.createPageElements(publicai.active.body.content, null)

    },


    createSymbolsList: function () {

        //components.ui.templates_item = ux.ext("templates_item")
        let container = $$$(document, "templates_container", "symbols")

        removeAllChildNodes(container)

        let symbolsFolder = _find.getRoot(publicai.active.page.id).symbols

        if (Object.keys(symbolsFolder).length == 0) {
            //no symbols
            container.textContent = "No symbols found in the current folder."
        }


        for (let template of Object.values(symbolsFolder)) {


            let item = addWhatWhere(components.ui.templates_item, container)
            let body = $$$(item, "the-template", "body")
            let label = $$$(item, "the-template", "name")
            let icon = item.querySelector(".el_icon")
            let del = $$$(item, "component-control", "delete")



            del.onclick = event => {

                event.stopPropagation()
                event.preventDefault()
                item.remove()
                delete symbolsFolder[template.symbol.id]
                notifications.show([
                    ["Alright", "The symbol has been deleted."]
                ])
            }

            console.log("template", template)

            label.textContent = template.symbol.name
            icon.textContent = template.symbol.icon || "chevron_right"


            item.onmouseenter = event => {
                console.log(template.symbol.id)
                //console.log(template.node)
            }

            item.onclick = event => {
                //_nav.addTemplatedContent(template, false)
                _nav.addSymbol(template, false)
                event.stopPropagation()
                event.preventDefault()
            }
        }

        function renderTemplatePreview(template, container) {

        }
    },

    addSymbol: function (el) {

        //console.log*()

        let symbol = {
            id: generateSceneID(),
            symbol: {
                id: el.symbol.id,
                name: el.symbol.name,
                icon: el.symbol.icon,
            }
        }

        console.log(el, symbol)

        publicai.selected.settings.content.push(symbol)
        _create.highlightElementIfBrandNew() // will remove the highlight since it now has children
        _nav.toggleNavigationContainer(false)
        play_interactive.createPageElements(publicai.selected.settings.content, publicai.selected.settings.id)
        play_interactive.renderInteractiveComponents()

        _create.deselectAllElements(21)
        publicai.selected.element = $$$(nwsapp.activeDocument, "public_id", el.id)
        publicai.selected.settings = el
        publicai.selected.id = el.id

        _create.addSelectionContainer(publicai.selected.element, publicai.selected.settings, "select")
        css_management.manage()


    },


    createTemplatesList: function () {

        //components.ui.templates_item = ux.ext("templates_item")
        //let container = document.querySelector(".templates_container")
        let container = $$$(document, "templates_container", "components")

        removeAllChildNodes(container)

        for (let template of public_system.templates) {

            if (template.node.config.type == "package") {
                //packages are listed separately
                return
            }

            let item = addWhatWhere(components.ui.templates_item, container)
            let body = $$$(item, "the-template", "body")
            let label = $$$(item, "the-template", "name")
            let icon = item.querySelector(".el_icon")

            let del = $$$(item, "component-control", "delete")

            if (template.node.config.template?.type == "system") {
                //del.classList.add("hidden") // allow deletes even for system ones for now
            }

            del.onclick = event => {

                if (template.node.config.template?.type != "system__") { ///cometed so we can delete system templates as well
                    event.stopPropagation()
                    event.preventDefault()
                    item.remove()

                    let deleteFromCloudflare = false
                    if (template.node.config.template?.type == "system") {
                        deleteFromCloudflare = true
                    }

                    handle.deleteTemplate(template.node.id, deleteFromCloudflare)
                } else {
                    notifications.show([
                        ["System Template", "This template cannot be deleted."]
                    ])
                }
            }


            label.textContent = template.node.name
            icon.textContent = template.node.config.template?.icon || "chevron_right"



            renderTemplatePreview(template, body)

            item.onmouseenter = event => {
                console.log(template.node.id)
                //console.log(template.node)
            }

            item.onclick = event => {
                _nav.addTemplatedContent(template.node.id, false)
            }

            //_nav.addTemplatedContent("5fa23aed-dc63-11ee-b8fe-5c3ed14f199b", false)


        }

        function renderTemplatePreview(template, container) {

        }
    },




    addTemplatedContent: function (id, returnElement = false, cssLocationId = null, specificTemplateObject) {

        let template
        let css
        let el
        let html = null

        if (specificTemplateObject) {

            template = specificTemplateObject.content[0]
            css = specificTemplateObject.css
            el = specificTemplateObject.content[0]
            el.raw = specificTemplateObject.html

        } else {
            //_defaults.getDefaultDynamicSettings("div"),
            //let foundTemplate = JSON.parse(JSON.stringify(public_system.templates.find(item => item.node.id == id)))


            let foundTemplate = components.system[id]

            if (foundTemplate) {
                template = foundTemplate.node
            } else {
                return
            }

            el = template
            css = foundTemplate.css
        }

        //if !returnElement, the element is returned so that it can be used in templates, etc


        template.component = true //marks this as component to prevent duplication, etc


        /* 
                if (foundTemplate.html) {
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(template.html, 'text/html');
                    template.html = doc.body.firstChild; // This is your DOM element
                    //console.log("domElement", domElement)
                } */



        //console.log("returning", template)

        el.settings.permissions.component = id

        delete el.schemaStack
        delete el.ancestor

        let newClasses = []
        let existingClasses = []

        updateSceneIds(el) //creats new ids for everything



        //create the css in the current location - already set up via editor
        let cssLocation = publicai.css
        if (cssLocationId) {
            //create the css in a given location, if content is added programmatically
            //console.log("have added css to ", cssLocationId)
            cssLocation = publicai.globalCss[cssLocationId]
        }



        for (let theClass of Object.keys(css)) {

            if (!cssLocation[theClass]) {
                newClasses.push(theClass)
                cssLocation[theClass] = { ...css[theClass] }
            } else {
                existingClasses.push(theClass)
            }
        }

        /* 
 
        notifications.show([
            ["New Classes", newClasses.join(", ")],
        ])
 
        notifications.show([
            ["Existing Classes", existingClasses.join(", ")],
        ])
 */

        //console.log("template.html", template)


        if (returnElement) {

            //console.log("element added is ", el)
            return el
        }



        publicai.selected.settings.content.push(el)

        _create.highlightElementIfBrandNew() // will remove the highlight since it now has children

        _nav.toggleNavigationContainer(false)
        play_interactive.createPageElements(publicai.selected.settings.content, publicai.selected.settings.id)
        play_interactive.renderInteractiveComponents()
        _create.deselectAllElements(20)
        publicai.selected.element = $$$(nwsapp.activeDocument, "public_id", el.id)
        publicai.selected.settings = el
        publicai.selected.id = el.id

        _create.addSelectionContainer(publicai.selected.element, publicai.selected.settings, "select")
        css_management.manage()
    },


    add_content: function () {

        //this needs to be removed from the router!!!!

        //consider adding items dynamically
        //let container = $$$(document, "templateslist", "list")

        function changeTab(tab) {
            if (tab == "all") {

            }
            if (tab == "templates") {
                _nav.createTemplatesList()
            }
            if (tab == "symbols") {
                _nav.createSymbolsList()
            }
        }

        let tab = "all"


        css_utils.operateTabs({
            "location": document,
            "name": "add-element",
            "default": tab,
            "callback": changeTab
        })

        let items = $$$(document, "data-template-type")

        //console.log("items", items)

        for (let item of items) {
            item.onclick = event => {
                console.log("click....")
                event.stopPropagation()
                event.preventDefault()



                let element_type = item.getAttribute("data-template-type")
                let element_mode = item.getAttribute("data-mode") || ""


                console.log("element_type", element_type)

                let el = _defaults[element_type](null)
                console.log("el", el)
                publicai.selected.settings.content.push(el)

                _create.highlightElementIfBrandNew() // will remove the highlight since it now has children

                _nav.toggleNavigationContainer(false)
                play_interactive.createPageElements(publicai.selected.settings.content, publicai.selected.settings.id)
                play_interactive.renderInteractiveComponents()
                _create.deselectAllElements()




                if (!publicai.timelineIsActive) {

                    publicai.selected.element = $$$(nwsapp.activeDocument, "public_id", el.id)
                    publicai.selected.settings = el
                    publicai.selected.id = el.id

                } else {

                    //re-select container
                    publicai.elementsToHighlight.push(el.id)
                    publicai.selected.settings = _find.elementByID(publicai.pages, publicai.selected.settings.id)
                    publicai.selected.id = publicai.selected.settings.id
                    publicai.selected.element = $$$(nwsapp.activeDocument, "public_id", publicai.selected.id)
                    _create.addSelectionContainer(publicai.selected.element, publicai.selected.settings, "select")
                    animator_timeline.refresh()
                }

                css_management.manage()

                //console.log("break")

                //animator_timeline.refreshTimeline()
            }
        }
    },


    text: function () { //this is the text navigation tab

        //consider adding items dynamically
        let container = $$$(document, "templateslist", "list")
        removeAllChildNodes(container)

        public_system.templates.forEach((template, i) => {


            let item = addWhatWhere(components.ui.template_item_text, container)
            let css = template.node.config.template
            let theText = $$$(item, "template", "text")
            let theLabel = $$$(item, "template", "label")
            let del = $$$(item, "template", "delete")

            theLabel.innerHTML = template.node.name

            for (let property of Object.keys(css)) {
                theText.style[property] = _handlers.adEmIfNeeded(property, css[property])
            }

            del.onclick = event => {
                event.stopPropagation()
                event.preventDefault()
                item.remove()
                handle.deleteTemplate(template.node.id)
            }

            item.onrollover = event => {
                event.stopPropagation()
                event.preventDefault()
                del.classList.add("active")
            }
            item.onrollout = event => {
                event.stopPropagation()
                event.preventDefault()
                del.classList.remove("active")
            }


            item.onclick = event => {
                event.stopPropagation()
                event.preventDefault()
                let templateId = item.getAttribute("template")


                let settings = {
                    "type": templateId.split("_")[0],
                    "style": templateId.split("_")[1],
                    "text": _defaults.getMovieLines().mainLine,
                    "description": ""
                }


                let el = _defaults.text()
                el.content.line1.id = animator_init.addTextDefinition([settings.text], settings.style)

                el.id = generateSceneID()
                el.rendered = false

                el.css = { ...css }
                el.backupcss = { ...css }


                let locationToAdd = _nav.getLocationToAddNewElements()



                _nav.setBestPositionAndSize(el, settings.type)
                locationToAdd[el.id] = el
                _utils.makeConsecutiveZindexes()
                animator_timeline.refreshTimeline()
            }

        })

        let items = container.childNodes
        for (let item of items) {

        }
    },

    menu: function () {
        console.log("menu")
    },

    shape: function () {

    },

    brand: function () {

    },
    audio: function () {

    },



    variations: function () {


        //datasources.managementPanel()


    },


}