export var css_elements = {


    init: function () {

        //return

        if (!this.ui) {
            let container = $$$(document, "data-group", "elements")
            this.ui = {

                components: $$$(document, "data_elements", "component"),
                role: $$$(document, "data_elements", "role"),

            }
        }

        this.componentSettings()
        this.componentRole()

    },

    checkIfComponentExists: function (name) {
        //add validation here
        return true

    },

    componentSettings: function () {

        let input = this.ui.components
        input.value = publicai.selected.settings.settings?.component?.name || ""
        input.oninput = function () {

            if (!publicai.selected.settings.settings?.component?.name) {
                publicai.selected.settings.settings.component =
                {
                    name: null,
                    //add more react settings
                }
            }

            input.classList.remove("is_invalid")
            let valid = css_elements.checkIfComponentExists(input.value)
            if (!valid) {
                input.classList.add("is_invalid")
            } else {
                publicai.selected.settings.settings.component.name = input.value
            }
        }
    },

    componentRole: function () {

        let input = this.ui.role
        input.value = publicai.selected.settings.settings?.role || ""

        let self = this

        input.oninput = function () {

            if (!publicai.selected.settings.settings?.role) {
                publicai.selected.settings.settings.role = ""

            }

            input.classList.remove("is_invalid")
            let valid = css_elements.checkIfComponentExists(input.value)
            if (!valid) {
                input.classList.add("is_invalid")
            } else {
                publicai.selected.settings.settings.role = input.value
            }
        }
    },


}