


export function init() {



    let activate = false

    let schemaInUse = null
    let pageSchema = publicai.active.page.dynamic.schema

    if (pageSchema) {
        schemaInUse = publicai.dataSources.schemas[pageSchema]
    }


    this.schema = schemaInUse

    if (schemaInUse) {
        activate = true
    } else {
        //activate = false
        activate = true
    }


    if (activate) {
        nwsapp.ux.controls_panel.classList.remove("hidden")
    } else {
        nwsapp.ux.controls_panel.classList.add("hidden")
        return
    }

    if (!interactive_controls.status) {
        interactive_controls.status = {}

    }

    if (publicai.active.page.dynamic.schema) {
        nwsapp.ux.panel_name.textContent = schemaInUse.name
        if (schemaInUse.name.length > 16) {
            nwsapp.ux.panel_name.textContent = schemaInUse.name.substring(0, 16) + "..."
        }
    }

    interactive_controls.heads = $$$$(document, "switch_head")
    interactive_controls.panels = $$$$(document, "controls_panel")

    if (nwsapp.activeRoute == "edit") {
        interactive_controls.multi = false
    }

    this.refresh()
    this.manageControlsPanel()


    return

    if (!interactive_controls.status) {
        interactive_controls.status = {}

    }


    if (nwsapp.activeRoute == "preview") {
        interactive_controls.multi = true
    }

}


export function manageControlsPanel() {



    let sidebarControl = $$$(document, "sidebar2", "close")
    let sidepanel = nwsapp.ux.controls_panel

    let icon = sidebarControl.firstChild


    sidebarControl.onclick = event => {
        sidepanel.classList.toggle("active")
        if (sidepanel.classList.contains("active")) {
            icon.textContent = "chevron_right"
        } else {

            icon.textContent = "chevron_left"
        }
    }


}

export function dimensions() {

}

export function group() {

}


export function refresh(clicked) {


    for (let key of Object.keys(interactive_controls.heads)) {

        interactive_controls.heads[key].onclick = function () {
            let panel = interactive_controls.panels[key]
            let head = interactive_controls.heads[key]
            panel.classList.toggle("active")
            head.classList.remove("hidden")
            panel.classList.remove("hidden")
        }
        //console.log(key)
        //interactive_controls[key]()
    }



    this.resources = {}


    if (publicai.active.page.dynamic.schema) {

        this.resources.schemaFolder = publicai.pages.content.find(entry => entry?.schema?.id === publicai.active.page.dynamic.schema);
        this.resources.templatesFolder = this.resources.schemaFolder.content.find(entry => entry?.properties?.role === "templates");
    }



    if (publicai.active.page.dynamic.schema) {
        this.pages()
        this.entries()
        this.agents()
    } else {
        this.sizes()
    }





    let role = publicai.active.page.properties.role

    let panelsToClose = []
    //let panelsToHide = ["pages", "entries", "agents"]
    let panelsToHide = []

    //editor.ui.runPage.classList.add("hidden")

    switch (role) {
        case "template":
            //interactive_controls.heads
            break;
        case "prompt":
            console.log("prompt")
            panelsToClose = ["pages", "entries"]
            panelsToHide = ["sizes"]

            //editor.ui.runPage.classList.remove("hidden")

            break;
        default:

            panelsToClose = ["pages", "entries"]

            break;

    }



    for (let item of panelsToClose) {
        interactive_controls.panels[item].classList.remove("active")
    }

    for (let item of panelsToHide) {
        interactive_controls.panels[item].classList.add("hidden")
        interactive_controls.heads[item].classList.add("hidden")
    }




    //native.refreshObjectReferences()
    //nativemedia.setUpSettingsAutomation()
}



export function agents() {

    return

    let entries = []

    let schemaFolder = publicai.pages.content.find(entry => entry?.schema?.id === this.schema.id);
    let agentsFolder = schemaFolder.content.find(entry => entry?.properties?.role === "prompts");

    //  console.log("templates")    


    removeAllChildNodes(interactive_controls.panels.agents)


    if (!interactive_controls.status.agents) {
        interactive_controls.status.agents = {}
    }
    let mystatusObject = interactive_controls.status.agents

    let clearButton = $$$(interactive_controls.heads.agents, "head_filter", "clear")
    clearButton.onclick = function (e) {
        e.stopPropagation()
        e.preventDefault()
        interactive_controls.toggleValues(interactive_controls.status.agents)
    }



    let settings = {
        el: agentsFolder.content,
        location: interactive_controls.panels.agents,
        ancestor: agentsFolder,
        editNewlyAdded: null,
    }
    _pages.addPageNavigationElement(settings)

    render.addClassesToAllChildrenOf(interactive_controls.panels.agents, "dark", true)



}



export async function entries() {

    let entries = []

    let schemaFolder = this.resources.schemaFolder
    let templatesFolder = this.resources.templatesFolder

    //  console.log("templates")    


    removeAllChildNodes(interactive_controls.panels.entries)


    if (!interactive_controls.status.entries) {
        interactive_controls.status.entries = {}
    }
    let mystatusObject = interactive_controls.status.entries

    let clearButton = $$$(interactive_controls.heads.entries, "head_filter", "clear")
    clearButton.onclick = function (e) {
        e.stopPropagation()
        e.preventDefault()
        interactive_controls.toggleValues(interactive_controls.status.entries)
    }


    let index = 0
    for (let entry of publicai.pageData.entries) {

        let button = addWhatWhere(components.ui.mode_switch, interactive_controls.panels.entries)
        let elements = $$$$(button, "control_el")
        button.setAttribute("variation", entry.id)
        button.setAttribute("index", index)


        let variationLine = entry.sections[0].elements[0].value

        if (variationLine.length > 16) {
            variationLine = variationLine.substring(0, 16) + "..."
        }

        elements.label.textContent = variationLine

        button.onclick = function () {
            changeEntry(entry.id)
        }

        //console.log("entry", entry)

        if (entry.id == publicai.pageData?.activeEntry?.id) {
            render.addClassesToAllChildrenOf(button, "active", true)
            elements.icon.textContent = "check"

        } else {
            render.addClassesToAllChildrenOf(button, "active", false)
            elements.icon.textContent = "fiber_manual_record"
        }

        index++
    }

    async function changeEntry(id) {
        //the update entry dropdown. Changes the collection entry to be rendered. 

        publicai.timeline.animation.stop()
        delete publicai.animations
        publicai.animations = {}

        await _data_init.updatePageData(id)
        play_interactive.createPageElements(publicai.active.page.content[0].content) //re-create the page from the top
        //play_interactive.renderInteractiveComponents()
        interactive_controls.entries()
    }


}

export function pages() {

    let pages = []

    let schemaFolder = this.resources.schemaFolder
    let templatesFolder = this.resources.templatesFolder

    //  console.log("templates")    


    removeAllChildNodes(interactive_controls.panels.pages)


    if (!interactive_controls.status.pages) {
        interactive_controls.status.pages = {}
    }
    let mystatusObject = interactive_controls.status.pages

    let clearButton = $$$(interactive_controls.heads.pages, "head_filter", "clear")
    clearButton.onclick = function (e) {
        e.stopPropagation()
        e.preventDefault()
        interactive_controls.toggleValues(interactive_controls.status.pages)
    }


    let settings = {
        el: templatesFolder.content,
        location: interactive_controls.panels.pages,
        ancestor: templatesFolder,
        editNewlyAdded: null,
    }
    _pages.addPageNavigationElement(settings)

    render.addClassesToAllChildrenOf(interactive_controls.panels.pages, "dark", true)
    _pages.closeAll()
    _pages.focusOnElement(publicai.active.page.id)


}


export function sizes() {


    let sizes = []

    for (let el of _size.order) {
        if (!el.includes("label")) {
            sizes.push(nwsapp.currentProject.config.formats[el])
        }
    }

    removeAllChildNodes(interactive_controls.panels.sizes)


    if (!interactive_controls.status.sizes) {
        interactive_controls.status.sizes = {}
    }
    let mystatusObject = interactive_controls.status.sizes

    let clearButton = $$$(interactive_controls.heads.sizes, "head_filter", "clear")
    clearButton.onclick = function (e) {
        e.stopPropagation()
        e.preventDefault()
        interactive_controls.toggleValues(interactive_controls.status.sizes)
    }

    let current = publicai.active.page.properties.size.current

    function refreshElements() {
        let current = publicai.active.page.properties.size.current

        for (let item of interactive_controls.panels.sizes.childNodes) {
            let value = item.getAttribute("settings_value")
            let elements = $$$$(item, "control_el")


            if (interactive_controls.multi) {

                if (typeof mystatusObject[value] == "undefined") {
                    mystatusObject[value] = true
                }

                if (mystatusObject[value]) {
                    render.addClassesToAllChildrenOf(item, "active", true)
                    elements.icon.textContent = "check"
                } else {
                    render.addClassesToAllChildrenOf(item, "active", false)
                    elements.icon.textContent = "fiber_manual_record"
                }

            } else {



                if (current && current.value && current.value == value) {

                    //console.log("FOUND", current.value , value)

                    render.addClassesToAllChildrenOf(item, "active", true)
                    elements.icon.textContent = "check"
                    mystatusObject[value] = true
                } else {
                    render.addClassesToAllChildrenOf(item, "active", false)
                    elements.icon.textContent = "fiber_manual_record"
                    mystatusObject[value] = false
                }

            }
        }
    }

    for (let item of sizes) {

        if (!item) {
            continue
        }

        let button = addWhatWhere(components.ui.mode_switch, interactive_controls.panels.sizes)
        let elements = $$$$(button, "control_el")
        button.setAttribute("settings_value", item.value)


        elements.label.textContent = item.label

        button.onclick = function () {
            elementClick(item)
        }


        //let settings = nwsapp.currentProject.config.variations.settings[nwsapp.currentSettings.mode]



        function refreshStatus() {
            //check if size is part of enabledSizes
            //switches on the on off butotn
            let activeSizes = publicai.active.page.properties.size.active
            let key = item.width + "x" + item.height

            if (activeSizes[key] && activeSizes[key].enabled) {
                elements.status_color.classList.add("is_active")
                elements.status_label.textContent = "On"
            } else {
                elements.status_color.classList.remove("is_active")
                elements.status_label.textContent = "Off"
            }
        }


        refreshStatus()

        elements.on_switch.onclick = function (e) {

            let activeSizes = publicai.active.page.properties.size.active
            let key = item.width + "x" + item.height

            if (activeSizes[key]) {
                activeSizes[key].enabled = !activeSizes[key].enabled
            }
            //console.log("activeSizes", activeSizes.enabled, key, item)
            //settings.enabledSizes.push(item.value)
            refreshStatus()
            _size.toggleSize()
            e.stopPropagation()
            e.preventDefault()
        }

    }

    refreshElements()

    function elementClick(s) {

        if (interactive_controls.multi) {
            mystatusObject[s.value] = !mystatusObject[s.value]
        } else {

            if (s.value == "responsive") {
                publicai.active.page.properties.size.responsive = true
            } else {
                publicai.active.page.properties.size.responsive = false
                delete publicai.active.page.properties.size.current
                publicai.active.page.properties.size.current = s
            }
            _size.manageSizeModes()
            refreshElements()
        }
    }

}

export function styles() {

    let availableStyles = []
    for (let key of Object.keys(nwsapp.currentSettings.styles)) {
        availableStyles.push({
            "label": key,
            "value": key
        })
    }


    removeAllChildNodes(interactive_controls.panels.styles)



    if (!interactive_controls.status.styles) {
        interactive_controls.status.styles = {}
    }
    let mystatusObject = interactive_controls.status.styles


    let clearButton = $$$(interactive_controls.heads.styles, "head_filter", "clear")
    clearButton.onclick = function (e) {
        e.stopPropagation()
        e.preventDefault()
        interactive_controls.toggleValues(interactive_controls.status.styles)
    }


    function refreshElements() {

        for (let item of interactive_controls.panels.styles.childNodes) {
            let value = item.getAttribute("settings_value")
            let elements = $$$$(item, "control_el")

            if (interactive_controls.multi) {

                if (typeof mystatusObject[value] == "undefined") {
                    mystatusObject[value] = true
                }

                if (mystatusObject[value]) {
                    render.addClassesToAllChildrenOf(item, "active", true)
                    elements.icon.textContent = "check"
                } else {
                    render.addClassesToAllChildrenOf(item, "active", false)
                    elements.icon.textContent = "fiber_manual_record"
                }

            } else {

                if (nwsapp.currentSettings.style == value) {
                    render.addClassesToAllChildrenOf(item, "active", true)
                    elements.icon.textContent = "check"
                    mystatusObject[value] = true

                } else {
                    render.addClassesToAllChildrenOf(item, "active", false)
                    elements.icon.textContent = "fiber_manual_record"
                    mystatusObject[value] = false
                }
            }
        }
    }

    for (let item of availableStyles) {


        let button = addWhatWhere(components.ui.mode_switch, interactive_controls.panels.styles)
        let elements = $$$$(button, "control_el")
        button.setAttribute("settings_value", item.value)




        elements.label.textContent = item.label

        button.onclick = function () {
            elementClick(item.value)
            interactive_controls.refresh(true)
        }



        let settings = nwsapp.currentProject.config.variations.settings[nwsapp.currentSettings.mode].styles[item.value]
        interactive_controls.refreshStatus(settings, elements)

        elements.on_switch.onclick = function (e) {
            settings.enabled = !settings.enabled
            interactive_controls.refreshStatus(settings, elements)
            e.stopPropagation()
            e.preventDefault()
        }

    }

    refreshElements()

    function elementClick(value) {
        if (interactive_controls.multi) {
            mystatusObject[value] = !mystatusObject[value]
        } else {
            nwsapp.currentSettings.style = value
            edit_native.refreshContent(nwsapp.activeVariation, true)
        }
    }

}

export function toggleValues(object) {

    let toggleValue = false
    let allFalse = true
    for (let key of Object.keys(object)) {
        if (object[key]) {
            allFalse = false
        }
    }

    if (allFalse) {
        toggleValue = true
    } else {
        toggleValue = false
    }


    for (let item of Object.keys(object)) {
        object[item] = toggleValue
    }
    interactive_controls.refresh(true)

}

export function variations() {

    let mediaModeColors = {
        carousel: "#4CAF50",  // Green
        scratch: "#FFC107",   // Amber
        reveal: "#9C27B0",    // Purple
        zoom: "#03A9F4",      // Light Blue
        onemedia: "#E91E63",  // Pink
        countdown: "#FF9800", // Orange
        products: "#00BCD4"   // Cyan
    };


    if (!interactive_controls.status.variations) {
        interactive_controls.status.variations = {}
    }
    let mystatusObject = interactive_controls.status.variations


    let clearButton = $$$(interactive_controls.heads.variations, "head_filter", "clear")
    clearButton.onclick = function (e) {
        e.stopPropagation()
        e.preventDefault()
        interactive_controls.toggleValues(interactive_controls.status.variations)
    }

    function refreshElements() {

        for (let item of interactive_controls.panels.variations.childNodes) {
            let value = item.getAttribute("variation")
            let elements = $$$$(item, "control_el")

            if (interactive_controls.multi) {

                if (typeof mystatusObject[value] == "undefined") {
                    mystatusObject[value] = true
                }

                if (mystatusObject[value]) {
                    render.addClassesToAllChildrenOf(item, "active", true)
                    elements.icon.textContent = "check"
                } else {
                    render.addClassesToAllChildrenOf(item, "active", false)
                    elements.icon.textContent = "fiber_manual_record"
                }


            } else {

                if (nwsapp.currentVariation.id == value) {
                    render.addClassesToAllChildrenOf(item, "active", true)
                    elements.icon.textContent = "check"
                    mystatusObject[value] = true

                } else {
                    render.addClassesToAllChildrenOf(item, "active", false)
                    elements.icon.textContent = "fiber_manual_record"
                    mystatusObject[value] = false
                }
            }
        }
    }

    removeAllChildNodes(interactive_controls.panels.variations)
    nwsapp.currentProject.config.content.forEach((variation, i) => {

        let button = addWhatWhere(components.ui.mode_switch, interactive_controls.panels.variations)
        let elements = $$$$(button, "control_el")
        button.setAttribute("variation", variation.id)
        button.setAttribute("index", i)


        let myMode = variation.modes[nwsapp.currentSettings.mode][nwsapp.currentSettings.style][nwsapp.currentSettings.size]
        elements.mode.style.backgroundColor = mediaModeColors[myMode]
        let variationLine = variation.native.line1
        //check if it's over 30 characters
        if (variationLine.length > 16) {
            variationLine = variationLine.substring(0, 16) + "..."
        }


        elements.label.textContent = i + 1 + ". " + variationLine

        function refreshStatus() {
            if (variation.enabled) {
                elements.status_color.classList.add("is_active")
                elements.status_label.textContent = "On"
            } else {
                elements.status_color.classList.remove("is_active")
                elements.status_label.textContent = "Off"
            }
        }


        refreshStatus()

        elements.on_switch.onclick = function (e) {

            variation.enabled = !variation.enabled

            refreshStatus()
            e.stopPropagation()
            e.preventDefault()
        }


        button.onclick = function () {
            let value = button.getAttribute("variation")
            elementClick(value)
            event.stopPropagation()
            event.preventDefault()
        }

        function elementClick(value) {
            if (interactive_controls.multi) {
                mystatusObject[value] = !mystatusObject[value]
                interactive_controls.refresh(true)
            } else {
                let index = button.getAttribute("index")
                native.activateNavigationItem(index)
                edit_native.refreshContent(index, true)
                nativemedia.activateMediaTab() //to check for duplicates!
                interactive_controls.refresh(true)
            }
        }

    })

    refreshElements()

}

export function themes() {

    let colorThemes = []

    let availableThemes = native.settings[nwsapp.currentSettings.mode].styles[nwsapp.currentSettings.style].colors


    availableThemes.forEach((el, i) => {
        colorThemes.push({
            "label": el.name,
            "value": el.name,
            "colors": el.colors,
        })
    })

    removeAllChildNodes(interactive_controls.panels.themes)


    if (!interactive_controls.status.themes) {
        interactive_controls.status.themes = {}
    }
    let mystatusObject = interactive_controls.status.themes


    function refreshElements() {

        for (let item of interactive_controls.panels.themes.childNodes) {
            let value = item.getAttribute("settings_value")
            let elements = $$$$(item, "control_el")


            if (interactive_controls.multi) {

                if (typeof mystatusObject[value] == "undefined") {
                    mystatusObject[value] = true
                }

                if (mystatusObject[value]) {
                    render.addClassesToAllChildrenOf(item, "active", true)
                    elements.icon.textContent = "check"
                } else {
                    render.addClassesToAllChildrenOf(item, "active", false)
                    elements.icon.textContent = "fiber_manual_record"
                }
            } else {

                if (nwsapp.currentSettings.colorTheme == value) {
                    render.addClassesToAllChildrenOf(item, "active", true)
                    elements.icon.textContent = "check"
                    mystatusObject[value] = true
                } else {
                    render.addClassesToAllChildrenOf(item, "active", false)
                    elements.icon.textContent = "fiber_manual_record"
                    mystatusObject[value] = false
                }
            }
        }
    }

    let index = 0






    for (let item of colorThemes) {

        index++

        let button = addWhatWhere(components.ui.color_switch, interactive_controls.panels.themes)
        let elements = $$$$(button, "control_el")
        button.setAttribute("settings_value", item.value)

        elements.label.textContent = index

        removeAllChildNodes(elements.colors_container)

        //console.log("going throug", item.colors)


        try {

            let colorsAdded = 0
            for (let color of Object.keys(item.colors)) {
                if (colorsAdded < 8) {
                    let el = addWhatWhere(components.ui.color_preview_unit, elements.colors_container)
                    el.firstChild.style.backgroundColor = item.colors[color]
                    colorsAdded++
                }
            }

        } catch (e) {
            //console.log("error rendering color")
            continue
        }


        button.onclick = function () {
            elementClick(item.value)
            interactive_controls.refresh(true)
        }


        let settings = nwsapp.currentProject.config.variations.settings[nwsapp.currentSettings.mode]

        function refreshStatus() {
            //check if size is part of enabledThemes
            //switches on the on off butotn
            if (settings.enabledThemes.includes(item.value)) {
                elements.status_color.classList.add("is_active")
                elements.status_label.textContent = "On"
            } else {
                elements.status_color.classList.remove("is_active")
                elements.status_label.textContent = "Off"
            }
        }


        refreshStatus()

        elements.on_switch.onclick = function (e) {

            if (settings.enabledThemes.includes(item.value)) {
                settings.enabledThemes = settings.enabledThemes.filter(el => el != item.value)
            } else {
                settings.enabledThemes.push(item.value)
            }
            refreshStatus()
            e.stopPropagation()
            e.preventDefault()
        }

    }

    refreshElements()

    function elementClick(value) {

        if (interactive_controls.multi) {
            mystatusObject[value] = !mystatusObject[value]
        } else {
            nwsapp.currentSettings.colorTheme = value
            edit_native.refreshContent(nwsapp.activeVariation, true)
            nativestyles.setUpAdColorPickers()
        }

    }

}



export function refreshStatus(settings, elements) {

    //switches on the on off butotn
    if (settings.enabled) {
        elements.status_color.classList.add("is_active")
        elements.status_label.textContent = "On"
    } else {
        elements.status_color.classList.remove("is_active")
        elements.status_label.textContent = "Off"
    }
}




