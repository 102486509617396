export var css_background = {
    init: function () {

        

        if (!this.ui) {
            let container = $$$(document, "data-group", "background")
            this.ui = {
                "panel": container,
                "background_color": $$$(container, "combo-background", "background-color"),
                "clipping": $$$(container, "data-dropdown", "background-clip"),
                "background_color_picker": $$$(container, "data-input", "background-color"),
                "element": $$$(container, "color_pickers", "background"),
            }
        }

        let colorPickerSettings = {
            "element": css_background.ui.element,
            "selector": css_background.ui.background_color_picker,
            "default": css_utils.checkValuesFromAncestors({ "variable": "background-color" })?.existingCssValue,
            "targetObject": publicai.active.lastStyleInStack.css,
            "label": "",
            "targetVariable": "background-color",
            "targetDocumentObject": publicai.selected.element,
            "showColorCode": false,
            "callback": refreshBgColor,
            "name": generateSceneID(),
            //"cover": css_borders.ui.color_cover_element, //element on top
        }

        css_utils.input({
            "selector": css_background.ui.background_color,
            "variable": "background-color",
            "color": colorPickerSettings,
            "units": false,
            "validation": "color",
        })



        function refreshBgColor(s) {
            //console.log(s)
        }


        let background_clip = [

            {
                label: "None",
                value: "border-box",
                comment: "The background extends to the outside edge of the border (but underneath the border in z-ordering)."
            },
            {
                label: "Padding Box",
                value: "padding-box",
                comment: "The background extends to the outside edge of the padding."
            },
            {
                label: "Content Box",
                value: "content-box",
                comment: "The background is painted within (clipped to) the content box."
            },
            {
                label: "Text",
                value: "text",
                comment: "The background is painted within (clipped to) the text box."
            },
            {
                label: "Border Box",
                value: "border-box",
                comment: "The background extends to the outside edge of the border (but underneath the border in z-ordering)."
            },
            {
                label: "Initial",
                value: "initial",
                comment: "Sets this property to its default value."
            },
            {
                label: "Inherit",
                value: "inherit",
                comment: "Inherits this property from its parent element."
            },

        ]

        css_utils.dropdown({
            "selector": css_background.ui.clipping,
            "values": background_clip,
            "label": "Clipping",
            "style": null,
            "direction": "center",

        })


    },
}