//handles saves, edits, etc in Projects
export var handle = {
    init: async function (objectToClone, mode) {

        nwsapp.parallel = {
            "retrieved": false,
            "lastRetrieved": null,
            "lastSaved": null,
        }
        nwsapp.currentSection = "handle"


        if (!nwsapp.playground) {

            if (nwsapp.profile == null && token != null && token != "") {
                await fetchAuthenticate()
            }

            /*   if (nwsapp.profile.role != "analyst") {
              } */
        } else {

            nwsapp.profile = {
                role: "guest"
            }

        }

        nwsapp.context = "player"
        checkIfTokenIsExpired()


        nwsapp.sync = new DataSync();


        if (nwsapp.playground) {
            delete nwsapp.currentProject;

            function extractIdFromUrl(url) {
                const parts = url.split('/');
                const projectsIndex = parts.indexOf('projects');
                if (projectsIndex === -1 || projectsIndex + 1 >= parts.length) {
                    throw new Error('ID not found in the URL');
                }
                return parts[projectsIndex + 1];
            }


            let projectId = window.location.href.split("handle")[1].split("/")[1].split("/")[0]
            nwsapp.currentProject = await nwsapp.sync.retrieveData(projectId, "content")

            try {

                nwsapp.currentProject = JSON.parse(nwsapp.currentProject)
                nwsapp.currentProject.config = JSON.parse(nwsapp.currentProject.config)

            } catch (err) {

            }

            await handle.open()
            return
        }


        if (nwsapp.currentProject && nwsapp.currentProject.projectType == "NATIVE") {
            nwsapp.currentProject.id = nwsapp.currentProject.publicId
            nwsapp.currentParameter = nwsapp.currentProject.id
            this.getSpecificProject()
            return
        }


        if (nwsapp.currentProject == undefined && nwsapp.currentParameter != undefined) {
            await this.getSpecificProject()
        }
        else if (nwsapp.currentProject != undefined && nwsapp.currentParameter == undefined) {

            await handle.open()
        } else {
            this.getSpecificProject()
        }

    },


    open: async function () {


        if (!nwsapp.sync) {
            nwsapp.sync = new DataSync(); //if opened via project manager
        }


        if (typeof public_system == "undefined") {
            public_system = {
                library: nwsapp.currentProject.config.library,
                fonts: {
                    loaded: new Set()
                }
            }
        }
        delete _pages.selected
        nwsapp.currentParameter = nwsapp.currentProject.id
        nwsapp.currentSection = "handle"
        urlConfig(nwsapp.currentSection + "/" + nwsapp.currentParameter, "Opening...")

        if (nwsapp.currentProject.projectType == "NATIVE") {
            //console.log("native")
            //native.init()
            setTimeout(() => {
                native_router.init()
            }, 50)
        }


        if (nwsapp.currentProject.projectType == "INTERACTIVE") {
            await editor.editorInit()
            setTimeout(() => {
                _routing.init()
            }, 50)
        }

        if (nwsapp.currentProject.projectType == "PITCH") {
            //await editor.editorInit()
            removeAllChildNodes(document.body)


            let containerToAdd = components.ui.main_project_pitch
            addWhatWhere(containerToAdd, document.body, "", "")

            setTimeout(function () {
                addWhatWhere(components.configurators.project, document.querySelector(".configpanel"))
                if (settings.chapters == null) {
                    edit.createFirstConversation(null)
                }
                else {
                    addDynamicallyCreatedChaptersToProject(settings.chapters)
                }

                pitchmanager.init()
            }, 25);



            //console.log("editor loaded")
            setTimeout(() => {
                //_routing.init()
            }, 50)
        }

        handle.manageSync()


    },

    manageSync: async function (action) {


        if (!nwsapp.sync || !nwsapp.sync.data || !nwsapp.sync.data.project) {
            nwsapp.sync.data.project = await nwsapp.sync.getProject(nwsapp.currentProject.id)
        }

        if (!nwsapp.sync.data.project.data) {
            //projet has never been saved
            let project = {
                id: nwsapp.currentProject.id,
                config: null,
                analytics: [],
                comments: {},
                created: new Date().toISOString()
            }

            await nwsapp.sync.saveProject(project)
            nwsapp.sync.data.project = await nwsapp.sync.getProject(nwsapp.currentProject.id)
        }

        if (action == "updateConfig") {
            await nwsapp.sync.saveConfig(nwsapp.currentProject.id, nwsapp.currentProject, "content")
        }

        //console.log("savedProject", savedProject)



    },

    createNew: function () {
        //text, rich text, image, multi image, video link, link, email, phone, number, date time, switch, color, opionion, file, reference, multi reference
        let config = getConfigFluff()

        config.css = {}
        config.definitions = {}
        config.formats = _defaults.getSizes()
        config.transitions = {}
        config.effects = {}
        config.library = {
            copy: {},
            folders: {
                root: {
                    "id": null,
                    "ancestor": null,
                    "name": "root",
                    "role": "root",
                    "publicId": null,
                    "folderName": generateShortId(6)
                },

            },
            voiceover: {

            },
            files: {
                "3fb372a4-df6b-11ee-86e5-2c7e1d97ad4e": {
                    "size": 814,
                    "ready": true,
                    "id": "TWVkaWFOb2RlOjIzMDc5MDM=",
                    "publicId": "3fb372a4-df6b-11ee-86e5-2c7e1d97ad4e",
                    "url": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/check_white-4.svg/compress/",
                    "mediaType": "VECTOR",
                    "meta": {},
                    "previewUrl": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/check_white-4.svg/thumbnail/",
                    "created": "1710136221828.7139",
                    "fileName": "check_white-4.svg",
                    "width": null,
                    "height": null,
                    "licensed": false,
                    "tags": {

                    },
                    "description": {
                        "vision": null,
                        "modified": "11 March 2024 at 05:50:21 am",
                        "entries": {
                            "general": "Content description"
                        }
                    },
                    "info": {
                        "width": 150,
                        "height": 150,
                        "aspectRatio": 1
                    }
                },


                "4e7d6bfe-e5e1-11ee-b6ea-7f99467840c9": {
                    "size": 669,
                    "ready": true,
                    "id": "TWVkaWFOb2RlOjIzMTUxNDM=",
                    "publicId": "4e7d6bfe-e5e1-11ee-b6ea-7f99467840c9",
                    "url": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/1arrow_up-1.svg/compress/",
                    "mediaType": "VECTOR",
                    "meta": {},
                    "previewUrl": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/1arrow_up-1.svg/thumbnail/",
                    "created": "1710846634231.241",
                    "fileName": "1arrow_up-1.svg",
                    "width": null,
                    "height": null,
                    "licensed": false,
                    "tags": {
                        "edges": [
                            {
                                "node": {
                                    "id": "VGFnTm9kZTo4NjYyOA==",
                                    "publicId": "446b162a-e5e1-11ee-bb1e-52a313cbed4c",
                                    "name": "matrix-maverick_quei8b"
                                }
                            }
                        ]
                    },
                    "description": {
                        "vision": null,
                        "modified": "19 March 2024 at 11:10:34 am",
                        "entries": {
                            "general": "Content description"
                        }
                    },
                    "info": {
                        "width": 24,
                        "height": 24,
                        "aspectRatio": 1
                    }
                },

                "39ac3d18-e20c-11ee-92e6-3992b7f683f6": {
                    "size": 318,
                    "ready": true,
                    "id": "TWVkaWFOb2RlOjIzMTE2NTE=",
                    "publicId": "39ac3d18-e20c-11ee-92e6-3992b7f683f6",
                    "url": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/paperclip_icon-1.svg/compress/",
                    "mediaType": "VECTOR",
                    "meta": {},
                    "previewUrl": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/paperclip_icon-1.svg/thumbnail/",
                    "created": "1710425263014.171",
                    "fileName": "paperclip_icon-1.svg",
                    "width": null,
                    "height": null,
                    "licensed": false,
                    "tags": {
                        "edges": [
                            {
                                "node": {
                                    "id": "VGFnTm9kZTo4NjU1MQ==",
                                    "publicId": "2830a538-e20c-11ee-8273-b7eb22c14e92",
                                    "name": "astral-puzzle-prodigy_zijzfe"
                                }
                            }
                        ]
                    },
                    "description": {
                        "vision": null,
                        "modified": "14 March 2024 at 02:07:43 pm",
                        "entries": {
                            "general": "Content description"
                        }
                    },
                    "info": {
                        "width": 150,
                        "height": 150,
                        "aspectRatio": 1
                    }
                },

                "4b6c4483-e07b-11ee-9900-1a16b4c5d46c": {
                    //menu
                    "size": 605,
                    "ready": true,
                    "id": "TWVkaWFOb2RlOjIzMDk0NTc=",
                    "publicId": "4b6c4483-e07b-11ee-9900-1a16b4c5d46c",
                    "url": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/menu_item.svg/compress/",
                    "mediaType": "VECTOR",
                    "meta": {},
                    "previewUrl": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/menu_item.svg/thumbnail/",
                    "created": "1710253064605.518",
                    "fileName": "menu_item.svg",
                    "width": null,
                    "height": null,
                    "licensed": false,
                    "tags": {
                        "edges": [
                            {
                                "node": {
                                    "id": "VGFnTm9kZTo4NjUxOQ==",
                                    "publicId": "444780f2-e07b-11ee-8fde-c74e6dab8264",
                                    "name": "ethereal-inquirer_80pkea"
                                }
                            }
                        ]
                    },
                    "description": {
                        "vision": null,
                        "modified": "12 March 2024 at 02:17:44 pm",
                        "entries": {
                            "general": "Content description"
                        }
                    },
                    "info": {
                        "width": 32,
                        "height": 32,
                        "aspectRatio": 1
                    }
                },

                placeholder_audio: {
                    "id": "TWVkaWFOb2RlOjIzOTAwOTQ=",
                    "url": "https://cdn.thenewsroom.io/platform/story_media/1288826107/epic-cinematic-logo-04-long.wav",
                    "meta": {},
                    "size": 321853,
                    "tags": {},
                    "ready": true,
                    "width": null,
                    "height": null,
                    "created": "1708962967959.495",
                    "fileName": "epic-cinematic-logo-04-long.wav",
                    "publicId": "2f85bad6-2d87-11ef-91f8-cd72cdeb270d",
                    "previewUrl": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/kalgoorlie.jpg/thumbnail/",
                    "type": "AUDIO",
                },


                placeholder: {
                    "id": "TWVkaWFOb2RlOjIyOTY1NDc=",
                    "url": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/kalgoorlie.jpg/compress/",
                    "info": {
                        "width": 299,
                        "height": 200,
                        "aspectRatio": 1.495
                    },
                    "meta": {},
                    "size": 321853,
                    "tags": {},
                    "ready": true,
                    "width": null,
                    "height": null,
                    "created": "1708962967959.495",
                    "fileName": "kalgoorlie.jpg",
                    "licensed": false,
                    "publicId": "8de66043-d4bf-11ee-81f5-2e362ea9bd39",
                    "mediaType": "IMAGE",
                    "previewUrl": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/kalgoorlie.jpg/thumbnail/"
                }
            },
            tags: {},
        }
        config.sources = {
            "collections": {},
            "schemasInUse": [],
            "schemas": {}
        }


        let projectName = getRandomElement(handle.randomProjectNames) + "_" + generateShortId()
        let projectSlug = projectName.replace(/\s+/g, '-').toLowerCase()

        config.library.folders.root.folderName = "Project Media"

        config.root = {

            pages: {
                //these are actual pages
                "id": generateSceneID(),
                "type": "folder",
                "homepage": null,
                "ancestor": null,
                "properties": {
                    "name": projectName,
                    "slug": projectSlug,
                    "description": "",
                    "code": "",
                    "meta": "",
                    "js": "",
                    "entry": ""
                },
                "content": []
            },
            "symbols": {}, //stores user-made symbols
            "components": null, //stores re-useable components file paths
            definitions: {
                classes: _defaults.pageCSS,

            },
            utility: {
                //these are utility pages like 404, error, etc
                "id": generateSceneID(),
                "type": "utility",
                "homepage": null,
                "ancestor": null,
                "content": [],
            },

        }

        config.root.definitions.classes.keyframes = { ..._defaults.keyframes }


        let data = JSON.stringify({
            "name": projectName,
            "slug": projectSlug,
            "config": config,
            "isTemplate": false,
            "projectType": "INTERACTIVE",
            "websiteId": auth.getCookie("websiteId")
        })


        return data

        //let options = graphql.buildRequest("createAirtypeProject", "", data);



        /*   fetch(url, options)
              .then((response) => response.json())
              .then(json => {
                  nwsapp.currentProject = json.data.createAirtypeProject.project
                  let projectId = nwsapp.currentProject?.id || nwsapp.currentProject.publicId
  
                  nwsapp.currentParameter = projectId
                  nwsapp.currentProject.id = projectId
                  nwsapp.currentProject.config = JSON.parse(nwsapp.currentProject.config)
  
                  //if the original slug was taken, the root folder will now use the new slug
                  nwsapp.currentProject.config.root.pages.properties.name = nwsapp.currentProject.name
                  nwsapp.currentProject.config.root.pages.properties.slug = nwsapp.currentProject.slug
                  handle.open()
              });
   */

    },

    randomProjectNames: [
        "Enigma Elicitator",
        "Nebula Whisperer",
        "Quantum Queryer",
        "Cosmic Conundrum Solver",
        "Galactic Riddle Master",
        "Ethereal Inquirer",
        "Astral Puzzle Prodigy",
        "Supernova Seeker",
        "Celestial Challenge Champion",
        "Stellar Mindbender",
        "Interstellar Enigmatist",
        "Dimensional Dilemma Decoder",
        "Pixel Puzzler",
        "Cyberpunk Codebreaker",
        "Matrix Maverick",
        "Warp Speed Wordsmith",
        "Sherlock Syntax",
        "Phantom of the Opera Parser",
        "Beatles Bug Fixer",
        "Casablanca Coder",
        "Wizard of OS",
        "Da Vinci Decoder",
        "Mozarts Melody Maker",
        "Romeo and Juliet Router",
        "Hitchcocks Hyperlinker",
        "Gatsbys Gigabyte",
        "Hogwarts Hexmaster",
        "Jedi Mind Trickster",
        "Indiana Jones Inquirer",
        "Back to the Future Brainiac",
        "X-Files Xpert",
        "Hitchhikers Guide Guru",
        "Pokemon Puzzle Pro",
        "Terminator Troubleshooter"
    ],


    getSpecificProject: async function () {
        try {
            let o = graphql.buildRequest("getSpecificProject", "", nwsapp.currentParameter);
            const response = await fetch(url, o);
            const data = await response.json();

            delete nwsapp.currentProject;
            nwsapp.currentProject = data.data.airtypeProject;

            nwsapp.currentProject.config = JSON.parse(nwsapp.currentProject.config);
            await handle.open(); // Now correctly waiting for the handle to open
        } catch (error) {
            console.log("can't find it", error);
        }
    },



    save: function () {

        console.log("save...")
        saveProject()

    },

    _save: function (confirmationMessage) {
        return new Promise(async (resolve, reject) => {
            if (nwsapp.playground) {
                resolve('Playground mode, save skipped.');
                return;
            }

            handle.manageSync("updateConfig");

            try {
                await edit.checkLockStatus(async () => {
                    if (typeof nwsapp.currentProject.publicId === "undefined") {
                        nwsapp.currentProject.publicId = nwsapp.currentProject.id;
                    }
                    if (typeof nwsapp.currentProject.id === "undefined") {
                        nwsapp.currentProject.id = nwsapp.currentProject.publicId;
                    }

                    if (typeof nwsapp.currentProject.isTemplate === "undefined") {
                        nwsapp.currentProject.isTemplate = false;
                    }

                    if (confirmationMessage) {
                        notifications.show([
                            ["Saving...", "This should take a few seconds."]
                        ]);
                    }

                    let data = JSON.stringify({
                        "name": nwsapp.currentProject.name,
                        "slug": nwsapp.currentProject.slug,
                        "id": nwsapp.currentProject.id,
                        "isTemplate": nwsapp.currentProject.isTemplate,
                        "config": nwsapp.currentProject.config,
                    });

                    let options = graphql.buildRequest("updateAirtypeProject", "", data);

                    const response = await fetch(url, options);
                    const json = await response.json();
                    if (confirmationMessage) {
                        notifications.show([
                            [getCongratulationsMessage(), "Your project has been saved."]
                        ]);
                    }
                    resolve(json); // Resolving with the JSON response from the fetch
                });
            } catch (error) {
                console.error("Save operation failed:", error);
                reject(error); // Reject the promise if there is an error
            }
        });
    },



    loadTemplates: async function (forceRefresh) {


        console.log("FIXme - public_system not ready.")


        return

        handle.loadPackageTemplates(true)

        // Check if templates are already loaded and forceRefresh is not required
        if (public_system.templates && !forceRefresh) {
            return; // Templates are already loaded, nothing more to do
        }

        try {
            let options = graphql.buildRequest("getProjectTemplates", "", "");



            let response = await fetch(url, options);
            let json = await response.json();

            //console.log("templates", json)

            // Assuming public_system.templates is an array that needs to be populated with the fetched data
            public_system.templates = json.data.airtypeProjectTemplates.edges;
            public_system.templates.forEach((el, index) => {
                el.node.config = JSON.parse(el.node.config);
            });
            //console.log("Templates have loaded")
            // If there's any further processing needed after loading templates, it can be done here
        } catch (error) {
            console.log('Error loading templates:', error);
            throw error; // Rethrow the error if you want the caller to handle it
        }
    },

    loadPackageTemplates: async function (forceRefresh) {
        // Check if templates are already loaded and forceRefresh is not required
        console.log("FIXme - public_system not ready.")
        return

        if (typeof public_system != "undefined" && public_system.templates && !forceRefresh) {
            return; // Templates are already loaded, nothing more to do
        }

        try {
            let options = graphql.buildRequest("packageTemplates", "", "");
            let response = await fetch(url, options);
            let json = await response.json();

            //console.log("package templates", json)

            // Assuming public_system.templates is an array that needs to be populated with the fetched data
            public_system.packages = json.data.airtypeProjectTemplates.edges;
            public_system.packages.forEach((el, index) => {
                el.node.config = JSON.parse(el.node.config);
            });
            //console.log("Packages have loaded", json)
            // If there's any further processing needed after loading templates, it can be done here
        } catch (error) {
            console.error('Error loading templates:', error);
            throw error; // Rethrow the error if you want the caller to handle it
        }
    },


    saveTemplate: function (settings) {
        let config = {
            "type": "project",
            "template": settings.config,
        };

        let data = JSON.stringify({
            "name": settings.name,
            "slug": settings.name,
            "config": config,
            "isTemplate": true,
            "projectType": settings.type,
            "websiteId": auth.getCookie("websiteId")
        });

        let options = graphql.buildRequest("createAirtypeProject", "", data);

        fetch(url, options)
            .then((response) => response.json())
            .then(json => {
                console.log("new template created", json);

                // Assuming json.data.createAirtypeProject.project.publicId is the ID
                const workerData = {
                    publicId: json.data.createAirtypeProject.project.publicId,
                    ...json.data.createAirtypeProject.project
                };

                handle.loadTemplates(true);
                if (!settings.saveOnCloudflare) {
                    //only save system templates to cloudflare
                    return
                }

                // Save to Cloudflare Worker
                fetch('https://uxcomps.newsroom-ai9687.workers.dev/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(workerData),
                })
                    .then(workerResponse => {
                        if (workerResponse.ok) {
                            return workerResponse.json().then(data => {
                                // Store the data in a variable if needed for later use
                                console.log('Saved to Cloudflare Worker', data);
                                return data; // Pass the data along to the next .then()
                            });
                        } else {
                            throw new Error('Network response was not ok.');
                        }
                    })
                    .catch(error => console.error('Error:', error));


                notifications.show([
                    [getCongratulationsMessage(), "The template has been saved."]
                ]);


            });
    },




    updateTemplate: function (settings, id) {

        console.log("updating template", settings, id)

        let config = {
            "type": "project",
            "template": settings.config,
        };

        let data = JSON.stringify({
            "name": settings.name,
            "slug": settings.name,
            "id": id,
            "config": config,
            "isTemplate": true,
        });

        let options = graphql.buildRequest("updateAirtypeProject", "", data);

        fetch(url, options)
            .then((response) => response.json())
            .then(json => {
                //console.log("Template has been updated", json);

                // Use the same ID for updating in Cloudflare Worker
                // Before sending data in updateTemplate
                const workerData = {
                    publicId: id,
                    name: settings.name,
                    slug: settings.name,
                    config: JSON.stringify(config), // Ensure config is correctly included and stringified
                    isTemplate: true,
                    projectType: "DISPLAY",
                    websiteId: auth.getCookie("websiteId")
                };




                notifications.show([
                    [getCongratulationsMessage(), "The data has been updated."]
                ]);

                handle.loadTemplates(true);

                if (!settings.saveOnCloudflare) {
                    console.log("not saving on cloudflare")
                    return
                }

                console.log("workerData", workerData)

                // Update in Cloudflare Worker
                fetch('https://uxcomps.newsroom-ai9687.workers.dev/', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(workerData),
                })
                    .then(workerResponse => workerResponse.json())
                    .then(workerJson => {
                        //ux.extractFromComponents()
                        console.log('Updated in Cloudflare Worker', workerJson)

                    });



            });
    },


    deleteTemplate: function (id, deleteFromCloudflare) {
        let data = JSON.stringify({
            "id": id,
        })

        let options = graphql.buildRequest("deleteAirtypeProject", "", data);

        if (deleteFromCloudflare) {
            handle.deleteCloudflareTemplateById(id).then(() => {
                console.log('Template has been deleted.');
            })

        }

        fetch(url, options)
            .then((response) => response.json())
            .then(json => {
                console.log("template deleted", json)

                notifications.show([
                    [getCongratulationsMessage(), "The template has been deleted."]
                ])

                handle.loadTemplates(true)




            });
    },

    // Function to delete a template by ID
    deleteCloudflareTemplateById: function (id) {
        return fetch(`https://uxcomps.newsroom-ai9687.workers.dev/?id=${id}`, {
            method: 'DELETE'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json(); // Parse and return the JSON response
            })
            .then(data => {
                console.log('Template deleted:', data);
            })
            .catch(error => {
                console.error('Error deleting the template:', error);
            });
    },







}