import { sidePanelUpdate } from '../modules/utils/system.js'

export async function init(path) {
    console.log("logging out", path);
    handleLogout()

}



export function handleLogout() {
    fetch('/logout', {
        method: 'POST',  // Use POST for logout action
        credentials: 'include', // Send cookies with the request
    })
        .then(response => {
            if (response.ok) {
                // Clear any client-side data
                document.cookie = "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                document.cookie = "idToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                document.cookie = "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                document.cookie = "googleUser=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                delete app.profile

                // Optionally redirect the user to a login page or homepage
                //window.location.href = '/login'; // Adjust to your login page path
                sidePanelUpdate()
                router.navigateTo('/create');
                

                console.log('Logout successful');

            } else {
                console.error('Logout failed');
            }
        })
        .catch(error => console.error('Error during logout:', error));
}
