export var css_input = {

    input: function (settings) {

        let items = {
            "selector": settings.selector,
            "labelContainer": settings.selector.querySelector(".reg_label"),
            "input": $$$(settings.selector, "data-input", "input"),
            "label": settings.selector.querySelector(".field_value"),
            "unit": settings.selector.querySelector(".unit_indicator"),
        }


        let objectToSaveTo = publicai.active.lastStyleInStack.css
        //values may be saved into different objects - such as the shadow object.

        /*  if (settings.object) {
             objectToSaveTo = settings.object
         } */

        if (settings.label) {
            items.label.textContent = settings.label
        }


        if (settings.doubleClick) {

            //console.log("settings.variable", settings.variable)

            let acceptedInputs = [
                "width",
                "height",
                "max-width",
                "max-height",
                "min-width",
                "min-height"

            ]

            if (acceptedInputs.includes(settings.variable)) {

                items.input.ondblclick = (e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    items.input.focus()
                    let defaultValue = "100%"

                    objectToSaveTo[settings.variable] = defaultValue
                    items.input.value = defaultValue


                    checkVariableStatus()
                    items.input.blur()
                }
            }

        }


        if (settings.rightClick) {
            items.input.oncontextmenu = (e) => {

                css_utils.reg_dropdown({
                    "selector": items.input,
                    "values": settings.rightClick,
                    "label": "Add new element",
                    "styles": ["auto"],
                    "customMode": true,
                    "save": false,
                    "callback": applyRightClickValues,
                    "direction": "up",
                    "overlayColor": "rgb(107 95 88)",
                    "overlayOpacity": 0.08,
                    "width": 120
                })

                function applyRightClickValues(s) {

                    console.log("objectToSaveTo[settings.variable]", objectToSaveTo[settings.variable])


                    items.input.focus()
                    objectToSaveTo[settings.variable] = s.value
                    items.input.value = s.value

                    checkVariableStatus()
                    items.input.blur()

                }

                e.stopPropagation()
                e.preventDefault()
            }
        }

        render.addClassesToAllChildrenOf(items.selector, "hasvalue", false)
        render.addClassesToAllChildrenOf(items.selector, "hasinheritedvalue", false)

        items.labelContainer.onclick = (e) => {
            e.stopPropagation()
            e.preventDefault()
            document.addEventListener('click', css_utils.closeExtraPanels);
            css_utils.openAncestoryPanel(items.labelContainer, settings.variable, items.selector.getAttribute("data-align-panel") || null)
        }




        checkVariableStatus()

        function removeUnitFromInput(input, pass) {


            if (input.value.toLowerCase().includes("auto")) {
                objectToSaveTo[settings.variable] = "auto"
                input.value = "Auto"
                items.input.blur()
                //css_management.manage()
                return ("-")
            }

            // Remove any previous unit from the value and extract numeric part
            const valueWithoutUnit = parseFloat(input.value);

            if (valueWithoutUnit == input.value) {
                //no units have been entered
                return (items.unit)
            }

            // Identify and extract the unit (px, em, vw, vh, %)
            const unitMatch = input.value.match(/px|em|vw|vh|%$/);


            const unit = unitMatch ? unitMatch[0] : "px"; // Default to px if no unit found
            // Update the items object
            input.value = valueWithoutUnit; // Update with the numeric value only
            return (unit)
        }










        function validateInputOnBlur(event) {


            //console.log("validating in put on blur", settings.variable, items.input.value, settings)

            //console.log("input blur", settings.variable, items.input.value)
            if (settings.validation != "color") {
                if (items.input.value != "") {

                    if (objectToSaveTo[settings.variable]) {
                        let unit = removeUnitFromInput(items.input)
                        if (allowedUnits.includes(unit)) {
                        } else {
                            //console.log("not part of allowed units", allowedUnits) // probably none has been entered
                            let unitMatch = objectToSaveTo[settings.variable].match(/px|em|vw|vh|%$/)
                            unit = unitMatch ? unitMatch[0] : "px"; // Default to px if no unit found
                        }


                        if (items.input.value.toLowerCase().includes("auto")) {
                            items.unit.textContent = unit;
                            objectToSaveTo[settings.variable] = "Auto"
                        } else {



                            if (!isNaN(parseFloat(items.input.value))) {
                                items.unit.textContent = unit;
                                objectToSaveTo[settings.variable] = items.input.value + items.unit.textContent

                            } else {

                                console.log("here......")
                                items.input.value = ""
                                items.unit.textContent = "-"
                                delete objectToSaveTo[settings.variable]
                                css_management.manage()
                            }
                        }

                    } else {
                        //it has beeen triggered by a blur with an inherited value. Do nothing, since value was saved on input.
                    }

                } else {
                    //console.log("is empty, deleting entry")
                    //delete objectToSaveTo[settings.variable]
                    //css_management.manage()
                    applyCSS()

                }
            } else {
                //is color
                if (objectToSaveTo[settings.variable] != items.input.value) {
                    console.log("Updating color")
                    objectToSaveTo[settings.variable] = items.input.value
                    css_management.manage()
                    applyCSS()
                }

            }

            if (settings.callback) {
                settings.callback(items.input.value)
            }
        }
        //remove all key listeners first


        let keyUp = function (event) {
            // Check if the key pressed is 'Enter'

            if (event.target === items.input && event.key === 'Enter') {



                event.stopPropagation()
                event.preventDefault()
                event.target.blur()
                validateInputOnBlur(event)
                applyCSS()
            }
        }

        items.input.removeEventListener('keyup', keyUp)
        items.input.addEventListener('keyup', keyUp);


        function arrowsCallback(event) {

            if (!isNaN(parseFloat(items.input.value))) {
                if (event.key == "ArrowUp") {
                    items.input.value = parseInt(items.input.value) + 1
                }
                if (event.key == "ArrowDown") {
                    items.input.value = parseInt(items.input.value) - 1
                }
            }
            objectToSaveTo[settings.variable] = items.input.value + items.unit.textContent;
            applyCSS(); // or whatever function is needed to apply the updated CSS

        }

        items.input.onfocus = (e) => {
            if (settings.number) {
                publicai.inputCallback = arrowsCallback
            } else {
                publicai.inputCallback = null //don't call back if input is not a number
            }
        }

        items.input.onblur = (e) => {
            //console.log(e)
            e.stopPropagation()
            e.preventDefault()
            validateInputOnBlur(e)
            applyCSS()
        }


        let allowedUnits = ["px", "em", "vw", "vh", "%"]
        items.input.oninput = (e) => {
            //check what key it was and ignore this if it was an arrow up
            objectToSaveTo[settings.variable] = items.input.value + items.unit.textContent
            render.addClassesToAllChildrenOf(items.labelContainer, "hasvalue", true)
            render.addClassesToAllChildrenOf(items.labelContainer, "hasinheritedvalue", false)
            applyCSS()
        }


        if (settings.units != false) {
            items.unit.onclick = (e) => {
                e.stopPropagation()
                e.preventDefault()
                document.addEventListener('click', css_utils.closeExtraPanels);

                let panel = addWhatWhere(components.css.css_units, items.unit)

                let allItems = $$$(items.unit, "data-unit")

                for (let unitItem of allItems) {
                    let value = unitItem.getAttribute("data-unit")
                    unitItem.onclick = (e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        items.unit.textContent = value
                        css_utils.closeExtraPanels()
                        objectToSaveTo[settings.variable] = items.input.value + value
                        applyCSS()

                    }
                }

            }
        }

        function checkVariableStatus() {

            let waterFallClassFound = false
            let acestorClassFound = false
            let inheritValuesFromAncestors = false

            if (css_utils.inheritance[settings.variable]) {
                inheritValuesFromAncestors = true
            }


            if (objectToSaveTo[settings.variable]) {
                //variable is set in class
                items.input.value = objectToSaveTo[settings.variable];
                let unit = removeUnitFromInput(items.input)

                items.unit.textContent = unit;
            } else {

                //variable may be inherited frrom css stack
                for (let css of publicai.active.cssStack) {
                    if (css.css[settings.variable]) {
                        items.input.value = css.css[settings.variable];
                        let unit = removeUnitFromInput(items.input)
                        items.unit.textContent = unit;
                        waterFallClassFound = true

                    }
                }

                if (!waterFallClassFound && !inheritValuesFromAncestors) {
                    //no values set in none of the classes or combos. 
                    items.input.value = ""
                    items.unit.textContent = "-"
                }

                if (!waterFallClassFound && inheritValuesFromAncestors) {
                    //inheritValuesFromAncestors means that inheritances are allowed for this variable
                    let inheritedValues = findClosestCssValue(settings.variable) //only if values can be inherited. Width etc are not. 
                    if (inheritedValues.length > 0) {
                        //the value is inherited from another container
                        acestorClassFound = true
                        items.input.value = inheritedValues[0].value;
                        let unit = removeUnitFromInput(items.input)
                        items.unit.textContent = unit;
                    } else {
                        items.input.value = ""
                        items.unit.textContent = "-"
                    }
                }
            }

            //adding inheritance classes
            if (objectToSaveTo[settings.variable]) {
                render.addClassesToAllChildrenOf(items.selector, "hasinheritedvalue", false)
                render.addClassesToAllChildrenOf(items.selector, "hasvalue", true)
            } else {
                render.addClassesToAllChildrenOf(items.selector, "hasvalue", false)
                if (waterFallClassFound || acestorClassFound) {
                    render.addClassesToAllChildrenOf(items.selector, "hasinheritedvalue", true)
                } else {
                    render.addClassesToAllChildrenOf(items.selector, "hasinheritedvalue", false)
                }

            }
        }

    },




}

