
import { css_input } from './css_input.js';

export var css_borders = {

    init: function () {
        //css_classes.add()

        if (!this.ui) {

            let container = $$$(document, "data-group", "borders")

            this.ui = {
                "panel": container,
                "allDataInputGroups": $$$(container, "data-input-group"),


                "side_picker": $$$(container, "data-border", "picker"),

                "radius": $$$(container, "data-input-group", "border-radius"),


                "corners_custom": $$$(container, "layout-combo", "corners"),
                "cusotom_corners_panel": container.querySelector(".custom_corners"),


                "style": $$$(container, "combo-border-style", "style"),
                "width": $$$(container, "combo-border-style", "width"),
                "color": $$$(container, "combo-border-style", "color"),

                "border_color": $$$(container, "data-input", "color"),

                "element": $$$(container, "color_pickers", "border"),

            }
        }


        css_utils.input({
            "selector": css_borders.ui.radius,
            "variable": "border-radius",
            "slider": true,
            "ranges": {
                "min": 0,
                "max": 100,
            },
            //"units": false,
            "custom_range": true,
            "validation": "auto",
        })

        css_utils.combo({
            "selector": css_borders.ui.corners_custom,
            "save": false,
            "callback": customCornerStyle
        })

        function customCornerStyle(setting) {

            //to detect if using custom corners to begin with 
            //css_borders.ui.corners_custom

            let allOptions = $$$(css_borders.ui.corners_custom, "data-value")

            function disableAllSides() {
                for (let side of allOptions) {
                    side.classList.remove("side-active")
                }
            }
            disableAllSides()

            if (setting == "custom") {
                css_borders.ui.cusotom_corners_panel.classList.add("has-custom-corners")
            } else {
                css_borders.ui.cusotom_corners_panel.classList.remove("has-custom-corners")
            }

            $$$(css_borders.ui.corners_custom, "data-value", setting).classList.add("custom-active")
        }

        customCornerStyle("auto")


        css_utils.combo({
            "selector": css_borders.ui.side_picker,
            "save": false,
            "callback": refreshBorderSide
        })

        function refreshBorderSide(side) {

            let allSidePickers = $$$(css_borders.ui.side_picker, "data-value")
            for (let el of allSidePickers) {
                let side = el.getAttribute("data-value")
                if (publicai.active.lastStyleInStack.css["border-" + side + "-width"] || publicai.active.lastStyleInStack.css["border-" + side + "-style"]) {
                    el.classList.add("csshasvalue")
                } else {
                    el.classList.remove("csshasvalue")
                }
            }

            function disableAllSides() {
                for (let side of allSidePickers) {
                    side.classList.remove("side-active")
                }
            }
            if (side) {
                disableAllSides()
                $$$(css_borders.ui.side_picker, "data-value", side).classList.add("side-active")
                css_borders.currentSide = side
                css_borders.configureBorderSettings()
            }
        }

        if (!css_borders.currentSide) {
            css_borders.currentSide = "all"
        }

        refreshBorderSide(css_borders.currentSide)


    },

    configureBorderSettings: function () {



        let ui = css_borders.ui
        let side = css_borders.currentSide

        ui.style.removeAttribute("layout-combo")
        ui.width.removeAttribute("data-input-group")
        ui.color.removeAttribute("data-input-color")

        let colorPickerSettings = {
            "element": css_borders.ui.element,
            "selector": css_borders.ui.border_color,
            "default": publicai.active.lastStyleInStack.css["border-color"],
            "targetObject": publicai.active.lastStyleInStack.css,
            "label": "",
            "targetVariable": "border-color",
            "targetDocumentObject": publicai.selected.element,
            "showColorCode": false,
            "callback": updateColor,
            "name": generateSceneID(),
            //"cover": css_borders.ui.color_cover_element, //element on top
        }

        if (side == "all") {
            ui.style.setAttribute("layout-combo", "border-style")
            ui.width.setAttribute("data-input-group", "border-width")
            ui.color.setAttribute("data-input-color", "border-color")
        } else {
            ui.style.setAttribute("layout-combo", "border-" + side + "-style")
            ui.width.setAttribute("data-input-group", "border-" + side + "-width")
            ui.color.removeAttribute("data-input-color", "border-" + side + "-color")
            colorPickerSettings.default = publicai.active.lastStyleInStack.css["border-" + side + "-color"]
            colorPickerSettings.targetVariable = "border-" + side + "-color"
        }


        function updateColor(settings, thePickedColor) {

            //console.log("xx", thePickedColor)
            //applyCSS()
            //css_borders.ui.color_cover_element.style.backgroundColor = settings.thePickedColor

        }


        css_utils.combo({
            "selector": ui.style,
        })

        //console.log("re-created border widget", publicai.active.lastStyleInStack.css)

        css_input.input({
            "selector": ui.width,
            "variable": ui.width.getAttribute("data-input-group"),
            "callback": borderWidthSettings,
            "validation": "none",
        })



      /*   css_input.input({
            "selector": ui.allDataInputGroup,
            "variable": ui.allDataInputGroup.getAttribute("data-input-group"),
            "label": capitaliseFirstLetter(setting.replaceAll("-", " ")),
            "validation": "none",
            "number":  true, 
            "doubleClick": true,
        }) */


        function borderWidthSettings(settings) {

            console.log("update border style", settings)
            //css_borders.addBasicBorderSoitIsVisible()

            if (settings.value != "" && settings.value != 0) {
                if (!publicai.active.lastStyleInStack.css["border-style"]) {
                    css_borders.addBasicBorderSoitIsVisible()
                    css_borders.configureBorderSettings(css_borders.currentSide)

                }
            }
        }


        if (!ui.color_input) {
            ui.color_input = $$$(ui.color, "data-input", "input")
        }
        ui.color_input.value = "" //reset while switching sides


        //console.log("BORDER", colorPickerSettings)

        css_utils.input({
            "selector": ui.color,
            "variable": ui.color.getAttribute("data-input-color"),
            "color": colorPickerSettings,
            "units": false,
            "validation": "color",
        })


    },

    removeAllOtherBorderKeys: function () {
        let stack = publicai.active.lastStyleInStack.css
        for (let key in stack) {
            if (key.includes("border-") && key.includes("color")) {
                //console.log("fixing ", key)
                delete stack[key]
            }
        }
    },

    addBasicBorderSoitIsVisible: function () {

        //creates basic boder settings so that users sees the result of the border work

        let styleString
        let widthString
        let stack = publicai.active.lastStyleInStack.css



        if (css_borders.currentSide == "all") {
            css_borders.removeAllOtherBorderKeys() //remove side colors if all is selected
            styleString = "border-style"
            widthString = "border-width"
        } else {
            styleString = "border-" + css_borders.currentSide + "-style"
            widthString = "border-" + css_borders.currentSide + "-width"
        }

        if (!stack[styleString]) {
            stack[styleString] = "solid"
            console.log("added solid")
            applyCSS()
            css_borders.configureBorderSettings()
        }

        if (!stack["border-color"]) {
            stack["border-color"] = "#cabfbf"
            console.log("added default border color");
            applyCSS();
            css_borders.configureBorderSettings();
        }


        //console.log("stack[widthString]", stack[widthString])

        if (!stack[widthString]) {
            stack[widthString] = "1px"
            console.log("added 1px")
            applyCSS()
            css_borders.configureBorderSettings()
        }

    }

}