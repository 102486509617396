
import { config } from '../../../config.js';

import { createAnonymousUser, refreshSession, storeSessionTokens } from './cognito.js';
import { CognitoUserPool, CognitoUser, CognitoRefreshToken } from 'amazon-cognito-identity-js';


import * as system from '../../modules/utils/system.js';


export async function checkSession(req, res, next) {

    
    try {
        const accessToken = req.cookies ? req.cookies['accessToken'] : null;
        const refreshToken = req.cookies ? req.cookies['refreshToken'] : null;
        const googleUser = req.cookies && req.cookies['googleUser'] ? JSON.parse(req.cookies['googleUser']) : null;

        // Check if session is invalid (i.e., no tokens or user info)
        if (!accessToken || !refreshToken || !googleUser) {
            // Handle anonymous user registration/authentication
            const anonymousUser = await createAnonymousUser();

            // Store the new tokens in cookies
            storeSessionTokens(res, anonymousUser.cognitoTokens);
            res.cookie('googleUser', JSON.stringify(anonymousUser.googleUser), { httpOnly: false, secure: true });

            // Attach the userId to the request for further processing
            req.userId = anonymousUser.googleUser.sub;
            req.sessionInvalid = false;

            console.log("check session....ok.",)

            // Call system.routeUpdate and proceed to the next middleware
            //system.routeUpdate(req.path);
            return next();
        }

        // If accessToken exists, verify and proceed
        const decoded = decodeJwt(accessToken);
        if (!decoded) {
            req.sessionInvalid = true;
            system.routeUpdate(req.path);
            return next();
        }

        // Attach userId to the request
        req.userId = decoded.sub;

        // Check if the token is expired and refresh if needed
        const currentTime = Math.floor(Date.now() / 1000);
        if (decoded.exp < currentTime) {
            refreshSession(refreshToken, googleUser.email)
                .then(newTokens => {
                    storeSessionTokens(res, newTokens); // Update session tokens in cookies
                    req.sessionInvalid = false;
                    system.routeUpdate(req.path); // Call routeUpdate
                    next();
                })
                .catch(error => {
                    console.error("Session refresh failed:", error);
                    req.sessionInvalid = true;
                    system.routeUpdate(req.path); // Call routeUpdate
                    next();
                });
        } else {
            req.sessionInvalid = false;
            //system.routeUpdate(req.path); // Call routeUpdate
            next();
        }
    } catch (error) {
        console.error('Error checking session:', error);
        req.sessionInvalid = true;
        //system.routeUpdate(req.path); // Call routeUpdate
        next();
    }
}


export function checkSessionClient(path) {
    system.routeUpdate(path); // Call routeUpdate

    const googleUserCookie = document.cookie.split('; ').find(row => row.startsWith('googleUser'));
    if (googleUserCookie) {
        const googleUser = JSON.parse(decodeURIComponent(googleUserCookie.split('=')[1]));
        if (googleUser.isAnonymous) {
            console.log("You are signed in as an anonymous user (John Doe).");
            // Optionally show a message to encourage the user to claim the account
        } else {
            delete app.profile

            console.log("Welcome, " + googleUser.name);
            // Call system.routeUpdate and proceed to the next middleware
            
        }

        delete app.profile
        app.profile = googleUser;
        system.routeUpdate(path);
    }
}

export async function handleLoginSuccess(googleUser, cognitoTokens, res) {
    try {
        /*       console.log("Setting cookies...");
              console.log("accessToken:", cognitoTokens.accessToken);
              console.log("idToken:", cognitoTokens.idToken);
              console.log("refreshToken:", cognitoTokens.refreshToken); */

        // Store the tokens in secure HTTP-only cookies
        res.cookie('accessToken', cognitoTokens.accessToken, { httpOnly: true, secure: true });
        res.cookie('idToken', cognitoTokens.idToken, { httpOnly: true, secure: true });
        res.cookie('refreshToken', cognitoTokens.refreshToken, { httpOnly: true, secure: true });

        // Store the Google user information in cookies
        res.cookie('googleUser', JSON.stringify(googleUser), { httpOnly: false, secure: true });
        agents
        console.log('Redirecting to workspace.');

        // Redirect to workspace or dashboard
        res.redirect('/agents');
    } catch (error) {
        console.error('Error handling login success:', error);
        res.status(500).send('Error processing login.');
    }
}


/**
 * This function can also extract tokens from cookies for future use (in the app) if necessary.
 * Example usage would be in client-side logic to check for tokens and redirect accordingly.
 */
export function getUserSessionFromCookies(req) {
    const accessToken = req.cookies['accessToken'];
    const idToken = req.cookies['idToken'];
    const refreshToken = req.cookies['refreshToken'];
    const googleUser = req.cookies['googleUser'] ? JSON.parse(req.cookies['googleUser']) : null;

    if (accessToken && idToken) {
        return {
            accessToken,
            idToken,
            refreshToken,
            googleUser
        };
    }
    return null;
}



function decodeJwt(token) {
    try {
        const parts = token.split('.');
        if (parts.length !== 3) {
            console.error('Invalid JWT token format.');
            return null;
        }
        const payload = atob(parts[1]);
        return JSON.parse(payload);
    } catch (error) {
        console.error('Failed to decode JWT token:', error);
        return null;
    }
}


