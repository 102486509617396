export var media_cache = {


    captureScreenshotFromVideo: async function (videoBlob, captureTime, width, height) {
        const videoElement = document.createElement('video');
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        return new Promise((resolve, reject) => {
            const objectURL = URL.createObjectURL(videoBlob);

            videoElement.src = objectURL;

            videoElement.addEventListener('loadedmetadata', () => {
                videoElement.currentTime = captureTime;
            }, { once: true });

            videoElement.addEventListener('seeked', () => {
                canvas.width = width;
                canvas.height = height;
                context.drawImage(videoElement, 0, 0, width, height);

                canvas.toBlob(blob => {
                    URL.revokeObjectURL(objectURL);
                    resolve(blob);
                }, 'image/png');
            }, { once: true });

            videoElement.addEventListener('error', (e) => {
                URL.revokeObjectURL(objectURL);
                reject(e);
            }, { once: true });
        });
    },




    getVideoDuration: function (blob) {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = () => {
                resolve(video.duration);
                URL.revokeObjectURL(video.src);
            };
            video.onerror = (event) => {
                reject(new Error("Failed to load video metadata"));
            };
            video.src = URL.createObjectURL(blob);
        });
    },





    addCachedMediaTo: function (cachedMedia, container, mediaItem, controls, entry) {



        const waitForReady = (retries = 5, delay = 100) => {
            return new Promise((resolve, reject) => {
                const attempt = (retryCount) => {
                    cachedMedia.readyPromise.then(() => {
                        if (!cachedMedia.ready && retryCount > 0) {
                            console.log(`Retrying... (${retries - retryCount + 1})`);
                            setTimeout(() => attempt(retryCount - 1), delay);
                        } else if (cachedMedia.ready) {
                            const objectURL = URL.createObjectURL(cachedMedia.blob);
                            let mediaElement;



                            if (mediaItem.fileType.toLowerCase() === 'image' || mediaItem.fileType.toLowerCase() === 'vector') {
                                mediaElement = document.createElement('img');
                                mediaElement.onload = () => {
                                    mediaElement.style.width = '100%';
                                    mediaElement.style.height = '100%';
                                    mediaElement.style.objectFit = 'cover'; // Ensure image covers the container

                                    if (container.querySelector('.loading-indicator')) {
                                        container.removeChild(container.querySelector('.loading-indicator'));
                                    }
                                    //resolve(mediaElement);

                                    resolve({
                                        element: mediaElement,
                                        duration: 0,
                                        type: "image"
                                    });
                                };
                                mediaElement.onerror = () => {
                                    console.error("Failed to load image.");
                                    reject(new Error("Failed to load image."));
                                };
                                mediaElement.src = objectURL;
                            } else if (mediaItem.fileType.toLowerCase() === 'video') {

                                mediaElement = document.createElement('video');
                                mediaElement.src = objectURL;
                                mediaElement.poster = mediaItem.previewUrl;

                                mediaElement.muted = true;
                                mediaElement.setAttribute('muted', ''); // Explicitly set muted attribute
                                mediaElement.setAttribute('playsinline', ''); // Add playsinline attribute
                                mediaElement.autoplay = false;

                                mediaElement.style.width = '100%';
                                mediaElement.style.height = '100%';
                                mediaElement.style.objectFit = 'cover';

                                mediaElement.addEventListener('loadedmetadata', () => {
                                    mediaElement.muted = true; // Ensure muted is still true after metadata is loaded
                                    const duration = mediaElement.duration;
                                    resolve({
                                        element: mediaElement,
                                        duration: duration,
                                        type: "video"
                                    });
                                });

                                // Optionally: play the video on user interaction if autoplay is blocked
                                mediaElement.load();


                                _utils.videoControlsAndLogging(controls, mediaElement, entry, mediaItem.id);
                            }

                            if (mediaItem.fileType.toLowerCase() === 'image') {
                                let loadingIndicator = document.createElement('div');
                                loadingIndicator.className = 'loading-indicator';
                                loadingIndicator.innerText = 'Loading...';
                                container.appendChild(loadingIndicator);
                            }

                            //console.log("adding item", mediaItem)

                            if (mediaItem.fileType.toLowerCase() != 'audio') {
                                container.appendChild(mediaElement);
                            }

                            if (mediaItem.fileType.toLowerCase() == 'audio') {
                                resolve({
                                    element: mediaElement,
                                    duration: 0,
                                    type: "audio",
                                    ready: true
                                });
                            }



                        } else {
                            reject(new Error("Failed to load media after retries."));
                        }
                    }).catch(error => {
                        console.error("Error in readyPromise:", error);
                        reject(error);
                    });
                };

                attempt(retries);
            });
        };

        return waitForReady();
    },



    addMediaElementTo: async function (el, mediaContainer, controls, preview = false, entry, removeChildren = true, preload = true) {

        if (removeChildren) {
            removeAllChildNodes(mediaContainer);
        }


        if (el && el.fileType && el.fileType.toLowerCase() != "audio") {
            el = _utils.normaliseMedia(el);
        }

        let mediaId = el.id
        let media, cachedMedia

        let loadFromCache = false

        if (publicai.preload[el.id] && publicai.preload[el.id].media) {

            //console.log("found in cache.", publicai.preload[el.id]);


            if (!entry) {
                loadFromCache = true
            } else {
                if (publicai.preload[el.id].media[entry.id]) {
                    loadFromCache = true
                } else {
                    //console.log("but not this one...")
                    loadFromCache = false
                }
            }

        }



        if (loadFromCache) {


            cachedMedia = publicai.preload[mediaId];
            media = await this.addCachedMediaTo(cachedMedia, mediaContainer, el);
            if (entry) {
                publicai.preload[mediaId].media[entry.id] = media.element
            }



        } else {

            cachedMedia = await this.fetchMedia(el);


            console.log(">>> cachedMedia", cachedMedia)

            if (cachedMedia.type == "audio") {
                //console.log("fetched media", cachedMedia)
            }

            if (!publicai.preload[mediaId] || !publicai.preload[mediaId].media) {
                publicai.preload[mediaId].media = {}
            }

            media = await this.addCachedMediaTo(cachedMedia, mediaContainer, el, controls, entry);

            if (entry && media.type == "video") {
                if (entry.settings?.timing.duration == 0) {
                    entry.settings.timing.duration = media.duration * 1000
                    entry.settings.timing.end = entry.settings.timing.start + entry.settings.timing.duration
                }
            }


            if (entry) {

                publicai.preload[mediaId].media[entry.id] = media.element

                if (cachedMedia.type == "audio") {
                    //console.log("saved entry", publicai.preload[mediaId], mediaId)
                }

                //console.log("saved entry", publicai.preload[mediaId], publicai.preload[mediaId].media[entry.id])
            }




        }

        return cachedMedia
    },




    fetchMedia: function (mediaItem) {
        //let src = null;

        if (!mediaItem.type) {
            mediaItem.type = mediaItem.fileType;
        }

        console.log("fetch media", mediaItem)

        /* 
                if (mediaItem.type.toLowerCase() === 'image' || mediaItem.type.toLowerCase() === 'vector') {
                    src = mediaItem.url;
                } else if (mediaItem.type.toLowerCase() === 'video') {
                    src = mediaItem.url;
                } else if (["sfx", "audio", "audio_video"].includes(mediaItem.fileType.toLowerCase())) {
                    src = mediaItem.url;
                } */


        
        let src = window.endpoints.media + mediaItem.s3Key;


        const mediaId = mediaItem.id;
        if (!publicai.preload) {
            publicai.preload = {};
        }

        let mediaEntry = publicai.preload[mediaId];
        if (mediaEntry) {
            if (mediaEntry.readyPromise) {
                return mediaEntry.readyPromise;
            } else if (mediaEntry.ready) {
                return Promise.resolve(mediaEntry);
            }
        } else {
            mediaEntry = {
                blob: null,
                size: null,
                dimensions: { width: mediaItem.width, height: mediaItem.height },
                lastFetched: getPrettyTimeStamp(),
                ready: false,
                readyPromise: null,
                type: mediaItem.type.toLowerCase(),
            };
            publicai.preload[mediaId] = mediaEntry;
        }

        // Handle mediaItem.audio_base64 directly as a voiceover
        if (mediaItem.audio_base64) {
            mediaEntry.readyPromise = new Promise((resolve) => {
                const audioBlob = base64ToBlob(mediaItem.audio_base64, 'audio/wav'); // Assuming it's a wav file, change MIME type as needed
                mediaEntry.blob = audioBlob;
                mediaEntry.size = audioBlob.size;
                mediaEntry.ready = true;
                mediaEntry.audioBlobBase64 = mediaItem.audio_base64; // Store the base64 version for reference if needed
                resolve(mediaEntry); // Return the mediaEntry inside a promise
            });


            return mediaEntry.readyPromise;
        }

        // If no audio_base64, proceed with the normal media fetching flow
        mediaEntry.readyPromise = fetch(src)
            .then(response => response.blob())
            .then(blob => {
                // Ensure SVG has correct MIME type
                if (mediaEntry.type === 'vector') {
                    blob = new Blob([blob], { type: 'image/svg+xml' });
                }

                mediaEntry.blob = blob;
                mediaEntry.size = blob.size;

                //console.log("blob", mediaEntry, mediaEntry.type)

                if (mediaEntry.type === 'video') {
                    return this.getVideoDuration(blob).then(duration => {
                        mediaEntry.duration = duration;
                        let blobAudioId = mediaItem.id + "_audio";
                        return timeline_audio.extractAudio(blob).then(audioBlobBase64 => {
                            mediaEntry.audioBlobBase64 = audioBlobBase64 || "";  // Use empty string if no audio
                            mediaEntry.ready = true;
                            return mediaEntry;
                        }).catch(() => {
                            mediaEntry.audioBlobBase64 = "";  // Handle error by setting default empty audio
                            mediaEntry.ready = true;
                            return mediaEntry;
                        });
                    });
                } else {
                    mediaEntry.ready = true;
                    return mediaEntry;
                }
            })
            .catch(error => {
                mediaEntry.ready = false;
                throw error;
            });

        return mediaEntry.readyPromise;
    },


}


/* 

function addMediaOld_____(cachedMedia, mediaContainer, el) {


    return new Promise((resolve, reject) => {


        let fileURL = el.sizes.medium
        if (preview) {
            fileURL = el.sizes.preview
        }


        if (el && (el.fileType?.toLowerCase() === "audio")) {

            mediaContainer.textContent = "Audio"
            resolve();

        } else {

            if (el && (el.fileType?.toLowerCase() === "image" || el.fileType?.toLowerCase() === "vector")) {
                let img = addImageTo(fileURL, mediaContainer, "100%", "100%");
                img.onload = () => resolve(img); // Resolve the promise when the image is loaded
                img.onerror = () => reject(new Error("Image failed to load")); // Reject the promise if there is an error loading the image
            } else if (el && el.fileType?.toLowerCase() === "video") {
                // Assuming addVideoTo function exists and handles video elements similarly
                let srcMP4 = el.url;
                let poster = el.previewUrl;
                let video = addVideoTo(srcMP4, poster, mediaContainer, "100%", "100%");

                _utils.videoControlsAndLogging(controls, video, entry, el.id)
                video.pause()

                resolve(video); // For simplicity, immediately resolve for videos
            } else {
                //console.log("unspported file", el)
                //reject(new Error("Unsupported media type"));
            }

        }
    });
} */