import { FileUpload } from '../../services/files/upload_frontend.js';
import { FileManager } from '../../services/files/FileManager.js';


export class FileLibrary {
    constructor(settings) {


        this.files = {}
        this.filters = {
            type: "image",
            multiple: "single",
            keyword: "",
            sort: "date",
            location: "local"
        }
        this.selected = {}
        this.settings = settings
        //console.log("new library", settings)
        this.init()

    }


    showSearchResults() {

        let resultsContainer = app.ui.file_library.content.search_results
        utils.removeAllChildNodes(resultsContainer)

        Object.keys(this.files).forEach(fileType => {

            let fileGroup = this.files[fileType]

            if (fileGroup.length > 0) {

                let group = utils.addWhatWhere(nwsapp.comp.results_container, resultsContainer)
                let groupEl = $$$$(group, "role")
                utils.removeAllChildNodes(groupEl.files)
                groupEl.results_group_label.textContent = fileGroup.length + " " + fileType + " files"
                fileGroup.forEach(file => {
                    let passed = this.checkAgainstFilters(file)
                    //let passed = true
                    if (passed) {
                        let item = utils.addWhatWhere(nwsapp.comp.file, groupEl.files)
                        let el = $$$$(item, "role")
                        el.filename.textContent = utils.getShortFileName(file.fileName)
                        let media = el.media_container
                        utils.removeAllChildNodes(media)
                        let asset = utils.addMediaTo(file, media, item)
                        item.onclick = () => {
                            this.selectFile(file)
                        }
                    }
                })
            }
        }
        )

    }

    checkAgainstFilters(file) {

        let passed = true
        if (this.filters.keyword != "") {
            if (!file.fileName.includes(this.filters.keyword)) {
                passed = false
                console.log("failed keyword", this.filters.keyword)
            }
        }

        //check against type
        //if (this.filters.type != "all") {}

        //check for type

        return passed

    }



    async getFileList() {
        try {
            const fileManager = new FileManager();
            const allAllowedTypes = ["image", "video", "vector", "music", "stylesheets", "fonts"];

            const allFiles = await fileManager.listFiles({
                type: allAllowedTypes,
                keyword: this.filters.keyword,
                shared: false,
                projectId: nwsapp?.currentProject?.id || null
            });

            console.log("Fetched files:", allFiles); // Log the fetched files
            this.files = allFiles.files;
        } catch (error) {
            console.error("Error getting file list:", error);
        }
    }


    async init() {

        let library = utils.addWhatWhere(nwsapp.comp.file_library, app.ui.top.el, "file_library", false)
        library.style.zIndex = 1000
        await this.getFileList()

        this.changeFolder()
        this.moreOptions()
        this.uploadSetup()
        this.libraryControls()
        this.fileType()
        this.librarySearch()
        this.showSearchResults()
    }

    refresh() {
        this.showSearchResults()
    }


    changeFolder() {
        let toggleButton = app.ui.file_library.content.file_location
        //get mosue coordinates
        let buttonRect = toggleButton.getBoundingClientRect()

        let moreValues = [
            {
                "label": "Project Assets",
                "value": "local",
                "icon": "pin_drop"
            },
            {
                "label": "All Assets",
                "value": "global",
                "icon": "public"
            },

        ]

        let panel = new Menu({
            "selector": toggleButton,
            "trigger": false,
            "values": moreValues,
            "label": "Add new element",
            "styles": ["auto", "horizontal", "light", "no_input"],
            "customMode": true,
            "save": false,
            "callback": moreMenu,
            "direction": "down",
            "overlayColor": "rgb(27 40 68)",
            "overlayOpacity": 0.01,
            "width": 200,
            "max_height": 500,
            "iframeMode": "disabled",
            x: buttonRect.left + 10,
            y: buttonRect.bottom - 10,
            //closeCallback: this.settings.more.closeCallback,
            //keys: this.settings.more.keys
        })

        function moreMenu(s) {
            console.log(s)
            this.settings.location = s.value
            this.refrsh()
        }

    }


    moreOptions() {
        let toggleButton = app.ui.file_library.content.library_more
        //get mosue coordinates
        let buttonRect = toggleButton.getBoundingClientRect()

        let moreValues = [
            {
                "label": "Single File",
                "value": "single",
                "icon": "description"
            },
            {
                "label": "Select Multiple",
                "value": "multiple",
                "icon": "note_stack"
            },

        ]

        let panel = new Menu({
            "selector": toggleButton,
            "trigger": false,
            "values": moreValues,
            "label": "Add new element",
            "styles": ["auto", "horizontal", "light", "no_input"],
            "customMode": true,
            "save": false,
            "callback": moreMenu,
            "direction": "down",
            "overlayColor": "rgb(27 40 68)",
            "overlayOpacity": 0.01,
            "width": 200,
            "max_height": 500,
            "iframeMode": "disabled",
            x: buttonRect.left,
            y: buttonRect.bottom - 10,
            //closeCallback: this.settings.more.closeCallback,
            //keys: this.settings.more.keys
        })

        let self = this
        function moreMenu(s) {
            console.log(s)
            self.filters.multiple = s.value
            self.refresh()
        }

    }




    selectFile(file) {

        this.selected[file.id] = file

        if (this.filters.multiple == "multiple") {
            //do nothing
            this.updateSelectedFilesList()
        } else {
            this.settings.callback(this.selected)
            this.destroy()
        }
    }

    updateSelectedFilesList() {

        if (Object.keys(this.selected) == 0) {
            app.ui.file_library.content.selected_media.classList.remove("is_active")
            return
        } else {
            app.ui.file_library.content.selected_media.classList.add("is_active")
        }

        let utiliseButton = app.ui.file_library.content.utilise_media_button
        let deleteSelected = app.ui.file_library.content.delete_media_button

        utiliseButton.onclick = () => {
            this.settings.callback(this.selected)
            this.destroy()
        }


        let container = app.ui.file_library.content.used_media_container

        utils.removeAllChildNodes(container)
        for (let key of Object.keys(this.selected)) {
            let file = this.selected[key]

            let fileEl = utils.addWhatWhere(nwsapp.comp.selected_file, container)
            let el = $$$$(fileEl, "role")
            el.selected_extension.textContent = file.format
            el.selected_label.textContent = utils.getShortFileName(file.fileName)

        }



    }

    libraryControls() {
        app.ui.file_library.content.close.onclick = () => {
            this.destroy()
        }

        app.ui.file_library.content.selected_media.classList.remove("is_active")


    }

    fileType() {
        let fileTypeButton = app.ui.file_library.content.file_type

        //get mosue coordinates
        let buttonRect = fileTypeButton.getBoundingClientRect()

        console.log("buttonRect", buttonRect)

        let moreValues = [
            {
                "label": "Images",
                "value": "image",
                "icon": "image"
            },
            {
                "label": "Videos",
                "value": "video",
                "icon": "videocam"
            },
            {
                "label": "Vector",
                "value": "vector",
                "icon": "category"
            },
            {
                "label": "Audio",
                "value": "audio",
                "icon": "music_note"
            },
            {
                "label": "Document",
                "value": "document",
                "icon": "description"
            },
            {
                "label": "All Files",
                "value": "all",
                "icon": "folder_open"
            },

        ]

        let panel = new Menu({
            "selector": fileTypeButton,
            "trigger": false,
            "values": moreValues,
            "label": "Add new element",
            "styles": ["auto", "horizontal", "light", "no_input"],
            "customMode": true,
            "save": false,
            "callback": moreMenu,
            "direction": "down",
            "overlayColor": "rgb(27 40 68)",
            "overlayOpacity": 0.01,
            "width": 200,
            "max_height": 500,
            "iframeMode": "disabled",
            x: buttonRect.left + 200,
            y: buttonRect.bottom - 10,
            //closeCallback: this.settings.more.closeCallback,
            //keys: this.settings.more.keys
        })

        let self = this
        function moreMenu(s) {
            self.filters.type = s.value
            self.refresh()
        }

    }

    librarySearch() {
        let input = app.ui.file_library.content.search
        input.placeholder = "File search..."
        input.focus()
    }

    destroy() {
        app.ui.file_library.el.remove()
    }





    uploadSetup() {

        let self = this
        //let elements = $$$$(document, "role_upload")
        //console.log("upload elements", elements)

        let allowedExtensions = []
        /* 
                for (let ext in this.filters.type) {
                    allowedExtensions.push(FILE_CATEGORIES[this.filters.type[ext]].extensions)
                } */

        console.log("allowedExtensions", allowedExtensions)


        const fileUpload = new FileUpload({
            //area: app.ui.file_library.el,

            area: app.ui.file_library.content.upload,
            button: app.ui.file_library.content.upload,
            uploadEndpoint: '/api/upload',
            extensions: ['png', 'jpg', 'jpeg', 'mp4', "svg", "mp3", "wav", "otf", "ttf", "woff", "woff2"],
            type: this.settings.type,
            projectId: nwsapp?.currentProject?.id || null,
            //successCallback: 
            //extensions: allowedExtensions,

            onFileSuccess: (data) => {
                // Show success message, update UI with file details
                console.log(`${data.fileName} uploaded to ${data.s3Key}`);
                self.init()
            },
            onFileError: (data) => {
                // Show error message
                console.error(`Failed to upload ${data.fileName}`);
            },
            onComplete: (data) => {
                // Show completion message
                console.log(`Uploaded ${data.successfulFiles} of ${data.totalFiles} files`);
            },
            onError: (error) => {
                // Show error message
                console.error('Upload failed:', error);
            },
            onProgress: (data) => {
                console.log(`
                File: ${data.fileName}
                Progress: ${data.fileProgress.toFixed(2)}%
                Speed: ${data.speed.toFixed(2)} MB/s
                Overall: ${data.totalProgress.toFixed(2)}%
                Average Speed: ${(data.averageSpeed / (1024 * 1024)).toFixed(2)} MB/s
                Uploaded: ${(data.uploadedSize / (1024 * 1024)).toFixed(2)}/${(data.totalSize / (1024 * 1024)).toFixed(2)} MB
            `);
            }

        });

    }

}


