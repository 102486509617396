export var css_style = {

    init: function () {
        if (!this.ui) {

            let container = $$$(document, "data-group", "position")

            this.ui = {
                "panel": container,

                "blending_dropdown": $$$(document, "data-dropdown", "mix-blend-mode"),
                "cursor_dropdown": $$$(document, "data-dropdown", "cursor"),
                "opacity": $$$(document, "data-input-group", "opacity"),
                "events": $$$(document, "layout-combo", "pointer-events"),
            }

            this.ui.opacity_input = $$$(this.ui.opacity, "data-input", "input")

        }

        //generate mix blend modes from css


        css_utils.dropdown({
            "selector": css_style.ui.blending_dropdown,
            "values": css_style.definitions.mix_blend_modes,
            "label": "Blending",
            "style": null,
            "direction": "center",
        })


        css_utils.dropdown({
            "selector": css_style.ui.cursor_dropdown,
            "values": css_style.definitions.cursor_modes,
            "label": "Cursor",
            "style": null,
            "direction": "center",
        })


        css_utils.combo({
            "selector": css_style.ui.events,
            "save": true,
        })

        let self = this

        let opacitySliderSettings = {
            "selector": css_style.ui.opacity,
            "variable": "opacity",
            "slider": true,
            "save": false,
            "defaultSliderValue": 100, //if there's no value in css
            "ranges": {
                "min": 0,
                "max": 100,
            },
            "units": false,
            "unit": "%",
            "custom_range": true,
            "validation": "float",
            "callback": sliderCallback,
            "givenValue": publicai.active.lastStyleInStack.css.opacity * 100
        }


        function sliderCallback(value) {

            if (!value) {
                self.ui.opacity_input.value = publicai.active.lastStyleInStack.css.opacity * 100
                return
            }

            console.log(value)

            if (value < 1) {
                publicai.active.lastStyleInStack.css.opacity = value
            } else {
                publicai.active.lastStyleInStack.css.opacity = value / 100
            }





        }

        self.ui.opacity_input.value = publicai.active.lastStyleInStack.css.opacity * 100

        css_utils.input(opacitySliderSettings)



    },

    definitions: {
        mix_blend_modes: [

            {
                label: "Normal",
                value: "normal",
                comment: "Default. Sets the default value."
            },

            {
                label: "Darken",
                value: "darken",
                comment: "Selects the darker of the backdrop and source colors."
            },
            {
                label: "Multiply",
                value: "multiply",
                comment: "Multiplies the backdrop and source color values."
            },
            {
                label: "Color Burn",
                value: "color-burn",
                comment: "Darkens the backdrop color to reflect the source color."
            },
            {
                label: "Lighten",
                value: "lighten",
                comment: "Selects the lighter of the backdrop and source colors."

            },
            {
                label: "Screen",
                value: "screen",
                comment: "Multiplies the complements of the backdrop and source color values, then complements the result."
            },
            {
                label: "Color Dodge",
                value: "color-dodge",
                comment: "Brightens the backdrop color to reflect the source color."

            },
            {
                label: "Overlay",
                value: "overlay",
                comment: "Multiplies or screens the colors, depending on the backdrop color value."

            },
            {
                label: "Hard Light",
                value: "hard-light",
                comment: "Multiplies or screens the colors, depending on the source color value."

            },
            {
                label: "Soft Light",
                value: "soft-light",
                comment: "Darkens or lightens the colors, depending on the source color value."
            },
            {
                label: "Difference",
                value: "difference",
                comment: "Subtracts the darker of the two constituent colors from the lighter color."

            },
            {
                label: "Exclusion",
                value: "exclusion",
                comment: "Produces an effect similar to that of the Difference mode but lower in contrast."

            },
            {
                label: "Hue",
                value: "hue",
                comment: "Creates a color with the hue of the source color and the saturation and luminosity of the backdrop color."

            },
            {
                label: "Saturation",
                value: "saturation",
                comment: "Creates a color with the saturation of the source color and the hue and luminosity of the backdrop color."

            },
            {
                label: "Color",
                value: "color",
                comment: "Creates a color with the hue and saturation of the source color and the luminosity of the backdrop color."

            },
            {
                label: "Luminosity",
                value: "luminosity",
                comment: "Creates a color with the luminosity of the source color and the hue and saturation of the backdrop color."
            },

            {
                label: "Initial",
                value: "initial",
                comment: "Sets this property to its default value."
            },
            {
                label: "Inherit",
                value: "inherit",
                comment: "Inherits this property from its parent element."
            },

        ],


        cursor_modes: [
            {
                label: "Default",
                value: "default",
                comment: "Standard cursor, typically an arrow."
            },
            {
                label: "Pointer",
                value: "pointer",
                comment: "Cursor that indicates a link, typically a hand with a pointing finger."
            },
            {
                label: "Text",
                value: "text",
                comment: "Indicates text that can be selected, typically an I-beam."
            },
            {
                label: "Wait",
                value: "wait",
                comment: "Indicates that the program is busy, typically an hourglass or watch."
            },
            {
                label: "Help",
                value: "help",
                comment: "Indicates that help is available, typically a question mark or a balloon."
            },
            {
                label: "Crosshair",
                value: "crosshair",
                comment: "Simple crosshair, used for precision, like in graphic applications."
            },
            {
                label: "Not Allowed",
                value: "not-allowed",
                comment: "Indicates that an action is not allowed, typically a circle with a line through it."
            },
            {
                label: "Move",
                value: "move",
                comment: "Indicates something is movable."
            },
            {
                label: "Grab",
                value: "grab",
                comment: "Indicates that something can be grabbed."
            },
            {
                label: "Grabbing",
                value: "grabbing",
                comment: "Indicates that something is being grabbed."
            },
            {
                label: "Resize Vertical",
                value: "ns-resize",
                comment: "Indicates vertical resize operation."
            },
            {
                label: "Resize Horizontal",
                value: "ew-resize",
                comment: "Indicates horizontal resize operation."
            },
            {
                label: "Resize Diagonal (NE-SW)",
                value: "nesw-resize",
                comment: "Indicates diagonal resize (northeast-southwest)."
            },
            {
                label: "Resize Diagonal (NW-SE)",
                value: "nwse-resize",
                comment: "Indicates diagonal resize (northwest-southeast)."
            },
            {
                label: "Resize All",
                value: "all-scroll",
                comment: "Indicates scrolling or moving in any direction."
            },
            {
                label: "Zoom In",
                value: "zoom-in",
                comment: "Indicates that something can be zoomed in."
            },
            {
                label: "Zoom Out",
                value: "zoom-out",
                comment: "Indicates that something can be zoomed out."
            },
            {
                label: "Progress",
                value: "progress",
                comment: "Indicates that the program is busy but can still be interacted with, often an arrow with a watch or hourglass."
            },
            {
                label: "No Drop",
                value: "no-drop",
                comment: "Indicates that a dragged item cannot be dropped here."
            },
            {
                label: "Context Menu",
                value: "context-menu",
                comment: "Indicates that a context menu is available."
            },
            {
                label: "Cell",
                value: "cell",
                comment: "Indicates cell selection in a table, typically a plus sign."
            }
        ]

    }

}