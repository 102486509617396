
//import { loadAllThreads } from './editor.js'
//import { loadAllAngets } from './editor.js'

import { init as projects } from './content/projects.js';
import { init as conversations } from './content/conversations.js';


export async function init(path) {

    //console.log("projects", projects)
    utils.addWhatWhere(nwsapp.comp.content, app.ui.main.el, "content", true)

    utils.removeAllChildNodes(app.ui.content.content.container);

    let segments = path.split("/")

    let callbacks = { projects, conversations }

    let tabs = new utils.Tabs({
        identifiers: {
            tab: "tab",
            panel: "panel"
        },
        container: app.ui.content.el,
        default: segments[2],
        callbacks: callbacks, 
        root: "content"
    })

 

}







