export var _pages = {
    currentTab: "pages",

    manageStyleScopes: function (page, pagePropertiesPanel) {

        let group = $$$(pagePropertiesPanel, "page-group-css", "group")
        let input = $$$(pagePropertiesPanel, "page-group-css", "input")

        if (page.type == "folder") {
            group.classList.remove("hidden")
        } else {
            group.classList.add("hidden")
        }

    },

    manageSaveAsPackage: function (folder, pagePropertiesPanel) {

        //in the page properties panel, allow the user to save the folder as a package
        if (!folder.ancestor) {
            return
        }


        let packageName = $$$(pagePropertiesPanel, "save-package", "name")
        let packageId = $$$(pagePropertiesPanel, "save-package", "id")
        let packageIdGroup = $$$(pagePropertiesPanel, "save-package", "id_group")
        let packageCategory = $$$(pagePropertiesPanel, "save-package", "category")
        let savePackage = $$$(pagePropertiesPanel, "save-package", "save")
        let previews = $$$(document, "save-package", "previews")

        let updateButton = $$$(pagePropertiesPanel, "save-package", "update")
        let deletePackage = $$$(pagePropertiesPanel, "save-package", "delete")
        let updateGroup = $$$(pagePropertiesPanel, "save-package", "update_group")
        let category = "advertising" //set as default. Replaced if package exists already.

        let packageExists = public_system.packages.find(template => {
            // Log the template object to the console
            //console.log(template);

            // Return true if the template matches the condition
            return template.node?.config?.template?.content[0]?.permissions?.originalId === folder.id;
        });


        console.log("packageExists", packageExists)


        if (packageExists) {
            updateGroup.classList.add("active")
            packageName.value = packageExists.node.name
            category = packageExists.node.config.template.category
            packageCategory.value = category


            packageId.value = packageExists.node.id
            packageIdGroup.classList.remove("hidden")


            deletePackage.onclick = event => {
                css_utils.confirmationMessage({
                    question: "Are you sure you want to delete this package?",
                    yes: "Yes, delete",
                    no: "No, cancel",
                    callback: deletePackageFunction,
                    success: ["Allright", "The package has been deleted"],
                    x: event.clientX,
                    y: event.clientY,
                })
                event.stopPropagation()
                event.preventDefault()
            }

            function deletePackageFunction() {
                handle.deleteTemplate(packageExists.node.id, false)
                updateGroup.classList.remove("active")
            }



        } else {
            updateGroup.classList.remove("active")
            packageIdGroup.classList.add("hidden")
        }

        let contentCategories = [
            {
                "label": "Advertising",
                "value": "advertising",
                "icon": "verified",
            },
            {
                "label": "Articles",
                "value": "articles",
                "icon": "verified",
            },
            {
                "label": "Landing Pages",
                "value": "landing",
                "icon": "verified",
            },
        ]

        packageCategory.onclick = function (e) {
            css_utils.reg_dropdown({
                "selector": packageCategory,
                "value": "",
                "multiSelect": false,
                "search": true,
                "values": contentCategories,
                "label": "Add new element",
                "styles": ["auto", "horizontal", "light", "no_input", "lighter"],
                "customMode": true,
                "save": false,
                "callback": selectCategory,
                "direction": "down",
                "overlayColor": "black",
                "overlayOpacity": 0.02,
                "width": "100%"
            })
        }

        function selectCategory(s) {
            //console.log("settings", s)
            category = s.value
            packageCategory.value = s.label
        }

        removeAllChildNodes(previews)

        let childrenPages = _find.childPages(folder, "page")
        let fileList = {}

        for (let page of childrenPages) {
            let previewItem = addWhatWhere(components.ui.screenshot_element, previews)
            addImageTo(page.url.screenshot, previewItem, "100%", "100%", null, true)
            fileList[page.id] = { ...page.url }
        }


        let nodeCopy = JSON.parse(JSON.stringify(folder))
        nodeCopy.css = "self"
        nodeCopy.permissions.owner = "nwsapp.profile.websiteId"
        nodeCopy.permissions.originalId = nodeCopy.id


        let rootFolder = null
        rootFolder = _find.getRoot(folder.id)

        //console.log("my root is", rootFolder)

        //console.log("fileList", fileList)

        if (!rootFolder || typeof rootFolder == "undefined" || rootFolder == undefined) {
            rootFolder = folder
        }


        let css = JSON.parse(JSON.stringify(nwsapp.currentProject.config.css[rootFolder.id]))
        let media = _find.allMedia(folder)
        let fonts = {}
        //let fonts = _find.allFonts(css)

        let config = {
            "content": [nodeCopy],
            "media": media,
            "fonts": fonts,
            "fileList": fileList,
            //"html": $$$(document2, "public_id", publicai.selected.settings.id).outerHTML,
            "css": css,
            "type": "package",
            "category": category
        }


        savePackage.onclick = (e) => {
            handle.saveTemplate({
                "name": packageName.value,
                "config": config,
                "saveOnCloudflare": true,
                "type": "PACKAGE",
            })
            console.log("saved config", config)
            updateGroup.classList.add("active") //consider adding wait
        }



        updateButton.onclick = (e) => {
            packageExists.node.name = packageName.value
            packageExists.node.config.template.category = category

            handle.updateTemplate({
                "name": packageName.value,
                //"config": packageExists.node.config.template,
                "config": config,
                "saveOnCloudflare": true
            }, packageExists.node.id)
        }

    },

    listPackages: function () {

        //package panel

        let el = {
            "panel": $$$(document, "page-packages", "panel"),
            "close": $$$(document, "page-packages", "close"),
            "refresh": $$$(document, "page-packages", "refresh"),
            "container": $$$(document, "page-packages", "container"),
            "search": $$$(document, "page-packages", "search"),
        }

        el.panel.classList.add("active")

        el.close.onclick = function () {
            el.panel.classList.remove("active")
        }




        components.ui.package_element = ux.ext("package_element")
        components.ui.screenshot_element = ux.ext("screenshot_element")

        el.search.oninput = function () {
            listPackages(el.search.value)
        }


        listPackages()

        function listPackages(keyword) {

            let matching = 0

            removeAllChildNodes(el.container)

            if (!keyword) {
                keyword = ""
            }

            for (let template of public_system.packages) {
                console.log("adding packages", template)

                if (template.node.name.toLowerCase().includes(keyword.toLowerCase())) {

                    matching++

                    let item = addWhatWhere(components.ui.package_element, el.container)
                    let previews = $$$(item, "package_el", "container")



                    item.onclick = event => {
                        css_utils.confirmationMessage({
                            question: "Add package as a new folder?",
                            yes: "New folder",
                            no: "Individual Files",
                            callback: newFolder,
                            noFunction: individualFiles,
                            success: ["Allright", "Adding as folder"],
                            x: event.clientX,
                            y: event.clientY,
                        })
                        event.stopPropagation()
                        event.preventDefault()
                    }

                    function newFolder() {
                        _pages.addPackageTo(template, "folder")
                    }

                    function individualFiles() {
                        _pages.addPackageTo(template, "files")
                    }

                    let screenshotsContainer = $$$(item, "package_el", "container")
                    removeAllChildNodes(screenshotsContainer)


                    for (let page of Object.values(template.node.config.template.fileList)) {
                        let previewItem = addWhatWhere(components.ui.screenshot_element, screenshotsContainer)
                        addImageTo(page.screenshot, previewItem, "100%", "100%", null, true)
                    }

                    let label = $$$(item, "package_el", "label")

                    let count = $$$(item, "package_el", "count")
                    let deletePackage = $$$(item, "package_el", "delete")

                    //check if this is my package
                    if (template.node.config.template?.permissions?.owner == "nwsapp.profile.websiteId") {
                        //deletePackage.classList.remove("hidden")
                    } else {
                        //deletePackage.classList.add("hidden")
                    }

                    deletePackage.onclick = event => {
                        css_utils.confirmationMessage({
                            question: "Are you sure you want to delete this package?",
                            yes: "Yes, delete",
                            no: "No, cancel",
                            callback: deletePackageFunction,
                            success: ["Allright", "The package has been deleted"],
                            x: event.clientX,
                            y: event.clientY,
                        })
                        event.stopPropagation()
                        event.preventDefault()
                    }

                    function deletePackageFunction() {
                        item.remove()
                        handle.deleteTemplate(template.node.id, false)
                    }



                    label.textContent = template.node.name
                    count.textContent = Object.values(template.node.config.template.fileList).length + " pages"

                }

            }

            if (public_system.packages.length == 0 || matching == 0) {
                el.container.textContent = "No packages found."
            }

        }
    },

    addPackageTo(thePackage, addAs) {

        let locationToAdd = _find.elementByID(publicai.pages, _pages.selected)
        if (locationToAdd.type == "page") {
            locationToAdd = _find.elementByID(publicai.pages, locationToAdd.ancestor)
        }

        //console.log("package", package)
        //console.log("addAs", addAs)
        //console.log("locationToAdd", locationToAdd)



        let mainPackageFolder = JSON.parse(JSON.stringify(thePackage.node.config.template.content[0]))
        let css = JSON.parse(JSON.stringify(thePackage.node.config.template.css))
        let media = JSON.parse(JSON.stringify(thePackage.node.config.template.media))

        for (let key of Object.keys(media)) {
            let file = media[key]

            //console.log("file", file, key)

            //placing the inported files into the root. Consider creating a folder for them

            try {
                let tags = file.tags.edges[0].node
                let root = nwsapp.currentProject.config.library.folders.root
                tags.id = root.id
                tags.publicId = root.publicId
                publicai.library.files[key] = file
            } catch (err) {

            }
        }


        if (addAs == "folder") {
            mainPackageFolder.ancestor = locationToAdd.id
            locationToAdd.content.push(mainPackageFolder)
            nwsapp.currentProject.config.css[mainPackageFolder.id] = mainPackageFolder.id
            updateSceneIds(mainPackageFolder)
            _pages.activateTab(mainPackageFolder.id) //refresh structure
        }


        nwsapp.currentProject.config.css[mainPackageFolder.id] = css
        console.log("css", mainPackageFolder.id, css)

        //console.log("locationToAdd", locationToAdd)
        //console.log("mainPackageFolder", mainPackageFolder)


    },

    init: function () {
        let allTabs = $$$(document, "data-sidetab")
        let newPage = $$$(document, "data-button", "new-page")
        let packagesButton = $$$(document, "data-button", "packages")
        let newFolder = $$$(document, "data-button", "new-folder")


        if (!_pages.selected) {
            _pages.selected = publicai.pages.id
        }

        function refreshTabs() {
            for (let item of allTabs) {
                item.classList.remove("active")
            }
            $$$(document, "data-sidetab", _pages.currentTab).classList.add("active")
            _pages.activateTab()
        }



        if (_pages.currentTab == "utility") {
            newFolder.classList.add("inactive")
        }

        for (let item of allTabs) {
            item.addEventListener("click", function () {
                _pages.currentTab = this.getAttribute("data-sidetab")
                publicai.state = _pages.currentTab
                refreshTabs()
            })
        }

        refreshTabs()
        newPage.onclick = function () { _pages.addPage() }
        packagesButton.onclick = function () { _pages.listPackages() }
        newFolder.onclick = function () { _pages.addFolder() }

    },

    tab: function (tab) {

        //public.pageType = tab

    },


    copyElement: function (navItem, page) {

        console.log(navItem, page)
        delete _pages.clipboard


        let rootFolder = null
        rootFolder = _find.getRoot(page.id)
        if (!rootFolder || typeof rootFolder == "undefined" || rootFolder == undefined) {
            rootFolder = page
        }

        console.log("root is", rootFolder)

        _pages.clipboard = {

            node: JSON.parse(JSON.stringify(page)),
            root: rootFolder,
            css: JSON.parse(JSON.stringify(nwsapp.currentProject.config.css[rootFolder.id]))
        }
        notifications.show([
            ["Copied", "Now go ahead and paste the element inside a new folder."]
        ])
    },

    pasteElement: function (navItem, page) {


        let rootFolder = null
        rootFolder = _find.getRoot(page.id)
        if (!rootFolder || typeof rootFolder == "undefined" || rootFolder == undefined) {
            //rootFolder = publicai.root.pages
            /* if (page.ancestor.id == publicai.pages.id ){

            } */

            if (page.type == "folder") {
                rootFolder = page //it's actually a folder
            }

            if (page.type == "page") {
                rootFolder = publicai.root.pages
            }

        }



        let destination = page

        if (page.type == "page") {
            destination = _find.elementByID(publicai.pages, page.ancestor)
        }


        console.log("destination", page)
        console.log("Destination root", rootFolder)
        console.log("Page root", _pages.clipboard.root)


        if (_pages.clipboard) {
            _pages.clipboard.node.ancestor = destination.id
            destination.content.push(_pages.clipboard.node)
            updateSceneIds(_pages.clipboard.node)



            if (rootFolder != _pages.clipboard.root) {
                let myCss = nwsapp.currentProject.config.css[rootFolder.id]
                for (let item of Object.keys(_pages.clipboard.css)) {
                    if (!myCss[item]) {
                        myCss[item] = _pages.clipboard.css[item]
                    } else {
                        console.log("found a CSS conflict at", item)
                    }
                }

            }

            _pages.activateTab()
            notifications.show([
                ["Pasted", "The element has been pasted inside the folder."]
            ])
        }

    },



    addPage: function () {
        console.log("Add page button")
        let locationToAdd = _find.elementByID(publicai.pages, _pages.selected)


        if (locationToAdd.type == "page") {
            locationToAdd = _find.elementByID(publicai.pages, locationToAdd.ancestor)
        }

        let id = null
        //check if location is in root 
        let rootFolder = _find.getRoot(_find.elementByID(publicai.pages, _pages.selected).id)




        if (rootFolder && rootFolder != null && rootFolder != undefined) {
            id = rootFolder.id
            console.log("using root folder")
        } else {
            //null was returned, meaning that the folder already sits in root and should have its own css entry
            console.log("using selected")
            id = _pages.selected
        }

        //console.log("My root is", rootFolder)
        //console.log("Will be using", id)


        let newPageRole = null





        if (locationToAdd.properties.role == "templates") {
            newPageRole = "template" //give pages a different name if they're templates
        }

        if (locationToAdd.properties.role == "prompt") {
            newPageRole = "prompt" //give pages a different name if they're templates
        }

        let newPage = _defaults.blankPage(id, newPageRole) //alows the body css to be created in a specific location rahter than in the active page
        newPage.ancestor = locationToAdd.id

        if (locationToAdd.properties.role == "templates") {
            newPage.properties.role = "template"
            newPage.schema = locationToAdd.schema
        }

        locationToAdd.content.push(newPage)

        publicai.active.page = newPage

        _pages.activateTab(newPage.id) //refresh structure

    },







    addFolder: function (whereToAdd) {

        //if whereToAdd, the function is called programmatically
        // if not, it's called via the pages UI and also activate UI elements

        let currentLocation
        if (!whereToAdd) {

            currentLocation = _find.elementByID(publicai.pages, _pages.selected)
            if (currentLocation.type != "folder") {
                currentLocation = _find.elementByID(publicai.pages, currentLocation.ancestor)
            }
        } else {
            currentLocation = _find.elementByID(publicai.pages, whereToAdd)
        }

        let newFolder = _defaults.folder()

        //check if location is in root. If so, create css object. If not, folders will use the top parent css
        let rootFolder = _find.getRoot(currentLocation.id)

        if (currentLocation.properties.role == "templates") {
            newFolder.properties.role = "templates"
        }

        if (currentLocation.properties.role == "prompt") {
            newFolder.properties.role = "prompt"
        }


        if (rootFolder == null || rootFolder == undefined || rootFolder == false || rootFolder == "null") {
            nwsapp.currentProject.config.css[newFolder.id] = JSON.parse(JSON.stringify(nwsapp.currentProject.config.root.definitions.classes))

        } else {
            //means there's already a parent folder that sits in root

        }

        newFolder.ancestor = currentLocation.id
        currentLocation.content.push(newFolder)

        if (!whereToAdd) {
            _pages.selected = newFolder.id
            _pages.activateTab(newFolder.id) //refresh structure
        } else {
            return (newFolder)
        }


    },

    checkIfNameIsUnique: function (theContentArray, name) {
        let uniqueName = name;
        let counter = 1;

        // Function to check if the name exists in the array
        function nameExists(array, nameToCheck) {
            return array.some(item => item.properties && item.properties.name === nameToCheck);
        }

        // Loop to find a unique name
        while (nameExists(theContentArray, uniqueName)) {
            uniqueName = `${name}_${counter}`;
            counter++;
        }

        return uniqueName;
    },


    addSource: function () {

    },


    editPageName: function (navItem, page) {
        let label = navItem.querySelector(".el_label")
        label.classList.add("editing")
        label.setAttribute("contenteditable", "true")
        label.focus()
        //select all in the field
        document.execCommand('selectAll', false, null);

        const handleBlur = event => {
            label.removeAttribute("contenteditable")
            label.classList.remove("editing")
            page.properties.name = label.textContent
            label.removeEventListener("blur", handleBlur)
            label.removeEventListener("keydown", handleKeyDown)
        }

        const handleKeyDown = event => {
            if (event.key === "Enter") {
                event.preventDefault()
                label.blur()
            }
        }

        label.addEventListener("blur", handleBlur)
        label.addEventListener("keydown", handleKeyDown)
        console.log("+++changed selected")
        _pages.selected = page.id
    },





    closePagePropertiesPanel: function () {
        let pagePropertiesPanel = document.querySelector(".page_properties")
        let rightSide = document.querySelector(".page_navigation")
        pagePropertiesPanel.classList.remove("active")
        rightSide.classList.remove("inactive")
        $$$(document, "page-packages", "panel").classList.remove("active")
    },


    managePageAncestorChanges: function (pagePropertiesPanel, page) {

        ///move page to a different folder
        let folderDropDown = $$$(pagePropertiesPanel, "page-properties-linkage", "input")
        let folderLabel = $$$(pagePropertiesPanel, "page-properties-linkage", "label")


    },

    deletePage: function (page, event, confirm = false) {


        let question = "Are you sure you want to delete this?"
        if (page.properties.role == "schemaroot") {
            question = "Deleting this folder will also have the schema deleted. are you sure?"
        }

        //check if event.clientY is 300 px from the bottom of the screen

        if (event.clientY > window.innerHeight - 300) {
            event.clientY = event.clientY - 200
        }

        if (confirm) {
            css_utils.confirmationMessage({
                question: question,
                yes: "Yes, delete",
                no: "No, cancel",
                callback: deletePage,
                success: ["Allright", "The page has been deleted"],
                x: event.clientX,
                y: event.clientY,
            })
        }

        function deletePage() {

            //let page = _find.elementByID(publicai.pages, _pages.selected)

            let currentPageIsToBeDeleted = false

            if (page == publicai.active.page) {
                currentPageIsToBeDeleted = true
            }

            if (page.properties.role == "schemaroot") {
                delete publicai.dataEntries[page.schema.id]
                delete publicai.dataSources.schemas[page.schema.id]
                publicai.dataSources.schemasInUse.remove(page.schema.id)

                notifications.show([
                    ["Alright.", "The folder and schema have been deleted."]
                ])
                //question = "Deleting this folder will also have the schema deleted. are you sure?"
            }


            let ancestor = _find.elementByID(publicai.pages, page.ancestor)
            let index = ancestor.content.indexOf(page)
            ancestor.content.splice(index, 1)
            _pages.closePagePropertiesPanel()
            _pages.activateTab()

            if (currentPageIsToBeDeleted) {
                window.openEditorPath('/' + "page" + "/" + publicai.pages.homepage);
            }

        }
    },

    adServingTags: function (page) {

        let techDropdown = $$$(document, "ad-serving-tags", "dropdown")
        let code = $$$(document, "ad-serving-tags", "code")
        let copy = $$$(document, "ad-serving-tags", "copy")

    },


    pagePropertiesPanel: async function (navItem, page) {




        let pagePropertiesPanel = document.querySelector(".page_properties")

        function delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }


        if (!pagePropertiesPanel) {
            //panel was called via shortcuts menu
            window.openEditorPath('/pages');
            await delay(100)
        }


        pagePropertiesPanel = document.querySelector(".page_properties")
        let rightSide = document.querySelector(".page_navigation")


        let tab = "title"
        css_utils.operateTabs({
            "location": pagePropertiesPanel,
            "name": "page-properties",
            "default": tab,
            "callback": tabCallback
        })


        _tags.init()

        function tabCallback(s) {
            //tab = s
            console.log(s)
        }



        pagePropertiesPanel.classList.add("active")
        rightSide.classList.add("inactive")

        let allInputs = $$$(pagePropertiesPanel, "page-properties-input")
        let del = $$$(pagePropertiesPanel, "page-properties-button", "delete")
        let del2 = $$$(pagePropertiesPanel, "page-properties-button", "delete2")
        let duplicate = $$$(pagePropertiesPanel, "page-properties-button", "duplicate")
        let close = $$$(pagePropertiesPanel, "page-properties-button", "close")
        let homepage = $$$(pagePropertiesPanel, "page-properties-homepage", "home")
        let linkage = $$$(pagePropertiesPanel, "page-properties-linkage", "input")
        let linkageLabel = $$$(pagePropertiesPanel, "page-properties-linkage", "label")
        let linkageContainer = $$$(pagePropertiesPanel, "linkage", "container")
        let pagePropertiesTitle = $$$(pagePropertiesPanel, "page-properties", "title")
        let slugLink = $$$(pagePropertiesPanel, "page-properties-label", "link")



        let parentFolderGroup = $$$(pagePropertiesPanel, "parent-folder", "group")

        let importGroup = $$$(pagePropertiesPanel, "page-group-settings", "import")
        let folderContainer = $$$(pagePropertiesPanel, "folder", "container") //allows for pages to be moved from one folder to the other


        let developerPanel = $$$(pagePropertiesPanel, "page-group-settings", "development")
        let codePath = $$$(pagePropertiesPanel, "page-properties-code", "url")
        let theFunction = $$$(pagePropertiesPanel, "page-properties-code", "function")


        let packageGroup = $$$(pagePropertiesPanel, "page-group-settings", "package")
        let adServingGroup = $$$(pagePropertiesPanel, "page-group-settings", "adserving")

        let pageRoleDropdown = $$$(pagePropertiesPanel, "page-properties-role", "role")

        let bottomPanelButtons = $$$(pagePropertiesPanel, "page-properties-panel", "bottom")

        _pages.setUpRoleDropdown(pageRoleDropdown, page)


        let pageButtons = $$$$(pagePropertiesPanel, "page-properties-button")
        pageButtons.copy.onclick = event => {
            _pages.copyElement(navItem, page)
        }


        pageButtons.paste.onclick = event => {
            _pages.pasteElement(navItem, page)
        }

        if (!_pages.clipboard) {
            pageButtons.paste.classList.add("hidden")
        } else {
            pageButtons.paste.classList.remove("hidden")
        }




        let isRoot = false
        if (!page.ancestor) {
            isRoot = true
        }

        close.onclick = event => {
            event.stopPropagation()
            event.preventDefault()
            _pages.closePagePropertiesPanel()
        }


        duplicate.onclick = event => {
            event.stopPropagation()
            event.preventDefault()
            _pages.duplicatePage(page, event, true)
        }

        del.onclick = del2.onclick = event => {
            event.stopPropagation()
            event.preventDefault()
            _pages.deletePage(page, event, true)
        }

        let roleContainer = $$$(pagePropertiesPanel, "role", "container")
        _pages.manageStyleScopes(page, pagePropertiesPanel)

        if (page.type == "folder") {
            _pages.managePageSchema(pagePropertiesPanel, page)
            packageGroup.classList.remove("hidden")
            _pages.manageSaveAsPackage(page, pagePropertiesPanel)
            importGroup.classList.add("hidden")
            parentFolderGroup.classList.add("hidden")
            folderContainer.classList.remove("active") //allows for moving pages across folders
            linkageContainer.classList.add("active")
            homepage.classList.add("hidden")
            pagePropertiesTitle.textContent = "Folder: " + page.properties.name
            bottomPanelButtons.classList.remove("hidden")

            if (isRoot) {
                pagePropertiesTitle.textContent = "Project: " + page.properties.name
            }

            developerPanel.classList.remove("hidden")
        } else {

            //is page

            packageGroup.classList.add("hidden")

            importGroup.classList.remove("hidden")
            parentFolderGroup.classList.remove("hidden")
            _pages.managePageAncestorChanges(pagePropertiesPanel, page)
            developerPanel.classList.add("hidden")
            linkageContainer.classList.remove("active")
            linkageContainer.classList.add("active")
            folderContainer.classList.add("active")
            homepage.classList.remove("hidden")
            //pagePropertiesTitle.textContent = "Page: " + page.properties.name
            pagePropertiesTitle.textContent = "Page Properties"
            bottomPanelButtons.classList.remove("hidden")
        }

        if (!page.ancestor) {
            packageGroup.classList.add("hidden")
            importGroup.classList.add("hidden")
            parentFolderGroup.classList.add("hidden")
            homepage.classList.add("hidden")
            developerPanel.classList.add("hidden")
            adServingGroup.classList.add("hidden")
            linkageContainer.classList.remove("active")
            folderContainer.classList.remove("hidden")
            roleContainer.classList.add("hidden")
            bottomPanelButtons.classList.add("hidden")

        }

        if (page.properties.role == "schemaroot") {
            //can't create package from root
            //packageGroup.classList.add("hidden")
        }



        slugLink.onclick = event => {
            event.stopPropagation()
            event.preventDefault()

            let pageURL
            if (isRoot) {
                pageURL = _pages.getProjectURL(page).preview
            } else {
                pageURL = _utils.getPageURL(page).preview
            }

            window.open(pageURL, '_blank');
        }

        homepage.onclick = event => {
            event.stopPropagation()
            event.preventDefault()
            let ancestor = _find.elementByID(publicai.pages, page.ancestor)
            ancestor.homepage = page.id
            notifications.show([
                ["You got it.", page.properties.name + " is now the homepage."],
            ])
            _pages.activateTab()

        }


        _pages.fetchContentFunctionality(navItem, page)

        for (let item of allInputs) {
            activateInput(item)
        }



        function updateProjectSettings() {
            nwsapp.currentProject.name = page.properties.name
            nwsapp.currentProject.slug = page.properties.slug
            handle.save()
        }

        let slug = $$$(pagePropertiesPanel, "page-properties-input", "slug")

        function updateSlug(value) {
            slug.value = page.properties.slug = value.replace(/\s+/g, '-').toLowerCase()
        }


        function activateInput(item) {
            let property = item.getAttribute("page-properties-input")
            item.value = page.properties[property]
            item.oninput = event => {
                page.properties[property] = item.value
                if (property == "name") {
                    updateSlug(item.value)
                }

            }
            item.onblur = event => {
                _pages.activateTab()
                if (isRoot) {
                    //if editing the root folder, update the name and slug in currentProjet as well
                    updateProjectSettings()
                }
            }
        }


    },


    setUpRoleDropdown: function (pageRoleDropdown, page) {

        pageRoleDropdown.value = page.properties?.role || "No Role"

        let pageRoles = [
            {
                "label": "Default",
                "value": "default",
                "icon": "edit_note",
            },
            {
                "label": "Schema Index", //add check to see if this is part of a schema
                "value": "schema_index",
                "icon": "view_stream",
            },
            {
                "label": "Components",
                "value": "components",
                "icon": "videogame_asset",
            },
            {
                "label": "Search",
                "value": "searches",
                "icon": "search",
            },
            {
                "label": "Agent",
                "value": "prompts",
                "icon": "draw",
            },
            {
                "label": "Template",
                "value": "template",
                "icon": "widgets",
            }
        ]



        pageRoleDropdown.onclick = function (e) {
            css_utils.reg_dropdown({
                "selector": pageRoleDropdown,
                "value": "",
                "multiSelect": false,
                "search": true,
                "values": pageRoles,
                "label": "Add new element",
                "styles": ["auto", "horizontal", "light", "no_input", "lighter"],
                "customMode": true,
                "save": false,
                "callback": updatePageRole,
                "direction": "down",
                "overlayColor": "black",
                "overlayOpacity": 0.02,
                "width": "100%"
            })
        }

        function updatePageRole(s) {
            page.properties.role = s.value
            pageRoleDropdown.value = s.label
        }

    },



    getProjectURL: function (page) {






        let production = nws.previewURL + nwsapp.profile.websiteAlias + "/" + nwsapp.currentProject.slug + "/"
        let preview = nws.unCachedURL + nwsapp.profile.websiteAlias + "/" + nwsapp.currentProject.slug + "/"

        return ({
            "production": production,
            "preview": preview,
        })

    },




    managePageSchema: function (pagePropertiesPanel, page) {

        //very similar to the _dynamic.schema function

        let linkage = $$$(pagePropertiesPanel, "page-properties-linkage", "input")
        let linkageLabel = $$$(pagePropertiesPanel, "page-properties-linkage", "label")
        let linkageContainer = $$$(pagePropertiesPanel, "linkage", "container")


        if (page.schema) {
            linkage.value = page.schema.name
        } else {
            linkage.value = "Static Data"
        }


        let collectionsList = []
        collectionsList.push(
            {
                "label": "Static - No Data Source",
                "value": null,
                "icon": "block",
            }
        )
        for (let key of Object.keys(publicai.dataSources.schemas)) {
            let item = publicai.dataSources.schemas[key]
            collectionsList.push(
                {
                    "label": item.name,
                    "value": item.id,
                    "icon": "insert_chart",
                }
            )
        }


        linkage.onclick = function (e) {
            css_utils.reg_dropdown({
                "selector": linkage,
                "value": "",
                "multiSelect": false,
                "search": true,
                "values": collectionsList,
                "label": "Add new element",
                "styles": ["auto", "horizontal", "light", "no_input", "lighter"],
                "customMode": true,
                "save": false,
                "callback": selectSchema,
                "direction": "down",
                "overlayColor": "black",
                "overlayOpacity": 0.02,
                "width": "100%"
            })
        }



        function selectSchema(settings) {
            if (!settings.value) {
                //means it's static
                delete page.schema
                linkage.value = "Static Data"
                return
            }

            if (settings.value != page.schema?.id) {
                console.log("settings", settings)
                delete page.schema
                if (settings.value) {
                    page.schema = {
                        id: settings.value,
                        name: settings.label,
                    }
                    linkage.value = settings.label

                    //settings the schema on children
                    for (let childPage of page.content) {
                        delete childPage.schema
                        childPage.schema = {
                            id: settings.value,
                            name: settings.label,
                        }
                    }
                }


            }
        }



    },

    ensureObjectCompatibility: function (object) {
        //replace object opacity with value * 100
        // our slider takes values from 0 to 100
        if (object.opacity) {
            object.opacity = object.opacity * 100
        }

        if (object["flex-direction"] == "column-reverse") {
            object["flex-direction"] = "column"
        }

    },


    fetchContent: async function (url) {
        _parser.parsePageAndCSS(url).then(result => {
            console.log('Final parsed result:', result);

            delete publicai.active.page.content;
            publicai.active.page.content = [];
            publicai.active.page.content.push(result.htmlStructure.content[0]);

            for (let className in result.cssRules) {
                //check if the class is part of result.usedClasses array
                let baseClass = className.split('.')[0];
                if (result.usedClasses.indexOf(baseClass) === -1) {
                    //console.log("Class not used in the page:", className);
                    //continue;
                }


                let processedClass = this.processClassWithCombos(result.cssRules[className], className);
                publicai.css[className.replace(/^\./, '')] = processedClass;
            }

            console.log("Processed CSS:", publicai.css);

            _pages.closePagePropertiesPanel();
            editor.editorInit();
        });
    },

    processClassWithCombos: function (classData, className) {
        console.log("Processing class:", className, classData);

        // Ensure className doesn't have a leading dot
        className = className.replace(/^\./, '');

        let classParts = className.split('.');  // Split by dot
        let baseClass = classParts[0];          // The base class
        let comboParts = classParts.slice(1);   // All combo parts after the base class

        // Ensure that publicai.css has the base class initialized if not already present
        if (!publicai.css[baseClass]) {
            publicai.css[baseClass] = this.createBaseClassStructure({});
        }

        if (comboParts.length > 0) {
            // This is a combo class, we need to traverse through the combo hierarchy
            let currentLevel = publicai.css[baseClass].combo;

            comboParts.forEach((comboPart, index) => {
                // Ensure the current level has the combo part initialized
                if (!currentLevel[comboPart]) {
                    currentLevel[comboPart] = this.createBaseClassStructure({});
                }

                // If this is the last part of the combo, apply the class data
                if (index === comboParts.length - 1) {
                    Object.assign(currentLevel[comboPart], classData);
                    console.log("Added combo part:", comboPart, currentLevel[comboPart]);
                } else {
                    // Move deeper into the combo hierarchy
                    currentLevel = currentLevel[comboPart].combo;
                }
            });
        } else {
            // If there are no combo parts, this is the base class
            Object.assign(publicai.css[baseClass], classData);
            console.log("Added base class:", baseClass, publicai.css[baseClass]);
        }

        return publicai.css[baseClass];  // Return the updated base class for further processing if needed
    },



    createBaseClassStructure: function (styles) {
        return {
            combo: {},                 // Container for combo classes
            state: {},                 // Container for state-specific styles (e.g., :hover)
            keyframes: {},             // Container for keyframe animations
            keyframe_definitions: {},  // Definitions of the keyframes themselves
            ...styles                  // Initial styles to merge
        };
    },


    fetchContentFunctionality: function (navItem, page) {

        let input = $$$(document, "page-properties-fetch", "input")
        let button = $$$(document, "page-properties-fetch", "button")

        input.onfocus = event => {
            if (input.value == "") {
                input.value = "https://"
            }
        }

        const handleKeyPress = event => {
            if (event.key === "Enter") {
                event.stopPropagation()
                event.preventDefault()
                _pages.fetchContent(input.value)
                input.removeEventListener("keypress", handleKeyPress)
            }
        }

        input.addEventListener("keypress", handleKeyPress)

        button.onclick = event => {
            event.stopPropagation()
            event.preventDefault()
            _pages.fetchContent(input.value)
            input.removeEventListener("keypress", handleKeyPress)
        }

    },

    deselectAll: function () {
        //console.log("deselect all")

        let container = document.querySelector(".pages_items")
        if (!container) {
            container = interactive_controls?.panels?.pages
        }

        let allNavItems = container.querySelectorAll(".pages_item_main")
        for (let item of allNavItems) {
            if (item.getAttribute("nav-id") != _pages.selected) {
                render.addClassesToAllChildrenOf(item, "nav-selected", false)
            } else {
                publicai.active.pageItem = item
                render.addClassesToAllChildrenOf(item, "nav-selected", true)
            }
        }
    },

    activateTab: function (editNewlyAdded = null) {


        let container = document.querySelector(".pages_items")



        //add root or project folder


        container.onclick = event => {
            event.stopPropagation()
            event.preventDefault()
            _pages.selected = publicai.pages.id //root
            _pages.closePagePropertiesPanel()
            _pages.deselectAll()
        }



        removeAllChildNodes(container)

        //adding root 

        function createRootFolderElement() {
            let navItem = addWhatWhere(components.ui.pages_item_main, container)
            let label = navItem.querySelector(".el_label")

            let icon = navItem.querySelector(".el_icon")
            let elementItem = navItem.querySelector(".pages_item")
            let close = navItem.querySelector(".el_close")
            let childrenContainer = navItem.querySelector(".element_children")
            let settingsContainer = navItem.querySelector(".page_settings_container")


            label.textContent = "Project"
            icon.textContent = "view_timeline"

            close.onclick = event => {
                event.preventDefault()
                event.stopPropagation()
                childrenContainer.classList.toggle("inactive")
            }

            navItem.onclick = event => {
                _pages.deselectAll()



                //childrenContainer.classList.toggle("inactive")

                elementItem.classList.add("nav-selected")



                _pages.pagePropertiesPanel(navItem, publicai.pages)
                event.preventDefault()
                event.stopPropagation()
            }

            return (childrenContainer)

        }

        let content = publicai[_pages.currentTab].content
        let rootLocation = createRootFolderElement()


        _pages.deselectAll()
        let settings = {
            el: content,
            location: rootLocation,
            ancestor: publicai[_pages.currentTab],
            editNewlyAdded: editNewlyAdded
        }
        _pages.addPageNavigationElement(settings)







        _pages.closeAll()



        //closeAll() 
        _pages.focusOnElement(publicai.active.page.id)

    },

    closeAll: function () {
        let allExpandableItems = document.querySelectorAll(".element_children")
        for (let item of allExpandableItems) {
            item.classList.toggle("inactive")
        }
    },

    focusOnElement: function (id) {

        publicai.active.pageItem = $$$(document, "nav-id", id)

        //console.log("active one ", publicai.active.pageItem)

        removeClassFromParents(publicai.active.pageItem, "inactive")
        if (publicai.active.pageItem) {
            publicai.active.pageItem.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
    },


    contentIcons: {
        "page": "description",
        "folder": "folder",
        "template": "design_services",
        "prompt": "keyboard",
        "search": "search",
        "schemaroot": "database",
        "schema": "insert_chart",
        "schema_index": "view_stream",
        "components": "videogame_asset",

    },



    addPageNavigationElement: function (settings) {

        //destructure s object into variables el location ancestor

        let { el, location, ancestor, editNewlyAdded } = settings

        if (el.length == 0) {
            let placeholder = addWhatWhere(components.ui.empty_placeholder, location)
            placeholder.textContent = "No pages here yet."
            return
        }

        //console.log("rendering pages at", el)


        let index = 1
        for (let page of el) {

            let navItem = addWhatWhere(components.ui.pages_item_main, location)
            let label = navItem.querySelector(".el_label")
            let icon = navItem.querySelector(".el_icon")
            let visibility = navItem.querySelector(".el_visibility")
            let elementItem = navItem.querySelector(".pages_item")
            let close = navItem.querySelector(".el_close")
            let childrenContainer = navItem.querySelector(".element_children")
            let settingsContainer = navItem.querySelector(".page_settings_container")

            let controls = $$$$(navItem, "data-page")


            let pagePropertiesPanel = document.querySelector(".page_properties")


            controls.copy.onclick = event => {
                event.stopPropagation()
                event.preventDefault()
                _pages.duplicatePage(page, event, true)
            }

            controls.delete.onclick = event => {
                event.stopPropagation()
                event.preventDefault()
                _pages.deletePage(page, event, true)
            }

            if (publicai.active.page.id == page.id) {
                render.addClassesToAllChildrenOf(navItem, "nav-selected", true)
            }


            navItem.setAttribute("nav-id", page.id)

            let home = _find.elementByID(publicai.pages, ancestor.id).homepage



            close.onclick = event => {
                event.preventDefault()
                event.stopPropagation()
                childrenContainer.classList.toggle("inactive")
            }


            controls.settings.onclick = event => {
                event.stopPropagation()
                event.preventDefault()
                _pages.pagePropertiesPanel(navItem, page)
            }

            navItem.ondblclick = event => {
                event.stopPropagation()
                event.preventDefault()
                _pages.pagePropertiesPanel(navItem, page)
            }

            navItem.onclick = event => {

                if (pagePropertiesPanel && pagePropertiesPanel.classList.contains("active")) {
                    //if properties panel is already open, open up the properties page, don't change the page
                    _pages.pagePropertiesPanel(navItem, page)
                }

                _pages.selected = page.id
                if (page.type == "page") {


                    window.openEditorPath('/' + "page" + "/" + page.id);
                    _nav.toggleNavigationContainer(false)
                    //_pages.openPage()
                }
                _pages.deselectAll()

                if (page.type == "folder") {
                    //icon.textContent = "folder_open"
                    //_nav.toggleNavigationContainer(true)

                    childrenContainer.classList.toggle("inactive")

                    if (page.homepage && _find.elementByID(publicai.pages, page.homepage)) {
                        console.log("has homepages")
                        //window.openEditorPath('/' + "page" + "/" + page.homepage);
                        //_nav.toggleNavigationContainer(false)
                    }
                }

                event.preventDefault()
                event.stopPropagation()
            }


            elementItem.onmouseenter = event => {
                settingsContainer.classList.add("active")
            }

            elementItem.onmouseleave = event => {
                settingsContainer.classList.remove("active")
            }


            controls.edit.onclick = event => {
                event.stopPropagation()
                event.preventDefault()
                _pages.editPageName(navItem, page)
            }


            if (editNewlyAdded == page.id) {
                //activate the edit on new page creation
                _pages.editPageName(navItem, page)
            }


            let hidden = false
            //go through stack
            if (hidden) {
                visibility.textContent = "visibility_off"
            }

            //console.log("have added", page)

            icon.textContent = _pages.icons[page.type]
            label.textContent = page.properties.name

            index++


            if (page.id == home) {
                icon.textContent = "home"
            }


            if (page.properties.role == "schemaroot") {
                icon.textContent = "database"
                //elementItem.classList.add("is-template")
            }

            let templatesAncestor = _find.checkIfAncestorIsTemplatesFolder(page.id)

            //console.log("templatesAncestor", templatesAncestor)

            if (!page.properties.size) {
                page.properties.size = _defaults.getDefaultSizeSettings()
            }

            if (templatesAncestor && page.type != "folder" && page.properties.role != "_system") {
                //to add _system properties to utility pages, although it's best to take them outside of the templates folder
                page.properties.role = "template"
            }

            if (page.properties.role == "template" || page.properties.role == "templates") {
                //icon.textContent = "design_services"
                //elementItem.classList.add("is-template")
            }

            if (page.properties.role == "prompt" || page.properties.role == "prompts") {
                //prompts is the folder
                icon.textContent = "keyboard"
                elementItem.classList.add("is-prompt")
            }

            if (page.properties.role == "search") {
                icon.textContent = "search"
                elementItem.classList.add("is-search")
            }


            if (page.properties.role) {
                //doesn't work - investigate _utils.createTooltip
                /*     elementItem.setAttribute("tooltip", page.properties.role)
                    elementItem.setAttribute("tooltip-style", "light")
                    elementItem.setAttribute("position", "right") */
            }


            label.ondblclick = event => {
                event.stopPropagation()
                event.preventDefault()
                _pages.editPageName(navItem, page)
            }

            if (page.type == "folder") {

                let settings = {
                    el: page.content,
                    location: childrenContainer,
                    ancestor: page,
                    editNewlyAdded: null
                }

                _pages.addPageNavigationElement(settings)
            } else {
                close.classList.add("inactive")
            }

        }
    },

    duplicatePage: function (page, event) {



        let ancestor = _find.elementByID(publicai.pages, page.ancestor)

        console.log("d", page, ancestor)
        let pageClone = JSON.parse(JSON.stringify(page))
        pageClone.id = generateSceneID()
        pageClone.properties.name = _pages.checkIfNameIsUnique(ancestor.content, pageClone.properties.name)
        pageClone.properties.slug = pageClone.properties.name.replace(/\s+/g, '-').toLowerCase()
        pageClone.ancestor = ancestor.id
        ancestor.content.push(pageClone)

        _pages.selected = ancestor.id

        _pages.activateTab(pageClone.id)
        //_pages.pagePropertiesPanel(publicai.active.pageItem, pageClone)




    },



    icons: {
        "root": "home",
        "folder": "folder",
        "page": "draft",
        "source": "code",
        "utility": "build",
    },

}