export function getDefaultProject() {

    let config = getConfigFluff()

    config.css = {}
    config.definitions = {}
    config.formats = _defaults.getSizes()
    config.transitions = {}
    config.effects = {}
    config.library = {
        copy: {},
        folders: {
            root: {
                "id": null,
                "ancestor": null,
                "name": "root",
                "role": "root",
                "publicId": null,
                "folderName": generateShortId(6)
            },

        },
        voiceover: {

        },
        files: {
            "3fb372a4-df6b-11ee-86e5-2c7e1d97ad4e": {
                "size": 814,
                "ready": true,
                "id": "TWVkaWFOb2RlOjIzMDc5MDM=",
                "publicId": "3fb372a4-df6b-11ee-86e5-2c7e1d97ad4e",
                "url": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/check_white-4.svg/compress/",
                "mediaType": "VECTOR",
                "meta": {},
                "previewUrl": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/check_white-4.svg/thumbnail/",
                "created": "1710136221828.7139",
                "fileName": "check_white-4.svg",
                "width": null,
                "height": null,
                "licensed": false,
                "tags": {

                },
                "description": {
                    "vision": null,
                    "modified": "11 March 2024 at 05:50:21 am",
                    "entries": {
                        "general": "Content description"
                    }
                },
                "info": {
                    "width": 150,
                    "height": 150,
                    "aspectRatio": 1
                }
            },


            "4e7d6bfe-e5e1-11ee-b6ea-7f99467840c9": {
                "size": 669,
                "ready": true,
                "id": "TWVkaWFOb2RlOjIzMTUxNDM=",
                "publicId": "4e7d6bfe-e5e1-11ee-b6ea-7f99467840c9",
                "url": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/1arrow_up-1.svg/compress/",
                "mediaType": "VECTOR",
                "meta": {},
                "previewUrl": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/1arrow_up-1.svg/thumbnail/",
                "created": "1710846634231.241",
                "fileName": "1arrow_up-1.svg",
                "width": null,
                "height": null,
                "licensed": false,
                "tags": {
                    "edges": [
                        {
                            "node": {
                                "id": "VGFnTm9kZTo4NjYyOA==",
                                "publicId": "446b162a-e5e1-11ee-bb1e-52a313cbed4c",
                                "name": "matrix-maverick_quei8b"
                            }
                        }
                    ]
                },
                "description": {
                    "vision": null,
                    "modified": "19 March 2024 at 11:10:34 am",
                    "entries": {
                        "general": "Content description"
                    }
                },
                "info": {
                    "width": 24,
                    "height": 24,
                    "aspectRatio": 1
                }
            },

            "39ac3d18-e20c-11ee-92e6-3992b7f683f6": {
                "size": 318,
                "ready": true,
                "id": "TWVkaWFOb2RlOjIzMTE2NTE=",
                "publicId": "39ac3d18-e20c-11ee-92e6-3992b7f683f6",
                "url": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/paperclip_icon-1.svg/compress/",
                "mediaType": "VECTOR",
                "meta": {},
                "previewUrl": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/paperclip_icon-1.svg/thumbnail/",
                "created": "1710425263014.171",
                "fileName": "paperclip_icon-1.svg",
                "width": null,
                "height": null,
                "licensed": false,
                "tags": {
                    "edges": [
                        {
                            "node": {
                                "id": "VGFnTm9kZTo4NjU1MQ==",
                                "publicId": "2830a538-e20c-11ee-8273-b7eb22c14e92",
                                "name": "astral-puzzle-prodigy_zijzfe"
                            }
                        }
                    ]
                },
                "description": {
                    "vision": null,
                    "modified": "14 March 2024 at 02:07:43 pm",
                    "entries": {
                        "general": "Content description"
                    }
                },
                "info": {
                    "width": 150,
                    "height": 150,
                    "aspectRatio": 1
                }
            },

            "4b6c4483-e07b-11ee-9900-1a16b4c5d46c": {
                //menu
                "size": 605,
                "ready": true,
                "id": "TWVkaWFOb2RlOjIzMDk0NTc=",
                "publicId": "4b6c4483-e07b-11ee-9900-1a16b4c5d46c",
                "url": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/menu_item.svg/compress/",
                "mediaType": "VECTOR",
                "meta": {},
                "previewUrl": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/menu_item.svg/thumbnail/",
                "created": "1710253064605.518",
                "fileName": "menu_item.svg",
                "width": null,
                "height": null,
                "licensed": false,
                "tags": {
                    "edges": [
                        {
                            "node": {
                                "id": "VGFnTm9kZTo4NjUxOQ==",
                                "publicId": "444780f2-e07b-11ee-8fde-c74e6dab8264",
                                "name": "ethereal-inquirer_80pkea"
                            }
                        }
                    ]
                },
                "description": {
                    "vision": null,
                    "modified": "12 March 2024 at 02:17:44 pm",
                    "entries": {
                        "general": "Content description"
                    }
                },
                "info": {
                    "width": 32,
                    "height": 32,
                    "aspectRatio": 1
                }
            },

            placeholder_audio: {
                "id": "TWVkaWFOb2RlOjIzOTAwOTQ=",
                "url": "https://cdn.thenewsroom.io/platform/story_media/1288826107/epic-cinematic-logo-04-long.wav",
                "meta": {},
                "size": 321853,
                "tags": {},
                "ready": true,
                "width": null,
                "height": null,
                "created": "1708962967959.495",
                "fileName": "epic-cinematic-logo-04-long.wav",
                "publicId": "2f85bad6-2d87-11ef-91f8-cd72cdeb270d",
                "previewUrl": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/kalgoorlie.jpg/thumbnail/",
                "type": "AUDIO",
            },


            placeholder: {
                "id": "TWVkaWFOb2RlOjIyOTY1NDc=",
                "url": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/kalgoorlie.jpg/compress/",
                "info": {
                    "width": 299,
                    "height": 200,
                    "aspectRatio": 1.495
                },
                "meta": {},
                "size": 321853,
                "tags": {},
                "ready": true,
                "width": null,
                "height": null,
                "created": "1708962967959.495",
                "fileName": "kalgoorlie.jpg",
                "licensed": false,
                "publicId": "8de66043-d4bf-11ee-81f5-2e362ea9bd39",
                "mediaType": "IMAGE",
                "previewUrl": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288826107/kalgoorlie.jpg/thumbnail/"
            }
        },
        tags: {},
    }
    config.sources = {
        "collections": {},
        "schemasInUse": [],
        "schemas": {}
    }


    //let projectName = getRandomElement(handle.randomProjectNames) + "_" + generateShortId()
    let projectName = "New Project"
    let projectSlug = projectName.replace(/\s+/g, '-').toLowerCase()

    config.library.folders.root.folderName = "Project Media"

    config.root = {

        pages: {
            //these are actual pages
            "id": generateSceneID(),
            "type": "folder",
            "homepage": null,
            "ancestor": null,
            "properties": {
                "name": projectName,
                "slug": projectSlug,
                "description": "",
                "code": "",
                "meta": "",
                "js": "",
                "entry": ""
            },
            "content": []
        },
        "symbols": {}, //stores user-made symbols
        "components": null, //stores re-useable components file paths
        definitions: {
            classes: _defaults.pageCSS,

        },
        utility: {
            //these are utility pages like 404, error, etc
            "id": generateSceneID(),
            "type": "utility",
            "homepage": null,
            "ancestor": null,
            "content": [],
        },

    }

    config.root.definitions.classes.keyframes = { ..._defaults.keyframes }

    

    let projectData = {
        "name": projectName,
        "slug": projectSlug,
        "config": config,
        "id": 0,  // Use a function to generate the ID or use an existing one for updates
        "userId": "1"  // The user's ID (partition key in the table)
    };

    //console.log("new project is", projectData);

    return projectData

}

export default getDefaultProject