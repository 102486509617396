import { CognitoIdentityProviderClient, AdminCreateUserCommand, AdminGetUserCommand, AdminSetUserPasswordCommand } from "@aws-sdk/client-cognito-identity-provider";
import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoRefreshToken } from 'amazon-cognito-identity-js';

import { config } from '../../../config.js';  // Adjust path as needed

// Look up the user in Cognito
export async function lookupCognitoUser(email) {
    const cognitoClient = new CognitoIdentityProviderClient({ region: config.region });

    try {
        const command = new AdminGetUserCommand({
            UserPoolId: process.env.COGNITO_USER_POOL_ID,
            Username: email
        });
        const response = await cognitoClient.send(command);
        console.log(`User ${email} exists in Cognito.`);
        return true;  // User exists
    } catch (error) {
        if (error.name === 'UserNotFoundException') {
            console.log(`User ${email} does not exist in Cognito.`);
            return false;  // User does not exist
        } else {
            throw error;  // Other errors
        }
    }
}

// Register a new user in Cognito
export async function registerCognitoUser(user) {
    const generatedPassword = generateStrongPassword();

    const params = {
        UserPoolId: process.env.COGNITO_USER_POOL_ID,
        Username: user.email,
        TemporaryPassword: generatedPassword,
        UserAttributes: [
            { Name: 'email', Value: user.email },
            { Name: 'email_verified', Value: 'true' },
            { Name: 'name', Value: user.name },
            { Name: 'picture', Value: user.picture || '' }
        ]
    };

    try {
        const cognitoClient = new CognitoIdentityProviderClient({ region: config.region });
        const command = new AdminCreateUserCommand(params);
        await cognitoClient.send(command);
        console.log("User successfully registered in Cognito.");
        user.generatedPassword = generatedPassword;
        return true;
    } catch (error) {
        console.error('Error registering user in Cognito:', error);
        return false;
    }
}

// Reset the user's password and make it permanent
export async function resetCognitoUserPassword(email, newPassword) {
    const params = {
        UserPoolId: process.env.COGNITO_USER_POOL_ID,
        Username: email,
        Password: newPassword,
        Permanent: true  // Set the new password as permanent so the user doesn't have to change it on next login
    };

    try {
        const cognitoClient = new CognitoIdentityProviderClient({ region: config.region });
        const command = new AdminSetUserPasswordCommand(params);
        await cognitoClient.send(command);
        console.log(`Password reset successful for user: ${email}`);
        return true;
    } catch (error) {
        console.error('Error resetting password for user:', error);
        return false;
    }
}

// Authenticate the user with Cognito
export async function authenticateUserWithCognito(email, password) {
    const userPool = new CognitoUserPool({
        UserPoolId: process.env.COGNITO_USER_POOL_ID,
        ClientId: process.env.COGNITO_CLIENT_ID,
    });

    const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
    const authenticationDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
    });

    return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                //console.log("Authentication successful", result);
                const tokens = {
                    accessToken: result.getAccessToken().getJwtToken(),
                    idToken: result.getIdToken().getJwtToken(),
                    refreshToken: result.getRefreshToken().getToken(),
                };
                resolve(tokens);  // Return tokens for further usage
            },
            onFailure: (err) => {
                console.error('Authentication failed:', err);
                reject(err);
            },
        });
    });
}

// Unified cookie management for session tokens (server-side)
export function storeSessionTokens(res, tokens) {
    res.cookie('accessToken', tokens.accessToken, { httpOnly: true, secure: true });
    res.cookie('idToken', tokens.idToken, { httpOnly: true, secure: true });
    res.cookie('refreshToken', tokens.refreshToken, { httpOnly: true, secure: true });
}

// Refresh the session token using the refresh token
export function refreshSession(refreshToken, username) {
    return new Promise((resolve, reject) => {
        const userPool = new CognitoUserPool({
            UserPoolId: config.UserPoolId,
            ClientId: config.ClientId,
        });

        const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });
        
        // This is where the CognitoRefreshToken is used
        const session = new CognitoRefreshToken({
            RefreshToken: refreshToken
        });

        cognitoUser.refreshSession(session, (err, session) => {
            if (err) {
                console.error("Error refreshing session:", err);
                reject(err);
            } else {
                const newTokens = {
                    accessToken: session.getAccessToken().getJwtToken(),
                    idToken: session.getIdToken().getJwtToken(),
                    refreshToken: session.getRefreshToken().getToken()
                };
                resolve(newTokens);  // Return new session tokens
            }
        });
    });
}


// Log the user out by clearing cookies
export function logout(res) {
    console.log("Logging out user");
    res.clearCookie('accessToken');
    res.clearCookie('refreshToken');
    res.clearCookie('idToken');
    res.clearCookie('googleUser');
}

// Helper function to generate a strong password
export function generateStrongPassword() {
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const digits = '0123456789';
    const specialChars = '!@#$%^&*()_+[]{}|;:,.<>?';

    const allChars = uppercase + lowercase + digits + specialChars;
    let password = '';
    password += uppercase[Math.floor(Math.random() * uppercase.length)];
    password += lowercase[Math.floor(Math.random() * lowercase.length)];
    password += digits[Math.floor(Math.random() * digits.length)];
    password += specialChars[Math.floor(Math.random() * specialChars.length)];

    for (let i = 4; i < 12; i++) {
        password += allChars[Math.floor(Math.random() * allChars.length)];
    }

    return password;
}


export async function createAnonymousUser() {
    // Generate a unique email and password for the anonymous user
    console.log("Creating anonymous user");
    const timestamp = Date.now();
    const anonymousEmail = `john.doe.${timestamp}@anonymous.com`;
    const temporaryPassword = generateStrongPassword();
    const permanentPassword = generateStrongPassword();  // Permanent password

    const googleUser = {
        email: anonymousEmail,
        name: 'Anonymous',
        picture: 'https://example.com/default-profile.png',  // Optional, can be a placeholder image
        sub: `anonymous-${timestamp}`,  // Unique identifier
    };

    // Step 1: Register the anonymous user in Cognito with a temporary password
    const registrationSuccess = await registerCognitoUser({
        email: anonymousEmail,
        name: 'Anonymous',
        picture: googleUser.picture,
        password: temporaryPassword  // Use the temporary password
    });

    if (registrationSuccess) {
        // Step 2: Reset the password to a permanent one
        const passwordResetSuccess = await resetCognitoUserPassword(anonymousEmail, permanentPassword);

        if (passwordResetSuccess) {
            // Step 3: Authenticate the user with the permanent password
            const cognitoTokens = await authenticateUserWithCognito(anonymousEmail, permanentPassword);

            // Return the tokens and user info
            return {
                cognitoTokens,
                googleUser: {
                    email: anonymousEmail,
                    name: 'John Doe',
                    picture: googleUser.picture,
                    isAnonymous: true,
                    sub: `anonymous-${timestamp}`,
                }
            };
        } else {
            throw new Error('Failed to reset the anonymous user password');
        }
    } else {
        throw new Error('Failed to register anonymous user');
    }
}
