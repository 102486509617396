import { $$$$ } from "../../../modules/utils/utils.js"
import { addDomain, listDomains, deleteDomain, updateDomain, verifyDomain, setupDomainInfrastructure } from "../../../services/domains/domainsApi.js"


export async function managePageReferences() {
    //manages references once a page opens. Also manages collection page dropdowns

    if (!nwsapp.publishingPath) {
        nwsapp.publishingPath = "v1"
    }

    nwsapp.activeDomains = {}

    nwsapp.publishDomains = await listDomains()

    //check if they're verified first
    console.log("nwsapp.publishDomains", nwsapp.publishDomains)

    publicai.urlStack = []
    publicai.urlStack.push(publicai.active.page.id)
    editor.ui.buttonIcon.textContent = "expand_more"
    editor.createSchemasDropdown()

    editor.ui.pageSettings.onclick = function () {
        window.openEditorPath('/' + "pages")
        setTimeout(function () {
            //to investigate why timeout is needed. Page propeties panel is not immediately available.
            let navItem = $$$(document, "nav-id", publicai.active.page.id)
            _pages.pagePropertiesPanel(navItem, publicai.active.page)
        }, 50)
    }

    let pagesInCurrentFolder = []
    let parent = _find.elementByID(publicai.pages, publicai.active.page.ancestor)
    for (let page of parent.content) {

        let icon = "draft"
        if (page.type == "folder") {
            icon = "folder"
        }

        pagesInCurrentFolder.push(
            {
                "label": page.properties.name,
                "value": page.id,
                "icon": icon
            }
        )
    }


    css_utils.setup_reg_dropdown({
        "selector": editor.ui.pageName,
        "value": "Select",
        "multiSelect": false,
        "search": false,
        "values": pagesInCurrentFolder,
        "label": "Add new element",
        //"styles": ["is_dark", "is_spaced"],
        "customMode": true,
        "save": false,
        "callback": changePage,
        "direction": "down",
        "anchor": "left",
        "overlayColor": "black",
        "overlayOpacity": 0.01,
        "width": "300"
    })

    function changePage(s) {
        //_routes.openPage(s.value)

        window.openEditorPath('/' + "page" + "/" + s.value);
    }


    let previewModes = [

    ]

    previewModes.push({
        "context1": "Staging:",
        "label": "publicai.com/staging",
        "value": "domain.id",
        "icon": "link",
        "icon2": "north_east",
        "type": "link",
        "context2": "Published 21 minutes ago."
    })

    for (let domain of nwsapp.publishDomains) {
        previewModes.push({
            "label": domain.domain,
            "value": domain.id,
            "icon": "link",
            "inputSettings": domainSettings,
            "icon2": "north_east",
            "type": "link",
            "context1": "Production",
            "context2": "Published 21 minutes ago."
        })
    }

    previewModes.push({
        value: "label",
        label: "Destination folder:",
        //context0: "Publish", 
        icon: "conversion_path",
        icon2: "chevron_right",
        type: "publish",
        "inputSettings": publishSettings,
        "context1": "Publish",
        "context2": "Enter the full path where you want your content to be published. For example: articles/2025/january/article1"
    },)



    if (publicai.active.page.properties.role == "template") {
        previewModes.push({
            "label": "Create with Agent",
            "value": "agent",
            "icon": "support_agent"
        })
    }

    let panel = new Menu({
        "selector": editor.ui.previewPage,
        "trigger": false,
        "values": previewModes,
        "label": "Add new element",
        "styles": ["auto", "horizontal", "light", "no_input"],
        "customMode": true,
        "save": false,
        "callback": previewThePage,
        "direction": "down",
        "overlayColor": "rgb(0 0 0)",
        "overlayOpacity": 0.3,
        "width": 380,
        "max_height": 500,
        "iframeMode": "disabled",
        //y: buttonRect.bottom - 10,
        //closeCallback: this.settings.more.closeCallback,
        //keys: this.settings.more.keys
    })




    function previewThePage(s) {

        let pageURL = _utils.getPageURL(publicai.active.page)

        handle.save()
        notifications.show([
            ["Saving...", "This should take a few seconds."]
        ])


        setTimeout(function () {

            switch (s.value) {
                case "preview":
                    window.open(pageURL.preview, '_blank');
                    break;
                case "agent":
                    window.open(pageURL.agent, '_blank');
                    break;
                case "production":
                    window.open(pageURL.production, '_blank');
                    break;
                case "copy_preview":
                    copyToClipboard(pageURL.preview)
                    notifications.show([
                        ["", "Copied to clipboard: " + pageURL.preview]
                    ])
                    break;
                case "copy_production":
                    copyToClipboard(pageURL.production)
                    notifications.show([
                        ["", "Copied to clipboard: " + pageURL.production]
                    ])
                    break;
            }

        }, 1000)

    }

    editor.ui.pageLabel.textContent = publicai.active.page.properties.name
    interactive_controls.init()
}




function domainSettings(menuItem, settings, t, item) {
    let el = $$$$(menuItem, "data-public-menu")
    nwsapp.activeDomains = []
    el.label.setAttribute("domain_id", item.value)
    nwsapp.activeDomains[item.value] = true
    el.link.onclick = (e) => {
        nwsapp.activeDomains[item.value] = !nwsapp.activeDomains[item.value]
        utils.addClassesToAllChildrenOf(menuItem, "is_active", nwsapp.activeDomains[item.value])
    }
}

function updateDomains() {
    let allDomains = $$$$(document, "domain_id")
    for (let domain of nwsapp.publishDomains) {
        let item = allDomains[domain.id]
        if (item) {
            item.textContent = domain.domain + "/" + nwsapp.publishingPath
        }
    }
}


function publishSettings(menuItem, settings, t, item) {

    //console.log(">>>>> publish settings", menuItem, settings, t, item)

    let el = $$$$(menuItem, "input_el")
    el.input.placeholder = "/folder_name"



    el.input.oninput = (e) => {
        // Replace spaces with underscores, remove invalid URL characters, and ensure it doesn't start with "/"
        nwsapp.publishingPath = el.input.value
            .replace(/\s+/g, "_")             // Replace spaces with underscores
            .replace(/[^a-zA-Z0-9/_-]/g, "") // Remove characters not allowed in URLs (except /, _, -)
            .replace(/^\/+/, "");            // Remove leading slashes

        updateDomains();
    };


    el.button.onclick = (e) => {



        console.log("PUBLISH", el.input.value)
    }

    setTimeout(function () {
        el.input.value = nwsapp.publishingPath
        el.input.focus()
        updateDomains()
    }, 0)

}

function publish() {

    let activeDomains = Object.entries(nwsapp.activeDomains)
        .filter(([key, value]) => value === true)
        .map(([key]) => key);

    let publishPath = nwsapp.publishingPath

}
