

window.activeElement = null
window.time = 0

export let publicai = {};

import { managePageReferences } from "./publish/publish.js"


export async function refreshContent() {

    //console.log("Refresh", publicai.pendingUpdates, nws.preset.data)

    for (let section of nws.preset.data.sections) {
        for (let element of section.elements) {
            if (element.status == "completed") {
                for (let pending of Object.values(publicai.pendingUpdates)) {
                    if (pending.schema == element.schema) {
                        //console.log("one element has been completed", pending.id)
                        //allPendingElements.push(pending)

                        let pageElement = _find.elementByID(publicai.active.page, pending.id);
                        //console.log("pageElement", pageElement)
                        let ancestor = _find.elementByID(publicai.active.page, pageElement.ancestor);

                        play_interactive.createPageElements(ancestor.content, ancestor.id, true)
                        pending = null
                    }
                }
            }
        }
    }
    //play_interactive.renderInteractiveComponents()
    play_interactive.checkForFormElements()


}


export async function forceRefreshContent() {


    await editor.clientInit()
    //await _utils.loadExternalScripts()
    await play_interactive.addBodyScene() //adding main scene
    //_utils.extractClientComponents() //extracts components such as the rollover boxes. 
    play_interactive.createPageElements(publicai.active.page.content[0].content)
    applyCSS()
    await play_interactive.addFonts(); // Wait for the fonts to load
    play_interactive.renderInteractiveComponents()
    window["role_" + publicai.active.page.properties.role].init()

}

export async function clientInit() {
    //this is used by the client version of the script. 
    //console.log("client init!")
    let root = nwsapp.currentProject.config.root
    _pages = {}
    await editor.initialise()

    _utils.addScrollListeners()

    publicai.sceneContainer = document.body
    publicai.sceneContainer.style.height = "100vh"
    nws.animatorMode = true
    //console.log("client init", publicai.sceneContainer)

    await _data_init.updatePageData()
    if (nws.edit) {
        nwsapp.light = new LightEditor(
            {
                //entry: entry,
                elements: {
                    "system": null,

                }
            });
    }
}


export async function loadVirtualLibrary() {

    //public.virtual_library = {}

    Object.defineProperty(nwsapp.currentProject.config.library.folders, 'shared', {
        //create a virtual folder
        get() {
            return {
                "id": null,
                "ancestor": "root",
                "name": "Shared",
                "role": "virtual",
                "publicId": null,
                "folderName": "Shared Media"
            };
        },
        enumerable: false,  // This ensures that it won't be serialized or saved
        configurable: true // This ensures that it won't be serialized or saved
    });
    await media_render.fetchSharedMedia()


    /* 

    for (let item of Object.keys(_public.virtual_library.files)) {
        let file = _public.virtual_library.files[item]
        if (file.mediaType.toLowerCase() == "audio" && file.url.includes(this.tab + "_")) {
            matchingFiles.push(file)
        }
    }

    //filter all files with url including this.tab keyword
    let matchingAudio = _public.virtual_library.files.filter((item) => { return item.url.includes(this.tab + "_") }) */

    //console.log("matchingAudio", matchingAudio)


}

export async function editorInit(refreshNavigation = true) {


    let root = nwsapp.currentProject.config.root
    _utils.createFirstPages() //if no pages exist


    await _utils.setUpEditor() //adds editor window
    await editor.initialise()
    _editor_modes.init()

    loadVirtualLibrary()

    if (!nwsapp.currentProject.config.css[root.pages.id]) {
        nwsapp.currentProject.config.css[root.pages.id] = JSON.parse(JSON.stringify(nwsapp.currentProject.config.root.definitions.classes))
        delete publicai.css
        publicai.css = nwsapp.currentProject.config.css[root.pages.id]
    }

    //handle.loadTemplates(true)
    change_history.init()
    publicai.sceneContainer = document.querySelector(".thecontent_holder") // resize this too to get a good border

    //console.log("have set scene containber ", publicai.sceneContainer)

    nws.animatorMode = true

    if (!nws.isPlayer) {

        nwsapp.currentProject.config.websiteAlias = "nwsapp.profile.websiteAlias"
    }

    await _data_init.updatePageData()

    if (nwsapp.router || nws.isPlayer) {
        await editor.renderPage(refreshNavigation)
    } else {
        //the router will re-call the init function

        return new Promise((resolve, reject) => {
            resolve();
        })
    }

    //adding css from system components
    if (components.cssToAdd && components.cssToAdd.length > 0) {

        let finalObject = {}

        for (let css of components.cssToAdd) {

            for (let key of Object.keys(css)) {
                finalObject[key] = css[key]
            }


        }

        applyCSS(null, finalObject, document)
    }

    _utils.addScrollListeners()

    return new Promise((resolve, reject) => {
        const checkInterval = 5;
        const checkReadyState = () => {
            if (publicai.ready) {
                if (publicai.selected.element == publicai.active.bodyElement) {
                    editor.selectBody()
                    delete publicai.active.page.html
                    publicai.active.page.html = publicai.active.bodyElement.outerHTML

                    /*  let innerHTML = publicai.active.bodyElement.outerHTML
                     console.log("outerHTML  ", outerHTML) */

                }
                resolve();
            } else {
                setTimeout(checkReadyState, checkInterval);
            }
        };
        checkReadyState();
    });

}



export async function renderPage(refreshNavigation) {

    nws.systemFonts = [
        "https://cdn.thenewsroom.io/platform/story_media/1288817549/Neuehaasgrotdisp_web.css",
        "https://cdn.thenewsroom.io/platform/story_media/1288817549/NeueHaasGrotText-65Medium-Web.css",
        "https://cdn.thenewsroom.io/platform/story_media/1288826107/google_material_icons.css"
    ]

    if (refreshNavigation) {
        await _nav.init()
    }


    //+-
    //_utils.openCloseTimeline(false) //close the timeline for now    
    //get time to see how long this await takes
    await play_interactive.addBodyScene() //adding main scene
    play_interactive.createPageElements(publicai.active.page.content[0].content)
    play_interactive.renderInteractiveComponents()
    tabs("style")

}


export function tabs(tab) {

    publicai.active.tabs.main = tab

    css_utils.operateTabs({
        "location": document,
        "name": "editor-tab",
        "default": tab,
        "callback": editor.editorTabChange
    })
}

export function editorTabChange(tab) {

    console.log("tab")

    let currentContainer = $$$(document, "editor-tab-content", tab)
    let settingsContainer = $$$(document, "editor-tab-content", "settings")
    let thirdContainer = $$$(document, "editor-tab-content", "third")
    let dataContainer = $$$(document, "editor-tab-content", "data")
    //console.log(tab, currentContainer)

    publicai.active.tabs.main = tab

    removeAllChildNodes(settingsContainer)
    //removeAllChildNodes(thirdContainer)

    if (tab == "animations") {
        addWhatWhere(components.ui.panel_animations, currentContainer)
        animations_init.init()
    } else {

    }

    if (tab == "settings") {
        addWhatWhere(components.ui.panel_settings, currentContainer)
        settings_init.init()
    }

    if (tab == "third") {
        settings_init.thirdTab()
    }

    if (tab == "frame") {
        console.log("frame")
        settings_init.frameManagement()
    }

    if (tab == "data") {
        _dynamic.init()
    }

}

export function saveInStorage(settings) {

    if (!publicai.storage[settings.type]) {
        publicai.storage[settings.type] = {}
    }

    if (!publicai.storage[settings.type][settings.data.id]) {
        publicai.storage[settings.type][settings.data.id] = []
    }
    publicai.storage[settings.type][settings.data.id].push(settings.data)

    //saving to storage
    window.localStorage.setItem("publicai", JSON.stringify(publicai.storage))

}


export async function initialise() {


    nwsapp.ux = $$$$(document, "ux")

    //local storage set up


    let storage = JSON.parse(window.localStorage.getItem("publicai"))
    if (!storage) {
        window.localStorage.setItem("publicai", JSON.stringify(
            {}
        ))
        storage = JSON.parse(window.localStorage.getItem("publicai"))
    }


    nwsapp.animatorMode = true
    //def = nwsapp.currentProject.config.definitions
    let root = nwsapp.currentProject.config.root

    if (!nwsapp.currentProject.config.fonts) {
        nwsapp.currentProject.config.fonts = {}
    }

    if (!nwsapp.currentProject.config.library) {
        nwsapp.currentProject.config.library = {}
    }

    Object.assign(publicai, {



        serviceURL: "https://schema_entries.newsroom-ai9687.workers.dev",
        timeline: {
            active: false,
        },
        mode: "design",
        preload: {},
        indyPlayers: {},
        animatedProperties: {},
        root: root,
        animations: {},
        timeIncrement: 4,
        pickers: [],
        elementsToHighlight: [],
        currentTime: 1000,
        renderTimeout: 0,
        userHasInteracted: false,
        currentFrame: 1,
        fps: 30,
        pendingUpdates: {},
        carousels: {},
        urlStack: [],
        dataSources: nwsapp.currentProject.config.sources,
        keys: {},
        dataEntries: {},
        pageData: null,
        settingsOverlayModes: [], //define classes that can be applied over the right side panels to make panels draggable or float over content
        pages: root.pages,
        table: {},
        highlights: {},
        bullets: {},
        quotes: {},
        dynamic_carousels: {},
        storage: storage,
        utility: root.utility,
        sources: root.sources,
        library: nwsapp.currentProject.config.library,
        virtual_library: {
            files: {},
            folders: {},
        },
        ready: false,
        css: null,
        keys: {},
        globalCss: nwsapp.currentProject.config.css,
        classSeparator: ":::",
        allowedBreakpoints: ["__desktop", "__tablet", "__mobile_h", "__mobile_v"],
        breakpointFriendlyNames: {
            "__desktop": "Desktop",
            "__tablet": "Tablet",
            "__mobile_h": "Mobile H",
            "__mobile_v": "Mobile"
        },
        transitions: nwsapp.currentProject.config.transitions,
        effects: nwsapp.currentProject.config.effects,
        breakpoints: {
            definitions: {
                "__desktop": {
                    min: 992,
                    max: null, // No max for desktop
                    //comment: "Great for desktops"
                },
                "__tablet": {
                    min: 768,
                    max: 991,
                    //comment: "Great for tablets"
                },
                "__mobile_h": {
                    min: 480,
                    max: 767,
                    //comment: "Great for mobile devices in horizontal orientation"
                },
                "__mobile_v": {
                    min: null, // No min for mobile vertical
                    max: 479,
                    //comment: "Great for mobile devices in vertical orientation"
                }
            }
        },
        active: {
            cssStack: null,
            tabs: {},
            path: null, //the path of the css stack
            state: null, //hover, pressed, normal, etc
            size: "Full screen",
            aspect: "9 / 16",
            breakpoint: "__desktop",
            body: null,
            page_index: 0,
            page: null,
            //body: sets[0]?.pages[0]?.content?.find(element => element.type === "body"),
            sceneElement: null,
            em: 25,
            timeline: {},
        },

        panels: {
            //track active panels

        },
        hovering: {
            element: null,
            settings: null,
            id: null,
        },

        /*    selected: {
               element: null,
               settings: null,
               id: null,
           }, */
        resizing: false,
        media: nwsapp.currentProject.config.media,
        activeTab: null,
        scene: null,
        spaceBetweenElements: 10,
        snapConstant: 1, //snap in editor
        clipboard: null,
        fonts: {
            platform: [],
            user: [],
            loaded: new Set(),
            used: nwsapp.currentProject.config.fonts
        },


        current: {
            time: 0
        },

        snapDelay: 1500,
        selected: {},
        zoom: 100,
        timelineConstant: 2.8, // defines the size of the timeline
        keyframesConstant: 2.5, // defines the size of the timeline. Not in use anymore.
        aspects: {
            "9 / 16": {
                sizes: ["Full screen", "1080x1920", "300x600", "720x1280", "480x854", "360x640", "320x480", "240x320"],
                basedFontSize: 25,
                initialTop: 55, // percent

            },
            "1 / 1": {
                sizes: ["Full screen", "1080x1080", "720x720", "480x480", "360x360", "320x320", "240x240"],
                basedFontSize: 25,
                initialTop: 50, // percent
            },
            "16 / 9": {
                sizes: ["Full screen", "1920x1080", "1280x720", "854x480", "640x360", "480x320", "320x240"],
                basedFontSize: 25,
                initialTop: 30, // percent
            },
            "4 / 1": {
                sizes: ["Full screen", "970x250", "728x90", "320x50"],
                basedFontSize: 25,
                initialTop: 10, // percent
            },
        },


    })

    if (!nws.isPlayer) {

        publicai.comps = {
            /*     text: components.user.animator_text,
                multitext: components.user.animator_multitext,
                media: components.user.widget_media,
                cta: components.user.animator_cta,
                branding: components.user.animator_branding,
                shape: components.user.animator_shape,
                floating: components.user.animator_floating,
                scene: components.ui.animator_main, */
            div: components.ui.animator_div,
        }

    }


    if (!nws.isPlayer) {
        publicai.userHasInteracted = true //allow video plays, etc without user interaction
    }


    //save the timeline zoom levels
    let savedConstant = parseFloat(localStorage.getItem("timelineConstant")).toFixed(0)
    let savedKeyframesConstant = parseFloat(localStorage.getItem("keyframesConstant")).toFixed(0)

    let savedZoom = parseFloat(localStorage.getItem("zoom"))

    if (!isNaN(savedConstant)) {
        publicai.timelineConstant = savedConstant
    }

    if (!isNaN(savedKeyframesConstant)) {
        publicai.keyframesConstant = savedKeyframesConstant
    }

    //save the document zoom level
    if (!isNaN(savedZoom)) {
        publicai.zoom = JSON.parse(savedZoom)
    }


    if (!publicai.state) {
        publicai.state = "pages" // obsolte - to be used with utility pages. To consider taking 404, etc into root. 
    }

    let isCollectionPage = false
    let activeEntry = null

    if (nws.isPlayer) {
        let route = nwsapp.currentProject.config.route
        publicai.route = nwsapp.currentProject.config.route

        if (route.path) {
            const temporaryRoute = route.path.split("/")
            temporaryRoute.pop()
            let fullPath = temporaryRoute.join("/")

            let currentPage = _find.pageFromRoute(fullPath, false)
            let rootFolder = _find.getRoot(currentPage.id) || root.pages;

            if (currentPage.dynamic.schema) {
                let entryName = nwsapp.currentProject.config.route.path.split("/").pop();

                publicai.activeEntrySlug = entryName

                let collection = publicai.dataEntries[currentPage.dynamic.schema]

                if (!publicai.dataEntries[currentPage.dynamic.schema] || Object.keys(publicai.dataEntries[currentPage.dynamic.schema]).length == 0) {
                    let searchSettings = {
                        slug: entryName.toLowerCase()
                    }
                    let data = await _data_init.entryLookup(searchSettings)

                    console.log("entry data", data)
                    if (data.length == 0) {
                        _utils.page404()

                    }

                    publicai.dataEntries[currentPage.dynamic.schema] = publicai.selected.collectionEntries = publicai.selected.collection = collection = data
                }

                activeEntry = collection.find(x => x.slug == entryName)

                //find the template page
                if (nws.iframeData) {
                    //data has been preset by the reports page. Replace the entry with that.
                    delete activeEntry.sections
                    activeEntry.sections = JSON.parse(JSON.stringify(nws.iframeData.sections))
                }

                let templatesFolder = rootFolder.content.find(x => x.properties.role == "templates")
                let templatePage = null
                if (templatesFolder) {
                    templatePage = templatesFolder.content[0].content[0]
                }
                _pages.selected = currentPage.id

            } else {
                let foundPage = null
                foundPage = _find.pageFromRoute(nwsapp.currentProject.config.route.path)
                if (foundPage) {
                    _pages.selected = foundPage.id
                } else {
                    console.log("not found")
                }
            }

        }

    } else {

        publicai.zoom = _defaults.documentZoom()

    }


    // Simplify the initial page selection
    publicai.active.page = _find.elementByID(root.pages, _pages.selected || root.pages.homepage);

    if (!nws.isPlayer) { //most likely redundant 
        await managePageReferences();
        _size.refreshMediaQueries(); // Check if this is necessary
    }

    let rootFolder = _find.getRoot(publicai.active.page.id) || root.pages;
    publicai.active.rootFolder = rootFolder;

    // CSS setup
    const rootCSS = nwsapp.currentProject.config.css;
    const rootDefs = nwsapp.currentProject.config.root.definitions.classes;
    rootCSS[rootFolder.id] = rootCSS[rootFolder.id] || JSON.parse(JSON.stringify(rootDefs));
    publicai.css = rootCSS[rootFolder.id];

    // Setup active collection based on page schema
    if (publicai.active.page.schema) {
        publicai.active.collection = publicai.dataSources.schemas[publicai.active.page.schema];
    }

    // Set active body content
    publicai.active.body = publicai.active.page.content[0];

    // Handle reserved keyword
    window._public = publicai;

}

export function createSchemasDropdown() {


    if (Object.keys(publicai.dataSources.schemas).length == 0) {
        editor.ui.schemasSelection.classList.add("hidden")
        return
    }

    editor.ui.schemasSelection.classList.remove("hidden")


    function updateSchemasLabel() {

        let theLabel = "No schema selected"
        if (publicai.active.page.dynamic.schema) {
            theLabel = publicai.dataSources.schemas[publicai.active.page.dynamic.schema].name
        }

        nwsapp.ux.schemas_label.textContent = theLabel

    }

    updateSchemasLabel()


    editor.ui.schemasSelection.onclick = function () {

        let availableSchemas = []

        for (let key of Object.keys(publicai.dataSources.schemas)) {
            availableSchemas.push({
                "label": publicai.dataSources.schemas[key].name,
                "value": publicai.dataSources.schemas[key].id
            })
        }

        css_utils.reg_dropdown({
            "selector": editor.ui.schemasSelection,
            "value": "Select",
            "multiSelect": false,
            "search": false,
            "values": availableSchemas,
            "label": "Add new element",
            "styles": ["auto", "horizontal", "bold", "no_input"],
            "customMode": true,
            "save": false,
            "callback": updateSchemas,
            "direction": "down",
            "anchor": "left",
            "overlayColor": "black",
            "overlayOpacity": 0.01,
            "width": "350"
        })
    }

    async function updateSchemas(s) {
        //make sure schema names are unique as this could cause issues
        publicai.active.page.dynamic.schema = s.value
        updateSchemasLabel()
        await _data_init.updatePageData()
        //if schemas have been removed, this will cause dynamic elements to error. 
    }

}






export function selectBody() {
    _create.deselectAllElements(15)
    publicai.selected.element = publicai.active.bodyElement
    publicai.selected.settings = publicai.active.body
    publicai.selected.id = publicai.active.body.id
    publicai.selected.element.classList.add("public-active-body")
    css_management.manage()
}
