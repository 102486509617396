export var _defaults = {

    styles: {
        "cta_small": {},
        "cta_medium": {},
        "cta_large": {},

    },



    "keyframes": {
        fadeIn: {
            "0%": {
                opacity: 0,
                transform: "translateY(20px)"
            },
            "100%": {
                opacity: 1,
                transform: "translateY(0)"
            }
        },
        fadeOut: {
            "0%": {
                opacity: 1,
            },
            "100%": {
                opacity: 0,
            }
        },
        slideInFromLeft: {
            "0%": {
                transform: "translateX(-100%)",
            },
            "100%": {
                transform: "translateX(0)",
            }
        },
        slideOutToLeft: {
            "0%": {
                transform: "translateX(0)",
            },
            "100%": {
                transform: "translateX(-100%)",
            }
        },
        slideInFromRight: {
            "0%": {
                transform: "translateX(100%)",
            },
            "100%": {
                transform: "translateX(0)",
            }
        },
        slideOutToRight: {
            "0%": {
                transform: "translateX(0)",
            },
            "100%": {
                transform: "translateX(100%)",
            }
        },
        slideInFromTop: {
            "0%": {
                transform: "translateY(-100%)",
            },
            "100%": {
                transform: "translateY(0)",
            }
        },
        slideOutToTop: {
            "0%": {
                transform: "translateY(0)",
            },
            "100%": {
                transform: "translateY(-100%)",
            }
        },
        slideInFromBottom: {
            "0%": {
                transform: "translateY(100%)",
            },
            "100%": {
                transform: "translateY(0)",
            }
        },
        slideOutToBottom: {
            "0%": {
                transform: "translateY(0)",
            },
            "100%": {
                transform: "translateY(100%)",
            }
        },
        scaleIn: {
            "0%": {
                transform: "scale(0)",
            },
            "100%": {
                transform: "scale(1)",
            }
        },
        scaleOut: {
            "0%": {
                transform: "scale(1)",
            },
            "100%": {
                transform: "scale(0)",
            }
        },
        rotateIn: {
            "0%": {
                transform: "rotate(-180deg)",
            },
            "100%": {
                transform: "rotate(0)",
            }
        },
        rotateOut: {
            "0%": {
                transform: "rotate(0)",
            },
            "100%": {
                transform: "rotate(180deg)",
            }
        },
        bounce: {
            "0%": {
                transform: "translateY(0)",
            },
            "50%": {
                transform: "translateY(-20px)",
            },
            "100%": {
                transform: "translateY(0)",
            }
        }
    },







    pageCSS: {
        "body": {
            "combo": {},
            "state": {},
            "keyframes": {},
            "keyframe_definitions": {},
            "color": "#333",
            "font-family": "CircularXXWeb",
            "position": "relative",
            "display": "flex",
            "align-items": "center",
            "justify-content": "flex-start",
            "flex-direction": "column",
            "z-index": 0,
        },

        "public_inactive": {
            "opacity": "0 !important",
            "pointer-events": "none",
            "display": "none !important",
        },

        "public_child": {
            "opacity": 0,
            "animation": "fadeIn 1s forwards"
        },
        "public_empty": {
            "width": "75px",
            "height": "75px",
            "border-top-style": "dashed",
            "border-top-width": "1px",
            "border-top-color": "hsla(0, 0.00%, 60.00%, 1.00)",
            "border-right-style": "dashed",
            "border-right-width": "1px",
            "border-right-color": "hsla(0, 0.00%, 60.00%, 1.00)",
            "border-bottom-style": "dashed",
            "border-bottom-width": "1px",
            "border-bottom-color": "hsla(0, 0.00%, 60.00%, 1.00)",
            "border-left-style": "dashed",
            "border-left-width": "1px",
            "border-left-color": "hsla(0, 0.00%, 60.00%, 1.00)",
            "box-shadow": "inset 0 0 0 3px hsla(0, 0.00%, 0.00%, 0.05)"
        },
        "public-editing-text": {
            "box-shadow": "0 0 0 1px hsla(316, 62.06%, 78.92%, 1.00)"
        },

        "nws_caption": {
            "combo": {
                "past": {
                    "combo": {},
                    "state": {},
                    "z-index": 1
                },
                "future": {
                    "color": "#f1f1f1ff",
                    "combo": {},
                    "state": {},
                    "z-index": 1,
                    "background-color": "#ffff2f00"
                },
                "present": {
                    "color": "#ffffffff",
                    "combo": {
                        "past": {
                            "color": "#222222ff",
                            "combo": {},
                            "state": {},
                            "z-index": 1,
                            "background-color": "#fffff2cf"
                        }
                    },
                    "state": {},
                    "z-index": 1,
                    "background-color": "#4334b2dc"
                },
                "nws_captions_container": {
                    "combo": {},
                    "state": {},
                    "z-index": 1
                }
            },
            "state": {},
            "display": "block",
            "outline": "none",
            "z-index": 1,
            "position": "relative",
            "keyframes": {},
            "align-items": "center",
            "padding-top": "3px",
            "padding-left": "3px",
            "padding-right": "3px",
            "padding-bottom": "3px",
            "justify-content": "center",
            "custom_transitions": [
                {
                    "transition-duration": "500ms",
                    "transition-property": "All",
                    "transition-timing-function": "ease-out"
                }
            ],
            "keyframe_definitions": {},
            "color": "#efe7e7ff"
        },

        "span": {
            combo: {
                highlight: {
                    combo: {},
                    state: {},
                    "z-index": 20,
                    "outline": "none",
                    "background-color": "yellow",
                    "color": "black",
                },
                link: {
                    combo: {},
                    state: {},
                    "background-color": "f1f1f1",
                    "text-decoration": "underline",
                },
                superscript: {
                    combo: {},
                    state: {},
                    //add superscript style
                    "vertical-align": "super",
                    "font-size": "smaller",
                    "text-decoration": "underline",
                },
                subscript: {
                    combo: {},
                    state: {},
                    "vertical-align": "sub",
                    "font-size": "smaller"
                },
                bold: {
                    combo: {},
                    state: {},
                    "font-weight": "bold",
                },
            },
            state: {},
            "z-index": 20,
            "outline": "none",
        }
    },
    //multi text


    set: function () {
        let newSet = {
            "id": generateSceneID(),
            "ancestor": null,
            "pages": [_defaults.blankPage()],
        }

        return (newSet)
    },





    generatePageName: function () {
        const adjectives1 = ["Epic", "Mystic", "Cosmic", "Retro", "Stellar", "Invisible", "Thunderous", "Ancient", "Daring", "Galactic", "Shadowy", "Radiant", "Sparkling", "Whimsical"];
        const adjectives2 = ["Tiny", "Mighty", "Sneaky", "Brave", "Quiet", "Loud", "Quick", "Clever", "Loyal", "Bold", "Fierce", "Gentle", "Jolly", "Zany"];
        const nouns = ["Wizard", "Robot", "Ninja", "Pirate", "Dragon", "Viking", "Alien", "Knight", "Sorcerer", "Hero", "Monster", "Guardian", "Explorer", "Ranger"];
        const verbs = ["Dancing", "Flying", "Running", "Coding", "Jumping", "Singing", "Zapping", "Questing", "Zooming", "Chasing", "Battling", "Creating", "Inventing", "Dreaming"];

        // Randomly select one word from each array
        const word1 = adjectives1[Math.floor(Math.random() * adjectives1.length)];
        const word2 = adjectives2[Math.floor(Math.random() * adjectives2.length)];
        const noun = nouns[Math.floor(Math.random() * nouns.length)];
        const verb = verbs[Math.floor(Math.random() * verbs.length)];

        // Combine the words to create a page name

        return (`${word1}-${word2}-${noun}-${verb}`)
        //return `${word1}${word2}${noun}${verb}`;

    },


    blankPage: function (cssID = null, role) {

        let pageName = _defaults.generatePageName()

        if (role == "template") {
            pageName = "Template_" + generateShortId()
        }

        let slug = pageName.toLowerCase().replace(/ /g, "-")
        //let bodyClass = _defaults.createClass("body_" + generateShortId())

        let newPage = {
            "id": generateSceneID(),
            "type": "page",
            "schema": null,
            "dynamic": _defaults.getDefaultDynamicSettings("div"),
            "role": role, //such as prompt or search page
            "permissions": {
                "delete": true,
                "edit": true,
                "addPages": null,  //it's a page, not a folder
                "enabled": true,
                "redirect": null,
            },
            "properties": {
                "name": pageName,
                "slug": slug,
                "description": "",
                "code": "",
                "meta": "",
                "js": "",
                "entry": "",
                "size": _defaults.getDefaultSizeSettings()
            },
            "content": [
                {
                    "type": "body",
                    "id": generateSceneID(),
                    "dynamic": _defaults.getDefaultDynamicSettings("div"),
                    //"classes": _defaults.body(cssID),
                    "classes": [{ "name": "body", "combo": [] }],
                    "settings": _defaults.getDefaultSettings(),
                    "content": []
                }
            ],
        }

        return (newPage)
    },



    getDefaultSizeSettings: function () {



        let settings = {
            responsive: true,
            sizes: this.getSizes(),
            active: {},
            currrent: {},
            size: {
                width: 800,
                height: 600,
                maxWidth: null,
                maxHeight: null,
            }
        }

        return settings

    },

    getSizes: function () {
        let sizes = {
            "auto_auto": {
                "label": "Responsive",
                "value": "responsive",
                "icon": "fit_page",
                "width": "auto",
                "height": "auto",
                "label2": "Breakpoints-based",
                "enabled": false,
                "classes": [],
                "comment": "Adjusts automatically to the available screen space based on breakpoints."
            },
            "860x540": {
                "label": "Custom Size",
                "value": "fixed",
                "icon": "keyboard",
                "width": 860,
                "height": 540,
                "label2": "Create new",
                "classes": [],
                "comment": "Create a new, custom size.",
                "enabled": false
            },
            "1920x1080": {
                "label": "Full HD (1080p)",
                "value": "full_hd",
                "icon": "slideshow",
                "width": 1920,
                "height": 1080,
                "label2": "1920x1080px",
                "classes": [],
                "comment": "Standard high-definition resolution commonly used for widescreen displays.",
                "enabled": false
            },
            "1280x720": {
                "label": "HD (720p)",
                "value": "hd_720",
                "icon": "hd",
                "width": 1280,
                "height": 720,
                "label2": "1280x720px",
                "enabled": false,
                "classes": [],
                "comment": "A popular high-definition size for smaller or more bandwidth-efficient videos."
            },
            "1080x1080": {
                "label": "Square (1:1)",
                "value": "square",
                "icon": "crop_square",
                "width": 1080,
                "height": 1080,
                "label2": "1080x1080px",
                "enabled": false,
                "classes": [],
                "comment": "Perfect for social media platforms like Instagram, where square videos are popular."
            },
            "1080x1920": {
                "label": "Vertical (9:16)",
                "value": "vertical_9_16",
                "icon": "smartphone",
                "width": 1080,
                "height": 1920,
                "label2": "1080x1920px",
                "enabled": false,
                "classes": [],
                "comment": "Ideal for mobile-oriented vertical video content, used on platforms like TikTok and Instagram Stories."
            },
            "1080x1350": {
                "label": "Instagram Post (4:5)",
                "value": "insta_post",
                "icon": "filter_none",
                "width": 1080,
                "height": 1350,
                "label2": "1080x1350px",
                "enabled": false,
                "classes": [],
                "comment": "Portrait-sized video or image, used mainly for Instagram Feed posts."
            },

            "300x250": {
                "label": "Medium Rectangle",
                "value": "medium_rectangle",
                "icon": "crop_landscape",
                "width": 300,
                "height": 250,
                "label2": "300x250px",
                "enabled": false,
                "classes": [],
                "comment": "A highly popular ad size, widely used for display ads in sidebars or between content."
            },
            "336x280": {
                "label": "Large Rectangle",
                "value": "large_rectangle",
                "icon": "aspect_ratio",
                "width": 336,
                "height": 280,
                "label2": "336x280px",
                "enabled": false,
                "classes": [],
                "comment": "A larger version of the Medium Rectangle, offering more space for visuals."
            },

            "970x250": {
                "label": "Large Leaderboard",
                "value": "large_leaderboard",
                "icon": "swap_vert",
                "width": 970,
                "height": 250,
                "label2": "970x250px",
                "enabled": false,
                "classes": [],
                "comment": "A wide, horizontal ad size, often used for header banners or above-the-fold content."
            },

            "300x600": {
                "label": "Wide Skyscraper",
                "value": "wide_skyscraper",
                "icon": "swap_vert",
                "width": 300,
                "height": 600,
                "label2": "300x600px",
                "enabled": false,
                "classes": [],
                "comment": "A wider skyscraper ad, used for more detailed or eye-catching display."
            },


        }

        return sizes;
    },





    agentsPage: function (cssID = null) {


        //let bodyClass = _defaults.createClass("body_" + generateShortId())


        let newPage = {
            "id": generateSceneID(),
            "type": "page",
            "schema": null,
            "dynamic": _defaults.getDefaultDynamicSettings("div"),
            //"role": "agents", //such as prompt or search page
            "permissions": {
                "delete": true,
                "edit": true,
                "addPages": null,  //it's a page, not a folder
                "enabled": true,
                "redirect": null,
            },
            "properties": {
                "name": "New Page " + render.getTimeStamp(),
                "slug": "new-page-" + render.getTimeStamp(),
                "description": "",
                "code": "",
                "meta": "",
                "js": "",
                "entry": "",
                "role": "agents"
            },
            "content": [
                {
                    "type": "body",
                    "id": generateSceneID(),
                    "dynamic": _defaults.getDefaultDynamicSettings("div"),
                    //"classes": _defaults.body(cssID),
                    "classes": [{ "name": "body", "combo": [] }], //consider adding dynamically
                    "settings": _defaults.getDefaultSettings(),
                    "content": []
                }
            ],
        }

        return (newPage)
    },

    page404: function (cssID = null) {

        let newPage = {
            "id": generateSceneID(),
            "type": "page",
            "schema": null,
            "dynamic": _defaults.getDefaultDynamicSettings("div"),
            //"role": "404", //such as prompt or search page
            "permissions": {
                "delete": true,
                "edit": true,
                "addPages": null,  //it's a page, not a folder
                "enabled": true,
                "redirect": null,
            },
            "properties": {
                "name": "New Page " + render.getTimeStamp(),
                "slug": "new-page-" + render.getTimeStamp(),
                "description": "",
                "code": "",
                "meta": "",
                "js": "",
                "entry": "",
                "role": "404"
            },
            "content": [
                {
                    "type": "body",
                    "id": generateSceneID(),
                    "dynamic": _defaults.getDefaultDynamicSettings("div"),
                    //"classes": _defaults.body(cssID),
                    "classes": [{ "name": "body", "combo": [] }],
                    "settings": _defaults.getDefaultSettings(),
                    "content": []
                }
            ],
        }

        return (newPage)
    },



    folder: function () {

        let pageName = _defaults.generatePageName()
        let slug = pageName.toLowerCase().replace(/ /g, "-")

        let newFolder = {
            "id": generateSceneID(),
            //"css": "self", //forces the css to saved separately
            "type": "folder",
            "symbols": {},
            dynamic: {},
            "permissions": {
                "delete": true,
                "edit": true,
                "addPages": true,
                "enabled": true,
                "redirect": null,
            },
            "properties": {
                "name": pageName,
                "slug": slug,
                "description": "",
                "code": "",
                "meta": ""
            },
            "content": [],
        }

        return (newFolder)
    },


    body: function (cssID) {


        return

        let css = {
            "combo": {},
            "state": {},
            "color": "#333",
            "background-color": "#ffffff",
            "font-family": "CircularXXWeb",
            "position": "relative",
            "display": "flex",
            "align-items": "center",
            "justify-content": "center",
            "z-index": 0,
        }


        let className


        let root = null
        if (cssID && typeof publicai != "undefined") {
            //when new pages are created, we need to check where body class should be created. If in root folder, we'll use the root folder id, not the top-level folder parent id

            let folder = _find.elementByID(publicai.pages, cssID)

            console.log("checking ", folder)

            if (folder && folder.properties?.role != "schemaroot") { //called via _schemas.createPagesForSchemas
                root = _find.getRoot(cssID)

                console.log("root is ", root)

                if (root == null || !root) {
                    console.log("Page is in root folder. Using ancestor css.")
                    cssID = publicai.active.page.ancestor
                    //cssID = publicai.pages.id
                }
            }
        }


        if (typeof publicai != "undefined") {
            className = _defaults.createClass("body")
            if (!cssID) {
                publicai.css[className] = { ...css }
            } else {
                nwsapp.currentProject.config.css[cssID][className] = { ...css } //save the css for the right folder
            }
        } else {
            //first time the document is opened, publicai doesn't exist. 
            className = "body"
            temporaryBodyCSS = { ...css }
            //save this later into the right css key
        }

        //applyCSS()
        return ([{ "name": className, "combo": [] }])

    },



    createClass: function (baseName) {
        // Assuming publicai.classes is an object with class names as keys
        let className = baseName;
        let counter = 1;

        // Check if the base class name exists
        while (publicai.css.hasOwnProperty(className)) {
            // Append a number, formatted with leading zeros if necessary
            className = `${baseName}_${String(counter).padStart(2, '0')}`;
            counter++;
        }

        // At this point, className is unique and can be returned
        return className;
    },



    elementPrompts: {


        quotes: "Create a list of quotes or remarkable snippets of information that support the current content. The first line in the array is a supporting line, the second is the big, bold quqote and the third line could be the source of the quote, credits or context.",
        bullets: "Create a summary of the current content in bullet points. The first line in the array is a supporting line or explanation, the second is the big, bold quuote. Third line could be a call to action if there is a source link. Fourth line could be a URL to the source, only if necessary.",
        dynamic_carousel: "Create a list media elements to support the current content.",

        table: "Create a JSON that features some facts and figueres to support the matter at hand. Add any data as long as the entries have consistency.",
        highlights: "Create a JSON that features key figueres to support the current content. Make the values a short numbers or key value like 100k, $1,235 or 25%, etc. The first and third line are supporting or explaining the content in the second line, which is the actual figure. Keep them very short",
        media: "Generate up to 5 Getty Images search queries in the searchQueries key using AND and OR operators, as well as a general description for the best media to use here. Do not generate actual media object, only the search queries for other platforms to use."

    },

    getPrompt: function (type) {

        let prompt = ""
        if (_defaults.elementPrompts[type]) {
            prompt = _defaults.elementPrompts[type]
        }

        return (prompt)

    },


    getDefaultInputSettings: function (type) {

        let settings = {
            "form": null, //the parent form id
            "prompt": _defaults.getPrompt(type),
            "label": "New Input <<---",
            "cta": "Upload files",
            "placeholder": "Enter your text",
            "extensions": null,
            "value": "",
            "units": "",
            "options": ["apples", "bananas", "oranges"],
            "key": "",
            "regexp": "",
            "comment": "comment",
            "help": "Explain the context",
            "min": "", //this is char count
            "max": "",
            "minSlider": 0, //these are slider values
            "maxSlider": 100,
            "placeholderAnimation": "none",
            "validation": {},
            "files": {
                "mode": "documents",
                "list": [],
                "extensions": []

            },
            "suggestions": {
                "mode": "user_activity",
                "list": []
            }

        }

        return (settings)

    },

    getDefaultSettings: function () {

        let settings = {
            "form": null,
            "permissions": {
                "delete": true,
            },
            "ai": {
                "name": "",
                "length": 256,
                "description": ""
            },

            "labels": {},

            "animation": null, //_defaults.getAnimationSettings(),
            "timing": _defaults.getDefaultTiming(),

            "onclick": {
                "type": "none",
                "propagation": "Propagate Events",
                "url": {},
                "form": {
                    "form": null,
                    "success": "",
                    "error": "",
                },
                "page": {
                    "page": null,
                    "window": "",
                    "settings": {}
                },
                "section": {},
                "js": {},
            },
            "component": {
                "enabled": false,
                "name": null,
                "settings": {}
            },
            "timeline": {
                "enabled": false,

            },
            "general": {
                "id": null,
                "label": null,
            },
            "attributes": [

            ],
            "player": {
                "speed": 1,
                "volume": 1,
                "enabled": false,
                "helpers": false,
                "autoplay": false,
                "controls": {
                    "enabled": true,
                    "settings": {}
                },

            }
        }
        return (settings)

    },

    getDefaultDivCSS: function () {

        let css = {
            "position": "relative",
            "display": "flex",
            "align-items": "center",
            "justify-content": "center",
            "z-index": 0,
        }
        return (css)
    },



    carousel: function (className, mode) {


        let templateID = "1f424e55-e082-11ee-9be9-4d3a05dbb1f0"
        let newCarousel = _nav.addTemplatedContent(templateID, true)
        applyCSS()
        return (newCarousel)

        //if className is given, use it, otherwise generate a new one

        let swiperTopContainer = {
            "id": generateSceneID(),
            "type": "carousel",
            "dynamic": _defaults.getDefaultDynamicSettings("div"),
            "settings": _defaults.getDefaultSettings(),
            "content": []
        }

        swiperTopContainer.settings.attributes.push({ "name": "swiper-element", "value": "swiper", "mode": "locked" })

        swiperTopContainer.settings.carousel = _defaults.carouselSettings()

        if (mode) { swiperTopContainer.mode = mode }


        let containerClassName = this.createClass("carousel")
        swiperTopContainer.classes = [{ "name": containerClassName, "combo": [] }]

        if (!publicai.css[containerClassName]) {
            publicai.css[containerClassName] = {
                "width": "100%",
                "height": "100%",
                "position": "relative",
                "display": "flex",
                "align-items": "center",
                "justify-content": "flex-start",
                "z-index": 0,
                "overflow": "hidden",
            }
        }



        let swiperElementsContainer = {
            "id": generateSceneID(),
            "type": "div",
            "dynamic": _defaults.getDefaultDynamicSettings("div"),
            "settings": _defaults.getDefaultSettings(),
            "content": []
        }

        swiperElementsContainer.settings.attributes.push({ "name": "swiper-element", "value": "wrapper", "mode": "hidden" })
        swiperElementsContainer.settings.permissions.delete = false


        let elementsClassName = this.createClass("elements_container")
        swiperElementsContainer.classes = [{ "name": elementsClassName, "combo": [] }]

        if (!publicai.css[elementsClassName]) {
            publicai.css[elementsClassName] = {
                "position": "relative",
                "height": "100%",
                "display": "flex",
                "align-items": "center",
                "justify-content": "center",
                "z-index": 0,
                "justify-content": "flex-start",
                "flex-direction": "row",
                "grid-column-gap": "0px",
                "grid-row-gap": "0px",

            }

        }


        let swiperElement = {
            "id": generateSceneID(),
            "type": "div",
            "dynamic": _defaults.getDefaultDynamicSettings("div"),
            "settings": _defaults.getDefaultSettings(),
            "content": []
        }


        let elementClassName = this.createClass("swiper_el")
        swiperElement.classes = [{ "name": elementClassName, "combo": [] }]

        if (!publicai.css[elementClassName]) {
            publicai.css[elementClassName] = {
                "width": "100%",
                "height": "100%",
                "min-width": "250px",
                "border-radius": "8px",
                "background-color": generateLightColor(),
                "position": "relative",
                "display": "flex",
                "align-items": "center",
                "justify-content": "center",
                "z-index": 0,
                "justify-content": "flex-start",
                "flex-direction": "row",
            }
        }




        //this is a template is
        let id = "a26ab020-b7a3-11ee-98e3-1475b037dca9"
        let utilsElement = _nav.addTemplatedContent(id, true)

        //adding 3 dummy elements

        let el1 = { ...swiperElement }
        el1.id = generateSceneID()
        swiperElementsContainer.content.push(el1)

        let el2 = { ...swiperElement }
        el2.id = generateSceneID()
        swiperElementsContainer.content.push(el2)

        let el3 = { ...swiperElement }
        el3.id = generateSceneID()
        swiperElementsContainer.content.push(el3)


        swiperTopContainer.content.push(utilsElement)

        swiperTopContainer.content.push(swiperElementsContainer)


        applyCSS()
        return (swiperTopContainer)
    },


    carouselSettings: function (contentItem) {
        let settings = {

            "onCompleteMode": "donothing",
            //"onCompleteContent": contentItem?.native?.onAction || "next",
            "helper": "Explore",

            "gradient": {
                "opacity": 20,
                "start": "rgba(0, 0, 0, 0.01)",
                "end": "rgba(0, 0, 0, 0.975)",
            },

            /*   "autoplay": {
                  delay: 1600,
              }, */

            "autoplay": true,

            "shuffle": false,
            "direction": 'horizontal',
            "draggable": true,
            "loop": false,
            "slidesPerView": 2,
            "speed": 380,
            "effect": "slide",
            "parallax": true,
            "centeredSlides": false,
            "centeredSlidesBounds": true,
            "spaceBetween": 10,
            "grabCursor": true,
            "simulateTouch": false,
            "cssMode": isChromeForIOs(),
            "cssMode": false,
            "loopFillGroupWithBlank": false,
            "runCallbacksOnInit": true,
        }
        return (settings)
    },


    div: function (className, mode) {

        //if className is given, use it, otherwise generate a new one

        let item = {
            "id": generateSceneID(),
            "type": "div",
            "dynamic": _defaults.getDefaultDynamicSettings("div"),
            "settings": _defaults.getDefaultSettings(),
            "content": [],
            "color": generateLightColor(),
        }

        if (mode) {
            item.mode = mode
        }


        if (!className) {
            className = this.createClass("div")
        }

        item.classes = [{ "name": className, "combo": [] }]

        if (!publicai.css[className]) {
            publicai.css[className] = {
                "combo": {},
                "state": {},
                "keyframes": {},
                "keyframe_definitions": {},
                "position": "relative",
                "display": "flex",
                "align-items": "center",
                "justify-content": "center",
                "z-index": 0,
                "box-sizing": "border-box",
                "outline": "none",
            }

            if (mode == "utility") {
                publicai.css[className]["display"] = "none"
            }
        }


        applyCSS()
        return (item)
    },

    span: function (settings) {

        //if className is given, use it, otherwise generate a new one



        let item = {
            "id": generateSceneID(),
            "type": "span",
            "dynamic": _defaults.getDefaultDynamicSettings("span"),
            "settings": _defaults.getDefaultSettings(),
            "content": []
        }



        //let className = this.createClass("span")

        if (!publicai.css.span) {
            publicai.css.span = { ...nwsapp.currentProject.config.root.definitions?.classes.span }
        }

        //console.log("publicai.css.span", publicai.css.span.combo.highlight)



        item.classes = [{ "name": "span", "combo": [] }]

        let classesToAdd = []
        if (settings.highlight) {
            classesToAdd.push("highlight")
            item.classes[0].combo.push("highlight")
        }

        if (settings.superscript) {
            classesToAdd.push("superscript")
            item.classes[0].combo.push("superscript")
        }

        if (settings.subscript) {
            classesToAdd.push("subscript")
            item.classes[0].combo.push("subscript")
        }

        if (settings.bold) {
            classesToAdd.push("bold")
            item.classes[0].combo.push("bold")
        }

        if (settings.link) {
            classesToAdd.push("link")
            item.classes[0].combo.push("link")
            item.settings.onclick.type = "url"
            item.settings.onclick.url = "https://www.google.com"
            item.settings.onclick.window = "_blank"
        }



        applyCSS()
        return (item)
    },

    //"5fd19111-3ab9-11ef-bd9b-1076139c4012"

    documentZoom: function () {

        let zoom = {
            "1920x1080": {
                value: 50
            },
            "1080x1920": {
                value: 50
            },
            "1080x1080": {
                value: 100
            },
            "1280x720": {
                value: 100
            },
            "1080x1920": {
                value: 100
            },
            "1080x1350": {
                value: 100
            },
            "1080x1350": {
                value: 100
            },
            "728x90": {
                value: 100
            },
            "300x250": {
                value: 100
            },
            "336x280": {
                value: 100
            },
            "160x600": {
                value: 100
            },
            "300x600": {
                value: 100
            },
            "320x50": {
                value: 100
            },
            "responsive": {
                value: 100
            },

        }

        return zoom

    },


    side_controls: function () {

        let templateID = "89ea33ce-7b2e-11ef-a701-cba99a2e0069"
        let newElement = _nav.addTemplatedContent(templateID, true)
        applyCSS()

        return (newElement)

    },

    scrolling_video: function () {

        let templateID = "b897b817-8268-11ef-97ad-3c1a31eef39b"


        let newElement = _nav.addTemplatedContent(templateID, true)
        applyCSS()

        return (newElement)

    },

    pages_player: function () {

        let templateID = "255943ce-7ccd-11ef-9919-7b9de3f4b856"


        let newElement = _nav.addTemplatedContent(templateID, true)
        applyCSS()

        return (newElement)

    },

    player_progress: function () {

        let templateID = "194773a5-7b2e-11ef-9e9b-262fc56c6160"


        let newElement = _nav.addTemplatedContent(templateID, true)
        applyCSS()

        return (newElement)

    },


    audio: function () {

        let templateID = "a419c79d-3ab9-11ef-bbc9-97e5be475574" //old 
        //let templateID = "ff1f541e-75ec-11ef-a805-2b4e21fab3bb"
        //let templateID = "a160fcf2-719c-11ef-a305-8dc12058eb12"

        let newElement = _nav.addTemplatedContent(templateID, true)
        applyCSS()

        //hack. Re-save the component to fix. 
        delete newElement.media.content
        newElement.media.content = {
            placeholder_audio: {
                "id": "placeholder_audio",
                "modified": "2 September 2024 at 05:03:26 pm"
            }
        }

        newElement.settings.timing.duration = 60000

        return (newElement)

    },

    media: function () {

        let templateID = "392b41f0-ba17-11ee-87c0-ad49561c70b6"
        let newElement = _nav.addTemplatedContent(templateID, true)
        applyCSS()
        return (newElement)

    },

    video: function () {

        let templateID = "392b41f0-ba17-11ee-87c0-ad49561c70b6"
        let newElement = _nav.addTemplatedContent(templateID, true)
        applyCSS()
        return (newElement)

    },

    video_player: function () {

        let templateID = "41669c7b-fd6b-11ee-913b-1d9c698ed490"
        let newElement = _nav.addTemplatedContent(templateID, true)
        applyCSS()
        return (newElement)

    },




    material_icon: function () {

        let templateID = "453fe23c-ba33-11ee-aab8-33790f41e390"
        let newElement = _nav.addTemplatedContent(templateID, true)
        applyCSS()
        return (newElement)

    },

    svg_icon: function () {

        let templateID = "f3a6e5fe-ba33-11ee-a25a-78f8ccd63ee3"
        let newElement = _nav.addTemplatedContent(templateID, true)
        applyCSS()
        return (newElement)

    },






    media_basic: function (mode) {

        //console.log("media basic...")


        let item = {
            "id": generateSceneID(),
            "type": "media",
            "dynamic": _defaults.getDefaultDynamicSettings("media"),
            "settings": _defaults.getDefaultSettings(),
            "media": {
                "mode": mode,
                "content": {},
                "settings": {}
            },
            "content": []
        }


        item.media.content.placeholder = {
            "modified": getPrettyTimeStamp(),
            "id": "placeholder"
        }




        let className = this.createClass("media")
        //public.classes 
        publicai.css[className] = {
            //"width": "300px",
            //"height": "300px",
            "combo": {},
            "state": {},
            "keyframes": {},
            "keyframe_definitions": {},
            "position": "relative",
            "display": "flex",
            "align-items": "center",
            "justify-content": "center",
            "z-index": 0,
        }

        item.classes = [{ "name": className, "combo": [] }]

        applyCSS()
        return (item)

    },

    getDefaultDynamicSettings: function (type) {

        let settings = {
            "enabled": false,
            "schema": null,
            "element": null,
            "entry": null,
        }

        return (settings)

    },


    text: function () {
        let item = {
            "id": generateSceneID(),
            "type": "text",
            "content": [],
            "dynamic": _defaults.getDefaultDynamicSettings("text"),
            "settings": _defaults.getDefaultSettings(),
            "text": {
                "text": "this is the text",
                "id": "",
                "settings": {
                    "visible": true,
                    "locked": false,
                    "opacity": 100,
                }
            },
        }


        let className = this.createClass("text")
        //public.classes 
        publicai.css[className] = {
            "combo": {},
            "state": {},
            "keyframes": {},
            "keyframe_definitions": {},
            "position": "relative",
            "display": "block",
            "align-items": "center",
            "justify-content": "center",
            "z-index": 0,
            "outline": "none",
        }

        item.classes = [{ "name": className, "combo": [] }]

        applyCSS()
        return (item)
    },


    system_message: function (rootFolderID) {

        let templateID = "182b0de6-cb27-11ee-aaf4-683e6a7af443"
        let newElement = _nav.addTemplatedContent(templateID, true, rootFolderID)
        applyCSS()
        return (newElement)
    },

    addSpecificTemplate: function (rootFolderID, template) {
        let newElement = _nav.addTemplatedContent(null, true, rootFolderID, template)
        //applyCSS()
        return (newElement)
    },


    user_message: function (rootFolderID) {
        let templateID = "7d240294-cc03-11ee-b4f1-5781ce403b7b"
        let newElement = _nav.addTemplatedContent(templateID, true, rootFolderID)
        applyCSS()
        return (newElement)
    },


    client_editor: function (rootFolderID) {
        let templateID = "0c422863-d8cc-11ee-89fe-e7a49bcc32ae"
        let newElement = _nav.addTemplatedContent(templateID, true, rootFolderID)
        applyCSS()
        return (newElement)
    },


    newFolder: function () {
        let tag = {
            name: generateShortId(8),
            id: null,
            publicId: null,
            folderName: "Folder_" + generateShortId(3),
            ancestor: null,
            project: nwsapp.currentProject.id,
            role: "folder",
            dynamic: {},
            agents: {
                share: true,
                whitelist: [],
            },
            description: {
                modified: getPrettyTimeStamp(),
                entries: {
                    general: "Content description",
                }
            },
        }
        return (tag)
    },




    promptBlock: function (rootFolderID) {
        // only used via _agents.addPrompt for now. Consider adding in the menu
        // root folder ID allows the css to go into a specific location rather than publicai.css

        let templateID = "f0d1a902-ca64-11ee-bc98-313d82905cff"
        let newElement = _nav.addTemplatedContent(templateID, true, rootFolderID)
        applyCSS()
        return (newElement)
    },

    promptBlockChat: function (rootFolderID) {


        let templateID = "60a0f30b-c5da-11ee-ba30-1c9b70d5ccee"
        let newElement = _nav.addTemplatedContent(templateID, true, rootFolderID)
        applyCSS()
        return (newElement)
    },



    upload: function (rootFolderID) {

        let templateID = "4a0b240c-be97-11ee-b5c3-63756b5d93e3"
        let newElement = _nav.addTemplatedContent(templateID, true, rootFolderID)
        applyCSS()
        return (newElement)

    },


    qr_code: function (rootFolderID) {
        let templateID = "885d1113-69f8-11ef-81e6-945d304ed085"
        let newElement = _nav.addTemplatedContent(templateID, true, rootFolderID)

        console.log("newElement", newElement)
        applyCSS()
        return (newElement)
    },

    quotes: function (rootFolderID) {

        let templateID = "17e207a5-e11b-11ee-9229-3e06c63c4b9d"
        let newElement = _nav.addTemplatedContent(templateID, true, rootFolderID)
        newElement.data = _utils.dummy_quotesData()
        applyCSS()
        return (newElement)
    },


    dynamic_carousel: function (rootFolderID) {

        let templateID = "45fc3072-e11b-11ee-a905-63d43e532e3f"
        let newElement = _nav.addTemplatedContent(templateID, true, rootFolderID)
        newElement.data = _utils.dummy_dynamic_carouselData()
        applyCSS()
        return (newElement)
    },

    bullets: function (rootFolderID) {

        let templateID = "3e27518e-e095-11ee-b7cd-5c8f205e4058"
        let newElement = _nav.addTemplatedContent(templateID, true, rootFolderID)
        newElement.data = _utils.dummy_bulletsdData()
        applyCSS()
        return (newElement)

    },

    table: function (rootFolderID) {

        let templateID = "5fa23aed-dc63-11ee-b8fe-5c3ed14f199b"
        let newElement = _nav.addTemplatedContent(templateID, true, rootFolderID)
        newElement.data = _utils.dummy_tabledData()
        applyCSS()
        return (newElement)

    },

    highlights: function (rootFolderID) {
        let templateID = "d8fe5303-de10-11ee-83a1-a60030be78ab"
        let newElement = _nav.addTemplatedContent(templateID, true, rootFolderID)
        newElement.data = _utils.dummy_highlightsData()
        applyCSS()
        return (newElement)
    },



    input: function (rootFolderID) {
        //let templateID = "3484b192-bba1-11ee-b28b-3cd658a41d22" //broken
        let templateID = "be637946-cb58-11ee-a571-c743d305209d"

        let newElement = _nav.addTemplatedContent(templateID, true, rootFolderID)
        applyCSS()
        return (newElement)
    },

    input_basic: function () {
        //this the primitive used to build the input component above
        let item = {
            "id": generateSceneID(),
            "type": "input_basic",
            "content": [],
            "dynamic": _defaults.getDefaultDynamicSettings("input"),
            "settings": _defaults.getDefaultSettings(),
            "text": {
                "text": "this is the text",
                "id": "",
                "settings": {
                    "visible": true,
                    "locked": false,
                    "opacity": 100,
                }
            },
        }

        item.settings.form = _defaults.getDefaultInputSettings()


        let className = this.createClass("input")
        //public.classes 
        publicai.css[className] = {
            "combo": {},
            "state": {},
            "keyframes": {},
            "keyframe_definitions": {},
            "color": "#333", //consider adding an contrasting color for the background
            "position": "relative",
            "display": "flex",
            "align-items": "center",
            "justify-content": "center",
            "box-sizing": "border-box",
            "z-index": 0,
            "outline": "none",
        }



        item.classes = [{ "name": className, "combo": [] }]

        applyCSS()
        return (item)
    },


    slider: function (rootFolderID) {

        let templateID = "4d4f2e7d-bba1-11ee-bb0f-25034d6c4094"
        let newElement = _nav.addTemplatedContent(templateID, true, rootFolderID)
        applyCSS()
        return (newElement)

    },

    multi: function (rootFolderID) {
        let templateID = "08ad852d-bba3-11ee-a6d8-ad47f6ab00ad"
        let newElement = _nav.addTemplatedContent(templateID, true, rootFolderID)
        applyCSS()
        return (newElement)
    },

    dropdown: function (rootFolderID) {
        let templateID = "31e3a739-ba11-11ee-94f7-ef2f94e8aebd"
        let newElement = _nav.addTemplatedContent(templateID, true, rootFolderID)
        applyCSS()
        return (newElement)

    },



    checkbox: function (rootFolderID) {

        let templateID = "1801d773-bba3-11ee-9043-75ba90d27239"
        let newElement = _nav.addTemplatedContent(templateID, true, rootFolderID)
        applyCSS()
        return (newElement)

    },

    radio: function (rootFolderID) {

        let templateID = "2232c415-bba3-11ee-99fa-d699bc815e9d"
        let newElement = _nav.addTemplatedContent(templateID, true, rootFolderID)
        applyCSS()
        return (newElement)

    },

    message: function (rootFolderID) {

        //console.log(rootFolderID)

        let templateID = "151b3d69-c9f2-11ee-b44a-2c61923665c0"
        let newElement = _nav.addTemplatedContent(templateID, true, rootFolderID)
        applyCSS()
        return (newElement)

    },

    textarea: function (rootFolderID) {

        //console.log(rootFolderID)

        let templateID = "fb451bf1-cca7-11ee-a6f4-72419d092a4c"
        let newElement = _nav.addTemplatedContent(templateID, true, rootFolderID)
        applyCSS()
        return (newElement)

    },


    textarea_basic: function () {
        //this the primitive used to build the input component above
        let item = {
            "id": generateSceneID(),
            "type": "textarea_basic",
            "content": [],
            "dynamic": _defaults.getDefaultDynamicSettings("textarea"),
            "settings": _defaults.getDefaultSettings(),
            "text": {
                "text": "this is the text",
                "id": "",
                "settings": {
                    "visible": true,
                    "locked": false,
                    "opacity": 100,
                }
            },
        }

        item.settings.form = _defaults.getDefaultInputSettings()

        let className = this.createClass("textarea")
        //public.classes 
        publicai.css[className] = {
            "combo": {},
            "state": {},
            "keyframes": {},
            "keyframe_definitions": {},
            "position": "relative",
            "display": "flex",
            "align-items": "center",
            "justify-content": "center",
            "z-index": 0,
            "outline": "none",
        }

        item.classes = [{ "name": className, "combo": [] }]

        applyCSS()
        return (item)
    },


    checkIfCurrentElementIsBody: function () {

        if (typeof publicai == "undefined") {
            //console.error("publicai is undefined. Returning zero.")
            return 10000
        }

        if (publicai?.selected?.settings.type == "body") {
            return 4000
        } else {
            return 0
        }

    },

    getDefaultTiming: function () {

        let settings = {
            "start": 0,
            //"end": _defaults.checkIfCurrentElementIsBody(),
            "end": 30000,
            "duration": 30000,
            "maxTime": null,
            "min_duration": 200,
        }

        return (settings)
    },


    getAnimationSettings: function () {

        let settings = {
            "timing": {
                "start": 0,
                "end": 4000,
                "min_duration": 200,
            },

            "keyframes": {},

        }

        return (settings)
    },









    getGlobalAnimatorDefaults: function () {

        //top, at variation content level
        let settings = {
            enabled: false,
            settings: {
                style: { ..._defaults.defaultStyles }
            },
            timeline: {},
        }

        return (settings)

    },

    getDefaultDeepSettings: function () {

        //deep at size and mode level
        let theDefault = {}
        return (theDefault)

    },






    getMediaPosition: function () {

        let positions = {
            "300x600": {
                "left": 0,
                "top": 0
            },
            "970x250": {
                "left": 0,
                "top": 0
            },
            "320x480": {
                "left": 0,
                "top": 0
            },
        }

        return (positions[publicai.currentSize])

    },
    getMediaSize: function () {

        let sizes = {
            "300x600": {
                "w": 100,
                "h": 100
            },
            "970x250": {
                "w": 50,
                "h": 100
            },
            "320x480": {
                "w": 100,
                "h": 100
            },
        }

        return (sizes[publicai.currentSize])

    },

    getTextPosition: function () {

        let positions = {
            "300x600": {
                "left": 0,
                "top": 50
            },
            "970x250": {
                "left": 50,
                "top": 0
            },
            "320x480": {
                "left": 0,
                "top": 0
            },
        }

        return (positions[publicai.currentSize])

    },
    getTextSize: function () {

        let sizes = {
            "300x600": {
                "w": 100,
                "h": "auto"
            },
            "970x250": {
                "w": 50,
                "h": "auto"
            },
            "320x480": {
                "w": 100,
                "h": "auto"
            },
        }

        return (sizes[publicai.currentSize])

    },




    defaultStyles: {
        "font-family": "",
        "font-size": 1,
        "line-height": 1.2,
        "opacity": "100%",
        "letter-spacing": 0,
        /*   "color": "#000000",
          "background-color": "#ffffff",
          "border-color": "#000000",
          "text-align": "left",
          "text-transform": "none",
          "font-weight": "",
          "font-style": "normal",
          "text-decoration": "",
          "padding-left": "20px",
          "padding-right": "20px",
          "padding-top": "11px",
          "padding-bottom": "11px", */
    },

    "definitions": {
        "fadeIn": {
            "keyframes": [
                { "opacity": 0, "offset": 0 },
                { "opacity": 1, "offset": 1 }
            ]
        },
        "fadeOut": {
            "keyframes": [
                { "opacity": 1, "offset": 0 },
                { "opacity": 0, "offset": 1 }
            ]
        },
        "bounce": {
            "keyframes": [
                { "transform": "scale(1)", "offset": 0 },
                { "transform": "scale(1.2)", "offset": 0.3 },
                { "transform": "scale(0.9)", "offset": 0.5 },
                { "transform": "scale(1.1)", "offset": 0.7 },
                { "transform": "scale(1)", "offset": 1 }
            ]
        },
        "slideInRight": {
            "keyframes": [
                { "transform": "translateX(100%)", "offset": 0 },
                { "transform": "translateX(0)", "offset": 1 }
            ]
        },
        "slideInLeft": {
            "keyframes": [
                { "transform": "translateX(-100%)", "offset": 0 },
                { "transform": "translateX(0)", "offset": 1 }
            ]
        },
        "slideInBottom": {
            "keyframes": [
                { "transform": "translateY(100%)", "offset": 0 },
                { "transform": "translateY(0)", "offset": 1 }
            ]
        },
        "slideInTop": {
            "keyframes": [
                { "transform": "translateY(-100%)", "offset": 0 },
                { "transform": "translateY(0)", "offset": 1 }
            ]
        }
    },




    // Function to get a random movie entry
    getMovieLines: function () {
        const randomIndex = Math.floor(Math.random() * this.movieEntries.length);
        return this.movieEntries[randomIndex];
    },

    getRandomMedia: function () {
        const randomIndex = Math.floor(Math.random() * this.randomMedia.length);
        return this.randomMedia[randomIndex];
    },

    randomMedia: [
        {
            "source": {
                "original": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288825410/sidemedia5.jpg/w_768/",
                "small": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288825410/sidemedia5.jpg/w_768/",
                "medium": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288825410/sidemedia5.jpg/w_768/",
                "large": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288825410/sidemedia5.jpg/w_768/"
            },
            "description": "Uploaded file",
            "short_description": "Uploaded file",
            "type": "IMAGE",
            "licensed": true,
            "id": "TWVkaWFOb2RlOjIyMjU1ODQ=",
            "publicId": "d4e85ae7-887b-11ee-867c-6f03d27ef1d1",
            "contentSource": "library",
            "mediaType": "IMAGE"
        },
        {
            "source": {
                "original": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288825410/sidemedia2.jpg/w_768/",
                "small": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288825410/sidemedia2.jpg/w_768/",
                "medium": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288825410/sidemedia2.jpg/w_768/",
                "large": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288825410/sidemedia2.jpg/w_768/"
            },
            "description": "Uploaded file",
            "short_description": "Uploaded file",
            "type": "IMAGE",
            "licensed": true,
            "id": "TWVkaWFOb2RlOjIyMjU1ODU=",
            "publicId": "d4f4fa21-887b-11ee-aaa4-d684727c3c76",
            "contentSource": "library",
            "mediaType": "IMAGE"
        },
        {
            "source": {
                "original": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288825410/sidemedia4.jpg/w_768/",
                "small": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288825410/sidemedia4.jpg/w_768/",
                "medium": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288825410/sidemedia4.jpg/w_768/",
                "large": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288825410/sidemedia4.jpg/w_768/"
            },
            "description": "Uploaded file",
            "short_description": "Uploaded file",
            "type": "IMAGE",
            "licensed": true,
            "id": "TWVkaWFOb2RlOjIyMjU1ODY=",
            "publicId": "d4f83785-887b-11ee-b15d-51f2ce549cf6",
            "contentSource": "library",
            "mediaType": "IMAGE"
        },
        {
            "source": {
                "original": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288825410/sidemedia3.jpg/w_768/",
                "small": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288825410/sidemedia3.jpg/w_768/",
                "medium": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288825410/sidemedia3.jpg/w_768/",
                "large": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288825410/sidemedia3.jpg/w_768/"
            },
            "description": "Uploaded file",
            "short_description": "Uploaded file",
            "type": "IMAGE",
            "licensed": true,
            "id": "TWVkaWFOb2RlOjIyMjU1ODc=",
            "publicId": "d4fc921b-887b-11ee-915c-9ca08b36cdbd",
            "contentSource": "library",
            "mediaType": "IMAGE"
        },
        {
            "source": {
                "original": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288825410/sidemedia1.jpg/w_768/",
                "small": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288825410/sidemedia1.jpg/w_768/",
                "medium": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288825410/sidemedia1.jpg/w_768/",
                "large": "https://transform.nws.ai/https%3A//cdn.thenewsroom.io/platform/story_media/1288825410/sidemedia1.jpg/w_768/"
            },
            "description": "Uploaded file",
            "short_description": "Uploaded file",
            "type": "IMAGE",
            "licensed": true,
            "id": "TWVkaWFOb2RlOjIyMjU1ODg=",
            "publicId": "d526082a-887b-11ee-bf08-435a8798213c",
            "contentSource": "library",
            "mediaType": "IMAGE"
        }
    ]

}