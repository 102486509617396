import { css_input } from './css_input.js';
export var css_size = {


    init: function () {
        //css_classes.add()

        if (!this.ui) {
            this.ui = {
                "panel": $$$(document, "data-group", "size"),

                "overflow": $$$(document, "layout-combo", "overflow"),
                "overflow_x": $$$(document, "layout-combo", "overflow-x"),


                "allDataInputGroups": $$$($$$(document, "data-group", "size"), "data-input-group"),

                "scrollbars": $$$(document, "data-dropdown", "custom_scrollbars"),
                "size_fit_dropdown": $$$(document, "data-dropdown", "object-fit"),
                "size_ratio_dropdown": $$$(document, "data-dropdown", "aspect-ratio"),

            }
        }


        this.manageInputs()
        this.fitAndRatio()
        this.overflow()

    },




    manageInputs: function () {

        let templatedValues = ["100%", "50%", "10px", "20px", "5px"]



        for (let item of css_size.ui.allDataInputGroups) {
            let rightClickValues = []

            for (let i of templatedValues) {
                rightClickValues.push(
                    {
                        "label": i,
                        "value": i,
                    }
                )
            }

            //this is using a dedicated input handler that's simplified and doesn't include the colors, etc. 
            //consider adding to more parts

            

            css_input.input({
                "selector": item,
                "variable": item.getAttribute("data-input-group"),
                "validation": "none",
                "number":  true, 
                "rightClick": rightClickValues, 
                "doubleClick": true,
            })
        }



    },
    fitAndRatio: function () {

        let size_fit_values = [
            {
                label: "Fill",
                value: "fill",
                comment: "Fill the entire container, stretching the image if needed."
            },
            {
                label: "Contain",
                value: "contain",
                comment: "Contain the image inside the container, preserving its aspect ratio."
            },
            {
                label: "Cover",
                value: "cover",
                comment: "Cover the entire container, cropping the image if needed."
            },
            {
                label: "None",
                value: "none",
                comment: "Display the image at its original size."
            },
            {
                label: "Scale down",
                value: "scale-down",
                comment: "Display the image at its original size, but scaled down to fit inside the container if needed."
            },
        ]

        let aspect_ratio_values = [
            {
                label: "1:1",
                value: "1 / 1",
                comment: "Display the image at its original size, but scaled down to fit inside the container if needed."
            },
            {
                label: "4:3",
                value: "4 / 3",
                comment: "Display the image at its original size, but scaled down to fit inside the container if needed."
            },
            {
                label: "16:9",
                value: "16 / 9",
                comment: "Display the image at its original size, but scaled down to fit inside the container if needed."
            },
            {
                label: "9:16",
                value: "9 / 16",
                comment: "Display the image at its original size, but scaled down to fit inside the container if needed."
            },
            {
                label: "4:1",
                value: "4 / 1",
                comment: "Display the image at its original size, but scaled down to fit inside the container if needed."
            },
        ]

        let scrollbars = [
            {
                label: "System Default",
                value: "system",
                comment: "Use the system default scrollbar style."
            },
            {
                label: "Dark",
                value: "dark",
                comment: "A dark, thin scrollbar style."
            },
            {
                label: "Light",
                value: "light",
                comment: "Light scrollbar style."
            },

        ]


        css_utils.dropdown({
            "selector": css_size.ui.scrollbars,
            "values": scrollbars,
            "label": "Scrollbar",
            "style": null,
            "callback": null,
            "direction": "down",
        })


        css_utils.dropdown({
            "selector": css_size.ui.size_fit_dropdown,
            "values": size_fit_values,
            "label": "Fit",
            "style": null,
            "callback": null,
            "direction": "down",
        })





        css_utils.dropdown({
            "selector": css_size.ui.size_ratio_dropdown,
            "values": aspect_ratio_values,
            "label": "Ratio",
            "style": null,
            "callback": null,
            "direction": "down",
        })

    },

    overflow: function () {

        css_utils.combo({
            "selector": css_size.ui.overflow,
            "save": true,
        })

        css_utils.combo({
            "selector": css_size.ui.overflow_x,
            "save": true,
        })


    },



}

