export class FileManager {
    constructor() {}

    async listFiles({
        type,
        extensions,
        dateFrom,
        dateTo,
        minSize,
        maxSize,
        page = 1,
        limit = 50,
        lastEvaluatedKey = null,
        keyword = null,
        projectId = '_global', // Default to '_global' if projectId is not defined
        shared = false // Default to false if not provided
    } = {}) {
        try {
            const response = await fetch(`/api/files/list`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    type,
                    extensions,
                    dateFrom,
                    dateTo,
                    minSize,
                    maxSize,
                    page,
                    limit,
                    lastEvaluatedKey,
                    keyword,
                    projectId,
                    shared // Include shared flag in the request body
                })
            });

            if (response.status === 401) {
                window.router.navigateTo("/login");
                return;
            }

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            if (response.status === 408) {
                throw new Error('Request timeout - try reducing the page size or applying more specific filters');
            }

            return await response.json();
        } catch (error) {
            console.error('Error listing files:', error);
            throw error;
        }
    }

    async *listAllFiles(options = {}) {
        let lastEvaluatedKey = null;
        do {
            const response = await this.listFiles({
                ...options,
                lastEvaluatedKey
            });

            yield response.files;

            lastEvaluatedKey = response.lastEvaluatedKey;
        } while (lastEvaluatedKey);
    }
}

// Usage examples:
/*
const fileManager = new FileManager();

// Get all files containing a keyword within a specific project
const filesForProject = await fileManager.listFiles({
    projectId: '123', // Specify the project ID
    keyword: 'project',
    dateFrom: '2024-01-01',
    dateTo: '2024-12-31',
    shared: true // Set shared flag
});

// Get global video files with specific extensions and a keyword if no project ID is provided
const globalVideoFiles = await fileManager.listFiles({
    type: 'video',
    extensions: ['mp4', 'mov'],
    minSize: 5 * 1024
*/