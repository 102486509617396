

import { init as workspaces } from './settings/workspaces.js';
import { init as plan } from './settings/plan.js';
import { init as profile } from './settings/profile.js';
import { init as publishing } from './settings/publishing.js';

import { init as team } from './settings/team.js';




export async function init(path) {

    console.log("settings", path)

    let segments = path.split("/")

    utils.addWhatWhere(nwsapp.comp.settings, app.ui.main.el, "settings", true)
    let callbacks = { profile, publishing, plan }


    let tabs = new utils.Tabs({
        identifiers: {
            tab: "tab",
            panel: "panel"
        },
        container: app.ui.settings.el,
        default: segments[2],
        callbacks: callbacks,
        root: "settings"
    })



}







