
import getDefaultProject from '@editor/utils/utils.js';
import { applyCSSServer } from '../modules/css/server_css.js';  // Import the session extraction logic

import * as utils from '../modules/utils/utils.js';
import { ColorPicker } from '@editor/utils/colorpicker.js';  // Import the session extraction logic
import { media_cache } from '@editor/utils/media_cache.js';


import * as editor from '@editor/editor.js';
import * as play_interactive from '@editor/play_interactive.js';
import * as interactive_controls from '@editor/data/interactive_controls.js';
import * as css_management from '@editor/css_management.js';
import { _create } from '@editor/_create.js';

import { _size } from '@editor/_size.js';
import { _nav } from '@editor/_nav.js';
import { _pages } from '@editor/_pages.js';
import { _utils } from '@editor/_utils.js';

import { _find } from '@editor/_find.js';
import { handle } from '@editor/handle.js';
import { _defaults } from '@editor/defaults/defaults.js';
import { applyCSS } from '../modules/css/client_css.js';


import { css_utils } from '@editor/css/css_utils.js';

import { Menu } from '../modules/utils/menu.js'
import { _shortcuts } from '../modules/utils/shortcuts.js'



//import { loadComponents, loadStylesheets } from '../index.js'


// editor.js


export async function init(path) {

    if (!app.ready) {

        /* 
            let loader = new utils.Loader({
                time: 2200
            }) */

        await utils.loadComponents("editor");
        await utils.loadStylesheets();

        window.handle = handle
        window.editor = editor
        window.media_cache = media_cache
        window.interactive_controls = interactive_controls
        window.publicai = editor.publicai
        window.play_interactive = play_interactive
        window.applyCSS = applyCSS
        window._create = _create
        window._size = _size
        window._nav = _nav
        window._pages = _pages
        window.css_utils = css_utils
        window._utils = _utils
        window.Menu = Menu
        window._find = _find
        window.css_management = css_management
        window._defaults = _defaults
        window.saveProject = saveProject
        window._shortcuts = _shortcuts

        window.currentMediaReference = {}


        if (!nwsapp.editor) {
            nwsapp.editor = await initialiseEditor()
        }

        window.openEditorPath = openEditorPath
        window.ColorPicker = ColorPicker


        ///system.toggleSidebar(false)

        setTimeout(() => {
            if (!nwsapp.pageRendered) {
                editor.renderPage(true)
                nwsapp.pageRendered = true
            }
        }, 1000)

        app.ready = true

    }

    await evalPath(path);

    //loader.finish()


}

// Rest of your code remains exactly the same...


// Rest of your code remains the same...
async function initialiseEditor() {


    addRootComponents()
    document.body.style.height = "100%";
    let el = $$$$(document, "role")
    utils.removeAllChildNodes(el.main)
    let component = utils.addWhatWhere(components.ui.animator_edit, el.main)
    component.style.width = "100%"

    //removeAllChildNodes(document.body);
    //document.body.appendChild(component.cloneNode(true));

    return component

}


async function addRootComponents() {

    //let animator_edit = document.querySelector(".animator_edit")
    if (!components.system_wide) {
        components.system_wide = {}
        let comp = components.system["aa8137d1-9056-11ef-951c-b72cab32893a"]
        components.system_wide = { ..._utils.extractSystemComponents(comp.html, "public_component") }
        //await _utils.extractSystemComponents(comp.html, "public_component")

        //console.log("have extracted system wide", components.system_wide)

        let myCss = applyCSSServer(comp.css)
        _utils.addStyleshetToPage(myCss)

        return
    }




}



export async function evalPath(path) {

    // Split the path into segments
    const segments = path.split('/').filter(Boolean);  // Filter out empty segments
    const mainRoute = segments[0];  // This should be 'editor'
    const projectId = segments[1];  // Project ID (required)
    const subRoute = segments.slice(2);  // Remaining segments for specific routes (e.g., pages, settings, etc.)

    //console.log(`Project ID: ${projectId}, Sub Route: ${subRoute.join('/')}`);
    // Load the project (or create it if necessary)
    if (projectId) {

        if (!nwsapp.currentProject || nwsapp.currentProject.id != projectId) {
            await loadProject(projectId);

        } else {
            //already loaded
            //console.log("Project already loaded");
        }

    } else {
        console.log("No project ID found, creating a new project...");
        await createNewProject();
    }

    // Set global variables after loading the project
    //delete _pages.selected;
    //nwsapp.currentParameter = nwsapp.currentProject.id;
    //nwsapp.currentSection = "handle";

    // Set up the public system with the project configuration
    if (!window["public_system"]) {
        window["public_system"] = {
            library: nwsapp.currentProject.config.library,
            fonts: {
                loaded: new Set(),
            },
        };
    }

    // Initialize the editor
    //must have AWAIT!!!
    if (!nwsapp.editorInitialied) {
        await editor.editorInit(true);
        nwsapp.editorInitialied = true
    }


    //console.log("subRoute", subRoute)
    switch (subRoute[0]) {
        case 'pages':
            if (subRoute[1]) {
                // Specific page, e.g., /editor/:projectId/pages/:pageId
                console.log(`Opening page with ID: ${subRoute[1]}`);
                await openPage(subRoute[1]);
            } else {
                // Default to list of pages
                console.log("Opening pages view");
                await pages();
            }
            break;
        case 'page':
            console.log("opening page....", subRoute)
            openPage(subRoute)
            break;

        case 'settings':
            console.log("Opening settings");
            await settings();
            break;
        case 'add_content':
            //console.log("Opening add content section");
            await add_content();
            break;
        case 'css':
            console.log("Opening CSS section");
            css();
            break;
        case 'schemas':
            if (subRoute[1]) {
                // Specific schema, e.g., /editor/:projectId/schemas/:schemaId
                console.log(`Opening schema with ID: ${subRoute[1]}`);
                await openSchema(subRoute[1], subRoute[2], subRoute[3]);  // Pass whichTab and promptId if they exist
            } else {
                // Default to schema list
                console.log("Opening schemas view");
                await schemas();
            }
            break;
        case 'elements':
            console.log("Opening elements view");
            elements();
            break;
        case 'forms':
            console.log("Opening forms view");
            forms();
            break;
        default:
            console.log(`Unknown sub-route: ${subRoute[0]}`);
            //open homepage
            openEditorPath("/page/" + publicai.pages.homepage)
            break;
    }


    return


}


function openEditorPath(path) {
    let section = router.path.segments[0]
    let projectId = nwsapp.currentProject.id
    let pathToNavigateTo = "/" + section + "/" + projectId + path
    router.navigateTo(pathToNavigateTo)
}



async function openPage(path) {

    //console.log(">>>>opening page", path)

    let pageId = path[1]


    let pageToOpen = _find.elementByID(publicai.pages, pageId)

    //console.log

    if (pageToOpen) {
        if (pageToOpen.type == "folder") {
            _pages.selected = pageToOpen.homepage
        } else {
            _pages.selected = pageId
        }
    } else {
        _pages.selected = publicai.pages.homepage
        console.log("Page not found. Btw, notifications are broken.")
        /*   notifications.show([
              ["Oops.", "We couldn't find that page. Opening project homepage instead."]
          ]) */
    }

    await editor.editorInit()

    editor.renderPage(true)

    window.router.updatePageTitle('Edit - ' + publicai.active.page.properties.name);

}




async function loadProject(projectId) {
    let url = "/api/project/get";
    await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: projectId }),
    })
        .then((response) => response.json())
        .then((json) => {
            //console.log("Got project:", json);
            nwsapp.currentProject = json;  // Assign project data to global variable
        })
        .catch((error) => {
            console.error("Error getting project:", error);
        });
}


async function saveProject() {

    console.log("***save")

    let projectData = nwsapp.currentProject

    let url = "/api/project/save";

    await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(projectData)
    })
        .then(response => response.json())
        .then(json => {
            console.log("Project saved:", json);
        })
        .catch(error => {
            console.error("Error saving project:", error);
        });
}


async function createNewProject() {
    //text, rich text, image, multi image, video link, link, email, phone, number, date time, switch, color, opionion, file, reference, multi reference

    console.log("***here")

    let projectData = getDefaultProject()

    let projectId = generateSceneID()
    projectData.id = projectId

    console.log("Saving new project:", projectData);

    let url = "/api/project/save";

    await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(projectData)
    })
        .then(response => response.json())
        .then(json => {
            console.log("Project saved:", json);
        })
        .catch(error => {
            console.error("Error saving project:", error);
        });


    delete nwsapp.currentProject
    nwsapp.currentProject = projectData
    router.updateURLSilently("/editor/" + projectId + "/pages")

}

// Client function to fetch all projects for the current user
export async function loadAllProjects() {
    const url = "/api/project/getAll";

    return fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
    })
        .then(response => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.json();
        })
        .then(json => {
            console.log("All projects loaded:", json);
            nwsapp.userProjects = json;  // Store the fetched projects in a global variable
            return json;  // Return the JSON result so it can be used in .then() after the promise
        })
        .catch(error => {
            console.error("Error loading all projects:", error);
            throw error;  // Rethrow the error so it can be caught by the caller
        });
}

export async function deleteProject(resourceId) {
    const url = "/api/project/delete";

    return fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ resourceId })
    })
        .then(response => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.json();
        })
        .then(json => {
            console.log("Project deleted:", json);
            return json;  // Return the JSON result so it can be used in .then() after the promise
        })
        .catch(error => {
            console.error("Error deleting project:", error);
            throw error;  // Rethrow the error so it can be caught by the caller
        });
}




async function settings() {
    await checkIfFirstPageHasLoaded();
    _nav.openSideSection("settings");
    window.router.updatePageTitle('Project Settings');
}

async function add_content() {
    await checkIfFirstPageHasLoaded();
    _nav.openSideSection("add_content");
    window.router.updatePageTitle('Add Content');
}

function css() {
    _nav.openSideSection("css");
    window.router.updatePageTitle('Project Code');
}

async function pages() {
    await checkIfFirstPageHasLoaded();
    _nav.openSideSection("pages");
    window.router.updatePageTitle('Pages');
}



async function schemas() {
    await checkIfFirstPageHasLoaded();
    _nav.openSideSection("schemas");
    window.router.updatePageTitle('Schemas');
}

async function openSchema(schemaId, whichTab, promptId) {
    await checkIfFirstPageHasLoaded();

    if (publicai.dataSources.schemas[schemaId]) {
        _nav.openSideSection("schemas");
        window.router.updatePageTitle('Schemas');

        _schemas.selected = schemaId;
        publicai.selected.schema = publicai.dataSources.schemas[schemaId];
        _schema_settings.init(whichTab, promptId);
    } else {
        _nav.openSideSection("schemas");
        window.router.updatePageTitle('Schemas');
        notifications.show([
            ["Oops.", "That schema doesn't exist."]
        ]);
    }
}

function forms() {
    _nav.openSideSection("forms");
    window.router.updatePageTitle('Forms');
}

function elements() {
    _nav.openSideSection("elements");
    window.router.updatePageTitle('Elements');
}


async function checkIfFirstPageHasLoaded() {


    return true

    return new Promise((resolve, reject) => {
        if (publicai.active.bodyElement) {
            // If the page is already active, resolve the promise right away.
            resolve("Page already loaded");
        } else {
            //no page has been rendered
            // If the page is not active, navigate to the homepage, then resolve after a delay.
            window.router.navigateTo('/page/' + _publicai.pages.homepage);
            // Resolve the promise after a 500ms delay.
            setTimeout(() => resolve("Navigated to homepage and resolved after delay"), 500);
        }
    });
}