
import * as utils from '../modules/utils/utils.js';

export async function init(path) {

    console.log("settings", path)

    let segments = path.split("/")

    utils.addWhatWhere(nwsapp.comp.create, app.ui.main.el, "create", true)
    //let callbacks = { workspaces, plan, profile, publishing, fonts, team }

    /* 
        let tabs = new utils.Tabs({
              identifiers: {
            tab: "tab",
            panel: "panel"
        },
            container: app.ui.settings.el,
            default: segments[2],
            callbacks: callbacks
        })
     */


}







