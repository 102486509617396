export var css_shadows = {

    init: function () {

        if (!this.ui) {
            let container = $$$(document, "data-group", "shadows")
            this.ui = {
                "panel": container,
                "allDataInputGroups": $$$(container, "data-input-group"),
                "shadow_type_dropdown": $$$(container, "data-dropdown", "shadow-type"),
                "extras_container": $$$(container, "data-transitions", "container"),
                "inside": $$$(container, "shadow_entry", "inside"),
                "outside": $$$(container, "shadow_entry", "outside"),
            }
        }

        this.ui.delInside = $$$(this.ui.inside, "data-extras", "delete")
        this.ui.delOutside = $$$(this.ui.outside, "data-extras", "delete")

        this.ui.inside.onclick = function (e) {
            css_shadows.editShadow(css_shadows.ui.inside, e)
            e.stopPropagation()
            e.preventDefault()
        }

        this.ui.outside.onclick = function (e) {
            css_shadows.editShadow(css_shadows.ui.outside, e)
            e.stopPropagation()
            e.preventDefault()
        }

        css_shadows.highlightShadowButtons()

    },

    highlightShadowButtons: function () {

        //css_shadows.parseShadow(publicai.active.lastStyleInStack.css["box-shadow"])
        //highlight the 2 shadow buttons if the have values

        //css_shadows.shadow = css_shadows.parseShadow(publicai.active.lastStyleInStack.css["box-shadow"])

        if (publicai.active.lastStyleInStack.css["box-shadow"]) {


            if (!css_shadows.shadow) {
                css_shadows.shadow = css_shadows.parseShadow(publicai.active.lastStyleInStack.css["box-shadow"])
            }

            let insideShadow = css_shadows.shadow?.inside
            let outsideShadow = css_shadows.shadow?.outside

            //console.log("inside", insideShadow, "outside", outsideShadow)


            if (insideShadow) {
                css_shadows.ui.delInside.classList.remove("hidden")
            }
            if (outsideShadow) {
                css_shadows.ui.delOutside.classList.remove("hidden")
            }

            css_shadows.ui.delInside.onclick = function (e) {
                e.stopPropagation()
                e.preventDefault()
                delete css_shadows.shadow.inside
                publicai.active.lastStyleInStack.css["box-shadow"] = css_shadows.buildShadowString(css_shadows.shadow)
                applyCSS()
                css_shadows.highlightShadowButtons()

            }

            css_shadows.ui.delOutside.onclick = function (e) {
                e.stopPropagation()
                e.preventDefault()
                delete css_shadows.shadow.outside
                publicai.active.lastStyleInStack.css["box-shadow"] = css_shadows.buildShadowString(css_shadows.shadow)
                applyCSS()
                css_shadows.highlightShadowButtons()
            }

            render.addClassesToAllChildrenOf(css_shadows.ui.inside, "hasvalue", !!insideShadow);
            render.addClassesToAllChildrenOf(css_shadows.ui.outside, "hasvalue", !!outsideShadow);

        } else {
            render.addClassesToAllChildrenOf(css_shadows.ui.inside, "hasvalue", false);
            render.addClassesToAllChildrenOf(css_shadows.ui.outside, "hasvalue", false);
            this.ui.delInside.classList.add("hidden")
            this.ui.delOutside.classList.add("hidden")
        }



    },

    editShadow: function (target, event) {

        let shadowType = target.getAttribute("shadow_entry")
        if (!publicai.active.lastStyleInStack.css["box-shadow"]) {
            publicai.active.lastStyleInStack.css["box-shadow"] = ""
        }

        css_shadows.shadow = css_shadows.parseShadow(publicai.active.lastStyleInStack.css["box-shadow"])

        let insideShadow = css_shadows.shadow.inside
        let outsideShadow = css_shadows.shadow.outside

        if (!insideShadow && shadowType == "inside") {
            css_shadows.shadow.inside = {
                inset: true,
                offsetX: 1,
                offsetY: 1,
                blurRadius: 4,
                spreadRadius: 1,
                color: '#dadada'
            }
        }

        if (!outsideShadow && shadowType == "outside") {
            css_shadows.shadow.outside = {
                inset: true,
                offsetX: 1,
                offsetY: 1,
                blurRadius: 12,
                spreadRadius: 1,
                color: '#dadada'
            }
        }

        insideShadow = css_shadows.shadow.inside //refreshes
        outsideShadow = css_shadows.shadow.outside


        publicai.active.lastStyleInStack.css["box-shadow"] = this.buildShadowString(css_shadows.shadow)

        delete css_shadows.shadow

        css_shadows.shadow = this.parseShadow(publicai.active.lastStyleInStack.css["box-shadow"])
        css_shadows.highlightShadowButtons() //highlight the 2 shadow buttons if the have values

        let targetCoords = target.getBoundingClientRect()

        let settingsEl = css_utils.configurationPanel({
            comp: components.ui.popup_shadow_setup,
            x: targetCoords.left,
            y: targetCoords.top,
            width: 240,
            overlayOpacity: 0.01,
            callback: updateShadow
        })

        function updateShadow(settings) {
            //console.log("closed shadow settings", settings)
        }

        $$$(settingsEl, "thegroup", "header").textContent = capitaliseFirstLetter(shadowType) + " shadow"

        this.shadowConfiguration(settingsEl, shadowType)

    },


    setUpDefaultsDropdown: function (dropdown, shadowType) {





        function changeShadowPreset(s) {
            css_shadows.shadow[shadowType] = s.value
            publicai.active.lastStyleInStack.css["box-shadow"] = css_shadows.buildShadowString(css_shadows.shadow)
            css_utils.closeConfigurationpanelManually()
            applyCSS()
            css_shadows.highlightShadowButtons()
            css_shadows.editShadow(css_shadows.ui[shadowType], null)
        }


        dropdown.onclick = event => {
            event.stopPropagation()
            event.preventDefault()

            css_utils.reg_dropdown({
                "selector": dropdown,
                "values": css_shadows.shadowPresets[shadowType],
                "label": "Add new element",
                "styles": ["auto"],
                "customMode": true,
                "save": false,
                "callback": changeShadowPreset,
                "direction": "up",
                "overlayColor": "rgb(107 95 88)",
                "overlayOpacity": 0.05,
                "width": 200

            })
        }


    },



    shadowConfiguration: function (settingsEl, shadowType) {

        console.log(shadowType)

        let shadowToConfigure = css_shadows.shadow[shadowType]
        console.log("shadowToConfigure", shadowToConfigure, shadowType)

        let el = {
            offsetX: $$$(settingsEl, "data-shadow-property", "offset_x"),
            offsetY: $$$(settingsEl, "data-shadow-property", "offset_y"),
            blurRadius: $$$(settingsEl, "data-shadow-property", "blur"),
            spreadRadius: $$$(settingsEl, "data-shadow-property", "size"),
            
            color: $$$(settingsEl, "data-shadow-property", "color"),
            presetsDropdown: $$$(settingsEl, "data-multi", "shadow-preset"),
            "color_picker": $$$(settingsEl, "data-input", "shadow-color"),

        }

        this.setUpDefaultsDropdown(el.presetsDropdown, shadowType)

        css_utils.input({
            "selector": el.offsetX,
            "object": shadowToConfigure, //the object to update instead of the css
            "variable": "offsetX",
            "slider": true,
            "defaultSliderValue": shadowToConfigure.offsetX || 0,
            "ranges": {
                "min": -20,
                "max": 20,
            },
            "units": false,
            "custom_range": true, //if true, then use the range above. need to change name to something else
            "validation": "number",
            "callback": updateOffsetX
        })

        function updateOffsetX(s) {
            shadowToConfigure.offsetX = s
            updateShadowSettings()
        }


        css_utils.input({
            "selector": el.offsetY,
            "object": shadowToConfigure, //the object to update instead of the css
            "variable": "offsetY",
            "slider": true,
            "defaultSliderValue": shadowToConfigure.offsetY || 0,
            "ranges": {
                "min": -20,
                "max": 20,
            },
            "units": false,
            "custom_range": true, //if true, then use the range above. need to change name to something else
            "validation": "number",
            "callback": updateOffsetY
        })
        function updateOffsetY(s) {
            shadowToConfigure.offsetY = s
            updateShadowSettings()
        }

        css_utils.input({
            "selector": el.blurRadius,
            "object": shadowToConfigure, //the object to update instead of the css
            "variable": "blurRadius",
            "slider": true,
            "defaultSliderValue": shadowToConfigure.blurRadius,
            "ranges": {
                "min": 0,
                "max": 20,
            },
            "units": false,
            "custom_range": true, //if true, then use the range above. need to change name to something else
            "validation": "number",
            "callback": updateBlurRadius
        })
        function updateBlurRadius(s) {
            shadowToConfigure.blurRadius = s
            updateShadowSettings()
        }

        css_utils.input({
            "selector": el.spreadRadius,
            "object": shadowToConfigure, //the object to update instead of the css
            "variable": "spreadRadius",
            "slider": true,
            "defaultSliderValue": shadowToConfigure.spreadRadius,
            "ranges": {
                "min": 0,
                "max": 20,
            },
            "units": false,
            "custom_range": true, //if true, then use the range above. need to change name to something else
            "validation": "number",
            "callback": updateSpreadRadius
        })
        function updateSpreadRadius(s) {
            shadowToConfigure.spreadRadius = s
            updateShadowSettings()
        }




        let colorPickerSettings = {
            "selector": el.color_picker,
            "element": el.color,
            "default": shadowToConfigure.color,
            "targetObject": shadowToConfigure,
            "label": "",
            "targetVariable": "color",
            "targetDocumentObject": null,
            //"targetDocumentObject": publicai.selected.element,
            "showColorCode": false,
            "callback": refreshShadowColor,
            "name": generateSceneID(),
            //"cover": css_borders.ui.color_cover_element, //element on top
        }

        console.log("colorPickerSettings", colorPickerSettings)


        css_utils.input({
            "selector": el.color,
            "object": shadowToConfigure,
            "variable": "color",
            "color": colorPickerSettings,
            "units": false,
            "validation": "color",
        })

        function refreshShadowColor(s) {
            updateShadowSettings()
        }

        function updateShadowSettings() {
            publicai.active.lastStyleInStack.css["box-shadow"] = css_shadows.buildShadowString(css_shadows.shadow)
            applyCSS()
            css_shadows.highlightShadowButtons()
        }


    },


    buildShadowString: function (shadows) {
        const buildIndividualShadowString = (shadow, isInset) => {
            if (!shadow) return ''; // If the shadow is null or undefined, return an empty string.

            // Destructure properties from shadow, ensure 'px' is added to numerical values
            const { offsetX, offsetY, blurRadius, spreadRadius, color } = shadow;
            return `${isInset ? 'inset ' : ''}${offsetX || 0}px ${offsetY || 0}px ${blurRadius || 0}px ${spreadRadius || 0}px ${color}`;
        };

        let shadowStrings = [];

        // Build the inside shadow string
        if (shadows.inside) {
            shadowStrings.push(buildIndividualShadowString(shadows.inside, true));
        }
        // Build the outside shadow string
        if (shadows.outside) {
            shadowStrings.push(buildIndividualShadowString(shadows.outside, false));
        }

        // Join the individual shadow strings with a comma
        return shadowStrings.join(', ');
    },


    parseShadow: function (shadowString) {

        if (!shadowString) {
            return {
                inside: null,
                outside: null
            }
        }

        // Split the shadow string into its parts and take only the first two.
        const shadowParts = shadowString.split(/,\s*(?![^\(]*\))/).slice(0, 2);

        // Define the object to hold the inside and outside shadows.
        const shadows = {
            inside: null,
            outside: null
        };

        // Define the parser for an individual shadow part.
        const parseIndividualShadow = (shadowPart, isInset) => {
            // If there's no shadow part, return null.
            if (!shadowPart) return null;

            // Initialize the shadow object.
            const shadowObj = {
                inset: isInset,
                offsetX: null,
                offsetY: null,
                blurRadius: null,
                spreadRadius: null,
                color: null
            };

            // Extract the color value using regex.
            const colorMatch = shadowPart.match(/rgba\([^)]+\)|#[\da-fA-F]{6}|#[\da-fA-F]{8}|#[\da-fA-F]{3}/);
            shadowObj.color = colorMatch ? colorMatch[0] : null;
            shadowPart = shadowPart.replace(shadowObj.color, '').trim();

            // Parse the numerical values from the remaining string.
            const parts = shadowPart.split(/\s+/).map(part => parseInt(part, 10));
            parts.forEach((part, index) => {
                if (!isNaN(part)) {
                    if (index === 0) shadowObj.offsetX = part;
                    else if (index === 1) shadowObj.offsetY = part;
                    else if (index === 2) shadowObj.blurRadius = part;
                    else if (index === 3) shadowObj.spreadRadius = part;
                }
            });

            return shadowObj;
        };

        // Process the shadow parts.
        shadowParts.forEach((part, index) => {
            const isInset = part.startsWith('inset');
            // If it's the first part and inset, it's the inside shadow.
            if (index === 0 && isInset) {
                shadows.inside = parseIndividualShadow(part, true);
            }
            // If it's the first part and not inset, or it's the second part, it's the outside shadow.
            else if ((index === 0 && !isInset) || index === 1) {
                shadows.outside = parseIndividualShadow(part, false);
            }
        });

        return shadows;
    },
    shadowPresets: {
        outside: [
            {
                "label": "Soft Glow",
                "value": {
                    "inset": false,
                    "offsetX": 0,
                    "offsetY": 0,
                    "blurRadius": 6,
                    "spreadRadius": 1,
                    "color": "#efefef"
                }
            },
            {
                "label": "Moderate Lift",
                "value": {
                    "inset": false,
                    "offsetX": 1,
                    "offsetY": 6,
                    "blurRadius": 8,
                    "spreadRadius": 2,
                    "color": "rgba(0,0,0,0.3)"
                }
            },
            {
                "label": "Bold Elevation",
                "value": {
                    "inset": false,
                    "offsetX": 2,
                    "offsetY": 8,
                    "blurRadius": 10,
                    "spreadRadius": 1,
                    "color": "rgba(0,0,0,0.4)"
                }
            },
            {
                "label": "Strong Depth",
                "value": {
                    "inset": false,
                    "offsetX": 2,
                    "offsetY": 10,
                    "blurRadius": 12,
                    "spreadRadius": 1,
                    "color": "rgba(0,0,0,0.5)"
                }
            },
            {
                "label": "Intense Contrast",
                "value": {
                    "inset": false,
                    "offsetX": 2,
                    "offsetY": 12,
                    "blurRadius": 14,
                    "spreadRadius": 1,
                    "color": "rgba(0,0,0,0.6)"
                }
            }
        ],
        inside: [
            {
                "label": "Gentle Inset",
                "value": {
                    "inset": true,
                    "offsetX": 0,
                    "offsetY": 2,
                    "blurRadius": 4,
                    "spreadRadius": 0,
                    "color": "rgba(0,0,0,0.2)"
                }
            },
            {
                "label": "Soft Inward",
                "value": {
                    "inset": true,
                    "offsetX": 0,
                    "offsetY": 4,
                    "blurRadius": 6,
                    "spreadRadius": 0,
                    "color": "rgba(0,0,0,0.3)"
                }
            },
            {
                "label": "Moderate Concave",
                "value": {
                    "inset": true,
                    "offsetX": 0,
                    "offsetY": 6,
                    "blurRadius": 8,
                    "spreadRadius": 0,
                    "color": "rgba(0,0,0,0.4)"
                }
            },
            {
                "label": "Deep Indent",
                "value": {
                    "inset": true,
                    "offsetX": 0,
                    "offsetY": 8,
                    "blurRadius": 10,
                    "spreadRadius": 0,
                    "color": "rgba(0,0,0,0.5)"
                }
            },
            {
                "label": "Intense Inset",
                "value": {
                    "inset": true,
                    "offsetX": 0,
                    "offsetY": 10,
                    "blurRadius": 12,
                    "spreadRadius": 0,
                    "color": "rgba(0,0,0,0.6)"
                }
            }
        ],
    }



}