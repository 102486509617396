export var _shortcuts = {

    //    _shortcuts.handleRightClickOverIframe()

    //public.active.bodyElement

    handleIframeEvents: function () {

        nwsapp.activeDocument.addEventListener('contextmenu', (event) => {
            _shortcuts.handleRightClickOverIframe(event)
            event.preventDefault()
            event.stopPropagation()
        })


    },

    handleClicksOverIframe(event) {


        if (publicai.classesExpansionPanelCloseFunction) {
            publicai.classesExpansionPanelCloseFunction()
            publicai.classesExpansionPanelCloseFunction = null
            event.stopPropagation()
            event.preventDefault()
        }

        //picked up from document elements
        if (publicai.closeFunction) {
            publicai.closeFunction()
            publicai.closeFunction = null
        }

        if (publicai.active.state) {
            publicai.active.state = null
            css_management.manage(true)
        }

    },


    handleRightClickOverIframe: function (event) {

        //uncomment tot enable right clicks

        let rightClickValueOptions = [

            {
                "label": publicai.hovering?.settings?.classes[0].name,
                "value": null,
                "style": "is_chapter",
                "icon": "adjust"
            },

            {
                "label": "Size",
                "value": "size",
                "icon": "aspect_ratio",
                "icon2": "chevron_right",
                "submenu": true,
                //"comment": "b alat nbla"
            },

            {
                "label": "Add element",
                "value": "add",
                "icon": "add",
                "icon2": "chevron_right",
                "submenu": true,
                //"comment": "b alat nbla"
            },

            {
                "label": "Wrap in DIV",
                "value": "wrap_in_div",
                "icon": "text_fields"
            },
            {
                "label": "Re-create DIV",
                "value": "recreate",
                "icon": "rotate_left"
            },
            {
                "label": "Inspect Node",
                "value": "inspect",
                "icon": "rotate_left"
            },
            {
                "label": "Wrap in Carousel",
                "value": "carousel",
                "icon": "view_carousel"
            },
            {
                "label": "Select Parent Element",
                "value": "select_parent_element",
                "icon": "call_made"
            },
            {
                "label": "Unwrap",
                "value": "unwrap",
                "icon": "layers_clear"
            },
            {
                "label": "Position: Absolute",
                "value": "absolute",
                "icon": "drag_indicator",
            },
            {
                "label": "Convert to Link Block",
                "value": "convert_to_link_block",
                "icon": "link"
            },
            {
                "label": "Cut",
                "value": "cut",
                "icon": "content_cut"
            },
            {
                "label": "Copy",
                "value": "copy",
                "icon": "content_copy"
            },
            {
                "label": "Duplicate",
                "value": "duplicate",
                "icon": "control_point_duplicate"
            },
            {
                "label": "Delete",
                "value": "delete",
                "icon": "delete"
            },
            {
                "label": "Add Class...",
                "value": "add_class",
                "icon": "class"
            },
            {
                "label": "Duplicate Class...",
                "value": "duplicate_class",
                "icon": "content_copy"
            },
            {
                "label": "Remove Class...",
                "value": "remove_class",
                "icon": "remove_circle_outline"
            },
            {
                "label": "Rename Class...",
                "value": "rename_class",
                "icon": "edit"
            },
            {
                "label": "Add Interaction Trigger",
                "value": "add_interaction_trigger",
                "icon": "touch_app"
            },
            {
                "label": "Move to Parent",
                "value": "move_to_parent",
                "icon": "subdirectory_arrow_left"
            },
            {
                "label": "Create Component",
                "value": "create_component",
                "icon": "add_box"
            }
        ];

        let x, y

        if (!event) {

            let target = publicai.selected.element.getBoundingClientRect();
            x = target.left
            y = target.top

        } else {
            x = event.clientX
            y = event.clientY - 40
        }



        let panel = new Menu({
            "selector": publicai.active.iframe,
            "values": rightClickValueOptions,
            "label": "Add new element",
            "styles": ["auto", "horizontal", "light", "no_input"],
            "customMode": true,
            "save": false,
            //"callback": settings.callback,
            "direction": "down",
            "overlayColor": "rgb(107 95 88)",
            "overlayOpacity": 0.08,
            "width": 200,
            "max_height": 600,
            x: x,
            y: y,
            "callback": righClickCallback
        })




        /*   let panel = css_utils.reg_dropdown({
              "selector": publicai.active.iframe,
              "values": rightClickValueOptions,
              "label": "Add new element",
              "styles": ["auto", "horizontal", "light", "no_input"],
              "customMode": true,
              "save": false,
              "callback": settings.callback,
              "direction": "down",
              "overlayColor": "rgb(107 95 88)",
              "overlayOpacity": 0.08,
              "width": 200,
              "max_height": 600,
              x: x,
              y: y,
              "callback": righClickCallback
          })
   */



        function righClickCallback(s) {
            console.log("right click callback", s)
            //create a switch for all rightClickValueOptions
            switch (s.value) {
                //create cases for all rightClickValueOptions
                case "carousel":
                    //_carousel.wrapCurrentDiv()
                    break;

                case "wrap_in_div":
                    wrapCurrentDiv()
                    break;

                case "select_parent_element":
                    _create.activateSpecificElement(publicai.selected.settings.ancestor)
                    break;
                case "inspect":
                    console.log("Node \n", publicai.selected.settings)
                    console.log("Element \n", publicai.selected.element)
                    console.log("Ancestor \n", $$$(publicai.active.bodyElement, "public_id", publicai.selected.settings.ancestor))

                    break;

                case "recreate":
                    console.log("--rec")
                    play_interactive.forceRecreateSelected(true)
                    break;

            }

        }

        function wrapCurrentDiv() {
            let current = publicai.selected.settings
            let ancestor = null
            if (current.ancestor) {
                ancestor = _find.elementByID(publicai.active.page, current.ancestor)
            } else {
                ancestor = publicai.active.page.content[0].content
            }




            //find position of current element in ancestors content stack
            let myIndex = ancestor.content.findIndex(x => x.id === current.id)
            let newWrapper = _defaults.div()
            //add wrapper at my index
            ancestor.content.splice(myIndex, 0, newWrapper)
            //move current element to wrapper ande delete from previous location
            newWrapper.content.push(current)
            ancestor.content.splice(myIndex + 1, 1)

            publicai.selected.element = $$$(nwsapp.activeDocument, "public_id", newWrapper.id)
            publicai.selected.settings = newWrapper
            publicai.selected.id = newWrapper.id

            _create.deselectAllElements()

            //css_management.manage(true)


            _create.addSelectionContainer(publicai.selected.element, newWrapper, "select")
            play_interactive.forceRecreateSelected(false, true)




        }

        if (event) {
            event.preventDefault()
            event.stopPropagation()
        }

    },


    /*   handleScroll: function (event) {
          //used by text editors / handlers > text
          //console.log("scrolling")
          PubSub.publish('scroll', {
              event: event
          });
      }, */

    handleScroll: function (event) {
        const scrollY = window.scrollY;
        const viewportHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;

        const scrollPercentage = scrollY / (documentHeight - viewportHeight);

        PubSub.publish('scroll', {
            event: event,
            scrollY: scrollY,
            viewportHeight: viewportHeight,
            documentHeight: documentHeight,
            scrollPercentage: scrollPercentage
        });
    },

    handleResize: function (event) {
        //used by text editors / handlers > text
        PubSub.publish('resize', {
            event: event
        });

    },



    handleMouseDown: function (event) {
        //used by text editors / handlers > text
        publicai.keys.isMouseDown = true;


        PubSub.publish('mousdown', {
            event: event
        });

    },

    handleMouseUp: function (event) {
        publicai.keys.isMouseDown = false;
        PubSub.publish('mouseup', {
            event: event
        });


    },


    handleKeyboardShortcuts: function (event) {

        if (typeof publicai == "undefined") {
            return
        }

        KeysSubscription.publish('key', {
            event: event,
            key: event.key
        });

        var active = event.target;


        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0) {
            // Command+Z for Undo
            if (event.key === 'z' && event.metaKey && !event.shiftKey) {
                event.preventDefault(); // Prevent the default action
                change_history.undo();
            }
            // Command+Shift+Z or Command+Y for Redo
            if ((event.key === 'z' && event.metaKey && event.shiftKey) || (event.key === 'y' && event.metaKey)) {
                event.preventDefault(); // Prevent the default action
                change_history.redo();
            }
        }
        // Check for Windows platform
        else {
            // Ctrl+Z for Undo
            if (event.key === 'z' && event.ctrlKey && !event.shiftKey) {
                event.preventDefault(); // Prevent the default action
                change_history.undo();
            }
            // Ctrl+Shift+Z or Ctrl+Y for Redo
            if ((event.key === 'z' && event.ctrlKey && event.shiftKey) || (event.key === 'y' && event.ctrlKey)) {
                event.preventDefault(); // Prevent the default action
                change_history.redo();
            }
        }

        //add an event for enter
        if (event.key === 'Enter') {
            //check if element is input or text area

            console.log("active.tagName", active.tagName)


            if (publicai.keys.escape) {
                publicai.keys.escape()
            }

            if (publicai.keys.enter) {
                publicai.keys.enter()
            }

            //check if shift is pressed
            if (publicai.shift) {
                console.log("shift was pressed")
            }

            //check if div is content editable 
            if (active.getAttribute("contentEditable") == "true" && !publicai.shift) {
                active.blur()
            }



            if (active.tagName.toLowerCase() === 'input') {

                if (publicai.active.page.properties.role == "prompt") {
                    role_prompt.submitForm()
                }

                //console.log("enter was pressed")
            }
        }

        //track cmd+t or ctr+t to open timeline

        /*   if ((event.key === ' ' || event.key === ' ') && (event.metaKey || event.ctrlKey)) {
              _utils.openCloseTimeline(true)
              event.stopPropagation()
              event.preventDefault()
          } */



        //capture the tab key 
        if (event.key === 'Tab') {
            //check if element is input or text area
            if (event.key === 'Tab') {
                // Prevent the default tab behavior
                event.preventDefault();

                // Get the currently focused element
                const activeElement = document.activeElement;

                // Trigger a blur on the active element
                activeElement.blur();

                // Determine the next element in the tab order
                const focusableElements = document.querySelectorAll('input, textarea, button, a[href], select, [tabindex]:not([tabindex="-1"])');

                // Convert NodeList to Array and find the index of the active element
                const focusableArray = Array.prototype.slice.call(focusableElements);
                const currentIndex = focusableArray.indexOf(activeElement);

                // Determine the next index (wrapping around if necessary)
                let nextIndex;
                if (event.shiftKey) {
                    // If Shift+Tab, move focus backward
                    nextIndex = (currentIndex - 1 + focusableArray.length) % focusableArray.length;
                } else {
                    // Move focus forward
                    nextIndex = (currentIndex + 1) % focusableArray.length;
                }

                // Focus the next element in the tab order
                focusableArray[nextIndex].focus();
            }

        }

        //check if element is input or text area



        if (event.key === ' ') {
            var active = document.activeElement;
            var activeInIframe = nwsapp.activeDocument.activeElement;

            var isActiveElementEditable = active.tagName.toLowerCase() === 'input' ||
                active.tagName.toLowerCase() == 'textarea' ||
                active.getAttribute("contentEditable") == "true";


            var isActiveElementEditableInIframe = activeInIframe.tagName.toLowerCase() === 'input' ||
                activeInIframe.tagName.toLowerCase() == 'textarea' ||
                activeInIframe.getAttribute("contentEditable") == "true";



            // Check if the active element is NOT editable
            if (!isActiveElementEditable && !isActiveElementEditableInIframe) {
                //public.timeline.animation.play()
                animator_timeline.playAndPause()
                event.preventDefault(); // Prevent default action (scrolling the page down)
                event.stopPropagation(); // Stop the event from bubbling up
            }
        }


        if (event.key === 'Meta' || event.key === 'Control') {
            publicai.keys.isMetaKeyPressed = false;
        }


        //new classes panel
        if ((event.key === 'Backspace' || event.key === 'Delete') && event.target.classList.contains("class_input_field")) {

            //first check the length of the input field and if it's empty, delete the class
            if (event.target.value.length == 0) {
                css_classes.deletePreviousClass()
            }


        }

        if ((event.key === 'Enter') && event.target.classList.contains("class_input_field")) {
            document.removeEventListener("click", css_classes.closeExpandPanel)

            if (css_classes.renamingClass) {
                //do nothing, just blur and it will call renameClass / save
            } else {
                css_classes.saveTheClass(event.target.value)
            }
            event.target.blur()

        }



        if (active && (active.tagName.toLowerCase() === 'input' || active.getAttribute("contentEditable") == "true" || active.tagName.toLowerCase() === 'textarea')) {


            //sends the event to the input to increment values
            if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                console.log("Arrow up or down")
            }


            if ((event.ctrlKey || event.metaKey) && event.key === 'v') {

                //check if input has color attribute
                if (active.getAttribute("color")) {
                    // Insert the plain text at the cursor position
                    //   active.setAttribute("color", plainText)
                    console.log("Do nothing. It's a color")
                    return
                }


                event.preventDefault(); // Prevent the default paste action

                navigator.clipboard.readText().then(plainText => {

                    // Insert the plain text at the cursor position
                    if (active.getAttribute("contentEditable") === "true") {
                        nwsapp.activeDocument.execCommand('insertText', false, plainText);
                    } else {
                        // For input or textarea
                        const start = active.selectionStart;
                        const end = active.selectionEnd;
                        active.value = active.value.slice(0, start) + plainText + active.value.slice(end);
                        active.selectionStart = active.selectionEnd = start + plainText.length;
                    }
                }).catch(err => {
                    console.error("Error accessing clipboard data: ", err);
                });

                return;
            }


            //sends the event to the input to increment values
            if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                if (publicai.inputCallback) {
                    //may be a callback set up by the inputs to capture arrow up and down
                    publicai.inputCallback(event)
                }
            }

            return;
        }

        //console.log(event.key)



        if (event.key == 't') {
            event.preventDefault(); // Prevent default action
            publicai.mode = "timeline"
            _editor_modes.enableTab()

        }

        if (event.key === 'c' && !event.ctrlKey && !event.metaKey) {
            //_nav.openSideSection("css");
            event.preventDefault(); // Prevent default action
            publicai.mode = "code"
            _editor_modes.enableTab()

        }

        if (event.key === 'r' && !event.ctrlKey && !event.metaKey) {
            //_nav.openSideSection("css");
            event.preventDefault(); // Prevent default action
            publicai.mode = "prompt"
            _editor_modes.enableTab()

        }




        if ((event.key === 'p' || event.key === 'p') && !event.metaKey && !event.ctrlKey) {
            if (publicai.active.panel == null) {
                _nav.openSideSection("pages")
            } else if (publicai.active.panel == "pages") {
                _nav.toggleNavigationContainer(false);
            }
        }

        if ((event.key === 'z' || event.key === 'Z') && !event.metaKey && !event.ctrlKey) {
            if (publicai.active.panel == null) {
                _nav.openSideSection("elements")
            } else if (publicai.active.panel == "elements") {
                _nav.toggleNavigationContainer(false);
            }
        }

        if (event.key === 'ArrowUp') {

            if (publicai.selected.settings.ancestor) {
                _create.activateSpecificElement(publicai.selected.settings.ancestor)
                event.stopPropagation()
                event.preventDefault()
            }
        }

        if (event.key === 'ArrowDown') {
            if (publicai.selected.settings.content.length > 0) {
                _create.activateSpecificElement(publicai.selected.settings.content[0].id)
                event.stopPropagation()
                event.preventDefault()
            }
        }

        if (event.key === 'Escape') {

            console.log("escape...")

            if (publicai.keys.escape) {
                publicai.keys.escape()
            }

            if (publicai.keys.enter) {
                publicai.keys.enter()
            }

            if (!nwsapp.ai) {

                //allow ai panel to capture escape key
                if (publicai.active.panel != null) {
                    _nav.toggleNavigationContainer(false);
                }
            }
        }

        if ((event.key === 'a' || event.key === 'A') && !event.ctrlKey && !event.metaKey) {
            if (publicai.active.panel == null) {
                _nav.openSideSection("add_content")
            } else if (publicai.active.panel == "add_content") {
                _nav.toggleNavigationContainer(false);
            }
        }


        function checkIfELementIsMultiItem() {
            try {
                //when deleting an item, if it's a multi value such as checkbox or dropdown, it also removes the value from the options array.
                let ancestorForm = _find.checkIfAncestorIsFormElement(publicai.selected.id)
                if (ancestorForm && (
                    ancestorForm.type == "form_checkbox" ||
                    ancestorForm.type == "form_radio" ||
                    ancestorForm.type == "form_dropdown")) {
                    if (publicai.selected.settings.settings?.role == "multi_item") {
                        let multiItemLabel = _find.elementByAttribute(publicai.selected.settings, "multi_item_label", "label")
                        console.log("found", ancestorForm, multiItemLabel.text.text)
                        ancestorForm.settings.form.options.remove(multiItemLabel.text.text)
                        console.log(ancestorForm.settings.form.options)
                    }

                }
            } catch (err) {
                console.log("Oops, couldn't delete the right item.")
            }

        }


        if (event.key == "right" || event.key == "ArrowRight" || event.key == "left" || event.key == "ArrowLeft") {
            if (animator_timeline.rollover) {
                animator_timeline.handleArrowKeys(event.key)
            }

        }


        if (event.key === 'Delete' || event.key === 'Backspace') {
            //console.log('Delete was pressed', event.target);

            if (animator_timeline.rollover) {
                //delete the effect or layer
                animator_timeline.handleDelete()
            } else {


                if (publicai.selected.settings.settings.permissions.delete) {

                    let ancestorId = publicai.selected.settings.ancestor

                    let ancestor = _find.elementByID(publicai.active.page, ancestorId)

                    console.log("ancestor is ", ancestor)

                    //find sibling within ancestor content
                    let myIndex = ancestor.content.findIndex(x => x.id === publicai.selected.id)
                    let nextSibling = ancestor.content[myIndex + 1]
                    let previousSibling = ancestor.content[myIndex - 1]

                    checkIfELementIsMultiItem()


                    _find.deleteObjectByID(publicai.active.page, publicai.selected.id)
                    css_management.manage(true) //refreshes content as well


                    //we preferably want to activate the next sibling upon delete
                    if (nextSibling) {
                        //console.log("next sibling", nextSibling)
                        _create.activateSpecificElement(nextSibling.id)
                    } else if (previousSibling) {
                        //console.log("previous sibling")
                        _create.activateSpecificElement(previousSibling.id)
                    } else if (ancestorId) {
                        //console.log("ancestor")
                        _create.activateSpecificElement(ancestorId)
                    } else {
                        //  console.log("body")
                        editor.selectBody()
                    }

                    if (publicai.active.panel == "elements") {
                        _nav.openSideSection("elements")
                    }
                } else {
                    notifications.show([
                        ["Oops.", "You can't remove this element. Try removing the parent element or hiding it."]
                    ])
                }
            }
        }




        if (event.key === 's') {
            handle.save()
            event.preventDefault()
            event.stopPropagation()
        }



        if ((event.ctrlKey || event.metaKey) && event.key === 'x') {
            console.log('Cut was pressed');
            delete nwsapp.clipboard
            nwsapp.clipboard = {
                content: JSON.parse(JSON.stringify(publicai.selected.settings)),
                scope: publicai.active.rootFolder.id,
            }
            checkIfELementIsMultiItem()

            nwsapp.clipboard.content.backupAncestor = nwsapp.clipboard.content.ancestor
            nwsapp.clipboard.content.backupId = publicai.selected.settings.id

            nwsapp.clipboard.content.id = generateSceneID();



            nwsapp.clipboard.content.ancestor = "pending";

            _find.deleteObjectByID(publicai.active.page, publicai.selected.id)
            _create.removeDeletedElements()
            css_management.manage(true) //refreshes content as well



            editor.selectBody()
        }


        if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
            if (publicai.active.panel) {
                return
            }

            if (publicai.selected.settings.type == "body") {
                notifications.show([
                    ["Oops.", "You can't copy the body element."]
                ])

                return
            }


            //console.log('Ctrl+C or Command+C was pressed');

            _shortcuts.copyElement(publicai.selected.settings)
        }



        if ((event.ctrlKey || event.metaKey) && event.key === 'v') {

            //check current object type

            //console.log("paste was pressed")

            if (publicai.active.panel && !publicai.active.panel == "elements") {
                return
            }

            if (nwsapp.clipboard) {
                //nwsapp.clipboard.ancestor = publicai.selected.settings.id
                _create.pasteTimelineElement(null, event.shiftKey)
            }


            //check if anything exists in the local storage clipboard
            if (!nwsapp.clipboard && localStorage.getItem("clipboard")) {
                nwsapp.clipboard = JSON.parse(localStorage.getItem("clipboard"))
                _create.pasteTimelineElement()
            }

            if (publicai.active.panel == "elements") {
                _nav.openSideSection("elements") //refreshes the navigation
            }

        }

        if (event.shiftKey) {
            console.log('Shift key was pressed');
            publicai.shift = true
            // Your code here
        } else {
            publicai.shift = null
        }



    },

    copyElement: function (element) {

        //span fix
        //check if the element contains spans. If so, also copy the publicai.selected.element, otherwise the spans can't be reproduced in the new element!!!
        console.log("check if spans exists. ")


        delete nwsapp.clipboard;
        nwsapp.clipboard = {
            content: JSON.parse(JSON.stringify(element)),
            scope: publicai.active.rootFolder.id,
        }

        nwsapp.clipboard.content.backupAncestor = nwsapp.clipboard.content.ancestor
        nwsapp.clipboard.content.backupId = publicai.selected.settings.id

        nwsapp.clipboard.content.settings.general.label += " Copy"


        nwsapp.clipboard.content.id = generateSceneID();
        nwsapp.clipboard.content.ancestor = "pending";

        localStorage.setItem("clipboard", JSON.stringify(nwsapp.clipboard))

        if (element.ancestor) {
            _create.activateSpecificElement(element.ancestor)
        }

        //return nwsapp.clipboard

    },


    handleKeyUp: function (event) {
        publicai.shift = null

    },
}