

import { addClassesToChildrenOf } from "./utils.js"


export class Menu {
    constructor(settings) {
        this.keyword = ""
        this.settings = settings

        if (settings.trigger) {
            this.trigger()
        } else {
            settings.selector.onclick = () => {
                this.trigger()
            }
        }

        this.destroy = this.destroy.bind(this)

    }

    trigger() {
        this.create()
        this.functionality()
        //add binding for destroy
    }

    create() {

        let settings = this.settings
        this.theLocation = app.ui.top.el

        //app.ui.main.el

        if (settings.theLocation) {
            this.theLocation = settings.theLocation
        }

        this.settings = settings;
        this.menu = nwsapp.comp.contextual
        this.overlay = utils.addWhatWhere(this.menu, this.theLocation, " ");
        this.overlay.style.position = "absolute"; //to remove
        this.overlay.style.zIndex = "1700"; //to remove

        this.ux = utils.$$$$(this.overlay, "data-public-menu")
        let container = this.ux.main

        let coords = {
            x: 0,
            y: 0
        }

        let rect

        if (settings.selector) {

            rect = settings.selector.getBoundingClientRect();

            coords.x = rect.right
            coords.y = rect.top
            coords.width = rect.width
            coords.height = rect.height
        }


        let width = settings.width

        if (settings.width == "100%") {
            //the size of the dropdown
            container.style.width = coords.width + "px"
            container.style.top = coords.y + coords.height + 5 + "px"
            container.style.left = coords.x - coords.width + "px"
        } else {
            container.style.width = width + "px"
            container.style.minWidth = width + "px"
            container.style.top = coords.y + + coords.height + 5 + "px"
            container.style.left = coords.x - width + "px"
            console.log("have set width", container.style.width)
        }



        if (settings.x && !settings.y) {
            //if coordinates are given, use those. Otherwise ^^ use the component coordinates. 

            if (window["publicai"]) {
                let iframeCoords = window["publicai"].active.iframe.getBoundingClientRect()
                let newX = iframeCoords.left + settings.x
                let newY = iframeCoords.top + settings.y
                container.style.top = newY + "px"
                container.style.left = newX + "px"

                if (settings?.iframeMode == "disabled") {
                    container.style.top = settings.y + "px"
                    container.style.left = settings.x + "px"
                }
            } else {
                container.style.top = settings.y + "px"
                container.style.left = settings.x + "px"

            }

        }


        if (this.settings.selector) {

            rect = this.settings.selector.getBoundingClientRect();
            coords.x = rect.right
            coords.y = rect.top
            coords.width = rect.width
            coords.height = rect.height
        }


        this.configureSearch()

    }

    configureSearch() {
        //console.log(this.ux)


        if (!this.settings.search) {
            this.ux.search.classList.remove("is_visible")
            return
        }

        this.ux.search.classList.add("is_visible")

        let self = this

        this.ux.search_input.oninput = (e) => {
            self.keyword = e.target.value
            self.functionality()

        }


        setTimeout(() => {
            if (this.settings.searchPlaceholder) {
                this.ux.search_input.placeholder = this.settings.searchPlaceholder
            }
        }, 100)

        setTimeout(() => {
            this.ux.search_input.focus()

        }, 120)





    }

    functionality() {

        let settings = this.settings
        let ux = this.ux

        this.ux.background.onclick = () => {
            console.log("bg clicked")
            this.destroy()
        }

        if (this.settings.overlayColor) {
            ux.contrast.style.background = this.settings.overlayColor
        }
        if (this.settings.overlayOpacity) {
            ux.contrast.style.opacity = this.settings.overlayOpacity
        }


        this.ux.comment.classList.remove("is_active")
        let itemsContainer = this.ux.items
        removeAllChildNodes(itemsContainer)

        if (settings.max_height) {
            itemsContainer.style.maxHeight = settings.max_height + "px"
        }

        if (typeof publicai != "undefined") {
            if (settings?.keys?.enter) {
                publicai.keys.enter = settings.keys.enter
            }
            if (settings?.keys?.escape) {
                publicai.keys.escape = settings.keys.escape
            }
        }


        for (let item of this.settings.values) {
            let str = JSON.stringify(item).toLowerCase()

            if (!str.includes(this.keyword.toLowerCase())) {
                continue
            }


            let menuElement = nwsapp.comp.menu_item


            if (item.type && item.type == "input") {
                menuElement = nwsapp.comp.menu_input
            }

            if (item.type && item.type == "publish") {
                menuElement = nwsapp.comp.menu_publish
            }

            if (item.type && item.type == "button") {
                menuElement = nwsapp.comp.menu_button
            }

            if (item.type && item.type == "link") {
                menuElement = nwsapp.comp.menu_link
            }



            let menuItem = utils.addWhatWhere(menuElement, itemsContainer)

            if (item.inputSettings) {
                //console.log()
                item.inputSettings(menuItem, this.settings, this, item)
            }

            if (item.style) {
                menuItem.classList.add(item.style)
            }

            let elements = $$$$(menuItem, "data-public-menu")
            //console.log(">>>elements", item, elements)


            let label = $$$(menuItem, "data-public-menu", "label")
            let icon1 = $$$(menuItem, "data-public-menu", "icon")
            let icon2 = $$$(menuItem, "data-public-menu", "icon2")

            label.textContent = item.label

            if (elements.context1) { elements.context1.textContent = item.context1 }
            if (elements.context2) { elements.context2.textContent = item.context2 }

            if (elements.label) {
                elements.label.textContent = item.label
            }
            if (elements.help) {
                elements.help.textContent = item.help
            }


            if (icon1) {
                icon1.textContent = item.icon || ""
            }
            if (icon2) {
                icon2.textContent = item.icon2 || ""
            }

            if (!item.icon && icon1) {
                icon1.classList.add("is_hidden")
            }

            if (!item.icon2 && icon2) {
                icon2.classList.add("is_hidden")
            }

            menuItem.onmouseenter = (e) => {
                e.stopPropagation()
                e.preventDefault()
                if (item.comment && item.comment != "") {
                    ux.comment.textContent = item.comment
                    ux.comment.classList.add("is_active")
                } else {
                    ux.comment.textContent = ""
                    ux.comment.classList.remove("is_active")

                }

                ux.comment.onmouseenter = (e) => {
                    e.stopPropagation()
                    e.preventDefault()
                }
            }

            menuItem.onmouseleave = (e) => {
                //items.dropdown_comment.classList.remove("active")
            }


            menuItem.onclick = (e) => {

                item.element = menuItem

                if (item.type == "input" ||
                    item.type == "publish" ||
                    item.type == "link"
                ) {
                    e.stopPropagation()
                    e.preventDefault()
                    return
                }

                if (item.hasSecondaryMenu) {
                    e.stopPropagation()
                    e.preventDefault()
                }

                if (settings.callback) {

                    if (!settings.multiSelect) {
                        //console.log("callback....")
                        e.stopPropagation()
                        e.preventDefault()
                        if (!item.hasSecondaryMenu) {
                            this.destroy()
                        }
                        settings.callback(item)
                    } else {
                        settings.callback(settings.values)

                    }
                }
            }
        }

        if (settings.styles) {
            for (let style of settings.styles) {
                addClassesToChildrenOf(itemsContainer, style, true)
            }
        }

    }

    destroy() {


        this.overlay.remove()
        if (typeof publicai != "undefined") {
            delete publicai?.keys?.enter
            delete publicai?.keys?.escape
        }

        if (this.settings.closeCallback) {
            this.settings.closeCallback()
        }
    }

}

