
import { LineItems } from '../../modules/utils/line_items.js';

import { loadAllProjects, deleteProject } from '../editor.js'


export function init(path) {

    loadAllProjects()
        .then(projects => {
            console.log("Projects loaded successfully:", projects);
            nwsapp.projects = projects
            //addProjects(projects);
            refreshLineItems(projects)
        })
        .catch(error => {
            console.error("Failed to load projects:", error);
        });

}


function openProject(project) {
    console.log("Open project", project)
    window.router.navigateTo("/editor/" + project.id + "/")
}


function dataMap(item, el) {

    //add content into the line


    let modified = new Date(item.modified)
    let created = new Date(item.created)
    el.label.textContent = item.name
    el.date.textContent = created.toLocaleString()
    el.details.textContent = modified.toLocaleString()
}



function refreshLineItems(data) {

    let lines = new LineItems({
        data: data,
        container: app.ui.content.content.projects_panel,
        line_item: nwsapp.comp.project_item_main,
        dataMap: dataMap,
        events: {
            doubleClick: openProject,
        },
        more: {
            callback: callback,
            closeCallback: closeCallback,

            keys: {
                enter: closeCallback,
                escape: closeCallback,
            },
            values: [
                {
                    value: "edit",
                    label: "Edit",
                    icon: "edit"
                },
                {
                    value: "preview",
                    label: "Preview",
                    icon: "preview"
                },
                {
                    value: "delete",
                    label: "Delete",
                    icon: "delete"
                },
                {
                    value: "label",
                    label: "Rename ",
                    type: "input",
                    inputSettings: manageitemName,
                    //item: item
                },

            ]
        }
    })

}


function closeCallback() {
    nwsapp.dropdownIsOpen = false

}

async function callback(settings, more) {

    if (settings.value == "edit") {
        window.router.navigateTo("/editor/" + settings.item.id)
    }
    if (settings.value == "preview") {
        console.log("Preview")
    }
    if (settings.value == "delete") {
        console.log("Delete")
        await deleteProject(settings.item.id)
        //recreate list of projects
   /*      loadAllProjects()
            .then(projects => {
                console.log("Projects loaded successfully:", projects);
                nwsapp.projects = projects
                //addProjects(projects);
                refreshLineItems(projects)
            }) */
    }

}


function manageitemName(menuItem, settings, menu, item) {
    //manages the input settings for the label via Menu constructor

    return

    console.log("MANAGE", settings, item)

    let elements = $$$$(menuItem, "input_el")

    elements.input.placeholder = "Enter Name...."
    elements.input.value = item.item.name

    elements.label.textContent = "Rename"
    elements.comment.textContent = "Rename the current item name."


    elements.button_label.textContent = "Rename"




    elements.button.onclick = function () {
        menu.destroy()
    }

    elements.input.focus()
}