import { css_audio } from './css/audio.js';
import { css_backdrop } from './css/backdrop.js';
import { css_background } from './css/background.js';
import { css_borders } from './css/borders.js';
import { css_carousel } from './css/carousel.js';
import { css_classes } from './css/classes.js';
//import { css_input } from './css/css_input.js';

import { css_effects } from './css/effects.js';
import { css_elements } from './css/elements.js';
import { css_filters } from './css/filters.js';
import { css_layout } from './css/layout.js';
import { css_media } from './css/media.js';
import { css_position } from './css/position.js';
import { css_shadows } from './css/shadows.js';
import { css_size } from './css/size.js';
import { css_spacing } from './css/spacing.js';
import { css_style } from './css/style.js';
import { css_transforms } from './css/transforms.js';
import { css_transitions } from './css/transitions.js';
import { css_typography } from './css/typography.js';


export function destroyAllColorPickers() {
    for (let i = publicai.pickers.length - 1; i >= 0; i--) {
        let picker = publicai.pickers[i];
        picker.destroy();
    }
}


export function manage(refreshContent = false, createHistorySnapshot = true, specificPanels = null, initialiseTimeline = true) {

    if (!window.css_utils) {
        window.css_utils = css_utils
    }

    if (animator_timeline.mode == "keyframes") {
        let effectType = publicai.keyframesTimeline?.settings[publicai.keyframesTimeline?.selected].settings?.type
        if (effectType && !specificPanels) {
            specificPanels = effectType
        }
    }


    css_management.destroyAllColorPickers()

    if (nwsapp.video) {
        video_css.updateCssEditor()
        return
    }

    if (!this.ui) {
        this.ui = {
            tabs: $$$$(document, "editor-tab"),
            panels: $$$$(document, "editor-tab-content"),
        }
    }


    if (!this.allPanels) {
        this.allPanels = $$$$(document, "data-group")
    }

    this.toggleCustomTabs() //toggles visibility on third tab dynamically


    let self = this
    this.log = []
    function addToLog(arg) {
        self.log.push(arg)
    }

    if (refreshContent) { _create.refreshContent() }

    //check if last in stack is ok

    this.refreshCssStack()
    this.updateBreadCrumbs()



    applyCSS(createHistorySnapshot) //creates the css object in the head

    if (code_editor.mode && publicai.mode == "code" && !nws.isPlayer) {
        code_editor.init()
    }


    if (publicai.active.tabs.main == "settings") {
        //if object selection changes while settings panel is open >> refresh settings panel
        settings_init.init()
    }





    let panelsToInitialise
    let panelsToHide = []

    panelsToHide = ["classes", "elements", "layout", "carousel", "audio", "shadows", "filters", "backdrop"]
    panelsToInitialise = ["classes", "elements", "background", "borders", "typography", "position", "size", "spacing", "style", "layout", "shadows", "transitions", "transforms", "filters", "backdrop", "carousel", "audio"]

    const panels = {
        classes: css_classes,
        elements: css_elements,
        background: css_background,
        borders: css_borders,
        typography: css_typography,
        position: css_position,
        size: css_size,
        spacing: css_spacing,
        style: css_style,
        layout: css_layout,
        shadows: css_shadows,
        transitions: css_transitions,
        transforms: css_transforms,
        filters: css_filters,
        backdrop: css_backdrop,
        carousel: css_carousel,
        audio: css_audio,
    };


    //health check to prevent failed initialisation
    if (css_classes.ui) {
        if (document.contains(css_position.ui.panel)) {
            //console.log("Element is in the DOM. All good.");
        } else {
            //console.log("Element has been removed from the DOM");
            for (const [key, panel] of Object.entries(panels)) {
                try {
                    if (panel.ui) {
                        delete panel.ui;
                    }
                } catch (err) {
                    console.log(`Error deleting ui for ${key}:`, err);
                }
            }
        }
    }

    //restore visibility 

    function restoreAllPanels(status) {
        for (let item of Object.values(self.allPanels)) {
            item.classList.toggle("is_hidden", status)
        }
    }


    restoreAllPanels(false) //makes all visible


    if (publicai.active.lastStyleInStack) {
        //addToLog(["last in stack", publicai.active.lastStyleInStack])
        if (specificPanels) {
            //console.log("specific panel", specificPanels)


            restoreAllPanels(true)  //hides all

            let panels = specificPanels.replaceAll(" ", "").split(",")

            for (let panel of panels) {
                this.allPanels[panel].classList.remove("is_hidden")
            }



            panelsToHide = panelsToInitialise
            panelsToHide = panelsToHide.filter(item => item !== specificPanels)
        } {

            for (let panel of Object.keys(panels)) {
                try {

                    this.allPanels[panel].classList.remove("disabled")
                } catch (err) { }
            }
        }
    } else {
        //no classes exist

        let allOtherPanels = ["background", "borders", "typography", "position", "size", "spacing", "style", "layout", "shadows", "transitions", "transforms", "filters", "backdrop", "carousel"]
        for (let panel of allOtherPanels) {
            try {

                this.allPanels[panel].classList.add("disabled")
            } catch (err) { }
        }
        panelsToInitialise = ["classes"] // do not initialise any other panels before an active class is added
    }


    this.cleanUpColorPanels()

    for (let panel of panelsToInitialise) {

        panels[panel].init()
        //console.log("panel", panel)
        //window["css_" + panel].init()
        addToLog(["63", panel])
    }
    this.setUpPanelsFocus()

    let sideContainer = $$$(document, "editor-tab-content", "style")
    this.resetTabIndexes(sideContainer)
    _utils.activateToolsPanel(true)
    addToLog(["67", "panel activated"])

    if (initialiseTimeline) {


        let refreshTimeline = true

        if (publicai?.timeline?.animation?.id == publicai.selected?.settings.id) {
            refreshTimeline = false
        }

        if (refreshTimeline) {
            animator_timeline.init()
        } else {
            //console.log("not refreshing timeline")
        }

    }

}


export function toggleCustomTabs() {
    //this.ui.panels
    //this.ui.tabs

    if (!publicai.selectionStack) {
        //keep track of how this runs to avoid changing to custom tabs over the same object
        //prevent tabs switching while on the same audio or media tab
        publicai.selectionStack = []
    }

    let okToProceed = true

    publicai.selectionStack.push(publicai.selected.settings.id)
    if (publicai.selectionStack.length > 2) {
        if (publicai.selected.settings.id == publicai.selectionStack[publicai.selectionStack.length - 2]) {
            okToProceed = false
        }
    }

    if (!okToProceed) {
        return
    }



    let customPanels = [
        "media", "media_settings", "carousel",
        "data_sources", "data_split", "data_prompt", "data_content"]

    let audioPanels = ["audio", "audio_captions", "audio_script", "audio_actor", "audio_playback"]

    for (let panel of customPanels) {
        css_management.allPanels[panel].classList.add("hidden")
    }

    for (let panel of audioPanels) {
        css_management.allPanels[panel].classList.add("hidden")
    }

    let toggleThirdTab = false
    let toggleDataTab = false
    let thirdTabLabel = "Media"

    if (publicai.selected.type == "media") {
        toggleThirdTab = true
        toggleDataTab = true
    }


    if (["text", "audio", "media", "quotes", "bullets", "highlights"].includes(publicai.selected.settings.type)) {
        toggleDataTab = true
    }


    let ancestorIsMedia = _find.checkIfAncestorType(publicai.selected.settings.id, ["media", "audio"])
    let ancestorIsCarousel = _find.checkIfAncestorType(publicai.selected.settings.id, ["carousel"])

    if (ancestorIsCarousel) {
        toggleThirdTab = true
        thirdTabLabel = "Carousel"
    }

    if (ancestorIsMedia) {
        toggleThirdTab = true
        if (ancestorIsMedia.type == "audio") {
            thirdTabLabel = "Audio"
        }
        if (ancestorIsMedia.type == "media") {
            thirdTabLabel = "Media"
        }
    }


    if (["media", "carousel", "audio"].includes(publicai.selected.settings.type)) {
        editor.tabs("third")
    } else {

        if (publicai.active.tabs.main == "data") {
            _dynamic.init()
        } else if (publicai.active.tabs.main != "style" && publicai.active.tabs.main != "settings") {
            editor.tabs("style")
        }
    }


    this.ui.tabs.third.classList.toggle("hidden", !toggleThirdTab)

    this.ui.tabs.data.classList.toggle("hidden", !toggleDataTab)

    this.ui.tabs.third.textContent = thirdTabLabel


    if (publicai.selected.settings.type == "media") {

        css_media.init()

    }



}


export function resetTabIndexes(container) {
    // Get all input elements within the container



    const inputs = container.getElementsByTagName('input');

    // Loop through each input and set the tabindex
    for (let i = 0; i < inputs.length; i++) {
        inputs[i].setAttribute('tabindex', i + 1);

        //console.log("resetting tab indexes", inputs[i])

        // Add focus event listener to log the tabindex
        inputs[i].addEventListener('focus', function () {
            //console.log('Focused tabindex:', this.getAttribute('tabindex'));
        });

        // Add keydown event listener to log the tabindex of the next focused element on Tab press
        inputs[i].addEventListener('keydown', function (event) {
            if (event.key === 'Tab') {
                setTimeout(() => {
                    const activeElement = document.activeElement;
                    if (activeElement.tagName.toLowerCase() === 'input') {
                        //console.log('Next focused tabindex:', activeElement.getAttribute('tabindex'));
                    }
                }, 0);
            }
        });
    }
}

export function cleanUpColorPanels() {

    let allColorPanels = document.querySelectorAll(".sp-hidden")
    //remove them all
    for (let item of allColorPanels) {
        item.remove()
    }

}

export function setUpPanelsFocus() {

    let allFocusItems = $$$(document, "data-group-action")
    let allPanels = $$$(document, "data-group")
    let allHeaders = document.querySelector(".thegroup_header")

    let allPanelByName = $$$$(document, "data-group")

    for (let key of Object.keys(allPanelByName)) {

        let item = allPanelByName[key]
        let header = item.querySelector(".thegroup_header")


        if (header) {

            //add on double click to open/close


            header.ondblclick = event => {

                let att = item.getAttribute("data-group")
                //console.log("clicked", )

                let panel = allPanelByName[att]

                if (panel) {

                    if (panel.classList.contains("public-panel-hidden")) {
                        render.addClassesToAllChildrenOf(panel, "public-panel-hidden", false)
                    } else {
                        render.addClassesToAllChildrenOf(panel, "public-panel-hidden", true)
                    }

                }

            }
        }


    }



    for (let panel of allPanels) {
        panel.onmouseover = event => {
            for (let item of allPanels) {
                item.style.zIndex = 1
            }
            panel.style.zIndex = 999
            $$$(document, "data-group", "classes").style.zIndex = 9999
            //console.log(panel)
        }

    }

}




export function refreshCssStack(node = null) {
    // If a node is provided, use its settings; otherwise, default to publicai.selected.settings
    let settings = node ? node : publicai.selected.settings;

    // Initialize a local cssStack array
    let cssStack = [];

    if (settings.classes.length > 0) {
        let mainClassName = settings.classes[0].name;
        let sep = publicai.classSeparator;
        const allowedBreakpoints = ["__desktop", "__tablet", "__mobile_h", "__mobile_v"];

        // Assuming settings.classes[0].combo is an array of strings
        let classesCombo = settings.classes[0].combo;

        // Filter out entries that are not in the allowedBreakpoints
        try {
            settings.classes[0].combo = classesCombo.filter(function (className) {
                return !allowedBreakpoints.includes(className);
            });
        } catch (err) {
            // Handle error if necessary
        }

        // Adjust combos based on breakpoints
        if (publicai.active.breakpoint === "__tablet") {
            settings.classes[0].combo.push("__tablet");
        }
        if (publicai.active.breakpoint === "__mobile_h") {
            settings.classes[0].combo.push("__tablet", "__mobile_h");
        }
        if (publicai.active.breakpoint === "__mobile_v") {
            settings.classes[0].combo.push("__tablet", "__mobile_h", "__mobile_v");
        }

        // Creating stack
        cssStack.push({
            name: mainClassName,
            css: publicai.css[mainClassName]
        });

        for (let combo of settings.classes[0].combo) {
            let lastLocation = publicai.css;
            for (let item of cssStack) {
                lastLocation = lastLocation[item.name] || lastLocation.combo[item.name];
            }

            if (!lastLocation.combo[combo]) {
                // Creating an empty entry for the breakpoint
                lastLocation.combo[combo] = {
                    "combo": {},
                    "keyframes": {},
                    "keyframe_definitions": {},
                    "state": {}
                };
            }

            cssStack.push({
                name: combo,
                css: lastLocation.combo[combo]
            });
        }

        if (publicai.active.state) {
            // Adding state such as hover, etc.
            let lastLocation = publicai.css;
            for (let item of cssStack) {
                lastLocation = lastLocation[item.name] || lastLocation.combo[item.name];
            }

            if (!lastLocation.state) {
                lastLocation.state = {};
            }

            if (!lastLocation.state[publicai.active.state]) {
                lastLocation.state[publicai.active.state] = {};
            }

            cssStack.push({
                name: mainClassName,
                css: lastLocation.state[publicai.active.state]
            });
        }

        // Handle keyframes if applicable
        if (publicai.keyframesTimeline &&
            animator_timeline.mode === "keyframes" &&
            publicai.keyframesTimeline.key !== "style" &&
            publicai.keyframesTimeline.selected
        ) {
            publicai.keyframeEditing = true;
            let selected = publicai.keyframesTimeline.effects[publicai.keyframesTimeline.selected];
            let sortedKeys = Object.keys(selected).sort((a, b) => parseInt(a) - parseInt(b));

            for (let key of sortedKeys) {
                if (parseInt(key) <= parseInt(publicai.keyframesTimeline.key)) {
                    cssStack.push({
                        name: key,
                        css: selected[key]
                    });
                }
            }
        } else {
            publicai.keyframeEditing = false;
        }

        let lastStyleInStack = cssStack[cssStack.length - 1];

        // If a node is provided, return the computed values without modifying publicai.active
        if (node) {
            return {
                cssStack: cssStack,
                lastStyleInStack: lastStyleInStack
            };
        } else {
            // If no node is provided, modify publicai.active as before
            publicai.active.cssStack = cssStack;
            publicai.active.lastStyleInStack = lastStyleInStack;
            publicai.active.path = cssStack.map(item => item.name).join(".");

            delete publicai.active.ancestorStack;
            if (!nwsapp.video) {
                publicai.active.ancestorStack = findAncestorClasses(publicai.active.page, settings.id); // Builds up an array of all classes that are ancestors of the selected element
            } else {
                // Video mode stack
                publicai.active.ancestorStack = video_css.createAncestorsStack();
            }
        }

    } else {
        // If no classes are found and no node is provided, reset publicai.active properties
        if (!node) {
            publicai.active.cssStack = [];
            publicai.active.lastStyleInStack = null;
            publicai.active.ancestorStack = null;
        }
    }

    // Return null if no node is provided and there are no classes
    return null;
}



export function getCssStack(el) {

    //returns stack info for each object. Used for mass-creating classes


    let cssStack = []
    let lastStyleInStack
    let ancestorStack
    let path


    if (el.classes.length > 0) {
        let mainClassName = el.classes[0].name
        //remove all breakpoints from stack

        let sep = publicai.classSeparator

        const allowedBreakpoints = ["__desktop", "__tablet", "__mobile_h", "__mobile_v"];

        // Assuming el.classes[0].combo is an array of strings
        let classesCombo = el.classes[0].combo;

        // Filter out entries that are not in the allowedBreakpoints
        try {
            el.classes[0].combo = classesCombo.filter(function (className) {
                return !allowedBreakpoints.includes(className);
            });
        } catch (err) {
            //console.log(err)
        }

        if (publicai.active.breakpoint == "__tablet") {
            if (!publicai.css[mainClassName + sep + "__tablet"]) {

            }
            el.classes[0].combo.push("__tablet")
        }
        if (publicai.active.breakpoint == "__mobile_h") {
            if (!publicai.css[mainClassName + sep + "__tablet" + sep + "__mobile_h"]) {

            }
            el.classes[0].combo.push("__tablet")
            el.classes[0].combo.push("__mobile_h")
        }

        if (publicai.active.breakpoint == "__mobile_v") {
            if (!publicai.css[mainClassName + sep + "__tablet" + sep + "__mobile_h" + sep + "__mobile_v"]) {

            }
            el.classes[0].combo.push("__tablet")
            el.classes[0].combo.push("__mobile_h")
            el.classes[0].combo.push("__mobile_v")
        }


        //creating stack
        cssStack.push({
            name: mainClassName,
            css: publicai.css[mainClassName]
        })



        for (let combo of el.classes[0].combo) {
            let comboName = mainClassName + publicai.classSeparator + combo


            let lastLocation
            lastLocation = publicai.css
            for (let item of cssStack) {
                if (lastLocation[item.name]) {
                    lastLocation = lastLocation[item.name]
                } else {
                    lastLocation = lastLocation.combo[item.name]
                }
                //console.log(lastLocation)
            }


            let obj = lastLocation.combo[combo]
            if (!obj || typeof obj == "undefined" || obj == undefined) {
                //creating an empty entry for the breakpoint
                lastLocation.combo[combo] =
                {
                    //"font-family": "XX obj" + combo
                    "combo": {},
                    "keyframes": {},
                    "keyframe_definitions": {},
                    "state": {}

                }
                obj = publicai.css[mainClassName].combo[combo]
            }

            cssStack.push({
                name: combo,
                css: lastLocation.combo[combo]
            })
        }


        lastStyleInStack = cssStack[cssStack.length - 1]
        path = cssStack.map(item => item.name).join(".")
        ancestorStack = findAncestorClasses(publicai.active.page, el.id) //builds up an array of all classes that are ancestors of the selected element

    } else {
        cssStack = []
        lastStyleInStack = null
        publicai.active.ancestorStack = null
    }

    return ({
        cssStack: cssStack,
        lastStyleInStack: lastStyleInStack,
        ancestorStack: ancestorStack,
        path: path
    })

}




export function updateBreadCrumbs() {

    let breadcrumbContainer = $$$(document, "data-breadcrumb", "container")
    removeAllChildNodes(breadcrumbContainer)

    let elements = [];
    elements.push(publicai.selected.settings)
    let ancestor = _find.elementByID(publicai.active.page, publicai.selected.settings.ancestor);
    while (ancestor) {
        elements.push(ancestor);
        ancestor = _find.elementByID(publicai.active.page, ancestor.ancestor);
    }


    for (let item of elements.reverse()) {
        let crumb = addWhatWhere(components.ui.breadcrumb, breadcrumbContainer)
        let icon = $$$(crumb, "data-icon", "container")
        let label = $$$(crumb, "data-label", "container")
        let extraLabel = $$$(crumb, "data-label", "extra")
        icon.textContent = _handlers.icons[item.type]

        extraLabel.textContent = item.type


        if (item.dynamic?.schema) {
            //crumb.classList.add("is_dynamic")
            render.addClassesToAllChildrenOf(crumb, "is_dynamic", true)
            icon.textContent = "add_box"
            extraLabel.textContent = "Wrapper"
        }

        if (item.settings?.role) {
            //crumb.classList.add("is_dynamic")
            render.addClassesToAllChildrenOf(crumb, "is_" + item.settings.role, true)
            icon.textContent = "add_box"
            extraLabel.textContent = item.settings.role
        }


        crumb.classList.add("crumb_" + item.type)





        let className
        if (item.classes.length > 0) {
            className = item.classes[0].name
        } else {
            className = "No Class"
        }


        label.textContent = capitaliseFirstLetter(className)

        if (item.settings?.general.label && item.settings?.general.label.toLowerCase() != className.toLowerCase()) {
            label.textContent += " (" + item.settings.general.label + ")"
        }


        if (publicai.timelineIsActive) {

            let duration = (item.settings.timing.duration / 1000).toFixed(2) + "s"

            label.textContent += " " + duration

        }

        if (publicai.selected.id == item.id) {

            render.addClassesToAllChildrenOf(crumb, "active", true)
        }

        crumb.onmouseenter = event => {


            for (let item of $$$(document, "public-rollover")) { item.remove() }

            let el = $$$(nwsapp.activeDocument, "public_id", item.id)

            publicai.hovering = {
                "element": el,
                "settings": item,
                "id": item.id
            }

            _create.addSelectionContainer(el, item, "rollover")
            event.stopPropagation()
            event.preventDefault()
        }

        crumb.onmouseleave = event => {
            for (let item of $$$(document, "public-rollover")) { item.remove() }
            let rollOverItem = $$$(document, "public-rollover", item.id)

            if (rollOverItem) {
                rollOverItem.remove()
            }

            event.stopPropagation()
            event.preventDefault()
        }




        crumb.onclick = event => {
            //console.log("id", item.id)
            _create.activateSpecificElement(item.id)


        }
        crumb.oncontextmenu = event => {
            //opens right click menu over the element
            _create.activateSpecificElement(item.id)
            _shortcuts.handleRightClickOverIframe(null)
            event.preventDefault()
            event.stopPropagation()
        }

    }

}

export var reservedStates = [
    "hover",       // When the mouse pointer is over an element.
    "focus",       // When an element has received focus.
    "placeholder",       // When an element has received focus.
    "pressed",
    "visited",     // For links that the user has visited.
    "link",        // For links that have not been visited.
    "checked",     // For input elements that are checked or selected.
    "disabled",    // For input elements that are disabled.
    "enabled",     // For input elements that are enabled.
    "focus-within",// For an element that has focus within itself or within any of its descendants.
    "focus-visible",// For elements that are focused and should also be visibly indicated to the user.
    // Common pseudo-elements related to interaction states:
    "before",      // For content before an element.
    "after"        // For content after an element.
]


export async function fetchPageDetails(targetUrl) {
    const workerUrl = 'https://pagefetch.m5734.workers.dev/';
    //const targetUrl = 'https://www.nws.ai';

    try {
        const response = await fetch(`${workerUrl}?url=${encodeURIComponent(targetUrl)}`);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Received data:', data);
        return data;
    } catch (error) {
        console.error('Error fetching page details:', error);
    }
}


export async function uxservice(frame) {


    return

    components.user_ux = {};
    const service = 'https://ux-publicai.m5734.workers.dev/';
    const refresh = false; // If true, the service will fetch the target URL again and not use the cache.
    nws.frameCSSToAdd = [];

    try {
        const response = await fetch(`${service}?refresh=${refresh}`);
        const data = await response.json();

        const contentDocuments = await Promise.all(data.map(loadContent));
        [template1, template2, template3, template4, template5, template6, template7, template8, template9, template10] = contentDocuments;
        const t1 = template1;

        const allUserCSS = t1.getElementsByTagName('head')[0].getElementsByTagName("link");
        for (let item of allUserCSS) {
            if (!nws.frameCSSToAdd.includes(item.href) && item.href.includes("css")) {
                nws.frameCSSToAdd.push(item.href);
            }
        }

        // Assuming css_management.extract() returns a promise or performs an async operation
        await css_management.extract();

        // Optionally, you can return some data or confirmation here
        return "UX service completed successfully";
    } catch (error) {
        // Handle any errors here
        console.error("Error in UX service: ", error);
        throw error; // Or handle the error as needed
    }

    function loadContent(data) {
        return new Promise((resolve, reject) => {
            try {
                const parser = new DOMParser();
                const doc = parser.parseFromString(data.html, 'text/html');
                data.css.forEach((cssLink) => {
                    if (!nws.frameCSSToAdd.includes(cssLink)) {
                        if (nws.isPlayer) {
                            nws.frameCSSToAdd.push(cssLink);
                        }
                    }
                });
                resolve(doc);
            } catch (error) {
                reject(error);
            }
        });
    }

}


export function extract(page) {
    components.user_ux.public_active = ux.ext("public-active")
    components.user_ux.public_div_label = ux.ext("public_div_label") // no longer used!

    components.user_ux.selector_elements = ux.ext("selector_elements")

    componentToUseBasedOnContentType = {
        "messages": "",
        "textoptions": ""
    }
    //utils.unFreezePage()
    if (!nws.isPlayer) { //if undefined >> it's rendered in wrapper
        setTimeout(async function () {
            await loadSection.loadSectionOrLoginPage()

            if (auth.isAuthenticated()) {
                multipleTabDetection();
            }
            sentryConfigureScope()
        }, 1000);
    } else {
        //      console.log(document.getElementById("components"))
        document.getElementById("components").remove()
    }
}



export function updateScrollbarTheme(theme) {
    // Define the CSS rules for light, dark, and system themes
    const themes = {
        system: {
            'scrollbarColor': 'auto',
            'scrollbarWidth': 'auto',
            'webkitScrollbar': ''
        },
        dark: {
            'scrollbarColor': '#888 #333',
            'scrollbarWidth': 'thin',
            'webkitScrollbar': `
            ::-webkit-scrollbar {
                width: 4px;
            }
            ::-webkit-scrollbar-track {
                background-color: #333;
            }
            ::-webkit-scrollbar-thumb {
                background-color: #888;
            }
        `
        },
        light: {
            'scrollbarColor': '#aaa #eee',
            'scrollbarWidth': 'thin',
            'webkitScrollbar': `
            ::-webkit-scrollbar {
                width: 4px;
            }
            ::-webkit-scrollbar-track {
                background-color: #eee;
            }
            ::-webkit-scrollbar-thumb {
                background-color: #aaa;
            }
        `
        }
    };

    // Get the selected theme
    const selectedTheme = themes[theme];

    // Apply the selected theme
    if (selectedTheme) {

        let frameLocation = document2 || document // will be document in client

        let styleElement = frameLocation.getElementById('custom-scrollbar-style');
        if (!styleElement) {
            styleElement = frameLocation.createElement('style');
            styleElement.id = 'custom-scrollbar-style';
            frameLocation.head.appendChild(styleElement);
        }

        // Set Firefox scrollbar styles
        styleElement.textContent = `
        .custom-scrollbar {
            scrollbar-color: ${selectedTheme.scrollbarColor};
            scrollbar-width: ${selectedTheme.scrollbarWidth};
        }
        ${selectedTheme.webkitScrollbar}
    `;

        // Call the function to apply CSS
    } else {
        console.error('Invalid theme specified');
    }
}

