// Alias window to nws_window (only for browser environments)

window.nws_window = window;

// Import modern modules
//import { app } from '../src/modules/main.js';
import { login } from './services/auth/login.js';
import { applyCSSServer } from './modules/css/server_css.js';  // Import the session extraction logic


import { Router } from '../src/routes/router.js';  // Import the Router class
import { saveOrUpdateContent, deletePackageFromDynamoDB, getContent } from '../src/modules/api/dynamo_ops/operations.js';
//import { utils } from '../src/modules/utils/utils.js';
import * as utils from '../src/modules/utils/utils.js';
import { removeAttributesFromNode, clearInlineStyles, addStylesheet, removeAllChildNodes, addWhatWhere } from './modules/utils/utils.js';
import * as system from '../src/modules/utils/system.js';


window.endpoints = {
  media: "https://web.assets0.com/"
}
window.utils = utils

window.createNewProject = async function () {
  let settings = {};
  const saveResult = await saveOrUpdateContent(settings);
  console.log("new project", saveResult);
}

// Function to initialize the application after loading components
export async function initializeApplication() {
  await utils.loadComponents("app");

  let el = components.system["785d069f-91fb-11ef-a90d-fb41c78f9c0e"];
  let myCss = applyCSSServer(el.css)
  system.createAppContainer()

  window.app.fonts = {
    used: el.fonts,
    loaded: new Set()
  }

  utils.loadFonts()
  utils.addStyleshetToPage(myCss)




  const router = new Router();  // Initialize your custom Router for client-side navigation

  // Initialize any legacy modules if present
  if (typeof window !== 'undefined' && window.moduleName && window.moduleName.init) {
    //console.log("Initializing legacy module...");
    window.moduleName.init();
  }

  //console.log("Application initialized successfully++");

  //router.navigateTo('/editor');
}


window.onload = () => {
  initializeApplication();
};




