import { loadAgent, saveAgent, createNewAgent } from './agentApi.js';  // Assuming agent functions are in agentApi.js
//import * as utils from '../src/modules/utils/utils.js';

export async function init(path) {
    console.log("Manage agent with path:", path);
    

    const el = $$$$(document, "role");
    const overlay = utils.addWhatWhere(nwsapp.comp.overlay, el.app);

    // Check if the path is for creating a new agent or loading an existing one
    const pathParts = path.split("/").filter(Boolean);  // Split path and filter out empty parts

    if (pathParts[0] === "manage" && pathParts[1] === "agent") {
        if (!pathParts[2]) {
            // No agent ID specified, create a new agent
            console.log("No agent ID specified, creating a new agent.");

            // Define a default agent schema
            const defaultAgent = {
                name: "Default Agent",
                description: "This is a default agent created automatically.",
                created: new Date().toISOString(),
                modified: new Date().toISOString(),
                schema: {
                    flow: "default_flow",
                    questions: [],
                    responses: []
                }
            };

            // Set the agent as the current agent in nwsapp
            nwsapp.currentAgent = defaultAgent;

            // Save and retrieve the new agent
            await createNewAgent();
            console.log("Agent created and loaded:", nwsapp.currentAgent);
        } else {
            // An agent ID is specified, load the agent
            const agentId = pathParts[2];
            console.log("Agent ID specified, loading agent:", agentId);

            await loadAgent(agentId);
            console.log("Agent loaded:", nwsapp.currentAgent);
        }
    }
}



