
export async function addDomain(domainName, status) {
    // Extract base domain (e.g., "example.com" from "www.example.com")
    const baseDomain = domainName.replace(/^www\./, '');

    const url = "/api/domain/add";
    const data = { domainName: baseDomain, status };

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        });
        const json = await response.json();

        if (response.ok) {
            console.log("Domain added:", json);
            // Trigger backend setup for S3, CloudFront, and DNS
            //await setupDomainInfrastructure(json.domain);
        } else {
            console.error("Error adding domain:", json.message);
        }

        return json

    } catch (error) {
        console.error("Error adding domain:", error);
    }
}



export async function listDomains() {
    const url = "/api/domain/list";

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' }
        });
        const domains = await response.json();

        if (response.ok) {
            console.log("Domains list:", domains);
            // Process the domains list as needed, e.g., render it in the UI
        } else {
            console.error("Error listing domains:", domains.message);
        }

        return domains;

    } catch (error) {
        console.error("Error listing domains:", error);
    }
}


export async function deleteDomain(domainId) {
    const url = "/api/domain/delete";
    const data = { domainId };

    await fetch(url, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    })
        .then(response => response.json())
        .then(json => {
            console.log("Domain deleted:", json);
        })
        .catch(error => {
            console.error("Error deleting domain:", error);
        });
}

export async function updateDomain(domainId, updates) {
    const url = "/api/domain/update";
    const data = { userId: nwsapp.currentUser.id, domainId, updates };

    await fetch(url, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    })
        .then(response => response.json())
        .then(json => {
            console.log("Domain updated:", json);
        })
        .catch(error => {
            console.error("Error updating domain:", error);
        });
}


export async function verifyDomain(domainId) {
    const url = `/api/domain/verify/${domainId}`;

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' }
        });
        const json = await response.json();

        if (response.ok) {
            if (json.verified) {
                console.log("Domain verified successfully:", json);
                // Optionally trigger the setup if verification is successful
                await setupDomainInfrastructure(json.domain);
            } else {
                console.warn("Domain verification failed:", json.message);
            }
            return json;
        } else {
            console.error("Error verifying domain:", json.message);
        }
    } catch (error) {
        console.error("Error verifying domain:", error);
    }
}


export async function setupDomainInfrastructure(domain) {
    try {
        // 1. Request SSL Certificate and store the ARN in DynamoDB
        const certArn = await requestCertificate(domain);
        await updateDomainData(domain, { certificateArn: certArn });

        // 2. Wait for certificate to be issued, then store validation details
        const certificate = await waitForCertificate(certArn);
        await createRoute53Records(domain, certificate.DomainValidationOptions);

        // 3. Create CloudFront distribution and store the distribution ID
        const cloudfrontUrl = await createCloudFrontDistribution(domain, certArn);
        await updateDomainData(domain, { distributionId: cloudfrontUrl.DistributionId });

        // 4. Create S3 folder and store folder key (if necessary)
        await createS3Folder(domain);
        await updateDomainData(domain, { bucketFolder: `${domain}/index.html` });

        console.log(`Infrastructure setup completed for domain: ${domain}`);
    } catch (error) {
        console.error(`Error setting up infrastructure for ${domain}:`, error);
    }
}

// Helper function to update DynamoDB with new attributes
async function updateDomainData(domain, updates) {
    await Domain.update({ domain }, updates);
}

