import { authenticateUserWithCognito, logout, refreshSession, storeSessionTokens } from '../services/auth/cognito.js';

export async function init(path) {
    console.log("full path", path);
    removeAllChildNodes(document.body);
    document.body.style.height = "100%";
    let component = components.ui.loginpage;
    document.body.appendChild(component.cloneNode(true));

    login();  // Call the login function on initialization
}

export function googleLogin() {
    const googleOAuthURL = 'https://accounts.google.com/o/oauth2/v2/auth';
    const params = new URLSearchParams({
        client_id: process.env.GOOGLE_CLIENT_ID,
        redirect_uri: 'https://publicai.com/callback/signin', // Updated URI
        response_type: 'code',
        scope: 'openid email profile',
        prompt: 'select_account'
    });

    window.location.href = `${googleOAuthURL}?${params.toString()}`;
}

export async function login() {
    let el = $$$$(document, "login_el");

    // Handle social login (Google)
    el.social.onclick = (e) => {
        googleLogin();
    };

    el.email.value = "test@nws.ai";
    el.password.value = "TestPassword123!";

    el.button.addEventListener("click", async () => {
        const username = el.email.value;
        const password = el.password.value;

        if (!username || !password) {
            console.error("Username and password are required");
            return;
        }

        try {
            // Call the function from `cognito.js` to authenticate the user
            const session = await authenticateUserWithCognito(username, password);
            //console.log("Authentication successful", session);

            // Fetch user profile or perform other actions after login
            // ...

            // Navigate to home page
            router.navigateTo('/workspace');
        } catch (error) {
            console.error("Error during login process:", error);
        }
    });
}
