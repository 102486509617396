import { Menu } from "./menu.js";





export class Loader {
    constructor(settings) {
        this.settings = settings;
        this.init();
    }

    init() {
        // Consider clearing other loaders here if needed

        // Add loader to the DOM and initialize progress bar
        this.loaderWindow = addWhatWhere(nwsapp.comp.loader, app.ui.main.el, "loader");
        const el = $$$$(this.loaderWindow, "role");
        this.progressBar = el.progress;

        // Apply initial styles to animate the progress bar
        this.animate();
    }

    animate() {
        // Animate the width of the progress bar to 100% over `settings.time` milliseconds
        this.progressBar.style.transition = `width ${this.settings.time}ms linear`;
        this.progressBar.style.width = '100%';
    }

    finish() {
        // When finish is called, reset the transition for a 500ms finish animation
        this.progressBar.style.transition = 'width 500ms linear';
        this.progressBar.style.width = '100%'; // Ensure it reaches 100%

        // Wait 500ms to complete the final animation, then remove the loader
        setTimeout(() => {
            if (this.loaderWindow && this.loaderWindow.parentNode) {
                this.loaderWindow.parentNode.removeChild(this.loaderWindow);
            }
        }, 500);
    }
}




export function loadFonts(fonts) {

    for (let item of Object.keys(app.fonts.used)) {
        let font = app.fonts.used[item]
        //see if font.id is in publicai.fonts.loaded
        if (!app.fonts.loaded.has(font.id)) {
            //font not loaded. Load it
            //play_interactive.addStylesheet(font.url, "custom", font.id) //one loads to iframe, other loads to document
            addStylesheet(font.url, "custom", font.id)
            app.fonts.loaded.add(font.id)
        }
    }

}

export function getShortFileName(name) {

    let filetoUse = ""

    let fileName = name.split(".")[0]
    let ext = name.split(".")[1]

    let lastCharOfFileName = fileName.charAt(fileName.length - 1)

    if (name.length > 10) {
        filetoUse = fileName.substring(0, 10) + "..." + lastCharOfFileName + "." + ext
    } else {
        filetoUse = name
    }

    return filetoUse



}
export function addMediaTo(file, container, parentContainer) {
    let asset;

    // Get the width of the container
    const containerWidth = container.clientWidth;

    // Calculate height based on container's width and the file's aspect ratio
    const aspectRatio = file.width / file.height;
    const calculatedHeight = containerWidth / aspectRatio;

    // Image handling
    if (file.fileType === "image") {
        const url = window.endpoints.media + file.s3Key;

        // Load the image in memory to check if it exists
        const img = new Image();
        img.src = url;
        img.onload = function () {
            asset = utils.addImageTo(url, container); // Set width to 100% and calculate height

            asset.style.height = `${calculatedHeight}px`;
            asset.style.maxHeight = "100%"
            asset.style.width = "100%"

            //, "100%", `${calculatedHeight}px`


        };
        img.onerror = function () {
            if (parentContainer) {
                parentContainer.style.display = "none"; // Hide the parent container if the image fails to load
            }
        };
    }

    // Video handling
    if (file.fileType === "video") {
        const url = window.endpoints.media + file.s3Key;
        const poster = file.previewUrl || ""; // Add poster if available

        // Create a video element to test loading
        const video = document.createElement("video");
        video.src = url;
        video.onloadeddata = function () {
            asset = utils.addVideoTo(url, poster, container, "100%", `${calculatedHeight}px`); // Set width to 100% and calculate height
        };
        video.onerror = function () {
            container.style.display = "none"; // Hide the container if the video fails to load
        };
    }

    return asset;
}




export function addImageTo(src, container, w, h, id) {
    let myImage = document.createElement("img")
    if (h != undefined) {
        myImage.setAttribute('height', h);
    }

    if (w != undefined && w != null && w != 0) {
        myImage.setAttribute('width', w);
    }
    if (id != undefined) {
        myImage.setAttribute('id', id);
    }

    myImage.style.maxWidth = "none"
    //myImage.setAttribute("loading", "lazy")
    myImage.setAttribute('src', src);
    myImage.style.objectFit = "cover";
    myImage.crossOrigin = "anonymous";
    container.appendChild(myImage)
    return (myImage)
}


export function addVideoTo(srcMP4, poster, container, w, h, id, showControls) {


    let video = document.createElement("video");
    let sourceMP4 = document.createElement("source");
    sourceMP4.type = "video/mp4";
    sourceMP4.src = srcMP4;
    video.muted = true
    video.autoplay = "true"
    video.loop = "true"
    video.setAttribute("controls", "")
    video.setAttribute("muted", "")
    video.setAttribute("playsinline", "")
    video.controls = false
    video.playsinline = true

    if (showControls == true) {
        //console.log("showing controls")
        video.controls = true
        video.autoplay = false
    }

    if (poster) {
        let p = document.createElement("poster");
        p.src = poster;
    }
    video.appendChild(sourceMP4);
    addSourceToVideo(video, srcMP4, 'video/mp4');


    function addSourceToVideo(element, src, type) {
        var source = document.createElement('source');
        source.src = src;
        source.type = type;
        element.appendChild(source);
    }


    if (h != undefined) {
        video.setAttribute('height', h);
    }
    if (w != undefined) {
        video.setAttribute('width', w);
    }
    video.style.objectFit = "cover";
    container.appendChild(video)
    return (video)
}




export function addWhatWhere(what, where, path, clearContainerFirst = false) {
    // Clone and add the element to the target container

    if (clearContainerFirst) {
        removeAllChildNodes(where)
    }


    let element = what.cloneNode(true);
    where.appendChild(element);

    // If there's a path, we set up the structure within window.app.ui
    if (path) {
        let segments = path.split("/"); // Split the path into segments
        let current = app.ui;

        // Iterate through the path segments
        segments.forEach((segment, index) => {
            // Check if the segment exists, if not, create an empty object for it
            if (!current[segment]) {
                current[segment] = {};
            }
            // Move to the next level in the object hierarchy
            current = current[segment];
        });

        // Set the last segment in the path to reference the newly added element
        current.el = element;
        current.content = $$$$(element, "role")

        current.buttons = $$$$(element, "action")
        for (let key of Object.keys(current.buttons)) {
            current.buttons[key].onclick = (e) => {
                let myAction = current.buttons[key].getAttribute("action")

                window.router.appendNavigateTo("/m/" + myAction)

            }
        }

    }

    //find all action buttons



    return element;
}


export function $$$(where, att, value) {
    if (value != null) {
        return (where.querySelectorAll('[' + att + '~="' + value + '"]')[0])
    } else {
        return (where.querySelectorAll('[' + att + ']'))
    }
}

export function addStyleshetToPage(css) {

    // Create a new <style> element
    let style = document.createElement("style");
    style.type = "text/css";

    // Add the CSS string to the <style> element
    if (style.styleSheet) {
        // This is required for IE
        style.styleSheet.cssText = css;
    } else {
        style.appendChild(document.createTextNode(css));
    }

    // Append the <style> element to the <head>
    document.head.appendChild(style);



}

export function $$$$(location, att) {
    let el = {}
    let allElements = $$$(location, att)
    for (let item of allElements) {
        el[item.getAttribute(att)] = item
    }
    return (el)

}

export function clearInlineStyle(node) {
    if (node.nodeType === Node.ELEMENT_NODE) {
        node.removeAttribute('style');
    }

    // Recursively clear inline styles from child nodes
    for (let child of node.children) {
        clearInlineStyles(child);
    }
}

export function clearInlineStyles(node) {
    if (node.nodeType === Node.ELEMENT_NODE) {
        node.removeAttribute('style');
    }

    // Recursively clear inline styles from child nodes
    for (let child of node.children) {
        clearInlineStyles(child);
    }
}


export function addStylesheet(src) {
    var head = document.getElementsByTagName('head')[0];
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = src
    link.media = 'all';
    head.appendChild(link);
}




export function removeAttributesFromNode(node, attributesToRemove) {
    if (node.nodeType === Node.ELEMENT_NODE) {
        attributesToRemove.forEach(attr => {
            if (node.hasAttribute(attr)) {
                node.removeAttribute(attr);
            }
        });
    }

    // Recursively remove attributes from child nodes
    for (let child of node.children) {
        removeAttributesFromNode(child, attributesToRemove);
    }
}

export function generateSceneID() {
    //console.log("-generating scene id")
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}




export function removeAllChildNodes(parent, exclude) {
    if (parent) {
        while (parent.firstChild) {
            parent.removeChild(parent.firstChild);
        }
    }
}
export function addClassesToChildrenOf(target, theClass, addOrRemove) {
    //console.log("adding classes", theClass)
    for (let item of target.children) {
        if (addOrRemove) {
            item.classList.add(theClass)
            target.classList.add(theClass)

        } else {
            item.classList.remove(theClass)
            target.classList.remove(theClass)
        }
    }

}

export function addClassesToAllChildrenOf(target, theClass, addOrRemove) {

    if (theClass != "") {

        if (target && theClass) {
            if (Array.isArray(theClass)) {
                // If theClass is an array, loop through each class and add or remove it
                for (let cls of theClass) {
                    for (let item of target.querySelectorAll("*")) {
                        if (addOrRemove) {
                            item.classList.add(cls);
                            target.classList.add(cls);
                        } else {
                            item.classList.remove(cls);
                            target.classList.remove(cls);
                        }
                    }
                }
            } else {
                // If theClass is not an array, add or remove it directly
                for (let item of target.querySelectorAll("*")) {
                    if (addOrRemove) {
                        item.classList.add(theClass);
                        target.classList.add(theClass);
                    } else {
                        item.classList.remove(theClass);
                        target.classList.remove(theClass);
                    }
                }
            }
        }

    }
}


export async function loadComponents(comp) {

    if (!comp) {
        comp = ""
    }

    try {
        const response = await fetch('/api/components/' + comp);

        if (!response.ok) {
            throw new Error(`Failed to fetch components: ${response.statusText}`);
        }

        if (window.components) {
            window.components = {}
        }

        let compResponse = await response.json();

        for (let key of Object.keys(compResponse)) {
            //avoid deleting existing components, just add the new keys
            window.components[key] = compResponse[key]
        }


        for (let section of Object.keys(window.components)) {
            for (let key of Object.keys(window.components[section])) {
                let element = window.components[section][key];
                if (typeof element === "string") {
                    let template = document.createElement('div'); // Create a template element
                    template.innerHTML = element.trim(); // Convert string to HTML
                    window.components[section][key] = template.firstChild; // Replace string with the first child of the template
                }
            }
        }

        if (!nwsapp.comp) {
            nwsapp.comp = {}
        }

        for (let key of Object.keys(window.components.system)) {
            let template = window.components.system[key];

            if (template.css && typeof template.css === "string") {
                template.css = JSON.parse(template.css);
            }
            if (template.node && typeof template.node === "string") {
                template.node = JSON.parse(template.node);
            }
            if (template.js && typeof template.js === "string") {
                template.js = JSON.parse(template.js);
            }
            if (template.permissions && typeof template.permissions === "string") {
                template.permissions = JSON.parse(template.permissions);
            }

            if (template.html && typeof template.html === "string") {
                let t = document.createElement('div');
                t.innerHTML = template.html.trim();
                //template.html = t.firstChild;
                template.html = t
                let allComps = utils.$$$(template.html, "comp")
                for (let comp of allComps) {
                    let name = comp.getAttribute("comp")
                    nwsapp.comp[name] = comp
                    //remove the component from the html

                }
                removeAttributesFromNode(template.html, ["public_id"])
                clearInlineStyles(template.html)
            }

        }

        //adding components css

    } catch (error) {
        console.error('Error loading components:', error);
    }
}


// Load stylesheets from the server
export async function loadStylesheets() {
    //only relevant for webflow. Native stylesheets are loaded in the object. 
    try {
        const response = await fetch('/api/stylesheets');
        if (!response.ok) {
            throw new Error('Failed to fetch stylesheets');
        }
        window.stylesheets = await response.json();

        for (let stylesheet of window.stylesheets) {
            addStylesheet(stylesheet);
        }
    } catch (error) {
        console.error('Error loading stylesheets:', error);
    }
}



export class Modal {
    constructor(settings) {
        this.settings = settings;
        this.init();
        //return (this.modal)
    }

    init() {
        //add modal container 

        //check if there are any previous modals
        let existingModal = $$$(app.ui.top.el, "role", "modal")
        if (existingModal) {
            existingModal.remove()
        }

        this.container = utils.addWhatWhere(nwsapp.comp.modal, app.ui.top.el)

        utils.removeAllChildNodes(this.container)

        this.container.onclick = (e) => {
            this.closeModal(e)
            e.stopPropagation()
            e.preventDefault()
        }

        this.modal = utils.addWhatWhere(this.settings.comp, this.container, "domain_modal")

        this.modal.onclick = (e) => {

            e.stopPropagation()
            e.preventDefault()
        }
    }

    closeModal(e) {
        this.container.remove()
    }

}


export class Tabs {
    constructor(settings) {
        this.settings = settings;

        this.tabs = $$$$(this.settings.container, settings.identifiers.tab)
        this.panels = $$$$(this.settings.container, settings.identifiers.panel)
        this.init();
    }

    init() {
        // Consider clearing other loaders here if needed

        let defaultTab = this.settings.default || Object.keys(this.tabs)[0]

        for (let key of Object.keys(this.tabs)) {
            this.tabs[key].onclick = (e) => {
                this.switchTo(key)
            }
        }

        this.switchTo(defaultTab)
    }

    toggleTab(key, toggle) {
        if (this.tabs[key]) {
            this.tabs[key].classList.toggle("is_active", toggle);
        }

    }

    togglePanel(key, toggle) {
        if (this.panels[key]) {
            this.panels[key].classList.toggle("is_active", toggle);
        }
    }


    switchTo(tab) {
        for (let key of Object.keys(this.tabs)) {
            this.toggleTab(key, false)
            this.togglePanel(key, false)
        }
        this.toggleTab(tab, true)
        this.togglePanel(tab, true)

        if (window.router) {
            window.router.updateURLSilently("/" + this.settings.root + "/" + tab)
        } else {
            console.log("roter not found")
        }


        console.log(tab, this.settings.callbacks)
        if (this.settings.callbacks && this.settings.callbacks[tab]) {
            this.settings.callbacks[tab]()
        }
    }
}



export function showModal(whichModal, addFirstDummyConversation) {


    switch (whichModal) {
        case "project_settings":


        case "name":
            document.querySelector(".modal_container").classList.add("active")
            currentPanel = "conversation_name"
            let conversationNamePanel = document.querySelector(".conversation_name")
            conversationNamePanel.classList.add("active")

            setTimeout(function () {
                document.querySelector(".conversationname_input").value = nwsapp.currentConversation.name
                document.querySelector(".conversationname_input").focus()
                document.querySelector(".conversationname_input").select()
                nwsapp.currentConversation.name = document.querySelector(".conversationname_input").value

                document.querySelector(".conversationname_input").addEventListener('input', (event) => {

                    document.querySelector(".conversationname_input").value = event.target.value
                    nwsapp.currentConversation.name = event.target.value
                    document.querySelector(".the_conv_name").textContent = nwsapp.currentConversation.name
                })

                conversations.save()

            }, 100);

            if (addFirstDummyConversation) {
                nwsapp.addFirstDummyConversation = true
            } else {
                nwsapp.addFirstDummyConversation = false

            }
            //saveConversationName



            let isTemplateRadioSwitch = document.querySelectorAll('[radioswitch~="template"]')[0]

            isTemplateRadioSwitch.addEventListener('click', (e) => {
                enabledOrDisableTempalateMode(e.target)
            })

            function enabledOrDisableTempalateMode(target) {

                thetarget = findParent(target, "radio_compact")

                if (nwsapp.currentConversation.isTemplate) {
                    render.addClassesToAllChildrenOf(thetarget, "active", false)
                    nwsapp.currentConversation.isTemplate = false
                } else {
                    render.addClassesToAllChildrenOf(thetarget, "active", true)
                    nwsapp.currentConversation.isTemplate = true
                }
            }


            document.querySelector(".media_type_button").addEventListener('click', (e) => {
                conversations.saveConversationName()
            })


            conversationNamePanel.addEventListener('click', (e) => {
                e.stopPropagation()
            })
            break;

        case "save":
            currentPanel = "save_menu"
            let savePanel = document.querySelector(".save_options")

            savePanel.classList.add("active")

            savePanel.addEventListener('click', (e) => {
                e.stopPropagation()
            })

            break;
    }

}

export function closeModal() {
    currentPanel = "general"
    render.addClassesToAllChildrenOf(document.querySelector(".modal_container"), "active", false)
    if (nwsapp.addFirstDummyConversation) {
        nwsapp.addFirstDummyConversation = false
        setTimeout(function () {

            let newDummySeq = JSON.parse(JSON.stringify(defaultsequence))
            newDummySeq.name = generateSceneID()
            newDummySeq.id = generateSceneID()
            //otherwise, they'll all be using one single id
            nwsapp.currentConversation.config.sequence.push(newDummySeq)
            content.refreshAllContent()
        }, 300);
    }



    try { document.querySelector(".save_options").classList.remove("active") } catch (err) { }
    //document.querySelector(".modal_container").classList.remove("active")


}


export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export function deleteChildren(where) {
    var e = where
    var child = e.lastElementChild;
    while (child) {
        e.removeChild(child);
        child = e.lastElementChild;
    }
}
export function setUpSearchField() {
    componentSearchField = document.getElementsByClassName("contentsearch")[0]
    componentSearchField.focus()
    componentSearchField.addEventListener('input', (event) => {
        ui.addContentPrompt("", event.target.value)
    })
    currentPanel = "featuresSearch"
}
export function freezePage() {
    //document.querySelector(".pageloader").style.display = "flex"
}
export function unFreezePage() {
    //document.querySelector(".pageloader").style.display = "none"
}


/* 

const utils = {
    Menu,
    generateSceneID,
    $$$,
    $$$$,
    removeAllChildNodes,
    addWhatWhere,
    addClassesToChildrenOf,
    addClassesToAllChildrenOf,
    addStyleshetToPage,
    loadStylesheets,
    loadComponents,
    loadFonts,
    Loader,
    Tabs,

};






export { utils };
 */